import { Modal, ThemeProvider, createTheme } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  memberCountryState,
  memberInformationEditOpenState,
  memberTypeState,
} from "../../../../atoms/members";
import MemberInformationEditHeader from "./memberInformation/MemberInformationEditHeader";
import MemberInformationEditCompany from "./memberInformation/MemberInformationEditCompany";
import MemberInformationEditUsers from "./memberInformation/MemberInformationEditUsers";
import MemberInformationEditMail from "./memberInformation/MemberInformationEditMail";
import MemberInformationEditAddress from "./memberInformation/MemberInformationEditAddress";

const modalTheme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: 20,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const MemberInformationEdit = () => {
  const [memberInformationEditOpen, setMemberInformationEditOpen] = useRecoilState(
    memberInformationEditOpenState
  );
  const [, setMemberType] = useRecoilState(memberTypeState);
  const [, setMemberCountry] = useRecoilState(memberCountryState);

  const handleClose = () => {
    setMemberInformationEditOpen({ status: false, data: {} });
    setMemberType("");
    setMemberCountry("");
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <Modal open={memberInformationEditOpen.status} onClose={handleClose}>
        <form
          style={{
            backgroundColor: "var(--background)",
            borderRadius: "var(--inputBorderRadius)",
            width: "100%",
            minHeight: "600px",
            margin: "20px auto",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "var(--gap2x)",
          }}
        >
          <MemberInformationEditHeader member={memberInformationEditOpen.data} />
          <MemberInformationEditCompany member={memberInformationEditOpen.data} />
          <MemberInformationEditUsers member={memberInformationEditOpen.data} />
          <MemberInformationEditMail member={memberInformationEditOpen.data} />
          <MemberInformationEditAddress member={memberInformationEditOpen.data} />
        </form>
      </Modal>
    </ThemeProvider>
  );
};

export default MemberInformationEdit;
