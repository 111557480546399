import React, { useCallback, useState } from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  PriceControlScreenCurrentPageState,
  PriceControlScreenDataState,
  PriceControlScreenTotalPageState,
} from "../../../../atoms/priceControl";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import axios from "../../../../api/axios";
import Errors from "../../../../utils/Errors";

const paddedCellStyle = {
  fontSize: "12px",
  padding: "5px",
  width: "50px",
};

const PriceControlScreenTableHead = ({
  onSelectAllClick,
  onCheckboxClick,
  allSelected,
  selectedRows,
  group1Checked,
  group2Checked,
  group3Checked,
  group4Checked,
  group5Checked,
  group6Checked,
}) => {
  const PriceControlScreenData = useRecoilValue(PriceControlScreenDataState);
  const [, setPriceControlScreenData] = useRecoilState(
    PriceControlScreenDataState
  );
  const [, setPriceControlScreenTotalPage] = useRecoilState(
    PriceControlScreenTotalPageState
  );
  const [PriceControlScreenCurrentPage, setPriceControlScreenCurrentPage] =
    useRecoilState(PriceControlScreenCurrentPageState);

  const [activeSort, setActiveSort] = useState([
    { column: "member_id", direction: null },
    { column: "created_at", direction: null },
    { column: "supplier_id", direction: null },
    { column: "status", direction: null },
    { column: "parcel_ref_no", direction: null },
    { column: "tracking_awb", direction: null },
    { column: "domestic_awb", direction: null },
    { column: "qty", direction: null },
    { column: "width", direction: null },
    { column: "length", direction: null },
    { column: "height", direction: null },
    { column: "gw", direction: null },
    { column: "vw", direction: null },
    { column: "cw", direction: null },
    { column: "total_ds", direction: null },
    { column: "totalPriceOld", direction: null },
    { column: "domestic_price", direction: null },
    { column: "packaging_type", direction: null },
    { column: "qty", direction: null },
    { column: "width", direction: null },
    { column: "length", direction: null },
    { column: "height", direction: null },
    { column: "gross", direction: null },
    { column: "vw", direction: null },
    { column: "cw", direction: null },
    { column: "total_ds", direction: null },
    { column: "selling_price", direction: null },
    { column: "domestic_price", direction: null },
    { column: "packaging_type", direction: null },
    { column: "roundedPriceDiff", direction: null },
    { column: "price_approved", direction: null },
    { column: "arrived_to_office_date", direction: null },
  ]);

  const handlePageChange = useCallback(
    async (orderBy) => {
      const updatedSort = activeSort.map((sort) => {
        if (sort.column === orderBy) {
          return {
            ...sort,
            direction: sort.direction === "ASC" ? "DESC" : "ASC",
          };
        }
        return {
          ...sort,
          direction: null, // Diğer sütunların yönünü varsayılan duruma sıfırlayın
        };
      });

      setActiveSort(updatedSort);

      const currentDirection = updatedSort.find(
        (sort) => sort.column === orderBy
      ).direction;

      await axios
        .get(`parcel?&detailed=1&beevision_read=1,2`, {
          params: {
            page: PriceControlScreenCurrentPage,
            limit: 1000,
            order_by: orderBy,
            order_direction: currentDirection,
          },
        })
        .then((resp) => {
          setPriceControlScreenData(resp.data.data);
          setPriceControlScreenCurrentPage(resp.data.currentPage);
          setPriceControlScreenTotalPage(resp.data.totalPages);
        })
        .catch((error) => Errors(error));
    },
    [
      setPriceControlScreenData,
      setPriceControlScreenCurrentPage,
      setPriceControlScreenTotalPage,
      PriceControlScreenCurrentPage,
      activeSort,
    ]
  );

  return (
    <TableHead
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 1,
        backgroundColor: "white",
      }}
    >
      <TableRow>
        <TableCell style={paddedCellStyle} sx={{ fontWeight: 700 }}>
          <input
            type="checkbox"
            indeterminate={
              allSelected.length > 0 &&
              allSelected.length < PriceControlScreenData?.length
            }
            checked={allSelected.length === PriceControlScreenData?.length}
            onChange={onSelectAllClick}
          />
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            display: group1Checked ? "none" : "",
            cursor: "pointer",
          }}
          onClick={() => handlePageChange("created_at")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            GÖNDERİ OLUŞTURMA TARİHİ{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "created_at")
                    ?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            display: group1Checked ? "none" : "",
            cursor: "pointer",
          }}
          onClick={() => handlePageChange("member_id")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            MÜŞTERİ ID{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "member_id")
                    ?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            display: group1Checked ? "none" : "",
          }}
        >
          MÜŞTERİ ADI
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            display: group2Checked ? "none" : "",
            cursor: "pointer",
          }}
          onClick={() => handlePageChange("supplier_id")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            TED İADELERİ{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "supplier_id")
                    ?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{ fontWeight: 700, display: group2Checked ? "none" : "" }}
        >
          TEDARİKÇİ
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            display: group2Checked ? "none" : "",
            cursor: "pointer",
          }}
          onClick={() => handlePageChange("status")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            STATÜ{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "status")
                    ?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{ fontWeight: 700, display: group2Checked ? "none" : "" }}
        >
          SORUN
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            display: group3Checked ? "none" : "",
            cursor: "pointer",
          }}
          onClick={() => handlePageChange("parcel_ref_no")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            REF NO{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "parcel_ref_no")
                    ?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            display: group3Checked ? "none" : "",
            cursor: "pointer",
          }}
          onClick={() => handlePageChange("tracking_awb")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            AWB NO{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "tracking_awb")
                    ?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            display: group3Checked ? "none" : "",
            cursor: "pointer",
          }}
          onClick={() => handlePageChange("domestic_awb")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            DOM NO{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "domestic_awb")
                    ?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            display: group4Checked ? "none" : "",
            backgroundColor: "#FFDF92",
            cursor: "pointer",
          }}
          onClick={() => handlePageChange("qty")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            KAP{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "qty")
                    ?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            backgroundColor: "#FFDF92",
            display: group5Checked ? "none" : "",
          }}
        >
          E
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            backgroundColor: "#FFDF92",
            display: group5Checked ? "none" : "",
          }}
        >
          B
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            backgroundColor: "#FFDF92",
            display: group5Checked ? "none" : "",
          }}
        >
          Y
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            backgroundColor: "#FFDF92",
            display: group5Checked ? "none" : "",
          }}
        >
          GW
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            backgroundColor: "#FFDF92",
            display: group5Checked ? "none" : "",
          }}
        >
          VW
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            backgroundColor: "#FFDF92",
            display: group5Checked ? "none" : "",
          }}
        >
          CW
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            backgroundColor: "#FFDF92",
            display: group4Checked ? "none" : "",
          }}
        >
          DESİ
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            backgroundColor: "#FFDF92",
            display: group4Checked ? "none" : "",
          }}
        >
          SATIŞ FİYATI CW
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            display: group4Checked ? "none" : "",
            backgroundColor: "#FFDF92",
            cursor: "pointer",
          }}
          onClick={() => handlePageChange("domestic_price")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            SATIŞ FİYATI DES{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "domestic_price")
                    ?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            display: group4Checked ? "none" : "",
            backgroundColor: "#FFDF92",
            cursor: "pointer",
          }}
          onClick={() => handlePageChange("packaging_type")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            T/B{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "packaging_type")
                    ?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            display: group6Checked ? "none" : "",
            backgroundColor: "#A3D1FF",
            cursor: "pointer",
          }}
          onClick={() => handlePageChange("qty")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            KAP{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "qty")
                    ?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            backgroundColor: "#A3D1FF",
            display: group6Checked ? "none" : "",
          }}
        >
          E
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            backgroundColor: "#A3D1FF",
            display: group6Checked ? "none" : "",
          }}
        >
          B
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            backgroundColor: "#A3D1FF",
            display: group6Checked ? "none" : "",
          }}
        >
          Y
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            backgroundColor: "#A3D1FF",
            display: group6Checked ? "none" : "",
          }}
        >
          GW
        </TableCell>

        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            backgroundColor: "#A3D1FF",
            display: group6Checked ? "none" : "",
          }}
        >
          VW
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            backgroundColor: "#A3D1FF",
            display: group6Checked ? "none" : "",
          }}
        >
          CW
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            backgroundColor: "#A3D1FF",
            display: group6Checked ? "none" : "",
          }}
        >
          Ölçü Kaydet
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            backgroundColor: "#A3D1FF",
            display: group4Checked ? "none" : "",
          }}
        >
          DESİ
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            backgroundColor: "#A3D1FF",
            display: group4Checked ? "none" : "",
          }}
        >
          SATIŞ FİYATI CW
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            display: group4Checked ? "none" : "",
            backgroundColor: "#A3D1FF",
            cursor: "pointer",
          }}
          onClick={() => handlePageChange("domestic_price")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            SATIŞ FİYATI DES{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "domestic_price")
                    ?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            display: group4Checked ? "none" : "",
            backgroundColor: "#A3D1FF",
            cursor: "pointer",
          }}
          onClick={() => handlePageChange("packaging_type")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            T/B{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "packaging_type")
                    ?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>

        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            display: group4Checked ? "none" : "",
            cursor: "pointer",
          }}
        >
          <div style={{ fontSize: "inherit" }}>SATIŞ FARK</div>
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            display: group4Checked ? "none" : "",

            cursor: "pointer",
          }}
          onClick={() => handlePageChange("price_approved")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            FİYAT{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "price_approved")
                    ?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          style={paddedCellStyle}
          sx={{
            fontWeight: 700,
            display: group4Checked ? "none" : "",

            cursor: "pointer",
          }}
          onClick={() => handlePageChange("arrived_to_office_date")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            TARİH{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find(
                    (sort) => sort.column === "arrived_to_office_date"
                  )?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default PriceControlScreenTableHead;
