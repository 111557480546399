import { useRecoilValue } from "recoil";
import { FinanceGonderiIadeDataState } from "../../../../atoms/finance";
import { Table, TableBody, TableContainer } from "@mui/material";
import FinanceGonderiIadeTableBody from "./FinanceGonderiIadeTableBody";
import FinanceGonderiIadeTableHead from "./FinanceGonderiIadeTableHead";

const FinanceGonderiIadeTable = () => {
  const FinanceGonderiIadeData = useRecoilValue(FinanceGonderiIadeDataState);

  return (
    <TableContainer
      sx={{
        backgroundColor: "var(--lighterBg)",
        boxShadow: "var(--shadow)",
        borderRadius: "var(--wrapperBorderRadius)",
      }}
    >
      <Table>
        <FinanceGonderiIadeTableHead />
        <TableBody>
          {FinanceGonderiIadeData?.map((item, key) => (
            <FinanceGonderiIadeTableBody key={key} item={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FinanceGonderiIadeTable;
