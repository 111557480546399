import "../../../style/myWallet/MyWallet.css";
import { Box, createTheme, Pagination, ThemeProvider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useRecoilState, useRecoilValue } from "recoil";
import { useInView, animated } from "@react-spring/web";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import axios from "../../../api/axios";
import { drawerOpenState } from "../../../atoms/viewPadding";
import NavBar from "../../components/sidebar/NavBar";
import { ToastContainer } from "react-toastify";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import WrapperStyle from "../../../utils/WrapperStyle";
import FinanceNav from "../../components/finance/FinanceNav";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import {
  financeFaturaInvoicingCurrentPageState,
  financeFaturaInvoicingDataState,
  financeFaturaInvoicingTotalPageState,
} from "../../../atoms/finance";
import FinanceBakiyeIadeFiles from "./financeBakiyeIade/FinanceBakiyeIadeFiles";
import FinanceFaturaInvoicingTable from "./financeFaturaInvoicing/FinanceFaturaInvoicingTable";
import FaturaKonsimentoModal from "./financeFaturaInvoicing/FaturaKonsimentoModal";
import FaturaFileModal from "./financeFaturaInvoicing/FaturaFileModal";
import FaturaInvoicingSearch from "./financeFaturaInvoicing/FaturaInvoicingSearch";
import Errors from "../../../utils/Errors";
import NotificationBar from "../sidebar/NotificationBar";
import FaturaInvoicingOptions from "./financeFaturaInvoicing/FaturaInvoicingOptions";
import FaturaInvoicingModal from "./financeFaturaInvoicing/FaturaInvoicingModal";
import FaturaInvoicingSingleModal from "./financeFaturaInvoicing/FaturaInvoicingSingleModal";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const FinanceFaturaInvoicing = () => {
  DynamicTitle("Finance Kesme - Admin");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const drawerOpen = useRecoilValue(drawerOpenState);

  const [loading, setLoading] = useState(false);

  const navUnder = {
    paddingLeft: drawerOpen ? "264px" : 0,
    transition: "all 150ms linear",
    backgroundColor: "var(--lighterBg)",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  };

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  // Sayfa Fonksiyonları
  const [financeFaturaInvoicingData, setfinanceFaturaInvoicingData] = useRecoilState(financeFaturaInvoicingDataState);
  const [financeFaturaInvoicingTotalPage, setfinanceFaturaInvoicingTotalPage] = useRecoilState(
    financeFaturaInvoicingTotalPageState
  );
  const [financeFaturaInvoicingCurrentPage, setfinanceFaturaInvoicingCurrentPage] = useRecoilState(
    financeFaturaInvoicingCurrentPageState
  );

  const getData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`invoice/list/billings`, {
        params: {
          limit: 500,
          page: 1,
          order_direction: "DESC",
        },
      })
      .then((res) => {
        setfinanceFaturaInvoicingData(res.data.data);
        setfinanceFaturaInvoicingTotalPage(res.data.totalPage);
        setfinanceFaturaInvoicingCurrentPage(res.data.currentPage);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [setfinanceFaturaInvoicingData, setfinanceFaturaInvoicingTotalPage, setfinanceFaturaInvoicingCurrentPage]);

  const handlePageChange = useCallback(
    async (e, page) => {
      setLoading(true);
      await axios
        .get(`invoice/list/billings`, {
          params: {
            limit: 500,
            page: page,
            order_direction: "DESC",
          },
        })
        .then((res) => {
          setfinanceFaturaInvoicingData(res.data.data);
          setfinanceFaturaInvoicingTotalPage(res.data.totalPage);
          setfinanceFaturaInvoicingCurrentPage(res.data.currentPage);
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    },
    [setfinanceFaturaInvoicingData, setfinanceFaturaInvoicingTotalPage, setfinanceFaturaInvoicingCurrentPage]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <ThemeProvider theme={theme}>
      <NotificationBar />
      <section
        className="my-wallet"
        style={{ paddingLeft: drawerOpen ? 0 : "64px", transition: "all 150ms linear" }}
      >
        <NavBar />
        <ToastContainer />
        <FinanceBakiyeIadeFiles />
        <FaturaKonsimentoModal />
        <FaturaFileModal />
        <FaturaInvoicingModal />
        <FaturaInvoicingSingleModal />
        <Box className="nav-under" style={navUnder}>
          <div>
            <ReceiptLongOutlinedIcon /> Faturalar
          </div>
        </Box>
        <Box className="my-wallet-wrapper" style={WrapperStyle()}>
          <FinanceNav />
          <animated.div ref={ref} style={springs}>
            <Box className="my-wallet-container">
              {loading ? (
                <Box
                  sx={{
                    height: "500px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img className="loading-logo" src={miniLogo} alt="" />
                </Box>
              ) : (
                <>
                  <FaturaInvoicingSearch />
                  <FaturaInvoicingOptions />
                  {financeFaturaInvoicingTotalPage > 1 && (
                    <Pagination
                      showFirstButton
                      showLastButton
                      size="small"
                      count={financeFaturaInvoicingTotalPage}
                      page={financeFaturaInvoicingCurrentPage}
                      onChange={handlePageChange}
                    />
                  )}
                  <FinanceFaturaInvoicingTable />
                  {financeFaturaInvoicingTotalPage > 1 && (
                    <Pagination
                      showFirstButton
                      showLastButton
                      size="small"
                      count={financeFaturaInvoicingTotalPage}
                      page={financeFaturaInvoicingCurrentPage}
                      onChange={handlePageChange}
                    />
                  )}
                </>
              )}
            </Box>
          </animated.div>
        </Box>
      </section>
    </ThemeProvider>
  );
};

export default FinanceFaturaInvoicing;
