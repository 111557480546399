import "../../../style/sidebar/Notification.css";
import "../../../style/sidebar/Navbar.css";
import axios from "../../../api/axios";
import { isBillAddressExistState } from "../../../atoms/profileSettings";
import Errors from "../../../utils/Errors";
import NavDrawerHeader from "../navbar/NavDrawerHeader";
import ProfileDetails from "../navbar/ProfileDetails";
import NavLinks from "../navbar/NavLinks";
import {
  drawerOpenState,
  notificationOpenState,
} from "../../../atoms/viewPadding";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useRecoilState } from "recoil";
import { Badge, Tooltip } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AdminNotificationList from "./AdminNotificationList";

const drawerWidth = 215;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  right: 0,
  left: "unset",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  right: 0,
  left: "unset",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "notificationOpen",
})(({ theme, notificationOpen }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(notificationOpen && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!notificationOpen && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// **** Menu ****

const menuWidth = 265;

const openedMixinMenu = (theme) => ({
  width: menuWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixinMenu = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "0",
  justifyContent: "space-between",
});

const DrawerMenu = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "drawerOpen",
})(({ theme, drawerOpen }) => ({
  width: menuWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(drawerOpen && {
    ...openedMixinMenu(theme),
    "& .MuiDrawer-paper": openedMixinMenu(theme),
  }),
  ...(!drawerOpen && {
    ...closedMixinMenu(theme),
    "& .MuiDrawer-paper": closedMixinMenu(theme),
  }),
}));

const NotificationBar = () => {
  const navigate = useNavigate();

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [notificationOpen, setNotificationOpen] = useRecoilState(
    notificationOpenState
  );
  const [drawerOpen, setDrawerOpen] = useRecoilState(drawerOpenState);

  const [, setIsBillAddressExist] = useRecoilState(isBillAddressExistState);

  const [isNotificationGet, setIsNotificationGet] = useState(false);

  const [not, setNot] = useState([]);

  const handleDrawerOpen = () => {
    setNotificationOpen(true);
  };

  const handleDrawerClose = () => {
    setNotificationOpen(false);
  };

  const handleMenuOpen = () => {
    setDrawerOpen(false);
  };

  const handleMenuClose = () => {
    setDrawerOpen(true);
  };

  const getNotification = useCallback(async () => {
    await axios
      .get("notification/show-user-notifications")
      .then((response) => {
        if (response.data.data.length === 0) {
          setNotificationOpen(false);
        }
        setNot(response.data.data);
        response.data.data.map(
          (billAddress) => billAddress.text === "Fatura Adresi Eksik."
        )
          ? setIsBillAddressExist(false)
          : setIsBillAddressExist(true);
      })
      .catch((error) => Errors(error));
  }, [setIsBillAddressExist, setNotificationOpen]);

  useEffect(() => {
    if (isNotificationGet === false) {
      getNotification();
      setIsNotificationGet(true);
    }
  }, [isNotificationGet, getNotification]);

  return (
    <div className="notification">
      <div className="drawer-arrow-wrapper admin-wrapper">
        <div className="drawer-arrow-contain">
          <div className="drawer-left">
          <ToastContainer />
            {drawerOpen ? (
              <IconButton className="menu-arrow" onClick={handleMenuOpen}>
                <ChevronLeftIcon />
              </IconButton>
            ) : (
              <IconButton className="menu-arrow" onClick={handleMenuClose}>
                <MenuOutlinedIcon />
              </IconButton>
            )}
            <img
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/dashboard");
              }}
              src="/images/home/logo.svg"
              alt="iyziShip"
            />
          </div>
          <div className="drawer-right">
            <Tooltip title={"Bildirimler"} placement="bottom">
              {notificationOpen ? (
                <IconButton
                  className="drawer-arrow"
                  onClick={handleDrawerClose}
                >
                  <ChevronRightIcon />
                </IconButton>
              ) : (
                <IconButton className="drawer-arrow" onClick={handleDrawerOpen}>
                  <Badge
                    badgeContent={not.length}
                    overlap="circular"
                    color="primary"
                  >
                    <NotificationsOutlinedIcon color="action" />
                  </Badge>
                </IconButton>
              )}
            </Tooltip>
          </div>
        </div>
      </div>
      <DrawerMenu
        className="navbar-drawer"
        variant="permanent"
        drawerOpen={drawerOpen}
      >
        <NavDrawerHeader />
        <ProfileDetails />
        <NavLinks />
      </DrawerMenu>
      <Drawer
        className="notification-drawer"
        variant="permanent"
        notificationOpen={notificationOpen}
      >
        <div
          style={{ gap: "var(--gap)", padding: "10px" }}
          className="notifications"
        >
          {not.length > 0 ? (
            not?.map((e, key) => (
              <AdminNotificationList
                key={key}
                notification={e}
                not={not}
                setNot={setNot}
              />
              
            ))
          ) : (
            <div
              style={{
                marginTop: "10px",
                whiteSpace: notificationOpen ? "break-spaces" : "inherit",
                backgroundColor: "var(--btnBgBlue)",
                color: "var(--lighterBg)",
                boxShadow: "var(--shadow)",
                borderRadius: "var(--buttonBorderRadius)",
                padding: "5px",
              }}
            >
              Bildiriminiz Bulunmamaktadır.
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default NotificationBar;
