import React from "react";
import "../../style/dowloadpdfview/dowloadPdfView.css";
import DescriptionIcon from "@mui/icons-material/Description";
import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "@mui/material";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const DowloadPdfView = ({ sendFiles, handleSetState }) => {
  const handleDelete = (id) => {
    handleSetState(id);
  };

  return (
    <div className="dowload-pdf-view">
      <TransitionGroup>
        {sendFiles.map((file) => (
          <CSSTransition key={file.id} classNames="file-item" timeout={300}>
            <div className="file-item">
              <div className="left-icon">
                <DescriptionIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
              </div>
              <div className="file-text-container">
                <div className="file-text">{file.file.name}</div>
              </div>
              <Button
                sx={{
                  minWidth: "33px",
                  width: "33px",
                  marginRight: "10px",
                  borderRadius: "50%",
                }}
                onClick={() => handleDelete(file.id)}
              >
                <ClearIcon sx={{ color: "var(--toastify-color-error)" }} />
              </Button>
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
};

export default DowloadPdfView;
