import { Box } from "@mui/material";
import { DynamicTitle } from "../../utils/DynamicTitle";
import NotificationBar from "../components/sidebar/NotificationBar";
import NavBar from "../components/sidebar/NavBar";
import WrapperStyle from "../../utils/WrapperStyle";
import { useCookies } from "react-cookie";
import axios from "../../api/axios";

const Dashboard = () => {
  DynamicTitle("Dashboard - Admin");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  return (
    <section className="dashboard">
      <NavBar />
      <NotificationBar />
      <Box style={WrapperStyle()} className="dashboard-wrapper">
        Admin Dashboard
      </Box>
    </section>
  );
};

export default Dashboard;
