import "../../../style/operation/Operation.css";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useInView, animated } from "@react-spring/web";
import axios from "../../../api/axios";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import NavBar from "../sidebar/NavBar";
import { ToastContainer } from "react-toastify";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import { useNavigate } from "react-router-dom";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import WrapperStyle from "../../../utils/WrapperStyle";
import OperationMeasureControlResult from "./operationMeasureControl/OperationMeasureControlResult";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const OperationMeasureControl = () => {
  DynamicTitle("Ölçü Kontrolü - Admin");

  const navigate = useNavigate();

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [checkedAwb, setCheckedAwb] = useState(true);
  const [checkedHawb, setCheckedHawb] = useState(false);

  const [awb, setAwb] = useState("");
  const [hawb, setHawb] = useState("");
  const [result, setResult] = useState(false);

  const [loading, setLoading] = useState(false);

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const handleCheckedAwb = () => {
    setCheckedAwb(true);
    setCheckedHawb(false);
  };

  const handleCheckedHawb = () => {
    setCheckedAwb(false);
    setCheckedHawb(true);
  };

  const handleAwb = (e) => {
    const value = e.target.value;
    setAwb(value);
  };

  const handleHawb = (e) => {
    const value = e.target.value;
    setHawb(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setResult(true);
  };
  return (
    <ThemeProvider theme={theme}>
      <section className="operation" style={{ paddingLeft: "64px" }}>
        <NavBar />
        <ToastContainer />
        {loading && (
          <Box
            sx={{
              height: "500px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="loading-logo" src={miniLogo} alt="" />
          </Box>
        )}
        {!loading && (
          <Box className="operation-wrapper" style={WrapperStyle()}>
            <animated.div ref={ref} style={springs}>
              <Box className="operation-container">
                <h6
                  style={{
                    color: "var(--priceText)",
                    marginTop: "var(--gap2x)",
                    marginBottom: "var(--gap4x)",
                  }}
                >
                  Depo Yük Girişi
                </h6>
                <div className="operation-security-check">
                  <div style={{ display: "flex", gap: "var(--gap)" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedOutlinedIcon />}
                          checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                          checked={checkedAwb}
                          onChange={handleCheckedAwb}
                        />
                      }
                      label="Domestik Teslim"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedOutlinedIcon />}
                          checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                          checked={checkedHawb}
                          onChange={handleCheckedHawb}
                        />
                      }
                      label="Yük Elden Teslim"
                    />
                  </div>
                  {checkedAwb && (
                    <form
                      style={{ display: "flex", gap: "var(--gap2x)" }}
                      onSubmit={handleSubmit}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        required
                        label="Awb"
                        value={awb}
                        onChange={handleAwb}
                      />
                      <Button type="submit" className="iyzi-button">
                        Ara
                      </Button>
                    </form>
                  )}
                  {checkedHawb && (
                    <form
                      style={{ display: "flex", gap: "var(--gap2x)" }}
                      onSubmit={handleSubmit}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        required
                        label="Hawb"
                        value={hawb}
                        onChange={handleHawb}
                      />
                      <Button type="submit" className="iyzi-button">
                        Ara
                      </Button>
                    </form>
                  )}
                </div>
                {result && <OperationMeasureControlResult />}
              </Box>
            </animated.div>
          </Box>
        )}
      </section>
    </ThemeProvider>
  );
};

export default OperationMeasureControl;
