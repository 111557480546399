import React, { useEffect, useCallback, useState } from "react";
import { Box, Button } from "@mui/material";
import { useSetRecoilState } from "recoil";
import { notificationOpenState } from "../../../atoms/viewPadding";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import NotificationBar from "../../components/sidebar/NotificationBar";
import NavBar from "../../components/sidebar/NavBar";
import WrapperStyle from "../../../utils/WrapperStyle";
import { useCookies } from "react-cookie";
import { useDropzone } from "react-dropzone";
import uploadIcon from "../../../img/icons/upload_icon.svg";
import DowloadPdfView from "../../../components/dowloadPdfView/DowloadPdfView";
import Errors from "../../../utils/Errors";
import { toast } from "react-toastify";
import axios from "../../../api/axios";
import DownloadBase64Excel from "../../../utils/DownloadBase64Excel";

const USPS = () => {
  DynamicTitle("USPS - Admin");
  const setNotificationOpen = useSetRecoilState(notificationOpenState);

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [parsedPDF, setParsedPDF] = useState({});
  const [sendFiles, setSendFiles] = useState([]);

  const handleDrop = useCallback((acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      id: Math.random().toString(),
      maxSize: 2048,
    }));
    setSendFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }, []);

  const handleSetState = useCallback((id) => {
    setSendFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
  });

  const parsePDF = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post("document/parse-pdf", formData);

      setParsedPDF(response.data.data);
    } catch (error) {
      console.error("PDF parsing failed:", error);
    }
  };

  const handleGetUspsReport = () => {
    axios
      .post(`parcel/operation/usps-report`)
      .then((response) => {
        toast.success(response.data.message);
        DownloadBase64Excel(
          response.data.base64,
          "Upsp",
          "Raporu",
          "",
          ""
        );
      })
      .catch((error) => {
        Errors(error);
      });
  };

  const handleGetRoyalMailReport = () => {
    axios
      .post(`parcel/operation/royal-mail-report`)
      .then((response) => {
        toast.success(response.data.message);
        DownloadBase64Excel(
          response.data.base64,
          "Royal Mail",
          "Raporu",
          "",
          ""
        );
      })
      .catch((error) => {
        Errors(error);
      });
  };

  useEffect(() => {
    setNotificationOpen(true);
  }, [setNotificationOpen]);

  const downloadPDF = (fileName, base64Data) => {
    const linkSource = `data:application/pdf;base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    const fileNameFormatted = fileName.replace(/\s+/g, "_").toLowerCase();
    downloadLink.href = linkSource;
    downloadLink.download = `${fileNameFormatted}.pdf`;
    downloadLink.click();
  };

  return (
    <section className="dashboard">
      <NavBar />
      <NotificationBar />
      <Box style={WrapperStyle()} className="dashboard-wrapper">
        Raporlar
        <br />
        <br />
        <Box
          sx={{
            width: "50%",
            marginBottom: "2rem",
          }}
          className="dropzone-wrapper"
        >
          Dosya parçalamak için PDF dosyasını yükleyin
          <Box className="dropzone">
            <div className="dropzone-area" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="dropzone-area-text">
                <img src={uploadIcon} alt="Upload Icon" />
                <span className="dropzone-area-text-headline">
                  Dosyayı sürükleyip bırakın veya{" "}
                  <span style={{ color: "var(--info)" }}>Gözat</span>
                </span>
                <span className="dropzone-area-text-sub">
                  Desteklenen Formatlar: DOC, DOCX, XLSX, PDF, TXT, CSV, PNG, JPG, JPEG
                </span>
              </div>
            </div>
          </Box>
          <DowloadPdfView sendFiles={sendFiles} handleSetState={handleSetState} />
          {sendFiles.length > 0 && (
            <Button
              sx={{
                marginTop: "1rem",
              }}
              variant="contained"
              onClick={() => parsePDF(sendFiles[0].file)}
            >
              Gönder
            </Button>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",

            flexDirection: "column",
          }}
        >
          <p>İndirilebilir PDF Dosyaları</p>
          <br />
          <br />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {Object.keys(parsedPDF).map((fileName) => (
              <Box
                key={fileName}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  marginBottom: "0.5rem",
                  width: "300px",
                }}
              >
                <iframe
                  src={`data:application/pdf;base64,${parsedPDF[fileName]}`}
                  style={{ width: "100%", height: "600px", marginRight: "1rem" }}
                  title="iframe"
                />
                <Box>
                  <p>{fileName}</p>
                  <Button
                    variant="contained"
                    onClick={() => downloadPDF(fileName, parsedPDF[fileName])}
                  >
                    İndir
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
          <Button
            sx={{
              marginTop: "1rem",
              width: "250px"
            }}
            variant="contained"
            onClick={handleGetUspsReport}
          >
            USPS Raporu Çek
          </Button>
          <Button
            sx={{
              marginTop: "1rem",
              width: "250px"
            }}
            variant="contained"
            onClick={handleGetRoyalMailReport}
          >
            Royal Mail Raporu Çek
          </Button>
        </Box>
      </Box>
    </section>
  );
};

export default USPS;
