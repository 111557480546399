import { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import LocalPrice from "../../../../utils/LocalPrice";
import Currency from "../../../../utils/CurrencySymbol";
import { Divider } from "@mui/material";
import { FormatReturnFieldWithDotsAndComma } from "../../../../utils/FormatFieldReturn";

const DigitalMarketingExpenses = ({ data, currency, compareDesign }) => {
  const ref = useRef(null);

  useEffect(() => {
    const createChart = (ref, data) => {
      if (ref.current) {
        const chartData = {
          labels: data?.labels,
          datasets: data?.datasets,
        };

        const chartOptions = {
          aspectRatio: 4,
          scales: {
            x: {
              type: "category",
            },
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 1,
              },
            },
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
          },
          plugins: {
            legend: {
              display: true,
              position: "bottom",
            },
          },
        };

        if (ref.current.chart) {
          ref.current.chart.destroy();
        }

        const newChart = new Chart(ref.current, {
          data: chartData,
          options: chartOptions,
        });

        ref.current.chart = newChart;
      }
    };

    createChart(ref, data);
  }, [data]);

  return (
    <div
      style={{
        backgroundColor: "var(--lighterBg)",
        boxShadow: "var(--shadow)",
        borderRadius: "var(--wrapperBorderRadius)",
        padding: "20px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between", gap: "var(--gap2x)" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "var(--gap)" }}>
          <h6 style={{ color: "var(--priceText)" }}>Dijital Pazarlama Giderleri</h6>
        </div>
        {compareDesign ? (
          <div style={{ display: "flex", alignItems: "center", gap: "var(--gap)" }}>
            <div style={{ fontSize: "30px" }}>
              <div style={{ color: "var(--inputBorderColor)" }}>Fark Oranı</div>
              <div style={{ color: "var(--shipmentGreen)", fontSize: "inherit" }}>
                <span style={{ fontSize: "inherit" }}>{FormatReturnFieldWithDotsAndComma(80)}</span>
                <span style={{ fontSize: "16px", color: "var(--priceText)" }}>%</span>
              </div>
            </div>
            <Divider
              sx={{ height: "100%", width: "1px", backgroundColor: "var(--inputBorderColor)" }}
            />
            <div style={{ fontSize: "30px" }}>
              <div style={{ color: "var(--inputBorderColor)" }}>Eski Dönem Toplam</div>
              <div style={{ color: "var(--btnBgBlue)", fontSize: "inherit" }}>
                <span style={{ fontSize: "inherit" }}>{LocalPrice(1000)}</span>
                <span style={{ fontSize: "16px", color: "var(--priceText)" }}>
                  {Currency(currency ? "USD" : "TRY")}
                </span>
              </div>
            </div>
            <Divider
              sx={{ height: "100%", width: "1px", backgroundColor: "var(--inputBorderColor)" }}
            />
            <div style={{ fontSize: "30px" }}>
              <div style={{ color: "var(--inputBorderColor)" }}>Yeni Dönem Toplam</div>
              <div style={{ color: "var(--btnBgBlue)", fontSize: "inherit" }}>
                <span style={{ fontSize: "inherit" }}>{LocalPrice(2000)}</span>
                <span style={{ fontSize: "16px", color: "var(--priceText)" }}>
                  {Currency(currency ? "USD" : "TRY")}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <span style={{ fontSize: "24px", color: "var(--btnBgBlue)" }}>
            {LocalPrice(data?.average_count)}&nbsp;
            <span style={{ fontSize: "16px", color: "var(--priceText)" }}>
              {Currency(currency ? "USD" : "TRY")}
            </span>
          </span>
        )}
      </div>
      <canvas ref={ref} />
    </div>
  );
};

export default DigitalMarketingExpenses;
