import { useCookies } from "react-cookie";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoutes = () => {
  const [token] = useCookies(["jwt"]);
  const user = JSON.parse(localStorage.getItem("user"));
  const role = user?.user?.user_role.name;

  if (
    token &&
    (role === "DEV" ||
      role === "BOS" ||
      role === "ADM" ||
      role === "FIN" ||
      role === "SPO" ||
      role === "OPS" ||
      role === "COU" ||
      role === "SPC" ||
      role === "CSE")
  ) {
    return <Outlet />;
  } else {
    localStorage.removeItem("user");
    document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    return <Navigate to="/" />;
  }
};

export default PrivateRoutes;
