import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoutesAdmin from "./utils/PrivateRoutesAdmin";
import Home from "./admin/view/Login";
import Dashboard from "./admin/view/Dashboard";
import MyShipments from "./admin/view/MyShipments";
import ReturnShipment from "./admin/view/ReturnShipment";
import AnnouncementAdmin from "./admin/view/AnnouncementAdmin";
import Logs from "./admin/view/Logs";
import CourierRegistrationAdmin from "./admin/view/CourierRegistrationAdmin";
import SupportAdmin from "./admin/view/SupportAdmin";
import SupportRequestAdmin from "./admin/components/support/SupportRequestAdmin";
import Members from "./admin/view/Members";
import OperationFreightFrontDesk from "./admin/components/operations/OperationFreightFrontDesk";
import OperationCargoEntranceWarehouse from "./admin/components/operations/OperationCargoEntranceWarehouse";
import OperationSecurityCheck from "./admin/components/operations/OperationSecurityCheck";
import OperationMeasureControl from "./admin/components/operations/OperationMeasureControl";
import Finance from "./admin/view/Finance";
import FinanceGonderiIade from "./admin/components/operations/FinanceGonderiIade";
import FinanceBakiyeIade from "./admin/components/finance/FinanceBakiyeIade";
import FinanceHavale from "./admin/components/finance/FinanceHavale";
import FinanceIban from "./admin/components/finance/FinanceIban";
import FinanceTazminIade from "./admin/components/finance/FinanceTazminIade";
import FinanceFatura from "./admin/components/finance/FinanceFatura";
import FinanceFaturaInvoicing from "./admin/components/finance/FinanceFaturaInvoicing";
import FinanceBekleyenOdemeler from "./admin/components/finance/FinanceBekleyenOdemeler";
import PriceControlScreen from "./admin/components/operations/PriceControlScreen";
import BeevisionPassingLoadScreen from "./admin/components/operations/BeevisionPassingLoadScreen";
import BeeVisionSettings from "./admin/components/operations/BeeVisionSettings";
import GetBarcode from "./admin/components/operations/GetBarcode";
import ExchangeRates from "./admin/view/ExchangeRates";
import PriceChecks from "./admin/view/PriceChecks";
import SystemSettings from "./admin/view/SystemSettings";
import USPS from "./admin/components/operations/USPS";
import ChartReports from "./admin/components/reports/ChartReports";
import ProfitReports from "./admin/components/reports/ProfitReports";
import NewMemberReports from "./admin/components/reports/NewMemberReports";
import LostMemberReports from "./admin/components/reports/LostMemberReports";
import SupportReports from "./admin/components/reports/SupportReports";
import BudgetReports from "./admin/components/reports/BudjetReports";
import ReturnReports from "./admin/components/reports/ReturnReports";
import SalesReport from "./admin/components/operations/SalesReports";
import AwbTransfer from "./admin/components/operations/AwbTransfer";
import DomesticInfoChangesScreen from "./admin/components/operations/DomesticInfoChangesScreen";

function App() {
  useEffect(() => {
    window.removeEventListener("message", (event) => event.data);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route element={<PrivateRoutesAdmin />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/reports/charts" element={<ChartReports />} />
          <Route exact path="/reports/profit" element={<ProfitReports />} />
          <Route exact path="/reports/new-member" element={<NewMemberReports />} />
          <Route exact path="/reports/lost-member" element={<LostMemberReports />} />
          <Route exact path="/reports/support" element={<SupportReports />} />
          <Route exact path="/reports/budget" element={<BudgetReports />} />
          <Route exact path="/reports/return" element={<ReturnReports />} />
          <Route exact path="/my-shipment" element={<MyShipments />} />
          <Route exact path="/returns" element={<ReturnShipment />} />
          <Route exact path="/announcement" element={<AnnouncementAdmin />} />

          <Route exact path="/system/log" element={<Logs />} />
          <Route exact path="/system/exchange-rates" element={<ExchangeRates />} />
          <Route exact path="/system/price-check" element={<PriceChecks />} />
          <Route exact path="/system/system-settings" element={<SystemSettings />} />

          <Route exact path="/currier-record" element={<CourierRegistrationAdmin />} />
          <Route exact path="/support" element={<SupportAdmin />} />
          <Route exact path="/support/ticket" element={<SupportRequestAdmin />} />
          <Route exact path="/members" element={<Members />} />
          <Route
            exact
            path="/operation-progress/freight-front-desk"
            element={<OperationFreightFrontDesk />}
          />
          <Route
            exact
            path="/operation-progress/cargo-entrance-warehouse"
            element={<OperationCargoEntranceWarehouse />}
          />
          <Route
            exact
            path="/operation-progress/security-check"
            element={<OperationSecurityCheck />}
          />
          <Route exact path="/operation-progress/get-barcode" element={<GetBarcode />} />
          <Route
            exact
            path="/operation-progress/measure-control"
            element={<OperationMeasureControl />}
          />
          <Route
            exact
            path="/operation-progress/shipment-refund-request"
            element={<FinanceGonderiIade />}
          />
          <Route
            exact
            path="/operation-progress/sales-report"
            element={<SalesReport />}
          />
          <Route
            exact
            path="/operation-progress/get-set-status"
            element={<OperationSecurityCheck />}
          />
          <Route
            exact
            path="/operation-progress/beevision-settings"
            element={<BeeVisionSettings />}
          />
          <Route
            exact
            path="/operation-progress/price-control-screen"
            element={<PriceControlScreen />}
          />
          <Route
            exact
            path="/operation-progress/awb-transfer"
            element={<AwbTransfer />}
          />
          <Route
            exact
            path="/operation-progress/beevision-passing-load-screen"
            element={<BeevisionPassingLoadScreen />}
          />
          <Route
            exact
            path="/operation-progress/domestic-info-changes-screen"
            element={<DomesticInfoChangesScreen />}
          />
          <Route exact path="/operation-progress/usps" element={<USPS />} />
          <Route exact path="/finance" element={<Finance />} />
          <Route exact path="/finance/balance-refund-request" element={<FinanceBakiyeIade />} />
          <Route exact path="/finance/remittance-requests" element={<FinanceHavale />} />
          <Route exact path="/finance/iban-info" element={<FinanceIban />} />
          <Route exact path="/finance/compensation-proceedings" element={<FinanceTazminIade />} />
          <Route exact path="/finance/my-bills" element={<FinanceFatura />} />
          <Route exact path="/finance/my-bills-invoicing" element={<FinanceFaturaInvoicing />} />
          <Route exact path="/finance/extract" element={<FinanceBekleyenOdemeler />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
