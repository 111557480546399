import React from "react";
import { TableRow, TableCell } from "@mui/material";

const FinanceIbanTableBody = ({ item }) => {
  return (
    <TableRow
      sx={{
        ":hover": {
          backgroundColor: "var(--pastelBlue)",
          transition: "all 150ms linear",
        },
      }}
    >
      <TableCell>{item.member_id}</TableCell>
      <TableCell>{item.name} {item.surname}</TableCell>
      <TableCell>{item.iban}</TableCell>
      <TableCell>{item.bank_name}</TableCell>
    </TableRow>
  );
};

export default FinanceIbanTableBody;
