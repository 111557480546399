import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { shipmentCheckPointState } from "../../../../atoms/myShipmentsAdmin";
import { useCallback } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const CheckPointModal = () => {
  const [shipmentCheckPoint, setShipmentCheckPoint] = useRecoilState(
    shipmentCheckPointState
  );

  const handleClose = useCallback(() => {
    setShipmentCheckPoint({ status: false, data: [] });
  }, [setShipmentCheckPoint]);

  return (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={shipmentCheckPoint.status}
      onClose={handleClose}
    >
      <div style={{ outline: 0 }}>
        <div>
          <div
            style={{
              height: 500,
              width: "100%",
              padding: 20,
              maxWidth: 600,
              backgroundColor: "var(--lighterBg)",
              position: "relative",
              borderRadius: "var(--wrapperBorderRadius)",
              outline: 0,
            }}
          >
            <HighlightOffIcon
              sx={{
                position: "absolute",
                right: 20,
                top: 20,
                cursor: "pointer",
                ":hover": {
                  color: "var(--error)",
                  transition: "all 150ms linear",
                },
              }}
              onClick={handleClose}
            />
            <div>
              <p>Check Point</p>
            </div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Değerden Sonra</TableCell>
                    <TableCell>Değerden Önce</TableCell>
                    <TableCell>Oluşturulma Tarihi</TableCell>
                    <TableCell>Alan Adı</TableCell>
                    <TableCell>Oluşturan Kişi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shipmentCheckPoint.data.map((checkpoint, index) => (
                    <TableRow key={index}>
                      <TableCell>{checkpoint.after_value}</TableCell>
                      <TableCell>{checkpoint.before_value}</TableCell>
                      <TableCell>{checkpoint.created_at}</TableCell>
                      <TableCell>Döviz Kuru</TableCell>
                      <TableCell>
                        {checkpoint.get_created_by.name}{" "}
                        {checkpoint.get_created_by.surname}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CheckPointModal;
