import {
  Button,
  CircularProgress,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  supportCaseMessagesState,
  supportCaseModalState,
  caseTextState,
  supportCaseMessagesCheckState,
  openSupportDetailState
} from "../../../../atoms/support";
import { useEffect, useState } from "react";
import axios from "../../../../api/axios";
import Errors from "../../../../utils/Errors";
import { toast } from "react-toastify";
import MessageTime from "../../../../utils/MessageTime";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const SupportCaseMessagesModal = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [closeMessage, setCloseMessage] = useState("");
  const [caseText, setCaseText] = useRecoilState(caseTextState);

  const [supportCaseModal, setSupportCaseModal] = useRecoilState(
    supportCaseModalState
  );
  const [closeCaseModal, setCloseCaseModal] = useState(false);
  const [supportCaseMessages, setSupportCaseMessages] = useRecoilState(
    supportCaseMessagesState
  );

  const [, setOpenSupportDetail] = useRecoilState(
    openSupportDetailState
  );

  const [supportCaseMessagesCheck, setSupportCaseMessagesCheck] = useRecoilState(
    supportCaseMessagesCheckState
  );
  const handleClose = () => {
    setSupportCaseModal(false);
    setCaseText("");
    setMessage("");
  };

  const handleCloseOpenCase = () => {
    setCloseCaseModal(true);
  };

  const handleCloseCase = () => {
    setCloseMessage("");
    setCloseCaseModal(false);
  };

  const handleMessage = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const response = await axios.post(
        `ticket/case/message/add/${supportCaseMessages?.id}`,
        {
          case_type: caseText,
          message: message,
        }
      );

      if (response && response.data && response.data.data) {
        setSupportCaseMessages((prevState) => ({
          ...prevState,
          get_case_messages: Array.isArray(prevState.get_case_messages)
            ? [...prevState.get_case_messages, response.data.data]
            : [response.data.data],
        }));
        setMessage("");
        toast.success("Mesaj Gönderildi.");
        setSupportCaseMessagesCheck(true);
        
      } else {
        toast.error("Mesaj Gönderme Başarısız. Hata: Yanıt Veri Yok");
      }
    } catch (error) {
      toast.error("Mesaj gönderme sırasında bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  const sendCloseCaseRequest = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const response = await axios.post(
        `ticket/case/close/${supportCaseMessages?.id}`,
        {
          message: closeMessage,
        }
      );

      if (response.status === 200) {
        toast.success(response.message, { autoClose: 500 });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else if (response.status === 400) {
        toast.error(response.message, { autoClose: 500 });
      }
    } catch (error) {
      Errors(error);
    }
  };

  const sortedMessages =
    supportCaseMessages?.get_case_messages &&
    [...supportCaseMessages.get_case_messages].sort(
      (a, b) => parseInt(b.case_type) - parseInt(a.case_type)
    );

  const supplierMessages =
    sortedMessages && sortedMessages.filter((mes) => mes.case_type === "1");

  const customerMessages =
    sortedMessages && sortedMessages.filter((mes) => mes.case_type === "2");

  const adminMessages =
    sortedMessages && sortedMessages.filter((mes) => mes.case_type === "3");

  const SupplierBox = ({ mes }) => (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <div>
        {MessageTime(mes?.created_at)} - {mes?.get_created_by?.name}{" "}
        {mes?.get_created_by.surname}
      </div>
      <div>{mes.message}</div>
      <br />
    </Box>
  );

  const CustomerBox = ({ mes }) => (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <div>
        {MessageTime(mes?.created_at)} - {mes?.get_created_by?.name}{" "}
        {mes?.get_created_by.surname}
      </div>
      <div>{mes.message}</div>
      <br />
    </Box>
  );

  const AdminBox = ({ mes }) => (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <div>
        {MessageTime(mes?.created_at)} - {mes?.get_created_by?.name}{" "}
        {mes?.get_created_by.surname}
      </div>
      <div>{mes.message}</div>
      <br />
    </Box>
  );

  return (
    <Modal
      sx={{ display: "flex", justifyContent: "center", padding: "20px" }}
      open={supportCaseModal}
      onClose={handleClose}
    >
      <form
        onSubmit={handleMessage}
        style={{
          backgroundColor: "var(--lighterBg)",
          maxWidth: "1200px",
          width: "100%",
          borderRadius: "var(--wrapperBorderRadius)",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "var(--gap2x)",
          height: "fit-content",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              padding: "0",
            }}
          >
            {Number(supportCaseMessages?.question_type) === 1
              ? "Adres/İletişim Bilgisi Yetersiz BA"
              : Number(supportCaseMessages?.question_type) === 2
              ? "Alıcı Adresi Yok NH"
              : Number(supportCaseMessages?.question_type) === 3
              ? "Hasar DM"
              : Number(supportCaseMessages?.question_type) === 4
              ? "Kayıp LT"
              : Number(supportCaseMessages?.question_type) === 5
              ? "Adres Değişikliği Taleplerinde AC"
              : Number(supportCaseMessages?.question_type) === 6
              ? "Gümrük Gecikmesi CD"
              : Number(supportCaseMessages?.question_type) === 7
              ? "Evrak Eksiği PW"
              : "Alıcı Kurum ise Adreste Yok CA"}{" "}
            <Button
              sx={{
                color: "red",
              }}
              onClick={handleCloseOpenCase}
            >
              Case Kapat
            </Button>
          </p>
          <Button onClick={handleClose}>
            <CancelOutlinedIcon />
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            gap: "20px",
          }}
        >
          {/* SupplierBox */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <p
                style={{
                  fontSize: "17px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                Tedarikçi
              </p>
            </div>
            <div
              style={{
                overflowY: "scroll",
                height: "500px",
                width: "100%",
              }}
            >
              {supplierMessages &&
                supplierMessages.map((mes) => (
                  <div
                    style={{
                      backgroundColor: "var(--phoneDigitBg)",
                      margin: "10px",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                    key={mes.id}
                  >
                    <SupplierBox mes={mes} />
                  </div>
                ))}
            </div>
          </Box>

          {/* CustomerBox */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <p
                style={{
                  fontSize: "17px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                Müşteri
              </p>
            </div>
            <div
              style={{
                overflowY: "scroll",
                height: "500px",
                width: "100%",
              }}
            >
              {customerMessages &&
                customerMessages.map((mes) => (
                  <div
                    style={{
                      backgroundColor: "var(--phoneDigitBg)",
                      margin: "10px",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                    key={mes.id}
                  >
                    <CustomerBox mes={mes} />
                  </div>
                ))}
            </div>
          </Box>

          {/* AdminBox */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <p
                style={{
                  fontSize: "17px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                Admin
              </p>
            </div>
            <div
              style={{
                overflowY: "scroll",
                height: "500px",
                width: "100%",
              }}
            >
              {adminMessages &&
                adminMessages.map((mes) => (
                  <div
                    style={{
                      backgroundColor: "var(--phoneDigitBg)",
                      margin: "10px",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                    key={mes.id}
                  >
                    <AdminBox mes={mes} />
                  </div>
                ))}
            </div>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <FormControl
            sx={{
              width: "50%",
            }}
            required
            size="small"
            fullWidth
          >
            <InputLabel id="case-text-label">
              Tedarikçi Müşteri Yazışması
            </InputLabel>
            <Select
              labelId="case-text-label"
              id="case-text"
              value={caseText}
              onChange={(e) => setCaseText(e.target.value)}
              label="Tedarikçi Müşteri Yazışması"
            >
              <MenuItem disabled value={0}>
                <em>Seçiniz</em>
              </MenuItem>
              <MenuItem value={1}>Tedarikçi</MenuItem>
              <MenuItem value={2}>Müşteri</MenuItem>
              <MenuItem value={3}>Admin</MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              display: "flex",
              gap: "var(--gap2x)",
              alignItems: "center",
              width: "50%",
            }}
          >
            <TextField
              label="Mesajınızı Yazınız"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              size="small"
              fullWidth
              required
              autoComplete="off"
              multiline
            />
            {loading ? (
              <div
                sx={{
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress
                  sx={{ width: "24px !important", height: "24px !important" }}
                />
              </div>
            ) : (
              <Button
                type="submit"
                sx={{
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "var(--info)",
                  transition: "all 150ms linear",
                  borderRadius: "50%",
                  minWidth: "40px",
                  ":hover": {
                    backgroundColor: "var(--blueBrand)",
                    transition: "all 150ms linear",
                  },
                }}
              >
                <img src="/images/icons/send_icon.svg" alt="Gönder Görseli" />
              </Button>
            )}
          </div>
        </Box>
        <Dialog
          sx={{ zIndex: "9999" }}
          open={closeCaseModal}
          onClose={handleCloseCase}
        >
          <DialogTitle id="alert-dialog-title">Case Kapatma</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Kurye kaydını iptal etmek istiyor musunuz?
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <TextField
              label="Kapatma Nedeni"
              size="small"
              value={closeMessage}
              onChange={(e) => setCloseMessage(e.target.value)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCase}>Vazgeç</Button>
            {loading ? (
              <Box
                sx={{
                  width: "72px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  sx={{ width: "24px !important", height: "24px !important" }}
                />
              </Box>
            ) : (
              <Button onClick={sendCloseCaseRequest}>Onayla</Button>
            )}
          </DialogActions>
        </Dialog>
      </form>
    </Modal>
  );
};

export default SupportCaseMessagesModal;
