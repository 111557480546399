import "../../../../style/support/SupportTazmin.css";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  supportTanzimModalOpenState,
  ticketState,
} from "../../../../atoms/support";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          margin: "20px",
          overflow: "auto",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          display: "flex",
          alignItems: "center",
          gap: "10px",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "14px",
          opacity: 0.8,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "8px !important",
        },
      },
    },
  },
});

const SupportTanzimModal = () => {
  const ticket = useRecoilValue(ticketState);

  const [supportTanzimModalOpen, setSupportTanzimModalOpen] = useRecoilState(
    supportTanzimModalOpenState
  );

  const [loading, setLoading] = useState(false);
  const [navlunArea, setNavlunArea] = useState(false);
  const [tazminOption, setTazminOption] = useState("Tazmin");
  const [tedReceiverTazmin, setTedReceiverTazmin] = useState("");
  const [customerGiveTazmin, setCustomerGiveTazmin] = useState("");
  const [customerNavlunGiveTazmin, setCustomerNavlunGiveTazmin] = useState("");
  const [tedApproveDude, setTedApproveDude] = useState("");
  const [tedNavlunApproveDude, setTedNavlunApproveDude] = useState("");
  const [billNote, setBillNote] = useState("");

  const refNo = ticket?.parcel_ref_no;
  const tedAwbNo = ticket?.get_parcel?.tracking_awb;
  const supplier = ticket?.get_parcel?.get_parcel_supplier?.supplier_name;

  const handleTextChange = (e) => {
    const { value } = e.target;
    const regex = /^[a-zöçşğüıİÖÇŞĞÜ\s]*$/i; // türkçe harfler, boşluklar ve büyük/küçük harfler kabul eder
    if (value === "" || regex.test(value)) {
      if (value.includes("  ")) {
        // eğer iki boşluk üst üste gelirse onu engelle
        return;
      }
      if (e.target.name === "ted-approver") {
        setTedApproveDude(value);
      } else if (e.target.name === "ted-navlun-approver") {
        setTedNavlunApproveDude(value);
      }
    }
  };

  const handleMoneyChange = (e, name) => {
    // Eğer değer virgül içeriyorsa, noktaya çevir
    const value = e.target.value.replace(",", ".");

    // Değer boşsa veya sadece rakamlar, nokta ve virgül içeriyorsa durumu güncelle
    if (value === "" || /^\d*([.,]\d{0,2})?$/.test(value)) {
      if (name === "tedarik-alinan") {
        setTedReceiverTazmin(value);
      } else if (name === "müsteri-verilen") {
        setCustomerGiveTazmin(value);
      } else if (name === "navlun-müsteri-verilen") {
        setCustomerNavlunGiveTazmin(value);
      }
    }
  };

  const handleClose = useCallback(() => {
    setSupportTanzimModalOpen(false);
    setNavlunArea(false);
  }, [setSupportTanzimModalOpen]);

  const sendTazmin = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);

      const tazminData = {
        ticket_id: ticket.id,
        member_id: ticket.member_id,
        compensation_type: tazminOption,
        parcel_ref_no: refNo,
        parcel_tracking_awb: tedAwbNo,
        supplier_name: supplier,
        supplier_compensation_amount: Number(tedReceiverTazmin),
        member_payable_amount: Number(customerGiveTazmin),
        supplier_authorized_name: tedApproveDude,
        invoice_note: billNote,
      };

      const missData = {
        ticket_id: ticket.id,
        member_id: ticket.member_id,
        compensation_type: tazminOption,
        parcel_ref_no: refNo,
        parcel_tracking_awb: tedAwbNo,
        supplier_name: supplier,
        supplier_compensation_amount: Number(tedReceiverTazmin),
        member_payable_amount: Number(customerGiveTazmin),
        supplier_authorized_name: tedApproveDude,
        invoice_note: billNote,
        member_payable_amount_for_navlun: Number(
          customerNavlunGiveTazmin
        ).toFixed(2),
        supplier_authorized_name_for_navlun: tedNavlunApproveDude,
      };

      const navlunData = {
        ticket_id: ticket.id,
        member_id: ticket.member_id,
        compensation_type: tazminOption,
        parcel_ref_no: refNo,
        parcel_tracking_awb: tedAwbNo,
        supplier_name: supplier,
        member_payable_amount: Number(customerNavlunGiveTazmin).toFixed(2),
        supplier_authorized_name: tedNavlunApproveDude,
        invoice_note: billNote,
      };

      const dataOption = () => {
        if (tazminOption === "Tazmin") {
          return tazminData;
        } else if (tazminOption === "Mal Kayıp") {
          return missData;
        } else if (tazminOption === "Navlun") {
          return navlunData;
        }
      };

      axios
        .post(`refund-compensation/add`, dataOption())
        .then((response) => {
          toast.success(response.data.message, { autoClose: 2000 });
          setLoading(false);
          setSupportTanzimModalOpen(false);
        });
    },
    [
      setSupportTanzimModalOpen,
      tazminOption,
      ticket,
      refNo,
      tedAwbNo,
      supplier,
      tedReceiverTazmin,
      customerGiveTazmin,
      tedApproveDude,
      billNote,
      customerNavlunGiveTazmin,
      tedNavlunApproveDude,
    ]
  );

  useEffect(() => {
    if (supportTanzimModalOpen) {
      if (
        Number(ticket?.get_parcel?.get_parcel_price_informations[0]?.paid) === 1
      ) {
        setCustomerNavlunGiveTazmin(
          ticket?.get_parcel?.get_parcel_price_informations?.filter(
            (e) => Number(e.paid)
          )
          .reduce(
            (acc, val) => acc + Number(val.paid_price_try),
            0
          )
        );
      }
      if (tazminOption === "Mal Kayıp" || tazminOption === "Navlun") {
        setNavlunArea(true);
      } else {
        setNavlunArea(false);
      }
    }
  }, [supportTanzimModalOpen, tazminOption, ticket]);

  return (
    <ThemeProvider theme={theme}>
      <Modal open={supportTanzimModalOpen} onClose={handleClose}>
        <form
          onSubmit={sendTazmin}
          style={{
            maxWidth: "1100px",
            width: "100%",
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            outline: 0,
            padding: "20px",
            position: "relative",
            margin: "auto",
            overflowY: "auto",
          }}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              color: "var(--priceText)",
              cursor: "pointer",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleClose}
          />
          <div className="support-tazmin-modal">
            <div className="tazmin-triple disabled">
              <TextField
                autoComplete="off"
                size="small"
                label="Konşimento No"
                placeholder="TR2131231US"
                defaultValue={refNo}
                disabled
              />
              <TextField
                autoComplete="off"
                size="small"
                label="Tedarikçi AWB No"
                placeholder="612366416712641"
                defaultValue={tedAwbNo}
                disabled
              />
              <TextField
                autoComplete="off"
                size="small"
                defaultValue={supplier}
                disabled
                label="Tedarikçi Adı"
                placeholder="Fedex"
              />
            </div>
            <div className="tazmin-headline">
              <h5>{tazminOption === "Navlun" ? "Navlun" : "Tazmin"} İadesi</h5>
              <FormControl size="small" sx={{ width: 120 }}>
                <InputLabel id="tazmin-select-label">
                  Tazmin Seçeneği
                </InputLabel>
                <Select
                  labelId="tazmin-select-label"
                  id="tazmin-select"
                  value={tazminOption}
                  label="Tazmin Seçeneği"
                  onChange={(e) => setTazminOption(e.target.value)}
                >
                  <MenuItem value={"Tazmin"}>Tazmin</MenuItem>
                  <MenuItem value={"Mal Kayıp"}>Mal Kayıp</MenuItem>
                  <MenuItem value={"Navlun"}>Navlun</MenuItem>
                </Select>
              </FormControl>
            </div>
            {tazminOption !== "Navlun" && (
              <>
                <div className="tazmin-triple">
                  <TextField
                    autoComplete="off"
                    size="small"
                    label="Tedarikçiden Alınan Tazmin Tutarı"
                    value={tedReceiverTazmin}
                    onChange={(e) => handleMoneyChange(e, "tedarik-alinan")}
                    placeholder="250.00"
                  />
                  <TextField
                    autoComplete="off"
                    size="small"
                    label="Müşteriye Verilecek Tazmin Tutarı"
                    value={customerGiveTazmin}
                    onChange={(e) => handleMoneyChange(e, "müsteri-verilen")}
                  />
                  <TextField
                    autoComplete="off"
                    size="small"
                    value={tedApproveDude}
                    name="ted-approver"
                    onChange={handleTextChange}
                    label="Tedarikçide Onay Veren Kişi"
                  />
                </div>
                <TextField
                  autoComplete="off"
                  fullWidth
                  size="small"
                  value={billNote}
                  onChange={(e) => setBillNote(e.target.value.toUpperCase())}
                  label="Fatura Notları"
                  placeholder="Tedarikçi AWB No - Tedarikçinin Verdiği Kod - Tedarikçide onay veren kişi"
                />
              </>
            )}
            {navlunArea && (
              <div className="tazmin-navlun">
                {tazminOption !== "Navlun" && <h5>Navlun İadesi</h5>}
                <div className="tazmin-triple">
                  <TextField
                    autoComplete="off"
                    size="small"
                    label="Müşteriye Verilecek Navlun Tutarı"
                    value={customerNavlunGiveTazmin}
                    onChange={(e) =>
                      handleMoneyChange(e, "navlun-müsteri-verilen")
                    }
                  />
                  <TextField
                    autoComplete="off"
                    size="small"
                    label="Tedarikçide Onay Veren Kişi"
                    value={tedNavlunApproveDude}
                    name="ted-navlun-approver"
                    onChange={handleTextChange}
                  />
                  <TextField
                    autoComplete="off"
                    fullWidth
                    size="small"
                    value={billNote}
                    onChange={(e) => setBillNote(e.target.value.toUpperCase())}
                    label="Fatura Notları"
                    placeholder="Tedarikçi AWB No - Tedarikçinin Verdiği Kod - Tedarikçide onay veren kişi"
                  />
                </div>
                <div className="tazmin-triple disabled">
                  <TextField
                    autoComplete="off"
                    size="small"
                    label="Tedarikçi Fatura No"
                    disabled
                  />
                  <TextField
                    autoComplete="off"
                    size="small"
                    label="Tedarikçi Fatura Tarihi"
                    disabled
                  />
                  <TextField
                    autoComplete="off"
                    size="small"
                    label="Tedarikçiden Alınan Navlun Tutarı"
                    disabled
                  />
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "var(--gap2x)",
              flexWrap: "wrap",
              marginTop: "var(--gap2x)",
            }}
          >
            <Button type="button" className="iyzi-button" onClick={handleClose}>
              İptal
            </Button>
            {loading ? (
              <div
                style={{
                  width: "68px",
                  height: "33px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress
                  sx={{
                    width: "24px!important",
                    height: "24px!important",
                  }}
                />
              </div>
            ) : (
              <Button type="submit" className="iyzi-button">
                Tamamla
              </Button>
            )}
          </div>
        </form>
      </Modal>
    </ThemeProvider>
  );
};

export default SupportTanzimModal;
