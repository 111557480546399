import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { useCallback, useState } from "react";
import axios from "../../../../api/axios";
import getSuperAdminRoleCheck from "../../../../utils/getSuperAdminRoleCheck";
import Errors from "../../../../utils/Errors";
import { useRecoilState } from "recoil";
import { memberListState } from "../../../../atoms/members";
import { toast } from "react-toastify";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditOffOutlinedIcon from "@mui/icons-material/EditOffOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import memberCustomerGroups from "../../../../json/memberCustomerGroups.json";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: 20,
          overflowY: "auto",
        },
      },
    },
  },
});

const MemberDocumentEditProfitMargin = ({ member }) => {
  const [memberList, setMemberList] = useRecoilState(memberListState);

  const [profitIsActive, setProfitIsActive] = useState(Number(member?.system_default_profit) === 1);
  const [profitType, setProfitType] = useState(member?.profit_type);
  const [discountAmount, setDiscountAmount] = useState(Number(member?.profit_amount));
  const [customerGroup, setCustomerGroup] = useState(member?.customer_group);
  const [profitTypeList, setProfitTypeList] = useState(member?.profit_type_list);
  const [systemDefaultLoading, setSystemDefaultLoading] = useState(false);

  const [profitEdit, setProfitEdit] = useState(false);

  const [loading, setLoading] = useState(false);

  const isSuperAdminRole = getSuperAdminRoleCheck();

  const handleDecimalChange = (e) => {
    let value = e.target.value.replace(/,/g, ".");
    const name = e.target.name;

    value = value.replace(/[^0-9.]|(?<=\..*)\.|^0{2,}(?=[.])/g, "");
    const regex = /^\d+(\.\d{0,2})?$/;

    if (
      value.startsWith(".") ||
      (!regex.test(value) && value !== "") ||
      (name !== "gross" && value.startsWith("0.") && value.length === 1)
    ) {
      return;
    } else {
      setDiscountAmount(value);
    }
  };

  const label =
    profitType === "amt"
      ? "Maliyet + Miktar"
      : profitType === "pct"
        ? "Maliyet + % (Yüzde)"
        : profitType === "fix_profit_plus_pct"
          ? "Fix Profit + % (Yüzde)"
          : profitType === "fix_profit_plus_amt"
            ? "Fix Profit + Miktar"
            : profitType === "special_profit_plus_pct"
              ? "Özel Fix Kar + Yüzde"
              : profitType === "special_profit_plus_amt"
                ? "Özel Fix Kar + Miktar"
                : profitType === "pricing_list"
                  ? "Fiyat Listesi"
                  : "";

  const handlePriceSettings = useCallback(() => {
    setLoading(true);

    const params =
      profitType === "pricing_list"
        ? {
          customer_group: customerGroup,
          profit_type: profitType,
          profit_type_list: profitTypeList,
        }
        : {
          customer_group: customerGroup,
          profit_type: profitType,
          profit_amount: discountAmount,
        };

    if (isSuperAdminRole) {
      axios
        .post(`member/update-profit-type/${member?.id}`, params)
        .then((response) => {
          toast.success(response.data.message);

          const updatedArray = memberList?.map((item) => {
            if (item.id === member?.id) {
              // gelen veriyi bu öğeye ata
              return {
                ...item,
                customer_group: response.data.data?.customer_group,
                profit_amount: response.data.data?.profit_amount,
                profit_type: response.data.data?.profit_type,
                profit_type_list: response.data.data?.profit_type_list,
                system_default_profit: response.data.data?.system_default_profit,
              };
            }
            // diğer öğeleri olduğu gibi döndür
            return item;
          });

          // güncellenmiş diziyi state'e kaydet
          setMemberList(updatedArray);
          setProfitEdit(false);
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    }
  }, [
    member,
    customerGroup,
    profitType,
    discountAmount,
    profitTypeList,
    isSuperAdminRole,
    memberList,
    setMemberList,
  ]);

  const getDefaultSystemSettings = useCallback(() => {
    if (profitIsActive) {
      setProfitIsActive(false);
      setProfitType(member?.profit_type);
      setDiscountAmount(Number(member?.profit_amount));
      setProfitTypeList(member?.profit_type_list);
    } else {
      axios
        .get("system-setting/get-system-default-profit")
        .then((response) => {
          setProfitType(response.data.data?.profit_type);
          setDiscountAmount(Number(response.data.data?.profit_amount));
          setProfitTypeList("");
          setProfitIsActive(true);
        })
        .catch((error) => Errors(error))
        .finally(() => {
          setSystemDefaultLoading(false);
        });
    }
  }, [member, profitIsActive]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          maxWidth: "280px",
          width: "100%",
          marginLeft: "auto",
          display: "grid",
          gap: "5px",
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: "20px" }}>
          Fiyat Listesi Kar Marjı
        </Typography>
        <Box sx={{ display: "grid", gap: "var(--gap)" }}>
          <FormControl disabled={!isSuperAdminRole || !profitEdit} size="small" fullWidth>
            <InputLabel id="customer-group-select-label">Müşteri Grubu</InputLabel>
            <Select
              labelId="customer-group-select-label"
              id="customer-group-select"
              label="Müşteri Grubu"
              value={customerGroup}
              onChange={(e) => setCustomerGroup(e.target.value)}
            >
              <MenuItem disabled value="">
                <em>Seç</em>
              </MenuItem>
              {memberCustomerGroups.map((cus) => (
                <MenuItem key={cus.value} value={cus.value}>
                  {cus.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: "grid", gap: "var(--gap)" }}>
            <Box sx={{ display: "flex", gap: "var(--gap)", minWidth: 200 }}>
              <FormControl
                disabled={!isSuperAdminRole || !profitEdit}
                size="small"
                sx={{ width: "85%" }}
              >
                <Select value={profitType} onChange={(e) => setProfitType(e.target.value)}>
                  <MenuItem disabled value={""}>
                    <em>Seç</em>
                  </MenuItem>
                  <MenuItem value={"amt"}>Maliyet + Miktar</MenuItem>
                  <MenuItem value={"pct"}>Maliyet + % (Yüzde)</MenuItem>
                  <MenuItem value={"fix_profit_plus_pct"}>Fix Profit + % (Yüzde)</MenuItem>
                  <MenuItem value={"fix_profit_plus_amt"}>Fix Profit + Miktar</MenuItem>
                  <MenuItem value={"special_profit_plus_pct"}>Özel Fix Kar + Yüzde (%)</MenuItem>
                  <MenuItem value={"special_profit_plus_amt"}>Özel Fix Kar + Miktar</MenuItem>
                  <MenuItem value={"pricing_list"}>Fiyat Listesi</MenuItem>
                </Select>
              </FormControl>
              {(profitType === "amt" ||
                profitType === "pct" ||
                profitType === "special_profit_plus_pct" ||
                profitType === "special_profit_plus_amt" ||
                profitType === "fix_profit_plus_pct" ||
                profitType === "fix_profit_plus_amt") && (
                  <TextField
                    size="small"
                    fullWidth
                    label={label}
                    autoComplete="off"
                    disabled={!isSuperAdminRole || !profitEdit}
                    inputMode={"decimal"}
                    value={discountAmount}
                    onChange={handleDecimalChange}
                  />
                )}{" "}
              {/* {profitType === "pct" && (
                <FormControl disabled={!isSuperAdminRole || !profitEdit} size="small" fullWidth>
                  <InputLabel id="discount-select-label">{label}</InputLabel>
                  <Select
                    labelId="discount-select-label"
                    id="discount-select"
                    label={label}
                    value={discountAmount}
                    onChange={(e) => setDiscountAmount(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <em>Temizle</em>
                    </MenuItem>
                    <MenuItem value={1}>2</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                  </Select>
                </FormControl>
              )} */}
              {profitType === "pricing_list" && (
                <TextField
                  size="small"
                  disabled={!isSuperAdminRole || !profitEdit}
                  fullWidth
                  label={label}
                  autoComplete="off"
                  value={profitTypeList}
                  onChange={(e) => setProfitTypeList(e.target.value)}
                />
              )}
            </Box>
            <FormGroup>
              <FormControlLabel
                control={
                  systemDefaultLoading ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "39px",
                        width: "39px",
                      }}
                    >
                      <CircularProgress
                        sx={{ height: "21px!important", width: "21px!important" }}
                      />
                    </div>
                  ) : (
                    <Checkbox
                      disabled={!isSuperAdminRole || !profitEdit}
                      checked={profitIsActive}
                      onChange={getDefaultSystemSettings}
                    />
                  )
                }
                label={
                  profitIsActive ? "Stantart Sistem Karı Aktif" : "Standart Sistem Karını Getir"
                }
              />
            </FormGroup>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {loading && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "150px",
                    height: "36px",
                  }}
                >
                  <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
                </div>
              )}
              {!loading && !profitEdit && (
                <Button
                  disabled={!isSuperAdminRole}
                  sx={{ ":disabled": { opacity: 0.3 } }}
                  onClick={() => setProfitEdit(true)}
                  className="iyzi-button-blue"
                >
                  <EditOutlinedIcon /> Düzenle
                </Button>
              )}
              {!loading && profitEdit && (
                <div style={{ display: "flex", gap: "var(--gap)" }}>
                  <Button
                    onClick={handlePriceSettings}
                    disabled={!isSuperAdminRole}
                    className="iyzi-button-blue"
                  >
                    <SaveOutlinedIcon />
                    Fiyat Ayarlarını Kaydet
                  </Button>
                  <Button onClick={() => setProfitEdit(false)} className="iyzi-button-blue">
                    <EditOffOutlinedIcon /> Vazgeç
                  </Button>
                </div>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default MemberDocumentEditProfitMargin;
