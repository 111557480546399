import React, { useCallback, useState } from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  FinanceGonderiIadeCurrentPageState,
  FinanceGonderiIadeDataState,
  FinanceGonderiIadeTotalPageState,
} from "../../../../atoms/finance";
import axios from "../../../../api/axios";
import Errors from "../../../../utils/Errors";

const FinanceGonderiIadeTableHead = () => {
  const [, setFinanceGonderiIadeData] = useRecoilState(FinanceGonderiIadeDataState);
  const [, setFinanceGonderiIadeTotalPage] = useRecoilState(FinanceGonderiIadeTotalPageState);
  const [FinanceGonderiIadeCurrentPage, setFinanceGonderiIadeCurrentPage] = useRecoilState(
    FinanceGonderiIadeCurrentPageState
  );

  const [activeSort, setActiveSort] = useState([
    { column: "member_id", direction: null },
    { column: "shipment_id", direction: null },
    { column: "date", direction: null },
    { column: "price", direction: null },
    { column: "approved", direction: null },
  ]);

  const handlePageChange = useCallback(
    async (orderBy) => {
      const updatedSort = activeSort.map((sort) => {
        if (sort.column === orderBy) {
          return {
            ...sort,
            direction: sort.direction === "ASC" ? "DESC" : "ASC",
          };
        }
        return {
          ...sort,
          direction: null, // Diğer sütunların yönünü varsayılan duruma sıfırlayın
        };
      });

      setActiveSort(updatedSort);

      const currentDirection = updatedSort.find((sort) => sort.column === orderBy).direction;

      await axios
        .get(`parcel-return/demand/list`, {
          params: {
            page: FinanceGonderiIadeCurrentPage,
            limit: 500,
            order_by: orderBy,
            order_direction: currentDirection,
          },
        })
        .then((resp) => {
          setFinanceGonderiIadeData(resp.data.data);
          setFinanceGonderiIadeCurrentPage(resp.data.currentPage);
          setFinanceGonderiIadeTotalPage(resp.data.totalPages);
        })
        .catch((error) => Errors(error));
    },
    [
      setFinanceGonderiIadeData,
      setFinanceGonderiIadeCurrentPage,
      setFinanceGonderiIadeTotalPage,
      FinanceGonderiIadeCurrentPage,
      activeSort,
    ]
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ fontWeight: 700 }}>Talep Tarihi</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Referans ID</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Müşteri ID</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Müşteri Adı</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Açıklama</TableCell>
        {/*<TableCell sx={{ fontWeight: 700 }}>İade Edilecek Tutar</TableCell>*/}
        <TableCell sx={{ fontWeight: 700 }}>Durum</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>İşlem</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default FinanceGonderiIadeTableHead;
