import "react-datepicker/dist/react-datepicker.css";
import { Button } from "@mui/material";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import axios from "../../../../api/axios";
import DownloadBase64Pdf from "../../../../utils/DownloadBase64Pdf";
import FormatDate from "../../../../utils/FormatDate";
import {
  adminBakiyeExportState,
  searchAdminBakiyeDateState,
  searchAdminBakiyeEmailState,
  searchAdminBakiyeIdState,
  searchAdminBakiyeKonsimentoState,
  searchAdminBakiyeNameState,
  searchAdminBakiyeParamState,
} from "../../../../atoms/finance";
import { useRecoilValue } from "recoil";

const FinanceBakiyeExport = () => {
  const today = new Date();

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);

  const adminBakiyeExport = useRecoilValue(adminBakiyeExportState);
  const searchAdminBakiyeParam = useRecoilValue(searchAdminBakiyeParamState);
  const searchAdminBakiyeId = useRecoilValue(searchAdminBakiyeIdState);
  const searchAdminBakiyeName = useRecoilValue(searchAdminBakiyeNameState);
  const searchAdminBakiyeEmail = useRecoilValue(searchAdminBakiyeEmailState);
  const searchAdminBakiyeKonsimento = useRecoilValue(
    searchAdminBakiyeKonsimentoState
  );
  const searchAdminBakiyeDate = useRecoilValue(searchAdminBakiyeDateState);

  const handleExport = (e) => {
    e.preventDefault();

    const detailedParams = {
      page: 1,
      limit: 100,
      order_direction: "DESC",
      detailed: 1,
      member_id: searchAdminBakiyeId,
      member_name: searchAdminBakiyeName,
      member_email: searchAdminBakiyeEmail,
      parcel_ref_no: searchAdminBakiyeKonsimento,
      created_at: searchAdminBakiyeDate,
      start_date: FormatDate(startDate),
      end_date: FormatDate(endDate),
      export: 1,
    };

    const basicParams = {
      limit: 500,
      page: 1,
      order_direction: "DESC",
      export: 1,
      start_date: FormatDate(startDate),
      end_date: FormatDate(endDate),
      search: searchAdminBakiyeParam,
    };

    const params = {
      limit: 500,
      page: 1,
      order_direction: "DESC",
      export: 1,
      start_date: FormatDate(startDate),
      end_date: FormatDate(endDate),
    };

    axios
      .get(`wallet/list`, {
        params:
          adminBakiyeExport === ""
            ? params
            : adminBakiyeExport === "basic"
            ? basicParams
            : detailedParams,
      })
      .then((res) => {
        DownloadBase64Pdf(
          res.data.base64,
          "IyziBakiyem",
          "finance",
          FormatDate(startDate),
          FormatDate(endDate)
        );
      });
  };

  return (
    <form className="iyzi-export" onSubmit={handleExport}>
      <div className="iyzi-date-range">
        <ReactDatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={today}
          required
        />
        <div style={{ margin: "auto" }}>-</div>
        <ReactDatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          maxDate={today}
          required
        />
      </div>
      <Button type="submit" className="iyzi-button">
        Dışarı Aktar
      </Button>
    </form>
  );
};

export default FinanceBakiyeExport;
