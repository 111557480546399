import GetToday from "./GetToday";

const DownloadBase64Excel = (base64, description, title, startDate, endDate) => {
  const today = GetToday(); // Önceki PDF indirme fonksiyonunda olduğu gibi tarih alma işlemi
  const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.target = "_blank";
  // Dosya adının PDF indirmeyle aynı şekilde ayarlanması
  title === "finance"
    ? (downloadLink.download = `${startDate}_${endDate}_${description}.xlsx`)
    : (downloadLink.download = `${today}_${description}.xlsx`);
  downloadLink.click(); // İndirme işleminin tetiklenmesi
};

export default DownloadBase64Excel;
