import { Table, TableBody, TableContainer } from "@mui/material";
import FinanceFaturaTableHead from "./FinanceFaturaTableHead";
import { useRecoilValue } from "recoil";
import { financeFaturaDataState } from "../../../../atoms/finance";
import FinanceFaturaTableBody from "./FinanceFaturaTableBody";

const FinanceFaturaTable = () => {
  const financeFaturaData = useRecoilValue(financeFaturaDataState);

  return (
    <TableContainer
      sx={{
        backgroundColor: "var(--lighterBg)",
        borderRadius: "var(--wrapperBorderRadius)",
        boxShadow: "var(--shadow)",
      }}
    >
      <Table>
        <FinanceFaturaTableHead />
        <TableBody>
          {financeFaturaData?.map((item, key) => (
            <FinanceFaturaTableBody key={key} item={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FinanceFaturaTable;
