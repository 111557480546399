import React, { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Modal,
  DialogContentText,
  Tooltip,
  Divider,
  IconButton,
  CircularProgress,
} from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  supplierChangeOpenState,
  selectedShipmentForMyShipmentsState,
} from "../../../../atoms/myShipments";
import LocalPrice from "../../../../utils/LocalPrice";
import CurrencySymbol from "../../../../utils/CurrencySymbol";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { useRecoilState } from "recoil";
import Errors from "../../../../utils/Errors";

const GET_PARCEL_PRICE_API = "parcel/get-parcel-price";
const UPDATE_SUPPLIER_API = "parcel/update-supplier";

const SupplierChangeModal = () => {
  const [selectedShipmentForMyShipments, setSelectedShipmentForMyShipments] = useRecoilState(
    selectedShipmentForMyShipmentsState
  );
  const [supplierChangeOpen, setSupplierChangeOpen] = useRecoilState(supplierChangeOpenState);

  const [contractCode, setContractCode] = useState("");
  const [priceId, setPriceId] = useState("");
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [selectedSupplierData, setSelectedSupplierData] = useState(null);

  const [loading, setLoading] = useState(false);

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;

  const shipmentDocument = selectedShipmentForMyShipments.doc_type;

  const getPrice = useCallback(async () => {
    setLoading(true);
    const dimensionArray = selectedShipmentForMyShipments.get_parcel_dimensions[0];
    const extractedValues = [
      {
        gross: dimensionArray?.gross,
        height: dimensionArray?.height,
        id: dimensionArray?.id,
        length: dimensionArray?.length,
        qty: dimensionArray?.qty,
        width: dimensionArray?.width,
      },
    ];

    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
      const data =
        shipmentDocument === "N"
          ? {
              member_id: selectedShipmentForMyShipments.member_id,
              origin_country_code: selectedShipmentForMyShipments.get_parcel_sender_address.country,
              country_code: selectedShipmentForMyShipments.get_parcel_receiver_address.country,
              document_type: selectedShipmentForMyShipments.doc_type,
              product_value: selectedShipmentForMyShipments.selling_price,
              product_value_currency: selectedShipmentForMyShipments.currency,
              dimensions: extractedValues,
              postal_code: selectedShipmentForMyShipments.get_parcel_sender_address.postal_code,
              domestic_supplier_name: "Other",
              total_qty: selectedShipmentForMyShipments.qty,
            }
          : shipmentDocument === "D"
          ? {
              member_id: selectedShipmentForMyShipments.member_id,
              origin_country_code: selectedShipmentForMyShipments.get_parcel_sender_address.country,
              country_code: selectedShipmentForMyShipments.get_parcel_receiver_address.country,
              document_type: selectedShipmentForMyShipments.doc_type,
              max_gross: selectedShipmentForMyShipments.gw,
              postal_code: selectedShipmentForMyShipments.get_parcel_sender_address.postal_code,
              domestic_supplier_name: "Other",
            }
          : "";

      const response = await axios.post(GET_PARCEL_PRICE_API, data);

      setSelectedSupplierData(response.data.data);
      setContractCode(response.data.contract_code);

      setLoading(false);
    } catch (error) {
      Errors(error);
      setLoading(false);
    }
  }, [jwt, selectedShipmentForMyShipments, shipmentDocument]);

  // Komponent yüklendiğinde getPrice çağrılacak
  useEffect(() => {
    if (supplierChangeOpen) {
      getPrice();
    }
  }, [supplierChangeOpen]);

  const handleSelectSupplier = (item) => {
    const selectedPriceId = item.price_id;
    setPriceId(selectedPriceId);
    openConfirmDialog(); // Seçildiğinde onay dialog penceresini aç
  };

  // Tedarikçi değiştirme işlemi
  const handleSupplierChange = () => {
    const requestBody = {
      price_details: {
        contract_code: contractCode,
        price_id: priceId,
      },
    };

    axios
      .post(`${UPDATE_SUPPLIER_API}/${selectedShipmentForMyShipments.id}`, requestBody, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        window.location.reload();
      })
      .catch((error) => Errors(error))
      .finally(() => {
        closeModal();
      });
  };

  // Bu işlev modalı kapatmak için kullanılır
  const closeModal = () => {
    setSelectedSupplierData(null);
    setSupplierChangeOpen(false);
    setSelectedShipmentForMyShipments([]);
  };

  const openConfirmDialog = () => {
    setIsConfirmOpen(true);
  };

  const closeConfirmDialog = () => {
    setIsConfirmOpen(false);
  };

  const handleConfirm = () => {
    handleSupplierChange();
    closeConfirmDialog();
  };

  return (
    <>
      <Modal
        open={supplierChangeOpen}
        onClose={closeModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        PaperProps={{
          style: {
            backgroundColor: "rgba(255, 255, 255, 0.2)",
          },
        }}
      >
        <div
          style={{
            width: "90%",
            maxWidth: "1200px",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {loading && <CircularProgress sx={{ margin: "auto" }} />}
          {!loading && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  width: " 100%",
                  height: "25px",
                }}
              >
                <IconButton
                  onClick={closeModal}
                  aria-label="kapat"
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent;",
                    },
                  }}
                >
                  <HighlightOffOutlinedIcon sx={{ fontSize: "24px" }} />
                </IconButton>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                  width: "100%",
                  gap: "var(--gap2x)",
                  marginTop: "var(--gap2x)",
                }}
              >
                {selectedSupplierData?.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      // width: "31%",
                      backgroundColor: "#f9f9f9",
                      padding: "20px",
                      borderRadius: "8px",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                      marginBottom: "20px",
                      // display: "flex",
                      // flexWrap: "wrap",
                      // margin: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      {item.supplier_note_tr && (
                        <Tooltip
                          title={item.supplier_note_tr}
                          style={{
                            width: "30px",
                          }}
                        >
                          <InfoOutlinedIcon
                            sx={{ color: "var(--info)" }}
                            className="logo-warning"
                          />
                        </Tooltip>
                      )}
                      <img
                        src={`/images/${item.supplier_logo}`}
                        alt={`${item.supplier_name} Logo Görseli`}
                        style={{
                          maxWidth: "100%",
                          // height: "auto",
                          marginLeft: item.supplier_note_tr ? "0" : "31px",
                          height: "80px",
                        }}
                      />
                      <span style={{ marginLeft: "auto", fontWeight: "bold" }}>
                        {item.service_type === "X" ? "Express" : "Economy"}
                      </span>
                    </div>
                    <div
                      className="get-card-header-subtext"
                      style={{
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                    >
                      Teslimat Süresi:&nbsp;<span>{item.transit}</span>&nbsp;iş günü
                    </div>
                    <Divider />
                    <div>
                      <div style={{ fontSize: "16px" }}>
                        <span
                          style={{
                            color: "#1976d2",
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        >
                          {LocalPrice(item.selling_price)}
                          {CurrencySymbol(item.currency)}
                        </span>

                        <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                          ({LocalPrice(Number(item.selling_price) * Number(item.exchange_rate))} ₺)
                        </span>
                      </div>
                      <div style={{ fontSize: "14px", marginTop: "10px" }}>
                        İade Masrafı: {LocalPrice(Number(item.navlun_selling_price) * 2)}{" "}
                        {CurrencySymbol(item.currency)}
                      </div>
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSelectSupplier(item)}
                      style={{
                        marginRight: "10px",
                        marginTop: "10px",
                        width: "100%",
                        borderRadius: "10px",
                      }}
                    >
                      Seç
                    </Button>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </Modal>
      <Dialog open={isConfirmOpen} onClose={closeConfirmDialog}>
        <DialogTitle>Değişiklik yapmak istiyor musunuz?</DialogTitle>
        <DialogContent>
          <DialogContentText>Değişiklikleri onaylamadan önce emin misiniz?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog} color="primary">
            Hayır
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Evet
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SupplierChangeModal;
