import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip
} from "@mui/material";
import FlightTakeoffOutlinedIcon from "@mui/icons-material/FlightTakeoffOutlined";
import FlightLandOutlinedIcon from "@mui/icons-material/FlightLandOutlined";
import { CircleFlag } from "react-circle-flags";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import shipmentLogo_0 from "../../../img/myShipments/gonderilerim_0.svg";
import shipmentLogo_1 from "../../../img/myShipments/gonderilerim_1.svg";
import shipmentLogo_2 from "../../../img/myShipments/gonderilerim_2.svg";
import shipmentLogo_4 from "../../../img/myShipments/gonderilerim_4.svg";
import shipmentLogo_5 from "../../../img/myShipments/gonderilerim_5.svg";
import Currency from "../../../utils/CurrencySymbol";
import countries from "../../../json/countries.json";
import { useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  checkedShipmentsState,
  selectedMyShipmentListItemState,
  selectedPaymentShipmentState,
  shipmentPaymentOpenState,
} from "../../../atoms/myShipments";
import ReturnListItemLinks from "./ReturnListItemLinks";
import {
  returnListArrayLengthState,
  returnShipmentListState,
} from "../../../atoms/returnShipment";
import FormatHeadlineText from "../../../utils/FormatHeadlineText";
import MessageTime from "../../../utils/MessageTime";
import ParcelType from "../../../utils/ParcelType";

const ReturnShipmentListItem = ({ shipment, keys }) => {
  const returnShipmentList = useRecoilValue(returnShipmentListState);
  const returnListArrayLength = useRecoilValue(returnListArrayLengthState);

  const [checkedShipments, setCheckedShipments] = useRecoilState(
    checkedShipmentsState
  );
  const [selectedMyShipmentListItem, setSelectedMyShipmentListItem] =
    useRecoilState(selectedMyShipmentListItemState);
  const [, setShipmentPaymentOpen] = useRecoilState(shipmentPaymentOpenState);
  const [, setSelectedPaymentShipment] = useRecoilState(
    selectedPaymentShipmentState
  );

  const handlePayment = async () => {
    setShipmentPaymentOpen(true);
    setSelectedPaymentShipment(shipment);
  };


  const handleCheckedShipments = useCallback(
    (e, id, key) => {
      const selectedMyShipmentListIndex = returnShipmentList.findIndex(
        (shipmentList) => shipmentList.id === id
      );

      if (
        selectedMyShipmentListItem.find(
          (e) => e === returnShipmentList[selectedMyShipmentListIndex]
        ) !== undefined
      ) {
        setSelectedMyShipmentListItem(
          selectedMyShipmentListItem.filter(
            (shipmentList) =>
              shipmentList.id !==
              returnShipmentList[selectedMyShipmentListIndex].id
          )
        );
      } else {
        setSelectedMyShipmentListItem([
          ...selectedMyShipmentListItem,
          returnShipmentList[selectedMyShipmentListIndex],
        ]);
      }

      const updatedCheckedState = checkedShipments.map((item, index) =>
        index === key ? !item : item
      );

      setCheckedShipments(updatedCheckedState);
    },
    [
      returnShipmentList,
      checkedShipments,
      selectedMyShipmentListItem,
      setCheckedShipments,
      setSelectedMyShipmentListItem,
    ]
  );

  useEffect(() => {
    if (!checkedShipments.find((e) => e === true || e === false)) {
      if (returnListArrayLength !== 0) {
        setCheckedShipments(new Array(returnListArrayLength).fill(false));
      }
    }
  }, []);

  return (
    <Box key={shipment.id} className="returnShipment-list-wrapper">
      <Box className="list-steps">
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div style={{ textAlign: "start" }}>
            <span
              style={{
                fontSize: "inherit",
                color: "var(--btnBgBlue)",
                fontWeight: 600,
              }}
            >
              Gönderici Adı:{" "}
            </span>
            {shipment?.get_parcel.sender_name}
          </div>
          <div style={{ textAlign: "start" }}>
            <span
              style={{
                fontSize: "inherit",
                color: "var(--btnBgBlue)",
                fontWeight: 600,
              }}
            >
              Alıcı Adı:{" "}
            </span>
            {shipment?.get_parcel.receiver_name}
          </div>
          <div>
            <span
              style={{
                color: "var(--btnBgBlue)",
                fontWeight: 600,
                fontSize: "inherit",
              }}
            >
              {shipment?.get_parcel.doc_type === "N" ? "Paket" : "Döküman"}
            </span>
            &nbsp;-&nbsp;{MessageTime(shipment?.get_parcel.created_at)}

            &nbsp;-&nbsp;
            <span
              style={{
                color: "var(--btnBgBlue)",
                fontWeight: 500,
                fontSize: "inherit",
              }}
            >
              {ParcelType(shipment?.get_parcel.parcel_type)}
            </span>
          </div>
        </div>
        <Box className="steps-where-to">
          <Box className="to-integration">
            {shipment?.get_parcel?.get_integration_parcel?.platform && (
              <>
                <Tooltip title={shipment?.get_parcel?.get_integration_parcel?.platform}>
                  <img
                    className="to-integration-platform"
                    src={`images/integrations/${shipment?.get_parcel?.get_integration_parcel?.platform === "ETSY"
                      ? "etsy_fast"
                      : shipment?.get_parcel?.get_integration_parcel?.platform?.toLowerCase()
                      }.svg`}
                    alt={shipment?.get_parcel?.get_integration_parcel?.platform}
                  />
                </Tooltip>
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: 700,
                    color: "var(--text)",
                  }}
                >
                  {shipment?.get_parcel?.get_integration_parcel?.shop_id}
                </span>
              </>
            )}
          </Box>
          <Box className="to-plane">
            {countries.find(
              (e) => e.code === shipment.get_parcel.sender_country
            ).label === "Turkey"
              ? "TÜRKİYE"
              : countries
                .find((e) => e.code === shipment.get_parcel.sender_country)
                .label.toUpperCase()}
            <div className="plane-flag">
              <CircleFlag
                countryCode={countries
                  .find((e) => e.code === shipment.get_parcel.sender_country)
                  .code.toLowerCase()}
                height="24"
              />
              <FlightTakeoffOutlinedIcon id="take-off" />
            </div>
          </Box>
          <Box sx={{ display: "grid", justifyContent: "center" }}>
            <Box
              sx={{
                fontSize: "13px",
                color: "var(--priceText)",
                textAlign: "center",
                marginBottom: "3px",
              }}
            >
              GİDİŞ AWB
            </Box>
            <Box sx={{ minWidth: "120px" }} className="to-tracking">
              {shipment.get_parcel.tracking_awb}
            </Box>
          </Box>
          <Box className="to-plane">
            {countries
              .find((e) => e.code === shipment.get_parcel.receiver_country)
              .label.toUpperCase()}
            <div className="plane-flag">
              <FlightLandOutlinedIcon id="land" />
              <CircleFlag
                countryCode={countries
                  .find((e) => e.code === shipment.get_parcel.receiver_country)
                  .code.toLowerCase()}
                height="24"
              />
            </div>
          </Box>
        </Box>
        <Box className="stepper-wrapper-css">
          <div className="step-wrapper-css">
            <div
              className="step-css"
              style={
                shipment.get_parcel.status > "0"
                  ? { backgroundColor: "var(--shipmentRed)" }
                  : { backgroundColor: "var(--shipmentLight)" }
              }
            >
              <img
                src={shipmentLogo_0}
                className="step-logo-css"
                alt="Gönderi 1.Aşama Görseli"
              />
            </div>
          </div>
          <div className="step-wrapper-css">
            <div
              className="step-css"
              style={
                shipment.get_parcel.status > "1"
                  ? { backgroundColor: "var(--shipmentRed)" }
                  : { backgroundColor: "var(--shipmentLight)" }
              }
            >
              <img
                src={shipmentLogo_1}
                className="step-logo-css"
                alt="Gönderi 2.Aşama Görseli"
              />
            </div>
          </div>
          <div className="step-wrapper-css">
            <div
              className="step-css"
              style={
                shipment.get_parcel.status === "2"
                  ? { backgroundColor: "var(--shipmentRed)" }
                  : { backgroundColor: "var(--shipmentLight)" }
              }
            >
              <img
                src={shipmentLogo_2}
                className="step-logo-css"
                alt="Gönderi 3.Aşama Görseli"
              />
            </div>
          </div>
          <div className="step-wrapper-css">
            <div
              className="step-css"
              style={
                shipment.get_parcel.status === "3"
                  ? { backgroundColor: "var(--shipmentRed)" }
                  : { backgroundColor: "var(--shipmentLight)" }
              }
            >
              <Avatar
                className="step-shipment-logo-css"
                src={`/images/${shipment.get_parcel.get_parcel_supplier.supplier_logo}`}
              />
            </div>
          </div>
          <div className="step-wrapper-css">
            <div
              className="step-css"
              style={
                shipment.get_parcel.status === "4"
                  ? { backgroundColor: "var(--shipmentRed)" }
                  : { backgroundColor: "var(--shipmentLight)" }
              }
            >
              <img
                src={shipmentLogo_4}
                className="step-logo-css"
                alt="Gönderi 5.Aşama Görseli"
              />
            </div>
          </div>
          <div className="step-wrapper-css">
            <div
              className="step-css"
              style={
                shipment.get_parcel.status === "5"
                  ? { backgroundColor: "var(--shipmentRed)" }
                  : { backgroundColor: "var(--shipmentLight)" }
              }
            >
              <img
                src={shipmentLogo_5}
                className="step-logo-css"
                alt="Gönderi 6.Aşama Görseli"
              />
            </div>
          </div>
        </Box>
        <Box className="steps-where-to">
          {/*<Box className="to-integration">
            <div style={{ visibility: "hidden" }}>Etsy</div>
            <div style={{ visibility: "hidden" }}>2410218693</div>
          </Box>*/}
          <Box className="to-plane">
            {countries.find(
              (e) => e.code === shipment.get_parcel.sender_country
            ).label === "Turkey"
              ? "TÜRKİYE"
              : countries
                .find((e) => e.code === shipment.get_parcel.sender_country)
                .label.toUpperCase()}
            <div className="plane-flag">
              <CircleFlag
                countryCode={countries
                  .find((e) => e.code === shipment.get_parcel.sender_country)
                  .code.toLowerCase()}
                height="24"
              />
              <FlightLandOutlinedIcon
                sx={{ transform: "rotateY(180deg)" }}
                id="land"
              />
            </div>
          </Box>
          <Box sx={{ display: "grid", justifyContent: "center" }}>
            <Box
              sx={{
                fontSize: "13px",
                color: "var(--priceText)",
                textAlign: "center",
                marginBottom: "3px",
              }}
            >
              İADE AWB
            </Box>
            <Box sx={{ minWidth: "120px" }} className="to-tracking">
              {shipment.tracking_awb}
            </Box>
          </Box>
          <Box className="to-plane">
            {countries
              .find((e) => e.code === shipment.get_parcel.receiver_country)
              .label.toUpperCase()}
            <div className="plane-flag">
              <FlightTakeoffOutlinedIcon
                sx={{ transform: "rotateY(180deg)" }}
                id="take-off"
              />
              <CircleFlag
                countryCode={countries
                  .find((e) => e.code === shipment.get_parcel.receiver_country)
                  .code.toLowerCase()}
                height="24"
              />
            </div>
          </Box>
        </Box>
        <Box className="steps-info">
          <Box className="steps-info-wrapper info-member">
            <div className="info-top">Üye Tarafından Beyan Edilen Kilo</div>
            <div className="info-bottom">
              <div className="info-bottom-subline">
                NET KG
                <div className="info-bottom-subline-number">
                  {shipment.get_parcel.get_parcel_dimensions
                    .filter((e) => e.status === "1")
                    .reduce((acc, val) => Number(acc) + Number(val.total_gw), 0)
                    .toFixed(2)}
                </div>
              </div>
              <div className="info-bottom-subline">
                DESİ
                <div className="info-bottom-subline-number">
                  {shipment.get_parcel.get_parcel_dimensions
                    .filter((e) => e.status === "1")
                    .reduce((acc, val) => Number(acc) + Number(val.total_vw), 0)
                    .toFixed(2)}
                </div>
              </div>
              <div className="info-bottom-subline">
                NAVLUN KG
                <div className="info-bottom-subline-number">
                  {shipment.get_parcel.get_parcel_dimensions
                    .filter((e) => e.status === "1")
                    .reduce((acc, val) => Number(acc) + Number(val.total_cw), 0)
                    .toFixed(2)}
                </div>
              </div>
            </div>
          </Box>
          {Number(shipment.return_paid) === 0 ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
              id="integer_done"
              className="steps-info-done"
            >
              {/*<span className="steps-info-done-price">
                {shipment.return_price}
                {Currency(shipment.currency)}
              </span>
              <Button
                onClick={handlePayment}
                className="steps-info-done-button"
              >
                "Ödeme Yap"
              </Button>*/}
              <div className="steps-info-done-button">
                İade İşlemi Yapılması Bekleniyor
              </div>
              <div
                style={{
                  color: "var(--priceText)",
                  backgroundColor: "var(--background)",
                  padding: "10px",
                  borderRadius: "var(--wrapperBorderRadius)",
                  boxShadow: "var(--shadow)",
                  fontSize: "12px",
                }}
              >
                Güncel:{" "}
                <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                  {FormatHeadlineText(shipment?.get_parcel?.status_name)}
                </span>
              </div>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
              id="integer_done"
              className="steps-info-done"
            >
              {/*<span className="steps-info-done-price paid">
                {shipment.return_price}
                {Currency(shipment.currency)}
              </span>*/}
              <div className="steps-info-done-button paid">
                İade İşlemi Tamamlandı
              </div>
              <div
                style={{
                  color: "var(--priceText)",
                  backgroundColor: "var(--background)",
                  padding: "10px",
                  borderRadius: "var(--wrapperBorderRadius)",
                  boxShadow: "var(--shadow)",
                  fontSize: "12px",
                }}
              >
                Güncel:{" "}
                <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                  {FormatHeadlineText(shipment?.get_parcel?.status_name)}
                </span>
              </div>
            </Box>
          )}
          <Box className="steps-info-wrapper info-system">
            <div className="info-top">
              iyziTeam Tarafından Beyan Edilen Kilo
            </div>
            <div className="info-bottom">
              <div className="info-bottom-subline">
                NET KG
                <div className="info-bottom-subline-number">
                  {shipment.get_parcel.get_parcel_dimensions
                    .filter((e) => e.status === "2")
                    .reduce((acc, val) => Number(acc) + Number(val.total_gw), 0)
                    .toFixed(2)}
                </div>
              </div>
              <div className="info-bottom-subline">
                DESİ
                <div className="info-bottom-subline-number">
                  {shipment.get_parcel.get_parcel_dimensions
                    .filter((e) => e.status === "2")
                    .reduce((acc, val) => Number(acc) + Number(val.total_vw), 0)
                    .toFixed(2)}
                </div>
              </div>
              <div className="info-bottom-subline">
                NAVLUN KG
                <div className="info-bottom-subline-number">
                  {shipment.get_parcel.get_parcel_dimensions
                    .filter((e) => e.status === "2")
                    .reduce((acc, val) => Number(acc) + Number(val.total_cw), 0)
                    .toFixed(2)}
                </div>
              </div>
            </div>
          </Box>
        </Box>
        {/*{Number(shipment.return_paid) === 0 ? (
          <Box id="seperate_done" className="steps-info-done show">
            <span className="steps-info-done-price">
              {shipment.return_price}
              {Currency(shipment.currency)}
            </span>
            <Button
              onClick={() => handlePayment(shipment)}
              className="steps-info-done-button"
            >
              {"Ödeme Yap"}
            </Button>
          </Box>
        ) : (
          <Box id="seperate_done" className="steps-info-done show">
            <span className="steps-info-done-price paid">
              {shipment.return_price}
              {Currency(shipment.currency)}
            </span>
            <div className="steps-info-done-button paid">İade İşlemi Tamamlandı</div>
          </Box>
        )}*/}
        {shipment.get_parcel.get_parcel_notes.length !== 0 &&
          shipment.get_parcel.get_parcel_notes
            .filter((e) => e.type === "1")
            .map((e) => (
              <Box key={e.id} className="steps-note">
                <span>NOT :</span> {e.description}
              </Box>
            ))}
      </Box>
      <Divider
        sx={{
          width: "1px",
          backgroundColor: "var(--phoneDigitBg)",
          margin: "0 10px",
        }}
        className="steps-divider"
      />
      <Box className="list-links-wrapper">
        <div className="list-links-container">
          {shipment.return_reason.length > 0 && (
            <Box sx={{ display: "grid", justifyContent: "center" }}>
              <Box sx={{ fontSize: "13px", color: "var(--priceText)", textAlign: "center", marginBottom: "3px" }}>
                İade Nedeni
              </Box>
              <Box sx={{ minWidth: "120px", textAlign: "center" }}>
                {shipment.return_reason}
              </Box>
            </Box>
          )}
          {!shipment.return_type_name.includes('app_list_strings') && (
            <Box sx={{ display: "grid", justifyContent: "center" }}>
              <Box sx={{ fontSize: "13px", color: "var(--priceText)", textAlign: "center", marginBottom: "3px" }}>
                DÖNÜŞ TİPİ
              </Box>
              <Box sx={{ minWidth: "120px", textAlign: "center" }}>
                {shipment.return_type_name}
              </Box>
            </Box>
          )}
          <h6 style={{ fontSize: "13px", textAlign: "center" }}>
            {shipment.get_parcel.parcel_ref_no}
          </h6>
          <ReturnListItemLinks shipment={shipment} />
        </div>
      </Box>
    </Box>
  );
};

export default ReturnShipmentListItem;
