import {
  Avatar,
  Button,
  CircularProgress,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import {
  caseExplainState,
  caseProblemState,
  caseTextState,
  expandFilesState,
  openNewCaseState,
  supportTanzimModalOpenState,
  ticketDocumentState,
  ticketMessagesState,
  ticketState,
} from "../../../../atoms/support";
import miniLogo from "../../../../img/logos/logo_white_mini_loading.svg";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import MessageTime from "../../../../utils/MessageTime";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import SupportDetailCaseAdmin from "./SupportDetailCaseAdmin";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import SupportDetailAddTalker from "./SupportDetailAddTalker";
import Errors from "../../../../utils/Errors";

const SupportDetailChatAdmin = () => {
  const [ticket] = useRecoilState(ticketState);
  const [ticketDocument, setTicketDocument] =
    useRecoilState(ticketDocumentState);
  const [, setExpandFiles] = useRecoilState(expandFilesState);
  const [, setCaseProblem] = useRecoilState(caseProblemState);
  const [, setCaseText] = useRecoilState(caseTextState);
  const [, setCaseExplain] = useRecoilState(caseExplainState);
  const [, setOpenNewCase] = useRecoilState(openNewCaseState);
  const [ticketMessages, setTicketMessages] =
    useRecoilState(ticketMessagesState);
  const [, setSupportTanzimModalOpen] = useRecoilState(
    supportTanzimModalOpenState
  );

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isSelectAnyFile, setIsSelectAnyFile] = useState(false);
  const [openCase, setOpenCase] = useState(false);
  const [openTalker, setOpenTalker] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openSupportDudeList = Boolean(anchorEl);
  const handleSupportDudeList = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSupportDudeList = () => {
    setAnchorEl(null);
  };

  const messageContainer = useRef(null);

  const handleFileSelect = (e) => {
    setSelectedFiles([...e.target.files]);
  };

  const handleSendMessage = useCallback(
    async (e) => {
      if (e) e.preventDefault();
      setLoading(true);

      if (!loading) {
        await axios
          .post(`ticket/message/add/${ticket.id}`, {
            message,
          })
          .then((response) => {
            setTicketMessages([...ticketMessages, response.data.data]);
          })
          .catch((error) => Errors(error))
          .finally(() => {
            setMessage("");
            setLoading(false);
          });
      }
    },
    [message, ticket.id, setTicketMessages, ticketMessages, loading]
  );

  const uploadSelected = useCallback(async () => {
    setUploadLoading(true);
    let formData = new FormData();
    Array.from(selectedFiles).forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
    formData.append("parent_id", ticket.id);
    formData.append("parent_type", "Ticket");
    formData.append("type", "ticket_user");
    await axios
      .post(`document/add`, formData)
      .then((response) => {
        toast.success("Dosyalar Başarıyla Yüklendi");
        setTicketDocument([...ticketDocument, ...response.data.data]);
        setExpandFiles(true);
      })
      .catch((error) => Errors(error))
      .finally(() => {
        setUploadLoading(false);
        setSelectedFiles([]);
      });
  }, [
    selectedFiles,
    ticket.id,
    setTicketDocument,
    ticketDocument,
    setExpandFiles,
  ]);

  const handleCase = useCallback(() => {
    setOpenCase(!openCase);

    if (openCase) {
      setCaseProblem(0);
      setCaseText(0);
      setCaseExplain("");
      setOpenNewCase(false);
    } else {
      setOpenTalker(false);
    }
  }, [setCaseProblem, setCaseText, setCaseExplain, setOpenNewCase, openCase]);

  const handleCloseCase = useCallback(() => {
    setOpenCase(false);
    setCaseProblem(0);
    setCaseText(0);
    setCaseExplain("");
    setOpenNewCase(false);
  }, [setCaseProblem, setCaseText, setCaseExplain, setOpenNewCase]);

  const handleTalker = useCallback(() => {
    if (!openTalker) {
      setOpenCase(false);
    }

    setOpenTalker(!openTalker);
  }, [openTalker]);

  const handleCloseTalker = () => {
    setOpenTalker(false);
  };

  const handleOptions = useCallback(
    (e) => {
      setAnchorEl(null);
      if (e === 1) {
        setOpenTalker(false);
        handleCase();
      } else if (e === 2) {
        setOpenCase(false);
        handleTalker();
      } else if (e === 3) {
        setSupportTanzimModalOpen(true);
      }
    },
    [handleCase, handleTalker, setSupportTanzimModalOpen]
  );

  useEffect(() => {
    if (messageContainer.current) {
      messageContainer.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [ticketMessages]);

  useEffect(() => {
    if (selectedFiles.length !== 0) {
      setIsSelectAnyFile(true);
    } else {
      setIsSelectAnyFile(false);
    }
  }, [selectedFiles.length]);

  return (
    <form
      style={{ position: "relative" }}
      onSubmit={handleSendMessage}
      className="support-detail-chat"
    >
      <Button
        type="button"
        sx={{
          minWidth: "40px",
          width: "40px",
          height: "40px",
          padding: 0,
          margin: 0,
          position: "absolute",
          top: "20px",
          right: "40px",
          borderRadius: "50%",
          zIndex: 2,
        }}
        aria-controls={openSupportDudeList ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openSupportDudeList ? "true" : undefined}
        onClick={handleSupportDudeList}
      >
        <Box
          sx={{
            border: "2px solid var(--shipmentLight)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
          }}
        >
          <MoreHorizOutlinedIcon
            sx={{
              width: "24px",
              height: "24px",
              color: "var(--shipmentLight)",
            }}
          />
        </Box>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openSupportDudeList}
        onClose={handleCloseSupportDudeList}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        onClick={(e) => handleOptions(e.target.value)}
      >
        <MenuItem value={1}>Case Ekle</MenuItem>
        <MenuItem value={2}>Konuşmacı Ekle</MenuItem>
        <MenuItem value={3}>Tazmin Ekle</MenuItem>
      </Menu>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box
          className="support-detail-chat-messages"
          sx={{ marginTop: "40px", width: "100%" }}
        >
          {ticketMessages?.map((message, key) => (
            <Box
              key={key}
              className={
                message.type !== "1"
                  ? "chat-message customer"
                  : "chat-message admin"
              }
            >
              <Box className="chat-message-time">
                {MessageTime(message.created_at)} -{" "}
                {message?.get_created_by?.name}{" "}
                {message?.get_created_by?.surname}
              </Box>
              <Box
                className={
                  message.type !== "1"
                    ? "chat-message-text-wrapper customer-wrapper"
                    : "chat-message-text-wrapper"
                }
              >
                {message.type === "1" ? (
                  <Avatar className="chat-message-avatar" />
                ) : (
                  <Avatar src={miniLogo} className="chat-message-avatar" />
                )}
                <Typography
                  className={
                    message.type !== "1"
                      ? "chat-message-text customer"
                      : "chat-message-text admin"
                  }
                  dangerouslySetInnerHTML={{
                    __html: message.message.replace(/\n/g, "<br>"),
                  }}
                />
              </Box>
            </Box>
          ))}
          <div ref={messageContainer} />
        </Box>
        <Box
          sx={{
            position: "relative",
            maxWidth: openCase || openTalker ? "350px" : 0,
            width: openCase || openTalker ? "100%" : 0,
          }}
        >
          {openCase && (
            <Box
              sx={{
                display: openCase ? "flex" : "none",
                flexDirection: openCase ? "column" : "row",
                padding: "20px",
                flexWrap: "nowrap",
                position: "relative",
              }}
            >
              <ArrowCircleRightOutlinedIcon
                onClick={handleCloseCase}
                sx={{
                  display: openCase ? "block" : "none",
                  position: "absolute",
                  right: "29px",
                  top: "40px",
                  color: "var(--redBrand)",
                  cursor: "pointer",
                }}
              />
              <SupportDetailCaseAdmin />
            </Box>
          )}
          {openTalker && (
            <Box
              sx={{
                flexDirection: openTalker ? "column" : "row",
                padding: "20px",
                flexWrap: "nowrap",
                position: "relative",
              }}
            >
              <ArrowCircleRightOutlinedIcon
                onClick={handleCloseTalker}
                sx={{
                  display: openTalker ? "block" : "none",
                  position: "absolute",
                  right: "29px",
                  top: "40px",
                  color: "var(--redBrand)",
                  cursor: "pointer",
                }}
              />
              <SupportDetailAddTalker ticket={ticket} />
            </Box>
          )}
        </Box>
      </Box>
      <Box
        className="detail-chat-send-message"
        sx={{
          borderRadius: "10px !important",
        }}
      >
        <TextField
          label="Mesajınızı Yazınız"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          size="small"
          fullWidth
          required
          autoComplete="off"
          multiline
          InputProps={{
            sx: {
              borderRadius: "10px !important",
            },
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  type="button"
                  variant="contained"
                  component="label"
                  sx={{
                    backgroundColor: "transparent",
                    outline: 0,
                    border: 0,
                    boxShadow: 0,
                    padding: 0,
                    minWidth: 0,
                    ":hover": {
                      backgroundColor: "transparent",
                      outline: 0,
                      border: 0,
                      boxShadow: 0,
                    },
                  }}
                >
                  <AttachFileIcon
                    sx={{
                      color: "var(--priceText)",
                      cursor: "pointer",
                      transition: "all 150ms linear",
                      ":hover": {
                        color: "var(--info)",
                        transition: "all 150ms linear",
                      },
                    }}
                  />
                  <input
                    multiple
                    onChange={handleFileSelect}
                    type="file"
                    hidden
                  />
                </Button>
              </InputAdornment>
            ),
          }}
        />
        {loading ? (
          <Box
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              sx={{ width: "24px !important", height: "24px !important" }}
            />
          </Box>
        ) : (
          <>
            {isSelectAnyFile &&
              (uploadLoading ? (
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress
                    sx={{ width: "24px !important", height: "24px !important" }}
                  />
                </Box>
              ) : (
                <Button
                  onClick={uploadSelected}
                  type="button"
                  className="detail-chat-send-message-button"
                >
                  <CloudUploadOutlinedIcon sx={{ color: "var(--lighterBg)" }} />
                </Button>
              ))}
            {!isSelectAnyFile && (
              <Button type="submit" className="detail-chat-send-message-button">
                <img src="/images/icons/send_icon.svg" alt="Gönder Görseli" />
              </Button>
            )}
          </>
        )}
      </Box>
    </form>
  );
};

export default SupportDetailChatAdmin;
