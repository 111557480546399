import { TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

const LostMemberTableHead = ({ labels, expand, setExpand }) => {
  const handleClick = (value) => {
    // expand dizisinin bir kopyasını oluştur
    const newExpand = expand.map((item) => {
      // Eğer mevcut item'ın label'ı tıklanan değere eşitse
      if (item.label === value) {
        // Yeni bir obje döndür ve status'ü true yap
        return { ...item, status: !item.status };
      }
      // Değilse, item'ı olduğu gibi döndür
      return item;
    });

    // State'i yeni dizi ile güncelle
    setExpand(newExpand);
  };

  const status = (value) => {
    const lab = expand.find((e) => e.label === value);
    return lab.status;
  };

  const months = [
    { 1: "Ocak" },
    { 2: "Şubat" },
    { 3: "Mart" },
    { 4: "Nisan" },
    { 5: "Mayıs" },
    { 6: "Haziran" },
    { 7: "Temmuz" },
    { 8: "Ağustos" },
    { 9: "Eylül" },
    { 10: "Ekim" },
    { 11: "Kasım" },
    { 12: "Aralık" },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "60px" }}>
          Üye
        </TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "60px" }}>
          Üye No
        </TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "60px" }}>
          Üyelik Tipi
        </TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "60px" }}>
          Üyelik Tarihi
        </TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "60px" }}>
          Son Gönderi Tarihi
        </TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "60px" }}>
          Durum
        </TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "60px" }}>
          Müşteri Grubu
        </TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "60px" }}>
          Karlılık Tipi
        </TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "60px" }}>
          Karlılık Miktarı
        </TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "60px" }}>
          Brüt Kar
        </TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "60px" }}>
          iyziKurye Bölgesi
        </TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "60px" }}>
          Posta Kodu
        </TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "60px" }}>
          Semt / İlçe
        </TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "60px" }}>
          Şehir / İl
        </TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "60px" }}>
          Eyalet Kodu
        </TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "60px" }}>
          Ülke
        </TableCell>
        {labels?.map((label, key) => (
          <React.Fragment key={key}>
            <TableCell
              key={key}
             
              sx={{ padding: "5px", fontWeight: 700, minWidth: "100px", cursor: "pointer" }}
              onClick={() => handleClick(label)}
            >
              {label} Toplam{" "}
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.75 7.4375V13.5469C12.75 13.7213 12.7156 13.894 12.6489 14.0551C12.5822 14.2163 12.4843 14.3627 12.361 14.486C12.2377 14.6093 12.0913 14.7072 11.9301 14.7739C11.769 14.8406 11.5963 14.875 11.4219 14.875H3.45312C3.10088 14.875 2.76307 14.7351 2.514 14.486C2.26493 14.2369 2.125 13.8991 2.125 13.5469V5.57812C2.125 5.22588 2.26493 4.88807 2.514 4.639C2.76307 4.38993 3.10088 4.25 3.45312 4.25H9.01398M11.1562 2.125H14.875V5.84375M7.4375 9.5625L14.6094 2.39062"
                  stroke="#7C7C7A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </TableCell>
            {status(label) && (
              <>
                {months.map((month, key) => (
                  <TableCell key={key} sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>
                    {Object.values(month)}
                  </TableCell>
                ))}
              </>
            )}
          </React.Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default LostMemberTableHead;
