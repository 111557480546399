import { Box, Button, createTheme, InputAdornment, TextField, ThemeProvider } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import {
  financeFaturaCurrentPageState,
  financeFaturaDataState,
  financeFaturaTotalPageState,
} from "../../../../atoms/finance";
import axios from "../../../../api/axios";
import Errors from "../../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const FaturaSearch = () => {
  const [, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");

  const [, setFinanceFaturaData] = useRecoilState(financeFaturaDataState);
  const [, setFinanceFaturaTotalPage] = useRecoilState(financeFaturaTotalPageState);
  const [, setFinanceFaturaCurrentPage] = useRecoilState(financeFaturaCurrentPageState);

  const handleSearch = useCallback(() => {
    axios
      .get(`invoice`, {
        params: {
          page: 1,
          limit: 500,
          order_direction: "DESC",
          search: searchParam,
        },
      })
      .then((resp) => {
        setFinanceFaturaData(resp.data.data);
        setFinanceFaturaCurrentPage(resp.data.currentPage);
        setFinanceFaturaTotalPage(resp.data.totalPages);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [setFinanceFaturaData, searchParam, setFinanceFaturaCurrentPage, setFinanceFaturaTotalPage]);

  const handleClear = useCallback(() => {
    setSearchParam("");
    axios
      .get(`invoice`, {
        params: {
          page: 1,
          limit: 500,
          order_direction: "DESC",
        },
      })
      .then((res) => {
        setFinanceFaturaData(res.data.data);
        setFinanceFaturaTotalPage(res.data.totalPage);
        setFinanceFaturaCurrentPage(res.data.currentPage);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [setFinanceFaturaData, setFinanceFaturaTotalPage, setFinanceFaturaCurrentPage]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: "var(--lighterBg)",
          padding: "20px",
          borderRadius: "var(--inputBorderRadius)",
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          display: "flex",
          gap: "var(--gap)",
        }}
      >
        <TextField
          size="small"
          fullWidth
          label="Arama"
          placeholder="Fatura No, Fatura Tarihi, Fatura Açıklaması, Konşimento Numarası"
          autoComplete="off"
          value={searchParam}
          onChange={(e) => setSearchParam(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            } else if (e.key === "Escape") {
              handleClear();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button onClick={handleSearch} className="iyzi-button">
          Ara
        </Button>
      </Box>
    </ThemeProvider>
  );
};

export default FaturaSearch;
