import { Modal } from "@mui/material";
import AuditHistory from "../../../../../utils/AuditHistory";

const MemberHistoryUserModal = ({ data, setData }) => {
  const open = data?.length > 0;

  const handleClose = () => {
    setData([]);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div
        style={{
          width: "100%",
          maxWidth: "1100px",
          backgroundColor: "var(--lighterBg)",
          borderRadius: "var(--wrapperBorderRadius)",
          padding: "20px",
          margin: "auto",
        }}
      >
        <AuditHistory data={data} />
      </div>
    </Modal>
  );
};

export default MemberHistoryUserModal;
