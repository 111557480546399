import { TableCell, TableHead, TableRow } from "@mui/material";

const FaturaKonsimentoTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ fontWeight: 700 }}>Return No</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Konşimento No</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Alıcı</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Varış Ülke</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Mal Hizmet Kur Tutarı</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Mal Hizmet TL Tutarı</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default FaturaKonsimentoTableHead;
