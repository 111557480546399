import {
  Avatar,
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  Button,
  Tooltip,
  IconButton,
  CircularProgress,
  Checkbox
} from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../../api/axios";
import {
  checkedShipmentsState,
  myShipmentListState,
  selectedMyShipmentListItemState,
  shipmentsListArrayLengthState,
  shipmentDetailModalState
} from "../../../atoms/myShipments";
import {
  memberBasicSearchState
} from "../../../atoms/members";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { adminShipmentDimensionDetailState } from "../../../atoms/myShipmentsAdmin";
import ListItemLinks from "./ListItemLinks";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import FlightTakeoffOutlinedIcon from "@mui/icons-material/FlightTakeoffOutlined";
import FlightLandOutlinedIcon from "@mui/icons-material/FlightLandOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { CircleFlag } from "react-circle-flags";
import shipmentLogo_0 from "../../../img/myShipments/gonderilerim_0.svg";
import shipmentLogo_1 from "../../../img/myShipments/gonderilerim_1.svg";
import shipmentLogo_2 from "../../../img/myShipments/gonderilerim_2.svg";
import shipmentLogo_4 from "../../../img/myShipments/gonderilerim_4.svg";
import shipmentLogo_5 from "../../../img/myShipments/gonderilerim_5.svg";
import MessageTime from "../../../utils/MessageTime";
import LocalPrice from "../../../utils/LocalPrice";
import FormatHeadlineText from "../../../utils/FormatHeadlineText";
import ParcelType from "../../../utils/ParcelType";
import Currency from "../../../utils/CurrencySymbol";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import countries from "../../../json/countries.json";
import Errors from "../../../utils/Errors";
import { useLocation, useNavigate } from "react-router-dom";

const MyShipmentsListItem = () => {
  const myShipmentList = useRecoilValue(myShipmentListState);
  const shipmentsListArrayLength = useRecoilValue(
    shipmentsListArrayLengthState
  );

  const [selectedMyShipmentListItem, setSelectedMyShipmentListItem] =
    useRecoilState(selectedMyShipmentListItemState);

  console.log(selectedMyShipmentListItem)
  const [checkedShipments, setCheckedShipments] = useRecoilState(
    checkedShipmentsState
  );

  const [, setBasicSearch] = useRecoilState(memberBasicSearchState);

  const [, setShipmentDimensionDetail] = useRecoilState(
    adminShipmentDimensionDetailState
  );

  const setShipmentDetailModal = useSetRecoilState(shipmentDetailModalState);

  /* Copy Ref Number */

  const [copied, setCopied] = useState(false);

  const [detailLoading, setDetailLoading] = useState(false);

  const handleClick = async (e) => {
    try {
      await navigator.clipboard.writeText(e);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Kopyalama Hatası: ", err);
    }
  };

  useEffect(() => {
    if (!checkedShipments.find((e) => e === true || e === false)) {
      if (shipmentsListArrayLength !== 0) {
        setCheckedShipments(new Array(shipmentsListArrayLength).fill(false));
      }
    }
  }, []);

  const handleSwitchChange = useCallback(
    (id) => {
      const shipment = myShipmentList.find((item) => item.id === id);
      setSelectedMyShipmentListItem(
        selectedMyShipmentListItem.find((e) => e.id === shipment.id)
          ? selectedMyShipmentListItem.filter((e) => e.id !== shipment.id)
          : [...selectedMyShipmentListItem, shipment]
      );
    },
    [setSelectedMyShipmentListItem, selectedMyShipmentListItem, myShipmentList]
  );

  const handleShipmentListItemDimension = useCallback(() => {
    setShipmentDimensionDetail({
      status: true,
      data: selectedMyShipmentListItem,
    });
  }, [setShipmentDimensionDetail, selectedMyShipmentListItem]);

  // Member Navigate

  const navigate = useNavigate();

  const navigateMember = (clickedId) => {
    // Tıklanan üye ID'sini parcel state'ine atanacak
    setBasicSearch(clickedId);
    navigate("/members");
  };

  // Gönderi Takip Butonu

  const handleShipmentDetail = useCallback(async () => {
    setDetailLoading(true);
    await axios
      .get(`parcel/detail-parcel/${selectedMyShipmentListItem.id}`)
      .then((response) => {
        if (response.data.data.length === 0) {
          toast.info(response.data.message);
        } else {
          setShipmentDetailModal({
            status: true,
            data: response.data.data,
            shipment: selectedMyShipmentListItem,
          });
        }
      })
      .catch((error) => Errors(error))
      .finally(() => setDetailLoading(false));
  }, [selectedMyShipmentListItem, setShipmentDetailModal]);

  const trackingShipmentButtonColor =
    Number(selectedMyShipmentListItem.paid) === 0 || Number(selectedMyShipmentListItem.status) === 8
      ? "iyzi-button-red"
      : "iyzi-button-green";

  return myShipmentList.map((shipment, key) => (
    <Box
      key={shipment.id}
      className="myShipments-list-wrapper"
      sx={{
        backgroundColor:
          Number(shipment?.deleted) === 1
            ? "var(--pastelRed) !important"
            : Number(shipment.status) === 8 ||
              Number(shipment.status) === 13 ||
              Number(shipment.status) === 14 ||
              Number(shipment.status) === 15 ||
              Number(shipment.status) === 16 ||
              Number(shipment.status) === 17 ||
              Number(shipment.status) === 18 ||
              Number(shipment.status) === 19 ||
              Number(shipment.status) === 20 ||
              Number(shipment.status) === 36 ||
              Number(shipment.status) === 0.05
              ? "#fdfd965c !important"
              : "var(--lighterBg) !important",
      }}
    >
      <Box className="list-steps">
        <div className="list-steps-headline">
          <FormGroup>
            <FormControlLabel
              className="list-picker"
              control={
                <Checkbox
                  checked={
                    !!selectedMyShipmentListItem.find(
                      (item) => item.id === shipment.id
                    )
                  }
                  onChange={() => handleSwitchChange(shipment.id)}
                />
              }
            />
          </FormGroup>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div style={{ textAlign: "start" }}>
              <span
                style={{
                  fontSize: "inherit",
                  color: "var(--btnBgBlue)",
                  fontWeight: 600,
                }}
              >
                Gönderici Adı:{" "}
              </span>
              {shipment?.sender_name}
            </div>
            <div style={{ textAlign: "start" }}>
              <span
                style={{
                  fontSize: "inherit",
                  color: "var(--btnBgBlue)",
                  fontWeight: 600,
                }}
              >
                Alıcı Adı:{" "}
              </span>
              {shipment?.receiver_name}
            </div>
            <div>
              <span
                style={{
                  color: "var(--btnBgBlue)",
                  fontWeight: 600,
                  fontSize: "inherit",
                }}
              >
                {shipment.doc_type === "N" ? "Paket" : "Döküman"}
              </span>
              &nbsp;-&nbsp;{MessageTime(shipment.created_at)}
              <span
                style={{
                  fontWeight: 400,
                  fontSize: "inherit",
                }}
                className="list-steps-parcel-ref-no"
              >
                &nbsp;-&nbsp;
                <span
                  style={{
                    color: "var(--btnBgBlue)",
                    fontWeight: 500,
                    fontSize: "inherit",
                    textDecoration: "underline",
                  }}
                >
                  {shipment.parcel_ref_no}
                </span>
              </span>
              &nbsp;-&nbsp;
              <span
                style={{
                  color: "var(--btnBgBlue)",
                  fontWeight: 500,
                  fontSize: "inherit",
                }}
              >
                {ParcelType(shipment.parcel_type)}
              </span>
            </div>
            <div style={{ textAlign: "start" }}>
              <span
                style={{
                  fontSize: "inherit",
                  color: "var(--btnBgBlue)",
                  fontWeight: 600,
                  display: "flex",
                  gap: "5px"
                }}
              >
                Member ID:{" "}
                <p onClick={() => navigateMember(shipment?.get_parcel_member?.id)} style={{
                  cursor: "pointer"
                }}>{shipment?.get_parcel_member?.id}</p>
              </span>
            </div>
            <div style={{ textAlign: "start" }}>
              <span
                style={{
                  fontSize: "inherit",
                  color: "var(--btnBgBlue)",
                  fontWeight: 600,
                }}
              >
                Member:{" "}
              </span>
              {shipment?.get_parcel_member?.name}
            </div>
          </div>
        </div>
        <Box className="steps-where-to">
          <Box className="to-integration">
            {shipment?.get_integration_parcel?.platform && (
              <>
                <Tooltip title={shipment?.get_integration_parcel?.platform}>
                  <img
                    className="to-integration-platform"
                    style={{
                      height: shipment?.get_integration_parcel?.platform === "excel" ? "40px" : ""
                    }}
                    src={
                      shipment?.get_integration_parcel?.platform === "excel"
                        ? "https://seeklogo.com/images/E/excel-logo-974BFF9CB9-seeklogo.com.png"
                        : `images/integrations/${shipment?.get_integration_parcel?.platform === "ETSY"
                          ? "etsy_fast"
                          : shipment?.get_integration_parcel?.platform?.toLowerCase()
                        }.svg`
                    }
                    alt={shipment?.get_integration_parcel?.platform}
                  />
                </Tooltip>
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: 700,
                    color: "var(--text)",
                  }}
                >
                  {shipment?.get_integration_parcel?.receipt_id}
                </span>
              </>
            )}
          </Box>
          <Box className="to-plane">
            {countries.find((e) => e.code === shipment.sender_country).label ===
              "Turkey"
              ? "TÜRKİYE"
              : countries
                .find((e) => e.code === shipment.sender_country)
                .label.toUpperCase()}
            <div className="plane-flag">
              <CircleFlag
                countryCode={countries
                  .find((e) => e.code === shipment.sender_country)
                  .code.toLowerCase()}
                height="24"
              />
              <FlightTakeoffOutlinedIcon id="take-off" />
            </div>
          </Box>
          <Box
            sx={{
              backgroundColor: shipment?.tracking_awb
                ? "inherit"
                : shipment?.status === "0.05"
                  ? "#fdfd965c !important"
                  : "var(--lighterBg) !important",
              width: "150px",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              borderRadius: "var(--inputBorderRadius)!important",
              boxShadow: shipment.tracking_awb ? "var(--shadow)" : "inherit",
            }}
            className="to-tracking"
          >
            <div
              style={{
                fontSize: "14px",
                fontWeight: 700,
                textAlign: "center",
                width: "100%",
              }}
            >
              {shipment.tracking_awb ? "Takip No" : ""}
            </div>
            <div
              style={{
                color: "inherit",
                fontSize: "inherit",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {shipment.tracking_awb}
              {shipment.tracking_awb && (
                <Tooltip
                  title={copied ? "Kopyalandı!" : "Kopyalamak için tıkla"}
                >
                  <IconButton
                    onClick={() => handleClick(shipment.tracking_awb)}
                    onMouseLeave={() => setCopied(false)}
                  >
                    <ContentCopyOutlinedIcon
                      sx={{ width: "16px", height: "16px" }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </Box>
          <Box className="to-plane">
            {countries
              .find((e) => e.code === shipment.receiver_country)
              .label.toUpperCase()}
            <div className="plane-flag">
              <FlightLandOutlinedIcon id="land" />
              <CircleFlag
                countryCode={countries
                  .find((e) => e.code === shipment.receiver_country)
                  .code.toLowerCase()}
                height="24"
              />
            </div>
          </Box>
        </Box>
        <Box className="stepper-wrapper-css">
          <Tooltip
            title={Number(shipment.status_stage) === 0 && shipment.status_name}
          >
            <div className="step-wrapper-css">
              <div
                className="step-css"
                style={
                  Number(shipment.status) === 8
                    ? { backgroundColor: "var(--shipmentRed)" }
                    : Number(shipment.status_stage) === 0
                      ? { backgroundColor: "var(--shipmentYellow)" }
                      : { backgroundColor: "var(--shipmentGreen)" }
                }
              >
                <img
                  src={shipmentLogo_0}
                  className="step-logo-css"
                  alt="Gönderi 1.Aşama Görseli"
                />
              </div>
            </div>
          </Tooltip>
          <Tooltip
            title={Number(shipment.status_stage) === 1 && shipment.status_name}
          >
            <div className="step-wrapper-css">
              <div
                className="step-css"
                style={
                  Number(shipment.status) === 8
                    ? { backgroundColor: "var(--shipmentRed)" }
                    : Number(shipment.status_stage) === 1
                      ? { backgroundColor: "var(--shipmentYellow)" }
                      : Number(shipment.status_stage) < 1
                        ? { backgroundColor: "var(--shipmentLight)" }
                        : { backgroundColor: "var(--shipmentGreen)" }
                }
              >
                <img
                  src={shipmentLogo_1}
                  className="step-logo-css"
                  alt="Gönderi 2.Aşama Görseli"
                />
              </div>
            </div>
          </Tooltip>
          <Tooltip
            title={Number(shipment.status_stage) === 2 && shipment.status_name}
          >
            <div className="step-wrapper-css">
              <div
                className="step-css"
                style={
                  Number(shipment.status) === 8
                    ? { backgroundColor: "var(--shipmentRed)" }
                    : Number(shipment.status_stage) === 2
                      ? { backgroundColor: "var(--shipmentYellow)" }
                      : Number(shipment.status_stage) < 2
                        ? { backgroundColor: "var(--shipmentLight)" }
                        : { backgroundColor: "var(--shipmentGreen)" }
                }
              >
                <img
                  src={shipmentLogo_2}
                  className="step-logo-css"
                  alt="Gönderi 3.Aşama Görseli"
                />
              </div>
            </div>
          </Tooltip>
          <Tooltip
            title={Number(shipment.status_stage) === 3 && shipment.status_name}
          >
            <div className="step-wrapper-css">
              <div
                className="step-css"
                style={
                  Number(shipment.status) === 8
                    ? { backgroundColor: "var(--shipmentRed)" }
                    : Number(shipment.status_stage) === 3
                      ? { backgroundColor: "var(--shipmentYellow)" }
                      : Number(shipment.status_stage) < 3
                        ? { backgroundColor: "var(--shipmentLight)" }
                        : { backgroundColor: "var(--shipmentGreen)" }
                }
              >
                <Avatar
                  className="step-shipment-logo-css"
                  src={`/images/${shipment.get_parcel_supplier?.supplier_logo}`}
                />
              </div>
            </div>
          </Tooltip>
          <Tooltip
            title={Number(shipment.status_stage) === 4 && shipment.status_name}
          >
            <div className="step-wrapper-css">
              <div
                className="step-css"
                style={
                  Number(shipment.status) === 8
                    ? { backgroundColor: "var(--shipmentRed)" }
                    : Number(shipment.status_stage) === 4
                      ? { backgroundColor: "var(--shipmentYellow)" }
                      : Number(shipment.status_stage) < 4
                        ? { backgroundColor: "var(--shipmentLight)" }
                        : { backgroundColor: "var(--shipmentGreen)" }
                }
              >
                <img
                  src={shipmentLogo_4}
                  className="step-logo-css"
                  alt="Gönderi 5.Aşama Görseli"
                />
              </div>
            </div>
          </Tooltip>
          <Tooltip
            title={Number(shipment.status_stage) === 5 && shipment.status_name}
          >
            <div className="step-wrapper-css">
              <div
                className="step-css"
                style={
                  Number(shipment.status) === 8
                    ? { backgroundColor: "var(--shipmentRed)" }
                    : Number(shipment.status_stage) === 5
                      ? { backgroundColor: "var(--shipmentYellow)" }
                      : Number(shipment.status_stage) < 5
                        ? { backgroundColor: "var(--shipmentLight)" }
                        : { backgroundColor: "var(--shipmentGreen)" }
                }
              >
                <img
                  src={shipmentLogo_5}
                  className="step-logo-css"
                  alt="Gönderi 6.Aşama Görseli"
                />
              </div>
            </div>
          </Tooltip>
          {/* <div className="step-connector-css">
                  <div
                    className={shipment.status >= 1 ? "step active" : "step1"}
                  ></div>
                  <div
                    className={shipment.status >= 2 ? "step active" : "step2"}
                  ></div>
                  <div
                    className={shipment.status >= 3 ? "step active" : "step3"}
                  ></div>
                  <div
                    className={shipment.status >= 4 ? "step active" : "step4"}
                  ></div>
                  <div
                    className={shipment.status >= 5 ? "step active" : "step5"}
                  ></div>
                </div> */}
        </Box>
        {shipment.doc_type === "D" && (
          <Box className="steps-info">
            <Box
              sx={{
                padding: "10px",
                borderRadius: "var(--inputBorderRadius)",
                ":hover": {
                  boxShadow: "var(--shadow)",
                  cursor: "pointer",
                },
              }}
              onClick={
                shipment.doc_type === "N" ? handleShipmentListItemDimension : ""
              }
              className="steps-info-wrapper info-member"
            >
              <div className="info-top">Üye Tarafından Beyan Edilen Kilo</div>
              <div className="info-top-mobil">Üye Ölçü Beyanı</div>
              {shipment.doc_type === "N" ? (
                <div className="info-bottom">
                  <div className="info-bottom-subline">
                    NET KG
                    <div className="info-bottom-subline-number">
                      {Number(shipment.gw).toFixed(2)}
                    </div>
                  </div>
                  <div className="info-bottom-subline">
                    DESİ
                    <div className="info-bottom-subline-number">
                      {Number(shipment.vw).toFixed(2)}
                    </div>
                  </div>
                  <div className="info-bottom-subline">
                    NAVLUN KG
                    <div className="info-bottom-subline-number">
                      {Number(shipment.cw).toFixed(2)}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="info-bottom"
                  style={{ justifyContent: "center" }}
                >
                  <div className="info-bottom-subline">
                    NET KG
                    <div className="info-bottom-subline-number">
                      {Number(shipment?.cw).toFixed(2)}
                    </div>
                  </div>
                </div>
              )}
            </Box>

            <Box
              id="integer_done"
              className="steps-info-done"
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Button
                style={{
                  textTransform: "none",
                }}
                className="steps-info-done-button"
              >
                {shipment.paid_name}
              </Button>
              <div
                style={{
                  color: "var(--priceText)",
                  backgroundColor: "var(--background)",
                  padding: "10px",
                  borderRadius: "var(--wrapperBorderRadius)",
                  boxShadow: "var(--shadow)",
                  fontSize: "12px",
                }}
              >
                Güncel:{" "}
                <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                  {FormatHeadlineText(shipment.status_name)}
                </span>
                <span style={{ fontWeight: 600, fontSize: "inherit" }}></span>
              </div>
            </Box>
            <Box className="steps-info-wrapper info-system">
              <div className="info-top">
                iyziTeam Tarafından Beyan Edilen Kilo
              </div>
              <div
                className="info-bottom"
                style={{
                  justifyContent: "center",
                }}
              >
                <div className="info-bottom-subline">
                  NET KG
                  <div className="info-bottom-subline-number">0</div>
                </div>
              </div>
            </Box>
          </Box>
        )}

        {shipment.doc_type !== "D" && (
          <Box className="steps-info">
            <Box
              sx={{
                padding: "10px",
                borderRadius: "var(--inputBorderRadius)",
                ":hover": {
                  boxShadow: "var(--shadow)",
                  cursor: "pointer",
                },
              }}
              onClick={
                shipment.doc_type === "N" ? handleShipmentListItemDimension : ""
              }
              className="steps-info-wrapper info-member"
            >
              <div className="info-top">Üye Tarafından Beyan Edilen Kilo</div>
              <div className="info-top-mobil">Üye Ölçü Beyanı</div>
              {shipment.doc_type === "N" ? (
                <div className="info-bottom">
                  <div className="info-bottom-subline">
                    NET KG
                    <div className="info-bottom-subline-number">
                      {Number(shipment.gw).toFixed(2)}
                    </div>
                  </div>
                  <div className="info-bottom-subline">
                    DESİ
                    <div className="info-bottom-subline-number">
                      {Number(shipment.vw).toFixed(2)}
                    </div>
                  </div>
                  <div className="info-bottom-subline">
                    NAVLUN KG
                    <div className="info-bottom-subline-number">
                      {Number(shipment.cw).toFixed(2)}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="info-bottom"
                  style={{ justifyContent: "center" }}
                >
                  <div className="info-bottom-subline">
                    NET KG
                    <div className="info-bottom-subline-number">
                      {Number(shipment?.cw).toFixed(2)}
                    </div>
                  </div>
                </div>
              )}
            </Box>
            {shipment?.deleted === "0" && (
              <Box
                id="seperate_done"
                className="steps-info-done show"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <span
                    className={
                      shipment.paid === "0"
                        ? "steps-info-done-price"
                        : "steps-info-done-price paid"
                    }
                  >
                    {LocalPrice(
                      shipment.paid === "0"
                        ? shipment.remaining_payment
                        : shipment.get_parcel_price_informations
                          ?.filter(
                            (e) => Number(e.paid) === Number(shipment.paid)
                          )
                          .reduce(
                            (acc, val) => acc + Number(val.paid_price),
                            0
                          )
                    )}
                    {Currency(shipment.currency)}
                  </span>
                  <Button
                    style={{
                      textAlign: "center",
                      color: "white",
                      backgroundColor:
                        shipment.paid === "0" ? "#eb7062" : "#86b817",
                      textTransform: "none",
                    }}
                  >
                    {shipment.paid_name}
                  </Button>
                </div>
                <div
                  style={{
                    color: "var(--priceText)",
                    backgroundColor: "var(--background)",
                    padding: "10px",
                    borderRadius: "var(--wrapperBorderRadius)",
                    boxShadow: "var(--shadow)",
                    fontSize: "12px",
                  }}
                >
                  Güncel:{" "}
                  <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                    {shipment?.status_name}
                  </span>
                  <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                    {shipment?.get_parcel_documents.length > 0 && (
                      <div>
                        {shipment.get_parcel_documents
                          .slice(-1)
                          .map((document, index) => {
                            const result =
                              document.type === "booking_damaged_parcel"
                                ? "Hasarlı / Teslim Alındı"
                                : document.type === "booking_cancel_parcel"
                                  ? "Hasarlı / Reddedildi"
                                  : null;
                            if (result) {
                              return (
                                <div>
                                  {shipment?.status !== "2.16" && (
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        color: "var(--priceText)",
                                        fontWeight: "lighter",
                                      }}
                                      key="additionalStatus"
                                    >
                                      Kurye Kaydı Durumu:{" "}
                                      <span
                                        style={{
                                          fontWeight: 600,
                                          fontSize: "inherit",
                                        }}
                                      >
                                        {result}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              );
                            }

                            return null;
                          })}
                      </div>
                    )}
                  </span>
                </div>
              </Box>
            )}
            <Box
              sx={{
                padding: "10px",
                borderRadius: "var(--inputBorderRadius)",
                ":hover": {
                  boxShadow: "var(--shadow)",
                  cursor: "pointer",
                },
              }}
              onClick={
                shipment.doc_type === "N" ? handleShipmentListItemDimension : ""
              }
              className="steps-info-wrapper info-system"
            >
              <div className="info-top">
                iyziTeam Tarafından Beyan Edilen Kilo
              </div>
              <div className="info-top-mobil">iyziTeam Ölçü Beyanı</div>
              {shipment.doc_type === "N" ? (
                <div className="info-bottom">
                  <div className="info-bottom-subline">
                    NET KG
                    <div className="info-bottom-subline-number">
                      {Number(shipment?.gw_real).toFixed(2)}
                    </div>
                  </div>
                  <div className="info-bottom-subline">
                    DESİ
                    <div className="info-bottom-subline-number">
                      {Number(shipment?.vw_real).toFixed(2)}
                    </div>
                  </div>
                  <div className="info-bottom-subline">
                    NAVLUN KG
                    <div className="info-bottom-subline-number">
                      {Number(shipment?.cw_real).toFixed(2)}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="info-bottom"
                  style={{ justifyContent: "center" }}
                >
                  <div className="info-bottom-subline">
                    NET KG
                    <div className="info-bottom-subline-number">
                      {Number(shipment?.cw_real).toFixed(2)}
                    </div>
                  </div>
                </div>
              )}
            </Box>
          </Box>
        )}
        {shipment.get_parcel_notes.length !== 0 &&
          shipment.get_parcel_notes
            .filter((e) => e.type === "1")
            .map((e) => (
              <Box key={e.id} className="steps-note">
                <span>Kullanıcı Notu :</span> {e.description}
              </Box>
            ))}
        {shipment.get_parcel_notes.length !== 0 && (
          <Box className="steps-note">
            <span>Admin Notu :</span>{" "}
            {
              shipment.get_parcel_notes[shipment.get_parcel_notes.length - 1]
                .description
            }
          </Box>
        )}
      </Box>
      <Divider
        sx={{
          width: "1px",
          backgroundColor: "var(--phoneDigitBg)",
          margin: "0 10px",
        }}
        className="steps-divider"
      />
      <Box className="list-links-wrapper">
        <div className="list-links-container show">
          <h6 style={{ fontSize: "13px", textAlign: "center" }}>
            {shipment.parcel_ref_no}
          </h6>
          <ListItemLinks shipment={shipment} />
        </div>
      </Box>
    </Box>
  ));
};

export default MyShipmentsListItem;
