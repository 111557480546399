import "../../../style/myWallet/MyWallet.css";
import { Box, createTheme, Pagination, ThemeProvider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useRecoilState, useRecoilValue } from "recoil";
import { useInView, animated } from "@react-spring/web";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import axios from "../../../api/axios";
import { drawerOpenState } from "../../../atoms/viewPadding";
import NavBar from "../sidebar/NavBar";
import { ToastContainer } from "react-toastify";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import WrapperStyle from "../../../utils/WrapperStyle";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import FinanceGonderiIadeSearch from "./FinanceGonderiIade/FinanceGonderiIadeSearch";
import {
  dynamicRefundableAmountState,
  FinanceGonderiIadeCurrentPageState,
  FinanceGonderiIadeDataState,
  FinanceGonderiIadeTotalPageState,
} from "../../../atoms/finance";
import FinanceGonderiIadeTable from "./FinanceGonderiIade/FinanceGonderiIadeTable";
import Errors from "../../../utils/Errors";
import NotificationBar from "../sidebar/NotificationBar";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const FinanceGonderiIade = () => {
  DynamicTitle("Gönderi İade Talepleri - Admin");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const drawerOpen = useRecoilValue(drawerOpenState);

  const [loading, setLoading] = useState(false);

  const navUnder = {
    paddingLeft: drawerOpen ? "264px" : "64px",
    transition: "all 150ms linear",
    backgroundColor: "var(--lighterBg)",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  };

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  // Sayfa Fonksiyonları
  const [, setFinanceGonderiIadeData] = useRecoilState(FinanceGonderiIadeDataState);
  const [, setDynamicRefundableAmount] = useRecoilState(dynamicRefundableAmountState);
  const [FinanceGonderiIadeTotalPage, setFinanceGonderiIadeTotalPage] = useRecoilState(
    FinanceGonderiIadeTotalPageState
  );
  const [FinanceGonderiIadeCurrentPage, setFinanceGonderiIadeCurrentPage] = useRecoilState(
    FinanceGonderiIadeCurrentPageState
  );

  const getData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`parcel-return/demand/list`, {
        params: {
          limit: 500,
          page: 1,
          order_direction: "DESC",
        },
      })
      .then((res) => {
        setFinanceGonderiIadeData(res.data.data);
        setFinanceGonderiIadeTotalPage(res.data.totalPage);
        setFinanceGonderiIadeCurrentPage(res.data.currentPage);
        setLoading(false);


        axios
          .get(`parcel-return/demand/list`)
          .then((response) => (response.data))


          .then((data) => {
            setDynamicRefundableAmount(data);
          })
          .catch((error) => Errors(error));
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [
    setDynamicRefundableAmount,
    setFinanceGonderiIadeData,
    setFinanceGonderiIadeTotalPage,
    setFinanceGonderiIadeCurrentPage,
  ]);

  useEffect(() => {
    // getData fonksiyonunu her 30 saniyede bir çağırır.
    const intervalId = setInterval(getData, 30000); // 30000 milisaniye, yani 30 saniye.

    // Component unmount olduğunda interval durdurulur.
    return () => clearInterval(intervalId);
  }, []);

  // const dataInterval = setInterval(getData, 30000);

  const handleBalancePageChange = useCallback(
    async (e, page) => {
      setLoading(true);
      await axios
        .get(`parcel-return/demand/list`, {
          params: {
            limit: 500,
            page: page,
            order_direction: "DESC",
          },
        })
        .then((res) => {
          setFinanceGonderiIadeData(res.data);
          setFinanceGonderiIadeTotalPage(res.data.totalPage);
          setFinanceGonderiIadeCurrentPage(res.data.currentPage);
          setLoading(false);
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    },
    [setFinanceGonderiIadeData, setFinanceGonderiIadeTotalPage, setFinanceGonderiIadeCurrentPage]
  );

  useEffect(() => {
    getData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <NotificationBar />
      <section
        className="my-wallet"
        style={{
          paddingLeft: drawerOpen ? 0 : "64px",
          transition: "all 150ms linear",
        }}
      >
        <NavBar />
        <ToastContainer />
        <Box className="nav-under" style={navUnder}>
          <div>
            <ReceiptLongOutlinedIcon /> Gönderi İade Bilgileri
          </div>
        </Box>
        <Box className="my-wallet-wrapper" style={WrapperStyle()}>
          <animated.div ref={ref} style={springs}>
            <Box className="my-wallet-container">
              {loading ? (
                <Box
                  sx={{
                    height: "500px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img className="loading-logo" src={miniLogo} alt="" />
                </Box>
              ) : (
                <>
                  <FinanceGonderiIadeSearch />
                  {FinanceGonderiIadeTotalPage > 1 && (
                    <Pagination
                      showFirstButton
                      showLastButton
                      size="small"
                      count={FinanceGonderiIadeTotalPage}
                      page={FinanceGonderiIadeCurrentPage}
                      onChange={handleBalancePageChange}
                    />
                  )}
                  <FinanceGonderiIadeTable />
                  {FinanceGonderiIadeTotalPage > 1 && (
                    <Pagination
                      showFirstButton
                      showLastButton
                      size="small"
                      count={FinanceGonderiIadeTotalPage}
                      page={FinanceGonderiIadeCurrentPage}
                      onChange={handleBalancePageChange}
                    />
                  )}
                </>
              )}
            </Box>
          </animated.div>
        </Box>
      </section>
    </ThemeProvider>
  );
};

export default FinanceGonderiIade;
