import { atom } from "recoil";

export const pastAddressModalState = atom({
  key: "pastAddressModal",
  default: { status: false, data: [] },
});

export const caseTransactionsState = atom({
  key: "caseTransactions",
  default: { status: false, data: [] },
});

export const shipmentCheckPointState = atom({
  key: "shipmentCheckPoint",
  default: { status: false, data: [] },
});

export const createAPIState = atom({
  key: "createAPI",
  default: { status: false, data: [] },
});

export const showDatesState = atom({
  key: "showDates",
  default: { status: false, data: [] },
});

export const pastAwbState = atom({
  key: "pastAwb",
  default: { status: false, data: [] },
});

export const adminReturnTypeState = atom({
  key: "adminReturnType",
  default: "",
});

export const adminAddressIdState = atom({
  key: "adminAddressId",
  default: "",
});

export const adminReturnReasonState = atom({
  key: "adminReturnReason",
  default: "",
});

export const adminDeliveryTypeState = atom({
  key: "adminDeliveryType",
  default: "",
});

export const adminReturnExitState = atom({
  key: "adminReturnExit",
  default: 0,
});

export const adminDomesticTypeState = atom({
  key: "adminDomesticType",
  default: 0,
});

export const adminUserTypeState = atom({
  key: "adminUserType",
  default: 0,
});

export const adminShipmentDimensionDetailState = atom({
  key: "adminShipmentDimensionDetail",
  default: { status: false, data: {} },
});

export const adminShipmentContractDetailsState = atom({
  key: "adminShipmentContractDetails",
  default: { status: false, data: {} },
});

export const historyOfChangeModalState = atom({
  key: "historyOfChangeModal",
  default: { status: false, data: {} },
});

export const domesticInfoModalState = atom({
  key: "domesticInfoModal",
  default: { status: false, data: {} },
});
