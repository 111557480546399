import "../../../style/myWallet/MyWallet.css";
import {
  Box,
  createTheme,
  Pagination,
  ThemeProvider,
  Button,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useInView, animated } from "@react-spring/web";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import axios from "../../../api/axios";
import { drawerOpenState } from "../../../atoms/viewPadding";
import NavBar from "../sidebar/NavBar";
import { ToastContainer } from "react-toastify";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import WrapperStyle from "../../../utils/WrapperStyle";
import FinanceNav from "./FinanceNav";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import FinanceHavaleSearch from "./financeHavale/FinanceHavaleSearch";
import {
  dynamicRefundableAmountState,
  financeHavaleCurrentPageState,
  financeHavaleDataState,
  financeHavaleTotalPageState,
} from "../../../atoms/finance";
import FinanceHavaleTable from "./financeHavale/FinanceHavaleTable";
import FinanceHavaleFiles from "./financeHavale/FinanceHavaleFiles";
import Errors from "../../../utils/Errors";
import NotificationBar from "../sidebar/NotificationBar";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const FinanceHavale = () => {
  DynamicTitle("Havale Talebi - Admin");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const drawerOpen = useRecoilValue(drawerOpenState);

  const [loading, setLoading] = useState(false);

  const navUnder = {
    paddingLeft: drawerOpen ? "264px" : 0,
    transition: "all 150ms linear",
    backgroundColor: "var(--lighterBg)",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  };

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  // Sayfa Fonksiyonları

  const setFinanceHavaleData = useSetRecoilState(financeHavaleDataState);
  const setDynamicRefundableAmount = useSetRecoilState(
    dynamicRefundableAmountState
  );
  const [financeHavaleTotalPage, setFinanceHavaleTotalPage] = useRecoilState(
    financeHavaleTotalPageState
  );
  const [financeHavaleCurrentPage, setFinanceHavaleCurrentPage] =
    useRecoilState(financeHavaleCurrentPageState);

  const getData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`money-order`, {
        params: {
          limit: 500,
          page: 1,
          order_direction: "DESC",
        },
      })
      .then((res) => {
        setFinanceHavaleData(res.data.data);
        setFinanceHavaleTotalPage(res.data.totalPage);
        setFinanceHavaleCurrentPage(res.data.currentPage);

        const ids = [...new Set(res.data.data.map((e) => e.member_id))];

        Promise.all(
          ids.map((id) =>
            axios
              .get(`money-order`)
              .then((response) => ({ member_id: id, ...response.data }))
          )
        )
          .then((data) => {
            setDynamicRefundableAmount(data);
          })
          .catch((error) => Errors(error));
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [
    setDynamicRefundableAmount,
    setFinanceHavaleData,
    setFinanceHavaleTotalPage,
    setFinanceHavaleCurrentPage,
  ]);

  useEffect(() => {
    // getData fonksiyonunu her 30 saniyede bir çağırır.
    const intervalId = setInterval(getData, 30000); // 30000 milisaniye, yani 30 saniye.

    // Component unmount olduğunda interval durdurulur.
    return () => clearInterval(intervalId);
  }, []);

  // const dataInterval = setInterval(getData, 30000);

  const handleBalancePageChange = useCallback(
    async (e, page) => {
      setLoading(true);
      await axios
        .get(`money-order`, {
          params: {
            limit: 500,
            page: page,
            order_direction: "DESC",
          },
        })
        .then((res) => {
          setFinanceHavaleData(res.data);
          setFinanceHavaleTotalPage(res.data.totalPage);
          setFinanceHavaleCurrentPage(res.data.currentPage);
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    },
    [
      setFinanceHavaleData,
      setFinanceHavaleTotalPage,
      setFinanceHavaleCurrentPage,
    ]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <ThemeProvider theme={theme}>
      <NotificationBar />
      <section
        className="my-wallet"
        style={{
          paddingLeft: drawerOpen ? 0 : "64px",
          transition: "all 150ms linear",
        }}
      >
        <NavBar />
        <ToastContainer />
        <FinanceHavaleFiles />
        <Box className="nav-under" style={navUnder}>
          <div>
            <ReceiptLongOutlinedIcon /> Bakiye İade Talepleri
          </div>
        </Box>

        <Box className="my-wallet-wrapper" style={WrapperStyle()}>
          <FinanceNav />
          <animated.div ref={ref} style={springs}>
            <Box className="my-wallet-container">
              {loading ? (
                <Box
                  sx={{
                    height: "500px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img className="loading-logo" src={miniLogo} alt="" />
                </Box>
              ) : (
                <>
                  <FinanceHavaleSearch />
                  <Box>
                    <Button
                      onClick={getData}
                      sx={{
                        textTransform: "none",
                        color: "white",
                        backgroundColor: "var(--blueBrand)",
                        "&:hover": {
                          opacity: 0.8,
                          backgroundColor: "var(--blueBrand)",
                          transition: "all 0.3s ease",
                        },
                      }}
                    >
                      Listeyi Güncelle
                    </Button>
                  </Box>
                  {financeHavaleTotalPage > 1 && (
                    <Pagination
                      showFirstButton
                      showLastButton
                      size="small"
                      count={financeHavaleTotalPage}
                      page={financeHavaleCurrentPage}
                      onChange={handleBalancePageChange}
                    />
                  )}
                  <FinanceHavaleTable />
                  {financeHavaleTotalPage > 1 && (
                    <Pagination
                      showFirstButton
                      showLastButton
                      size="small"
                      count={financeHavaleTotalPage}
                      page={financeHavaleCurrentPage}
                      onChange={handleBalancePageChange}
                    />
                  )}
                </>
              )}
            </Box>
          </animated.div>
        </Box>
      </section>
    </ThemeProvider>
  );
};

export default FinanceHavale;
