import {
  Box,
  Button,
  CircularProgress,
  Modal,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  myWalletDocModalState,
  selectedArrangementItemState,
} from "../../../../atoms/myWallet";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import uploadIcon from "../../../../img/icons/upload_icon.svg";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  financeHavaleFilesModalState,
  selectedHavaleFilesState,
} from "../../../../atoms/finance";
import MessageTime from "../../../../utils/MessageTime";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const FinanceHavaleFiles = () => {
  const [financeHavaleFilesModal, setFinanceHavaleFilesModal] = useRecoilState(
    financeHavaleFilesModalState
  );
  const [selectedHavaleFiles, setSelectedHavaleFiles] = useRecoilState(
    selectedHavaleFilesState
  );

  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClose = useCallback(() => {
    setFinanceHavaleFilesModal(false);
    setSelectedHavaleFiles([]);
  }, [setFinanceHavaleFilesModal, setSelectedHavaleFiles]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      console.log(acceptedFiles);
      setSelectedDocuments(acceptedFiles);
    },
  });

  const getFile = (path) => {
    axios
      .get(`document/get-file/${path.replaceAll("/", "-")}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        let blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        let image = window.URL.createObjectURL(blob);

        window.open(image, "_blank");
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal open={financeHavaleFilesModal} onClose={handleClose}>
        <Box className="my-wallet-arrangement-bill-modal">
          {/* <Typography
            className="my-wallet-arrangement-bill-modal-headline"
            variant="h6"
          >
            Muvafakatname Yükle
          </Typography>
          <Box className="dropzone-wrapper">
            <Box className="dropzone">
              <div className="dropzone-area" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dropzone-area-text">
                  <img src={uploadIcon} alt="Upload Icon" />
                  <span className="dropzone-area-text-headline">
                    Dosyayı sürükleyip bırakın veya{" "}
                    <span style={{ color: "var(--info)" }}>Gözat</span>
                  </span>
                  <span className="dropzone-area-text-sub">
                    Desteklenen Format: PDF
                  </span>
                </div>
              </div>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "var(--gap)",
                width: "100%",
              }}
            >
              <Button className="iyzi-button" onClick={handleClose}>
                İptal
              </Button>
              {loading ? (
                <div
                  style={{
                    width: "64px",
                    height: "33px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress
                    sx={{ width: "24px!important", height: "24px!important" }}
                  />
                </div>
              ) : (
                <Button className="iyzi-button" onClick={uploadSelected}>
                  Kaydet
                </Button>
              )}
            </Box>
          </Box> */}
          <Box>
            {/* {selectedDocuments.length !== 0 && (
              <Box
                sx={{
                  color: "var(--priceText)",
                  fontWeight: 700,
                  fontSize: "14px",
                  marginBottom: "20px",
                  marginTop: "20px",
                  paddingLeft: "8px",
                }}
              >
                Yüklenecek Dosya İsmi
              </Box>
            )}
            {selectedDocuments.length !== 0 &&
              selectedDocuments.map((document, key) => (
                <Box
                  sx={{
                    border: "1px solid var(--phoneDigitBg)",
                    marginBottom: "10px",
                    padding: "10px",
                    borderRadius: "var(--wrapperBorderRadius)",
                    fontSize: "13px",
                    cursor: "pointer",
                    color: "var(--priceText)",
                    transition: "all 150ms linear",
                    ":hover": {
                      backgroundColor: "var(--pastelBlue)",
                      transition: "all 150ms linear",
                    },
                  }}
                  key={document.id}
                  onClick={() => getFile(document)}
                >
                  <span
                    style={{
                      fontSize: "13px",
                      color: "var(--priceText)",
                      fontWeight: "700",
                    }}
                  >
                    {key + 1}
                  </span>{" "}
                  - {document.name}
                </Box>
              ))} */}
            <h5 style={{ color: "var(--priceText)", marginBottom: "10px" }}>
              Görüntülenecek Dosyalar
            </h5>
            {selectedHavaleFiles.map((e, key) => (
              <Box
                sx={{
                  border: "1px solid var(--inputBorderColor)",
                  borderRadius: "var(--wrapperBorderRadius)",
                  padding: "10px",
                  transition: "all 150ms linear",
                  cursor: "pointer",

                  ":hover": {
                    backgroundColor: "var(--pastelBlue)",
                    transition: "all 150ms linear",
                  },
                }}
                onClick={() => getFile(e.file_path)}
                key={key}
              >
                {MessageTime(e.created_at)} - {e.file_name}
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default FinanceHavaleFiles;
