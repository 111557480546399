import { IconButton, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

const SystemSettingTableHead = ({ handleAdd }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ padding: "8px" }} align="center">
          <Tooltip title="Ayar Ekle">
            <IconButton onClick={handleAdd}>
              <AddCircleOutlineOutlinedIcon sx={{ color: "var(--btnBgBlue)" }} />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell style={{ padding: "8px", fontWeight: 700 }}>Tip</TableCell>
        <TableCell style={{ padding: "8px", fontWeight: 700 }}>Alt Tip</TableCell>
        <TableCell style={{ padding: "8px", fontWeight: 700 }}>Değer</TableCell>
        <TableCell style={{ padding: "8px", fontWeight: 700 }}>Durum</TableCell>
        <TableCell style={{ padding: "8px", fontWeight: 700 }}>Oluşturulma Tarihi</TableCell>
        <TableCell style={{ padding: "8px", fontWeight: 700 }} align="center">
          Değişiklik Tarihçesi
        </TableCell>
        <TableCell style={{ padding: "8px" }} align="center" />
      </TableRow>
    </TableHead>
  );
};

export default SystemSettingTableHead;
