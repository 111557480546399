const MessageTime = (time) => {
  const date = new Date(time);
  const localDate = new Date(
    new Date(date.getTime() - 3 * 60 * 60 * 1000) -
      date.getTimezoneOffset() * 60000
  );

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const timeString = localDate.toLocaleTimeString("tr-TR", {
    hour12: false,
    hour: "numeric",
    minute: "numeric",
  });

  var d = new Date(date),
    month =
      (d.getMonth() + 1).toLocaleString().length === 1
        ? `0${d.getMonth() + 1}`
        : d.getMonth() + 1,
    day =
      d.getDate().toLocaleString().length === 1
        ? `0${d.getDate()}`
        : d.getDate(),
    year = d.getFullYear();

  if (localDate.toDateString() === today.toDateString()) {
    return `Bugün ${timeString}`;
  } else if (localDate.toDateString() === yesterday.toDateString()) {
    return `Dün ${timeString}`;
  } else {
    return `${day}.${month}.${year} ${timeString}`;
  }
};

export default MessageTime;
