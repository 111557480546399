import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  ClickAwayListener,
  Collapse,
  IconButton,
  styled,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  expandIyziNoteState,
  teamNoteListState,
  ticketState,
  ticketDocumentState,
  expandFilesState,
} from "../../../../atoms/support";
import { useCallback, useEffect, useRef, useState } from "react";
import MessageTime from "../../../../utils/MessageTime";
import axios from "../../../../api/axios";
import Errors from "../../../../utils/Errors";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
}));

const SupportDetailIyziNoteAdmin = () => {
  const ticket = useRecoilValue(ticketState);

  const [teamNoteList, setTeamNoteList] = useRecoilState(teamNoteListState);
  const [expandIyziNote, setExpandIyziNote] =
    useRecoilState(expandIyziNoteState);
  const [ticketDocument, setTicketDocument] =
    useRecoilState(ticketDocumentState);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isSelectAnyFile, setIsSelectAnyFile] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [, setExpandFiles] = useRecoilState(expandFilesState);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const teamNoteContainer = useRef(null);

  const handleClickAway = () => {
    setExpandIyziNote(false);
  };

  const handleExpandTeamClick = useCallback(() => {
    setExpandIyziNote(!expandIyziNote);
  }, [setExpandIyziNote, expandIyziNote]);

  const handleSendTeamNote = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }
      setLoading(true);
      axios
        .post(`ticket/ticket-note/add/${ticket.id}`, {
          message,
        })
        .then((response) => {
          setTeamNoteList([...teamNoteList, response.data.data]);
        })
        .catch((error) => Errors(error))
        .finally(() => {
          setMessage("");
          setLoading(false);
        });
    },
    [message, ticket.id, teamNoteList, setTeamNoteList]
  );

  const handleFileSelect = (e) => {
    const filesArray = Array.from(e.target.files);
    setSelectedFiles(filesArray);
    e.target.value = null;
  };
  useEffect(() => {
    if (selectedFiles.length !== 0) {
      setIsSelectAnyFile(true);
    } else {
      setIsSelectAnyFile(false);
    }
  }, [selectedFiles.length]);

  const uploadSelected = useCallback(async () => {
    setUploadLoading(true);
    let formData = new FormData();
    Array.from(selectedFiles).forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
    formData.append("parent_id", ticket.id);
    formData.append("parent_type", "Ticket");
    formData.append("type", "ticket_iyzi_team");

    await axios
      .post(`document/add`, formData)
      .then((response) => {
        toast.success("Dosyalar Başarıyla Yüklendi");
        setTicketDocument([...ticketDocument, ...response.data.data]);
        setExpandFiles(true);
      })
      .catch((error) => Errors(error))
      .finally(() => {
        setUploadLoading(false);
        setSelectedFiles([]);
      });
  }, [
    selectedFiles,
    setExpandFiles,
    setTicketDocument,
    ticket,
    ticketDocument,
  ]);

  useEffect(() => {
    if (teamNoteContainer.current) {
      teamNoteContainer.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [teamNoteList]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendTeamNote();
    }
  };

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClickAway}
    >
      <Card className="support-detail-files" sx={{ width: "100%" }}>
        <CardActions
          sx={{ cursor: "pointer" }}
          onClick={handleExpandTeamClick}
          disableSpacing
        >
          <Box className="support-detail-files-header">
            <GroupsIcon /> IyziTeam
          </Box>
          <ExpandMore
            expand={expandIyziNote}
            onClick={handleExpandTeamClick}
            aria-expanded={expandIyziNote}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expandIyziNote} timeout="auto" unmountOnExit>
          <CardContent className="support-detail-files-content">
            <Box sx={{ overflowY: "auto", maxHeight: 400 }}>
              {teamNoteList?.map((note, key) => (
                <Box className="note-content" key={key}>
                  <Typography className="note-content-date">
                    {MessageTime(note.created_at)} - {note.get_created_by?.name}{" "}
                    {note.get_created_by?.surname}
                  </Typography>
                  <Typography
                    className="note-content-text"
                    dangerouslySetInnerHTML={{
                      __html: note.message.replace(/\n/g, "<br>"),
                    }}
                  ></Typography>
                </Box>
              ))}
              <div ref={teamNoteContainer} />
            </Box>
            <form
              onSubmit={handleSendTeamNote}
              className="detail-chat-send-message-team"
            >
              <TextField
                label="Mesajınızı Yazınız"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                size="small"
                fullWidth
                required
                autoComplete="off"
                autoFocus
                multiline
                InputProps={{
                  sx: {
                    borderRadius: "10px !important",
                  },
                  onKeyPress: handleKeyPress,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        type="button"
                        variant="contained"
                        component="label"
                        sx={{
                          backgroundColor: "transparent",
                          outline: 0,
                          border: 0,
                          boxShadow: 0,
                          padding: 0,
                          minWidth: 0,
                          ":hover": {
                            backgroundColor: "transparent",
                            outline: 0,
                            border: 0,
                            boxShadow: 0,
                          },
                        }}
                      >
                        <AttachFileIcon
                          sx={{
                            color: "var(--priceText)",
                            cursor: "pointer",
                            transition: "all 150ms linear",
                            ":hover": {
                              color: "var(--info)",
                              transition: "all 150ms linear",
                            },
                          }}
                        />
                        <input
                          multiple
                          onChange={handleFileSelect}
                          type="file"
                          hidden
                        />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              {loading ? (
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress
                    sx={{
                      width: "24px !important",
                      height: "24px !important",
                    }}
                  />
                </Box>
              ) : selectedFiles.length > 0 ? (
                <Button
                  type="button"
                  className="detail-chat-send-message-team-button"
                  onClick={uploadSelected}
                >
                  <BackupOutlinedIcon sx={{ color: "var(--lighterBg)" }} />
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="detail-chat-send-message-team-button"
                >
                  <img src="/images/icons/send_icon.svg" alt="Gönder Görseli" />
                </Button>
              )}
            </form>
          </CardContent>
        </Collapse>
      </Card>
    </ClickAwayListener>
  );
};

export default SupportDetailIyziNoteAdmin;
