import "../../style/support/SupportAdmin.css";
import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useRecoilState, useRecoilValue } from "recoil";
import { useInView, animated } from "@react-spring/web";
import axios from "../../api/axios";
import {
  appointeeListState,
  openSupportDetailState,
  supportCurrentPageState,
  supportListState,
  supportTotalPageState,
} from "../../atoms/support";
import { notificationOpenState } from "../../atoms/viewPadding";
import { DynamicTitle } from "../../utils/DynamicTitle";
import NavBar from "../components/sidebar/NavBar";
import { ToastContainer } from "react-toastify";
import miniLogo from "../../img/logos/logo_white_mini_loading.svg";
import SupportListTableAdmin from "../components/support/SupportListTableAdmin";
import SupportDetailAdmin from "../components/support/SupportDetailAdmin";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import SupportTanzimModal from "../components/support/supportModal/SupportTanzimModal";
import WrapperStyle from "../../utils/WrapperStyle";
import Errors from "../../utils/Errors";
import SupportCaseMessagesModal from "../components/support/supportModal/SupportCaseMessagesModal";
import SupportSearch from "../components/support/SupportSearch";
import {
  adminParcelMemberIdState,
  lastTicketState,
} from "../../atoms/myShipments";
import NotificationBar from "../components/sidebar/NotificationBar";

const SupportAdmin = () => {
  const [token] = useCookies(["jwt"]);
  const user = JSON.parse(localStorage.getItem("user"));
  const roleID = user?.user?.user_role.id;
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const openSupportDetail = useRecoilValue(openSupportDetailState);

  const [, setNotificationOpen] = useRecoilState(notificationOpenState);
  const [, setSupportList] = useRecoilState(supportListState);
  const [, setSupportCurrentPage] = useRecoilState(supportCurrentPageState);
  const [, setSupportTotalPage] = useRecoilState(supportTotalPageState);
  const [, setAppointeeList] = useRecoilState(appointeeListState);
  const [, setAdminParcelMemberId] = useRecoilState(adminParcelMemberIdState);

  const lastTicket = useRecoilValue(lastTicketState);

  console.log(lastTicket);

  DynamicTitle("Destek - Admin");

  const navigate = useNavigate();

  // awb parametresi url'de bulunuyorsa silmek için.
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const awbParam = searchParams.get("awb");

  const [loading, setLoading] = useState(false);

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const getSupportData = useCallback(async () => {
    try {
      const response = await axios.get(
        `ticket?order_direction=DESC&limit=10&page=1&order_by=updated_at`
      );
      setSupportList(response.data.data);
      setSupportTotalPage(response.data.totalPages);
      setSupportCurrentPage(response.data.currentPage);
    } catch (error) {
      Errors(error);
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        navigate("/");
      }
    }
  }, [setSupportTotalPage, setSupportCurrentPage, navigate, setSupportList]);

  const handleAppointeeList = useCallback(() => {
    axios
      .get(
        `user?&order_direction=DESC&limit=10&page=1&detailed=1&role_id=${
          roleID === 10 ? "10" : "9,10"
        }`
      )
      .then((response) => {
        setAppointeeList(response.data.data);
      });
  }, [setAppointeeList]);

  useEffect(() => {
    getSupportData();
    handleAppointeeList();

    if (awbParam) {
      navigate(`/support/ticket?awb=${awbParam}`);
    }
  }, [
    handleAppointeeList,
    setNotificationOpen,
    getSupportData,
    awbParam,
    navigate,
  ]);

  return (
    <>
      <NotificationBar />
      <section className="support">
        <NavBar />
        <ToastContainer />
        {/* <RateComment /> */}
        <SupportCaseMessagesModal />
        <SupportTanzimModal />

        {loading && (
          <Box
            sx={{
              height: "500px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="loading-logo" src={miniLogo} alt="" />
          </Box>
        )}
        {!loading && (
          <Box className="support-wrapper" style={WrapperStyle()}>
            <animated.div ref={ref} style={springs}>
              {!openSupportDetail && <SupportSearch />}

              <Box className="support-container">
                <h6
                  style={{
                    color: "var(--priceText)",
                    marginTop: "var(--gap2x)",
                    marginBottom: "var(--gap4x)",
                  }}
                >
                  Destek Talebi
                </h6>
                {
                  // if lastTicket === number then show the last ticket
                  lastTicket && !openSupportDetail && (
                    <p className="support-request-admin-link">
                      Son kontrol edilen talep {lastTicket}
                    </p>
                  )
                }
                {openSupportDetail ? (
                  <SupportDetailAdmin />
                ) : (
                  <>
                    <NavLink
                      className="support-request-admin-link"
                      to={"/support/ticket"}
                    >
                      Destek Talebi Oluştur
                    </NavLink>
                    <SupportListTableAdmin />
                  </>
                )}
                
              </Box>
            </animated.div>
          </Box>
        )}
      </section>
    </>
  );
};

export default SupportAdmin;
