import { TableCell, TableRow } from "@mui/material";
import FormatDateHour from "../../../../utils/FormatDateHour";
import IyziBalanceDescription from "../../../../utils/IyziBalanceDescription";
import Currency from "../../../../utils/CurrencySymbol";

const FinanceBakiyeTableBody = ({ item }) => {
  return (
    <TableRow>
      <TableCell>
        {item.get_member?.uyumsoft_code === null
          ? "---"
          : item.get_member?.uyumsoft_code}
      </TableCell>
      <TableCell>{item.get_member?.name}</TableCell>
      <TableCell>{item.get_member?.id}</TableCell>
      <TableCell>
        {item.get_parcel === null ? "---" : item.get_parcel?.parcel_ref_no}
      </TableCell>
      <TableCell sx={{ padding: "5px", minWidth: "80px" }}>
        {FormatDateHour(item.created_at)}
      </TableCell>
      <TableCell>
        {IyziBalanceDescription(Number(item.description_code))}
      </TableCell>
      <TableCell>
        {item.navlun_price} {Currency(item.currency)}
      </TableCell>
      <TableCell>
        {item.garanti_price} {Currency(item.currency)}
      </TableCell>
      <TableCell>
        {item.iyzico_price} {Currency(item.currency)}
      </TableCell>
      <TableCell>
        {item.prize_price} {Currency(item.currency)}
      </TableCell>
      <TableCell>
        {item?.insurance_compensation_price} {Currency(item.currency)}
      </TableCell>
      <TableCell>
        {item.navlun_refund_price} {Currency(item.currency)}
      </TableCell>
      <TableCell>
        {item.loyality_price} {Currency(item.currency)}
      </TableCell>
      <TableCell>
        {item.total_balance} {Currency(item.currency)}
      </TableCell>
    </TableRow>
  );
};

export default FinanceBakiyeTableBody;
