import React from "react";
import { Button } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { ffdBase64State, ffdDataState } from "../../../atoms/operation";
import PDFReader from "./PDFReader";

function OperationSingleAndFrontDeskResp() {
  const [ffdData, setFfdData] = useRecoilState(ffdDataState);
  const [ffdBase64, setFfdBase64] = useRecoilState(ffdBase64State);
  const printDocument = () => {
    const byteCharacters = atob(ffdBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement("iframe");

    // The component should be mounted in the DOM tree before calling print
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.onload = function () {
      setTimeout(() => {
        // Give the browser some time to render the PDF
        iframe.contentWindow.print();
        URL.revokeObjectURL(blobUrl); // Free up memory
      }, 0);
    };
    iframe.src = blobUrl;
  };
  return (
    <div>
      <div
        className="operation-cargo-dom-resp-pdf"
        style={{
          maxWidth: "1100px",
          backgroundColor: "var(--lighterBg)",
          padding: "10px",
          borderRadius: "var(--wrapperBorderRadius)",
          boxShadow: "var(--shadow)",
        }}
      >
        <div className="resp-card-wrapper">
   
          <div style={{ display: " flex", justifyContent: "center" }}>
            <PDFReader base64String={ffdBase64} />
          </div>

          <div
            style={{
              display: " flex",
              justifyContent: "center",
              marginBottom: "10px",
              marginTop:"20px"
            }}
          >
            <Button
              onClick={printDocument}
              className="iyzi-button-blue"
              style={{ marginLeft: "3px" }}
            >
              Yazdır
            </Button>
          </div>

            {/*/<div
            style={{
              display: " flex",
              justifyContent: "end",
              marginBottom: "10px",
              marginTop:"20px"
            }}
          >
            <Button
              className="iyzi-button-blue"
              style={{ marginLeft: "3px" }}
            >
             Kaydet
            </Button>
          </div>/*/}
          {/*/ <div className="resp-card-container">
                <div className="resp-card-number">201178</div>
                <div className="resp-card-page">1/1</div>
                <div className="resp-card-barcode">
                  <img src="/images/karekod.png" alt="Karekod" />
                  <Typography>TR81539995GB</Typography>
                  <Typography>274737572834</Typography>
                </div>
                <Divider className="resp-card-divider" />
                <div className="resp-card-info">
                  <div className="resp-card-saler">
                    <Typography>SİNEM ŞAHİN</Typography>
                  </div>
                  <div className="resp-card-customer">
                    <Typography>Chris D Ross</Typography>
                    <Typography>FEDEX - X</Typography>
                  </div>
                </div>
              </div> /*/}
            
        </div>
      </div>
    </div>
  );
}

export default OperationSingleAndFrontDeskResp;
