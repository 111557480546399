import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Modal,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  memberChangeActivatedState,
  memberDocumentEditOpenState,
  memberListState,
  memberTotalPageState,
} from "../../../../atoms/members";
import { useCallback, useEffect, useState } from "react";
import MemberDocumentEditApproval from "./MemberDocumentEditApproval";
import MemberDocumentEditHeader from "./MemberDocumentEditHeader";
import MemberUsersTable from "./memberUsers/MemberUsersTable";
import axios from "../../../../api/axios";
import Errors from "../../../../utils/Errors";
import MemberDocumentEditAddresses from "./MemberDocumentEditAddresses";
import miniLogo from "../../../../img/logos/logo_white_mini_loading.svg";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import MemberCreateUserModal from "./memberUsers/MemberCreateUserModal";

const modalTheme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: 20,
          overflowY: "auto",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          margin: "0 !important",
          ":before": {
            height: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: "48px!important",
        },
        content: {
          margin: "0 !important",
        },
      },
    },
  },
});

const MemberDocumentEdit = () => {
  const addressesLimit = 4;

  const [memberDocumentEditOpen, setMemberDocumentEditOpen] = useRecoilState(
    memberDocumentEditOpenState
  );

  const [, setMemberList] = useRecoilState(memberListState);
  const [, setMemberTotalPage] = useRecoilState(memberTotalPageState);
  const [memberChangeActivated, setMemberChangeActivated] = useRecoilState(
    memberChangeActivatedState
  );

  const [documents, setDocuments] = useState([]);
  const [memberIsActive, setMemberIsActive] = useState(null);

  const [senderAddresses, setSenderAddresses] = useState([]);
  const [senderCurrentPage, setSenderCurrentPage] = useState(1);
  const [senderTotalPages, setSenderTotalPages] = useState(1);

  const [receiverAddresses, setReceiverAddresses] = useState([]);
  const [receiverCurrentPage, setReceiverCurrentPage] = useState(1);
  const [receiverTotalPages, setReceiverTotalPages] = useState(1);

  const [addressExpand, setAddressExpand] = useState(false);
  const [addressUpdated, setAddressUpdated] = useState(true);
  const [addressLoading, setAddressLoading] = useState(false);

  const [createUserModal, setCreateUserModal] = useState(false);

  const getData = useCallback(async () => {
    setMemberDocumentEditOpen({ status: false, data: {} });
    await axios
      .get("member", {
        params: {
          order_direction: "DESC",
          limit: 10,
          page: 1,
        },
      })
      .then((response) => {
        setMemberList(response.data?.data);
        setMemberTotalPage(response.data?.totalPages);
        setDocuments([]);

        // Action Reset
        setMemberChangeActivated(false);
      })
      .catch((error) => {
        Errors(error);
      });
  }, [setMemberList, setMemberTotalPage, setMemberDocumentEditOpen, setMemberChangeActivated]);

  const handleClose = () => {
    if (memberChangeActivated) {
      getData();
    } else {
      setMemberDocumentEditOpen({ status: false, data: {} });
      setDocuments([]);
    }
    setAddressUpdated(true);
    setAddressExpand(false);
  };

  const handleExpandAddress = () => {
    setAddressExpand(!addressExpand);

    if (!addressExpand && addressUpdated) {
      setAddressLoading(true);

      const senderAddressRequest = axios.get(`member-address`, {
        params: {
          member_id: memberDocumentEditOpen.data?.id,
          address_type: 4,
          limit: addressesLimit,
        },
      });
      const receiverAddressRequest = axios.get(`member-address`, {
        params: {
          member_id: memberDocumentEditOpen.data?.id,
          address_type: 5,
          limit: addressesLimit,
        },
      });

      Promise.all([senderAddressRequest, receiverAddressRequest])
        .then((responses) => {
          setSenderAddresses(responses[0].data.data);
          setSenderCurrentPage(responses[0].data.currentPage);
          setSenderTotalPages(responses[0].data.totalPages);

          setReceiverAddresses(responses[1].data.data);
          setReceiverCurrentPage(responses[1].data.currentPage);
          setReceiverTotalPages(responses[1].data.totalPages);

          setAddressUpdated(false);
        })
        .catch((error) => Errors(error))
        .finally(() => setAddressLoading(false));
    }
  };

  const handleCreateUser = () => {
    setCreateUserModal(true);
  };

  useEffect(() => {
    if (memberDocumentEditOpen.status) {
      setMemberIsActive(
        memberDocumentEditOpen.data?.get_member_users[0]?.email_verified_at ? true : false
      );
      if (memberDocumentEditOpen.data?.get_member_documents?.length !== 0) {
        setDocuments(memberDocumentEditOpen.data?.get_member_documents);
      }
    }
  }, [memberDocumentEditOpen]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Modal open={memberDocumentEditOpen.status} onClose={handleClose}>
        <Box
          sx={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            width: "100%",
            maxWidth: "1100px",
            margin: "20px auto",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "var(--gap2x)",
            overFlowY: "auto",
          }}
        >
          <MemberDocumentEditHeader
            memberIsActive={memberIsActive}
            setMemberIsActive={setMemberIsActive}
            member={memberDocumentEditOpen.data}
          />
          <Accordion
            sx={{ backgroundColor: "var(--background)", borderRadius: "var(--inputBorderRadius)" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">Evraklar ({documents?.length})</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "var(--gap2x)",
                  backgroundColor: "var(--background)",
                  padding: "10px 20px",
                  borderRadius: "var(--inputBorderRadius)",
                }}
              >
                {documents?.length === 0 && "Yüklü evrak bulunamadı."}
                {documents &&
                  documents?.map((document, key) => (
                    <MemberDocumentEditApproval
                      key={key}
                      member={memberDocumentEditOpen.data}
                      document={document}
                      documents={documents}
                      setDocuments={setDocuments}
                      documentType={document.type}
                    />
                  ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ backgroundColor: "var(--background)", borderRadius: "var(--inputBorderRadius)" }}
            expanded={addressExpand}
            onChange={handleExpandAddress}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h5">Adresler</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "var(--gap2x)",
                  backgroundColor: "var(--background)",
                  padding: "10px 20px",
                  borderRadius: "var(--inputBorderRadius)",
                }}
              >
                {!addressLoading &&
                  senderAddresses?.length === 0 &&
                  receiverAddresses?.length === 0 &&
                  memberDocumentEditOpen.data?.get_member_invoice_addresses?.length === 0 &&
                  memberDocumentEditOpen.data?.get_member_booking_addresses?.length === 0 &&
                  "Kayıtlı adres bulunamadı."}
                {addressLoading && (
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img className="loading-logo" src={miniLogo} alt="" />
                  </div>
                )}
                {!addressLoading &&
                  (senderAddresses?.length !== 0 ||
                    receiverAddresses?.length !== 0 ||
                    memberDocumentEditOpen.data?.get_member_invoice_addresses?.length !== 0 ||
                    memberDocumentEditOpen.data?.get_member_booking_addresses?.length !== 0) && (
                    <MemberDocumentEditAddresses
                      invoiceAddresses={memberDocumentEditOpen.data?.get_member_invoice_addresses}
                      bookingAddresses={memberDocumentEditOpen.data?.get_member_booking_addresses}
                      setAddressUpdated={setAddressUpdated}
                      senderAddresses={senderAddresses}
                      senderCurrentPage={senderCurrentPage}
                      senderTotalPages={senderTotalPages}
                      setSenderAddresses={setSenderAddresses}
                      setSenderCurrentPage={setSenderCurrentPage}
                      setSenderTotalPages={setSenderTotalPages}
                      receiverAddresses={receiverAddresses}
                      receiverCurrentPage={receiverCurrentPage}
                      receiverTotalPages={receiverTotalPages}
                      setReceiverAddresses={setReceiverAddresses}
                      setReceiverCurrentPage={setReceiverCurrentPage}
                      setReceiverTotalPages={setReceiverTotalPages}
                      member={memberDocumentEditOpen.data}
                      addressesLimit={addressesLimit}
                    />
                  )}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ backgroundColor: "var(--background)", borderRadius: "var(--inputBorderRadius)" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">
                Kullanıcılar ({memberDocumentEditOpen.data?.get_member_users?.length})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "var(--gap2x)",
                  backgroundColor: "var(--background)",
                  padding: "10px 20px",
                  borderRadius: "var(--inputBorderRadius)",
                }}
              >
                <Button
                  onClick={handleCreateUser}
                  sx={{ width: "fit-content" }}
                  className="iyzi-button-blue"
                >
                  <PersonAddOutlinedIcon /> Kullanıcı Ekle
                </Button>
                <MemberUsersTable member={memberDocumentEditOpen.data} />
              </Box>
            </AccordionDetails>
          </Accordion>
          <MemberCreateUserModal
            createUserModal={createUserModal}
            setCreateUserModal={setCreateUserModal}
            memberId={memberDocumentEditOpen.data?.id}
          />
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default MemberDocumentEdit;
