import { CircularProgress, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useState } from "react";
import AuditHistoryDialog from "../modal/AuditHistoryDialog";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import Errors from "../../../../utils/Errors";
import DeleteDialog from "../modal/DeleteDialog";
import EditSystemSetting from "./EditSystemSetting";
import FormatDateTR from "../../../../utils/FormatDateTR";

const SystemSettingTableBody = ({ item, data, setData, getData }) => {
  const [loading, setLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [auditLoading, setAuditLoading] = useState(false);
  const [auditOpen, setAuditOpen] = useState(false);
  const [auditData, setAuditData] = useState([]);
  const [auditCurrentPage, setAuditCurrentPage] = useState(1);
  const [auditTotalPages, setAuditTotalPages] = useState(1);
  const [editOpen, setEditOpen] = useState(false);

  const handleEditAction = () => {
    setEditOpen(true);
  };

  const handleDeleteAction = () => {
    setDeleteDialog(true);
  };

  const handleDelete = () => {
    setLoading(true);
    axios
      .post(`system-setting/delete/${item?.id}`)
      .then((response) => {
        const filteredData = data?.filter((e) => e.id !== item?.id);
        setData(filteredData);
        toast.success(response.data.message);
        if (filteredData?.length === 0) {
          getData();
        }
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  const handleAuditHistory = () => {
    setAuditLoading(true);

    axios
      .get(`system-setting/audit-history/${item?.id}`)
      .then((response) => {
        if (response.data?.data?.length === 0) {
          toast.info("İşlem tarihçesinde veri bulunmamaktadır.");
        } else {
          setAuditOpen(true);
          setAuditData(response.data.data);
          setAuditCurrentPage(response.data.currentPage);
          setAuditTotalPages(response.data.totalPages);
          toast.success(response.data.message);
        }
      })
      .catch((error) => Errors(error))
      .finally(() => setAuditLoading(false));
  };

  const handleAuditHistoryPageChange = (e, page) => {
    setAuditLoading(true);

    axios
      .get(`system-setting/audit-history/${item?.id}`, { params: { page } })
      .then((response) => {
        if (response.data?.data?.length === 0) {
          toast.info("İşlem tarihçesinde veri bulunmamaktadır.");
        } else {
          setAuditOpen(true);
          setAuditData(response.data.data);
          setAuditCurrentPage(response.data.currentPage);
          setAuditTotalPages(response.data.totalPages);
          toast.success(response.data.message);
        }
      })
      .catch((error) => Errors(error))
      .finally(() => setAuditLoading(false));
  };

  return (
    <>
      <EditSystemSetting
        data={data}
        setData={setData}
        open={editOpen}
        setOpen={setEditOpen}
        item={item}
      />
      <DeleteDialog open={deleteDialog} setOpen={setDeleteDialog} handleDelete={handleDelete} />
      <AuditHistoryDialog
        data={auditData}
        open={auditOpen}
        setOpen={setAuditOpen}
        currentPage={auditCurrentPage}
        totalPage={auditTotalPages}
        handlePageChange={handleAuditHistoryPageChange}
      />
      <TableRow>
        <TableCell style={{ padding: "8px" }} align="center">
          <Tooltip title="Ayarı Düzenle">
            <IconButton onClick={handleEditAction}>
              <EditOutlinedIcon sx={{ color: "var(--btnBgBlue)" }} />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell style={{ padding: "8px" }}>{item?.type_name}</TableCell>
        <TableCell style={{ padding: "8px" }}>{item?.sub_type_name}</TableCell>
        <TableCell style={{ padding: "8px", maxWidth: "250px" }}>{item?.value_name}</TableCell>
        <TableCell style={{ padding: "8px" }}>{item?.status_name}</TableCell>
        <TableCell style={{ padding: "8px" }}>{FormatDateTR(item?.created_at)}</TableCell>
        <TableCell style={{ padding: "8px" }} align="center">
          {auditLoading ? (
            <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
          ) : (
            <Tooltip title="Ayar Değişiklik Tarihçesini İncele">
              <IconButton onClick={handleAuditHistory}>
                <HistoryIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell style={{ padding: "8px" }} align="center">
          {loading ? (
            <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
          ) : (
            <Tooltip title="Ayarı Sil">
              <IconButton onClick={handleDeleteAction}>
                <DeleteOutlineIcon sx={{ color: "var(--shipmentRed)" }} />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default SystemSettingTableBody;
