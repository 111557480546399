import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import Errors from "../../../utils/Errors";
import { CircularProgress, Pagination, Table, TableBody, TableContainer } from "@mui/material";
import SystemSettingTableHead from "./systemSetting/SystemSettingTableHead";
import SystemSettingTableBody from "./systemSetting/SystemSettingTableBody";
import AddSystemSetting from "./systemSetting/AddSystemSetting";

const SystemSetting = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [addOpen, setAddOpen] = useState(false);

  const getData = () => {
    axios
      .get(`system-setting`)
      .then((response) => {
        setData(response.data.data);
        setCurrentPage(response.data.currentPage);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  const handlePageChange = (e, page) => {
    setLoading(true);
    axios
      .get(`exchange-rate`, { params: { page } })
      .then((response) => {
        setData(response.data.data);
        setCurrentPage(response.data.currentPage);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  const handleAddSystemSettingAction = () => {
    setAddOpen(true);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <AddSystemSetting open={addOpen} setOpen={setAddOpen} data={data} setData={setData} />
      <div>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              minHeight: "50vh",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column", gap: "var(--gap)" }}>
            <TableContainer
              style={{
                padding: "20px",
                backgroundColor: "var(--lighterBg)",
                boxShadow: "var(--shadow)",
                borderRadius: "var(--wrapperBorderRadius)",
              }}
            >
              <Pagination
                showFirstButton
                showLastButton
                size="small"
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
              />
              <Table>
                <SystemSettingTableHead handleAdd={handleAddSystemSettingAction} />
                <TableBody>
                  {data.map((item) => (
                    <SystemSettingTableBody
                      key={item.id}
                      item={item}
                      data={data}
                      setData={setData}
                      getData={getData}
                    />
                  ))}
                </TableBody>
              </Table>
              <Pagination
                showFirstButton
                showLastButton
                size="small"
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                sx={{ margin: "10px 0" }}
              />
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export default SystemSetting;
