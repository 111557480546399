import { Box, Divider, Modal, ThemeProvider, createTheme } from "@mui/material";
import { useRecoilState } from "recoil";
import { useCallback } from "react";
import axios from "../../../../api/axios";
import {
  financeTazminIadeFilesModalState,
  selectedTazminIadeFilesState,
} from "../../../../atoms/finance";
import MessageTime from "../../../../utils/MessageTime";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const FinanceTazminIadeModal = () => {
  const [financeTazminIadeFilesModal, setFinanceTazminIadeFilesModal] =
    useRecoilState(financeTazminIadeFilesModalState);
  const [selectedTazminIadeFiles, setSelectedTazminIadeFiles] = useRecoilState(
    selectedTazminIadeFilesState
  );

  const handleClose = useCallback(() => {
    setFinanceTazminIadeFilesModal(false);
    setSelectedTazminIadeFiles([]);
  }, [setFinanceTazminIadeFilesModal, setSelectedTazminIadeFiles]);

  const getFile = (path) => {
    axios
      .get(`document/get-file/${path.replaceAll("/", "-")}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        let blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        let image = window.URL.createObjectURL(blob);

        window.open(image, "_blank");
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal open={financeTazminIadeFilesModal} onClose={handleClose}>
        <Box className="my-wallet-arrangement-bill-modal">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "var(--gap2x)",
            }}
          >
            <h5 style={{ color: "var(--priceText)" }}>Dosyalar</h5>
            {/* {selectedTazminIadeFiles?.member_consent_file?.length !== 0 && (
              <h5 style={{ color: "var(--priceText)" }}>
                Görüntülenecek Muvafakatname
              </h5>
            )} */}
            {selectedTazminIadeFiles?.member_consent_file?.map((e, key) => (
              <Box
                sx={{
                  border: "1px solid var(--inputBorderColor)",
                  borderRadius: "var(--wrapperBorderRadius)",
                  padding: "10px",
                  transition: "all 150ms linear",
                  cursor: "pointer",

                  ":hover": {
                    backgroundColor: "var(--pastelBlue)",
                    transition: "all 150ms linear",
                  },
                }}
                onClick={() => getFile(e.file_path)}
                key={key}
              >
                {key + 1}) {MessageTime(e.created_at)} - Muvafakatname
              </Box>
            ))}
            {selectedTazminIadeFiles?.member_invoice_file?.length !== 0 &&
              selectedTazminIadeFiles?.member_consent_file?.length !== 0 && (
                <Divider />
              )}
            {/* {selectedTazminIadeFiles?.member_invoice_file?.length !== 0 && (
              <h5 style={{ color: "var(--priceText)" }}>
                Görüntülenecek Fatura
              </h5>
            )} */}
            {selectedTazminIadeFiles?.member_invoice_file?.map((e, key) => (
              <Box
                sx={{
                  border: "1px solid var(--inputBorderColor)",
                  borderRadius: "var(--wrapperBorderRadius)",
                  padding: "10px",
                  transition: "all 150ms linear",
                  cursor: "pointer",

                  ":hover": {
                    backgroundColor: "var(--pastelBlue)",
                    transition: "all 150ms linear",
                  },
                }}
                onClick={() => getFile(e.file_path)}
                key={key}
              >
                {key + 1}) {MessageTime(e.created_at)} - Fatura
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default FinanceTazminIadeModal;
