const PreHeader = () => {
  return (
    <header>
      <div
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        className="sign-logo"
      >
        <img src={"/images/home/logo.svg"} alt="IyziShip Logo" style={{ height: "80px" }} />
      </div>
    </header>
  );
};

export default PreHeader;
