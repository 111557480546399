import { Table, TableBody, TableContainer, ThemeProvider, createTheme } from "@mui/material";
import ProfitTableHead from "./ProfitTableHead";
import ProfitTableBody from "./ProfitTableBody";
import { useState } from "react";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "13px",
        },
      },
    },
  },
});

const ProfitTable = ({ data, labels }) => {
  const [expand, setExpand] = useState(() =>
    labels ? labels.map((label) => ({ label, status: false })) : []
  );

  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        style={{
          backgroundColor: "var(--lighterBg)",
          padding: "20px",
          borderRadius: "var(--wrapperBorderRadius",
          boxShadow: "var(--shadow)",
        }}
      >
        <Table>
          <ProfitTableHead labels={labels} expand={expand} setExpand={setExpand} />
          <TableBody>
            {data?.map((item) => (
              <ProfitTableBody key={item?.id} item={item} data={data} expand={expand} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

export default ProfitTable;
