import { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Divider } from "@mui/material";

const SupportLateCloserChart = ({ data }) => {
  const ref = useRef(null);

  useEffect(() => {
    const createChart = (ref, data) => {
      if (ref.current) {
        const chartData = {
          labels: data?.labels,
          datasets: data?.datasets,
        };

        const chartOptions = {
          plugins: {
            datalabels: {
              color: "#fff",
              formatter: (value, context) => {
                const sum = context.chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
                const percentage = ((value * 100) / sum).toFixed(2) + "%";
                return percentage;
              },
              font: {
                size: 16,
              },
            },
            legend: {
              position: "bottom",
            },
          },
          responsive: true,
        };

        if (ref.current.chart) {
          ref.current.chart.destroy();
        }

        const newChart = new Chart(ref.current, {
          type: "pie",
          data: chartData,
          options: chartOptions,
          plugins: [ChartDataLabels],
        });

        ref.current.chart = newChart;
      }
    };

    createChart(ref, data);
  }, [data]);

  return (
    <div
      style={{
        backgroundColor: "var(--lighterBg)",
        boxShadow: "var(--shadow)",
        borderRadius: "var(--wrapperBorderRadius)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "var(--gap2x)",
          padding: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "var(--gap)" }}>
          <h6 style={{ color: "var(--priceText)", fontSize: "16px" }}>
            Kapanışı Geciken Dosyaların Konu Dağılımı
          </h6>
        </div>
      </div>
      <Divider sx={{ height: "1px", width: "100%", color: "var(--borderInputColor" }} />
      <div style={{ padding: "20px" }}>
        <canvas ref={ref} />
      </div>
    </div>
  );
};

export default SupportLateCloserChart;
