import "../../../style/operation/Operation.css";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useInView, animated } from "@react-spring/web";
import axios from "../../../api/axios";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import NavBar from "../sidebar/NavBar";
import { toast, ToastContainer } from "react-toastify";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import { useNavigate } from "react-router-dom";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import WrapperStyle from "../../../utils/WrapperStyle";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const OperationSecurityCheck = () => {
  DynamicTitle("Gönderi Seç -  Statü Güncelle - Admin");

  const navigate = useNavigate();

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [checkedAwb, setCheckedAwb] = useState(true);
  const [checkedHawb, setCheckedHawb] = useState(false);
  const [checkedPak, setCheckedPak] = useState(false);

  const [awb, setAwb] = useState("");
  const [hawb, setHawb] = useState("");
  const [pak, setPak] = useState("");

  const [loading, setLoading] = useState(false);

  const [manual, setManual] = useState(0);

  const [loadedParcel, setLoadedParcel] = useState({});
  const [statuChangeResponse, setStatuChangeResponse] = useState({});
  const [loadedParcelText, setLoadedParcelText] = useState("");

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  useEffect(() => {
    console.log("Durum Atlatma Güncellendi");
    setStatuChangeResponse(statuChangeResponse);
  }, [statuChangeResponse]);
  useEffect(() => {
    console.log("Manual Güncellendi");
    setManual(manual);
  }, [manual]);

  useEffect(() => {
    if (manual === 0 && hawb !== "" && awb !== "") {
      const timeoutId = setTimeout(() => {
        setLoading(true);
        getAndSetCustomParcel();
      }, 750);
      return () => {
        setLoading(false);
        clearTimeout(timeoutId);
      };
    }
    setAwb(awb);
    setHawb(hawb);
  }, [awb, hawb]);

  useEffect(() => {
    console.log("Loading Status Updated.");
    setLoading(loading);
  }, [loading]);

  const handleCustomStatuChange = (e) => {
    e.preventDefault();
    axios
      .post(`parcel/operation/set-custom-parcel`, {
        parcel_id: loadedParcel.id,
        status: loadedParcel.status,
      })
      .then((response) => {
        toast.success("İstek Başarıyla Çalıştırıldı");

        const cloned = JSON.parse(JSON.stringify(loadedParcel));
        cloned.status_text = response.data.data.status_text;
        setLoadedParcel(cloned);
        setStatuChangeResponse(response.data.data);
        setLoadedParcelText("");
        console.log(response);
      })
      .catch((error) => Errors(error));
  };

  const handleCheckedAwb = () => {
    setCheckedAwb(true);
    setCheckedHawb(false);
    setCheckedPak(false);
  };

  const handleCheckedHawb = () => {
    setCheckedAwb(false);
    setCheckedHawb(true);
    setCheckedPak(false);
  };
  const handleCheckedPak = () => {
    /*/setCheckedAwb(false);
    setCheckedHawb(false);/*/
    setCheckedPak(true);
  };

  const handleAwb = (e) => {
    const value = e.target.value.slice(0, 12);
    setAwb(value);
  };

  const handleHawb = (e) => {
    const value = e.target.value;
    setHawb(value);
  };
  const handlePak = (e) => {
    const value = e.target.value;
    setPak(value);
  };

  const getAndSetCustomParcel = () => {
    const parcel_ref_no = checkedAwb ? awb : "";
    const tracking_awb = checkedHawb ? hawb : "";

    if ((checkedAwb && awb !== "") || (checkedHawb && hawb !== "" && loading)) {
      axios
        .get(
          `parcel/operation/get-custom-parcel?parcel_ref_no=${parcel_ref_no}&tracking_awb=${tracking_awb}`
        )
        .then((response) => {
          console.log(response);
          setLoading(false);
          if (response.data.status == 200) {
            toast.success("Gönderi Getirildi");
            setLoadedParcel(response.data.data);
            setLoadedParcelText(response.data.data.customText);
            setAwb("");
            setHawb("");
          }
        })
        .catch((error) => Errors(error));
    } else {
      console.log("hawb veya ref no henüz okutulmadı.");
    }
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    const parcel_ref_no = checkedAwb ? awb : "";
    const tracking_awb = checkedHawb ? hawb : "";
    const pak = checkedPak ? 1 : 0;
    setManual(1);
    axios
      .get(
        `parcel/operation/get-custom-parcel?parcel_ref_no=${parcel_ref_no}&tracking_awb=${tracking_awb}&manual=${manual}&pak=${pak}`
      )
      .then((response) => {
        toast.success("Gönderi Getirildi");
        setLoadedParcel(response.data.data);
        setManual(0);
        console.log(response);
        setAwb("");
        setHawb("");
        setPak("");
      })
      .catch((error) => Errors(error));
  };

  useEffect(() => {
    if (awb.length === 12) {
      handleSubmit();
    }
  }, [awb]);

  return (
    <ThemeProvider theme={theme}>
      <section className="operation" style={{ paddingLeft: "64px" }}>
        <NavBar />
        <ToastContainer />
        {loading && (
          <Box
            sx={{
              height: "500px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="loading-logo" src={miniLogo} alt="" />
          </Box>
        )}
        {!loading && (
          <Box className="operation-wrapper" style={WrapperStyle()}>
            <animated.div ref={ref} style={springs}>
              <Box className="operation-container">
                <h6
                  style={{
                    color: "var(--priceText)",
                    marginTop: "var(--gap2x)",
                    marginBottom: "var(--gap4x)",
                  }}
                >
                  Gönderi İzle & Statü Atlatma
                </h6>
                <div className="operation-security-check">
                  <div style={{ display: "flex", gap: "var(--gap)" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedOutlinedIcon />}
                          checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                          checked={checkedAwb}
                          onChange={handleCheckedAwb}
                        />
                      }
                      label="Referans Numarası"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedOutlinedIcon />}
                          checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                          checked={checkedHawb}
                          onChange={handleCheckedHawb}
                        />
                      }
                      label="AWB"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedOutlinedIcon />}
                          checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                          checked={checkedPak}
                          onChange={handleCheckedPak}
                        />
                      }
                      label="PAK"
                    />
                  </div>
                  {checkedAwb && (
                    <form
                      style={{ display: "flex", gap: "var(--gap2x)" }}
                      onSubmit={handleSubmit}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        required
                        label={checkedHawb ? "Awb" : "Referans Numarası"}
                        value={awb}
                        onChange={handleAwb}
                      />
                      <Button onClick={handleSubmit} className="iyzi-button">
                        Ara
                      </Button>
                    </form>
                  )}
                  {checkedHawb && (
                    <form
                      style={{ display: "flex", gap: "var(--gap2x)" }}
                      onSubmit={handleSubmit}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        required
                        label="Hawb"
                        value={hawb}
                        onChange={handleHawb}
                      />
                      <Button onClick={handleSubmit} className="iyzi-button">
                        Ara
                      </Button>
                    </form>
                  )}
                  {/*/checkedPak && (
                    <form
                      style={{ display: "flex", gap: "var(--gap2x)" }}
                      onSubmit={handleSubmit}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        required
                        label="Pak"
                        value={pak}
                        onChange={handlePak}
                      />
                      <Button onClick={handleSubmit} className="iyzi-button">
                        Ara
                      </Button>
                    </form>
                  )/*/}
                </div>
                {loadedParcel && Object.keys(loadedParcel).length > 0 ? (
                  <>
                    <div
                      className="operation-security-check"
                      style={{
                        height: "500px",
                        backgroundColor: loadedParcel.backgroundColor,
                      }}
                    >
                      <div>
                        <Box className="steps-note">
                          <span style={{ color: "black" }}>
                            Gönderi Parcel Numarası :
                          </span>{" "}
                          {loadedParcel.parcel_ref_no}
                        </Box>
                        <Box className="steps-note">
                          <span style={{ color: "black" }}>
                            Gönderi Son Durumu :
                          </span>{" "}
                          {loadedParcel.status_text}
                        </Box>
                        <Box
                          className="steps-note"
                          style={{ marginTop: "10px" }}
                        >
                          <span style={{ color: "black" }}>
                            Gönderi Ödeme Durumu :
                          </span>{" "}
                          {parseInt(loadedParcel.paid) === 1
                            ? "Ödendi"
                            : "Ödenmedi"}
                          <Button
                            onClick={handleCustomStatuChange}
                            style={{ float: "right" }}
                            className="iyzi-button"
                          >
                            Statü Atlat
                          </Button>
                        </Box>
                      </div>


                      {
                        loadedParcelText ?  (
                            <>
                              <div style={{color:"red"}}>
                                {loadedParcelText}
                              </div>
                            </>
                        ) : null
                      }

                      {statuChangeResponse &&
                      Object.keys(statuChangeResponse).length > 0 ? (
                        <>
                          <div style={statuChangeResponse.style}>
                            {statuChangeResponse.text}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </>
                ) : null}
              </Box>
            </animated.div>
          </Box>
        )}
      </section>
    </ThemeProvider>
  );
};

export default OperationSecurityCheck;
