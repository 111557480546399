import { FormatReturnFieldWithDotsAndComma } from "../../../../utils/FormatFieldReturn";

const SupportInfos = ({ openedFile, lateCloser }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "var(--gap2x)" }}>
      <div
        style={{
          backgroundColor: "var(--lighterBg)",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          borderRadius: "var(--wrapperBorderRadius)",
          boxShadow: "var(--shadow)",
          gap: "var(--gap)",
          height: "-webkit-fill-available",
          maxHeight: 500,
        }}
      >
        <h6 style={{ color: "var(--priceText)", fontSize: "16px" }}>Açık Dosyalar</h6>
        <p style={{ color: "var(--btnBgBlue)", fontSize: "20px" }}>
          {FormatReturnFieldWithDotsAndComma(Number(openedFile))}{" "}
          <span style={{ fontSize: "inherit", fontWeight: "inherit", color: "var(--priceText)" }}>
            Adet
          </span>
        </p>
      </div>
      <div
        style={{
          backgroundColor: "var(--lighterBg)",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          borderRadius: "var(--wrapperBorderRadius)",
          boxShadow: "var(--shadow)",
          gap: "var(--gap)",
          height: "-webkit-fill-available",
          maxHeight: 500,
        }}
      >
        <h6 style={{ color: "var(--priceText)", fontSize: "16px" }}>
          Dosya Ortalama Kapatma Süresi
        </h6>
        <p style={{ color: "var(--btnBgBlue)", fontSize: "20px" }}>
          {FormatReturnFieldWithDotsAndComma(Number(lateCloser))}{" "}
          <span style={{ fontSize: "inherit", fontWeight: "inherit", color: "var(--priceText)" }}>
            Gün
          </span>
        </p>
      </div>
    </div>
  );
};

export default SupportInfos;
