import { useRecoilState } from "recoil";
import { domesticInfoModalState } from "../../../../atoms/myShipmentsAdmin";
import FormatDate from "../../../../utils/FormatDate";
import { Modal } from "@mui/material";

const DomesticAwbView = () => {
  const [domesticInfoModal, setDomesticInfoModal] = useRecoilState(domesticInfoModalState);

  const handleClose = () => {
    setDomesticInfoModal({ status: false, data: {} });
  };

  return (
    <Modal
      sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }}
      open={domesticInfoModal.status}
      onClose={handleClose}
    >
      <div
        style={{
          backgroundColor: "var(--lighterBg)",
          padding: "20px",
          borderRadius: "var(--inputBorderRadius)",
          display: "flex",
          flexDirection: "column",
          gap: "var(--gap)",
        }}
      >
        <div>Domestik AWB: {domesticInfoModal.data?.domestic_awb}</div>
        <div>Domestik Tarih: {FormatDate(domesticInfoModal.data?.domestic_awb_date)}</div>
        <div>Domestik Taşıyıcı: {domesticInfoModal.data?.domestic_supplier}</div>
      </div>
    </Modal>
  );
};

export default DomesticAwbView;
