import { TableCell, TableRow } from "@mui/material";
import React from "react";
import LocalPrice from "../../../../utils/LocalPrice";
import Currency from "../../../../utils/CurrencySymbol";
import { FormatReturnFieldWithDotsAndComma } from "../../../../utils/FormatFieldReturn";

const LostMemberTableBody = ({ item, expand }) => {
  const color =
    parseFloat(item?.gross_profit) > 0
      ? "var(--shipmentGreen)"
      : parseFloat(item?.gross_profit) < 0
      ? "var(--shipmentRed)"
      : "var(--btnBgBlue)";

  const status = (value) => {
    const lab = expand.find((e) => e.label === value);
    return lab.status;
  };

  return (
    <TableRow>
      <TableCell sx={{ padding: "5px" }}>{item?.name}</TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.id}</TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.member_type}</TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.created_at}</TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.last_parcel_date}</TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.status}</TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.company_group}</TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.profit_type}</TableCell>
      <TableCell sx={{ padding: "5px" }}>
        {FormatReturnFieldWithDotsAndComma(item?.profit_amount)}
      </TableCell>
      <TableCell sx={{ padding: "5px", color }}>{LocalPrice(item?.gross_profit)}</TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.courier_area}</TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.postal_code}</TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.town}</TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.city}</TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.state_code}</TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.country}</TableCell>
      {item?.profit_data?.map((year, key) => (
        <React.Fragment key={key}>
          <TableCell align="center" sx={{ padding: "5px", fontWeight: 700, minWidth: "60px" }}>
            {LocalPrice(year?.total)} {Currency("TRY")}
          </TableCell>
          {status(year?.label) &&
            year?.details?.map((month, key) => (
              <TableCell key={key} sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>
                {LocalPrice(month)} {Currency("TRY")}
              </TableCell>
            ))}
        </React.Fragment>
      ))}
    </TableRow>
  );
};

export default LostMemberTableBody;
