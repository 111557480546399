const LighterWrapperBox = () => {
  const style = {
    backgroundColor: "var(--lighterBg)",
    padding: "20px",
    borderRadius: "var(--wrapperBorderRadius)",
    boxShadow: "var(--shadow)",
    display: "flex",
    flexDirection: "column",
    gap: "var(--gap2x)",
  };

  return style;
};

export default LighterWrapperBox;
