import { atom } from "recoil";

export const startDimensionAnimationState = atom({
  key: "startDimensionAnimation",
  default: false,
});

export const packState = atom({
  key: "pack",
  default: "",
});

export const supplierState = atom({
  key: "supplier",
  default: "",
});

export const isDefaultBillAddressState = atom({
  key: "isDefaultBillAddress",
  default: "",
});

export const isBillAddressExistState = atom({
  key: "isBillAddressExist",
  default: null,
});

export const dimensionListArrayState = atom({
  key: "dimensionListArray",
  default: [],
});

export const updateDimensionModalState = atom({
  key: "updateDimensionModal",
  default: { status: false, data: [] },
});

export const addressTypeState = atom({
  key: "addressType",
  default: "",
});

export const startIbanAnimationState = atom({
  key: "startIbanAnimation",
  default: false,
});
export const ibanListArrayState = atom({
  key: "ibanListArray",
  default: [],
});

export const checkedDimensionIdState = atom({
  key: "checkedDimensionId",
  default: null,
});
