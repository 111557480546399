import {
  Button,
  Card,
  CardContent,
  Collapse,
  IconButton,
  InputAdornment,
  TextField,
  createTheme,
} from "@mui/material";
import axios from "../../../../api/axios";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import Errors from "../../../../utils/Errors";
import DetailSearchExchangeRate from "./DetailSearchExchangeRate";
import { ThemeProvider } from "@emotion/react";

const theme = createTheme({
  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "0 !important",
          paddingBottom: "0 !important",
        },
      },
    },
  },
});

const SearchExchangeRate = ({ setData, setLoading, setCurrentPage, setTotalPages }) => {
  const [expanded, setExpanded] = useState(false);
  const [basicSearch, setBasicSearch] = useState("");

  const handleBasicSearch = (e) => {
    if (e) e.preventDefault();

    setLoading(true);
    axios
      .get(`exchange-rate`, {
        params: {
          search: basicSearch,
        },
      })
      .then((response) => {
        setData(response.data?.data);
        setCurrentPage(response.data?.currentPage);
        setTotalPages(response.data?.totalPages);
        toast.success(response.data?.message);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  return (
    <ThemeProvider theme={theme}>
      <Card
        style={{
          padding: "20px",
          backgroundColor: "var(--lighterBg)",
          boxShadow: "var(--shadow)",
          borderRadius: "var(--wrapperBorderRadius)",
        }}
      >
        <CardContent style={{ display: "flex", gap: "var(--gap)" }}>
          <form style={{ width: "100%" }} onSubmit={handleBasicSearch}>
            <TextField
              label="Kur Ara"
              size="small"
              fullWidth
              autoComplete="off"
              placeholder="Kur Tipi, Para Birimi, Kur Tarihi"
              value={basicSearch}
              onChange={(e) => setBasicSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton type="submit">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
          <Button
            style={{ minWidth: "165px", fontSize: "13px" }}
            onClick={() => setExpanded(!expanded)}
            className="iyzi-button"
          >
            {expanded ? "Detaylı Aramayı Kapat" : "Detaylı Aramayı Aç"}
          </Button>
        </CardContent>
        <Collapse in={expanded} timeout={"auto"} unmountOnExit>
          <DetailSearchExchangeRate
            setData={setData}
            setCurrentPage={setCurrentPage}
            setTotalPages={setTotalPages}
          />
        </Collapse>
      </Card>
    </ThemeProvider>
  );
};

export default SearchExchangeRate;
