import { toast } from "react-toastify";

const Errors = (error) => {
  if (error.response.data?.errors) {
    return Object.values(error.response.data?.errors)?.map((messages) =>
      messages?.forEach((message) => toast.error(message))
    );
  } else if (error.response.data?.message) return toast.error(error.response.data?.message);
  else return toast.error("Bir Hata Oluştu! Lütfen Sistem Yöneticisiyle İletişime Geçiniz.");
};

export default Errors;
