import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "../../../api/axios";
import courierSupport from "../../../json/courierSupport.json";
import { useCookies } from "react-cookie";
import { useRecoilState } from "recoil";
import { adminParcelMemberIdState } from "../../../atoms/myShipments";
import Errors from "../../../utils/Errors";
import { toast } from "react-toastify";

const SupportRequestAdmin = () => {
  const navigate = useNavigate();

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [subject, setSubject] = useState("");
  const [awbData, setAwbData] = useState([]);
  const [awbFilteredData, setFilteredAwbData] = useState([]);
  const [description, setDescription] = useState("");
  const [parcelRefNo, setParcelRefNo] = useState(null);
  const [selectedParcelRefNo, setSelectedParcelRefNo] = useState("");
  const [adminParcelMemberId, setAdminParcelMemberId] = useRecoilState(
    adminParcelMemberIdState
  );

  const getAwbData = useCallback(() => {
    axios
      .get(`member/members-parcels-list`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params: {
          detailed: 1,
          unlimited: 1,
          id: adminParcelMemberId,
        },
      })
      .then((response) => {
        setAwbData(response.data.data);
      });
  }, []);

  const handleAdminSupportRequest = useCallback(
    (e) => {
      if (e) e.preventDefault();

      axios
        .post(`ticket/add`, {
          subject,
          description,
          member_id: adminParcelMemberId.id.id,
          parcel_ref_no: selectedParcelRefNo,
        })
        .then((response) => {
          navigate("/support");
          setAdminParcelMemberId("");
          setSelectedParcelRefNo("");
          toast.success(response.data.message);
        })
        .catch((error) => Errors(error));
    },
    [adminParcelMemberId, parcelRefNo, description, navigate, subject]
  );

  useEffect(() => {
    const filteredAwb = awbData.map((parcel) => {
      return {
        id: parcel,
        name: parcel.name,
        parcels: parcel.get_member_parcels,
      };
    });
    setFilteredAwbData(filteredAwb);
  }, [awbData, adminParcelMemberId]);

  useEffect(() => {
    getAwbData();
  }, [getAwbData, adminParcelMemberId]);

  return (
    <Box
      sx={{
        backgroundColor: "var(--background)",
        height: "100vh",
        paddingTop: "40px",
      }}
    >
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          gap: "var(--gap2x)",
          maxWidth: "1100px",
          margin: "auto",
          backgroundColor: "var(--lighterBg)",
          boxShadow: "0 0 6px var(--inputBorderColor)",
          borderRadius: "var(--wrapperBorderRadius)",
        }}
        onSubmit={handleAdminSupportRequest}
      >
        <div style={{ display: "flex", gap: "var(--gap2x)" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "var(--gap2x)",
              width: "100%",
              maxWidth: "300px",
              justifyContent: "space-between",
            }}
          >
            <FormControl size="small" fullWidth required>
              <InputLabel id="subject-select-label">Konu</InputLabel>
              <Select
                labelId="subject-select-label"
                id="subject-select"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                label="Konu"
              >
                {courierSupport.map((sup, key) => (
                  <MenuItem key={key} value={sup.supValue}>
                    {sup.support}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Autocomplete
              id="support-awb-select"
              fullWidth
              size="small"
              options={awbFilteredData.map((option) => option.name)}
              autoHighlight
              openOnFocus={false}
              getOptionLabel={(option) => option}
              value={parcelRefNo?.name}
              onChange={(event, newValue) => {
                const selectedParcel = awbFilteredData.find(
                  (option) => option.name === newValue
                );
                setAdminParcelMemberId(selectedParcel);
              }}
              PaperComponent={({ children }) => (
                <Paper style={{ overflow: "hidden" }}>{children}</Paper>
              )}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={Math.random()}>
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Üye Seçiniz"
                  name="support-awb"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                  }}
                  required
                />
              )}
            />

            <Autocomplete
              id="support-awb-select"
              fullWidth
              size="small"
              options={
                adminParcelMemberId?.parcels?.map(
                  (option) => option.parcel_ref_no
                ) || ["Gönderi Yok"]
              }
              autoHighlight
              openOnFocus={false}
              getOptionLabel={(option) => option}
              value={selectedParcelRefNo}
              onChange={(event, newValue) => {
                const selectedParcel = adminParcelMemberId?.parcels?.find(
                  (option) => option.parcel_ref_no === newValue
                );
                setSelectedParcelRefNo(selectedParcel?.parcel_ref_no);
              }}
              PaperComponent={({ children }) => (
                <Paper style={{ overflow: "hidden" }}>{children}</Paper>
              )}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={Math.random()}>
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Kargo Seçiniz"
                  name="support-awb"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                  }}
                  required
                />
              )}
            />
          </Box>
          <TextField
            fullWidth
            autoComplete="off"
            size="small"
            label="Mesajınız"
            required
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "var(--gap2x)",
          }}
        >
          <Button
            sx={{
              backgroundColor: "var(--info)",
              textTransform: "none",
              transition: "all 150ms linear",
              padding: 0,
              margin: 0,
              ":hover": {
                transition: "all 150ms linear",
                backgroundColor: "var(--blueBrand)",
              },
            }}
            type="submit"
          >
            <NavLink
              style={{
                color: "var(--lighterBg)",
                textDecoration: "none",
                margin: 0,
                fontSize: "13px",
                width: "64px",
                padding: "5px",
              }}
              to={"/support"}
            >
              İptal
            </NavLink>
          </Button>
          <Button
            sx={{
              backgroundColor: "var(--info)",
              textTransform: "none",
              transition: "all 150ms linear",
              color: "var(--lighterBg)",
              fontSize: "13px",
              padding: "5px",
              ":hover": {
                transition: "all 150ms linear",
                backgroundColor: "var(--blueBrand)",
              },
            }}
            type="submit"
            onClick={handleAdminSupportRequest}
          >
            Kaydet
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default SupportRequestAdmin;
