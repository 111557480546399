import { useRecoilState } from "recoil";
import { ffdBase64State, ffdDataState } from "../../../../atoms/operation";
import PDFReader from "../PDFReader";
import FormatDate from "../../../../utils/FormatDate";
import MessageTime from "../../../../utils/MessageTime";
import { Button } from "@mui/material";
import { useCallback } from "react";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import Errors from "../../../../utils/Errors";

const OperationFreightFrontDeskResponse = () => {
  const [ffdData, setFfdData] = useRecoilState(ffdDataState);
  const [ffdBase64, setFfdBase64] = useRecoilState(ffdBase64State);

  const handleClear = useCallback(() => {
    setFfdData({});
    setFfdBase64("");
  }, [setFfdData, setFfdBase64]);

  const printDocument = () => {
    const byteCharacters = atob(ffdBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement("iframe");

    // The component should be mounted in the DOM tree before calling print
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.onload = function () {
      setTimeout(() => {
        // Give the browser some time to render the PDF
        iframe.contentWindow.print();
        URL.revokeObjectURL(blobUrl); // Free up memory
      }, 0);
    };
    iframe.src = blobUrl;
  };

  const handleSend = useCallback(
    (e) => {
      axios
        .post(`parcel/operation/mark-damaged`, {
          parcel_ref_no: ffdData?.parcel_ref_no,
          status: e === 1 ? 2.16 : 2.17,
        })
        .then((response) => {
          toast.success(response.data.message);
        })
        .catch((error) => Errors(error))
        .finally(() => handleClear());
    },
    [ffdData, handleClear]
  );

  return (
    <div
      style={{
        boxShadow: "var(--shadow)",
        padding: "20px",
        backgroundColor: "var(--lighterBg)",
        display: "flex",
        gap: "var(--gap2x)",
        borderRadius: "var(--wrapperBorderRadius)",
      }}
    >
      <PDFReader base64String={ffdBase64} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "var(--gap)",
          fontSize: "16px",
        }}
      >
        <div>
          <b>AWB: </b>
          {ffdData?.parcel_ref_no}
        </div>
        <div>
          <b>SONUÇ: </b>Yük merkeze varış {FormatDate(ffdData?.arrived_to_office_date)} tarihinde
          yapılmıştır.
        </div>
        <div>
          <b>TEDARİKÇİ: </b>
          {ffdData?.get_parcel_supplier?.supplier_name}
        </div>
        <div>
          <b>ALICI: </b>
          {ffdData?.receiver_name}
        </div>
        <div style={{ display: "flex", gap: "var(--gap)", alignItems: "center" }}>
          <div>
            <b>GW: </b>
            {ffdData?.gw}
          </div>
          <div>
            <b>VW: </b>
            {ffdData?.vw}
          </div>
          <div>
            <b>CW: </b>
            {ffdData?.cw}
          </div>
        </div>
        <div>
          <b>Müşteri Notu: </b>
          {ffdData?.get_parcel_notes?.length === 0
            ? ""
            : ffdData?.get_parcel_notes?.find((note) => Number(note.type) === 1).description}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "var(--gap)",
          }}
        >
          <div>
            {ffdData?.get_parcel_notes?.filter((note) => Number(note.type) === 2)?.length > 1 ? (
              <b>IyziTeam Notları: </b>
            ) : (
              <b>IyziTeam Notu: </b>
            )}
          </div>
          {ffdData?.get_parcel_notes?.length === 0
            ? ""
            : ffdData?.get_parcel_notes
                ?.filter((note) => Number(note.type) === 2)
                .map((e, key) => (
                  <div key={key}>
                    {e?.get_created_by?.name} {e?.get_created_by?.surname} -{" "}
                    {MessageTime(e?.created_at)} / {e?.description}
                  </div>
                ))}
        </div>
        <div style={{ display: "flex", gap: "var(--gap2x)" }}>
          <Button className="iyzi-button-blue" onClick={printDocument}>
            Etiket Bastır
          </Button>
          <Button className="iyzi-button-blue" onClick={() => handleSend(1)}>
            Hasar Bildir ve Onay Bekle
          </Button>
          <Button className="iyzi-button-blue" onClick={() => handleSend(2)}>
            Hasar Bildir ve İptal Et
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OperationFreightFrontDeskResponse;
