import { Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { useRecoilState } from "recoil";
import { caseTransactionsState } from "../../../../atoms/myShipmentsAdmin";
import { useCallback } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const CaseTransactionsModal = () => {
  const [caseTransactions, setCaseTransactions] = useRecoilState(caseTransactionsState);

  const handleClose = useCallback(() => {
    setCaseTransactions({ status: false, data: [] });
  }, [setCaseTransactions]);

  return (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={caseTransactions.status}
      onClose={handleClose}
    >
      <div>
        <div
          style={{
            width: "100%",
            padding: 20,
            maxWidth: 600,
            backgroundColor: "var(--lighterBg)",
            position: "relative",
            borderRadius: "var(--wrapperBorderRadius)",
          }}
        >
          <HighlightOffIcon
            sx={{
              position: "absolute",
              right: 20,
              top: 20,
              cursor: "pointer",
              ":hover": {
                color: "var(--error)",
                transition: "all 150ms linear",
              },
            }}
            onClick={handleClose}
          />
          <div>
            <p>Durum İşlemleri</p>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Önceki Değer</TableCell>
                    <TableCell>Yeni Değer</TableCell>
                    <TableCell>Oluşturma Tarihi</TableCell>
                    <TableCell>Alan Adı</TableCell>
                    <TableCell>Oluşturan Kişi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {caseTransactions.data.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.before_value}</TableCell>
                      <TableCell>{item.after_value}</TableCell>
                      <TableCell>{item.created_at}</TableCell>
                      <TableCell>Durum</TableCell>
                      <TableCell>{`${item.get_created_by.name} ${item.get_created_by.surname}`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CaseTransactionsModal;
