import "../../../style/myWallet/MyWallet.css";
import {
  Box,
  createTheme,
  Pagination,
  ThemeProvider,
  Button,
  Modal,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useRecoilState, useRecoilValue } from "recoil";
import { useInView, animated } from "@react-spring/web";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import axios from "../../../api/axios";
import { drawerOpenState } from "../../../atoms/viewPadding";
import NavBar from "../sidebar/NavBar";
import { ToastContainer } from "react-toastify";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import WrapperStyle from "../../../utils/WrapperStyle";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import PriceControlScreenSearch from "./PriceControlScreen/PriceControlScreenSearch";
import {
  PriceControlScreenCurrentPageState,
  PriceControlScreenDataState,
  PriceControlScreenTotalPageState,
  PriceControlScreenIDState,
} from "../../../atoms/priceControl";
import { basicSearchState } from "../../../atoms/myShipments";
import {
  shipmentListCurrentPageState,
  shipmentListOrderDirectionState,
  shipmentsListArrayLengthState,
  totalPagesState,
} from "../../../atoms/myShipments";
import PriceControlScreenTable from "./PriceControlScreen/PriceControlScreenTable";
import Errors from "../../../utils/Errors";
import NotificationBar from "../sidebar/NotificationBar";
import { toast } from "react-toastify";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const PriceControlScreen = () => {
  DynamicTitle("Fiyat Kontrol Ekranı - Admin");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const drawerOpen = useRecoilValue(drawerOpenState);

  const [loading, setLoading] = useState(false);

  const navUnder = {
    paddingLeft: drawerOpen ? "264px" : "64px",
    transition: "all 150ms linear",
    backgroundColor: "var(--lighterBg)",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  };

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  // Sayfa Fonksiyonları
  const [priceControlScreenData, setPriceControlScreenData] = useRecoilState(
    PriceControlScreenDataState
  );

  const [PriceControlScreenTotalPage, setPriceControlScreenTotalPage] =
    useRecoilState(PriceControlScreenTotalPageState);
  const [PriceControlScreenCurrentPage, setPriceControlScreenCurrentPage] =
    useRecoilState(PriceControlScreenCurrentPageState);

  const shipmentOrderDirectionPage = useRecoilValue(
    shipmentListOrderDirectionState
  );

  const [shipmentListCurrentPage, setShipmentListCurrentPage] = useRecoilState(
    shipmentListCurrentPageState
  );

  const basicSearch = useRecoilValue(basicSearchState);

  const priceControlScreenIDState = useRecoilValue(PriceControlScreenIDState);

  const [myShipmentList, setMyShipmentList] = useState([]);
  const [loadingSelectedParcelPrice, setLoadingSelectedParcelPrice] =
    useState(false);
  const [progress, setProgress] = useState(0);

  const getShipment = async () => {
    try {
      const response = await axios.get(
        `parcel?&page=1&limit=1000&detailed=1&beevision_read=1,2&price_approved=0&status=2.16&order_by=arrived_to_office_date&order_direction=DESC`
      );
      setPriceControlScreenData(response.data.data);
      setPriceControlScreenCurrentPage(response.data.currentPage);
      setPriceControlScreenTotalPage(response.data.totalPages);
    } catch (error) {
      Errors(error);
    }
  };

  const setSelectedDomesticPrice = useCallback(async () => {
    try {
      setLoadingSelectedParcelPrice(true);
      const intervalId = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 90 ? 100 : prevProgress + 10
        );
      }, 500);

      const response = await axios.post(
        "parcel/operation/clear-real-contract-parcel",
        {
          ids: priceControlScreenIDState,
        }
      );

      clearInterval(intervalId);
      setProgress(100);
      toast.success(response.data.message);
    } catch (error) {
      Errors(error);
    } finally {
      setLoadingSelectedParcelPrice(false);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  }, [priceControlScreenIDState]);

  const getSelectedParcelPrice = useCallback(async () => {
    try {
      setLoadingSelectedParcelPrice(true);
      const intervalId = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 90 ? 100 : prevProgress + 10
        );
      }, 500);

      const response = await axios.post(
        "parcel/operation/get-selected-parcel-price",
        {
          parcels: priceControlScreenIDState,
        }
      );

      clearInterval(intervalId);
      setProgress(100);
      toast.success(response.data.message);
    } catch (error) {
      Errors(error);
    } finally {
      setLoadingSelectedParcelPrice(false);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  }, [priceControlScreenIDState]);

  const setSelectedParcelPrice = useCallback(async () => {
    try {
      setLoadingSelectedParcelPrice(true);

      // Simulate progress with a timeout
      const intervalId = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 90 ? 100 : prevProgress + 10
        );
      }, 500);

      const response = await axios.post(
        "parcel/operation/set-selected-parcel-price",
        {
          parcels: priceControlScreenIDState,
        }
      );

      clearInterval(intervalId);
      setProgress(100);
      toast.success(response.data.message);
    } catch (error) {
      Errors(error);
    } finally {
      setLoadingSelectedParcelPrice(false);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  }, [priceControlScreenIDState]);

  const handlePageChange = async (e, pageValue) => {
    await axios
      .get(
        `parcel?&beevision_read=1,2&detailed=1&order_by=arrived_to_office_date&order_direction=DESC&order_direction=${shipmentOrderDirectionPage}&limit=1000&page=${pageValue}&search=${basicSearch}`
      )
      .then((response) => {
        setPriceControlScreenData(response.data.data);
        setPriceControlScreenTotalPage(response.data.totalPages);
        setPriceControlScreenCurrentPage(pageValue);
      })
      .catch((error) => Errors(error));
  };

  useEffect(() => {
    getShipment();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <NotificationBar />
      <section
        className="my-wallet"
        style={{
          paddingLeft: drawerOpen ? 0 : 0,
          transition: "all 150ms linear",
          width: "100%",
          padding: "20px",
        }}
      >
        <NavBar />
        <ToastContainer />
        {/* <Box className="nav-under" style={navUnder}>
          <div>
            <ReceiptLongOutlinedIcon /> Fiyat Kontrol Ekranı
          </div>
        </Box> */}
        <Box
          sx={{
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
          }}
          className="my-wallet-wrapper myShipments"
          style={WrapperStyle()}
        >
          <animated.div ref={ref} style={springs}>
            <Box className="my-wallet-container myShipments-wrapper">
              {loading ? (
                <Box
                  sx={{
                    height: "500px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img className="loading-logo" src={miniLogo} alt="" />
                </Box>
              ) : (
                <>
                  <PriceControlScreenSearch />

                  <PriceControlScreenTable />
                  <Pagination
                    showFirstButton
                    showLastButton
                    size="small"
                    count={PriceControlScreenTotalPage}
                    page={PriceControlScreenCurrentPage}
                    onChange={handlePageChange}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      width: "100%",
                      gap: "10px",
                      marginBottom: "25px",
                    }}
                  >
                    <Button
                      onClick={setSelectedDomesticPrice}
                      sx={{
                        background: "#107BE6",
                        color: "#fff",
                        width: "400px",
                        "&:hover": {
                          background: "#107BE6",
                        },
                        "&:disabled": {
                          opacity: 0.5,
                          color: "#fff",
                        },
                      }}
                      disabled={priceControlScreenIDState.length === 0}
                    >
                      Domestik Fiyatları Sıfırla
                    </Button>
                    <Button
                      onClick={getSelectedParcelPrice}
                      sx={{
                        background: "#107BE6",
                        color: "#fff",
                        width: "400px",
                        "&:hover": {
                          background: "#107BE6",
                        },
                        "&:disabled": {
                          opacity: 0.5,
                          color: "#fff",
                        },
                      }}
                      disabled={priceControlScreenIDState.length === 0}
                    >
                      Navlun Fiyatları Getir
                    </Button>
                    <Button
                      sx={{
                        background: "#107BE6",
                        color: "#fff",
                        width: "400px",
                        "&:hover": {
                          background: "#107BE6",
                        },
                        "&:disabled": {
                          opacity: 0.5,
                          color: "#fff",
                        },
                      }}
                      disabled={priceControlScreenIDState.length === 0}
                      onClick={setSelectedParcelPrice}
                    >
                      Fiyatları Onayla ve Gönder
                    </Button>
                  </Box>
                </>
              )}
              <Modal open={loadingSelectedParcelPrice} onClose={() => {}}>
                <Box>
                  <Box
                    sx={{
                      height: "500px",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",

                      backgroundColor: "white",
                      margin: "auto",
                    }}
                  >
                    <div>Fiyatlar getiriliyor...</div>
                    <LinearProgress variant="determinate" value={progress} />
                  </Box>
                </Box>
              </Modal>
            </Box>
          </animated.div>
        </Box>
      </section>
    </ThemeProvider>
  );
};

export default PriceControlScreen;
