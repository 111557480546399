import {
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  financeBakiyeCurrentPageState,
  financeBakiyeDataState,
  financeBakiyeTotalPageState,
} from "../../../../atoms/finance";
import { useCallback, useState } from "react";
import axios from "../../../../api/axios";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Errors from "../../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          padding: "5px",
        },
      },
    },
  },
});

const FinanceBakiyeTableHead = () => {
  const [, setFinanceBakiyeData] = useRecoilState(financeBakiyeDataState);
  const [, setFinanceBakiyeTotalPage] = useRecoilState(
    financeBakiyeTotalPageState
  );
  const [financeBakiyeCurrentPage, setFinanceBakiyeCurrentPage] =
    useRecoilState(financeBakiyeCurrentPageState);

  const [activeSort, setActiveSort] = useState([
    { column: "member_id", direction: null },
    { column: "created_at", direction: null },
    { column: "description_code", direction: null },
    { column: "navlun_price", direction: null },
    { column: "garanti_price", direction: null },
    { column: "iyzico_price", direction: null },
    { column: "prize_price", direction: null },
    { column: "insurance_compensation_price", direction: null },
    { column: "navlun_refund_price", direction: null },
    { column: "loyality_price", direction: null },
    { column: "total_balance", direction: null },
  ]);

  const handlePageChange = useCallback(
    async (orderBy) => {
      const updatedSort = activeSort.map((sort) => {
        if (sort.column === orderBy) {
          return {
            ...sort,
            direction: sort.direction === "ASC" ? "DESC" : "ASC",
          };
        }
        return {
          ...sort,
          direction: null, // Diğer sütunların yönünü varsayılan duruma sıfırlayın
        };
      });

      setActiveSort(updatedSort);

      const currentDirection = updatedSort.find(
        (sort) => sort.column === orderBy
      ).direction;

      await axios
        .get(`wallet/list`, {
          params: {
            page: financeBakiyeCurrentPage,
            limit: 500,
            order_by: orderBy,
            order_direction: currentDirection,
          },
        })
        .then((resp) => {
          setFinanceBakiyeData(resp.data.data);
          setFinanceBakiyeCurrentPage(resp.data.currentPage);
          setFinanceBakiyeTotalPage(resp.data.totalPages);
        })
        .catch((error) => Errors(error));
    },
    [
      setFinanceBakiyeData,
      setFinanceBakiyeCurrentPage,
      setFinanceBakiyeTotalPage,
      financeBakiyeCurrentPage,
      activeSort,
    ]
  );

  return (
    <ThemeProvider theme={theme}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ fontWeight: 700 }}>Muhasebe Kodu</TableCell>
          <TableCell sx={{ fontWeight: 700 }}>Müşteri Adı</TableCell>
          <TableCell
            sx={{ fontWeight: 700, cursor: "pointer" }}
            onClick={() => handlePageChange("member_id")}
          >
            <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
              Müşteri ID{" "}
              <KeyboardArrowDownOutlinedIcon
                sx={{
                  transform:
                    activeSort.find((sort) => sort.column === "member_id")
                      ?.direction === "ASC"
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  transition: "transform 0.3s",
                }}
              />
            </div>
          </TableCell>
          <TableCell sx={{ fontWeight: 700 }}>Konşimento</TableCell>
          <TableCell
            sx={{ fontWeight: 700, cursor: "pointer" }}
            onClick={() => handlePageChange("created_at")}
          >
            <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
              Tarih{" "}
              <KeyboardArrowDownOutlinedIcon
                sx={{
                  transform:
                    activeSort.find((sort) => sort.column === "created_at")
                      ?.direction === "ASC"
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  transition: "transform 0.3s",
                }}
              />
            </div>
          </TableCell>
          <TableCell
            sx={{ fontWeight: 700, cursor: "pointer" }}
            onClick={() => handlePageChange("description_code")}
          >
            <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
              Açıklama{" "}
              <KeyboardArrowDownOutlinedIcon
                sx={{
                  transform:
                    activeSort.find(
                      (sort) => sort.column === "description_code"
                    )?.direction === "ASC"
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  transition: "transform 0.3s",
                }}
              />
            </div>
          </TableCell>
          <TableCell
            sx={{ fontWeight: 700, cursor: "pointer" }}
            onClick={() => handlePageChange("navlun_price")}
          >
            <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
              Navlun{" "}
              <KeyboardArrowDownOutlinedIcon
                sx={{
                  transform:
                    activeSort.find((sort) => sort.column === "navlun_price")
                      ?.direction === "ASC"
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  transition: "transform 0.3s",
                }}
              />
            </div>
          </TableCell>
          <TableCell
            sx={{ fontWeight: 700, cursor: "pointer" }}
            onClick={() => handlePageChange("garanti_price")}
          >
            <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
              Garanti{" "}
              <KeyboardArrowDownOutlinedIcon
                sx={{
                  transform:
                    activeSort.find((sort) => sort.column === "garanti_price")
                      ?.direction === "ASC"
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  transition: "transform 0.3s",
                }}
              />
            </div>
          </TableCell>
          <TableCell
            sx={{ fontWeight: 700, cursor: "pointer" }}
            onClick={() => handlePageChange("iyzico_price")}
          >
            <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
              İyzico{" "}
              <KeyboardArrowDownOutlinedIcon
                sx={{
                  transform:
                    activeSort.find((sort) => sort.column === "iyzico_price")
                      ?.direction === "ASC"
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  transition: "transform 0.3s",
                }}
              />
            </div>
          </TableCell>
          <TableCell
            sx={{ fontWeight: 700, cursor: "pointer" }}
            onClick={() => handlePageChange("prize_price")}
          >
            <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
              Ödül{" "}
              <KeyboardArrowDownOutlinedIcon
                sx={{
                  transform:
                    activeSort.find((sort) => sort.column === "prize_price")
                      ?.direction === "ASC"
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  transition: "transform 0.3s",
                }}
              />
            </div>
          </TableCell>
          <TableCell
            sx={{ fontWeight: 700, cursor: "pointer" }}
            onClick={() => handlePageChange("insurance_compensation_price")}
          >
            <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
              Tazmin Kayıp/Hasar{" "}
              <KeyboardArrowDownOutlinedIcon
                sx={{
                  transform:
                    activeSort.find(
                      (sort) => sort.column === "insurance_compensation_price"
                    )?.direction === "ASC"
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  transition: "transform 0.3s",
                }}
              />
            </div>
          </TableCell>
          <TableCell
            sx={{ fontWeight: 700, cursor: "pointer" }}
            onClick={() => handlePageChange("navlun_refund_price")}
          >
            <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
              Navlun İade{" "}
              <KeyboardArrowDownOutlinedIcon
                sx={{
                  transform:
                    activeSort.find(
                      (sort) => sort.column === "navlun_refund_price"
                    )?.direction === "ASC"
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  transition: "transform 0.3s",
                }}
              />
            </div>
          </TableCell>
          <TableCell
            sx={{ fontWeight: 700, cursor: "pointer" }}
            onClick={() => handlePageChange("loyality_price")}
          >
            <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
              Loyality{" "}
              <KeyboardArrowDownOutlinedIcon
                sx={{
                  transform:
                    activeSort.find((sort) => sort.column === "loyality_price")
                      ?.direction === "ASC"
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  transition: "transform 0.3s",
                }}
              />
            </div>
          </TableCell>
          <TableCell
            sx={{ fontWeight: 700, cursor: "pointer" }}
            onClick={() => handlePageChange("total_balance")}
          >
            <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
              Cüzdan{" "}
              <KeyboardArrowDownOutlinedIcon
                sx={{
                  transform:
                    activeSort.find((sort) => sort.column === "total_balance")
                      ?.direction === "ASC"
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  transition: "transform 0.3s",
                }}
              />
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
    </ThemeProvider>
  );
};

export default FinanceBakiyeTableHead;
