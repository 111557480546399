import "../../../style/profileSettings/TimerSmsModal.css";
import { Box, Button, CircularProgress, Modal } from "@mui/material";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useRecoilState } from "recoil";
import { useCallback, useState } from "react";
import OtpInput from "react18-input-otp";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import {
  memberDocumentEditOpenState,
  memberListState,
  memberTimerModalState,
  memberUserIDState,
} from "../../../atoms/members";
import Errors from "../../../utils/Errors";

const MemberTimerModal = () => {
  const [token] = useCookies(["jwt"]);
  const jwt = token?.jwt;

  const [memberTimerModal, setMemberTimerModal] = useRecoilState(memberTimerModalState);
  const [memberUserID, setMemberUserID] = useRecoilState(memberUserIDState);
  const [, setMemberList] = useRecoilState(memberListState);
  const [, setMemberDocumentEditOpen] = useRecoilState(memberDocumentEditOpenState);

  const [timeIsDone, setTimeIsDone] = useState(false);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOtpChange = (enteredOtp) => {
    setOtp(enteredOtp);
  };

  const children = ({ remainingTime }) => {
    setTimeIsDone(false);
    let minutes = Math.floor(remainingTime / 60);
    let seconds = remainingTime % 60;

    if (minutes === 0 && seconds === 0) {
      setTimeIsDone(true);

      setTimeout(() => {
        handleClose();
      }, [1000]);
    }

    return `${minutes}:${seconds}`;
  };

  const codeSendHandler = async (e) => {
    e.preventDefault();
    console.log("selam");
    setLoading(true);

    const config = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    const data = {
      phone_verification_code: otp,
    };

    await axios
      .post(`user/verify-phone/${memberUserID}`, data, config)
      .then((response) => {
        toast.success(response.data.message);

        // setMemberList ile memberList state güncellenir
        setMemberList((prevMemberList) =>
          prevMemberList.map((member) =>
            member.get_member_users.some((e) => e.id === memberUserID)
              ? {
                  ...member,
                  get_member_users: member.get_member_users.map((e) =>
                    e.id === memberUserID ? response.data.data : e
                  ),
                }
              : member
          )
        );

        // Aynı işlemi memberEditUserModal state için de uygulayın.
        setMemberDocumentEditOpen((prevMemberEditUserModal) => ({
          ...prevMemberEditUserModal,
          data: {
            ...prevMemberEditUserModal.data,
            get_member_users: prevMemberEditUserModal.data.get_member_users
              ? prevMemberEditUserModal.data.get_member_users.map((e) =>
                  e.id === memberUserID ? response.data.data : e
                )
              : [], // Eğer get_member_users undefined veya null ise boş bir array oluşturulur
          },
        }));
      })
      .catch((error) => Errors(error))
      .finally(() => {
        setMemberTimerModal(false);
        setMemberUserID("");
        setOtp("");
        setLoading(false);
      });
  };

  const handleClose = useCallback(() => {
    setMemberTimerModal(false);
    setMemberUserID("");
  }, [setMemberUserID, setMemberTimerModal]);

  return (
    <Modal
      className="timer-sms-modal"
      open={memberTimerModal}
      onClose={handleClose}
      sx={{ minHeight: "100vh" }}
    >
      <Box className="timer-sms-modal-box">
        <CountdownCircleTimer
          isPlaying
          duration={120}
          colors={["#2F80ED", "#2F80ED", "#EB5757", "#EB5757"]}
          colorsTime={[120, 11, 10, 0]}
        >
          {children}
        </CountdownCircleTimer>
        <div className="timer-sms-div">
          {timeIsDone ? (
            "Süreniz bitti, lütfen tekrar deneyin."
          ) : (
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "var(--gap)",
              }}
              onSubmit={codeSendHandler}
            >
              <OtpInput
                value={otp}
                shouldAutoFocus={true}
                onChange={handleOtpChange}
                numInputs={6}
                separator={<span>&nbsp;</span>}
              />
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "35px",
                  }}
                >
                  <CircularProgress sx={{ width: "30px !important", height: "30px !important" }} />
                </div>
              ) : (
                <Button
                  disabled={otp.length !== 6 ? true : false}
                  sx={{ opacity: otp.length !== 6 ? 0.4 : 1 }}
                  type="submit"
                  className="iyzi-button-blue"
                >
                  Kodu Onayla
                </Button>
              )}
              <Button className="iyzi-button-blue" onClick={handleClose} type="button">
                Kapat
              </Button>
            </form>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default MemberTimerModal;
