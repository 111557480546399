import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useRecoilState } from "recoil";
import {
  adminDimensionsModalOpenState,
  selectedShipmentForMyShipmentsState,
} from "../../../../atoms/myShipments";
const DimensionsModal = () => {
  const [selectedShipmentForMyShipments, setSelectedShipmentForMyShipments] =
    useRecoilState(selectedShipmentForMyShipmentsState);
  const [dimensionsModalOpenState, setDimensionsModalOpenState] =
    useRecoilState(adminDimensionsModalOpenState);
  const handleClose = () => {
    setDimensionsModalOpenState(false);
    setSelectedShipmentForMyShipments([]);
  };
  return (
    <Dialog
      open={dimensionsModalOpenState}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        Ölçüler
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {selectedShipmentForMyShipments?.get_parcel_dimensions?.length === 0 ? (
          <Typography variant="body1">Ölçü bilgisi bulunmamaktadır.</Typography>
        ) : (
          selectedShipmentForMyShipments?.get_parcel_dimensions?.map((dimension, index) => (
            <div key={index}>
              <Typography variant="body2">
                Uzunluk: {dimension.length}
              </Typography>
              <Typography variant="body2">
                Genişlik: {dimension.width}
              </Typography>
              <Typography variant="body2">
                Yükseklik: {dimension.height}
              </Typography>
              <Typography variant="body2">Adet: {dimension.qty}</Typography>
              <Typography variant="body2">
                Toplam Hacim Ağırlığı: {dimension.total_vw}
              </Typography>
              <Typography variant="body2">
                Toplam Brut Ağırlık: {dimension.total_gw}
              </Typography>
            </div>
          ))
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DimensionsModal;
