import { Box, Button, createTheme, Modal, TextField, ThemeProvider } from "@mui/material";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import axios from "../../../../api/axios";
import {
  addNoteOpenState,
  myShipmentsNoteState,
  selectedShipmentForMyShipmentsState,
} from "../../../../atoms/myShipments";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FormatDateHour from "../../../../utils/FormatDateHour";
import Errors from "../../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px",
          overflow: "auto",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const AddNote = () => {
  const [addNoteOpen, setAddNoteOpen] = useRecoilState(addNoteOpenState);
  const [myShipmentsNote, setMyShipmentsNote] = useRecoilState(myShipmentsNoteState);
  const [selectedShipmentForMyShipments, setSelectedShipmentForMyShipments] = useRecoilState(
    selectedShipmentForMyShipmentsState
  );

  const handleClose = () => {
    setMyShipmentsNote("");
    setAddNoteOpen(false);
    setSelectedShipmentForMyShipments([]);
  };

  const sendNoteKey = (e) => {
    if (e.key === "Enter") {
      axios
        .post(`parcel/parcel-note/add/${selectedShipmentForMyShipments.id}`, {
          description: myShipmentsNote,
        })
        .then((response) => {
          toast.success("Notunuz Başarıyla Eklendi.");
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        })
        .catch((error) => Errors(error));
      setMyShipmentsNote("");
      setAddNoteOpen(false);
      setSelectedShipmentForMyShipments([]);
    } else if (e.key === "Escape") {
      setMyShipmentsNote("");
      setSelectedShipmentForMyShipments([]);
      toast.success("Sayfa Temizlendi", { autoClose: 500 });
    }
  };

  const sendNote = (e) => {
    axios
      .post(`parcel/parcel-note/add/${selectedShipmentForMyShipments.id}`, {
        description: myShipmentsNote,
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Notunuz Başarıyla Eklendi.", { autoClose: 500 });
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      })
      .catch((error) => Errors(error));
    setMyShipmentsNote("");
    setAddNoteOpen(false);
    setSelectedShipmentForMyShipments([]);
  };

  const userNotes =
    selectedShipmentForMyShipments.length !== 0 &&
    selectedShipmentForMyShipments.get_parcel_notes.filter((e) => e.type === "1");
  const adminNotes =
    selectedShipmentForMyShipments.length !== 0 &&
    selectedShipmentForMyShipments.get_parcel_notes.filter((e) => e.type === "2");

  return (
    <ThemeProvider theme={theme}>
      <Modal open={addNoteOpen} onClose={handleClose}>
        {selectedShipmentForMyShipments.length !== 0 ? (
          <Box
            sx={{
              backgroundColor: "var(--lighterBg)",
              borderRadius: "var(--inputBorderRadius)",
              padding: "20px",
              outline: 0,
              display: "grid",
              gap: "var(--gap)",
              width: "100%",
              maxWidth: "1100px",
              margin: "auto",
              position: "relative",
            }}
          >
            <CancelOutlinedIcon
              sx={{
                position: "absolute",
                right: 1,
                top: 1,
                cursor: "pointer",
                color: "var(--priceText)",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
            <h6
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "var(--priceText)",
                marginBottom: "10px",
              }}
            >
              <NoteAddOutlinedIcon />
              Not Ekle
            </h6>
            <Box
              sx={{
                marginBottom: "var(--gap)",
                display: "grid",
                gap: "var(--gap2x)",
              }}
            >
              <div>
                <h6
                  style={{
                    color: "#6a707e",
                    marginBottom: "20px",
                    fontSize: "18px",
                  }}
                >
                  Kullanıcı Notları
                </h6>
                {userNotes.length < 1 && <p>Kullanıcı notu bulunmamaktadır.</p>}

                {userNotes.length > 0 &&
                  userNotes.map((note, key) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "10px 0",
                      }}
                    >
                      <div
                        style={{
                          paddingLeft: "var(--gap)",
                          fontSize: "14px",
                          color: "var(--lighterTextColor)",
                          paddingBottom: "4px",
                        }}
                      >
                        {FormatDateHour(note?.created_at)} -{" "}
                        <span
                          style={{
                            color: "var(--priceText)",
                            border: "1px solid var(--inputBorderColor)",
                            borderRadius: "var(--wrapperBorderRadius)",
                            padding: "10px",
                          }}
                        >
                          {note?.description}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
              <div>
                <div>
                  <h6
                    style={{
                      color: "#6a707e",
                      marginBottom: "10px",
                      fontSize: "18px",
                    }}
                  >
                    Admin Notları
                  </h6>
                  {adminNotes.length < 1 && <p>Admin notu bulunmamaktadır.</p>}
                </div>
                {adminNotes?.map((note) => (
                  <div key={note.id}>
                    <div
                      style={{
                        paddingLeft: "var(--gap)",
                        fontSize: "14px",
                        color: "var(--lighterTextColor)",
                        paddingBottom: "4px",
                      }}
                    >
                      {note.created_at.slice(0, 10)} {note.created_at.slice(11, 19)} {" - "}
                      {note.get_created_by?.name} {note.get_created_by?.surname}
                    </div>
                    <Box
                      sx={{
                        display: "grid",
                        gap: "var(--gap)",
                        marginTop: "var(--gap)",
                      }}
                    >
                      <TextField
                        defaultValue={note.description}
                        multiline
                        fullWidth
                        autoFocus
                        onFocus={(e) => {
                          let descValue = e.target.value;
                          e.target.value = "";
                          e.target.value = descValue;
                        }}
                        label="Notu Düzenle"
                        id={`update_note_${note.id}`}
                        // onChange={() => updateNoteText(note)}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          gap: "var(--gap)",
                        }}
                      ></Box>
                    </Box>
                  </div>
                ))}
              </div>
            </Box>

            <Box sx={{ display: "grid", gap: "var(--gap)" }}>
              <div>
                <h7>Yeni Not Ekle</h7>
              </div>
              <TextField
                value={myShipmentsNote}
                onChange={(e) => setMyShipmentsNote(e.target.value)}
                onKeyDown={(e) => sendNoteKey(e)}
                label="Notunuz"
                autoFocus
                onFocus={(e) => {
                  let descValue = e.target.value;
                  e.target.value = "";
                  e.target.value = descValue;
                }}
                multiline
                rows={8}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                }}
              >
                <Button
                  onClick={sendNote}
                  sx={{
                    justifySelf: "flex-end",
                    backgroundColor: "var(--info)",
                    color: "var(--lighterBg)",
                    textTransform: "none",
                    transition: "all 150ms linear",
                    borderRadius: "var(--buttonBorderRadius)",
                    padding: "7px 16px",
                    ":hover": {
                      backgroundColor: "var(--blueBrand)",
                      transition: "all 150ms linear",
                    },
                  }}
                >
                  Gönder
                </Button>
                <Button
                  onClick={() => setAddNoteOpen(false)}
                  sx={{
                    justifySelf: "flex-end",
                    backgroundColor: "var(--info)",
                    color: "var(--lighterBg)",
                    textTransform: "none",
                    transition: "all 150ms linear",
                    borderRadius: "var(--buttonBorderRadius)",
                    padding: "7px 16px",
                    ":hover": {
                      backgroundColor: "var(--blueBrand)",
                      transition: "all 150ms linear",
                    },
                  }}
                >
                  İptal
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: "var(--lighterBg)",
              borderRadius: "var(--inputBorderRadius)",
              padding: "20px",
              outline: 0,
              display: "grid",
              gap: "var(--gap)",
              width: "100%",
              maxWidth: "1100px",
              margin: "auto",
              position: "relative",
            }}
          >
            <CancelOutlinedIcon
              sx={{
                position: "absolute",
                right: 1,
                top: 1,
                color: "var(--priceText)",
                cursor: "pointer",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
            Sistem daha kendine gelemedi, sakin ol kardeşim.
          </Box>
        )}
      </Modal>
    </ThemeProvider>
  );
};

export default AddNote;
