import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const OperationMeasureControlResultTable = () => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Müşteri</TableCell>
            <TableCell align="center">Fark</TableCell>
            <TableCell align="center">iyziShip</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">3.04</TableCell>
            <TableCell align="center">---</TableCell>
            <TableCell align="center">---</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">36.08</TableCell>
            <TableCell align="center" sx={{ color: "var(--shipmentRed)" }}>
              2.06
            </TableCell>
            <TableCell align="center">38.14</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OperationMeasureControlResultTable;
