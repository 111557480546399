import "../../../style/operation/Operation.css";
import {
  Box,
  Checkbox,
  FormControlLabel,
  ThemeProvider,
  createTheme,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useInView, animated } from "@react-spring/web";
import axios from "../../../api/axios";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import NavBar from "../sidebar/NavBar";
import { ToastContainer } from "react-toastify";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import WrapperStyle from "../../../utils/WrapperStyle";
import WarehouseOutlinedIcon from "../../../img/images/la_warehouse.svg";
import questionIcon from "../../../img/images/ph_question-bold.svg";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  ffdDataState,
  ffdCheckSingleRXState,
  ffdCheckMultipleRXState,
  ffdCheckSearchRXState,
  ffdAddDimensionsState,
} from "../../../atoms/operation";
import { drawerOpenState } from "../../../atoms/viewPadding";
import NotificationBar from "../sidebar/NotificationBar";
import { toast } from "react-toastify";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const BeeVisionSettings = () => {
  DynamicTitle("Depo Yük Girişi - Admin");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [checkedDom, setCheckedDom] = useState(true);
  const [checkedFrontDesk, setCheckedFrontDesk] = useState(false);
  const [checkedHand, setCheckedHand] = useState(false);
  console.log(checkedHand);
  const [checkedHandBuro, setCheckedHandBuro] = useState(false);
  const [checkedCourier, setCheckedCourier] = useState(false);
  const [checkedMaster, setCheckedMaster] = useState(false);
  const [checkedBaby, setCheckedBaby] = useState(false);
  const [awbNo, setAWBNo] = useState("");
  const [ioss, setIoss] = useState("");
  const [checkSettings, setCheckSettings] = useState(false);
  const [beevisionStatus, setBeevisionStatus] = useState("");
  const [beevisionCurrentStatus, setBeevisionCurrentStatus] = useState("");
  console.log(beevisionStatus)

  //tekli gonderi coklu gonderi ve gonderi arama//
  const [checkedMultipleDom, setCheckedMultipleDom] = useRecoilState(
    ffdCheckMultipleRXState
  );
  const [checkedSearchDom, setCheckedSearchDom] = useRecoilState(
    ffdCheckSearchRXState
  );
  const [checkedSingleDom, setCheckedSingleDom] = useRecoilState(
    ffdCheckSingleRXState
  );
  //tekli gonderi coklu gonderi ve gonderi arama//

  // Ölçü inputları
  const [, setAddDimensions] = useRecoilState(ffdAddDimensionsState);

  const [loading, setLoading] = useState(false);

  const [ffdData, setFfdData] = useRecoilState(ffdDataState);
  const drawerOpen = useRecoilValue(drawerOpenState);

  const [adSoyad, setAdSoyad] = useState("");
  const [tc, setTc] = useState("");

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  // const updateMode = async (currentMode, id) => {
  //   try {
  //     const response = await axios.post("beevision/mode-update", {
  //       current_mode: currentMode,
  //       mode_description: `${checkedSingleDom ? "Tek" : ""}${
  //         checkedMultipleDom ? "Toplu" : ""
  //       }${checkedDom ? "Domestik Teslim" : ""}${
  //         checkedHand ? "Yük Depo Elden Teslim" : ""
  //       }${checkedCourier ? "Yük Kuryeden Teslim" : ""}`,
  //       id: 1,
  //     });

  //     // Handle the response as needed
  //     toast.success(response.data.message);
  //   } catch (error) {
  //     // Handle errors
  //     console.error("Error updating mode:", error);
  //   }
  // };

  const newTrackingFromGun = async () => {
    try {
      const trackingFromGun = await axios.get(
        "beevision/new-tracking-from-gun",
        {
          Authorization: `Bearer ${jwt}`,
        }
      );
    } catch (error) {
      console.error("Error updating mode:", error);
    }
  };

  const getBeevisionList = async () => {
    try {
      const response = await axios.get("beevision/list", {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      setBeevisionStatus(response.data.data[0].mode_description);
    } catch (error) {
      console.error("Error updating mode:", error);
    }
  };

  const handleUpdateSettings = async () => {
    let modeCode = 0;
    let modeDescription = "";

    if (checkedSingleDom && checkedDom) {
      modeCode = 4;
      modeDescription = "Tek Domestik Teslim";
    } else if (checkedHand && checkedSingleDom) {
      modeCode = 4.01;
      modeDescription = "Tek Yük Depo Elden Teslim";
    } else if (checkedCourier && checkedSingleDom) {
      modeCode = 4.02;
      modeDescription = "Tek Yük Kuryeden Teslim";
    } else if (checkedMultipleDom && checkedMaster) {
      modeCode = 3;
      modeDescription = "Toplu Master";
    } else if (checkedMultipleDom && checkedBaby) {
      modeCode = 3.01;
      modeDescription = "Toplu Baby";
    }

    if (modeCode !== 0) {
      try {
        let requestData = {
          current_mode: modeCode,
          mode_description: modeDescription,
          id: 1,
        };

        if (modeCode === 3.01) {
          requestData = {
            ...requestData,
            domestic_awb: awbNo,
          };
        }

        if (modeCode === 4.01 && checkedHandBuro === false) {
          requestData = {
            ...requestData,
            parcel_source_from: adSoyad,
            parcel_source_from_number: tc,
          };
        }

        const response = await axios.post(
          "beevision/mode-update",
          requestData
        );
        setCheckSettings(true);
        toast.success(response.data.message);
        setBeevisionStatus(response.data.data.mode_description);
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const handleCheckedDom = () => {
    setCheckedDom(true);
    setCheckedHand(false);
    setCheckedCourier(false);
    setCheckedFrontDesk(false);
    setCheckedHandBuro(false);
    setCheckedSearchDom(false);
    setCheckedBaby(false);
    setFfdData({});
  };

  const handleCheckedHand = () => {
    setCheckedDom(false);
    setCheckedHand(true);
    setCheckedCourier(false);
    setCheckedFrontDesk(false);
    setAddDimensions(false);
    setCheckedSearchDom(false);
    setFfdData({});
  };

  const handleCheckedHandBuro = () => {
    setCheckedHandBuro((prevValue) => !prevValue);
  };

  const handleCheckedCourier = () => {
    setCheckedDom(false);
    setCheckedHand(false);
    setCheckedCourier(true);
    setCheckedFrontDesk(false);
    setAddDimensions(false);
    setCheckedHandBuro(false);
    setCheckedSearchDom(false);
    setFfdData({});
  };

  const handleCheckedSingleDom = () => {
    setCheckedSingleDom(true);
    setCheckedMultipleDom(false);
    setCheckedSearchDom(false);
    setAddDimensions(false);
    setCheckedHandBuro(false);
    setCheckedDom(true);
    setCheckedHand(false);
    setCheckedCourier(false);
    setCheckedFrontDesk(false);
    setFfdData({});
  };

  const handleCheckedMultipleDom = () => {
    setCheckedMultipleDom(true);
    setCheckedSingleDom(false);
    setCheckedSearchDom(false);
    setAddDimensions(true);
    setCheckedHandBuro(false);
    setFfdData({});
  };

  const handleAdSoyad = (e) => {
    const value = e.target.value;
    setAdSoyad(value);
  };

  const handleAWBNo = (e) => {
    const value = e.target.value;
    setAWBNo(value);
  };

  const handleTc = (e) => {
    const value = e.target.value;
    setTc(value);
  };

  const handleCheckedMaster = () => {
    setCheckedMaster((prevValue) => !prevValue);
    setCheckedBaby(false);
  };

  const handleCheckedBaby = () => {
    setCheckedBaby((prevValue) => !prevValue);
    setCheckedMaster(false);
  };
  const handleOnSettingsChange = () => {
    setCheckSettings(false);
  };

  useEffect(() => {
    getBeevisionList();
    const intervalId = setInterval(() => {
      getBeevisionList();
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <NotificationBar />
      <section
        className="operation"
        style={{
          paddingLeft: drawerOpen ? 0 : "64px",
          transition: "all 150ms linear",
        }}
      >
        <NavBar />
        <ToastContainer />
        {loading && (
          <Box
            sx={{
              height: "500px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="loading-logo" src={miniLogo} alt="" />
          </Box>
        )}
        {!loading && (
          <div
            style={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                height: "90px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "10px",
                backgroundColor: "#ffff",
                marginBottom: "10px",
                paddingLeft: drawerOpen ? "285px" : "20px",
                transition: "all 150ms linear",
              }}
            >
              <img
                style={{ width: "40px", height: "40px" }}
                src={WarehouseOutlinedIcon}
                alt="Icon"
              />
              <h6
                style={{
                  color: "var(--priceText)",
                }}
              >
                Depo Mal Kabul
              </h6>
              <div
                style={{
                  width: "193px",
                  Height: "32px",
                  border: " 1px solid #4285F4",
                  padding: "5px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: " center",
                  alignItems: "center",
                  color: " rgb(66, 133, 244)",
                  marginLeft: "10px",
                  gap: "5px",
                  cursor: "pointer",
                }}
              >
                <img src={questionIcon} alt="question icon" />
                Bu Sayfa Nasıl Kullanılır
              </div>
            </div>
            <Box className="operation-wrapper" style={WrapperStyle()}>
              <animated.div ref={ref} style={springs}>
                <Box className="operation-container">
                  <div
                    style={{ maxWidth: "100%" }}
                    className="operation-freight-front-desk"
                  >
                    <div className="operation-freight-front-desk-checkbox">
                      <div className="operation-freight-front-desk-checkbox-tics">
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedOutlinedIcon />}
                              checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                              checked={checkedSingleDom}
                              onChange={handleCheckedSingleDom}
                            />
                          }
                          label="Tek"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedOutlinedIcon />}
                              checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                              checked={checkedMultipleDom}
                              onChange={handleCheckedMultipleDom}
                            />
                          }
                          label="Toplu"
                        />
                      </div>
                      <div className="operation-freight-front-desk-checkbox-doms">
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedOutlinedIcon />}
                              checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                              checked={checkedDom}
                              onChange={handleCheckedDom}
                            />
                          }
                          label="Domestik Teslim"
                        />
                        {!checkedMultipleDom && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<RadioButtonUncheckedOutlinedIcon />}
                                checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                                checked={checkedHand}
                                onChange={handleCheckedHand}
                              />
                            }
                            label="Yük Depo Elden Teslim"
                          />
                        )}

                        {!checkedMultipleDom && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<RadioButtonUncheckedOutlinedIcon />}
                                checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                                checked={checkedCourier}
                                onChange={handleCheckedCourier}
                              />
                            }
                            label="Yük Kuryeden Teslim"
                          />
                        )}
                      </div>
                    </div>
                    <div>
                      {checkedMultipleDom && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedOutlinedIcon />}
                              checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                              checked={checkedMaster}
                              onChange={handleCheckedMaster}
                            />
                          }
                          label="Master"
                        />
                      )}
                      {checkedMultipleDom && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedOutlinedIcon />}
                              checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                              checked={checkedBaby}
                              onChange={handleCheckedBaby}
                            />
                          }
                          label="Baby"
                        />
                      )}
                    </div>
                    {checkedHand && checkedSingleDom ? (
                      <div
                        style={{
                          display: " flex",
                          gap: " 20px",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            width: "240px",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<RadioButtonUncheckedOutlinedIcon />}
                                checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                                checked={checkedHandBuro}
                                onChange={handleCheckedHandBuro}
                              />
                            }
                            label="Ön Büro"
                          />
                        </div>

                        {checkedHandBuro === false && (
                          <div style={{ display: " flex", gap: " 20px" }}>
                            <TextField
                              required={checkedHand}
                              fullWidth
                              size="small"
                              label="Adı Soyadı"
                              placeholder="Sinem Şahin"
                              autoComplete="off"
                              value={adSoyad}
                              onChange={handleAdSoyad}
                              sx={{
                                width: "100%",
                              }}
                              InputProps={{ style: { height: "50px" } }}
                            />
                            <TextField
                              required={checkedHand}
                              fullWidth
                              size="small"
                              label="TC Kimlik No"
                              placeholder="12345678910"
                              autoComplete="off"
                              value={tc}
                              onChange={handleTc}
                              sx={{
                                width: "100%",
                              }}
                              InputProps={{ style: { height: "50px" } }}
                              inputProps={{ maxLength: 11 }}
                            />
                          </div>
                        )}
                      </div>
                    ) : null}
                    {checkedBaby && checkedMultipleDom ? (
                      <div
                        style={{
                          display: " flex",
                          gap: " 20px",
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ display: " flex", gap: " 20px" }}>
                          <TextField
                            required={checkedHand}
                            fullWidth
                            size="small"
                            label="Domestik AWB"
                            placeholder="AB123456789"
                            autoComplete="off"
                            value={awbNo}
                            onChange={handleAWBNo}
                            sx={{
                              width: "100%",
                            }}
                            InputProps={{ style: { height: "50px" } }}
                          />
                        </div>
                      </div>
                    ) : null}

                    <Button
                      className="iyzi-button-blue"
                      onClick={() => handleUpdateSettings()}
                    >
                      Ayarları Kaydet
                    </Button>
                  </div>
                </Box>
                <Dialog open={checkSettings} onClose={handleOnSettingsChange}>
                  <DialogContent>
                    <DialogTitle id="alert-dialog-title">
                      Beevision Ayar Değişikliği
                    </DialogTitle>
                    <DialogTitle>
                      <DialogContentText id="alert-dialog-description">
                        Beevision ayarı <b>{beevisionStatus}</b> olarak güncellendi.
                      </DialogContentText>
                    </DialogTitle>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleOnSettingsChange}>Tamam</Button>
                  </DialogActions>
                </Dialog>
              </animated.div>
            </Box>
            <Box className="operation-wrapper" style={WrapperStyle()}>
              <animated.div ref={ref} style={springs}>
                <Box className="operation-container">
                  <div
                    style={{ maxWidth: "100%" }}
                    className="operation-freight-front-desk"
                  >
                    Beevision şu anki ayarı {beevisionStatus}                    
                  </div>
                </Box>
                <Dialog open={checkSettings} onClose={handleOnSettingsChange}>
                  <DialogContent>
                    <DialogTitle id="alert-dialog-title">
                      Beevision Ayar Değişikliği
                    </DialogTitle>
                    <DialogTitle>
                      <DialogContentText id="alert-dialog-description">
                        Beevision ayarı <b>{beevisionStatus}</b> olarak güncellendi.
                      </DialogContentText>
                    </DialogTitle>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleOnSettingsChange}>Tamam</Button>
                  </DialogActions>
                </Dialog>
              </animated.div>
            </Box>
          </div>
        )}
      </section>
    </ThemeProvider>
  );
};

export default BeeVisionSettings;
