import { atom } from "recoil";

export const PriceControlScreenTotalPageState = atom({
    key: "PriceControlScreenTotalPage",
    default: 0,
  });
  
  export const PriceControlScreenCurrentPageState = atom({
    key: "PriceControlScreenCurrentPage",
    default: 1,
  });
  
  export const PriceControlScreenDataState = atom({
    key: "PriceControlScreenDataState",
    default: [],
  });

  export const PriceControlScreenIDState = atom({
    key: "PriceControlScreenIDState",
    default: [],
  });

  export const PriceDiffListState = atom({
    key: "priceDiffList",
    default: [],
  });