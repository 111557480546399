import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const DefaultDomesticSupplierSetting = ({
  value,
  setValue,
  status,
  setStatus,
  handleClose,
  loading,
}) => {
  return (
    <div
      style={{
        marginTop: "var(--gap2x)",
        display: "flex",
        flexDirection: "column",
        gap: "var(--gap2x)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "var(--gap2x)",
        }}
      >
        <FormControl size="small" fullWidth>
          <InputLabel id="select-label">Değer</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            value={value}
            label="Değer"
            onChange={(e) => setValue(e.target.value)}
          >
            <MenuItem value={"Ptt"}>PTT Kargo</MenuItem>
            <MenuItem value={"Aras"}>Aras Kargo</MenuItem>
            <MenuItem value={"Surat"}>Sürat Kargo</MenuItem>
            <MenuItem value={"Mng"}>MNG Kargo</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" fullWidth>
          <InputLabel id="select-label">Durum</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            value={status}
            label="Ayar Alt Tipi - Kur"
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value={0}>İnaktif</MenuItem>
            <MenuItem value={1}>Aktif</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "var(--gap)",
        }}
      >
        <Button type="button" onClick={handleClose} className="iyzi-button-gray">
          İptal
        </Button>
        {loading ? (
          <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
        ) : (
          <Button type="submit" className="iyzi-button-blue">
            Kaydet
          </Button>
        )}
      </div>
    </div>
  );
};

export default DefaultDomesticSupplierSetting;
