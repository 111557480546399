import {
  Box,
  Card,
  CardActions,
  CardContent,
  ClickAwayListener,
  Collapse,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import { useRecoilValue } from "recoil";
import { ticketState } from "../../../atoms/support";
import MessageTime from "../../../utils/MessageTime";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
}));

const SupportDetailNotes = () => {
  const [expandNotes, setExpandNotes] = useState(false);

  const ticket = useRecoilValue(ticketState);

  const handleClickNotesAway = () => {
    setExpandNotes(false);
  };
  const handleExpandNotesClick = useCallback(() => {
    setExpandNotes(!expandNotes);
  }, [setExpandNotes, expandNotes]);

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClickNotesAway}
    >
      <Card className="support-detail-files" sx={{ width: "100%" }}>
        <CardActions
          sx={{ cursor: "pointer" }}
          onClick={handleExpandNotesClick}
          disableSpacing
        >
          <Box className="support-detail-files-header">
            <EventNoteOutlinedIcon /> Gönderi Notları
          </Box>
          <ExpandMore
            expand={expandNotes}
            onClick={handleExpandNotesClick}
            aria-expanded={expandNotes}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expandNotes} timeout="auto" unmountOnExit>
        {ticket.get_parcel && ticket.get_parcel.get_parcel_notes ? (
            <CardContent className="support-detail-files-content">
              {ticket.get_parcel.get_parcel_notes.map((note, key) => (
                <Box className="note-content" key={key}>
                  <Typography className="note-content-date">
                    {MessageTime(note.created_at)}
                  </Typography>
                  <Typography className="note-content-text">
                    {note.description}
                  </Typography>
                </Box>
              ))}
            </CardContent>
          ) : (
            <CardContent className="support-detail-files-content">
              Not bulunmamaktadır.
            </CardContent>
          )}
        </Collapse>
      </Card>
    </ClickAwayListener>
  );
};

export default SupportDetailNotes;
