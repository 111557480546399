import { Box, CircularProgress, createTheme, ThemeProvider } from "@mui/material";
import { useState, useCallback } from "react";
import { toast } from "react-toastify";
import axios from "../../../api/axios";
import DownloadBase64Pdf from "../../../utils/DownloadBase64Pdf";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import PreviewIcon from "@mui/icons-material/Preview";
import { useRecoilState } from "recoil";
import {
  connectedPaymentsOpenState,
  selectedShipmentForMyShipmentsState,
  showFinancialDetailsOpenState,
  statusChangeModalOpenState
} from "../../../atoms/myShipments";
import {
  historyOfChangeModalState,
  adminShipmentDimensionDetailState
} from "../../../atoms/myShipmentsAdmin";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.1);",
        },
      },
    },
  },
});

const ReturnListItemLinks = ({ shipment }) => {
  const [consignmentLoading, setConsignmentLoading] = useState(false);
  const [, setSelectedShipmentForMyShipments] = useRecoilState(selectedShipmentForMyShipmentsState);
  const [, setShowFinancialDetailsOpen] = useRecoilState(showFinancialDetailsOpenState);
  const [, setConnectedPaymentsOpen] = useRecoilState(connectedPaymentsOpenState);
  const [, setStatusChangeModalOpenState] = useRecoilState(statusChangeModalOpenState);
  const [, setHistoryOfChangeModal] = useRecoilState(historyOfChangeModalState);
  const [, setShipmentDimensionDetail] = useRecoilState(adminShipmentDimensionDetailState);
  const handleConsignmentDownload = (shipment) => {
    setConsignmentLoading(true);
    axios
      .get(`parcel/get-consignment/${shipment.get_parcel.id}`)
      .then((response) => {
        DownloadBase64Pdf(response.data.base64, shipment.get_parcel.parcel_ref_no);
        toast.success("Konşimento İndirme Başarılı.");
      })
      .catch((error) => Errors(error))
      .finally(() => setConsignmentLoading(false));
  };
  const handleFinancialDetailsOpen = () => {
    setSelectedShipmentForMyShipments(shipment.get_parcel);
    setShowFinancialDetailsOpen(true);
  };

  const handleConnectedPaymentsModal = () => {
    setSelectedShipmentForMyShipments(shipment.get_parcel);
    setConnectedPaymentsOpen(true);
  };

  const handleStatusChangeOpenState = () => {
    setSelectedShipmentForMyShipments(shipment.get_parcel);
    setStatusChangeModalOpenState(true);
  };

  const handleShowHistoryOfChange = useCallback(() => {
    setHistoryOfChangeModal({ status: true, data: shipment.get_parcel });
  }, [setHistoryOfChangeModal, shipment.get_parcel]);

  const handleShipmentDimensionDetailState = () => {
    setShipmentDimensionDetail({
      status: true,
      data: shipment.get_parcel,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="list-links">
        {consignmentLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "15px",
            }}
          >
            <CircularProgress sx={{ height: "15px !important", width: "15px !important" }} />
          </div>
        ) : (
          <>
            <div onClick={() => handleConsignmentDownload(shipment)}>
              <SimCardDownloadOutlinedIcon sx={{ height: "13px" }} /> Konşimento İndir
            </div>
            <div onClick={handleFinancialDetailsOpen}>
              <TrendingUpOutlinedIcon sx={{ height: "13px" }} /> Finansal Değerler
            </div>
            <div onClick={handleConnectedPaymentsModal}>
              <PaymentsOutlinedIcon sx={{ height: "13px" }} /> Bağlı Ödemeler
            </div>
            <div onClick={handleStatusChangeOpenState}>
              <ChangeCircleIcon sx={{ height: "13px" }} /> Statü Değiştir
            </div>
            <div onClick={handleShowHistoryOfChange}>
              <PreviewIcon sx={{ height: "13px" }} /> Değişiklik Tarihçesi Göster
            </div>
            <div onClick={handleShipmentDimensionDetailState}>
              <SquareFootOutlinedIcon sx={{ height: "13px" }} /> Ölçüler
            </div>
          </>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default ReturnListItemLinks;
