import { useRecoilState } from "recoil";
import { addExtraPriceOpenState, selectedShipmentForMyShipmentsState, editDomesticInfoState } from "../../../../atoms/myShipments";
import {
  Button, Modal, ThemeProvider, createTheme, FormControl, InputLabel, Select, MenuItem, TextField, Box
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Errors from "../../../../utils/Errors";
import { Height } from "@mui/icons-material";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px",
          overflow: "auto",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const DomesticInfoChange = () => {
  const [editDomesticInfo, setEditDomesticInfo] = useRecoilState(editDomesticInfoState);
  const [selectedShipmentForMyShipments, setSelectedShipmentForMyShipments] = useRecoilState(
    selectedShipmentForMyShipmentsState
  );

  const [supplierName, setSupplierName] = useState("");
  const [domesticAwb, setDomesticAwb] = useState("");
  const [domesticPrice, setDomesticPrice] = useState("");
  const [gross, setGross] = useState("");
  const [height, setHeight] = useState("");
  const [length, setLenght] = useState("");
  const [width, setWidth] = useState("");
  const [domesticCost, setDomesticCost] = useState("");
  const [supplierList, setSupplierList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSupplierData = () => {
    axios
      .get(`supplier/list-simple`, { params: { unlimited: 1 } })
      .then((response) => setSupplierList(response.data.data))
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  const handleUpdateDomesticInfo = () => {
    const requestBody = {
      domestic_supplier_name: supplierName,
      domestic_awb: domesticAwb,
      domestic_price: domesticPrice,
      domestic_cost: domesticCost,
      length: length,
      width: width,
      height: height,
      gross: gross
    };

    setLoading(true); // İşlem başladığında loading state aktif ediliyor

    axios
      .post(`parcel/operation/add-domestic-parcel/${selectedShipmentForMyShipments?.id}`, requestBody)
      .then((response) => {
        // API çağrısı başarılı olursa yapılacak işlemler
        toast.success(response.data.message);
        handleClose();
        setInterval(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        // Hata olursa yapılacak işlemler
        Errors(error);
      })
      .finally(() => {
        // API çağrısı tamamlandığında loading state devre dışı bırakılıyor
        setLoading(false);
      });
  };

  useEffect(() => {
    getSupplierData();

    if (
      selectedShipmentForMyShipments?.get_parcel_domestic_dimensions &&
      selectedShipmentForMyShipments.get_parcel_domestic_dimensions.length > 0
    ) {
      const firstDimension = selectedShipmentForMyShipments.get_parcel_domestic_dimensions[0];
      setSupplierName(firstDimension?.domestic_supplier);
      setDomesticAwb(firstDimension?.domestic_awb);
      setHeight(firstDimension?.height);
      setLenght(firstDimension?.length);
      setWidth(firstDimension?.width);
      setGross(firstDimension?.gw);
    }
    setDomesticPrice(selectedShipmentForMyShipments?.domestic_price);
    setDomesticCost(selectedShipmentForMyShipments?.domestic_cost_price);
  }, [selectedShipmentForMyShipments]);


  const handleClose = () => {
    setEditDomesticInfo(false);
    setSupplierName("");
    setDomesticAwb("");
    setDomesticPrice("");
    setGross("");
  };

  console.log(selectedShipmentForMyShipments)

  return (
    <ThemeProvider theme={theme}>
      <Modal open={editDomesticInfo} onClose={handleClose}>
        <div
          style={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            padding: "20px",
            outline: 0,
            display: "grid",
            gap: "var(--gap)",
            width: "100%",
            maxWidth: "1100px",
            margin: "auto",
            position: "relative",
          }}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              cursor: "pointer",
              color: "var(--priceText)",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleClose}
          />
          <Box sx={
            {
              display: "flex",
              flexDirection: "column",
              gap: "20px"
            }
          }>
            <Box>
              <p>
                Domestik Bilgi Ekleme
              </p>
              <Box sx={{
                display: "flex",
                gap: "20px",
                marginTop: "20px"
              }}>
                <FormControl fullWidth size="small">
                  <InputLabel sx={{ fontSize: "14px" }} id="supplier-select-label">
                    Tedarikçi
                  </InputLabel>
                  <Select
                    labelId="supplier-select-label"
                    id="supplier-select"
                    label="Tedarikçi"
                    value={supplierName}
                    onChange={(e) => setSupplierName(e.target.value)}
                    sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
                  >
                    <MenuItem value="">
                      <em>Hepsi</em>
                    </MenuItem>
                    <MenuItem value={"Ptt"}>
                      Ptt Kargo
                    </MenuItem>
                    <MenuItem value={"Aras"}>
                      Aras Kargo
                    </MenuItem>
                    <MenuItem value={"Surat"}>
                      Surat Kargo
                    </MenuItem>
                    <MenuItem value={"Mng"}>
                      Mng Kargo
                    </MenuItem>
                    <MenuItem value={"Getir"}>
                      Getir
                    </MenuItem>
                    <MenuItem value={"iyziCourier"}>
                      iyzi Kurye
                    </MenuItem>
                    <MenuItem value={"Other"}>
                      Diğer
                    </MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Domestik AWB"
                  size="small"
                  value={domesticAwb}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setDomesticAwb(e.target.value)}
                  fullWidth
                />
                <TextField
                  label="Domestik Fiyat"
                  size="small"
                  value={domesticPrice}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setDomesticPrice(e.target.value)}
                  fullWidth
                />
                <TextField
                  label="Domestik Cost"
                  size="small"
                  value={domesticCost}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setDomesticCost(e.target.value)}
                  fullWidth
                />
              </Box>
              {selectedShipmentForMyShipments?.get_parcel_dimensions
                ?.filter((e) => Number(e.status) === 1)
                ?.map((dim, key) => (
                  <Box sx={{
                    display: "flex",
                    gap: "20px",
                    marginTop: "20px"
                  }} key={key} className="dimension-detail-per-side">
                    <TextField
                      label="En"
                      size="small"
                      value={width}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setWidth(e.target.value)}
                      fullWidth
                    />
                    <TextField
                      label="Boy"
                      size="small"
                      value={length}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setLenght(e.target.value)}
                      fullWidth
                    />

                    <TextField
                      label="Yükseklik"
                      size="small"
                      value={height}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setHeight(e.target.value)}
                      fullWidth
                    />
                    <TextField
                      label="Ağırlık"
                      size="small"
                      value={gross}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setGross(e.target.value)}
                      fullWidth
                    />
                  </Box>
                ))}
            </Box>
          </Box>
          <Box sx={{ display: " flex", justifyContent: "flex-end" }}>
            <Button
              type="button"
              onClick={() => {
                handleClose();
              }}
              className="iyzi-button"
            >
              Vazgeç
            </Button>
            <Button
              onClick={handleUpdateDomesticInfo}
              className="iyzi-button"
              sx={{ ml: "10px", ":disabled": { opacity: 0.3 } }}
              disabled={selectedShipmentForMyShipments?.get_parcel_domestic_dimensions?.length >= 1}
            >
              Ekle
            </Button>
          </Box>

        </div>
      </Modal>
    </ThemeProvider>
  );
};

export default DomesticInfoChange;
