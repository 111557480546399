import "../../style/myWallet/MyWallet.css";
import { Box, createTheme, Pagination, ThemeProvider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useRecoilState, useRecoilValue } from "recoil";
import { useInView, animated } from "@react-spring/web";
import { DynamicTitle } from "../../utils/DynamicTitle";
import axios from "../../api/axios";
import { drawerOpenState } from "../../atoms/viewPadding";
import NavBar from "../components/sidebar/NavBar";
import { ToastContainer } from "react-toastify";
import miniLogo from "../../img/logos/logo_white_mini_loading.svg";
import WrapperStyle from "../../utils/WrapperStyle";
import FinanceBakiye from "../components/finance/FinanceBakiye";
import FinanceNav from "../components/finance/FinanceNav";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import {
  financeBakiyeCurrentPageState,
  financeBakiyeDataState,
  financeBakiyeTotalPageState,
} from "../../atoms/finance";
import FinanceBakiyeSearch from "../components/finance/financeBakiye/FinanceBakiyeSearch";
import FinanceBakiyeExport from "../components/finance/financeBakiye/FinanceBakiyeExport";
import Errors from "../../utils/Errors";
import NotificationBar from "../components/sidebar/NotificationBar";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const Finance = () => {
  DynamicTitle("Finans Bakiyem - Admin");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const drawerOpen = useRecoilValue(drawerOpenState);

  const [loading, setLoading] = useState(false);

  const navUnder = {
    paddingLeft: drawerOpen ? "264px" : "64px",
    transition: "all 150ms linear",
    backgroundColor: "var(--lighterBg)",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  };

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  // Sayfa Fonksiyonları
  const [, setFinanceBakiyeData] = useRecoilState(financeBakiyeDataState);
  const [financeBakiyeTotalPage, setFinanceBakiyeTotalPage] = useRecoilState(
    financeBakiyeTotalPageState
  );
  const [financeBakiyeCurrentPage, setFinanceBakiyeCurrentPage] = useRecoilState(
    financeBakiyeCurrentPageState
  );

  const getData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`wallet/list`, {
        params: {
          limit: 500,
          page: 1,
          order_direction: "DESC",
        },
      })
      .then((res) => {
        setFinanceBakiyeData(res.data.data);
        setFinanceBakiyeTotalPage(res.data.totalPage);
        setFinanceBakiyeCurrentPage(res.data.currentPage);
        setLoading(false);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [setFinanceBakiyeData, setFinanceBakiyeTotalPage, setFinanceBakiyeCurrentPage]);

  const handleBalancePageChange = useCallback(
    async (e, page) => {
      setLoading(true);
      await axios
        .get(`wallet/list`, {
          params: {
            limit: 500,
            page: page,
            order_direction: "DESC",
          },
        })
        .then((res) => {
          setFinanceBakiyeData(res.data.data);
          setFinanceBakiyeTotalPage(res.data.totalPage);
          setFinanceBakiyeCurrentPage(res.data.currentPage);
          setLoading(false);
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    },
    [setFinanceBakiyeData, setFinanceBakiyeTotalPage, setFinanceBakiyeCurrentPage]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <NotificationBar />
      <section
        className="my-wallet"
        style={{ paddingLeft: drawerOpen ? 0 : "64px", transition: "all 150ms linear" }}
      >
        <ToastContainer />
        <Box className="nav-under" style={navUnder}>
          <div>
            <ReceiptLongOutlinedIcon /> iyziBakiyem
          </div>
        </Box>
        <Box className="my-wallet-wrapper" style={WrapperStyle()}>
          <FinanceNav />
          <animated.div ref={ref} style={springs}>
            <Box className="my-wallet-container">
              {loading ? (
                <Box
                  sx={{
                    height: "500px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img className="loading-logo" src={miniLogo} alt="" />
                </Box>
              ) : (
                <>
                  <FinanceBakiyeSearch />
                  <FinanceBakiyeExport />
                  <FinanceBakiye />
                  {financeBakiyeTotalPage > 1 && (
                    <Pagination
                      showFirstButton
                      showLastButton
                      size="small"
                      count={financeBakiyeTotalPage}
                      page={financeBakiyeCurrentPage}
                      onChange={handleBalancePageChange}
                    />
                  )}
                </>
              )}
            </Box>
          </animated.div>
        </Box>
      </section>
    </ThemeProvider>
  );
};

export default Finance;
