import axios from "../api/axios";
import Errors from "./Errors";

const GetFileAdmin = (document) => {
  axios
    .get(`document/${document.id}`, { responseType: "arraybuffer" })
    .then((response) => {
      let blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      let image = window.URL.createObjectURL(blob);

      window.open(image, "_blank");
    })
    .catch((error) => Errors(error));
};

export default GetFileAdmin;
