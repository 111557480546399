import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import axios from "../../../../api/axios";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Errors from "../../../../utils/Errors";

const SupportDetailAddTalker = ({ ticket }) => {
  const [talkerList, setTalkerList] = useState([]);
  const [talker, setTalker] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [confirmId, setConfirmId] = useState("");
  const [talkers, setTalkers] = useState([]);
  
  const getTalkerData = useCallback(() => {
    axios
      .get(`user`, {
        params: {
          order_direction: "DESC",
          detailed: 1,
          where_in_field: "role_id",
          where_in_array: [2, 3, 4, 5, 6, 7, 8, 9, 10],
          exclude_me: 1,
        },
      })
      .then((response) => {
        setTalkerList(response.data.data);
      });
  }, []);

  const handleAddSupportDude = useCallback(
    (e) => {
      if (e !== undefined) {
        setConfirm(true);
        setConfirmId(e);
      } else return;
    },
    [setConfirmId]
  );

  const handleConfirm = useCallback(
    (e) => {
      axios
        .post(`ticket/ticket-user/add/${ticket.id}`, {
          user_id: confirmId,
        })
        .then((response) => {
          setTalkers([...talkers, response.data]);
          window.location.reload();
        })
        .catch((error) => Errors(error))
        .finally(() => {
          handleConfirmClose();
        });
    },
    [ticket, confirmId, setTalkers, talkers]
  );

  const handleConfirmClose = () => {
    setConfirm(false);
    setConfirmId("");
    setTalker({});
  };

  const handleTalkerDelete = useCallback(
    (user_id) => {
      axios
        .post(`ticket/ticket-user/delete/${ticket.id}`, {
          user_id,
        })
        .then((response) => {
          if (response.data.status === 200) {
            setTalkers(talkers.filter((e) => e.id !== user_id));
          }
        })
        .catch((error) => Errors(error));
    },
    [ticket, talkers]
  );

  useEffect(() => {
    getTalkerData();
  }, [getTalkerData]);

  useEffect(() => {
    setTalkers(ticket.get_ticket_users);
  }, [ticket.get_ticket_users]);

  return (
    <>
      <h6 style={{ color: "var(--shipmentLight)", textAlign: "center" }}>Konuşmacılar</h6>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "var(--gap2x)" }}>
        <Dialog
          open={confirm}
          onClose={handleConfirmClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Konuşmacı Ekle"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span style={{ fontWeight: 700 }}>
                {talker.name} {talker.surname}
              </span>{" "}
              kişisini konuşmaya eklemek istediğine emin misin?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className="iyzi-button" onClick={handleConfirmClose}>
              Hayır
            </Button>
            <Button className="iyzi-button" onClick={handleConfirm} autoFocus>
              Evet
            </Button>
          </DialogActions>
        </Dialog>
        <FormControl sx={{ marginTop: "20px" }} fullWidth>
          <InputLabel id="talker-add-select-label">Konuşmacı Ekle</InputLabel>
          <Select
            labelId="talker-add-select-label"
            id="talker-add-select"
            value={talker}
            label="Konuşmacı Ekle"
            onChange={(e) => setTalker(e.target.value)}
          >
            <MenuItem key={0} value={{}}>
              <em>Temizle</em>
            </MenuItem>
            {talkerList.map((talk) => (
              <MenuItem onClick={() => handleAddSupportDude(talk.id)} key={talk.id} value={talk}>
                {talk.name} {talk.surname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {talkers?.map((user) => (
          <>
            <Box
              key={user.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                <Box>
                  {user.name} {user.surname}
                </Box>
                <Box sx={{ color: "var(--priceText)" }}>{user?.user_role?.display_name}</Box>
              </Box>
              <Box
                sx={{
                  color: "var(--blueBrand)",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                Konuşmada{" "}
                <DeleteOutlineOutlinedIcon
                  sx={{
                    cursor: "pointer",
                    color: "var(--notRed)",
                    transition: "all 150ms linear",
                    ":hover": {
                      color: "var(--redBrand)",
                      transition: "all 150ms linear",
                    },
                  }}
                  onClick={() => handleTalkerDelete(user.id)}
                />
              </Box>
            </Box>
            <Divider />
          </>
        ))}
      </Box>
    </>
  );
};

export default SupportDetailAddTalker;
