/*const FormatDateHour = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hours = "" + d.getUTCHours(), // UTC formatına göre saat bilgisini al
    minutes = "" + d.getUTCMinutes(), // UTC formatına göre dakika bilgisini al
    seconds = "" + d.getUTCSeconds(); // UTC formatına göre saniye bilgisini al

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hours.length < 2) hours = "0" + hours;
  if (minutes.length < 2) minutes = "0" + minutes;
  if (seconds.length < 2) seconds = "0" + seconds;

  return [day, month, year].join(".") + " " + [hours, minutes].join(":");
};*/
const FormatDateHour = (date) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };

  const turkishLocale = 'tr-TR';

  return new Date(date).toLocaleDateString(turkishLocale, options);
};
export default FormatDateHour;
