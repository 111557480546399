import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  TextField,
  FormControl,
  Chip,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useCallback, useState } from "react";
import statusData from "../../../../json/status.json";
import { useRecoilState } from "recoil";
import CircularProgress from "@mui/material/CircularProgress";
import uploadIcon from "../../../../img/icons/upload_icon.svg";
import {
  statusChangeModalOpenState,
  selectedShipmentForMyShipmentsState,
} from "../../../../atoms/myShipments";
import { useDropzone } from "react-dropzone";
import DowloadPdfView from "../../../../components/dowloadPdfView/DowloadPdfView";
import axios from "../../../../api/axios";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: " 80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: " 20%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const StatusChangeModal = ({ title, content, id, statusForStatusChange }) => {
  const [selectedShipmentForMyShipments, setSelectedShipmentForMyShipments] =
    useRecoilState(selectedShipmentForMyShipmentsState);
  const [statusChangeOpen, setStatusChangeOpen] = useRecoilState(
    statusChangeModalOpenState
  );

  const [traAwb, setTraAwb] = useState("");
  const [domAwb, setDomAwb] = useState("");
  const [domSupplier, setDomSupplier] = useState("");
  const handleClose = () => {
    if (content) {
      return;
    }
    setStatusChangeOpen(false);
    setNumericStatuses("");
    setReasonHand("");
    setSelectedStep("");
    setSelectedStatus("");
    setStatusInput(false);
    setLoading(false);
    setSelectedShipmentForMyShipments([]);
  };
  const [selectedStep, setSelectedStep] = useState(null); // Seçilen adım
  const [selectedStatus, setSelectedStatus] = useState(null); // Seçilen statü
  const [numericStatuses, setNumericStatuses] = useState(""); // Sayısal statü
  const [statusDomesticInputs, setStatusDomesticInputs] = useState(false);
  const [statusTrackingAwbInputs, setStatusTrackingAwbInputs] = useState(false);

  const [loading, setLoading] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const stepsAndStatus = statusData;

  //Balon Depo Seçilirse Açılcak Modallar
  const [damagedModal, setDamagedModal] = useState(false);
  const [missingDocumentModal, setMissingDocumentModal] = useState(false);
  const [missingAddressModal, setMissingAddressModal] = useState(false);
  const [missingProformaModal, setMissingProformaModal] = useState(false);
  const [sendFiles, setSendFiles] = useState([]);
  const [description, setDescription] = useState("");
  const [textError, setTextError] = useState(false);
  const [inputOtherValue, setInputOtherValue] = useState("");
  const [otherTags, setOtherTags] = useState([]);
  const [statusInput, setStatusInput] = useState(false);
  const [reasonHand, setReasonHand] = useState("");
  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  //EKSİK EVRAK TYPES AND METHODS
  const missingDocumentTypes = [
    { value: "E-ARŞİV", label: "E-ARŞİV" },
    { value: "TSCA", label: "TSCA" },
    { value: "FDA", label: "FDA" },
    { value: "MSDS", label: "MSDS" },
    { value: "ATR", label: "ATR" },
    { value: "DİĞER", label: "DİĞER", extraContent: true },
  ];

  const handleInputOtherChange = (event) => {
    setInputOtherValue(event.target.value);
  };

  const handleInputOtherKeyPress = (event) => {
    if (event.key === "Enter" && inputOtherValue.trim() !== "") {
      setOtherTags([...otherTags, inputOtherValue]);
      setInputOtherValue("");
    }
  };

  const handleTagDelete = (tagToDelete) => {
    setOtherTags(otherTags.filter((tag) => tag !== tagToDelete));
  };
  const [selectedMissingDocumentOptions, setSelectedMissingDocumentOptions] =
    useState([]);
  const handleRegisterMissingDocument = () => {
    let cloneMissingSelectedOptions = JSON.parse(
      JSON.stringify(selectedMissingDocumentOptions)
    );
    let clonedOtherTags = JSON.parse(JSON.stringify(otherTags));
    cloneMissingSelectedOptions = cloneMissingSelectedOptions
      .filter((f) => !f.extraContent)
      .map((r) => r.label);

    if (clonedOtherTags.length > 0) {
      for (let i = 0; i < clonedOtherTags.length; i++) {
        cloneMissingSelectedOptions.push(clonedOtherTags[i]);
      }
    }
    setMissingDocumentModal(false);
    setSelectedMissingDocumentOptions(cloneMissingSelectedOptions);
    return;
  };
  const handleMultiSelectChange = (event, selected) => {
    if (selected.filter((f) => f.extraContent).length === 0) {
      setOtherTags([]);
    }
    setSelectedMissingDocumentOptions(selected);
  };
  //__________________________________________________________________
  const handleTextBlur = useCallback(
    (e) => {
      if (e.target.value.length < 2) {
        setTextError(true);
      } else if (e.target.value.length > 2) {
        setTextError(false);
      }
    },
    [setTextError]
  );
  const handleSetState = (id) => {
    setSendFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  const handleDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      id: Math.random().toString(),
      maxSize: 2048,
    }));
    setSendFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
  });
  const handleRegisterDamage = () => {
    setDamagedModal(false);
    return;
  };

  const stepOptionsWithSubStatus = stepsAndStatus.map((stepData) => {
    const stepName = stepData[0]["statuName"] || stepData[0]["statu-name"];
    const subStatuses = stepData.slice(1).map((statusData) => {
      const statu = statusData["statu"];
      const subStatuName = statusData["subStatuName"];
      return `${statu} ${subStatuName}`;
    });
    return {
      stepName,
      subStatuses,
    };
  });

  const handleStepChange = (event, newValue) => {
    setSelectedStep(newValue);
    setSelectedStatus(null);
  };

  const handleReturnContent = async () => {
    const apiUrl = `parcel-return/demand/list/${
      selectedShipmentForMyShipments.id || id
    }`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      setReasonHand(response.data.data);
    } catch (error) {
      console.error("Hata:", error);
    }
  };

  const handleStatusChange = (event, newValue) => {
    setSelectedStatus(newValue);
    const numericStatus = newValue.match(/^\d+(\.\d+)?/);
    /*   if (numericStatus) {
      setNumericStatuses(numericStatus[0]);
    } */
    if (numericStatus) {
      switch (numericStatus[0]) {
        case "2.08":
          setDamagedModal(true);
          setNumericStatuses(numericStatus[0]);
          break;
        case "2.11":
          setMissingDocumentModal(true);
          setNumericStatuses(numericStatus[0]);
          break;
        case "2.12":
          setMissingAddressModal(true);
          setNumericStatuses(numericStatus[0]);
          break;
        case "2.13":
          setMissingProformaModal(true);
          setNumericStatuses(numericStatus[0]);
          break;
        case "2.22":
          setStatusInput(true);
          setNumericStatuses(numericStatus[0]);
          handleReturnContent();
          break;
        case "2.25":
          setStatusDomesticInputs(true);
          setNumericStatuses(numericStatus[0]);
          break;
        case "2.28":
          setStatusInput(true);
          setNumericStatuses(numericStatus[0]);
          handleReturnContent();
          break;
        case "2.29":
          setStatusInput(true);
          setStatusTrackingAwbInputs(true);
          setNumericStatuses(numericStatus[0]);
          handleReturnContent();
          break;
        case "3.02":
          setStatusInput(true);
          setNumericStatuses(numericStatus[0]);
          handleReturnContent();
          break;
        case "4.08":
          setStatusInput(true);
          setNumericStatuses(numericStatus[0]);
          handleReturnContent();
          break;
        default:
          setNumericStatuses(numericStatus[0]);
          setStatusInput(false);
          setStatusDomesticInputs(false);
          setStatusTrackingAwbInputs(false);
      }
    }
  };

  const handleOpenConfirmation = () => {
    setConfirmationOpen(true);
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  const handleSupplierChange = () => {
    handleOpenConfirmation();
  };

  //____________Adres Hatası___________
  const missingAddressTypes = [
    { value: "PO BOX", label: "PO BOX" },
    { value: "ASKERİ ÜS", label: "ASKERİ ÜS" },
    { value: "POSTA KODU", label: "POSTA KODU" },
    { value: "AÇIK ADRES", label: "AÇIK ADRES" },
    { value: "DİĞER", label: "DİĞER", extraContent: true },
  ];

  const [selectedMissingAddressOptions, setSelectedMissingAddressOptions] =
    useState([]);

  const handleMultiSelectChange0 = (event, selected) => {
    if (selected.filter((f) => f.extraContent).length === 0) {
      setOtherTags0([]);
    }
    setSelectedMissingAddressOptions(selected);
  };

  const [inputOtherValue0, setInputOtherValue0] = useState("");
  const [otherTags0, setOtherTags0] = useState([]);

  const handleInputOtherChange0 = (event) => {
    setInputOtherValue0(event.target.value);
  };

  const handleInputOtherKeyPress0 = (event) => {
    if (event.key === "Enter" && inputOtherValue0.trim() !== "") {
      setOtherTags0([...otherTags0, inputOtherValue0]);
      setInputOtherValue0("");
    }
  };

  const handleTagDelete0 = (tagToDelete) => {
    setOtherTags0(otherTags0.filter((tag) => tag !== tagToDelete));
  };

  const handleRegisterMissingAddress = () => {
    let cloneMissingSelectedOptions = JSON.parse(
      JSON.stringify(selectedMissingAddressOptions)
    );
    let clonedOtherTags = JSON.parse(JSON.stringify(otherTags0));
    cloneMissingSelectedOptions = cloneMissingSelectedOptions
      .filter((f) => !f.extraContent)
      .map((r) => r.label);

    if (clonedOtherTags.length > 0) {
      for (let i = 0; i < clonedOtherTags.length; i++) {
        cloneMissingSelectedOptions.push(clonedOtherTags[i]);
      }
    }
    setMissingAddressModal(false);
    setSelectedMissingAddressOptions(cloneMissingSelectedOptions);
    return;
  };

  //___________Proforma Hatası_________
  const missingProformaTypes = [
    { value: "IOSS", label: "IOSS" },
    { value: "GTIP", label: "GTIP" },
    {
      value: "DETAYLI İNGİLİZCE MAL TANIMI",
      label: "DETAYLI İNGİLİZCE MAL TANIMI",
    },
    { value: "DİĞER", label: "DİĞER", extraContent: true },
  ];

  const [selectedMissingProformaOptions, setSelectedMissingProformaOptions] =
    useState([]);

  const handleMultiSelectChange2 = (event, selected) => {
    if (selected.filter((f) => f.extraContent).length === 0) {
      setOtherTags2([]);
    }
    setSelectedMissingProformaOptions(selected);
  };

  const [inputOtherValue2, setInputOtherValue2] = useState("");
  const [otherTags2, setOtherTags2] = useState([]);

  const handleInputOtherChange2 = (event) => {
    setInputOtherValue2(event.target.value);
  };

  const handleInputOtherKeyPress2 = (event) => {
    if (event.key === "Enter" && inputOtherValue2.trim() !== "") {
      setOtherTags2([...otherTags2, inputOtherValue2]);
      setInputOtherValue2("");
    }
  };

  const handleTagDelete2 = (tagToDelete) => {
    setOtherTags2(otherTags2.filter((tag) => tag !== tagToDelete));
  };

  const handleRegisterMissingProforma = () => {
    let cloneMissingSelectedOptions = JSON.parse(
      JSON.stringify(selectedMissingProformaOptions)
    );
    let clonedOtherTags = JSON.parse(JSON.stringify(otherTags2));
    cloneMissingSelectedOptions = cloneMissingSelectedOptions
      .filter((f) => !f.extraContent)
      .map((r) => r.label);

    if (clonedOtherTags.length > 0) {
      for (let i = 0; i < clonedOtherTags.length; i++) {
        cloneMissingSelectedOptions.push(clonedOtherTags[i]);
      }
    }
    setMissingProformaModal(false);
    setSelectedMissingProformaOptions(cloneMissingSelectedOptions);
    return;
  };

  const handleSaveChanges = () => {
    setLoading(true);
    const apiUrl = `parcel/update-status/${
      selectedShipmentForMyShipments.id || id
    }`;

    const requestData = {
      status: numericStatuses,
      return_reason: reasonHand.length > 0 ? reasonHand : "",
      selectedMissingProformaOptions,
      selectedMissingDocumentOptions,
      selectedMissingAddressOptions,
      sendFiles,
      description
    };
    domAwb && (requestData.domestic_awb = domAwb);
    domSupplier && (requestData.domestic_supplier = domSupplier);
    traAwb && (requestData.tracking_awb = traAwb);
    axios
      .post(apiUrl, requestData)
      .then((response) => {
        toast.success(response.data.message);
        setConfirmationOpen(false);
        handleClose();
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setConfirmationOpen(false);
        handleClose();
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Dialog
        open={statusChangeOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && content) {
            handleClose();
          }
        }}
        fullWidth
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      >
        {title && content && <DialogTitle>{title}</DialogTitle>}
        {title && content && <DialogContent>{content}</DialogContent>}
        <DialogTitle>Statü Değiştir</DialogTitle>
        <DialogContent>
          <Autocomplete
            fullWidth
            options={stepOptionsWithSubStatus.map(
              (stepData) => stepData.stepName
            )}
            value={selectedStep}
            onChange={handleStepChange}
            renderInput={(params) => (
              <TextField {...params} label="Balon Seçiniz" />
            )}
            sx={{
              padding: "0.5rem 0",
            }}
          />
          {selectedStep && (
            <Autocomplete
              fullWidth
              options={
                stepOptionsWithSubStatus.find(
                  (stepData) => stepData.stepName === selectedStep
                ).subStatuses
              }
              value={selectedStatus}
              onChange={handleStatusChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`Statü Seçiniz (${selectedStep})`}
                />
              )}
              sx={{
                padding: "0.5rem 0",
              }}
            />
          )}
          {statusInput && (
            <TextField
              margin="dense"
              label="İade Nedeni"
              fullWidth
              value={reasonHand}
              onChange={(e) => setReasonHand(e.target.value)}
            />
          )}
          {statusDomesticInputs && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <TextField
                onChange={(e) => setDomAwb(e.target.value)}
                margin="dense"
                label="Domestik AWB No"
                fullWidth
              />
              <FormControl size="small" fullWidth sx={{ maxWidth: "100%" }}>
                <InputLabel id="dom-awb-select-label">
                  Domestik Tedarikçi
                </InputLabel>
                <Select
                  labelId="dom-awb-select-label"
                  id="dom-awb-select"
                  label="Domestik Tedarikçi"
                  size="small"
                  sx={{ height: "59px" }}
                  onChange={(e) => setDomSupplier(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Temizle</em>
                  </MenuItem>
                  <MenuItem value={"Aras"}>Aras Kargo</MenuItem>
                  <MenuItem value={"Surat"}>Sürat Kargo</MenuItem>
                  <MenuItem value={"Ups"}>Ups Kargo</MenuItem>
                  <MenuItem value={"Diğer"}>Diğer</MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}
          {statusTrackingAwbInputs && (
              <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
              >
                <TextField
                    onChange={(e) => setTraAwb(e.target.value)}
                    margin="dense"
                    label="Tracking AWB No"
                    fullWidth
                />
              </Box>
          )}
        </DialogContent>
        <DialogActions>
          {!content && <Button onClick={handleClose}>İptal</Button>}
          <Button onClick={handleSupplierChange}> Kaydet</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmationOpen} onClose={handleCloseConfirmation}>
        <DialogTitle>Değişiklikleri Kaydet</DialogTitle>
        <DialogContent>
          <p>Değişiklikleri kaydetmek istiyor musunuz?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation} color="primary">
            Hayır
          </Button>
          <Button
            onClick={handleSaveChanges}
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : "Evet"}
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={damagedModal}
        onClose={() => setDamagedModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus
      >
        <Box sx={style}>
          <DialogTitle id="damaged-modal-modal-title">
            Gönderi Hasarlı Dosya ve Açıklama Penceresi
          </DialogTitle>
          <TextField
            required
            fullWidth
            inputProps={{
              minLength: 2,
            }}
            onBlur={(e) => handleTextBlur(e)}
            error={textError}
            helperText={textError && "Açıklama max 2 karakter olmalı"}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            autoComplete="off"
            type="text"
            label="Açıklama"
            size="small"
            sx={{ marginBottom: "10px" }}
          />
          <Box className="dropzone-wrapper">
            <Box className="dropzone">
              <div className="dropzone-area" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dropzone-area-text">
                  <img src={uploadIcon} alt="Upload Icon" />
                  <span className="dropzone-area-text-headline">
                    Dosyayı sürükleyip bırakın veya{" "}
                    <span style={{ color: "var(--info)" }}>Gözat</span>
                  </span>
                  <span className="dropzone-area-text-sub">
                    Desteklenen Formatlar:
                    DOC,DOCX,XLSX,PDF,TXT,CSV,PNG,JPG,JPEG
                  </span>
                </div>
              </div>
            </Box>
            <DowloadPdfView
              sendFiles={sendFiles}
              handleSetState={handleSetState}
            />
          </Box>
          <Box sx={{ display: " flex", justifyContent: "flex-end" }}>
            <Button
              type="button"
              onClick={() => {
                setDamagedModal(false);
                setSendFiles([]);
              }}
              className="iyzi-button"
            >
              Vazgeç
            </Button>
            <Button
              onClick={handleRegisterDamage}
              className="iyzi-button"
              sx={{ ml: "10px" }}
            >
              Ekle
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={missingDocumentModal}
        onClose={() => setMissingDocumentModal(false)}
        aria-labelledby="missing-modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus
      >
        <Box sx={style2}>
          <DialogTitle id="missing-modal-modal-title">
            Eksik Evrak Seçimleri
          </DialogTitle>
          <Autocomplete
            multiple
            id="multi-select-autocomplete"
            options={missingDocumentTypes}
            getOptionLabel={(option) => option.label}
            value={selectedMissingDocumentOptions}
            onChange={handleMultiSelectChange}
            renderInput={(params) => (
              <TextField {...params} label="Çoklu Seçim" />
            )}
          />
          {selectedMissingDocumentOptions.filter((f) => f.extraContent).length >
          0 ? (
            <FormControl style={{ marginTop: "10px" }} fullWidth size="small">
              <TextField
                label="Diğer Seçenek Yazınız"
                value={inputOtherValue}
                onChange={handleInputOtherChange}
                onKeyPress={handleInputOtherKeyPress}
              />
              <div>
                <Box style={{ marginTop: "12px" }}>
                  Eklenecek Diğer Seçenekler
                </Box>
                {otherTags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onDelete={() => handleTagDelete(tag)}
                    style={{ margin: "4px" }}
                  />
                ))}
              </div>
            </FormControl>
          ) : null}
          <Box
            sx={{
              display: " flex",
              justifyContent: "flex-end",
              marginTop: "5px",
            }}
          >
            <Button
              type="button"
              onClick={() => {
                setMissingDocumentModal(false);
                setSelectedMissingDocumentOptions([]);
                setOtherTags([]);
                setInputOtherValue("");
              }}
              className="iyzi-button"
            >
              Vazgeç
            </Button>
            <Button
              onClick={handleRegisterMissingDocument}
              className="iyzi-button"
              sx={{ ml: "10px" }}
            >
              Ekle
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={missingAddressModal}
        onClose={() => setMissingAddressModal(false)}
        aria-labelledby="missing-modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus
      >
        <Box sx={style2}>
          <DialogTitle id="missing-modal-modal-title">
            Adres Hatası Seçimleri
          </DialogTitle>
          <Autocomplete
            multiple
            id="multi-select-autocomplete"
            options={missingAddressTypes}
            getOptionLabel={(option) => option.label}
            value={selectedMissingAddressOptions}
            onChange={handleMultiSelectChange0}
            renderInput={(params) => (
              <TextField {...params} label="Çoklu Seçim" />
            )}
          />
          {selectedMissingAddressOptions.filter((f) => f.extraContent).length >
          0 ? (
            <FormControl style={{ marginTop: "10px" }} fullWidth size="small">
              <TextField
                label="Diğer Seçenek Yazınız"
                value={inputOtherValue0}
                onChange={handleInputOtherChange0}
                onKeyPress={handleInputOtherKeyPress0}
              />
              <div>
                <Box style={{ marginTop: "12px" }}>
                  Eklenecek Diğer Seçenekler
                </Box>
                {otherTags0.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onDelete={() => handleTagDelete0(tag)}
                    style={{ margin: "4px" }}
                  />
                ))}
              </div>
            </FormControl>
          ) : null}
          <Box
            sx={{
              display: " flex",
              justifyContent: "flex-end",
              marginTop: "5px",
            }}
          >
            <Button
              type="button"
              onClick={() => {
                setMissingAddressModal(false);
                setOtherTags0([]);
                setInputOtherValue0("");
                setSelectedMissingAddressOptions([]);
              }}
              className="iyzi-button"
            >
              Vazgeç
            </Button>
            <Button
              onClick={handleRegisterMissingAddress}
              className="iyzi-button"
              sx={{ ml: "10px" }}
            >
              Ekle
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={missingProformaModal}
        onClose={() => setMissingProformaModal(false)}
        aria-labelledby="missing-modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus
      >
        <Box sx={style2}>
          <DialogTitle id="missing-modal-modal-title">
            Proforma Hatası Seçimleri
          </DialogTitle>
          <Autocomplete
            multiple
            id="multi-select-autocomplete"
            options={missingProformaTypes}
            getOptionLabel={(option) => option.label}
            value={selectedMissingProformaOptions}
            onChange={handleMultiSelectChange2}
            renderInput={(params) => (
              <TextField {...params} label="Çoklu Seçim" />
            )}
          />
          {selectedMissingProformaOptions.filter((f) => f.extraContent).length >
          0 ? (
            <FormControl style={{ marginTop: "10px" }} fullWidth size="small">
              <TextField
                label="Diğer Seçenek Yazınız"
                value={inputOtherValue2}
                onChange={handleInputOtherChange2}
                onKeyPress={handleInputOtherKeyPress2}
              />
              <div>
                <Box style={{ marginTop: "12px" }}>
                  Eklenecek Diğer Seçenekler
                </Box>
                {otherTags2.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onDelete={() => handleTagDelete2(tag)}
                    style={{ margin: "4px" }}
                  />
                ))}
              </div>
            </FormControl>
          ) : null}
          <Box
            sx={{
              display: " flex",
              justifyContent: "flex-end",
              marginTop: "5px",
            }}
          >
            <Button
              type="button"
              onClick={() => {
                setMissingProformaModal(false);
                setSelectedMissingProformaOptions([]);
                setOtherTags2([]);
                setInputOtherValue2("");
              }}
              className="iyzi-button"
            >
              Vazgeç
            </Button>
            <Button
              onClick={handleRegisterMissingProforma}
              className="iyzi-button"
              sx={{ ml: "10px" }}
            >
              Ekle
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default StatusChangeModal;
