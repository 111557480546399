import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";

const OperationMeasureControlResultCheck = () => {
  const [checkDegub, setCheckDebug] = useState(false);
  const [inform, setInform] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "var(--gap)",
        alignItems: "center",
      }}
    >
      <FormControlLabel
        label="Debug Mod (Admin)"
        control={
          <Checkbox
            checked={checkDegub}
            onChange={() => setCheckDebug(!checkDegub)}
          />
        }
      />
      <FormControlLabel
        label="Alıcıyı E-posta ile Bilgilendir"
        control={
          <Checkbox checked={inform} onChange={() => setInform(!inform)} />
        }
      />
      <Button className="iyzi-button">Fiyat Detaylarını Göster</Button>
    </div>
  );
};

export default OperationMeasureControlResultCheck;
