const FormatDateDots = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return "---";
  } else return [day, month, year].join(".");
};

export default FormatDateDots;
