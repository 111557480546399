import "../../../style/operation/Operation.css";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useInView, animated } from "@react-spring/web";
import axios from "../../../api/axios";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import NavBar from "../sidebar/NavBar";
import { toast, ToastContainer } from "react-toastify";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import { useNavigate } from "react-router-dom";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import WrapperStyle from "../../../utils/WrapperStyle";
import Errors from "../../../utils/Errors";
import PDFReader from "./PDFReader";
import { useRecoilState } from "recoil";
import { ffdBase64State } from "../../../atoms/operation";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const GetBarcode = () => {
  DynamicTitle("Gönderi Ara -  Yazdır - Admin");

  const navigate = useNavigate();

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [checkedAwb, setCheckedAwb] = useState(true);
  const [checkedHawb, setCheckedHawb] = useState(false);
  const [checkedPdf, setCheckedPdf] = useState(false);

  const [awb, setAwb] = useState("");
  const [hawb, setHawb] = useState("");

  const [loading, setLoading] = useState(false);

  const [ffdBase64, setFfdBase64] = useRecoilState(ffdBase64State);

  const [manual, setManual] = useState(0);

  const [loadedParcel, setLoadedParcel] = useState({});
  const [statuChangeResponse, setStatuChangeResponse] = useState({});

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  useEffect(() => {
    if (manual === 0 && hawb !== "" && awb !== "") {
      const timeoutId = setTimeout(() => {
        setLoading(true);
        getAndSetCustomParcel();
      }, 750);
      return () => {
        setLoading(false);
        clearTimeout(timeoutId);
      };
    }
    setAwb(awb);
    setHawb(hawb);
  }, [awb, hawb]);

  useEffect(() => {
    console.log("Loading Status Updated.");
    setLoading(loading);
  }, [loading]);

  const handleCheckedAwb = () => {
    setCheckedAwb(true);
    setCheckedHawb(false);
  };

  const handleCheckedHawb = () => {
    setCheckedAwb(false);
    setCheckedHawb(true);
  };

  const handleCheckedPdf = () => {
    setCheckedPdf(checkedPdf === true ? false : true);
  };

  const handleAwb = (e) => {
    const value = e.target.value.slice(0, 12);
    setAwb(value);
  };

  const handleHawb = (e) => {
    const value = e.target.value;
    setHawb(value);
  };

  const getAndSetCustomParcel = () => {
    const parcel_ref_no = checkedAwb ? awb : "";
    const tracking_awb = checkedHawb ? hawb : "";

    if ((checkedAwb && awb !== "") || (checkedHawb && hawb !== "" && loading)) {
      axios
        .get(
          `parcel/operation/get-custom-parcel?parcel_ref_no=${parcel_ref_no}&tracking_awb=${tracking_awb}&barcode=1`
        )
        .then((response) => {
          console.log(response);
          setLoading(false);
          if (response.data.status == 200) {
            toast.success("Gönderi Getirildi");
            setLoadedParcel(response.data.data);
            const parcelLabels = response.data.data.get_parcel_labels;
            const filteredLabels = parcelLabels
              .filter(label =>
                label.type === "pdf" &&                            // Yalnızca PDF tipi etiketler
                !label.file_path.includes("commercial") &&          // "commercial" içermeyenler
                !label.file_path.includes("invoice") &&             // "invoice" içermeyenler
                !label.file_path.includes("Commercial") &&          // Büyük harfle "Commercial" içermeyenler
                !label.file_path.includes("Invoice") &&             // Büyük harfle "Invoice" içermeyenler
                label.label_type !== "invoice"                       // "invoice" türü olmayanlar
              )
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // En yeni etiketi ilk sıraya alır
            // İlk uygun customLabel'ı al
            const customLabel = filteredLabels.length > 0 ? filteredLabels[0].customLabel : null;

            setFfdBase64(checkedPdf ? customLabel : response.data.data.base64);
            setAwb("");
            setHawb("");
          }
        })
        .catch((error) => Errors(error));
    } else {
      console.log("hawb veya ref no henüz okutulmadı.");
    }
  };

  const printDocument = () => {
    const byteCharacters = atob(ffdBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement("iframe");

    // The component should be mounted in the DOM tree before calling print
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.onload = function () {
      setTimeout(() => {
        // Give the browser some time to render the PDF
        iframe.contentWindow.print();
        URL.revokeObjectURL(blobUrl); // Free up memory
      }, 0);
    };
    iframe.src = blobUrl;
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    const parcel_ref_no = checkedAwb ? awb : "";
    const tracking_awb = checkedHawb ? hawb : "";
    setManual(1);
    axios
      .get(
        `parcel/operation/get-custom-parcel?parcel_ref_no=${parcel_ref_no}&tracking_awb=${tracking_awb}&manual=${manual}&barcode=1`
      )
      .then((response) => {
        toast.success("Gönderi Getirildi");
        setManual(0);
        setLoadedParcel(response.data.data);
        const parcelLabels = response.data.data.get_parcel_labels;

        const filteredLabels = parcelLabels
          .filter(label =>
            label.type === "pdf" &&                            // Yalnızca PDF tipi etiketler
            !label.file_path.includes("commercial") &&          // "commercial" içermeyenler
            !label.file_path.includes("invoice") &&             // "invoice" içermeyenler
            !label.file_path.includes("Commercial") &&          // Büyük harfle "Commercial" içermeyenler
            !label.file_path.includes("Invoice") &&             // Büyük harfle "Invoice" içermeyenler
            label.label_type !== "invoice"                       // "invoice" türü olmayanlar
          )
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // En yeni etiketi ilk sıraya alır

          console.log(filteredLabels);

        // İlk uygun customLabel'ı al
        const customLabel = filteredLabels.length > 0 ? filteredLabels[0].customLabel : null;

        setFfdBase64(checkedPdf ? customLabel : response.data.data.base64);
        setAwb("");
        setHawb("");
      })
      .catch((error) => Errors(error));
  };

  useEffect(() => {
    if (awb.length === 12) {
      handleSubmit();
    }
  }, [awb]);

  useEffect(() => {
    printDocument();
  }, [ffdBase64]);

  return (
    <ThemeProvider theme={theme}>
      <section className="operation" style={{ paddingLeft: "64px" }}>
        <NavBar />
        <ToastContainer />
        {loading && (
          <Box
            sx={{
              height: "500px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="loading-logo" src={miniLogo} alt="" />
          </Box>
        )}
        {!loading && (
          <Box className="operation-wrapper" style={WrapperStyle()}>
            <animated.div ref={ref} style={springs}>
              <Box className="operation-container">
                <h6
                  style={{
                    color: "var(--priceText)",
                    marginTop: "var(--gap2x)",
                    marginBottom: "var(--gap4x)",
                  }}
                >
                  Gönderi Ara & Yazdır
                </h6>
                <div className="operation-security-check">
                  <div style={{ display: "flex", gap: "var(--gap)" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedOutlinedIcon />}
                          checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                          checked={checkedAwb}
                          onChange={handleCheckedAwb}
                        />
                      }
                      label="Referans Numarası"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedOutlinedIcon />}
                          checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                          checked={checkedHawb}
                          onChange={handleCheckedHawb}
                        />
                      }
                      label="AWB"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedOutlinedIcon />}
                          checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                          checked={checkedPdf}
                          onChange={handleCheckedPdf}
                        />
                      }
                      label="Etiket"
                    />
                  </div>
                  {checkedAwb && (
                    <form
                      style={{ display: "flex", gap: "var(--gap2x)" }}
                      onSubmit={handleSubmit}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        required
                        label={checkedHawb ? "Awb" : "Referans Numarası"}
                        value={awb}
                        onChange={handleAwb}
                      />
                      <Button onClick={handleSubmit} className="iyzi-button">
                        Ara
                      </Button>
                    </form>
                  )}
                  {checkedHawb && (
                    <form
                      style={{ display: "flex", gap: "var(--gap2x)" }}
                      onSubmit={handleSubmit}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        required
                        label="Hawb"
                        value={hawb}
                        onChange={handleHawb}
                      />
                      <Button onClick={handleSubmit} className="iyzi-button">
                        Ara
                      </Button>
                    </form>
                  )}
                </div>
                {loadedParcel && Object.keys(loadedParcel).length > 0 ? (
                  <>
                    <div className="operation-cargo-dom-resp-pdf">
                      <div className="resp-card-wrapper">
                        <div
                          style={{
                            display: " flex",
                            justifyContent: " end",
                            marginBottom: "10px",
                          }}
                        >
                          <Button
                            onClick={printDocument}
                            className="iyzi-button-blue"
                            style={{ marginLeft: "3px" }}
                          >
                            Yazdır
                          </Button>
                        </div>
                        <div
                          style={{ display: " flex", justifyContent: "center" }}
                        >
                          <PDFReader base64String={ffdBase64} />
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </Box>
            </animated.div>
          </Box>
        )}
      </section>
    </ThemeProvider>
  );
};

export default GetBarcode;
