import GetToday from "./GetToday";

const DownloadBase64Pdf = (base64, description, title, startDate, endDate) => {
  const today = GetToday();
  const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.target = "_blank";
  title === "finance"
    ? (downloadLink.download = `${startDate}_${endDate}_${description}.xlsx`)
    : (downloadLink.download = `${today}_${description}.pdf`);
  //downloadLink.download = `${today}_${description}.pdf`
  downloadLink.click();
};

export default DownloadBase64Pdf;


function DownloadBase64Excel(base64Data, filename) {
  const blob = base64toBlob(base64Data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename + '.xlsx';
  link.click();
}

function base64toBlob(base64Data, contentType) {
const byteCharacters = atob(base64Data);
const byteNumbers = new Array(byteCharacters.length);
for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
}
const byteArray = new Uint8Array(byteNumbers);
return new Blob([byteArray], { type: contentType });
}
