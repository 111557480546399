import {
  Autocomplete,
  Box,
  Button,
  createTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {useCallback, useEffect, useState} from "react";
import axios from "../../../api/axios";
import {
  shipmentStatusState,
  timeState,
  senderCountryState,
  senderCountryCodeState,
  receiverCountryState,
  receiverCountryCodeState,
  selectedSupplierState,
  selectedPriceStatusState,
  selectedExportStatusState,
  selectedDomesticStatusState,
  selectedCaseStatusState,
  awbState,
  refIdState,
  senderState,
  receiverState,
} from "../../../atoms/myShipments";
import { useRecoilState, useRecoilValue } from "recoil";
import countriesDetailed from "../../../json/countriesDetailed.json";
import {
  returnShipmentListLoadingState,
  returnShipmentListState,
  returnShipmentListLimitState,
  returnShipmentListOrderDirectionState,
  returnDetailedStatusState,
  returnBasicSearchErrorState,
  returnTotalPagesState,
  returnErrorSetState,
} from "../../../atoms/returnShipment";
import Errors from "../../../utils/Errors";
import {useNavigate} from "react-router-dom";

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          color: "currentcolor",
        },
        icon: {
          color: "currentcolor",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "currentcolor",
        },
      },
    },
  },
});

const ReturnShipmentDetailedSearch = ({ expanded }) => {
  const navigate = useNavigate();
  const [time, setTime] = useRecoilState(timeState);
  const [senderCountry, setSenderCountry] = useRecoilState(senderCountryState);
  const [senderCountryCode, setSenderCountryCode] = useRecoilState(senderCountryCodeState);
  const [receiverCountry, setReceiverCountry] = useRecoilState(receiverCountryState);
  const [receiverCountryCode, setReceiverCountryCode] = useRecoilState(receiverCountryCodeState);

  const [selectedSupplier, setSelectedSupplier] = useRecoilState(selectedSupplierState);
  const [selectedPriceStatus, setSelectedPriceStatus] = useRecoilState(selectedPriceStatusState);
  const [selectedExportStatus, setSelectedExportStatus] = useRecoilState(selectedExportStatusState);
  const [selectedDomesticStatus, setSelectedDomesticStatus] = useRecoilState(
    selectedDomesticStatusState
  );
  const [selectedCaseStatus, setSelectedCaseStatus] = useRecoilState(selectedCaseStatusState);

  const [awb, setAwb] = useRecoilState(awbState);
  const [refId, setRefId] = useRecoilState(refIdState);
  const [sender, setSender] = useRecoilState(senderState);
  const [receiver, setReceiver] = useRecoilState(receiverState);

  const [, setReturnShipmentListLoading] = useRecoilState(returnShipmentListLoadingState);
  const [, setReturnShipmentList] = useRecoilState(returnShipmentListState);

  const returnShipmentListLimit = useRecoilValue(returnShipmentListLimitState);
  const returnShipmentOrderDirectionPage = useRecoilValue(returnShipmentListOrderDirectionState);
  const shipmentStatus = useRecoilValue(shipmentStatusState);

  const [, setReturnDetailedStatus] = useRecoilState(returnDetailedStatusState);
  const [, setDeductionCostTryPages] = useRecoilState(returnTotalPagesState);
  const [, setReturnBasicSearchError] = useRecoilState(returnBasicSearchErrorState);
  const [, setReturnErrorSet] = useRecoilState(returnErrorSetState);

  const [suppliers, setSuppliers] = useState([]);

  const getSuppliers = () => {
    axios
      .get("supplier")
      .then((response) => {
        setSuppliers(response.data.data);
      })
      .catch((error) => Errors(error));
  };

  const handleDetailedSearch = useCallback(async () => {
    setReturnShipmentListLoading(true);
    setReturnDetailedStatus(1);

    await axios
      .get(
        `parcel-return?order_direction=${returnShipmentOrderDirectionPage}&limit=${returnShipmentListLimit}&page=${1}&tracking_awb=${awb}&parcel_ref_no=${refId}&supplier_id=${
          selectedSupplier !== "" ? Number(selectedSupplier) : selectedSupplier
        }&status=${shipmentStatus}&tracking_awb_date=${time}&sender_name=${sender}&receiver_name=${receiver}&sender_country=${senderCountryCode}&receiver_country=${receiverCountryCode}&parcel_type=${selectedExportStatus}&paid=${selectedPriceStatus}&is_domestic=${selectedDomesticStatus}&is_case=${selectedCaseStatus}&detailed=${1}`
      )
      .then((response) => {
        setReturnShipmentList(response.data.data);
        setDeductionCostTryPages(response.data.totalPages);
        setReturnShipmentListLoading(false);
        setReturnErrorSet(true);
        setReturnBasicSearchError(false);
      })
      .catch((error) => {
        setReturnShipmentListLoading(false);
        Errors(error);
      });
  }, [
    setReturnShipmentList,
    setReturnShipmentListLoading,
    setReturnDetailedStatus,
    setDeductionCostTryPages,
    setReturnErrorSet,
    setReturnBasicSearchError,
    awb,
    time,
    returnShipmentOrderDirectionPage,
    returnShipmentListLimit,
    refId,
    selectedSupplier,
    shipmentStatus,
    sender,
    senderCountryCode,
    receiver,
    receiverCountryCode,
    selectedExportStatus,
    selectedDomesticStatus,
    selectedCaseStatus,
    selectedPriceStatus,
  ]);

  useEffect(() => {
    if (expanded) {
      getSuppliers();
    }
  }, [expanded]);

  return (
    <ThemeProvider theme={theme}>
      <Box className="returnShipment-detailed-search-wrapper">
        <h6 className="returnShipment-detailed-search-title">
          <TravelExploreOutlinedIcon />
          Detaylı Arama
        </h6>
        <Box className="returnShipment-detailed-search">
          <TextField
            value={awb}
            onChange={(e) => setAwb(e.target.value)}
            autoComplete="off"
            fullWidth
            size="small"
            label="AWB"
          />
          <TextField
            autoComplete="off"
            fullWidth
            size="small"
            label="Referans ID"
            value={refId}
            onChange={(e) => setRefId(e.target.value)}
          />
          <FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: "14px" }} id="supplier-select-label">
              Tedarikçi
            </InputLabel>
            <Select
              labelId="supplier-select-label"
              id="supplier-select"
              label="Tedarikçi"
              value={selectedSupplier}
              onChange={(e) => setSelectedSupplier(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Hepsi</em>
              </MenuItem>
              {suppliers?.map((sup, key) => (
                <MenuItem key={key} value={sup.id}>
                  {sup.supplier_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            type={"date"}
            label="Tarih Aralığı"
            InputLabelProps={{ shrink: true }}
            required
            value={time}
            onChange={(e) => setTime(e.target.value)}
            size={"small"}
          />
          <TextField
            autoComplete="off"
            fullWidth
            size="small"
            label="Gönderici Kişi"
            value={sender}
            onChange={(e) => setSender(e.target.value)}
          />
          <TextField
            autoComplete="off"
            fullWidth
            size="small"
            label="Alıcı Kişi"
            value={receiver}
            onChange={(e) => setReceiver(e.target.value)}
          />

          <Autocomplete
            id="country-typing-select"
            fullWidth
            size="small"
            options={countriesDetailed}
            value={
              countriesDetailed.find((option) => option.iso_code === senderCountryCode) || null
            }
            onChange={(e, val) => {
              if (val) {
                setSenderCountryCode(val.iso_code);
                setSenderCountry(val.country_name_tr);
              } else {
                setSenderCountryCode("");
                setSenderCountry("");
              }
            }}
            autoHighlight
            openOnFocus={false}
            getOptionLabel={(option) => `${option.country_name_tr}, ${option.country_name_en}`}
            renderOption={(props, option) => (
              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.iso_code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.iso_code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.country_name_tr}, {option.country_name_en}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Çıkış Ülkesi"
                required
                name="unique-country-field"
                value={senderCountryCode}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable autocomplete and autofill
                }}
              />
            )}
          />
          <Autocomplete
            id="country-typing-select"
            fullWidth
            size="small"
            options={countriesDetailed}
            value={
              countriesDetailed.find((option) => option.iso_code === receiverCountryCode) || null
            }
            onChange={(e, val) => {
              if (val) {
                setReceiverCountryCode(val.iso_code);
                setReceiverCountry(val.country_name_tr);
              } else {
                setReceiverCountryCode("");
                setReceiverCountry(val.country_name_tr);
              }
            }}
            autoHighlight
            openOnFocus={false}
            getOptionLabel={(option) => `${option.country_name_tr}, ${option.country_name_en}`}
            renderOption={(props, option) => (
              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.iso_code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.iso_code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.country_name_tr}, {option.country_name_en}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Varış Ülkesi"
                required
                name="unique-country-field"
                value={receiverCountryCode}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable autocomplete and autofill
                }}
              />
            )}
          />

          <FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: "14px" }} id="price-status-select-label">
              Ödeme Durumu
            </InputLabel>
            <Select
              labelId="price-status-select-label"
              id="price-status-select"
              label="Ödeme Durumu"
              value={selectedPriceStatus}
              multiline
              onChange={(e) => setSelectedPriceStatus(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={0}>Ödeme Bekleyenler</MenuItem>
              <MenuItem value={1}>Ödenenler</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: "14px" }} id="export-status-select-label">
              İhracat Tipi
            </InputLabel>
            <Select
              labelId="export-status-select-label"
              id="export-status-select"
              label="İhracat Tipi"
              value={selectedExportStatus}
              onChange={(e) => setSelectedExportStatus(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={1}>Normal</MenuItem>
              <MenuItem value={2}>Hediye</MenuItem>
              <MenuItem value={3}>Mikro İhracat</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: "14px" }} id="domestic-status-select-label">
              Domestik Durumu
            </InputLabel>
            <Select
              labelId="domestic-status-select-label"
              id="domestic-status-select"
              label="Domestik Durumu"
              value={selectedDomesticStatus}
              multiline
              onChange={(e) => setSelectedDomesticStatus(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Hepsi</em>
              </MenuItem>
              <MenuItem value={0}>Eklenmemiş</MenuItem>
              <MenuItem value={1}>Eklenmiş</MenuItem>
            </Select>
          </FormControl>
          {/*<FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: "14px" }} id="case-status-select-label">
              Case Durumu
            </InputLabel>
            <Select
              labelId="case-status-select-label"
              id="case-status-select"
              label="Case Durumu"
              value={selectedCaseStatus}
              multiline
              onChange={(e) => setSelectedCaseStatus(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={0}>Case Olmayanlar</MenuItem>
              <MenuItem value={1}>Case Olanlar</MenuItem>
            </Select>
          </FormControl>*/}
        </Box>
        <Box className="returnShipment-detailed-search-done">
          <Button className="iyzi-button" onClick={handleDetailedSearch}>
            <SearchOutlinedIcon />
            Arama Yap
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ReturnShipmentDetailedSearch;
