import { TableCell, TableHead, TableRow } from "@mui/material";
import axios from "../../../../api/axios";
import { useRecoilState } from "recoil";
import {
  financeFaturaCurrentPageState,
  financeFaturaDataState,
  financeFaturaTotalPageState,
} from "../../../../atoms/finance";
import { useCallback, useState } from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Errors from "../../../../utils/Errors";

const FinanceFaturaTableHead = () => {
  const [, setFinanceFaturaData] = useRecoilState(financeFaturaDataState);
  const [, setFinanceFaturaTotalPage] = useRecoilState(financeFaturaTotalPageState);
  const [financeFaturaCurrentPage, setFinanceFaturaCurrentPage] = useRecoilState(
    financeFaturaCurrentPageState
  );

  const [activeSort, setActiveSort] = useState([
    { column: "created_at", direction: null },
    { column: "member_invoice_no", direction: null },
    { column: "member_payable_amount", direction: null },
    { column: "invoice_note", direction: null },
    { column: "status", direction: null },
  ]);

  const handlePageChange = useCallback(
    async (orderBy) => {
      const updatedSort = activeSort.map((sort) => {
        if (sort.column === orderBy) {
          return {
            ...sort,
            direction: sort.direction === "ASC" ? "DESC" : "ASC",
          };
        }
        return {
          ...sort,
          direction: null, // Diğer sütunların yönünü varsayılan duruma sıfırlayın
        };
      });

      setActiveSort(updatedSort);

      const currentDirection = updatedSort.find((sort) => sort.column === orderBy).direction;

      await axios
        .get(`refund-compensation`, {
          params: {
            page: financeFaturaCurrentPage,
            limit: 100,
            order_by: orderBy,
            order_direction: currentDirection,
          },
        })
        .then((resp) => {
          setFinanceFaturaData(resp.data.data);
          setFinanceFaturaCurrentPage(resp.data.currentPage);
          setFinanceFaturaTotalPage(resp.data.totalPages);
        })
        .catch((error) => Errors(error));
    },
    [
      setFinanceFaturaData,
      setFinanceFaturaCurrentPage,
      setFinanceFaturaTotalPage,
      financeFaturaCurrentPage,
      activeSort,
    ]
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ fontWeight: 700 }}>Muhasebe Kodu</TableCell>
        <TableCell
          onClick={() => handlePageChange("member_payable_amount")}
          sx={{ fontWeight: 700, cursor: "pointer" }}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Müşteri ID{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "member_payable_amount")?.direction ===
                  "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Müşteri Adı</TableCell>
        <TableCell
          onClick={() => handlePageChange("member_payable_amount")}
          sx={{ fontWeight: 700, cursor: "pointer" }}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Fatura No{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "member_payable_amount")?.direction ===
                  "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          onClick={() => handlePageChange("member_payable_amount")}
          sx={{ fontWeight: 700, cursor: "pointer" }}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Tarih{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "member_payable_amount")?.direction ===
                  "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          onClick={() => handlePageChange("member_payable_amount")}
          sx={{ fontWeight: 700, cursor: "pointer" }}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Fatura Açıklaması{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "member_payable_amount")?.direction ===
                  "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Fatura Gönderi Adedi</TableCell>
        <TableCell
          onClick={() => handlePageChange("member_payable_amount")}
          sx={{ fontWeight: 700, cursor: "pointer" }}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Fatura Tutarı{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "member_payable_amount")?.direction ===
                  "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default FinanceFaturaTableHead;
