import {
  Box,
  Button,
  createTheme,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  caseExplainState,
  caseListState,
  caseProblemState,
  caseTextState,
  openNewCaseState,
  ticketState,
} from "../../../../atoms/support";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import axios from "../../../../api/axios";
import MessageTime from "../../../../utils/MessageTime";
import SupportCaseQuestionType from "../../../../utils/SupportCaseQuestionType";
import Errors from "../../../../utils/Errors";
import SupportDetailCaseListAdmin from "./SupportDetailCaseListAdmin";
import { toast } from "react-toastify";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px!important",
        },
      },
    },
  },
});

const SupportDetailCaseAdmin = () => {
  const ticket = useRecoilValue(ticketState);

  const [caseProblem, setCaseProblem] = useRecoilState(caseProblemState);
  const [caseExplain, setCaseExplain] = useRecoilState(caseExplainState);
  const [openNewCase, setOpenNewCase] = useRecoilState(openNewCaseState);
  const [caseList, setCaseList] = useRecoilState(caseListState);
  console.log(caseList);

  const [error, setError] = useState(false);

  const handleAbortNewCase = useCallback(() => {
    setOpenNewCase(false);
  }, [setOpenNewCase]);

  const handleSendCase = useCallback(async () => {
    let hasError = false;
    if (hasError) {
      for (const item of caseList) {
        if (item.question_type >= 1 || item.question_type <= 7) {
          hasError = true;
          setError(true);
          toast.error("Aynı sorun için ikinci bir yazışma açılamaz");
          break;
        }
      }
    }
    if (!hasError) {
      await axios
        .post(`ticket/case/add/${ticket.id}`, {
          question_type: caseProblem,
        })
        .then((response) => {
          if (response.data.status === 200) {
            setCaseList([...caseList, response.data.data]);
            handleAbortNewCase();
            setCaseProblem(0);
            toast.success("Case açıldı.");
          }
        })
        .catch((error) => Errors(error));
    }
  }, [
    ticket.id,
    caseList,
    setCaseList,
    handleAbortNewCase,
    caseProblem,
    setCaseProblem,
  ]);
  return (
    <>
      <h6 style={{ color: "var(--redBrand)", textAlign: "center" }}>
        Case Yazışmaları
      </h6>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            minHeight: 400,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {openNewCase && (
            <FormControl
              sx={{
                minHeight: 400,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "var(--gap2x)",
                }}
              >
                <FormControl required size="small" fullWidth>
                  <InputLabel id="problem-label">Sorun</InputLabel>
                  <Select
                    labelId="problem-label"
                    id="problem"
                    value={caseProblem}
                    onChange={(e) => setCaseProblem(e.target.value)}
                    label="Sorun"
                  >
                    <MenuItem disabled value={0}>
                      <em>Seçiniz</em>
                    </MenuItem>
                    <MenuItem value={1}>
                      Adres/İletişim Bilgisi Yetersiz BA
                    </MenuItem>
                    <MenuItem value={2}>Alıcı Adresi Yok NH</MenuItem>
                    <MenuItem value={3}>Hasar DM</MenuItem>
                    <MenuItem value={4}>Kayıp LT</MenuItem>
                    <MenuItem value={5}>
                      Adres Değişikliği Taleplerinde AC
                    </MenuItem>
                    <MenuItem value={6}>Gümrük Gecikmesi CD</MenuItem>
                    <MenuItem value={7}>Evrak Eksiği PW</MenuItem>
                    <MenuItem value={8}>
                      Alıcı Kurum ise Adreste Yok CA
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {error ? (
                <FormHelperText
                  sx={{ color: "var(--redBrand)", fontWeight: 700 }}
                >
                  Lütfen bilgilerinizi kontrol ediniz.
                </FormHelperText>
              ) : (
                ""
              )}
              <Box sx={{ display: "flex", gap: "var(--gap)" }}>
                <Button
                  fullWidth
                  sx={{
                    backgroundColor: "var(--info)",
                    color: "var(--lighterBg)",
                    textTransform: "none",
                    transition: "all 150ms linear",
                    borderRadius: "var(--inputBorderRadius)",
                    ":hover": {
                      backgroundColor: "var(--blueBrand)",
                      transition: "all 150ms linear",
                    },
                  }}
                  onClick={handleAbortNewCase}
                  type="button"
                >
                  İptal
                </Button>
                <Button
                  fullWidth
                  sx={{
                    backgroundColor: "var(--info)",
                    color: "var(--lighterBg)",
                    textTransform: "none",
                    transition: "all 150ms linear",
                    borderRadius: "var(--inputBorderRadius)",
                    ":hover": {
                      backgroundColor: "var(--blueBrand)",
                      transition: "all 150ms linear",
                    },
                  }}
                  type="button"
                  onClick={handleSendCase}
                >
                  Case Ekle
                </Button>
              </Box>
            </FormControl>
          )}
          {!openNewCase ? (
            caseList.length === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "var(--gap2x)",
                  height: "100%",
                  minHeight: 400,
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SpeakerNotesIcon
                    sx={{
                      width: 100,
                      height: 100,
                      color: "var(--info)",
                    }}
                  />
                  <Typography sx={{ textAlign: "center" }}>Case Boş</Typography>
                </Box>
                <Button
                  sx={{
                    backgroundColor: "var(--info)",
                    color: "var(--lighterBg)",
                    textTransform: "none",
                    transition: "all 150ms linear",
                    borderRadius: "var(--inputBorderRadius)",
                    width: "200px!important",
                    margin: "0 auto",
                    ":hover": {
                      backgroundColor: "var(--blueBrand)",
                      transition: "all 150ms linear",
                    },
                  }}
                  onClick={() => setOpenNewCase(true)}
                >
                  Yeni Case Ekle
                </Button>
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "var(--gap2x)",
                  }}
                >
                  {caseList.map((item, key) => (
                    <SupportDetailCaseListAdmin key={key} item={item} />
                  ))}
                </Box>
                <Button
                  sx={{
                    backgroundColor: "var(--info)",
                    color: "var(--lighterBg)",
                    textTransform: "none",
                    transition: "all 150ms linear",
                    borderRadius: "var(--inputBorderRadius)",
                    width: "200px!important",
                    margin: "0 auto",
                    ":hover": {
                      backgroundColor: "var(--blueBrand)",
                      transition: "all 150ms linear",
                    },
                  }}
                  onClick={() => setOpenNewCase(true)}
                >
                  Yeni Case Ekle
                </Button>
              </>
            )
          ) : (
            ""
          )}
        </Box>
      </ThemeProvider>
    </>
  );
};

export default SupportDetailCaseAdmin;
