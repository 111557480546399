import {
  Box,
  Button,
  createTheme,
  Modal,
  TextField,
  ThemeProvider,
} from "@mui/material";
import {
  ffdIyziTeamDimemsionState,
  ffdDataState,
} from "../../../../atoms/operation";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import axios from "../../../../api/axios";
import {
  weightCheckOpenState,
  selectedShipmentForMyShipmentsState,
} from "../../../../atoms/myShipments";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Errors from "../../../../utils/Errors";
import { useEffect, useState, useCallback } from "react";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px",
          overflow: "auto",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const WeightCheckModal = () => {
  const [weightCheckOpen, setWeightCheckOpen] =
    useRecoilState(weightCheckOpenState);
  const [selectedShipmentForMyShipments, setSelectedShipmentForMyShipments] =
    useRecoilState(selectedShipmentForMyShipmentsState);

  const [ffdData, setFfdData] = useRecoilState(ffdDataState);

  const [dimensions, setDimensions] = useRecoilState(ffdIyziTeamDimemsionState);

  const handleClose = () => {
    setWeightCheckOpen(false);
    setSelectedShipmentForMyShipments([]);
  };

  const sendDimensions = (e) => {
    axios
      .post()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Notunuz Başarıyla Eklendi.", { autoClose: 500 });
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      })
      .catch((error) => Errors(error));
    setWeightCheckOpen(false);
    setSelectedShipmentForMyShipments([]);
  };

  const [totalCw, setTotalCw] = useState();

  const roundWeight = (weight) => {
    if (weight <= 0.01) {
      return 0.01;
    } else if (weight < 10) {
      const integerPart = Math.floor(weight);
      const decimalPart = weight - integerPart;
      if (decimalPart > 0 && decimalPart <= 0.5) {
        return integerPart + 0.5;
      } else {
        return Math.ceil(weight * 2) / 2; // Bu, 0.5 adımlarla yukarı doğru yuvarlar
      }
    } else {
      return Math.ceil(weight);
    }
  };

  const handleInputChange = useCallback((e, i) => {
    const { name, value } = e.target;

    setDimensions((prevDimensions) => {
      const updatedDimensions = [...prevDimensions];
      let fvalue = value.replace(/,/g, ".");

      if (name === "qty") {
        updatedDimensions[i] = {
          ...updatedDimensions[i],
          qty: fvalue.replace(/[^0-9]/g, ""),
        };
      } else {
        const value = fvalue.replace(/[^0-9.]|(?<=\..*)\.|^0{2,}(?=[\.])/g, "");
        const regex = /^\d+(\.\d{0,2})?$/;

        if (value.startsWith(".") || (!regex.test(value) && value !== "")) {
          return prevDimensions;
        } else {
          if (name === "gross") {
            let newValue = value.replace(/^0{2,}/, "0");
            if (Number(fvalue) > 300) {
              newValue = "300";
            }
            updatedDimensions[i] = {
              ...updatedDimensions[i],
              gross: newValue,
            };
          } else {
            updatedDimensions[i] = {
              ...updatedDimensions[i],
              [name]: value,
            };
          }
        }
      }

      const calculatedCw = updatedDimensions?.map((dimension) =>
        ((Number(dimension.width) *
          Number(dimension.length) *
          Number(dimension.height)) /
          5000) *
          Number(dimension.qty) >
        Number(dimension.gross) * Number(dimension.qty)
          ? ((Number(dimension.width) *
              Number(dimension.length) *
              Number(dimension.height)) /
              5000) *
            Number(dimension.qty)
          : Number(dimension.gross) * Number(dimension.qty)
      );

      setTotalCw(
        roundWeight(calculatedCw?.reduce((acc, item) => acc + item, 0))
      );
      return updatedDimensions;
    });
  }, []);

  useEffect(() => {
    const filteredItems = selectedShipmentForMyShipments?.map(
      (item) => item.status === "2"
    );
    if (filteredItems.length > 0) {
      const transformedData = filteredItems?.map((item, index) => ({
        width: item.width || "",
        length: item.length || "",
        height: item.height || "",
        gross: item.gross || "",
        qty: item.qty || "",
        id: item.id !== 0 ? item.id : 0,
      }));

      setDimensions(transformedData);
      const calculatedCw = transformedData?.map((dimension) =>
        ((Number(dimension.width) *
          Number(dimension.length) *
          Number(dimension.height)) /
          5000) *
          Number(dimension.qty) >
        Number(dimension.gross) * Number(dimension.qty)
          ? ((Number(dimension.width) *
              Number(dimension.length) *
              Number(dimension.height)) /
              5000) *
            Number(dimension.qty)
          : Number(dimension.gross) * Number(dimension.qty)
      );

      setTotalCw(calculatedCw?.reduce((acc, item) => acc + item, 0));
    } else {
      const transformedData = {
        width: "",
        length: "",
        height: "",
        gross: "",
        qty: "",
        id: 0,
      };

      setDimensions([transformedData]);
      setTotalCw(0);
    }
  }, [setDimensions]);

  return (
    <ThemeProvider theme={theme}>
      <Modal open={weightCheckOpen} onClose={handleClose}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "var(--gap)",
            background: "white",
            padding: "20px",
          }}
        >
          {dimensions.map((dim, index) => (
            <form
              onSubmit={sendDimensions}
              className="operation-cargo-dom-resp-weight-iyziteam-dimension"
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
                gap: "30px",
              }}
            >
              <div className="operation-cargo-dom-resp-weight-iyziteam-item">
                <div className="little-title">Genişlik</div>
                <TextField
                  size="small"
                  name="width"
                  value={dim.width}
                  required
                  onChange={(e) => handleInputChange(e, index)}
                  sx={{
                    backgroundColor: "#e9ecef",
                    width: "50px",
                    height: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0",
                    "& fieldset": { border: "none" },
                  }}
                />
              </div>
              <div className="operation-cargo-dom-resp-weight-iyziteam-item">
                <div className="little-title">Uzunluk</div>
                <TextField
                  size="small"
                  name="length"
                  required
                  value={dim.length}
                  onChange={(e) => handleInputChange(e, index)}
                  sx={{
                    backgroundColor: "#e9ecef",
                    width: "50px",
                    height: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0",
                    "& fieldset": { border: "none" },
                  }}
                />
              </div>
              <div className="operation-cargo-dom-resp-weight-iyziteam-item">
                <div className="little-title">Yükseklik</div>
                <TextField
                  size="small"
                  name="height"
                  value={dim.height}
                  required
                  onChange={(e) => handleInputChange(e, index)}
                  sx={{
                    backgroundColor: "#e9ecef",
                    width: "50px",
                    height: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0",
                    "& fieldset": { border: "none" },
                  }}
                />
              </div>
              <div className="operation-cargo-dom-resp-weight-iyziteam-item">
                <div className="little-title">Kap</div>
                <TextField
                  size="small"
                  value={dim.qty}
                  required
                  onChange={(e) => handleInputChange(e, index)}
                  name="qty"
                  sx={{
                    backgroundColor: "#e9ecef",
                    width: "50px",
                    height: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0",
                    "& fieldset": { border: "none" },
                  }}
                />
              </div>
              <div className="operation-cargo-dom-resp-weight-iyziteam-item">
                <div className="little-title">Kilogram</div>
                <TextField
                  size="small"
                  name="gross"
                  value={dim.gross}
                  required
                  onChange={(e) => handleInputChange(e, index)}
                  sx={{
                    backgroundColor: "#e9ecef",
                    width: "50px",
                    height: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0",
                    "& fieldset": { border: "none" },
                  }}
                />
              </div>
              <div className="operation-cargo-dom-resp-weight-iyziteam-item">
                <div className="little-title">Brüt Ağırlık :
                </div>
                <div
                  style={{
                    backgroundColor: "#e9ecef",
                    width: "50px",
                    height: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0",
                  }}
                >
                  {Number(dim.gross * dim.qty).toFixed(2)} KG
                </div>
              </div>
              <div className="operation-cargo-dom-resp-weight-iyziteam-item">
                <div className="little-title">Hacim / Desi</div>
                <div
                  style={{
                    backgroundColor: "#e9ecef",
                    width: "50px",
                    height: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0",
                  }}
                >
                  {Number(
                    ((dim.width * dim.length * dim.height) / 5000) * dim.qty
                  ).toFixed(2)}
                  KG
                </div>
              </div>
              <div>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit">Kaydet</Button>
              </div>
            </form>
          ))}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "#6a707e",
              gap: "5px",
            }}
            className="operation-cargo-dom-resp-wight-shipment-bottom-side"
          >
            <div className="little-title">Toplam Ücret Ağırlığı</div>
            <div
              style={{
                backgroundColor: "#e9ecef",
                width: "124px",
                height: "20px",
                fontSize: "10px",
                fontWeight: 700,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="operation-cargo-dom-resp-wight-shipment-item-bottom"
            >
              {totalCw} kg
            </div>
          </div>
        </div>
      </Modal>
    </ThemeProvider>
  );
};

export default WeightCheckModal;
