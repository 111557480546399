import { CircularProgress, createTheme } from "@mui/material";
import { useRecoilValue } from "recoil";
import { useCookies } from "react-cookie";
import { useInView, animated } from "@react-spring/web";
import { ThemeProvider } from "@emotion/react";
import { drawerOpenState } from "../../../atoms/viewPadding";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import axios from "../../../api/axios";
import NotificationBar from "../sidebar/NotificationBar";
import NavBar from "../sidebar/NavBar";
import WrapperStyle from "../../../utils/WrapperStyle";
import SupportInfos from "./supportReports/SupportInfos";
import SupportChart from "./supportReports/SupportChart";
import { useEffect, useState } from "react";
import Errors from "../../../utils/Errors";
import SupportSubjectChart from "./supportReports/SupportSubjectChart";
import SupportLateCloserChart from "./supportReports/SupportLateCloserChart";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const SupportReports = () => {
  DynamicTitle("Destek - Raporlar");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const drawerOpen = useRecoilValue(drawerOpenState);

  const [loading, setLoading] = useState(true);
  const [supportFiles, setSupportFiles] = useState();
  const [supportSubjects, setSupportSubjects] = useState();
  const [openedFiles, setOpenedFiles] = useState();
  const [fileCloserAverageDate, setFileCloserAverageDate] = useState();
  const [lateFileCloserSubjects, setLateFileCloserSubjects] = useState();

  const navUnder = {
    paddingLeft: drawerOpen ? "264px" : 0,
    transition: "all 150ms linear",
    backgroundColor: "var(--lighterBg)",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  };

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const getData = () => {
    axios
      .get(`report/ticket`)
      .then((response) => {
        setSupportFiles(response.data?.data_tickets);
        setSupportSubjects(response.data?.data_ticket_subjects);
        setOpenedFiles(response.data?.data_open_tickets);
        setFileCloserAverageDate(response.data?.data_average_ticket_closure_time_in_days);
        setLateFileCloserSubjects(response.data?.data_ticket_unclosed_subjects);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <NotificationBar />
      <NavBar />
      <section className="my-wallet">
        <div className="nav-under" style={navUnder}>
          <div>Destek</div>
        </div>
        <div className="my-wallet-wrapper" style={WrapperStyle()}>
          <animated.div ref={ref} style={springs}>
            <div className="my-wallet-container">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "column", gap: "var(--gap2x)" }}>
                  <SupportChart data={supportFiles} />
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                      gap: "var(--gap2x)",
                      marginBottom: "var(--gap2x)",
                    }}
                  >
                    <SupportSubjectChart data={supportSubjects} />
                    <SupportInfos openedFile={openedFiles} lateCloser={fileCloserAverageDate} />
                    <SupportLateCloserChart data={lateFileCloserSubjects} />
                  </div>
                </div>
              )}
            </div>
          </animated.div>
        </div>
      </section>
    </ThemeProvider>
  );
};

export default SupportReports;
