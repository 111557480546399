import { atom } from 'recoil';

export const notificationOpenState = atom({
  key: 'notificationOpen',
  default: false,
});

export const drawerOpenState = atom({
  key: 'drawerOpen',
  default: true,
});