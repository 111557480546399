import { TableRow, TableCell, Button } from "@mui/material";
import { useCookies } from "react-cookie";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import MessageTime from "../../../../utils/MessageTime";
import Errors from "../../../../utils/Errors";

const FinanceGonderiIadeTableBody = ({ item }) => {
  const [token] = useCookies(["jwt"]);
  const jwt = token?.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  /* const handleApproveAccept = async () => {
    await axios
        .post(`parcel-return/demand/approve/${item.id}`,
            {action_type: 'accept'})
        .then((response) => {
            toast.success("İşlem Başarılı", { autoClose: 500 });
            setTimeout(() => {
                window.location.reload(true);
            }, 1000);
        })
        .catch((error) => {
            toast.error("İşlem Başarısız", { autoClose: 500 });
        });
  };*/

  const handleApproveDecline = async () => {
    await axios
      .post(`parcel-return/demand/approve/${item.id}`, {
        action_type: "decline",
      })
      .then(() => {
        toast.success("İşlem Başarılı");
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      })
      .catch((error) => Errors(error));
  };

  return (
    <TableRow
      sx={{
        ":hover": {
          backgroundColor: "var(--pastelBlue)",
          transition: "all 150ms linear",
        },
      }}
    >
      <TableCell>{MessageTime(item.created_at)}</TableCell>
      <TableCell>{item.get_parcel.parcel_ref_no}</TableCell>
      <TableCell>{item.member_id}</TableCell>
      <TableCell>
        {item.get_created_by.name} {item.get_created_by.surname}
      </TableCell>
      <TableCell>{item.return_reason}</TableCell>
      {/*<TableCell>{item.return_price}</TableCell>*/}
      <TableCell>
        <div className={`status status${item.approved}`}>
          {Number(item.approved) === 0
            ? "Beklemede"
            : Number(item.approved) === 1
            ? "Onaylandı"
            : Number(item.approved) === 2
            ? "Reddedildi"
            : "İptal Edildi"}
        </div>
      </TableCell>
      <TableCell>
        {Number(item.approved) === 0 && (
          <div
            style={{
              display: Number(item.approved) === 0 ? "flex" : "none",
              gap: "var(--gap)",
            }}
          >
            {/*<Button className="iyzi-button-green" onClick={handleApproveAccept}>Onayla</Button>*/}
            <Button className="iyzi-button-red" onClick={handleApproveDecline}>
              Reddet
            </Button>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};

export default FinanceGonderiIadeTableBody;
