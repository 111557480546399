const OperationDimensionMember = ({ item }) => {
  return (
    <div className="operation-cargo-dom-resp-wight-shipment-group">
      <div className="operation-cargo-dom-resp-wight-shipment-group-top">
        <div className="operation-cargo-dom-resp-wight-shipment-item">
          <div className="little-title">Genişlik</div>
          <div className="operation-cargo-dom-resp-wight-shipment-item-bottom">
            {item.width}
          </div>
        </div>
        <div className="operation-cargo-dom-resp-wight-shipment-item">
          <div className="little-title">Uzunluk</div>
          <div className="operation-cargo-dom-resp-wight-shipment-item-bottom">
            {item.length}
          </div>
        </div>
        <div className="operation-cargo-dom-resp-wight-shipment-item">
          <div className="little-title">Yükseklik</div>
          <div className="operation-cargo-dom-resp-wight-shipment-item-bottom">
            {item.height}
          </div>
        </div>
        <div className="operation-cargo-dom-resp-wight-shipment-item">
          <div className="little-title">Kap</div>
          <div className="operation-cargo-dom-resp-wight-shipment-item-bottom">
            {item.qty}
          </div>
        </div>
        <div className="operation-cargo-dom-resp-wight-shipment-item">
          <div className="little-title">Kilogram</div>
          <div className="operation-cargo-dom-resp-wight-shipment-item-bottom">
            {item.gross}
          </div>
        </div>
        <div className="operation-cargo-dom-resp-wight-shipment-item">
          <div className="little-title">Brüt Ağırlık</div>
          <div className="operation-cargo-dom-resp-wight-shipment-item-bottom">
            {item.gw}
          </div>
        </div>
        <div className="operation-cargo-dom-resp-wight-shipment-item">
          <div className="little-title">Hacim/Desi</div>
          <div className="operation-cargo-dom-resp-wight-shipment-item-bottom">
            {item.vw}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperationDimensionMember;
