import { CircularProgress, createTheme } from "@mui/material";
import { useRecoilValue } from "recoil";
import { useCookies } from "react-cookie";
import { useInView, animated } from "@react-spring/web";
import { ThemeProvider } from "@emotion/react";
import { drawerOpenState } from "../../../atoms/viewPadding";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import axios from "../../../api/axios";
import NotificationBar from "../sidebar/NotificationBar";
import NavBar from "../sidebar/NavBar";
import WrapperStyle from "../../../utils/WrapperStyle";
import Errors from "../../../utils/Errors";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ShipmentChart from "./chartReports/ShipmentChart";
import AverageWeightChart from "./chartReports/AverageWeightChart";
import ChartOptions from "./chartReports/ChartOptions";
import SalesTurnoverChart from "./budgetReports/SalesTurnoverChart";
import GrossSalesProfitChart from "./chartReports/GrossSalesProfitChart";
import ActivePassiveMemberCountChart from "./chartReports/ActivePassiveMemberCountChart";
import CostChart from "./chartReports/CostChart";
import SalesExchangeRateChart from "./budgetReports/SalesExchangeRateChart";
import DomesticCostChart from "./chartReports/DomesticCostChart";
import DomesticCountChart from "./chartReports/DomesticCountChart";
import BookingChart from "./chartReports/BookingChart";
import UncollectedBalanceChart from "./chartReports/UncollectedBalanceChart";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const ChartReports = () => {
  DynamicTitle("Göstergeler  - Raporlar");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const drawerOpen = useRecoilValue(drawerOpenState);

  const navUnder = {
    paddingLeft: drawerOpen ? "264px" : 0,
    transition: "all 150ms linear",
    backgroundColor: "var(--lighterBg)",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  };

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const [loading, setLoading] = useState(true);
  const [shipments, setShipments] = useState({});
  const [averageWeight, setAverageWeight] = useState({});
  const [salesTurnover, setSalesTurnover] = useState({});
  const [grossSalesProfit, setGrossSalesProfit] = useState({});
  const [activePassiveMemberCount, setActivePassiveMemberCount] = useState({});
  const [cost, setCost] = useState({});
  const [salesExchangeRate, setSalesExchangeRate] = useState({});
  const [domesticCost, setDomesticCost] = useState({});
  const [domesticCount, setDomesticCount] = useState({});
  const [booking, setBooking] = useState({});
  const [uncollectedBalances, setUncollectedBalances] = useState({});
  const [currency, setCurrency] = useState(false);
  const [compareDesign, setCompareDesign] = useState(false);

  const getData = () => {
    axios
      .get(`report/indicator`)
      .then((response) => {
        setShipments(response.data?.data_parcels);
        setAverageWeight(response.data?.data_average_cw);
        setSalesTurnover(response.data?.data_sales_turnover);
        setGrossSalesProfit(response.data?.data_gross_sales_profit);
        setActivePassiveMemberCount(response.data?.data_active_passive_member_count);
        setCost(response.data?.data_cost);
        setSalesExchangeRate(response.data?.data_sales_exchange_rate);
        setDomesticCost(response.data?.data_domestic_cost);
        setDomesticCount(response.data?.data_domestic_count);
        setBooking(response.data?.data_booking);
        setUncollectedBalances(response.data?.data_uncollected_balances);

        toast.success(response.data.message);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <NotificationBar />
      <NavBar />
      <section className="my-wallet">
        <div className="nav-under" style={navUnder}>
          <div>Göstergeler</div>
        </div>
        <div className="my-wallet-wrapper" style={WrapperStyle()}>
          <animated.div ref={ref} style={springs}>
            <div className="my-wallet-container">
              {loading ? (
                <CircularProgress />
              ) : (
                <div style={{ display: "flex", flexDirection: "column", gap: "var(--gap)" }}>
                  <ChartOptions
                    setCurrency={setCurrency}
                    setShipments={setShipments}
                    setAverageWeight={setAverageWeight}
                    setSalesTurnover={setSalesTurnover}
                    setGrossSalesProfit={setGrossSalesProfit}
                    setActivePassiveMemberCount={setActivePassiveMemberCount}
                    setCost={setCost}
                    setSalesExchangeRate={setSalesExchangeRate}
                    setDomesticCost={setDomesticCost}
                    setDomesticCount={setDomesticCount}
                    setBooking={setBooking}
                    setUncollectedBalances={setUncollectedBalances}
                    setCompareDesign={setCompareDesign}
                  />
                  <div style={{ display: "flex", flexDirection: "column", gap: "var(--gap)" }}>
                    <ShipmentChart data={shipments} compareDesign={compareDesign} />
                    <AverageWeightChart data={averageWeight} compareDesign={compareDesign} />
                    <SalesTurnoverChart data={salesTurnover} currency={currency} compareDesign={compareDesign} />
                    <GrossSalesProfitChart data={grossSalesProfit} currency={currency} compareDesign={compareDesign} />
                    <ActivePassiveMemberCountChart data={activePassiveMemberCount} compareDesign={compareDesign} />
                    <CostChart data={cost} currency={currency} compareDesign={compareDesign} />
                    <SalesExchangeRateChart data={salesExchangeRate} currency={currency} compareDesign={compareDesign} />
                    <DomesticCostChart data={domesticCost} currency={currency} compareDesign={compareDesign} />
                    <DomesticCountChart data={domesticCount} compareDesign={compareDesign} />
                    <BookingChart data={booking} compareDesign={compareDesign} />
                    <UncollectedBalanceChart data={uncollectedBalances} currency={currency} compareDesign={compareDesign} />
                  </div>
                </div>
              )}
            </div>
          </animated.div>
        </div>
      </section>
    </ThemeProvider>
  );
};

export default ChartReports;
