import "../../../../style/globals/Globals.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import MessageTime from "../../../../utils/MessageTime";
import { useCallback, useState } from "react";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import {
  financeHavaleCurrentPageState,
  financeHavaleDataState,
  financeHavaleFilesModalState,
  financeHavaleTotalPageState,
} from "../../../../atoms/finance";
import LocalPrice from "../../../../utils/LocalPrice";
import { useRecoilState } from "recoil";
import Errors from "../../../../utils/Errors";

const FinanceHavaleTableBody = ({ item }) => {
  const [financeHavaleData, setFinanceHavaleData] = useRecoilState(financeHavaleDataState);
  const [, setFinanceHavaleFilesModal] = useRecoilState(financeHavaleFilesModalState);
  const [, setFinanceHavaleTotalPage] = useRecoilState(financeHavaleTotalPageState);
  const [, setFinanceHavaleCurrentPage] = useRecoilState(financeHavaleCurrentPageState);

  const [dialog, setDialog] = useState(false);
  const [textTitle, setTextTitle] = useState("");
  const [textContent, setTextContent] = useState("");
  const [textStatus, setTextStatus] = useState(null);
  const [, setStatus] = useState(item.status);
  const [rejectReason, setRejectReason] = useState("");
  const [, setApprovalAmount] = useState("");

  const handleApprove = (e) => {
    e.preventDefault();
    setDialog(false);

    const requestBody = {
      action_type: "accept",
    };

    axios
      .post(`money-order/approve-decline/${item.id}`, requestBody)
      .then((response) => {
        toast.success(response.data.message);

        // Update the financeHavaleData array with the response data
        const updatedData = financeHavaleData?.map((e) => {
          if (e === item) {
            return response.data.data;
          } else {
            return e;
          }
        });

        setFinanceHavaleData(updatedData);
        setFinanceHavaleTotalPage(response.data.totalPage);
        setFinanceHavaleCurrentPage(response.data.currentPage);
      })
      .catch((error) => Errors(error));
  };

  const handleReject = (e) => {
    e.preventDefault();
    setDialog(false);

    const requestBody = {
      action_type: "decline",
      response_description: rejectReason,
    };

    axios
      .post(`money-order/approve-decline/${item.id}`, requestBody)
      .then((response) => {
        const updatedData = financeHavaleData?.map((e) => {
          if (e === item) {
            return response.data.data;
          } else {
            return e;
          }
        });

        setFinanceHavaleData(updatedData);
        setFinanceHavaleTotalPage(response.data.totalPage);
        setFinanceHavaleCurrentPage(response.data.currentPage);
        toast.success(response.data.message);
        updateStatus(1);
      })
      .catch((error) => {
        Errors(error);
        if (error.response && error.response.status === 401) {
          handleReject(e);
        } else {
          setFinanceHavaleData([item, ...financeHavaleData]);
        }
      });
  };

  const handleDialogOpen = (e) => {
    setTextStatus(e);

    if (e === 1) {
      setTextTitle("Onaylama İsteği");
      setTextContent("HAVALE/EFT ile cüzdana para giriş talebini onaylamak istediğinizden emin misiniz ?");
      setDialog(true);
    } else {
      setTextTitle("Reddetme İsteği");
      setTextContent("HAVALE/EFT ile cüzdana para giriş talebini reddetmek istediğinizden emin misiniz ?");
      setDialog(true);
    }
  };

  const handleDialogClose = () => {
    setDialog(false);
    setApprovalAmount("");
  };

  const handleChangeRejectReason = (e) => {
    setRejectReason(e.target.value);
  };

  const handleApprovalAmount = (e) => {
    const value = e.target.value.replace(",", ".");

    if (value === "" || /^\d*([.]\d{0,2})?$/.test(value)) {
      setApprovalAmount(value);
    }
  };

  const handleBlurApprovalAmount = (e) => {
    const value = e.target.value.replace(",", ".");

    if (Number(value) > Number(item.request_refund_amount)) {
      setApprovalAmount(Number(item.request_refund_amount).toFixed(2));
    } else setApprovalAmount(value);
  };

  const handleFileModalOpen = useCallback(() => {
    setFinanceHavaleFilesModal(true);
    // setSelectedHavaleFiles(item?.get_files);
  }, [setFinanceHavaleFilesModal]);

  const updateStatus = (newStatus) => {
    setStatus(newStatus);
  };

  return (
    <>
      <Dialog
        open={dialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "var(--wrapperBorderRadius)" }}
      >
        <DialogTitle id="alert-dialog-title">{textTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{textContent}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "8px 20px 20px" }}>
          <form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "var(--gap)",
            }}
            onSubmit={textStatus === 1 ? handleApprove : handleReject}
          >
            {textStatus === 2 && (
              <TextField
                label="Reddetme Nedeni"
                value={rejectReason}
                onChange={handleChangeRejectReason}
                fullWidth
                required
                size="small"
                autoComplete="off"
              />
            )}
            <div
              style={{
                display: "flex",
                gap: "var(--gap2x)",
                justifyContent: "flex-end",
              }}
            >
              <Button type="button" className="iyzi-button" onClick={handleDialogClose}>
                Hayır
              </Button>
              <Button className="iyzi-button" type="submit">
                Evet
              </Button>
            </div>
          </form>
        </DialogActions>
      </Dialog>
      <TableRow
        sx={{
          ":hover": {
            backgroundColor: "var(--pastelBlue)",
            transition: "all 150ms linear",
          },
        }}
      >
        <TableCell>
          {item.ref_no}
          {item.response_description === "Onaylandı" ? null : (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <strong style={{ fontSize: "12px", color: "var(--shipmentRed)" }}>
                {item.response_description === "" ? "" : "İptal Nedeni: "}
              </strong>{" "}
              <p style={{ fontSize: "12px" }}>
                {item.response_description === "Onaylandı" ? "" : item.response_description}
              </p>
            </div>
          )}
        </TableCell>
        <TableCell>{item.member_id}</TableCell>
        <TableCell>{item.sender_name}</TableCell>
        <TableCell>{MessageTime(item.created_at)}</TableCell>
        <TableCell>{LocalPrice(item.amount)} ₺</TableCell>
        <TableCell>{item.iban}</TableCell>

        <TableCell>
          <div className={`status status${item.status}`}>
            {Number(item.status) === 0
              ? "Beklemede"
              : Number(item.status) === 1
              ? "Onaylandı"
              : Number(item.status) === 2
              ? "Reddedildi"
              : "İptal Edildi"}
          </div>
        </TableCell>
        <TableCell>
          {Number(item.status) === 0 && (
            <div
              style={{
                display: Number(item.status) === 0 ? "flex" : "none",
                gap: "var(--gap)",
              }}
            >
              <Button onClick={() => handleDialogOpen(1)} className="iyzi-button-green">
                Onayla
              </Button>
              <Button onClick={() => handleDialogOpen(2)} className="iyzi-button-red">
                Reddet
              </Button>
            </div>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default FinanceHavaleTableBody;
