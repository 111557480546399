import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import {
  updateHAWBModalOpenState,
  selectedShipmentForMyShipmentsState,
} from "../../../../atoms/myShipments";
import { useRecoilState } from "recoil";
import Errors from "../../../../utils/Errors";

const UpdateHAWBModal = () => {
  const [selectedShipmentForMyShipments, setSelectedShipmentForMyShipments] = useRecoilState(
    selectedShipmentForMyShipmentsState
  );
  const [updateHAWBModalOpen, setUpdateHAWBModalOpen] = useRecoilState(updateHAWBModalOpenState);
  const handleClose = () => {
    setUpdateHAWBModalOpen(false);
    setSelectedShipmentForMyShipments([]);
    setTrackingAWB("");
  };
  const [trackingAWB, setTrackingAWB] = useState("");
  // const [trackingAWBDate, setTrackingAWBDate] = useState("");
  // const [supplierID, setSupplierID] = useState("");

  const handleSubmit = () => {
    const requestBody = {
      tracking_awb: trackingAWB,
      // tracking_awb_date: trackingAWBDate,
      // supplier_id: supplierID,
    };

    axios
      .post(`parcel/update-tracking-awb/${selectedShipmentForMyShipments.id}`, requestBody)
      .then((response) => toast.success(response.data.message))
      .catch((error) => Errors(error))
      .finally(() => {
        handleClose();
      });
  };

  return (
    <Dialog open={updateHAWBModalOpen} onClose={handleClose}>
      <DialogTitle>İzleme Numarası Manuel Güncelle</DialogTitle>
      <DialogContent>
        <Grid
          sx={{
            marginTop: 1,
          }}
          container
          spacing={2}
        >
          <Grid item xs={12}>
            <TextField
              size="small"
              label="İzleme Numarası"
              value={trackingAWB}
              onChange={(e) => setTrackingAWB(e.target.value)}
              fullWidth
              autoComplete="off"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Vazgeç</Button>
        <Button type="submit" onClick={handleSubmit} variant="contained" color="primary">
          Kaydet
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateHAWBModal;
