import "../../style/announcement/Announcement.css";
import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { drawerOpenState, notificationOpenState } from "../../atoms/viewPadding";
import { DynamicTitle } from "../../utils/DynamicTitle";
import NavBar from "../components/sidebar/NavBar";
import NotificationBar from "../components/sidebar/NotificationBar";
import { useInView, animated } from "@react-spring/web";
import { ToastContainer } from "react-toastify";
import miniLogo from "../../img/logos/logo_white_mini_loading.svg";
import AnnouncementList from "../components/announcement/AnnouncementList";
import axios from "../../api/axios";
import {
  announcementListCountState,
  announcementListCurrentPageState,
  notificationListState,
} from "../../atoms/announcement";
import { useCookies } from "react-cookie";
import Errors from "../../utils/Errors";
import WrapperStyle from "../../utils/WrapperStyle";

const AnnouncementAdmin = () => {
  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  DynamicTitle("Duyurular - Süper Admin");

  const [loading, setLoading] = useState(false);

  const [, setNotificationOpen] = useRecoilState(notificationOpenState);
  const [, setNotificationList] = useRecoilState(notificationListState);
  const [, setAnnouncementListCount] = useRecoilState(announcementListCountState);
  const [, setAnnouncementListCurrentPage] = useRecoilState(announcementListCurrentPageState);

  const drawerOpen = useRecoilValue(drawerOpenState);

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const getAnnouncementData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`notification?limit=25&page=1`)
      .then((response) => {
        setNotificationList(response.data.data);
        setAnnouncementListCurrentPage(response.data.currentPage);
        setAnnouncementListCount(response.data.totalPages);
      })
      .catch((error) => {
        Errors(error);
      })
      .finally(() => setLoading(false));
  }, [setNotificationList, setAnnouncementListCurrentPage, setAnnouncementListCount]);

  useEffect(() => {
    setNotificationOpen(false);
    getAnnouncementData();
  }, [setNotificationOpen, getAnnouncementData]);

  return (
    <>
      <NotificationBar />
      <section className="announcement" style={WrapperStyle()}>
        <NavBar />

        <ToastContainer />
        <Box
          className="announcement-wrapper"
          sx={{
            paddingLeft: drawerOpen ? 0 : "64px",
            transition: "all 150ms linear",
            paddingTop: "20px",
          }}
        >
          {loading ? (
            <Box
              sx={{
                height: "500px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img className="loading-logo" src={miniLogo} alt="" />
            </Box>
          ) : (
            <animated.div ref={ref} style={springs}>
              <Box className="announcement-container">
                <AnnouncementList />
              </Box>
            </animated.div>
          )}
        </Box>
      </section>
    </>
  );
};

export default AnnouncementAdmin;
