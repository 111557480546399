import { atom } from "recoil";

export const logTotalPageState = atom({
  key: "logTotalPage",
  default: 0,
});

export const logCurrentPageState = atom({
  key: "logCurrentPage",
  default: 1,
});

export const logDataState = atom({
  key: "logDataState",
  default: [],
});
