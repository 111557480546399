import { atom } from "recoil";

export const walletLoadingState = atom({
  key: "walletLoading",
  default: false,
});

export const creditCardState = atom({
  key: "creditCard",
  default: "",
});

export const totalBalanceState = atom({
  key: "totalBalance",
  default: "0",
});

export const addMoneyToBalanceOpenState = atom({
  key: "addMoneyToBalanceOpen",
  default: false,
});

export const amountState = atom({
  key: "amount",
  default: 0,
});

export const amountNoteState = atom({
  key: "amountNote",
  default: "",
});

export const paymentRefNumberState = atom({
  key: "paymentRefNumber",
  default: "",
});

export const myBillsTotalPageState = atom({
  key: "myBillsTotalPage",
  default: 0,
});

export const myBillsCurrentPageState = atom({
  key: "myBillsCurrentPage",
  default: 1,
});

export const extractTotalPageState = atom({
  key: "extractTotalPage",
  default: 0,
});

export const extractCurrentPageState = atom({
  key: "extractCurrentPage",
  default: 1,
});

export const extractDataState = atom({
  key: "extractData",
  default: [],
});

export const balanceTotalPageState = atom({
  key: "balanceTotalPage",
  default: 0,
});

export const balanceCurrentPageState = atom({
  key: "balanceCurrentPage",
  default: 1,
});

export const walletPaymentStatusState = atom({
  key: "walletPaymentStatus",
  default: 0,
});

export const isCardsExistState = atom({
  key: "isCardsExist",
  default: false,
});

export const isCardsExist2State = atom({
  key: "isCardsExist2",
  default: false,
});

export const registeredDefaultNotEmptyState = atom({
  key: "registeredDefaultNotEmpty",
  default: false,
});

export const myWalletBillModalState = atom({
  key: "myWalletBillModal",
  default: false,
});

export const selectedMyWalletBillState = atom({
  key: "selectedMyWalletBill",
  default: {},
});

export const myWalletDocModalState = atom({
  key: "myWalletDocModal",
  default: false,
});

export const myRefundEditModalState = atom({
  key: "myRefundEditModal",
  default: false,
});

export const selectedRefundItemState = atom({
  key: "selectedRefundItem",
  default: {},
});

export const myRefundDocModalState = atom({
  key: "myRefundDocModal",
  default: false,
});

export const iyziBalanceDataState = atom({
  key: "iyziBalanceData",
  default: [],
});

export const myBillsDataState = atom({
  key: "myBillsData",
  default: [],
});

export const billsKonsimentoModalOpenState = atom({
  key: "billsKonsimentoModalOpen",
  default: false,
});

export const billsKonsimentoDataState = atom({
  key: "billsKonsimentoData",
  default: [],
});

export const billsFileModalOpenState = atom({
  key: "billsFileModalOpen",
  default: false,
});

export const billsFileDataState = atom({
  key: "billsFileData",
  default: "",
});

export const extractDetailDataState = atom({
  key: "extractDetailData",
  default: [],
});

export const extractDetailModalOpenState = atom({
  key: "extractDetailModalOpen",
  default: false,
});

export const billsKonsimentoIdState = atom({
  key: "billsKonsimentoId",
  default: 0,
});

export const refundableAmountState = atom({
  key: "refundableAmount",
  default: 0,
});

export const nonRefundableAmountState = atom({
  key: "nonRefundableAmount",
  default: 0,
});

export const walletRefundDataState = atom({
  key: "walletRefundData",
  default: [],
});

export const walletRefundTotalPageState = atom({
  key: "walletRefundTotalPage",
  default: 0,
});

export const walletRefundCurrentPageState = atom({
  key: "walletRefundCurrentPage",
  default: 1,
});

export const arrangementCompanyBillModalOpenState = atom({
  key: "arrangementCompanyBillModalOpen",
  default: false,
});

export const selectedArrangementItemState = atom({
  key: "selectedArrangementItem",
  default: {},
});

export const walletArrangementDataState = atom({
  key: "walletArrangementData",
  default: [],
});

export const walletArrangementCurrentPageState = atom({
  key: "walletArrangementCurrentPage",
  default: 1,
});

export const walletArrangementTotalPageState = atom({
  key: "walletArrangementTotalPage",
  default: 0,
});

export const financeBakiyeIadeDataState = atom({
  key: 'financeBakiyeIadeDataState',
  default: [],
});
