import "../../../style/operation/Operation.css";
import {
  Box,
  ThemeProvider,
  createTheme,
  Button,
  TextField,
  Alert,
  Tooltip,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { useCookies } from "react-cookie";
import axios from "../../../api/axios";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import NavBar from "../sidebar/NavBar";
import { ToastContainer } from "react-toastify";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import FlightTakeoffOutlinedIcon from "@mui/icons-material/FlightTakeoffOutlined";
import FlightLandOutlinedIcon from "@mui/icons-material/FlightLandOutlined";
import WrapperStyle from "../../../utils/WrapperStyle";
import WarehouseOutlinedIcon from "../../../img/images/la_warehouse.svg";
import countriesDetailed from "../../../json/countriesDetailed.json";
import questionIcon from "../../../img/images/ph_question-bold.svg";
import proformaLogo from "../../../img/images/mdi_map-outline.svg";
import OperationDimension from "./operationCargoEntranceWarehouse/OperationDimension";
import { CircleFlag } from "react-circle-flags";
import { useRecoilState, useRecoilValue } from "recoil";
import { ffdDataState } from "../../../atoms/operation";
import { drawerOpenState } from "../../../atoms/viewPadding";
import NotificationBar from "../sidebar/NotificationBar";
import Errors from "../../../utils/Errors";
// Barcode scan
// import useScanDetection from "use-scan-detection";
import { toast } from "react-toastify";

import {
  myShipmentListLoadingState,
  myShipmentListState,
  myShipmentsListLimitState,
  shipmentListCurrentPageState,
  shipmentListOrderDirectionState,
  shipmentsListArrayLengthState,
  totalPagesState,
} from "../../../atoms/myShipments";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const BeevisionPassingLoadScreen = () => {
  DynamicTitle("Depo Yük Girişi - Admin");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
  const [ioss, setIoss] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [currentStatusValue, setCurrentStatusValue] = useState("");

  // Shipment list

  const [myShipmentListLoading, setMyShipmentListLoading] = useRecoilState(
    myShipmentListLoadingState
  );
  const shipmentOrderDirectionPage = useRecoilValue(
    shipmentListOrderDirectionState
  );
  const [myShipmentsListLimit, setMyShipmentsListLimit] = useRecoilState(
    myShipmentsListLimitState
  );
  const [shipmentListCurrentPage, setShipmentListCurrentPage] = useRecoilState(
    shipmentListCurrentPageState
  );
  const [myShipmentList, setMyShipmentList] = useState([]);
  const [totalPages, setTotalPages] = useRecoilState(totalPagesState);
  const [, setShipmentsListArrayLength] = useRecoilState(
    shipmentsListArrayLengthState
  );
  // Main panel

  const [domAwb, setDomAwb] = useState("");
  const [domSupplier, setDomSupplier] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open4, setOpen4] = useState(false);

  // Ölçü inputları

  const [loading, setLoading] = useState(false);

  const [ffdData, setFfdData] = useRecoilState(ffdDataState);
  const [drawerOpen, setDrawerOpen] = useRecoilState(drawerOpenState);

  const [barcodeScan, setBarcodeScan] = useState("");
  const [manuelScan, setManuelScan] = useState("");
  const [getTrackingFromGunText, setGetTrackingFromGunText] = useState("");
  const [checkSettings, setCheckSettings] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  // useScanDetection({
  //   onComplete: setBarcodeScan,
  //   minLength: 3,
  // });

  // console.log(barcodeScan);

  const getBeevisionList = async () => {
    try {
      const response = await axios.get("beevision/list", {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      setCurrentStatus(response.data.data[0].mode_description);
      setCurrentStatusValue(response.data.data[0].current_mode);
    } catch (error) {
      Errors(error);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "2.16":
        return "green";
      case "1.02":
      case "0.05":
      case "0.06":
        return "red";
      case "2.03":
        return "orange";
      case "0.00":
        return "blue";
      case "1.00":
      case "1.01":
        return "orange";
      default:
        return "red";
    }
  };

  const shipmentStatus = myShipmentList[0]?.status;

  const getTrackingFromGun = useCallback(async () => {
    try {
      if (barcodeScan.trim() !== "") {
        const response = await axios.post(`beevision/new-tracking-from-gun`, {
          parcel_ref_no: manuelScan,
        });
        setGetTrackingFromGunText(response.message);
      } else {
        console.log("BarcodeScan is empty. No request made.");
      }
    } catch (error) {
      Errors(error);
    } finally {
      setMyShipmentListLoading(false);
    }
  }, [manuelScan]);

  const getShipment = async () => {
    if (manuelScan.trim() === "") {
      return;
    } else if (manuelScan.length >= 12) {
      setMyShipmentListLoading(true);
      setCheckSettings(true);

      try {
        const response = await axios.get(
          `parcel?order_direction=${shipmentOrderDirectionPage}&limit=100000000&page=${shipmentListCurrentPage}&unlimited=1&parcel_ref_no=${manuelScan}&order_by=updated_at&order_direction=DESC&detailed=1`
        );

        const filteredData = response.data.data.filter(
          (item) => item.parcel_ref_no === manuelScan
        );

        setMyShipmentList(filteredData);
      } catch (error) {
        Errors(error);
      } finally {
        setMyShipmentListLoading(false);
        setCheckSettings(false);
        setManuelScan("");
        getTrackingFromGun();
      }
    }
  };

  useEffect(() => {
    getBeevisionList();
    const intervalId = setInterval(() => {
      getBeevisionList();
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getShipment();
  }, [manuelScan]);

  useEffect(() => {
    setDrawerOpen(false);
  }, []);

  const handleManuelScanChange = (event) => {
    setManuelScan(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <NotificationBar />
      <section
        className="operation"
        style={{
          // paddingLeft: drawerOpen ? 0 : "64px",
          transition: "all 150ms linear",
        }}
      >
        <NavBar />
        <ToastContainer />
        {loading && (
          <Box
            sx={{
              height: "500px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="loading-logo" src={miniLogo} alt="" />
          </Box>
        )}
        {!loading && (
          <div
            style={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                height: "90px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "10px",
                backgroundColor: "#ffff",
                marginBottom: "10px",
                paddingLeft: drawerOpen ? "285px" : "20px",
                transition: "all 150ms linear",
              }}
            >
              <img
                style={{ width: "40px", height: "40px" }}
                src={WarehouseOutlinedIcon}
                alt="Icon"
              />
              <h6
                style={{
                  color: "var(--priceText)",
                }}
              >
                Depo Mal Kabul
              </h6>
              <div
                style={{
                  width: "193px",
                  Height: "32px",
                  border: " 1px solid #4285F4",
                  padding: "5px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: " center",
                  alignItems: "center",
                  color: " rgb(66, 133, 244)",
                  marginLeft: "10px",
                  gap: "5px",
                  cursor: "pointer",
                }}
              >
                <img src={questionIcon} alt="question icon" />
                Bu Sayfa Nasıl Kullanılır
              </div>
            </div>

            {currentStatusValue === "3.00" ? (
              <Box className="operation-wrapper" style={WrapperStyle()}>
                <Box className="operation-container">
                  <div
                    className="operation-freight-front-desk"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "100%",
                    }}
                  >
                    <p>
                      Şu anki Beevision ayarı <b>{currentStatus}</b>
                    </p>
                  </div>
                </Box>

                <Box className="operation-container">
                  <div
                    className="operation-freight-front-desk"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "100%",
                    }}
                  >
                    <Box>
                      <label>Domestik AWB No</label>
                      <TextField
                        value={myShipmentList[0]?.domestic_awb}
                        autoComplete="on"
                        size="small"
                        label=""
                        sx={{ width: "100%" }}
                        disabled
                      />
                    </Box>
                    <Box>
                      <label>Domestik Tedarikçi</label>
                      <TextField
                        value={myShipmentList[0]?.domestic_supplier}
                        autoComplete="on"
                        size="small"
                        label=""
                        sx={{ width: "100%" }}
                        disabled
                      />
                    </Box>
                    <Box>
                      <label>Parcel No</label>
                      <TextField
                        value={myShipmentList[0]?.parcel_ref_no}
                        autoComplete="on"
                        size="small"
                        label=""
                        sx={{ width: "100%" }}
                        disabled
                      />
                    </Box>
                  </div>
                  <div
                    className="operation-freight-front-desk"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      <label>Barkod Parcel No</label>
                      <TextField
                        value={manuelScan}
                        size="small"
                        label=""
                        sx={{ width: "100%" }}
                        onChange={handleManuelScanChange}
                        autofocus
                      />
                    </Box>
                  </div>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    backgroundColor: "var(--lighterBg)",
                    borderRadius: "10px",
                    gap: "10px",
                    padding: "15px 50px",
                  }}
                >
                  <Box>
                    <div className="operation-cargo-dom-resp-weight">
                      <div className="operation-cargo-dom-resp-weight-iyziteam">
                        <div className="title">
                          İyziTeam Tarafından Beyan Edilen Kilo
                        </div>
                        <OperationDimension
                          disable={true}
                          items={myShipmentList[0]?.get_parcel_dimensions}
                          i={myShipmentList[0]?.get_parcel_dimensions?.length}
                          isBeevision={true}
                        />
                      </div>
                    </div>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box className="operation-wrapper" style={WrapperStyle()}>
                <Box className="operation-container">
                  <div
                    className="operation-freight-front-desk"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "100%",
                    }}
                  >
                    <p>
                      Şu anki Beevision ayarı <b>{currentStatus}</b>
                    </p>
                  </div>
                </Box>

                <Box className="operation-container">
                  <div
                    className="operation-freight-front-desk"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "100%",
                    }}
                  >
                    <Box>
                      <label>Domestik AWB No</label>
                      <TextField
                        value={myShipmentList[0]?.domestic_awb}
                        autoComplete="on"
                        size="small"
                        label=""
                        sx={{ width: "100%" }}
                        disabled
                      />
                    </Box>
                    <Box>
                      <label>Domestik Tedarikçi</label>
                      <TextField
                        value={myShipmentList[0]?.domestic_supplier}
                        autoComplete="on"
                        size="small"
                        label=""
                        sx={{ width: "100%" }}
                        disabled
                      />
                    </Box>
                    <Box>
                      <label>Parcel No</label>
                      <TextField
                        value={myShipmentList[0]?.parcel_ref_no}
                        autoComplete="on"
                        size="small"
                        label=""
                        sx={{ width: "100%" }}
                        disabled
                      />
                    </Box>
                  </div>
                  <div
                    className="operation-freight-front-desk"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      <label>Barkod Parcel No</label>
                      <TextField
                        value={manuelScan}
                        size="small"
                        label=""
                        sx={{ width: "100%" }}
                        onChange={handleManuelScanChange}
                        autoFocus
                      />
                    </Box>
                  </div>
                </Box>
                <Box className="operation-container">
                  <div
                    className="operation-freight-front-desk"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      maxWidth: "100%",
                    }}
                  >
                    <div className="operation-cargo-dom-resp-tickets">
                      <Alert
                        severity="info"
                        className="text-center"
                        style={{
                          width: "100%",
                          backgroundColor: " #E5F6FD",
                          color: " #014361",
                        }}
                      >
                        Müşteri Bugüne Kadar Toplam :{" "}
                        {myShipmentList[0]?.total_parcel_count} Adet Gönderi
                        Yapmıştır.
                      </Alert>
                    </div>
                    <div className="operation-cargo-dom-resp-tickets">
                      <Alert
                        severity="info"
                        className="text-center"
                        style={{
                          width: "100%",
                          backgroundColor: " #E5F6FD",
                          color: " #014361",
                        }}
                      >
                        Gönderinin Son Durumu : {myShipmentList[0]?.status}{" "}
                        {myShipmentList[0]?.status_name === ""
                          ? myShipmentList[0]?.status
                          : myShipmentList[0]?.status_name}
                      </Alert>
                    </div>
                  </div>
                </Box>
                {/* Address */}
                <Box
                  className="operation-container"
                  sx={{
                    display: "flex !important",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    className="operation-freight-front-desk"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <span>Gönderici: {myShipmentList[0]?.sender_name}</span>
                    <span>
                      Adresi:{" "}
                      {myShipmentList[0]?.get_parcel_sender_address.address1}{" "}
                      {myShipmentList[0]?.get_parcel_sender_address.address2}{" "}
                      {myShipmentList[0]?.get_parcel_sender_address.address3}
                    </span>
                    <span>
                      Telefon Numarası: +
                      {myShipmentList[0]?.get_parcel_sender_address.phone}
                    </span>
                    <span>
                      E-mail:{" "}
                      {myShipmentList[0]?.get_parcel_sender_address.email}
                    </span>
                    <span>
                      Posta Kodu:{" "}
                      {myShipmentList[0]?.get_parcel_sender_address.postal_code}
                    </span>
                  </div>
                  <div
                    className="operation-freight-front-desk"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <span>Alıcı: {myShipmentList[0]?.receiver_name}</span>
                    <span>
                      Adresi:{" "}
                      {myShipmentList[0]?.get_parcel_receiver_address.address1}{" "}
                      {myShipmentList[0]?.get_parcel_receiver_address.address2}{" "}
                      {myShipmentList[0]?.get_parcel_receiver_address.address3}
                    </span>
                    <span>
                      Telefon Numarası: +
                      {myShipmentList[0]?.get_parcel_receiver_address.phone}
                    </span>
                    <span>
                      E-mail:{" "}
                      {myShipmentList[0]?.get_parcel_receiver_address.email}
                    </span>
                    <span>
                      Posta Kodu:{" "}
                      {
                        myShipmentList[0]?.get_parcel_receiver_address
                          .postal_code
                      }
                    </span>
                  </div>
                </Box>
                <Box
                  className="operation-container"
                  sx={{
                    display: "flex !important",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    className="operation-freight-front-desk"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor:
                          myShipmentList[0]?.ddp === "1" ? "green" : "red",
                      }}
                      className="operation-freight-front-desk-bee-status"
                    >
                      YDG
                    </span>
                    <span
                      style={{
                        backgroundColor:
                          myShipmentList[0]?.insurance === "1"
                            ? "green"
                            : "red",
                      }}
                      className="operation-freight-front-desk-bee-status"
                    >
                      SİGORTA
                    </span>
                    <span
                      style={{
                        backgroundColor:
                          myShipmentList[0]?.parcel_type === "3"
                            ? "green"
                            : "red",
                      }}
                      className="operation-freight-front-desk-bee-status"
                    >
                      ETGB
                    </span>
                    <span
                      style={{
                        backgroundColor:
                          myShipmentList[0]?.lucid === "1"
                            ? "green"
                            : "red",
                      }}
                      className="operation-freight-front-desk-bee-status"
                    >
                      LUCID
                    </span>
                  </div>
                  <div
                    className="operation-freight-front-desk"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div className="operation-cargo-dom-resp-info-bottom">
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          gap: "50px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "70%",
                            gap: "50px",
                          }}
                          className="steps-where-to"
                        >
                          <Box className="to-plane">
                            {countriesDetailed
                              .find(
                                (e) =>
                                  e.iso_code ===
                                  myShipmentList[0]?.sender_country
                              )
                              ?.country_name_tr?.toUpperCase()}
                            <div
                              className="plane-flag"
                              style={{
                                display: "flex",
                                gap: "10px",
                              }}
                            >
                              <Tooltip
                                title={countriesDetailed
                                  .find(
                                    (e) =>
                                      e.iso_code ===
                                      myShipmentList[0]?.sender_country
                                  )
                                  ?.country_name_tr?.toUpperCase()}
                              >
                                <span>
                                  <CircleFlag
                                    countryCode={countriesDetailed
                                      .find(
                                        (e) =>
                                          e.iso_code ===
                                          myShipmentList[0]?.sender_country
                                      )
                                      ?.iso_code?.toLowerCase()}
                                    height="24"
                                  />
                                </span>
                              </Tooltip>
                              <FlightTakeoffOutlinedIcon id="take-off" />
                            </div>
                          </Box>

                          <div className="list-item-supplier">
                            <Tooltip
                              title={
                                myShipmentList[0]?.get_parcel_supplier
                                  ?.supplier_name
                              }
                            >
                              <Avatar
                                className="list-item-supplier-img"
                                src={`/images/${myShipmentList[0]?.get_parcel_supplier?.supplier_logo}`}
                              />
                            </Tooltip>
                            <p>
                              {myShipmentList[0]?.get_parcel_supplier?.supplier_name}
                            </p>
                          </div>
                          <Box className="to-plane">
                            {countriesDetailed
                              .find(
                                (e) =>
                                  e.iso_code ===
                                  myShipmentList[0]?.receiver_country
                              )
                              ?.country_name_tr?.toUpperCase()}
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                              }}
                              className="plane-flag"
                            >
                              <FlightLandOutlinedIcon id="land" />
                              <Tooltip
                                title={countriesDetailed
                                  .find(
                                    (e) =>
                                      e.iso_code ===
                                      myShipmentList[0]?.receiver_country
                                  )
                                  ?.country_name_tr?.toUpperCase()}
                              >
                                <span>
                                  <CircleFlag
                                    countryCode={countriesDetailed
                                      .find(
                                        (e) =>
                                          e.iso_code ===
                                          myShipmentList[0]?.receiver_country
                                      )
                                      ?.iso_code?.toLowerCase()}
                                    height="24"
                                  />
                                </span>
                              </Tooltip>
                            </div>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "50%",
                          }}
                        >
                          İzleme No
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: 700,
                              textAlign: "center",
                              width: "150px",
                              height: "20px",
                              color: " #6A707E",
                              backgroundColor: " #F3F6F9",
                              marginTop: "10px",
                            }}
                          >
                            {myShipmentList[0]?.tracking_awb}
                          </div>
                        </Box>
                      </div>
                    </div>
                  </div>
                </Box>
                <div className="operation-cargo-dom-resp-general-note-admin">
                  <Card
                    sx={{
                      maxWidth: "100%",
                      width: "100%",
                      backgroundColor: " var(--lighterBg)",
                      padding: "0",
                      borderRadius: "var(--wrapperBorderRadius)",
                      boxShadow: "var(--shadow)",
                      minHeight: "29px",
                    }}
                  >
                    <CardHeader
                      onClick={() => setOpen(!open)}
                      title={
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: "700",
                            letterSpacing: " 0px",
                            marginLeft: "20px",
                          }}
                        >
                          Admin Notu
                        </span>
                      }
                      sx={{
                        minHeight: "10px",
                        padding: "0",
                        cursor: "pointer",
                      }}
                    ></CardHeader>
                    <Box
                      sx={{
                        padding: "20px",
                      }}
                    >
                      {myShipmentList[0]?.get_parcel_notes.length !== 0 &&
                        myShipmentList[0]?.get_parcel_notes
                          .filter((e) => e.type === "2")
                          .map((e) => (
                            <Box key={e.id} className="steps-note">
                              <span>NOT :</span> {e.description}
                            </Box>
                          ))}
                    </Box>
                  </Card>
                </div>
                <div className="operation-cargo-dom-resp-general-note-shipment">
                  <Card
                    sx={{
                      maxWidth: "100%",
                      width: "100%",
                      backgroundColor: " var(--lighterBg)",
                      padding: "0",
                      borderRadius: "var(--wrapperBorderRadius)",
                      boxShadow: "var(--shadow)",
                      minHeight: "29px",
                    }}
                  >
                    <CardHeader
                      onClick={() => setOpen1(!open1)}
                      title={
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: "700",
                            letterSpacing: " 0px",
                            marginLeft: "20px",
                          }}
                        >
                          Müşteri Notu
                        </span>
                      }
                      sx={{
                        minHeight: "10px",
                        padding: "0",
                        cursor: "pointer",
                      }}
                    ></CardHeader>
                    <Box
                      sx={{
                        padding: "20px",
                      }}
                    >
                      {myShipmentList[0]?.get_parcel_notes.length !== 0 &&
                        myShipmentList[0]?.get_parcel_notes
                          .filter((e) => e.type === "1")
                          .map((e) => (
                            <Box key={e.id} className="steps-note">
                              <span>NOT :</span> {e.description}
                            </Box>
                          ))}
                    </Box>
                  </Card>
                </div>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    backgroundColor: "var(--lighterBg)",
                    borderRadius: "10px",
                    gap: "10px",
                    padding: "15px 50px",
                  }}
                >
                  <Box>
                    <div
                      style={{
                        width: "500px",
                      }}
                      className="operation-cargo-dom-resp-weight"
                    >
                      <div className="operation-cargo-dom-resp-wight-shipment">
                        <div className="title">
                          Üye Tarafından Beyan Edilen Kilo
                        </div>
                        {myShipmentList[0]?.get_parcel_dimensions
                          .filter((item) => item.status === "1")
                          .map((item, index) => (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                gap: "10px",
                                paddingTop: "20px",
                              }}
                            >
                              <div className="operation-cargo-dom-resp-wight-shipment-item">
                                <div
                                  style={{
                                    color: "rgba(0, 0, 0, 0.50)",
                                  }}
                                  className="little-title"
                                >
                                  E
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "#e9ecef",
                                    width: "50px",
                                    color: "black",
                                    padding: "3px",
                                    fontSize: "14px",
                                  }}
                                  className="operation-cargo-dom-resp-wight-shipment-item-bottom"
                                >
                                  {item.width}
                                </div>
                              </div>
                              <div className="operation-cargo-dom-resp-wight-shipment-item">
                                <div
                                  style={{
                                    color: "rgba(0, 0, 0, 0.50)",
                                  }}
                                  className="little-title"
                                >
                                  B
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "#e9ecef",
                                    width: "50px",
                                    color: "black",
                                    padding: "3px",
                                    fontSize: "14px",
                                  }}
                                  className="operation-cargo-dom-resp-wight-shipment-item-bottom"
                                >
                                  {item.length}
                                </div>
                              </div>
                              <div className="operation-cargo-dom-resp-wight-shipment-item">
                                <div
                                  style={{
                                    color: "rgba(0, 0, 0, 0.50)",
                                  }}
                                  className="little-title"
                                >
                                  Y
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "#e9ecef",
                                    width: "50px",
                                    color: "black",
                                    padding: "3px",
                                    fontSize: "14px",
                                  }}
                                  className="operation-cargo-dom-resp-wight-shipment-item-bottom"
                                >
                                  {item.height}
                                </div>
                              </div>
                              <div className="operation-cargo-dom-resp-wight-shipment-item">
                                <div
                                  style={{
                                    color: "rgba(0, 0, 0, 0.50)",
                                  }}
                                  className="little-title"
                                >
                                  Kap
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "#e9ecef",
                                    width: "50px",
                                    color: "black",
                                    padding: "3px",
                                    fontSize: "14px",
                                  }}
                                  className="operation-cargo-dom-resp-wight-shipment-item-bottom"
                                >
                                  {item.qty}
                                </div>
                              </div>
                              <div className="operation-cargo-dom-resp-wight-shipment-item">
                                <div
                                  style={{
                                    color: "rgba(0, 0, 0, 0.50)",
                                  }}
                                  className="little-title"
                                >
                                  GW
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "#e9ecef",
                                    width: "50px",
                                    color: "black",
                                    padding: "3px",
                                    fontSize: "14px",
                                  }}
                                  className="operation-cargo-dom-resp-wight-shipment-item-bottom"
                                >
                                  {item.gross}
                                </div>
                              </div>
                              <div className="operation-cargo-dom-resp-wight-shipment-item">
                                <div
                                  style={{
                                    color: "rgba(0, 0, 0, 0.50)",
                                  }}
                                  className="little-title"
                                >
                                  VW
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "#e9ecef",
                                    width: "50px",
                                    color: "black",
                                    padding: "3px",
                                    fontSize: "14px",
                                  }}
                                  className="operation-cargo-dom-resp-wight-shipment-item-bottom"
                                >
                                  {item.vw}
                                </div>
                              </div>
                            </div>
                          ))}
                        <br />
                        <br />
                        <div className="operation-cargo-dom-resp-wight-shipment-group-bottom">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              color: "#6a707e",
                              marginLeft: "50px",
                              gap: "5px",
                            }}
                            className="operation-cargo-dom-resp-wight-shipment-bottom-side"
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                color: "rgba(0, 0, 0, 0.50)",
                              }}
                              className="little-title"
                            >
                              Toplam Ücret Ağırlığı
                            </div>
                            <div
                              style={{
                                backgroundColor: "#e9ecef",
                                width: "124px",
                                height: "20px",
                                fontSize: "14px",
                                fontWeight: 700,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              className="operation-cargo-dom-resp-wight-shipment-item-bottom"
                            >
                              {myShipmentList[0]?.get_parcel_dimensions
                                .filter((item) => item.status === "1") // status değeri "1"
                                .reduce(
                                  (acc, item) =>
                                    Number(acc) + Number(item.total_cw),
                                  0
                                )}
                              kg
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                  <Box>
                    <div
                      style={{
                        width: "500px",
                      }}
                      className="operation-cargo-dom-resp-weight"
                    >
                      <div className="operation-cargo-dom-resp-wight-shipment">
                        <div className="title">
                          iyziTeam Tarafından Beyan Edilen Kilo
                        </div>
                        {myShipmentList[0]?.get_parcel_dimensions
                          .filter((item) => item.status === "2")
                          .map((item, index) => (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                gap: "10px",
                                paddingTop: "20px",
                              }}
                            >
                              <div className="operation-cargo-dom-resp-wight-shipment-item">
                                <div
                                  style={{
                                    color: "rgba(0, 0, 0, 0.50)",
                                  }}
                                  className="little-title"
                                >
                                  E
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "#e9ecef",
                                    width: "50px",
                                    color: "black",
                                    padding: "3px",
                                    fontSize: "14px",
                                  }}
                                  className="operation-cargo-dom-resp-wight-shipment-item-bottom"
                                >
                                  {item.width}
                                </div>
                              </div>
                              <div className="operation-cargo-dom-resp-wight-shipment-item">
                                <div
                                  style={{
                                    color: "rgba(0, 0, 0, 0.50)",
                                  }}
                                  className="little-title"
                                >
                                  B
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "#e9ecef",
                                    width: "50px",
                                    color: "black",
                                    padding: "3px",
                                    fontSize: "14px",
                                  }}
                                  className="operation-cargo-dom-resp-wight-shipment-item-bottom"
                                >
                                  {item.length}
                                </div>
                              </div>
                              <div className="operation-cargo-dom-resp-wight-shipment-item">
                                <div
                                  style={{
                                    color: "rgba(0, 0, 0, 0.50)",
                                  }}
                                  className="little-title"
                                >
                                  Y
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "#e9ecef",
                                    width: "50px",
                                    color: "black",
                                    padding: "3px",
                                    fontSize: "14px",
                                  }}
                                  className="operation-cargo-dom-resp-wight-shipment-item-bottom"
                                >
                                  {item.height}
                                </div>
                              </div>
                              <div className="operation-cargo-dom-resp-wight-shipment-item">
                                <div
                                  style={{
                                    color: "rgba(0, 0, 0, 0.50)",
                                  }}
                                  className="little-title"
                                >
                                  Kap
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "#e9ecef",
                                    width: "50px",
                                    color: "black",
                                    padding: "3px",
                                    fontSize: "14px",
                                  }}
                                  className="operation-cargo-dom-resp-wight-shipment-item-bottom"
                                >
                                  {item.qty}
                                </div>
                              </div>
                              <div className="operation-cargo-dom-resp-wight-shipment-item">
                                <div
                                  style={{
                                    color: "rgba(0, 0, 0, 0.50)",
                                  }}
                                  className="little-title"
                                >
                                  GW
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "#e9ecef",
                                    width: "50px",
                                    color: "black",
                                    padding: "3px",
                                    fontSize: "14px",
                                  }}
                                  className="operation-cargo-dom-resp-wight-shipment-item-bottom"
                                >
                                  {item.gross}
                                </div>
                              </div>
                              <div className="operation-cargo-dom-resp-wight-shipment-item">
                                <div
                                  style={{
                                    color: "rgba(0, 0, 0, 0.50)",
                                  }}
                                  className="little-title"
                                >
                                  VW
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "#e9ecef",
                                    width: "50px",
                                    color: "black",
                                    padding: "3px",
                                    fontSize: "14px",
                                  }}
                                  className="operation-cargo-dom-resp-wight-shipment-item-bottom"
                                >
                                  {item.vw}
                                </div>
                              </div>
                            </div>
                          ))}
                        <br />
                        <br />
                        <div className="operation-cargo-dom-resp-wight-shipment-group-bottom">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              color: "#6a707e",
                              marginLeft: "50px",
                              gap: "5px",
                            }}
                            className="operation-cargo-dom-resp-wight-shipment-bottom-side"
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                color: "rgba(0, 0, 0, 0.50)",
                              }}
                              className="little-title"
                            >
                              Toplam Ücret Ağırlığı
                            </div>
                            <div
                              style={{
                                backgroundColor: "#e9ecef",
                                width: "124px",
                                height: "20px",
                                fontSize: "14px",
                                fontWeight: 700,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              className="operation-cargo-dom-resp-wight-shipment-item-bottom"
                            >
                              {myShipmentList[0]?.get_parcel_dimensions
                                .filter((item) => item.status === "2")
                                .reduce(
                                  (acc, item) =>
                                    Number(acc) + Number(item.total_cw),
                                  0
                                )}
                              kg
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Box>
                <Box>
                  <div className="operation-cargo-dom-resp-proforma">
                    <Card
                      sx={{
                        maxWidth: "100%",
                        width: "100%",
                        padding: "5px",
                        backgroundColor: " var(--lighterBg)",
                        borderRadius: "var(--wrapperBorderRadius)",
                        boxShadow: "var(--shadow)",
                        minHeight: "40px",
                      }}
                    >
                      <CardHeader
                        onClick={() => setOpen4(!open4)}
                        title={
                          <span
                            style={{
                              fontSize: "20px",
                              fontWeight: "700",
                              letterSpacing: " 0px",
                              marginLeft: "20px",
                              display: "flex",
                              color: "#8C8C8C",
                              alignItems: "center",
                              padding: "15px 0px",
                            }}
                          >
                            <img
                              className="logo"
                              src={proformaLogo}
                              alt=""
                              style={{ marginRight: "20px" }}
                            />{" "}
                            Proforma
                          </span>
                        }
                        sx={{
                          minHeight: "10px",
                          padding: "0",
                          cursor: "pointer",
                        }}
                      ></CardHeader>
                      <div>
                        <CardContent>
                          <Container
                            sx={{
                              lineHeight: 2,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "20px",
                              }}
                              className="operation-cargo-dom-resp-proforma-up"
                            >
                              <Box>
                                <label>HS Code</label>
                                <TextField
                                  value={
                                    myShipmentList[0]?.get_parcel_commoduties[0]?.hs_code === null
                                      ? ""
                                      : myShipmentList[0]?.get_parcel_commoduties[0]?.hs_code
                                  }
                                  autoComplete="on"
                                  size="small"
                                  label=""
                                  sx={{ width: "100%" }}
                                />
                              </Box>
                              <Box>
                                <label>IOSS Numarası</label>
                                <TextField
                                  value={
                                    myShipmentList[0]?.ioss === null
                                      ? ""
                                      : myShipmentList[0]?.ioss
                                  }
                                  autoComplete="on"
                                  size="small"
                                  label=""
                                  sx={{ width: "100%" }}
                                />
                              </Box>

                              <Box>
                                <label>Ürün Açıklaması</label>
                                <TextField
                                  value={
                                    myShipmentList[0]?.get_parcel_commoduties[0]
                                      ?.title
                                  }
                                  autoComplete="off"
                                  size="small"
                                  sx={{ width: "100%" }}
                                  disabled
                                />
                              </Box>

                              <Box>
                                <label>Proforma Toplam Değer</label>
                                <TextField
                                  value={
                                    myShipmentList[0]?.get_parcel_commoduties[0]
                                      ?.total_price
                                  }
                                  autoComplete="off"
                                  size="small"
                                  sx={{ width: "100%" }}
                                  disabled
                                />
                              </Box>

                              <Box>
                                <label>Proforma Döviz Cinsi</label>
                                <TextField
                                  value={
                                    myShipmentList[0]?.get_parcel_commoduties[0]
                                      ?.currency
                                  }
                                  autoComplete="off"
                                  size="small"
                                  sx={{ width: "100%" }}
                                  disabled
                                />
                              </Box>
                            </div>
                          </Container>
                        </CardContent>
                      </div>
                    </Card>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    backgroundColor: getStatusColor(shipmentStatus),
                    borderRadius: "10px",
                  }}
                >
                  <Button
                    sx={{
                      padding: "30px 0px",
                      fontSize: "25px",
                      width: "100%",
                      color: "#fff",
                    }}
                  >
                    {myShipmentList[0]?.status === "2.03"
                      ? "GÜVENLİK KONTROLÜNE ALINIZ (SI)"
                      : myShipmentList[0]?.status === "1.02"
                      ? "KULLANICI TARAFINDAN İADE TALEBİ OLUŞTURULMUŞTUR. İADELERE AYIRINIZ"
                      : myShipmentList[0]?.status === "2.16"
                      ? "ÖLÇÜ KONTROLÜ YAPILDI"
                      : myShipmentList[0]?.status === "0.05"
                      ? "KULLANICI TARAFINDAN İPTAL EDİLMİŞTİR. İADELERE AYIRINIZ"
                      : myShipmentList[0]?.status === "0.06"
                      ? "KULLANICI TARAFINDAN SİLİNMİŞTİR İADELERE AYIRINIZ"
                      : myShipmentList[0]?.status === "0.00"
                      ? "OLUŞTURULDU"
                      : myShipmentList[0]?.status === "1.00"
                      ? "IYZISHIP MERKEZİNE DOĞRU YOLDA - KURYE TARAFINDAN TESLİM ALINDI"
                      : myShipmentList[0]?.status === "1.01"
                      ? "IYZISHIP MERKEZİNE DOĞRU YOLDA - DOMESTİK TAŞIYICIDA"
                      : "GÖNDERİNİN STATÜSÜ İŞLEM YAPMAYA UYGUN DEĞİLDİR. LÜTFEN KONTROL EDİNİZ"}
                  </Button>
                </Box>
                {myShipmentList[0]?.get_beevision_read_response
                  ?.converted_response_message.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      backgroundColor: "red",
                      borderRadius: "10px",
                    }}
                  >
                    <Button
                      sx={{
                        padding: "30px 0px",
                        fontSize: "25px",
                        width: "100%",
                        color: "#fff",
                      }}
                    >
                      {
                        myShipmentList[0]?.get_beevision_read_response
                          ?.converted_response_message
                      }
                    </Button>
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    marginBottom: "50px",
                  }}
                >
                  <Button
                    sx={{
                      padding: "15px 0px",
                      width: "20%",
                    }}
                    className="iyzi-button-blue"
                  >
                    Kaydet
                  </Button>
                </Box>
              </Box>
            )}
          </div>
        )}
        <Dialog open={checkSettings} onClose={checkSettings}>
          <DialogContent>
            <DialogTitle>
              <DialogContentText id="alert-dialog-description">
                Barkod okunuyor lütfen bekleyiniz...
              </DialogContentText>
            </DialogTitle>
          </DialogContent>
        </Dialog>
      </section>
    </ThemeProvider>
  );
};

export default BeevisionPassingLoadScreen;
