import { useRecoilState } from "recoil";
import { addExtraPriceOpenState, selectedShipmentForMyShipmentsState, editDomesticPackageInfoState } from "../../../../atoms/myShipments";
import {
  Button, Modal, ThemeProvider, createTheme, FormControl, InputLabel, Select, MenuItem, TextField, Box
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Errors from "../../../../utils/Errors";
import { Height } from "@mui/icons-material";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px",
          overflow: "auto",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const DomesticPackageTypeChange = () => {
  const [editDomesticPackageInfo, setEditDomesticPackageInfo] = useRecoilState(editDomesticPackageInfoState);
  const [selectedShipmentForMyShipments, setSelectedShipmentForMyShipments] = useRecoilState(
    selectedShipmentForMyShipmentsState
  );

  const [gross, setGross] = useState("");
  const [height, setHeight] = useState("");
  const [length, setLenght] = useState("");
  const [width, setWidth] = useState("");
  const [loading, setLoading] = useState(true);

  const handleUpdateDomesticInfo = () => {
    const requestBody = {
      length: length,
      width: width,
      height: height,
      gross: gross
    };

    setLoading(true); // İşlem başladığında loading state aktif ediliyor

    axios
      .post(`parcel/operation/change-packet-parcel/${selectedShipmentForMyShipments?.id}`, requestBody)
      .then((response) => {
        // API çağrısı başarılı olursa yapılacak işlemler
        toast.success(response.data.message);
        handleClose();
        setInterval(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        // Hata olursa yapılacak işlemler
        Errors(error);
      })
      .finally(() => {
        // API çağrısı tamamlandığında loading state devre dışı bırakılıyor
        setLoading(false);
      });
  };

  const handleClose = () => {
    setEditDomesticPackageInfo(false);
    setHeight("");
    setWidth("");
    setLenght("");
    setGross("");
  };

  console.log(selectedShipmentForMyShipments)

  return (
    <ThemeProvider theme={theme}>
      <Modal open={editDomesticPackageInfo} onClose={handleClose}>
        <div
          style={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            padding: "20px",
            outline: 0,
            display: "grid",
            gap: "var(--gap)",
            width: "100%",
            maxWidth: "1100px",
            margin: "auto",
            position: "relative",
          }}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              cursor: "pointer",
              color: "var(--priceText)",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleClose}
          />
          <Box sx={
            {
              display: "flex",
              flexDirection: "column",
              gap: "20px"
            }
          }>
            <Box>
              <p>
                Paket Tipi Değişikliği
              </p>
              <Box sx={{
                display: "flex",
                gap: "20px",
                marginTop: "20px"
              }}>
                <Box sx={{
                  display: "flex",
                  gap: "20px",
                  marginTop: "20px"
                }} className="dimension-detail-per-side">
                  <TextField
                    label="En"
                    size="small"
                    value={width}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setWidth(e.target.value)}
                    fullWidth
                  />
                  <TextField
                    label="Boy"
                    size="small"
                    value={length}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setLenght(e.target.value)}
                    fullWidth
                  />

                  <TextField
                    label="Yükseklik"
                    size="small"
                    value={height}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setHeight(e.target.value)}
                    fullWidth
                  />
                  <TextField
                    label="Ağırlık"
                    size="small"
                    value={gross}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setGross(e.target.value)}
                    fullWidth
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: " flex", justifyContent: "flex-end" }}>
            <Button
              type="button"
              onClick={() => {
                handleClose();
              }}
              className="iyzi-button"
            >
              Vazgeç
            </Button>
            <Button
              onClick={handleUpdateDomesticInfo}
              className="iyzi-button"
              sx={{ ml: "10px" }}
            >
              Ekle
            </Button>
          </Box>

        </div>
      </Modal>
    </ThemeProvider>
  );
};

export default DomesticPackageTypeChange;
