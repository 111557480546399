import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const FinanceNav = () => {
  const links = [
    {
      page: "İyziBakiye",
      location: "/finance",
    },
    {
      page: "Faturalar",
      location: "/finance/my-bills",
    },
    {
      page: "Fatura Kesme",
      location: "/finance/my-bills-invoicing",
    },
    {
      page: "Bekleyen Ödemeler",
      location: "/finance/extract",
    },
    {
      page: "Bakiye İade Talepleri",
      location: "/finance/balance-refund-request",
    },
    {
      page: "Havale Talepleri",
      location: "/finance/remittance-requests",
    },
    {
      page: "Iban Bilgileri",
      location: "/finance/iban-info",
    },
    {
      page: "Tazmin İşlemleri",
      location: "/finance/compensation-proceedings",
    },
  ];

  return (
    <Box className="my-wallet-nav">
      {links.map((link, key) => (
        <Link
          key={key}
          className={"my-wallet-nav-link"}
          style={{
            color:
              link.location === window.location.pathname
                ? "var(--info)"
                : "var(--priceText)",
            fontSize: "13px",
          }}
          to={link.location}
        >
          {link.page}
        </Link>
      ))}
    </Box>
  );
};

export default FinanceNav;
