import {
  Box,
  Button,
  createTheme,
  Modal,
  TextField,
  ThemeProvider,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  InputAdornment,
  CircularProgress,
  FormControl,
} from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import axios from "../../../../api/axios";
import {
  editReceiverAddressOpenState,
  selectedShipmentForMyShipmentsState,
} from "../../../../atoms/myShipments";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import PhoneInput from "react-phone-input-2";
import Errors from "../../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px",
          overflow: "auto",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const EditShipmentAddress = () => {
  const navigate = useNavigate();

  const [editReceiverAddressOpen, setEditReceiverAddressOpen] = useRecoilState(
    editReceiverAddressOpenState
  );
  const [selectedShipmentForMyShipments, setSelectedShipmentForMyShipments] =
    useRecoilState(selectedShipmentForMyShipmentsState);

  const [contactName, setContactName] = useState();
  const [companyName, setCompanyName] = useState();
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [address3, setAddress3] = useState();
  const [postalCode, setPostalCode] = useState();
  const [state, setState] = useState();
  const [town, setTown] = useState();
  const [city, setCity] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [stateCode, setStateCode] = useState("");

  const [destroyDialog, setDestroyDialog] = useState(false);

  const [zipLoading, setZipLoading] = useState(false);
  const [zipIsDone, setZipIsDone] = useState(null);

  const [zipCodeError, setZipCodeError] = useState(false);

  const handleDestroyDialogOpen = () => {
    setDestroyDialog(true);
    setEditReceiverAddressOpen(false);
  };

  const handleDestroyDialogClose = () => {
    setDestroyDialog(false);
  };

  const zipCodeHandler = useCallback(async () => {
    if (postalCode.length === 0) {
      setZipIsDone(null);
    } else {
      setZipLoading(true);
      setZipIsDone(null);
      await axios
        .post("get-geo-details", {
          postalcode: postalCode,
          country:
            selectedShipmentForMyShipments?.get_parcel_receiver_address
              ?.country,
        })
        .then((res) => {
          if (res.data.data.postalcodes.length === 0) {
            setZipCodeError(false);
            setStateCode("");
          } else if (res.data.data.postalcodes.length > 1) {
            setPostalCode("");
            setStateCode("");
            setZipCodeError(true);
          } else {
            setZipIsDone(true);
            setState(res.data.data.postalcodes[0].placeName);
            setTown(
              res.data.data.postalcodes[0].adminName2
                ? res.data.data.postalcodes[0].adminName2
                : res.data.data.postalcodes[0].placeName
            );
            setCity(res.data.data.postalcodes[0].adminName1);
            setStateCode(res.data.data.postalcodes[0].adminCode1);
            setZipCodeError(false);
          }
        })
        .catch((error) => Errors(error))
        .finally(() => {
          setZipLoading(false);
          setZipIsDone(false);
        });
    }
  }, [postalCode, selectedShipmentForMyShipments]);

  const handleSetValues = useCallback(() => {
    setContactName(
      selectedShipmentForMyShipments?.get_parcel_receiver_address?.contact_name
    );
    setCompanyName(
      selectedShipmentForMyShipments?.get_parcel_receiver_address?.company_name
    );
    setAddress1(
      selectedShipmentForMyShipments?.get_parcel_receiver_address?.address1
    );
    setAddress2(
      selectedShipmentForMyShipments?.get_parcel_receiver_address?.address2
    );
    setAddress3(
      selectedShipmentForMyShipments?.get_parcel_receiver_address?.address3
    );
    setPostalCode(
      selectedShipmentForMyShipments?.get_parcel_receiver_address?.postal_code
    );
    setState(
      selectedShipmentForMyShipments?.get_parcel_receiver_address?.state
    );
    setTown(selectedShipmentForMyShipments?.get_parcel_receiver_address?.town);
    setCity(selectedShipmentForMyShipments?.get_parcel_receiver_address?.city);
    setPhone(
      selectedShipmentForMyShipments?.get_parcel_receiver_address?.phone
    );
    setEmail(
      selectedShipmentForMyShipments?.get_parcel_receiver_address?.email
    );
    setStateCode(
      selectedShipmentForMyShipments?.get_parcel_receiver_address?.state_code
    );
  }, [selectedShipmentForMyShipments]);

  useEffect(() => {
    if (selectedShipmentForMyShipments.length !== 0) {
      handleSetValues();
    }
  }, [handleSetValues, selectedShipmentForMyShipments]);

  const updateAddress = async () => {
    await axios
      .post(
        `parcel/update-receiver-address/${selectedShipmentForMyShipments.id}`,
        {
          contact_name: contactName,
          company_name: companyName,
          address1: address1,
          address2: address2,
          address3: address3,
          postal_code: postalCode,
          state: state,
          state_code: stateCode,
          town: town,
          city: city,
          phone: phone,
          email: email,
          country:
            selectedShipmentForMyShipments?.get_parcel_receiver_address.country,
          address_type:
            selectedShipmentForMyShipments?.get_parcel_receiver_address
              .address_type,
          address_description:
            selectedShipmentForMyShipments?.get_parcel_receiver_address
              .address_description,
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          toast.success(response.data.message);
          setEditReceiverAddressOpen(false);
          setInterval(() => {
            window.location.reload();
          }, 1000);
        }
        if (response.data.status === 400) {
          toast.error(response.data.message);
        }
      })
      .catch((error) => Errors(error))
      .finally(() => {
        setEditReceiverAddressOpen(false);
        setSelectedShipmentForMyShipments([]);
      });
  };

  const handleClose = () => {
    setEditReceiverAddressOpen(false);
    setSelectedShipmentForMyShipments([]);
    handleDestroyDialogClose();
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal open={editReceiverAddressOpen} onClose={handleClose}>
        <Box
          sx={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            padding: "20px",
            outline: 0,
            display: "grid",
            gap: "var(--gap)",
            width: "100%",
            maxWidth: "1100px",
            margin: "auto",
            position: "relative",
          }}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              color: "var(--priceText)",
              cursor: "pointer",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleClose}
          />
          <Box
            sx={{
              display: "grid",

              gap: "var(--gap2x)",
            }}
          >
            <div style={{ display: "flex", gap: "var(--gap)" }}>
              <TextField
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                size="small"
                label="Kontak"
                autoFocus
                onFocus={(e) => {
                  let descValue = e.target.value;
                  e.target.value = "";
                  e.target.value = descValue;
                }}
                fullWidth
              />
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size="small"
                type={"email"}
                label="E-Posta"
                fullWidth
              />
            </div>
            <TextField
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              size="small"
              label="Firma Adı"
            />
            <TextField
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              size="small"
              label="Adres Satırı"
            />
            {address2 && (
              <TextField
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                size="small"
                label="Adres Satırı 2"
              />
            )}
            {address3 && (
              <TextField
                value={address3}
                onChange={(e) => setAddress3(e.target.value)}
                size="small"
                label="Adres Satırı 3"
              />
            )}
            <div
              style={{
                display: "grid",
                gap: "var(--gap2x)",
                gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
              }}
            >
              <TextField
                required
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                placeholder="06010"
                onBlur={zipCodeHandler}
                fullWidth
                size="small"
                autoComplete="off"
                label="Posta Kodu"
                error={zipCodeError}
                helperText={
                  <div
                    style={{
                      fontSize: "inherit",
                      color: "var(--info)",
                      backgroundColor: "var(--pastelBlue)",
                      display: "flex",
                      gap: "5px",
                      padding: "5px",
                      borderRadius: "var(--wrapperBorderRadius)",
                      alignItems: "center",
                    }}
                  >
                    <InfoOutlinedIcon />
                    <div style={{ fontSize: "inherit", color: "var(--info)" }}>
                      Eğer posta kodunun yanında{" "}
                      <CloseOutlinedIcon
                        style={{ color: "red", fontSize: "11px" }}
                      />{" "}
                      görseli varsa bu seçeneklerde bulunmadığı anlamına gelir.
                      Semt, İlçe ve Şehir kısmını doldurarak devam etmenizde bir
                      sakınca yoktur.
                    </div>
                  </div>
                }
                InputProps={
                  zipIsDone === true
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <DoneOutlinedIcon style={{ color: "green" }} />
                          </InputAdornment>
                        ),
                      }
                    : zipIsDone === false
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CloseOutlinedIcon style={{ color: "red" }} />
                          </InputAdornment>
                        ),
                      }
                    : zipIsDone === null && zipLoading === true
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress
                              style={{ width: "20px", height: "20px" }}
                            />
                          </InputAdornment>
                        ),
                      }
                    : {}
                }
              />
              <FormControl size="small" fullWidth>
                <PhoneInput
                  country={selectedShipmentForMyShipments?.get_parcel_receiver_address?.country.toLowerCase()}
                  value={phone}
                  onChange={(e) => setPhone(e)}
                  autoComplete="off"
                  inputProps={{
                    name: "phone",
                    autoFocus: true,
                    id: "ind-phone-input",
                    style: {
                      height: "100%",
                      width: "100%",
                      minHeight: "40px",
                      maxHeight: "40px",
                    },
                  }}
                />
              </FormControl>
            </div>
            <div style={{ display: "flex", gap: "var(--gap)" }}>
              <TextField
                value={city}
                fullWidth
                onChange={(e) => setCity(e.target.value)}
                size="small"
                label="Province / Eyalet"
              />
              <TextField
                value={town}
                fullWidth
                onChange={(e) => setTown(e.target.value)}
                size="small"
                label="Kent"
              />
              <TextField
                value={state}
                fullWidth
                onChange={(e) => setState(e.target.value)}
                size="small"
                label="İlçe"
              />
              <TextField
                value={stateCode}
                fullWidth
                onChange={(e) => setStateCode(e.target.value)}
                size="small"
                label="Eyalet Kodu"
              />
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "var(--gap)",
            }}
          >
            <Button
              sx={{
                textTransform: "none",
                padding: "7px 16px",
                color: "var(--lighterBg)",
                backgroundColor: "var(--info)",
                borderRadius: "var(--buttonBorderRadius)",
                transition: "all 150ms linear",
                ":hover": {
                  backgroundColor: "var(--blueBrand)",
                  transition: "all 150ms linear",
                },
              }}
              onClick={handleDestroyDialogOpen}
            >
              Düzenle
            </Button>
            <Button
              sx={{
                textTransform: "none",
                padding: "7px 16px",
                color: "var(--lighterBg)",
                backgroundColor: "var(--info)",
                borderRadius: "var(--buttonBorderRadius)",
                transition: "all 150ms linear",
                ":hover": {
                  backgroundColor: "var(--blueBrand)",
                  transition: "all 150ms linear",
                },
              }}
              onClick={handleClose}
            >
              İptal
            </Button>
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={destroyDialog}
        onClose={handleDestroyDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Alıcı Adresi Düzenle"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ fontWeight: 500, fontSize: "16px" }}
            id="alert-dialog-description"
          >
            Yaptığınız işlem sonrasında tekrardan izleme numarası almanız
            gerekecektir. Onaylıyor musunuz?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="iyzi-button" onClick={handleDestroyDialogClose}>
            Hayır
          </Button>
          <Button className="iyzi-button" onClick={updateAddress}>
            Evet
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default EditShipmentAddress;
