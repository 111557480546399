import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  ClickAwayListener,
  Collapse,
  IconButton,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCallback, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloudCircleOutlinedIcon from "@mui/icons-material/CloudCircleOutlined";
import { useRecoilState } from "recoil";
import {
  expandFilesState,
  parcelDocumentState,
  ticketDocumentState,
} from "../../../../atoms/support";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import axios from "../../../../api/axios";
import Errors from "../../../../utils/Errors";
import { toast } from "react-toastify";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
}));

const SupportDetailFilesAdmin = () => {
  const [ticketDocument, setTicketDocument] =
    useRecoilState(ticketDocumentState);
  const [parcelDocument, setParcelDocument] =
    useRecoilState(parcelDocumentState);
  const [expandFiles, setExpandFiles] = useRecoilState(expandFilesState);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);

  const handleClickAway = () => {
    setExpandFiles(false);
  };

  const handleExpandFilesClick = useCallback(() => {
    setExpandFiles(!expandFiles);
  }, [setExpandFiles, expandFiles]);

  const handleShowFile = useCallback((document) => {
    setDocumentLoading(true);
    axios
      .get(`document/${document.id}`, { responseType: "arraybuffer" })
      .then((response) => {
        let blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        let image = window.URL.createObjectURL(blob);
        window.open(image, "_blank");
      })
      .catch((error) => Errors(error))
      .finally(() => setDocumentLoading(false));
  }, []);

  const handleOpenDeleteDialog = (document) => {
    setDocumentToDelete(document);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDocumentToDelete(null);
    setDeleteDialogOpen(false);
  };

  const handleDeleteFile = (document) => {
    handleOpenDeleteDialog(document);
  };

  const handleConfirmDelete = () => {
    if (documentToDelete) {
      axios
        .post(`document/delete/${documentToDelete.id}`)
        .then((response) => {
          toast.success(response.data.message);
          setTicketDocument((prevDocuments) =>
            prevDocuments.filter((doc) => doc.id !== documentToDelete.id)
          );
          handleCloseDeleteDialog();
        })
        .catch((error) => {
          Errors(error);
        });
    }
  };

  return (
    <>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <Card className="support-detail-files" sx={{ width: "100%" }}>
          <CardActions
            sx={{ cursor: "pointer" }}
            onClick={handleExpandFilesClick}
            disableSpacing
          >
            <Box className="support-detail-files-header">
              <CloudCircleOutlinedIcon /> Yüklü Dosyalar
            </Box>
            <ExpandMore
              expand={expandFiles}
              onClick={handleExpandFilesClick}
              aria-expanded={expandFiles}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expandFiles} timeout="auto" unmountOnExit>
            <CardContent className="support-detail-files-content">
              {ticketDocument?.length !== 0 ? <h5>Destek Talebi</h5> : null}
              <Box className="files-content">
                {documentLoading ? (
                  // Yükleme durumunda
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      sx={{ width: "24px!important", height: "24px!important" }}
                    />
                  </Box>
                ) : ticketDocument && ticketDocument.length !== 0 ? (
                  ticketDocument.map((document, key) => (
                    <Box className="files-content-item" key={key}>
                      <Box
                        onClick={() => handleShowFile(document)}
                        className="files-content-item-icon"
                        sx={{
                          backgroundColor:
                            document.type === "ticket_iyzi_team"
                              ? "var(--shipmentBlue) !important"
                              : document.type === "ticket_user"
                              ? "var(--pastelYellow) !important"
                              : undefined,
                        }}
                      >
                        <InsertDriveFileOutlinedIcon />
                      </Box>
                      <Box className="files-content-item-text">
                        {document.name.length > 15
                          ? `${document.name.slice(0, 10)}...`
                          : document.name}
                      </Box>
                      <IconButton
                        onClick={() => handleDeleteFile(document)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))
                ) : null}
              </Box>

              {/* {parcelDocument.length !== 0 ? <h5>Gönderi</h5> : null} */}

              <Box className="files-content">
                {documentLoading ? (
                  // Yükleme durumunda
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      sx={{ width: "24px!important", height: "24px!important" }}
                    />
                  </Box>
                ) : parcelDocument && parcelDocument.length !== 0 ? (
                  parcelDocument.map((document, key) => (
                    <Box className="files-content-item" key={key}>
                      <Box
                        onClick={() => handleShowFile(document)}
                        className="files-content-item-icon"
                        sx={{
                          backgroundColor:
                            document.type === "ticket_iyzi_team"
                              ? "var(--shipmentBlue) !important"
                              : document.type === "ticket_user"
                              ? "var(--pastelYellow) !important"
                              : undefined,
                        }}
                      >
                        <InsertDriveFileOutlinedIcon />
                      </Box>
                      <Box className="files-content-item-text">
                        {document.name.length > 15
                          ? `${document.name.slice(0, 10)}...`
                          : document.name}
                      </Box>
                      <IconButton
                        onClick={() => handleDeleteFile(document)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))
                ) : null}
              </Box>
            </CardContent>
          </Collapse>
        </Card>
      </ClickAwayListener>
      <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Belgeyi Sil</DialogTitle>
        <DialogContent>
          <p>Emin misiniz? Bu belgeyi silmek istiyor musunuz?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Hayır
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Evet
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SupportDetailFilesAdmin;
