import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "../../../../api/axios";
import Errors from "../../../../utils/Errors";
import Currency from "../../../../utils/CurrencySymbol";
import FormatDate from "../../../../utils/FormatDate";
import { FormatReturnFieldWithCustomDecimals } from "../../../../utils/FormatFieldReturn";

const AddExchangeRate = ({ data, setData, open, setOpen }) => {
  const today = FormatDate(new Date());

  const [addLoading, setAddLoading] = useState(false);
  const [rateType, setRateType] = useState("");
  const [currency, setCurrency] = useState("");
  const [rateDate, setRateDate] = useState(today);
  const [buyRate, setBuyRate] = useState("");
  const [sellRate, setSellRate] = useState("");

  const handleClose = () => {
    setOpen(false);
    setRateType("");
    setCurrency("");
    setRateDate(today);
    setBuyRate("");
    setSellRate("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "sell_rate") {
      setSellRate(FormatReturnFieldWithCustomDecimals(value, 4));
    } else if (name === "buy_rate") {
      setBuyRate(FormatReturnFieldWithCustomDecimals(value, 4));
    }
  };

  const handleAddExchangeRate = (e) => {
    if (e) e.preventDefault();

    setAddLoading(true);
    axios
      .post(`exchange-rate/add`, {
        rate_type: rateType,
        currency: currency,
        rate_date: rateDate,
        buy_rate: parseFloat(buyRate),
        sell_rate: parseFloat(sellRate),
      })
      .then((response) => {
        setData([response.data?.data, ...data]);
        toast.success(response.data?.message);
        handleClose();
      })
      .catch((error) => Errors(error))
      .finally(() => setAddLoading(false));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Kur Ekle</DialogTitle>
      <br />
      <DialogContent>
        <form
          onSubmit={handleAddExchangeRate}
          style={{ display: "flex", flexDirection: "column", gap: "var(--gap2x)" }}
        >
          <FormControl size="small" fullWidth>
            <InputLabel id="select-label">Kur Tipi</InputLabel>
            <Select
              labelId="select-label"
              id="select"
              value={rateType}
              label="Kur Tipi"
              onChange={(e) => setRateType(e.target.value)}
            >
              <MenuItem value={"MB"}>Merkez Bankası</MenuItem>
              <MenuItem value={"IYZI"}>iyziShip</MenuItem>
              <MenuItem value={"GARANTI"}>Garanti Bankası</MenuItem>
            </Select>
          </FormControl>
          <FormControl size="small" fullWidth>
            <InputLabel id="select-label">Para Birimi</InputLabel>
            <Select
              labelId="select-label"
              id="select"
              value={currency}
              label="Para Birimi"
              onChange={(e) => setCurrency(e.target.value)}
            >
              <MenuItem value={"USD"}>({Currency("USD")}) Dolar</MenuItem>
              <MenuItem value={"EUR"}>({Currency("EUR")}) Euro</MenuItem>
              <MenuItem value={"GBP"}>({Currency("GBP")}) Pound</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            size="small"
            type="date"
            label="Kur Tarihi"
            value={rateDate}
            onChange={(e) => setRateDate(e.target.value)}
          />
          <TextField
            fullWidth
            size="small"
            label="Alış Kuru"
            value={buyRate}
            onChange={handleChange}
            name="buy_rate"
          />
          <TextField
            fullWidth
            size="small"
            label="Satış Kuru"
            value={sellRate}
            onChange={handleChange}
            name="sell_rate"
          />
          <DialogActions>
            <Button type="button" className="iyzi-button-gray" onClick={handleClose}>
              İptal
            </Button>
            {addLoading ? (
              <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
            ) : (
              <Button type="submit" className="iyzi-button-blue">
                Ekle
              </Button>
            )}
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddExchangeRate;
