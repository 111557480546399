import "../../../../style/globals/Globals.css";
import {
  Button,
  Checkbox, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import MessageTime from "../../../../utils/MessageTime";
import LocalPrice from "../../../../utils/LocalPrice";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useCallback, useState } from "react";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import {
  financeTazminIadeDataState,
  financeTazminIadeFilesModalState,
  financeTazminIadeResponseItemState,
  financeTazminIadeResponseModalState,
  selectedTazminIadeFilesState,
} from "../../../../atoms/finance";
import { useRecoilState } from "recoil";
import { Info } from "@mui/icons-material";
import Errors from "../../../../utils/Errors";

const FinanceTazminIadeTableBody = ({ item }) => {
  const [financeTazminIadeData, setFinanceTazminIadeData] = useRecoilState(
    financeTazminIadeDataState
  );
  const [, setFinanceTazminIadeFilesModal] = useRecoilState(financeTazminIadeFilesModalState);
  const [, setSelectedTazminIadeFiles] = useRecoilState(selectedTazminIadeFilesState);
  const [, setFinanceTazminIadeResponseModal] = useRecoilState(financeTazminIadeResponseModalState);
  const [, setFinanceTazminIadeResponseItem] = useRecoilState(financeTazminIadeResponseItemState);

  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [textTitle, setTextTitle] = useState("");
  const [textContent, setTextContent] = useState("");
  const [textStatus, setTextStatus] = useState(null);
  const [rejectReason, setRejectReason] = useState("");
  const [approvalAmount, setApprovalAmount] = useState(item.member_payable_amount);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [rejectStatus, setRejectStatus] = useState("Reject");

  const handleApprove = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`refund-compensation/approve-decline/${item?.id}`, {
        type: "approve",
        member_payable_amount: Number(approvalAmount), // İade Edilecek Tutar Admin Tarafından Girilen
      })
      .then((response) => {
        toast.success(response.data.message);
        setFinanceTazminIadeData([
          ...financeTazminIadeData.filter((e) => e.id !== item.id),
          response.data.data,
        ]);
        setLoading(false);
      })
      .catch((error) => Errors(error))
      .finally(() => {
        handleDialogClose();
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      });
  };

  const handleReject = (e) => {
    e.preventDefault();
    setLoading(true);

    const data =
      rejectStatus === "CorrectionNeeded"
        ? {
            type: "decline",
            response_description: rejectReason,
            rejected_files: rejectedFiles.toLocaleString(),
            status: 4.1,
          }
        : {
            type: "decline",
            response_description: rejectReason,
            status: 4,
          };

    axios
      .post(`refund-compensation/approve-decline/${item?.id}`, data)
      .then((response) => {
        setFinanceTazminIadeData([
          response.data.data,
          ...financeTazminIadeData.filter((e) => e.id !== item?.id),
        ]);
        toast.success(response.data.message);
        setLoading(false);
      })
      .catch((error) => Errors(error))
      .finally(() => {
        handleDialogClose();
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      });
  };

  const handleRejectFiles = useCallback(
    (e) => {
      if (e.target.name === "file") {
        if (rejectedFiles.includes("member_consent_file")) {
          setRejectedFiles(rejectedFiles.filter((e) => e !== "member_consent_file"));
        } else {
          setRejectedFiles([...rejectedFiles, "member_consent_file"]);
        }
      } else if (e.target.name === "invoice") {
        if (rejectedFiles.includes("member_invoice_file")) {
          setRejectedFiles(rejectedFiles.filter((e) => e !== "member_invoice_file"));
        } else {
          setRejectedFiles([...rejectedFiles, "member_invoice_file"]);
        }
      }
    },
    [rejectedFiles]
  );

  const handleRejectStatus = useCallback((e) => {
    setRejectStatus(e.target.value);
  }, []);

  const isAnyCheckboxChecked = () => {
    return (
      rejectedFiles.includes("member_consent_file") || rejectedFiles.includes("member_invoice_file")
    );
  };

  const handleDialogOpen = (e) => {
    setTextStatus(e);

    if (e === 1) {
      setTextTitle("Onaylama İsteği");
      setTextContent("Tazmin iade talebini onaylamak istediğinize emin misiniz?");
      setDialog(true);
    } else if (e === 2) {
      setTextTitle("Reddetme İsteği");
      setTextContent("Tazmin iade talebini reddetmek istediğinize emin misiniz?");
      setDialog(true);
    } else if (e === 3) {
      setFinanceTazminIadeResponseModal(true);
      setFinanceTazminIadeResponseItem(item);
    }
  };

  const handleDialogClose = () => {
    setDialog(false);
    setApprovalAmount("");
    setRejectedFiles([]);
  };

  const handleChangeRejectReason = (e) => {
    setRejectReason(e.target.value);
  };

  const handleApprovalAmount = (e) => {
    const value = e.target.value.replace(",", ".");

    if (value === "" || /^\d*([.]\d{0,2})?$/.test(value)) {
      setApprovalAmount(value);
    }
  };

  const handleBlurApprovalAmount = (e) => {
    const value = e.target.value.replace(",", ".");

    if (Number(value) > Number(item?.member_payable_amount)) {
      setApprovalAmount(Number(item?.member_payable_amount).toFixed(2));
    } else {
      setApprovalAmount(value);
    }
  };

  const handleFileModalOpen = useCallback(() => {
    setFinanceTazminIadeFilesModal(true);
    setSelectedTazminIadeFiles({
      member_consent_file: item?.member_consent_file
        ? [
            {
              file_path: item?.member_consent_file,
              created_at: item?.created_at,
            },
          ]
        : [],
      member_invoice_file: item?.member_invoice_file
        ? [
            {
              file_path: item?.member_invoice_file,
              created_at: item?.created_at,
            },
          ]
        : [],
    });
  }, [setFinanceTazminIadeFilesModal, setSelectedTazminIadeFiles, item]);

  return (
    <>
      <Dialog
        open={dialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "var(--wrapperBorderRadius)" }}
      >
        <DialogTitle id="alert-dialog-title">{textTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{textContent}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "8px 20px 20px" }}>
          <form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "var(--gap)",
            }}
            onSubmit={textStatus === 1 ? handleApprove : handleReject}
          >
            {textStatus === 2 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "var(--gap)",
                }}
              >
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Reddetme Sebebi</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={rejectStatus}
                    label="Reddetme Seb"
                    onChange={handleRejectStatus}
                  >
                    <MenuItem value={"Reject"}>Reddedildi</MenuItem>
                    <MenuItem value={"CorrectionNeeded"}>Hatalı Dosya</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Reddetme Nedeni"
                  value={rejectReason}
                  onChange={handleChangeRejectReason}
                  fullWidth
                  required
                  size="small"
                  autoComplete="off"
                />
              </div>
            )}
            {rejectStatus === "CorrectionNeeded" && (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={rejectedFiles.find((e) => e === "member_consent_file")}
                      name="file"
                      onChange={handleRejectFiles}
                    />
                  }
                  label="Muvafakatname"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value={rejectedFiles.find((e) => e === "member_invoice_file")}
                      name="invoice"
                      onChange={handleRejectFiles}
                    />
                  }
                  label="Fatura"
                />
              </div>
            )}
            {textStatus === 1 && (
              <TextField
                label="Onaylanan Tutar"
                value={approvalAmount}
                onChange={handleApprovalAmount}
                onBlur={handleBlurApprovalAmount}
                fullWidth
                required
                size="small"
                autoComplete="off"
              />
            )}
            {loading ? (
                <div
                    style={{
                      width: "68px",
                      height: "33px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                >
                  <CircularProgress
                      sx={{
                        width: "24px!important",
                        height: "24px!important",
                      }}
                  />
                </div>
            ) : (
                <div
                    style={{
                      display: "flex",
                      gap: "var(--gap2x)",
                      justifyContent: "flex-end",
                    }}
                >
                  <Button type="button" className="iyzi-button" onClick={handleDialogClose}>
                    Hayır
                  </Button>
                  <Button
                      className="iyzi-button"
                      type="submit"
                      disabled={rejectStatus === "CorrectionNeeded" ? !isAnyCheckboxChecked() : false}
                      sx={{":disabled": {opacity: "0.4"}}}
                  >
                    Eminim
                  </Button>
                </div>
            )}
          </form>
        </DialogActions>
      </Dialog>
      <TableRow
        className={
          Number(item.status) === 4 || Number(item.status) === 4.1
            ? "borderless-tablerow-reject borderless2"
            : ""
        }
        sx={{
          ":hover": {
            backgroundColor: "var(--pastelBlue)",
            transition: "all 150ms linear",
          },
        }}
      >
        <TableCell>{item?.member_id}</TableCell>
        <TableCell>{item?.get_member?.name}</TableCell>
        <TableCell>{MessageTime(item?.created_at)}</TableCell>
        <TableCell>{item?.member_invoice_no === null ? "---" : item?.member_invoice_no}</TableCell>
        <TableCell>{LocalPrice(item?.member_payable_amount)} ₺</TableCell>
        <TableCell>
          {(item?.member_invoice_file?.length === 0 || item?.member_invoice_file === null) &&
          (item?.member_consent_file?.length === 0 || item?.member_consent_file === null) ? (
            "---"
          ) : (
            <Button
              sx={{
                borderRadius: "50%",
                minWidth: "33px",
                width: "33px",
                height: "33px",
              }}
              onClick={handleFileModalOpen}
            >
              <InsertDriveFileOutlinedIcon />
            </Button>
          )}
        </TableCell>
        <TableCell>
          <div
            className={`status tanzim${Number(item?.status) === 4.1 ? 4 : Number(item?.status)}`}
          >
            {Number(item?.status) === 0
              ? "Destek Hattı"
              : Number(item?.status) === 1
              ? "Müşteriden Cevap Bekleniyor"
              : Number(item?.status) === 2
              ? "Beklemede"
              : Number(item?.status) === 3
              ? "Onaylandı"
              : Number(item?.status) === 4
              ? "Reddedildi"
              : Number(item?.status) === 4.1
              ? "Hatalı Dosya"
              : ""}
          </div>
        </TableCell>
        <TableCell>
          {Number(item?.status) === 0 && (
            <div style={{ display: "flex", gap: "var(--gap)" }}>
              <Button onClick={() => handleDialogOpen(3)} className="iyzi-button-blue">
                Cevapla
              </Button>
            </div>
          )}
          {Number(item?.status) === 2 && (
            <div style={{ display: "flex", gap: "var(--gap)" }}>
              <Button onClick={() => handleDialogOpen(1)} className="iyzi-button-green">
                Onayla
              </Button>
              <Button onClick={() => handleDialogOpen(2)} className="iyzi-button-red">
                Reddet
              </Button>
            </div>
          )}
        </TableCell>
      </TableRow>
      {(Number(item.status) === 4 || Number(item.status) === 4.1) && (
        <TableRow cl>
          <TableCell colSpan={9} sx={{}}>
            <div
              style={{
                fontSize: "inherit",
                fontWeight: 700,
                color: "var(--shipmentRed)",
                backgroundColor: "var(--pastelRed)",
                width: "fit-content",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                padding: "5px 10px",
                borderRadius: "var(--wrapperBorderRadius)",
                boxShadow: "var(--shadow)",
              }}
            >
              <Info sx={{ color: "var(--error)", width: "18px", height: "18px" }} />
              İptal Nedeni:{" "}
              <span
                style={{
                  fontSize: "inherit",
                  fontWeight: 400,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {item.response_description}
              </span>
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default FinanceTazminIadeTableBody;
