import { Table, TableBody, TableContainer } from "@mui/material";
import { useRecoilValue } from "recoil";
import { notificationListState } from "../../../atoms/announcement";
import AnnouncementTableBody from "./announcementTable/AnnouncementTableBody";
import AnnouncementTableHead from "./announcementTable/AnnouncementTableHead";

const AnnouncementTable = () => {
  const notificationList = useRecoilValue(notificationListState);
  return (
    <TableContainer className="announcement-list-table-container">
      <Table className="announcement-list-table">
        <AnnouncementTableHead />
        <TableBody>
          {notificationList.length !== 0 &&
            notificationList.map((not, key) => (
              <AnnouncementTableBody key={key} not={not} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AnnouncementTable;
