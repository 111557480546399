import "../../../style/sidebar/Navbar.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import { useRecoilValue } from "recoil";
import { drawerOpenState } from "../../../atoms/viewPadding";
import ProfileDetails from "../navbar/ProfileDetails";
import NavLinks from "../navbar/NavLinks";
import NavDrawerHeader from "../navbar/NavDrawerHeader";
import NavMiniLogo from "../navbar/NavMiniLogo";

const drawerWidth = 265;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "0px", 
  justifyContent: "space-between",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "drawerOpen",
})(({ theme, drawerOpen }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(drawerOpen && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!drawerOpen && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const NavBar = () => {
  const drawerOpen = useRecoilValue(drawerOpenState);

  return (
    <Box className="navbar">
      <Drawer
        className="navbar-drawer"
        variant="permanent"
        drawerOpen={drawerOpen}
      >
        <NavDrawerHeader />
        <NavMiniLogo />
        <ProfileDetails />
        <NavLinks />
      </Drawer>
    </Box>
  );
};

export default NavBar;
