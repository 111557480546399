import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import Currency from "../../../../utils/CurrencySymbol";
import { FormatReturnFieldWithCustomDecimals } from "../../../../utils/FormatFieldReturn";
import Errors from "../../../../utils/Errors";
import axios from "../../../../api/axios";

const EditExchangeRate = ({ data, setData, open, setOpen, item }) => {
  const [editLoading, setEditLoading] = useState(false);
  const [rateType, setRateType] = useState(item?.rate_type);
  const [currency, setCurrency] = useState(item?.currency);
  const [rateDate, setRateDate] = useState(item?.rate_date);
  const [buyRate, setBuyRate] = useState(item?.buy_rate);
  const [sellRate, setSellRate] = useState(item?.sell_rate);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "sell_rate") {
      setSellRate(FormatReturnFieldWithCustomDecimals(value, 4));
    } else if (name === "buy_rate") {
      setBuyRate(FormatReturnFieldWithCustomDecimals(value, 4));
    }
  };

  const handleEditExchangeRate = (e) => {
    if (e) e.preventDefault();

    setEditLoading(true);
    axios
      .post(`exchange-rate/update/${item?.id}`, {
        rate_type: rateType,
        currency: currency,
        rate_date: item?.rate_date === rateDate ? null : rateDate,
        buy_rate: parseFloat(buyRate),
        sell_rate: parseFloat(sellRate),
      })
      .then((response) => {
        toast.success(response.data?.message);
        handleClose();
        const updatedItem = data.map((e) => {
          if (e.id === item.id) {
            return {
              ...e,
              rate_type: rateType,
              currency: currency,
              rate_date: rateDate,
              buy_rate: parseFloat(buyRate),
              sell_rate: parseFloat(sellRate),
            };
          }
          return e;
        });

        setData(updatedItem);
      })
      .catch((error) => Errors(error))
      .finally(() => setEditLoading(false));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Kur Düzenle</DialogTitle>
      <br />
      <DialogContent>
        <form
          onSubmit={handleEditExchangeRate}
          style={{ display: "flex", flexDirection: "column", gap: "var(--gap2x)" }}
        >
          <FormControl size="small" fullWidth>
            <InputLabel id="select-label">Kur Tipi</InputLabel>
            <Select
              labelId="select-label"
              id="select"
              value={rateType}
              label="Kur Tipi"
              onChange={(e) => setRateType(e.target.value)}
            >
              <MenuItem value={"MB"}>Merkez Bankası</MenuItem>
              <MenuItem value={"IYZI"}>iyziShip</MenuItem>
              <MenuItem value={"GARANTI"}>Garanti Bankası</MenuItem>
            </Select>
          </FormControl>
          <FormControl size="small" fullWidth>
            <InputLabel id="select-label">Para Birimi</InputLabel>
            <Select
              labelId="select-label"
              id="select"
              value={currency}
              label="Para Birimi"
              onChange={(e) => setCurrency(e.target.value)}
            >
              <MenuItem value={"USD"}>({Currency("USD")}) Dolar</MenuItem>
              <MenuItem value={"EUR"}>({Currency("EUR")}) Euro</MenuItem>
              <MenuItem value={"GBP"}>({Currency("GBP")}) Pound</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            size="small"
            type="date"
            label="Kur Tarihi"
            value={rateDate}
            onChange={(e) => setRateDate(e.target.value)}
          />
          <TextField
            fullWidth
            size="small"
            label="Alış Kuru"
            value={buyRate}
            onChange={handleChange}
            name="buy_rate"
          />
          <TextField
            fullWidt
            size="small"
            label="Satış Kuru"
            value={sellRate}
            onChange={handleChange}
            name="sell_rate"
          />
          <DialogActions>
            <Button type="button" className="iyzi-button-gray" onClick={handleClose}>
              İptal
            </Button>
            {editLoading ? (
              <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
            ) : (
              <Button type="submit" className="iyzi-button-blue">
                Düzenle
              </Button>
            )}
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditExchangeRate;
