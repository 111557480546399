import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "../../../../api/axios";
import { useState } from "react";
import Currency from "../../../../utils/CurrencySymbol";
import { toast } from "react-toastify";
import Errors from "../../../../utils/Errors";

const DetailSearchExchangeRate = ({ setData, setCurrentPage, setTotalPages }) => {
  const [rateType, setRateType] = useState("");
  const [currency, setCurrency] = useState("");
  const [rateDate, setRateDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);

  const handleDetailedSearch = (e) => {
    if (e) e.preventDefault();

    setLoading(true);
    axios
      .get(`exchange-rate`, {
        params: {
          rate_type: rateType,
          currency: currency,
          rate_date: rateDate,
          detailed: 1,
        },
      })
      .then((response) => {
        setData(response.data?.data);
        setCurrentPage(response.data?.currentPage);
        setTotalPages(response.data?.totalPages);
        toast.success(response.data?.message);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  const getData = () => {
    setClearLoading(true);
    axios
      .get(`exchange-rate`)
      .then((response) => {
        setData(response.data.data);
        setCurrentPage(response.data.currentPage);
        setTotalPages(response.data.totalPages);
        toast.success(response.data?.message);
        setRateType("");
        setCurrency("");
        setRateDate(null);
      })
      .catch((error) => Errors(error))
      .finally(() => setClearLoading(false));
  };

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "var(--gap2x)",
        marginTop: "var(--gap2x)",
      }}
      onSubmit={handleDetailedSearch}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gap: "var(--gap2x)",
        }}
      >
        <FormControl size="small" fullWidth>
          <InputLabel id="select-label">Kur Tipi</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            value={rateType}
            label="Kur Tipi"
            onChange={(e) => setRateType(e.target.value)}
          >
            <MenuItem value={""}>
              <em>Hepsi</em>
            </MenuItem>
            <MenuItem value={"MB"}>Merkez Bankası</MenuItem>
            <MenuItem value={"IYZI"}>iyziShip</MenuItem>
            <MenuItem value={"GARANTI"}>Garanti Bankası</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" fullWidth>
          <InputLabel id="select-label">Para Birimi</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            value={currency}
            label="Para Birimi"
            onChange={(e) => setCurrency(e.target.value)}
          >
            <MenuItem value={""}>
              <em>Hepsi</em>
            </MenuItem>
            <MenuItem value={"USD"}>({Currency("USD")}) Dolar</MenuItem>
            <MenuItem value={"EUR"}>({Currency("EUR")}) Euro</MenuItem>
            <MenuItem value={"GBP"}>({Currency("GBP")}) Pound</MenuItem>
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormControl size="small" component="fieldset" fullWidth>
            <DatePicker
              label="Kur Tarihi"
              value={rateDate}
              onChange={(newValue) => {
                // Eğer yeni değer geçerli bir tarih değilse, rateDate'i boş yap
                if (!newValue || newValue.toString() === "Invalid Date") {
                  setRateDate("");
                } else {
                  // Aksi takdirde, yeni değeri formatlayıp ayarla
                  setRateDate(newValue.format("YYYY-MM-DD"));
                }
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
              mask="____-__-__" // Use a mask for date format (YYYY-MM-DD) - oppo
              inputFormat="YYYY-MM-DD" // Set the input format to "YYYY-MM-DD" - oppo
            />
          </FormControl>
        </LocalizationProvider>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "var(--gap)",
        }}
      >
        {loading ? (
          <div
            style={{
              width: "110px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
          </div>
        ) : (
          <Button
            type="submit"
            className="iyzi-button-blue"
            style={{ fontSize: "13px", width: "110px" }}
          >
            <TravelExploreOutlinedIcon /> Detaylı Ara
          </Button>
        )}
        {clearLoading ? (
          <div
            style={{
              width: "90px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
          </div>
        ) : (
          <Button
            type="button"
            onClick={getData}
            className="iyzi-button-gray"
            style={{ fontSize: "13px", gap: "5px", width: "90px" }}
          >
            <CancelOutlinedIcon /> Temizle
          </Button>
        )}
      </div>
    </form>
  );
};

export default DetailSearchExchangeRate;
