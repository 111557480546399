import React, { useState, useEffect, useCallback } from "react";
import {
  TextField,
  Button,
  Box,
  Card,
  CardContent,
  Collapse,
  createTheme,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MyShipmentsDetailedSearch from "./MyShipmentsDetailedSearch";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "../../../api/axios";
import Errors from "../../../utils/Errors";
import { useLocation, useNavigate } from "react-router-dom";
import {
  awbState,
  basicSearchErrorState,
  basicSearchState,
  detailedStatusState,
  errorSetState,
  myShipmentListLoadingState,
  myShipmentListState,
  myShipmentsListLimitState,
  receiverCountryCodeState,
  receiverState,
  refIdState,
  selectedDomesticStatusState,
  selectedCaseStatusState,
  selectedExportStatusState,
  selectedPriceStatusState,
  selectedSupplierState,
  senderCountryCodeState,
  senderState,
  shipmentListOrderDirectionState,
  basicSearchArrayState,
  shipmentStatusState,
  basicFieldArrayState,
  timeState,
  timeStartState,
  timeEndState,
  totalPagesState,
  totalItemsState,
  selectedTrackingStatusState,
  selectedSearchDateTypeState
} from "../../../atoms/myShipments";
import { searchAdminBakiyeIdState } from "../../../atoms/finance";

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          boxShadow: "none",
        },
      },
    },
  },
});

const MyShipmentsSearch = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const awbParam = searchParams.get("memberId");

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [shipmentStatus, setShipmentStatus] =
    useRecoilState(shipmentStatusState);
  const [basicSearch, setBasicSearch] = useRecoilState(basicSearchState);
  const [myShipmentList, setMyShipmentList] =
    useRecoilState(myShipmentListState);
  const [, setMyShipmentListLoading] = useRecoilState(
    myShipmentListLoadingState
  );
  const [, setDetailedStatus] = useRecoilState(detailedStatusState);
  const [, setTotalPages] = useRecoilState(totalPagesState);
  const [basicSearchError, setBasicSearchError] = useRecoilState(
    basicSearchErrorState
  );
  const [, setTotalItems] = useRecoilState(totalItemsState);
  const [errorSet, setErrorSet] = useRecoilState(errorSetState);

  const myShipmentsListLimit = useRecoilValue(myShipmentsListLimitState);
  const shipmentOrderDirectionPage = useRecoilValue(
    shipmentListOrderDirectionState
  );
  const awb = useRecoilValue(awbState);
  const refId = useRecoilValue(refIdState);
  const sender = useRecoilValue(senderState);
  const receiver = useRecoilValue(receiverState);
  const time = useRecoilValue(timeState);
  const timeStart = useRecoilValue(timeStartState);
  const timeEnd = useRecoilValue(timeEndState);
  const senderCountryCode = useRecoilValue(senderCountryCodeState);
  const selectedTrackingStatus = useRecoilValue(selectedTrackingStatusState);
  const receiverCountryCode = useRecoilValue(receiverCountryCodeState);
  const selectedSupplier = useRecoilValue(selectedSupplierState);
  const selectedPriceStatus = useRecoilValue(selectedPriceStatusState);
  const selectedExportStatus = useRecoilValue(selectedExportStatusState);
  const selectedDomesticStatus = useRecoilValue(selectedDomesticStatusState);
  const selectedCaseStatus = useRecoilValue(selectedCaseStatusState);
  const [selectedSearchDateType, setSelectedSearchDateType] = useRecoilState(
    selectedSearchDateTypeState
  );

  const [searchAdminBakiyeId, setSearchAdminBakiyeId] = useRecoilState(
    searchAdminBakiyeIdState
  );

  const [basicSearchArray, setBasicSearchArray] = useRecoilState(
    basicSearchArrayState
  );
  const basicFieldArray = useRecoilValue(basicFieldArrayState);

  const [bulkSearchInput, setBulkSearchInput] = useState("");
  const [bulkSearchArray, setBulkSearchArray] = useState([]);

  const handleBasicSearch = useCallback(async () => {
    if (basicSearch.length > 0) {
      setMyShipmentListLoading(true);
      setDetailedStatus(0);

      try {
        const response = await axios.get(
          `parcel?order_direction=${shipmentOrderDirectionPage}&limit=${myShipmentsListLimit}&page=${1}&search=${basicSearch}`
        );
        setMyShipmentList(response.data.data);
        setTotalPages(response.data.totalPages);
        setTotalItems(response.data.totalItems);
        if (basicSearch === "") {
          setErrorSet(true);
          setBasicSearchError(false);
        }
      } catch (error) {
        Errors(error);
      } finally {
        setMyShipmentListLoading(false);
      }
    } else {
      const parcelUrlBase = "parcel";
      const parcelUrlParams = {
        order_direction: shipmentOrderDirectionPage,
        limit: myShipmentsListLimit,
        page: 1,
        where_in_field: basicFieldArray,
      };

      try {
        const params = { ...parcelUrlParams };
        bulkSearchArray.forEach((item, index) => {
          params[`where_in_array[${index}]`] = item.trim();
        });

        const response = await axios.get(parcelUrlBase, {
          params: params,
        });

        setMyShipmentList(response.data.data);
        setTotalPages(response.data.totalPages);
        setTotalItems(response.data.totalItems);
      } catch (error) {
        Errors(error);
      }
    }
  }, [
    setTotalPages,
    setMyShipmentList,
    setMyShipmentListLoading,
    setDetailedStatus,
    setBasicSearchError,
    setErrorSet,
    setTotalItems,
    myShipmentsListLimit,
    shipmentOrderDirectionPage,
    basicSearch,
    basicFieldArray,
    bulkSearchInput,
    bulkSearchArray,
  ]);

  const handleClearBasicSearch = () => {
    setBasicSearch("");
    setSearchAdminBakiyeId("");
    navigate("/my-shipment");
  };

  console.log(bulkSearchArray, "bulkSearchArray");

  const handleDetailedSearch = useCallback(
    async (shipmentStatusValue) => {
      setMyShipmentListLoading(true);
      setDetailedStatus(1);

      const params = {
        order_direction: shipmentOrderDirectionPage,
        limit: myShipmentsListLimit,
        tracking_awb: awb,
        parcel_ref_no: refId,
        supplier_id:
          selectedSupplier !== "" ? Number(selectedSupplier) : selectedSupplier,
        status: shipmentStatusValue,
        date_search_type: selectedSearchDateType,
        created_at: time,
        from_date: timeStart,
        has_tracking_awb: selectedTrackingStatus,
        to_date: timeEnd,
        sender_name: sender,
        receiver_name: receiver,
        sender_country: senderCountryCode,
        receiver_country: receiverCountryCode,
        parcel_type: selectedExportStatus,
        paid: selectedPriceStatus,
        is_domestic: selectedDomesticStatus,
        is_case: selectedCaseStatus,
        member_id: searchAdminBakiyeId,
        detailed: 1,
      };

      try {
        const response = await axios.get(`parcel`, { params: params });
        setMyShipmentList(response.data.data);
        setTotalPages(response.data.totalPages);
        setTotalItems(response.data.totalItems);
        setErrorSet(true);
        setBasicSearchError(false);
      } catch (error) {
        Errors(error);
      } finally {
        setMyShipmentListLoading(false);
      }
    },
    [
      setMyShipmentList,
      setMyShipmentListLoading,
      setDetailedStatus,
      setTotalPages,
      setTotalItems,
      setErrorSet,
      setBasicSearchError,
      awb,
      time,
      timeStart,
      timeEnd,
      shipmentOrderDirectionPage,
      myShipmentsListLimit,
      refId,
      selectedSupplier,
      sender,
      senderCountryCode,
      receiver,
      receiverCountryCode,
      selectedExportStatus,
      selectedDomesticStatus,
      selectedCaseStatus,
      selectedPriceStatus,
      searchAdminBakiyeId,
      selectedTrackingStatus,
      selectedSearchDateType,
    ]
  );

  const handleBulkSearchInputChange = (event) => {
    const inputValue = event.target.value;
    let searchItems = [];
    let currentItem = "";
    let isInTRSection = false;

    for (let i = 0; i < inputValue.length; i++) {
      const char = inputValue[i];

      if (char === ',') {
        if (isInTRSection && currentItem.length > 0) {
          searchItems.push(currentItem);
          currentItem = "";
          isInTRSection = false;
        }
      } else if (isInTRSection) {
        currentItem += char;
        if (currentItem.length === 12) {
          searchItems.push(currentItem);
          currentItem = "";
          isInTRSection = false;
        }
      } else if (char === 'T' && inputValue.substr(i, 2) === 'TR') {
        currentItem += 'T';
        isInTRSection = true;
      }
    }

    const joinedValue = searchItems.join(",");
    setBulkSearchInput(inputValue);
    setBulkSearchArray(searchItems);
  };

  const handleBulkSearchAdd = (searchItems) => {
    if (searchItems?.length > 0) {
      setBulkSearchArray((prevArray) => [...prevArray, ...searchItems]);
    }
  };

  useEffect(() => {
    if (myShipmentList.length === 0 && !errorSet) {
      setBasicSearchError(true);
    }

    if (myShipmentList.length !== 0) {
      setErrorSet(false);
    }
  }, [myShipmentList, errorSet, setBasicSearchError, setErrorSet]);

  useEffect(() => {
    if (awbParam) {
      setExpanded(true);
      handleDetailedSearch();
    }
  }, []);

  useEffect(() => {
    handleBulkSearchAdd();
    setSelectedSearchDateType("");
  }, []);

  useEffect(() => {
    if (basicSearch.length > 0) {
      handleBasicSearch();
    }
  }, [basicSearch]);

  return (
    <ThemeProvider theme={theme}>
      <Box className="myShipments-search">
        <Card className="search-card">
          <CardContent className="search-card-content">
            <Box sx={{ display: "flex", gap: "var(--gap)", width: "100%" }}>
              <TextField
                className="search-card-content-search"
                label="Gönderi Arama"
                value={basicSearch}
                error={basicSearchError}
                autoComplete="off"
                helperText={
                  basicSearchError
                    ? "Arama kriterlerinizi değiştirip yeniden deneyiniz."
                    : ""
                }
                onChange={(e) => setBasicSearch(e.target.value.slice(0, 12))}
                onKeyDown={(e) =>
                  e.key === "Enter"
                    ? handleBasicSearch()
                    : e.key === "Escape"
                      ? handleClearBasicSearch()
                      : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                size="small"
              />
              <TextField
                label="Toplu Gönderi Arama"
                value={bulkSearchInput} // Toplu arama girişini göstermek için bulkSearchInput'i kullan
                onChange={handleBulkSearchInputChange}
                variant="outlined"
                size="small"
                sx={{
                  width: "60%",
                }}
                onKeyDown={(e) =>
                  e.key === "Enter"
                    ? handleBasicSearch()
                    : e.key === "Escape"
                      ? handleClearBasicSearch()
                      : ""
                }
              />
            </Box>
            <Box sx={{ display: "flex", gap: "var(--gap)" }}>
              <FormControl
                className="search-card-content-status"
                size="small"
                sx={{ minWidth: 200 }}
              >
                <InputLabel
                  sx={{ fontSize: "14px" }}
                  id="detailed-search-label"
                >
                  Gönderi Durum Bilgisi
                </InputLabel>
                <Select
                  labelId="detailed-search-label"
                  id="detailed-search"
                  label="Gönderi Durum Bilgisi"
                  sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
                  value={shipmentStatus}
                  onChange={(e) => setShipmentStatus(e.target.value)}
                >
                  <MenuItem onClick={() => handleDetailedSearch("")} value={""}>
                    Tümü
                  </MenuItem>
                  <MenuItem onClick={() => handleDetailedSearch(0)} value={0}>
                    Gönderi Oluşturuldu
                  </MenuItem>
                  <MenuItem onClick={() => handleDetailedSearch(1)} value={1}>
                    iyziShip Merkezine Geliyor
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleDetailedSearch(2.16)}
                    value={2.6}
                  >
                    Ölçü Kontrolü Yapıldı
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleDetailedSearch(2.17)}
                    value={2.12}
                  >
                    Çıkış İçin Ödeme Bekleniyor
                  </MenuItem>
                  <MenuItem
                      onClick={() => handleDetailedSearch(2.18)}
                      value={2.18}
                  >
                    Çıkışa Hazır
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleDetailedSearch(3.01)}
                    value={3.01}
                  >
                    Ana Taşıyıcıya Teslim Edildi
                  </MenuItem>
                  <MenuItem onClick={() => handleDetailedSearch(4.01)} value={5}>
                    Taşımada
                  </MenuItem>
                  <MenuItem onClick={() => handleDetailedSearch(5)} value={6}>
                    Teslim Edildi
                  </MenuItem>
                </Select>
              </FormControl>
              <Button
                className="iyzi-button-blue"
                style={{ minWidth: "150px" }}
                onClick={handleExpandClick}
              >
                {expanded ? "Detaylı Aramayı Kapat" : "Detaylı Aramayı Aç"}
              </Button>
            </Box>
          </CardContent>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <MyShipmentsDetailedSearch />
            </CardContent>
          </Collapse>
        </Card>
      </Box>
    </ThemeProvider>
  );
};

export default MyShipmentsSearch;
