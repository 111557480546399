import { useEffect, useState } from "react"
import { Modal, ThemeProvider, createTheme, Box, TextField, Button } from "@mui/material";
import { useRecoilState } from "recoil";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import {
  memberNoteEditOpenState,
  memberListState
} from "../../../../atoms/members";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import Errors from "../../../../utils/Errors";

const modalTheme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: 20,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const MemberNoteEdit = () => {
  const [memberList, setMemberList] = useRecoilState(memberListState);
  const [memberNoteEditOpen, setMemberNoteEditOpen] = useRecoilState(
    memberNoteEditOpenState
  );

  const [myShipmentsNote, setMyShipmentsNote] = useState("");

  const sendNoteKey = async () => {
    try {
      const response = await axios.post(`member/update-member-note/${memberNoteEditOpen?.data.id}`, {
        member_note: myShipmentsNote
      });
      handleClose();
      // Gelen tüm verileri güncelleyerek listeyi yeniden oluşturma
      setMemberList((prevList) => {
        const updatedList = prevList.map((item) =>
          item.id === response.data.data.id
            ? { ...item, member_note: response.data.data.member_note }
            : item
        );

        return updatedList;
      });
      toast.success(response.data.message);
    } catch (error) {
      // Errors(error);
    }
  };

  const handleClose = () => {
    setMemberNoteEditOpen({ status: false, data: {} });
    setMyShipmentsNote("");
  };

  useEffect(() => {
    setMyShipmentsNote(memberNoteEditOpen?.data?.member_note);
  }, [memberNoteEditOpen]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Modal open={memberNoteEditOpen?.status} onClose={handleClose}>
        <Box
          sx={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            padding: "20px",
            outline: 0,
            display: "grid",
            gap: "var(--gap)",
            width: "100%",
            maxWidth: "1100px",
            margin: "auto",
            position: "relative",
          }}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              cursor: "pointer",
              color: "var(--priceText)",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleClose}
          />
          <h6
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              color: "var(--priceText)",
              marginBottom: "10px",
            }}
          >
            <NoteAddOutlinedIcon />
            Not Ekle
          </h6>
          <Box
            sx={{
              marginBottom: "var(--gap)",
              display: "grid",
              gap: "var(--gap2x)",
            }}
          >
            <div>
              <div>
                <div
                  style={{
                    paddingLeft: "var(--gap)",
                    fontSize: "14px",
                    color: "var(--lighterTextColor)",
                    paddingBottom: "4px",
                  }}
                >
                  {/* {note.created_at.slice(0, 10)} {note.created_at.slice(11, 19)} {" - "} */}
                  {/* {note.get_created_by?.name} {note.get_created_by?.surname} */}
                </div>
                <Box
                  sx={{
                    display: "grid",
                    gap: "var(--gap)",
                    marginTop: "var(--gap)",
                  }}
                >
                  {/* <TextField
                    defaultValue={note.description}
                    multiline
                    fullWidth
                    autoFocus
                    onFocus={(e) => {
                      let descValue = e.target.value;
                      e.target.value = "";
                      e.target.value = descValue;
                    }}
                    label="Notu Düzenle"
                  id={`update_note_${note.id}`}
                  onChange={() => updateNoteText(note)}
                  /> */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "var(--gap)",
                    }}
                  ></Box>
                </Box>
              </div>
            </div>
          </Box>
          <Box sx={{ display: "grid", gap: "var(--gap)" }}>
            <div>
              <h7>Yeni Not Ekle</h7>
            </div>
            <TextField
              value={myShipmentsNote}
              onChange={(e) => setMyShipmentsNote(e.target.value)}
              label="Notunuz"
              autoFocus
              onFocus={(e) => {
                let descValue = e.target.value;
                e.target.value = "";
                e.target.value = descValue;
              }}
              multiline
              rows={8}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
              }}
            >
              <Button
                onClick={sendNoteKey}
                sx={{
                  justifySelf: "flex-end",
                  backgroundColor: "var(--info)",
                  color: "var(--lighterBg)",
                  textTransform: "none",
                  transition: "all 150ms linear",
                  borderRadius: "var(--buttonBorderRadius)",
                  padding: "7px 16px",
                  ":hover": {
                    backgroundColor: "var(--blueBrand)",
                    transition: "all 150ms linear",
                  },
                }}
              >
                Gönder
              </Button>
              <Button
                onClick={handleClose}
                sx={{
                  justifySelf: "flex-end",
                  backgroundColor: "var(--info)",
                  color: "var(--lighterBg)",
                  textTransform: "none",
                  transition: "all 150ms linear",
                  borderRadius: "var(--buttonBorderRadius)",
                  padding: "7px 16px",
                  ":hover": {
                    backgroundColor: "var(--blueBrand)",
                    transition: "all 150ms linear",
                  },
                }}
              >
                İptal
              </Button>
            </Box>
          </Box>
        </Box>

      </Modal>
    </ThemeProvider>
  );
};

export default MemberNoteEdit;
