import NavBar from "../components/sidebar/NavBar";
import NotificationBar from "../components/sidebar/NotificationBar";
import { DynamicTitle } from "../../utils/DynamicTitle";
import { useCookies } from "react-cookie";
import axios from "../../api/axios";
import { drawerOpenState } from "../../atoms/viewPadding";
import { useRecoilValue } from "recoil";
import { useInView, animated } from "@react-spring/web";
import { ThemeProvider, createTheme } from "@mui/material";
import WrapperStyle from "../../utils/WrapperStyle";
import SystemSetting from "../components/system/SystemSetting";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const PriceChecks = () => {
  DynamicTitle("Sistem Ayarları - Admin");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const drawerOpen = useRecoilValue(drawerOpenState);

  const navUnder = {
    paddingLeft: drawerOpen ? "264px" : 0,
    transition: "all 150ms linear",
    backgroundColor: "var(--lighterBg)",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  };

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  return (
    <ThemeProvider theme={theme}>
      <NotificationBar />
      <NavBar />
      <section className="my-wallet">
        <div className="nav-under" style={navUnder}>
          <div>Sistem Ayarları</div>
        </div>
        <div className="my-wallet-wrapper" style={WrapperStyle()}>
          <animated.div ref={ref} style={springs}>
            <div className="my-wallet-container">
              <SystemSetting />
            </div>
          </animated.div>
        </div>
      </section>
    </ThemeProvider>
  );
};

export default PriceChecks;
