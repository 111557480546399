import { atom } from "recoil";

export const ffdDataState = atom({
  key: "ffdData",
  default: {},
});

export const ffdBase64State = atom({
  key: "ffdBase64",
  default: "",
});

export const ffdCheckWhereState = atom({
  key: "ffdCheckWhere",
  default: "dom",
});

export const ffdCheckDomState = atom({
  // domestik//
  key: "ffdCheckDom",
  default: true,
});

export const ffdCheckFrontDeskState = atom({
  //ön büro elden teslim
  key: "ffdCheckFrontDesk",
  default: false,
});

export const ffdCheckHandState = atom({
  //depo elden teslim
  key: "ffdCheckHand",
  default: false,
});

export const ffdCheckCourierState = atom({
  //kurye teslim
  key: "ffdCheckCourier",
  default: false,
});

export const ffdCheckedMultipleDomState = atom({
  key: "ffdCheckedMultipleDom",
  default: true,
});

export const ffdCheckedTagState = atom({
  key: "ffdCheckedTag",
  default: true,
});

export const ffdDomSupplierState = atom({
  // domestik tedarikci input//
  key: "ffdDomSupplier",
  default: "",
});
export const ffdDomAwbState = atom({
  // domestik awb input//
  key: "ffdDomAwb",
  default: "",
});

export const ffdManualDomWidth = atom({
  key: "ffdDomWidth",
  default: "",
});

export const ffdManualDomLength = atom({
  key: "ffdDomLength",
  default: "",
});

export const ffdManualDomHeight = atom({
  key: "ffdDomHeight",
  default: "",
});
export const ffdManualDomGw = atom({
  key: "ffdDomGw",
  default: "",
});

export const ffdManualDomSaveToDimension = atom({
  key: "ffdDomSaveToDimension",
  default: true,
});


export const ffdCheckManualDimensionState = atom({
  key: "ffdCheckManualDimension",
  default: true,
});

export const ffdIyziTeamDimemsionState = atom({
  key: "ffdIyziTeamDimemsion",
  default: [
    { width: "", length: "", height: "", gross: "", qty: "", id: 0 },
  ],
});

export const warehouseCheckedFrontDeskState = atom({
  //yük depo elden teslim seçildiginde çıkan "ön büro" buttonu
  key: "warehouseCheckedFrontDesk",
  default: false,
});



export const ffdCheckFrontInfoRadioState = atom({  //ÖN BÜRO RADİO INFO
  key: "ffdCheckFrontInfo",
  default: true,
});

export const cargoDomStatusAlertState = atom({  //ÖN BÜRO RADİO INFO
  key: "cargoDomStatusAlert",
  default: false,
});

export const ffdCheckSingleRXState = atom({  //TEKLİ GÖNDERİ RADİO
  key: "ffdCheckSingle",
  default: true,
});

export const ffdCheckMultipleRXState = atom({  //ÇOKLU GÖNDERİ RADİO
  key: "ffdCheckMultiple",
  default: false,
});

export const ffdCheckSearchRXState = atom({  // GÖNDERİ ARAMA RADİO
  key: "ffdCheckSearch",
  default: false,
});


export const ffdSearchParcelState = atom({  // GÖNDERİ ARAMA  2.01 KONTROL RADİOSU
  key: "ffdSearchCounter",
  default: false,
});

export const ffdAddDimensionsState = atom({  // GÖNDERİ ARAMA  2.01 KONTROL RADİOSU
  key: "ffdAddDimensions",
  default: false,
});

export const editedProformaState = atom({
  key:" editedProforma",
  default: false,
})


export const ffdSaveDomesticInfoState = atom({  //domestik bilgi kaydet veya etme
  key: "saveDomesticInfo",
  default: true,
});

export const ffdPakCostState = atom({  //Pak Maliyeti
  key: "PakCost",
  default: [
    { width: "", length: "", height: "", gross: "", qty: "", id: 0 },
  ],
});
