import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import taxData from "../../../../../json/FinanceGovermentTaxOffices.json";
import { useCallback, useState } from "react";
import axios from "../../../../../api/axios";
import Errors from "../../../../../utils/Errors";

const MemberInformationEditCompany = ({ member }) => {
  const [taxOfficeError, setTaxOfficeError] = useState(false);
  const [taxOfficeErrorMessage, setTaxOfficeErrorMessage] = useState("");
  const [memberTaxOffice, setMemberTaxOffice] = useState("");
  const [memberTaxOfficeCity, setMemberTaxOfficeCity] = useState("");
  const [taxNoError, setTaxNoError] = useState(false);
  const [memberCompanyName, setMemberCompanyName] = useState("");
  const [memberTaxNo, setMemberTaxNo] = useState("");

  const handleSearchTaxNo = useCallback(() => {
    if (memberTaxNo.length === 0) {
      setTaxNoError(true);
    }

    if (memberTaxOffice.length === 0) {
      setTaxOfficeError(true);
    }

    if (memberTaxNo.length === 0 || memberTaxOffice.length === 0) {
      setTaxOfficeError(true);
    } else {
      setTaxOfficeError(false);
      if (member.member_type !== "Company") {
        axios
          .post("verify-vkn", {
            type: "tckn",
            tax_office:
              memberTaxOffice.length === 4
                ? `00${memberTaxOffice}`
                : memberTaxOffice.length === 5
                ? `0${memberTaxOffice}`
                : "",
            tax_office_city: memberTaxOfficeCity,
            identity_no: memberTaxNo,
          })
          .then((response) => {
            setMemberCompanyName(response.data.result.unvan);
          })
          .catch((error) => {
            Errors(error);
            if (error.response.data.errors.tax_no[0].length !== 0) {
              setTaxOfficeErrorMessage(error.response.data.errors.tax_no[0]);
            } else {
              setTaxOfficeError(true);
            }
          });
      } else if (member.member_type === "Company") {
        axios
          .post("verify-vkn", {
            type: "vkn",
            tax_no: memberTaxNo,
            tax_office:
              memberTaxOffice.length === 4
                ? `00${memberTaxOffice}`
                : memberTaxOffice.length === 5
                ? `0${memberTaxOffice}`
                : "",
            tax_office_city: memberTaxOfficeCity,
          })
          .then((response) => {
            setMemberCompanyName(response.data.result.unvan);
          })
          .catch((error) => {
            Errors(error);
            if (error.response.data.errors.tax_no[0].length !== 0) {
              setTaxOfficeErrorMessage(error.response.data.errors.tax_no[0]);
            } else {
              setTaxOfficeError(true);
            }
          });
      }
    }
  }, [member, memberTaxNo, memberTaxOffice, memberTaxOfficeCity, setMemberCompanyName]);

  const taxHandler = useCallback(
    (e) => {
      setMemberTaxOffice(e.target.value);

      if (e.target.value.length === 4) {
        setMemberTaxOfficeCity(`00${e.target.value.slice(0, 1)}`);
      } else if (e.target.value.length === 5) {
        setMemberTaxOfficeCity(`0${e.target.value.slice(0, 2)}`);
      }
    },
    [setMemberTaxOffice, setMemberTaxOfficeCity]
  );

  return (
    <Box
      sx={{
        boxShadow: "var(--shadow)",
        padding: "20px",
        backgroundColor: "var(--lighterBg)",
        borderRadius: "var(--inputBorderRadius)",
        display: "grid",
        gap: "var(--gap2x)",
      }}
    >
      <Typography color={"var(--priceText)"} variant="h5">
        Firma Bilgileri{" "}
        <p
          style={{
            color: "var(--shipmentBlue)",
            display: "inline",
            fontSize: "inherit",
          }}
        >
          {member.member_type === "Company" ? "(Tüzel Firma)" : "Şahıs Firması"}
        </p>
      </Typography>
      <Box sx={{ display: "flex", gap: "var(--gap2x)" }}>
        <FormControl required size="small" fullWidth>
          <InputLabel sx={taxOfficeError ? { color: "#d32f2f" } : {}} id="select-country">
            Vergi Dairesi
          </InputLabel>
          <Select
            label="Vergi Dairesi"
            labelId="select-tax-county"
            value={memberTaxOffice}
            onChange={taxHandler}
            error={taxOfficeError || taxOfficeErrorMessage.length !== 0}
          >
            {taxData.map((e) => {
              return (
                <MenuItem value={e.vd_kodu} key={e.id}>
                  {e.vd_adi}
                </MenuItem>
              );
            })}
          </Select>
          {taxOfficeErrorMessage.length !== 0 ? (
            <FormHelperText sx={{ color: "#d32f2f" }}>{taxOfficeErrorMessage}</FormHelperText>
          ) : taxNoError ? (
            <FormHelperText sx={{ color: "#d32f2f" }}>
              Vergi dairesi seçilmek zorunda.
            </FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
        <TextField
          required
          fullWidth
          size="small"
          label={"Vergi No"}
          value={memberTaxNo}
          onChange={(e) => setMemberTaxNo(e.target.value)}
          onBlur={() => handleSearchTaxNo()}
          error={taxNoError || taxOfficeErrorMessage.length !== 0}
          helperText={
            taxNoError && taxOfficeErrorMessage.length === 0
              ? "Vergi numarası boş bırakılamaz."
              : taxOfficeErrorMessage.length !== 0
              ? taxOfficeErrorMessage
              : ""
          }
        />
        <TextField
          required
          fullWidth
          size="small"
          label="Firma Adı"
          disabled
          value={memberCompanyName}
          onChange={(e) => setMemberCompanyName(e.target.value)}
          helperText="Vergi numarası doğrulandığında bu alan otomatik dolacaktır."
        />
      </Box>
    </Box>
  );
};

export default MemberInformationEditCompany;
