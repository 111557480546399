const PDFReader = ({ base64String }) => {
  return (
    <object
      data={`data:application/pdf;base64,${base64String}`}
      type="application/pdf"
      style={{ height: "450px", width: "641px" }}
    > 
      <embed
        src={`data:application/pdf;base64,${base64String}`}
        type="application/pdf"
      />
    </object>
  );
};

export default PDFReader;
