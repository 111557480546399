import { Avatar } from "@mui/material";
import { Box } from "@mui/system";
import { useRecoilValue } from "recoil";
import { ticketState } from "../../../atoms/support";
import shipmentLogo_0 from "../../../img/myShipments/gonderilerim_0.svg";
import shipmentLogo_1 from "../../../img/myShipments/gonderilerim_1.svg";
import shipmentLogo_2 from "../../../img/myShipments/gonderilerim_2.svg";
import shipmentLogo_4 from "../../../img/myShipments/gonderilerim_4.svg";
import shipmentLogo_5 from "../../../img/myShipments/gonderilerim_5.svg";

const SupportDetailStepper = () => {
  const ticket = useRecoilValue(ticketState);
  return (
    <Box className="stepper-wrapper-css">
      <div className="step-wrapper-css">
        <div
          className="step-css"
          style={
            Number(ticket.get_parcel.status) === 0
              ? { backgroundColor: "var(--shipmentYellow)" }
              : { backgroundColor: "var(--shipmentGreen)" }
          }
        >
          <img
            src={shipmentLogo_0}
            className="step-logo-css"
            alt="Gönderi 1.Aşama Görseli"
          />
        </div>
      </div>
      <div className="step-wrapper-css">
        <div
          className="step-css"
          style={
            Number(ticket.get_parcel.status) === 1
              ? { backgroundColor: "var(--shipmentYellow)" }
              : Number(ticket.get_parcel.status) < 1
              ? { backgroundColor: "var(--shipmentLight)" }
              : { backgroundColor: "var(--shipmentGreen)" }
          }
        >
          <img
            src={shipmentLogo_1}
            className="step-logo-css"
            alt="Gönderi 2.Aşama Görseli"
          />
        </div>
      </div>
      <div className="step-wrapper-css">
        <div
          className="step-css"
          style={
            Number(ticket.get_parcel.status) === 2
              ? { backgroundColor: "var(--shipmentYellow)" }
              : Number(ticket.get_parcel.status) < 2
              ? { backgroundColor: "var(--shipmentLight)" }
              : { backgroundColor: "var(--shipmentGreen)" }
          }
        >
          <img
            src={shipmentLogo_2}
            className="step-logo-css"
            alt="Gönderi 3.Aşama Görseli"
          />
        </div>
      </div>
      <div className="step-wrapper-css">
        <div
          className="step-css"
          style={
            Number(ticket.get_parcel.status) === 3
              ? { backgroundColor: "var(--shipmentYellow)" }
              : Number(ticket.get_parcel.status) < 3
              ? { backgroundColor: "var(--shipmentLight)" }
              : { backgroundColor: "var(--shipmentGreen)" }
          }
        >
          <Avatar
            className="step-shipment-logo-css"
            src={`/images/${ticket.get_parcel?.get_parcel_supplier?.supplier_logo}`}
          />
        </div>
      </div>
      <div className="step-wrapper-css">
        <div
          className="step-css"
          style={
            Number(ticket.get_parcel.status) === 4
              ? { backgroundColor: "var(--shipmentYellow)" }
              : Number(ticket.get_parcel.status) < 4
              ? { backgroundColor: "var(--shipmentLight)" }
              : { backgroundColor: "var(--shipmentGreen)" }
          }
        >
          <img
            src={shipmentLogo_4}
            className="step-logo-css"
            alt="Gönderi 5.Aşama Görseli"
          />
        </div>
      </div>
      <div className="step-wrapper-css">
        <div
          className="step-css"
          style={
            Number(ticket.get_parcel.status) === 5
              ? { backgroundColor: "var(--shipmentYellow)" }
              : Number(ticket.get_parcel.status) < 5
              ? { backgroundColor: "var(--shipmentLight)" }
              : { backgroundColor: "var(--shipmentGreen)" }
          }
        >
          <img
            src={shipmentLogo_5}
            className="step-logo-css"
            alt="Gönderi 6.Aşama Görseli"
          />
        </div>
      </div>
      {/* <div className="step-connector-css">
        <div
          className={
            Number(ticket.get_parcel.status) >= 1 ? "step active" : "step1"
          }
        ></div>
        <div
          className={
            Number(ticket.get_parcel.status) >= 2 ? "step active" : "step2"
          }
        ></div>
        <div
          className={
            Number(ticket.get_parcel.status) >= 3 ? "step active" : "step3"
          }
        ></div>
        <div
          className={
            Number(ticket.get_parcel.status) >= 4 ? "step active" : "step4"
          }
        ></div>
        <div
          className={
            Number(ticket.get_parcel.status) >= 5 ? "step active" : "step5"
          }
        ></div>
      </div> */}
    </Box>
  );
};

export default SupportDetailStepper;
