import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import countries from "i18n-iso-countries";
import trLocale from "i18n-iso-countries/langs/tr.json";
import { useRecoilState } from "recoil";
import {
  memberCountryState,
  memberTypeState,
} from "../../../../../atoms/members";

const MemberInformationEditHeader = ({ member }) => {
  const [memberType, setMemberType] = useRecoilState(memberTypeState);
  const [memberCountry, setMemberCountry] = useRecoilState(memberCountryState);

  // Have to register the languages you want to use
  countries.registerLocale(trLocale);

  // Returns an object not a list
  const countryObj = countries.getNames("tr", { select: "official" });

  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  });

  return (
    <Box
      sx={{
        display: "flex",
        gap: "var(--gap2x)",
        backgroundColor: "var(--lighterBg)",
        borderRadius: "var(--inputBorderRadius)",
        boxShadow: "var(--shadow)",
        padding: "20px",
      }}
    >
      <FormControl required size="small" fullWidth>
        <InputLabel id="membership-type">Üyelik Tipi</InputLabel>
        <Select
          value={memberType === "" ? member.member_type : memberType}
          label="Üyelik Tipi"
          labelId="membership-type"
          onChange={(e) => setMemberType(e.target.value)}
        >
          <MenuItem value={"Company"}>Company</MenuItem>
          <MenuItem value={"Individual"}>Individual</MenuItem>
        </Select>
      </FormControl>
      <FormControl required size="small" fullWidth>
        <InputLabel id="select-country">Ülke</InputLabel>
        <Select
          value={memberCountry === "" ? member.identity_type : memberCountry}
          label="Ülke"
          labelId="select-country"
          onChange={(e) => setMemberCountry(e.target.value)}
        >
          <MenuItem value={""}>Temizle</MenuItem>
          {!!countryArr?.length &&
            countryArr.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default MemberInformationEditHeader;
