import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Chip,
  createTheme,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useState, useEffect } from "react";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import axios from "../../../../api/axios";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  ffdCheckDomState,
  ffdDataState,
  ffdBase64State,
  ffdDomSupplierState,
  ffdDomAwbState,
  ffdManualDomWidth,
  ffdManualDomLength,
  ffdManualDomHeight,
  ffdManualDomGw,
  ffdManualDomSaveToDimension,
  ffdCheckManualDimensionState,
  ffdCheckFrontDeskState,
  ffdCheckHandState,
  ffdCheckCourierState,
  warehouseCheckedFrontDeskState,
  ffdSearchParcelState,
  ffdCheckSingleRXState,
  ffdAddDimensionsState,
  ffdSaveDomesticInfoState,
  ffdCheckFrontInfoRadioState,
  cargoDomStatusAlertState,
} from "../../../../atoms/operation";
import Errors from "../../../../utils/Errors";

const OperationCargoDom = () => {
  const [domAwb, setDomAwb] = useRecoilState(ffdDomAwbState);
  const [domSupplier, setDomSupplier] = useRecoilState(ffdDomSupplierState);
  const [warehousecheckedFrontDesk, setWarehouseCheckedFrontDesk] =
    useRecoilState(warehouseCheckedFrontDeskState);
  const [awb, setAwb] = useState("");
  const [adSoyad, setAdSoyad] = useState("");
  const [tc, setTc] = useState("");
  const [checkedFrontDesk, setCheckedFrontDesk] = useState(false);
  //const [checkHand, setCheckHand] = useState(false);
  const [checkHand, setCheckHand] = useRecoilState(ffdCheckFrontInfoRadioState);

  const ffdCheckDom = useRecoilValue(ffdCheckDomState);
  const ffdCheckFrontDesk = useRecoilValue(ffdCheckFrontDeskState);
  const ffdCheckHand = useRecoilValue(ffdCheckHandState);
  const ffdCheckCourier = useRecoilValue(ffdCheckCourierState);
  const addDimensionsState = useRecoilValue(ffdAddDimensionsState);

  console.log(addDimensionsState);

  const [ffd1, setFfdData] = useRecoilState(ffdDataState);
  const [, setFfdBase64] = useRecoilState(ffdBase64State);
  const [, setCargoDomStatusAlert] = useRecoilState(cargoDomStatusAlertState);

  const [checkedManuelDom, setCheckedManuelDom] = useRecoilState(
    ffdCheckManualDimensionState
  );

  const [domSaveToDimension, setDomSaveToDimension] = useRecoilState(
    ffdManualDomSaveToDimension
  );

  const domSaveInfo = useRecoilValue(ffdSaveDomesticInfoState);
  const fffCheckSingleDom = useRecoilValue(ffdCheckSingleRXState);

  const [domEn, setDomEn] = useRecoilState(ffdManualDomWidth);
  const [domBoy, setDomBoy] = useRecoilState(ffdManualDomLength);
  const [domYuk, setDomYuk] = useRecoilState(ffdManualDomHeight);
  const [domGw, setDomGw] = useRecoilState(ffdManualDomGw);

  const [inputMultipleAwbValue, setInputMultipleAwbValue] = useState("");
  const [multipleAwb, setMultipleAwb] = useState([]);

  const handleWidthChange = (index, field, value) => {
    setMultipleAwb((prevMultipleAwb) => {
      const updatedAwb = [...prevMultipleAwb];
      updatedAwb[index] = { ...updatedAwb[index], [field]: value };
      return updatedAwb;
    });
  };

  // const makeDisableStatusesSearch = () => {
  //   console.log("ffdCheckDom",ffdCheckDom)
  //    console.log("multipleAwb",multipleAwb.length)
  //    console.log("fffCheckSingleDom",fffCheckSingleDom)
  //   if(!fffCheckSingleDom){
  //     if(!fffCheckSingleDom && multipleAwb.length === 0 && ffdCheckDom)
  //     {
  //       return true;
  //     }
  //     else if(multipleAwb.length === 0){
  //       return true;
  //     }
  //   }
  //   return false;
  // };
  const handleInputMultipleAwbChange = (event) => {
    setInputMultipleAwbValue(event.target.value);
  };

  const handleInputMultipleAwbKeyPress = (event) => {
    event.preventDefault();
    if (event.key === "Enter" && inputMultipleAwbValue.trim() !== "") {
      setMultipleAwb([
        ...multipleAwb,
        {
          domestic_awb: domAwb,
          awb: inputMultipleAwbValue,
          width: "",
          length: "",
          height: "",
          gw: "",
          type: "baby",
        },
      ]);
      setInputMultipleAwbValue("");
    }
  };

  const [preventUpdateStatus, setPreventUpdateStatus] =
    useRecoilState(ffdSearchParcelState); // GONDERİ BİR KERE ARANDIGINDA YANİ FALSE İSE 1 KERE UPDATE ET TRUE İSE 2.01 E GONDERİYİ CEKMEYİ ENGELLE.

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "10px",
          },
        },
      },
    },
  });

  const handleDeleteMultipleAwb = (selected) => {
    setMultipleAwb(multipleAwb.filter((item) => item.awb !== selected.awb));
  };

  useEffect(() => {}, [preventUpdateStatus]);
  useEffect(() => {
    console.log("Arama Sonucu Dom Bilgiler Varsa Doldur.");
    setDomSupplier(ffd1.domestic_supplier ?? "");
    setDomAwb(ffd1.domestic_awb ?? "");
  }, [ffd1]);

  const handleCheckedManuelDom = () => {
    setCheckedManuelDom(!checkedManuelDom);
  };
  const handleCheckedManuelSaveToDimension = () => {
    setDomSaveToDimension(!domSaveToDimension);
  };
  const handleCheckedFrontDesk = () => {
    setCheckedFrontDesk(!checkedFrontDesk);
    setWarehouseCheckedFrontDesk(!checkedFrontDesk);
  };
  const handleCheckedHand = () => {
    setCheckHand(!checkHand);
  };
  const handleChangeDomAwb = (e) => {
    const value = e.target.value;
    setDomAwb(value);

    // AWB numarasını kontrol et ve tedarikçiyi otomatik olarak seç
    checkAwbForMatchingSupplier(value);
  };

  const handleChangeDomSupplier = (e) => {
    const value = e.target.value;
    setDomSupplier(value);
  };

  const handleAwb = (e) => {
    const value = e.target.value;
    setAwb(value);
    // AWB numarasını kontrol et ve tedarikçiyi otomatik olarak seç
    checkAwbForMatchingSupplier(value);
  };

  const checkAwbForMatchingSupplier = (awbValue) => {
    // AWB numarasını kontrol edip tedarikçiyi otomatik olarak seç
    if (awbValue.startsWith("AR") || awbValue.startsWith("2P")) {
      setDomSupplier("Aras");
    } else if (awbValue.startsWith("1Z")) {
      setDomSupplier("UPS");
    } else if (awbValue.startsWith("2TV")) {
      setDomSupplier("Surat");
    }
  };

  const handleSubmitDom = (e) => {
    let stringUrl = ``;
    let multipleAwbData = ``;

    //eger tekli domestikse awb degiskenindeki veriyi al degilse multipleawb dekileri joinle gonder.//
    if (!fffCheckSingleDom) {
      const urlSearchParams = new URLSearchParams();

      const items = {};

      multipleAwb.forEach((item, index) => {
        items[index] = {
          domestic_awb: item.domestic_awb,
          awb: item.awb,
          width: item.width,
          length: item.length,
          height: item.height,
          gw: item.gw,
          type: item.type,
        };
      });

      let latestIndex = multipleAwb.length - 1;
      Object.keys(items).forEach((index) => {
        const item = items[index];
        Object.keys(item).forEach((property) => {
          urlSearchParams.append(
            `items[${index}][${property}]`,
            item[property]
          );
        });
      });

      urlSearchParams.append(`items[${latestIndex + 1}][domestic_awb]`, domAwb);
      urlSearchParams.append(`items[${latestIndex + 1}][awb]`, "");
      urlSearchParams.append(`items[${latestIndex + 1}][width]`, domEn);
      urlSearchParams.append(`items[${latestIndex + 1}][length]`, domBoy);
      urlSearchParams.append(`items[${latestIndex + 1}][height]`, domYuk);
      urlSearchParams.append(`items[${latestIndex + 1}][gw]`, domGw);
      urlSearchParams.append(`items[${latestIndex + 1}][type]`, "master");

      multipleAwbData = urlSearchParams.toString();
      stringUrl += `${multipleAwbData}`;
    } else {
      stringUrl += `parcel_ref_no=${awb}`;
    }

    //ARRIVED TO OFFICE TYPE CONDITIONS//
    let arrived_to_office_type = ``;
    if (ffdCheckDom) {
      arrived_to_office_type = `domestic`;
    } else if (ffdCheckFrontDesk) {
      arrived_to_office_type = `front_desk`;
      stringUrl += `&front_desk=1&parcel_source_from=${adSoyad}&parcel_source_from_number=${tc}`;
    } else if (ffdCheckHand) {
      arrived_to_office_type = `hand_warehouse`;
    } else if (ffdCheckCourier) {
      arrived_to_office_type = `courier`;
    }
    console.log("Arrived To Office Type:" + arrived_to_office_type);

    //ARRIVED TO OFFICE TYPE CONDITIONS//

    if (arrived_to_office_type == "domestic") {
      stringUrl += `&save_dom_supplier=1&domestic_supplier=${domSupplier}&domestic_awb=${domAwb}&arrived_to_office_type=${arrived_to_office_type}&single_receive_domestic=${fffCheckSingleDom}`;
    } else if (ffdCheckFrontDesk || ffdCheckHand) {
      stringUrl += `&front_desk_tc=${checkHand}&front_desk=1&parcel_source_from=${adSoyad}&parcel_source_from_number=${tc}&arrived_to_office_type=${arrived_to_office_type}&single_receive_domestic=${fffCheckSingleDom}`;
    } else if (arrived_to_office_type == "courier") {
      stringUrl += `&arrived_to_office_type=${arrived_to_office_type}`;
    }

    /*/if (domSaveInfo) {
      stringUrl += `&save_dom_supplier=1&domestic_supplier=${domSupplier}&domestic_awb=${domAwb}&arrived_to_office_type=${arrived_to_office_type}&single_receive_domestic=${fffCheckSingleDom}`;
    } else if (ffdCheckFrontDesk || warehousecheckedFrontDesk) {
      stringUrl += `&front_desk=1&parcel_source_from=${adSoyad}&parcel_source_from_number=${tc}`;
    }/*/
    e.preventDefault();
    console.log("Statü Değişikliği Durumu (2.01):" + preventUpdateStatus);
    axios
      .get(
        `parcel?detailed=1&barcode=1&${stringUrl}&update_status=${preventUpdateStatus}`
      )
      .then((response) => {
        if (response.data.actionType != undefined) {
          if (response.data.failItems.length > 0) {
            alert("hata var response a  bak");
          } else {
            setCargoDomStatusAlert(true);
          }
        } else if (response.data.totalItems > 0) {
          setFfdData(response.data.data[0]);
          setFfdBase64(response.data.base64);
          setPreventUpdateStatus(true);
        }
      })
      .catch((error) => Errors(error));
  };

  const handleAdSoyad = (e) => {
    const value = e.target.value;
    setAdSoyad(value);
  };
  const handleTc = (e) => {
    const value = e.target.value;
    setTc(value);
  };

  const handleDomEn = (e) => {
    const value = e.target.value;
    setDomEn(value);
  };
  const handleDomBoy = (e) => {
    const value = e.target.value;
    setDomBoy(value);
  };
  const handleDomYuk = (e) => {
    const value = e.target.value;
    setDomYuk(value);
  };
  const handleDomGw = (e) => {
    const value = e.target.value;
    setDomGw(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <form
        onSubmit={handleSubmitDom}
        className="operation-feight-front-desk-dom"
      >
        {ffdCheckDom || ffdCheckFrontDesk || ffdCheckHand || ffdCheckCourier ? (
          <>
            {ffdCheckDom ? (
              <div className="operation-feight-front-desk-dom-input">
                <TextField
                  fullWidth
                  required={domSaveInfo}
                  label="Domestik AWB No"
                  autoComplete="off"
                  value={domAwb}
                  placeholder="12321362131371"
                  onChange={handleChangeDomAwb}
                  size="small"
                  sx={{ maxWidth: "100%" }}
                  InputProps={{ style: { height: "59px" } }}
                />
                <FormControl
                  size="small"
                  required={domSaveInfo}
                  fullWidth
                  sx={{ maxWidth: "100%" }}
                >
                  <InputLabel id="dom-awb-select-label">
                    Domestik Tedarikçi
                  </InputLabel>
                  <Select
                    labelId="dom-awb-select-label"
                    id="dom-awb-select"
                    value={domSupplier}
                    label="Domestik Tedarikçi"
                    onChange={handleChangeDomSupplier}
                    size="small"
                    sx={{ height: "59px" }}
                  >
                    <MenuItem value="">
                      <em>Temizle</em>
                    </MenuItem>
                    <MenuItem value={"Aras"}>Aras Kargo</MenuItem>
                    <MenuItem value={"Surat"}>Sürat Kargo</MenuItem>
                    <MenuItem value={"Mng"}>MNG Kargo</MenuItem>
                    <MenuItem value={"Diğer"}>Diğer</MenuItem>
                  </Select>
                </FormControl>
              </div>
            ) : null}
            {ffdCheckFrontDesk || ffdCheckHand ? (
              <div style={{ display: " flex", gap: " 20px" }}>
                <div
                  style={{
                    width: ffdCheckFrontDesk ? "240px" : "",
                    display: ffdCheckFrontDesk ? "none" : "",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<RadioButtonUncheckedOutlinedIcon />}
                        checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                        checked={checkHand}
                        onChange={handleCheckedHand}
                      />
                    }
                    label="Ön Büro"
                  />
                </div>

                <TextField
                  required={checkedFrontDesk || ffdCheckFrontDesk}
                  fullWidth
                  size="small"
                  label="Adı Soyadı"
                  placeholder="Sinem Şahin"
                  autoComplete="off"
                  value={adSoyad}
                  onChange={handleAdSoyad}
                  sx={{
                    width: "100%",
                    display: checkHand ? "none" : "block",
                  }}
                  InputProps={{ style: { height: "50px" } }}
                />
                <TextField
                  // required={checkedFrontDesk ||ffdCheckFrontDesk }
                  fullWidth
                  size="small"
                  label="TC Kimlik No"
                  placeholder="12345678910"
                  autoComplete="off"
                  value={tc}
                  onChange={handleTc}
                  sx={{
                    width: "100%",
                    display: checkHand ? "none" : "block",
                  }}
                  InputProps={{ style: { height: "50px" } }}
                  inputProps={{ maxLength: 11 }}
                />
              </div>
            ) : null}
          </>
        ) : null}
        {addDimensionsState && (
          <div className="operation-manuel-cargo">
            <div className="operation-manuel-cargo-item">
              <TextField
                value={domEn}
                onChange={handleDomEn}
                label="En"
                size="small"
                required
                fullWidth
              />
              <TextField
                value={domBoy}
                onChange={handleDomBoy}
                label="Boy"
                size="small"
                required
                fullWidth
              />
              <TextField
                value={domYuk}
                onChange={handleDomYuk}
                label="Yükseklik"
                size="small"
                required
                fullWidth
              />
              <TextField
                value={domGw}
                onChange={handleDomGw}
                label="KG"
                size="small"
                required
                fullWidth
              />
            </div>
          </div>
        )}
        <div className="operation-feight-front-desk-dom-input bottom-field">
          {fffCheckSingleDom ? (
            <>
              <TextField
                required
                fullWidth
                size="small"
                label="Lütfen AWB Giriniz"
                placeholder="TR12312321321US"
                autoComplete="off"
                value={awb}
                onChange={handleAwb}
                sx={{ maxWidth: "950px" }}
                InputProps={{ style: { height: "50px" } }}
              />
            </>
          ) : (
            <>
              <TextField
                required={!fffCheckSingleDom && multipleAwb.length === 0}
                fullWidth
                size="small"
                placeholder="TR12312321321US"
                autoComplete="off"
                label="Lütfen AWB leri Giriniz"
                value={inputMultipleAwbValue}
                onChange={handleInputMultipleAwbChange}
                onKeyPress={handleInputMultipleAwbKeyPress}
                sx={{ maxWidth: "950px" }}
                InputProps={{ style: { height: "50px" } }}
              />
            </>
          )}
          <Button
            // disabled={makeDisableStatusesSearch()}
            type="submit"
            className="iyzi-button-blue"
            sx={{ maxWidth: "76px", height: "50px" }}
          >
            Ara
          </Button>
        </div>
        {!fffCheckSingleDom && multipleAwb.length > 0 ? (
          <>
            <div className="operation-feight-front-desk-dom-input bottom-field">
              Seçilen AWB ler
            </div>
            {/*/<div className="operation-feight-front-desk-dom-input bottom-field">
              <div>
                {multipleAwb.map((awb, index) => (
                    <Chip
                        key={index}
                        label={awb}
                        onDelete={() => handleDeleteMultipleAwb(awb)}
                        style={{ margin: "4px" }}
                    />
                ))}
              </div>
            </div>/*/}

            <div className="operation-manuel-cargo">
              {multipleAwb.map((item, index) => (
                <div key={index} className="operation-manuel-cargo-item">
                  <TextField
                    value={item.awb}
                    disabled
                    label={index + 1 + ". AWB"}
                    size="small"
                    fullWidth
                    key={"awb_" + index}
                  />
                  <TextField
                    disabled={!ffdCheckDom}
                    value={item.width}
                    onChange={(e) =>
                      handleWidthChange(index, "width", e.target.value)
                    }
                    label={index + 1 + ". En"}
                    size="small"
                    required={ffdCheckDom}
                    fullWidth
                    key={"width_" + index}
                  />
                  <TextField
                    disabled={!ffdCheckDom}
                    value={item.length}
                    onChange={(e) =>
                      handleWidthChange(index, "length", e.target.value)
                    }
                    label={index + 1 + ". Boy"}
                    size="small"
                    required={ffdCheckDom}
                    fullWidth
                    key={"length_" + index}
                  />
                  <TextField
                    disabled={!ffdCheckDom}
                    value={item.height}
                    onChange={(e) =>
                      handleWidthChange(index, "height", e.target.value)
                    }
                    label={index + 1 + ". Yükseklik"}
                    size="small"
                    required={ffdCheckDom}
                    fullWidth
                    key={"height_" + index}
                  />
                  <TextField
                    disabled={!ffdCheckDom}
                    value={item.gw}
                    onChange={(e) =>
                      handleWidthChange(index, "gw", e.target.value)
                    }
                    label={index + 1 + ". KG"}
                    size="small"
                    required={ffdCheckDom}
                    fullWidth
                    key={"gw_" + index}
                  />

                  <Button
                    type="buttom"
                    className="iyzi-button-blue"
                    sx={{ maxWidth: "76px", height: "35px" }}
                    onClick={() => handleDeleteMultipleAwb(item)}
                  >
                    Kaldır
                  </Button>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </form>
    </ThemeProvider>
  );
};

export default OperationCargoDom;
