import { TableCell, TableHead, TableRow } from "@mui/material";

const MemberUsersTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Düzenle</TableCell>
        <TableCell>Adı Soyadı</TableCell>
        <TableCell>E-Posta</TableCell>
        <TableCell>Telefon</TableCell>
        <TableCell>Dil</TableCell>
        <TableCell>Durum</TableCell>
        <TableCell>Yönetici</TableCell>
        <TableCell>Değişiklik Tarihçesi</TableCell>
        <TableCell>Kaldır</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default MemberUsersTableHeader;
