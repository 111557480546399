import { atom } from "recoil";

export const courierListAdminState = atom({
  key: "courierListAdmin",
  default: [],
});

export const courierCurrentPageAdminState = atom({
  key: "courierCurrentPageAdmin",
  default: 1,
});

export const courierTotalPagesAdminState = atom({
  key: "courierTotalPagesAdmin",
  default: 0,
});

export const courierAbortAdminOpenState = atom({
  key: "courierAbortAdminOpen",
  default: false,
});

export const courierAbortAdminIDState = atom({
  key: "courierAbortAdminID",
  default: false,
});

export const courierDeliveredAdminOpenState = atom({
  key: "courierDeliveredAdminOpen",
  default: false,
});

export const deliveredItemState = atom({
  key: "deliveredItem",
  default: {},
});

export const courierRightItemsState = atom({
  key: "courierRightItems",
  default: [],
});

export const errorSetState = atom({
  key: "errorSett",
  default: true,
});
