const SupportCaseQuestionType = (e) => {
  if (Number(e) === 1) {
    return "Tedarikçi";
  } else if (Number(e) === 2) {
    return "Müşteri";
  } else if (Number(e) === 3) {
    return "Admin";
  }
};

export default SupportCaseQuestionType;
