import {createTheme, ThemeProvider} from "@mui/material";
import {useState} from "react";
import {useRecoilState} from "recoil";
import {
  financeFaturaInvoicingCurrentPageState,
  financeFaturaInvoicingDataState,
  financeFaturaInvoicingTotalPageState,
  invoicingModalState,
} from "../../../../atoms/finance";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const FaturaInvoicingOptions = () => {
  const [, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");


  const [, setFinanceFaturaInvoicingData] = useRecoilState(financeFaturaInvoicingDataState);
  const [, setFinanceFaturaInvoicingTotalPage] = useRecoilState(financeFaturaInvoicingTotalPageState);
  const [, setFinanceFaturaInvoicingCurrentPage] = useRecoilState(financeFaturaInvoicingCurrentPageState);

  const [, setInvoicingModal] = useRecoilState(invoicingModalState);

  const handleInvoicingModal = () => {
    setInvoicingModal(true);
  }

  return (
    <ThemeProvider theme={theme}>

    </ThemeProvider>
  );
};

export default FaturaInvoicingOptions;
