import React, { useState } from "react";
import { Box, Select, MenuItem } from "@mui/material";
import { useRecoilValue } from "recoil";
import {
  courierDeliveredAdminOpenState,
  courierListAdminState,
} from "../../../atoms/courierRegistration";
import CourierDeliveredAdmin from "./CourierDeliveredAdmin";
import CourierListItemAdmin from "./CourierListItemAdmin";

const CourierListAdmin = () => {
  const courierListAdmin = useRecoilValue(courierListAdminState);
  const courierDeliveredAdminOpen = useRecoilValue(
    courierDeliveredAdminOpenState
  );

  const [selectedStatus, setSelectedStatus] = useState(6);

  const filteredCouriers =
    selectedStatus === 6
      ? courierListAdmin
      : courierListAdmin.filter(
          (item) => String(item.status) === String(selectedStatus)
        );

  const handleStatusChange = (event) => {
    setSelectedStatus(Number(event.target.value));
  };

  const sortedCouriers = [...filteredCouriers].sort((a, b) => {
    // Sıralama işlemi selectedStatus'e göre yapılır.
    return a.status - b.status;
  });

  return (
    <Box className="courier-list">
      <Select sx={{
        width: "300px",
        margin: "20px 0",
        backgroundColor: "white",
      }} value={selectedStatus} onChange={handleStatusChange}>
        <MenuItem value={6}>Tüm Kurye Kayıtlarını Göster</MenuItem>
        <MenuItem value={0}>Onay Bekleniyor</MenuItem>
        <MenuItem value={1}>Kayıt Onaylandı</MenuItem>
        <MenuItem value={2}>Kurye Yola Çıktı</MenuItem>
        <MenuItem value={3}>Teslim Alındı</MenuItem>
        <MenuItem value={4}>Tamamlandı</MenuItem>
        <MenuItem value={5}>İptal Edildi</MenuItem>
      </Select>

      {courierDeliveredAdminOpen ? (
        <CourierDeliveredAdmin />
      ) : sortedCouriers.length > 0 ? (
        sortedCouriers.map((item, key) => (
          <CourierListItemAdmin key={key} item={item} />
        ))
      ) : (
        <p
          style={{
            fontSize: "20px",
          }}
        >
          Kayıt Bulunamadı
        </p>
      )}
    </Box>
  );
};

export default CourierListAdmin;
