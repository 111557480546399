import { TableCell, TableHead, TableRow } from "@mui/material";

const AnnouncementTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Tür</TableCell>
        <TableCell>Üye ID</TableCell>
        <TableCell>Ekleyen</TableCell>
        <TableCell>Başlangıç Tarihi</TableCell>
        <TableCell>Bitiş Tarihi</TableCell>
        <TableCell>Düzenle</TableCell>
        <TableCell>Sil</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default AnnouncementTableHead;
