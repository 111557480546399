import { TableCell, TableHead, TableRow } from "@mui/material";

const NewMemberTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Üye</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Üye No</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Üye Tipi</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Üyelik Tarihi</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Müşteri Grubu</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Karlılık Tipi</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Karlılık Miktarı</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>İlk Gönderi Tarihi</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Ocak</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Şubat</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Mart</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Nisan</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Mayıs</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Haziran</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Temmuz</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Ağustos</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Eylül</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Ekim</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Kasım</TableCell>
        <TableCell sx={{ padding: "5px", fontWeight: 700, minWidth: "100px" }}>Aralık</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default NewMemberTableHead;
