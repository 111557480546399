import { Box, createTheme, ThemeProvider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useInView, animated } from "@react-spring/web";
import { DynamicTitle } from "../../utils/DynamicTitle";
import axios from "../../api/axios";
import NavBar from "../components/sidebar/NavBar";
import { ToastContainer } from "react-toastify";
import miniLogo from "../../img/logos/logo_white_mini_loading.svg";
import { memberListState, memberTotalPageState } from "../../atoms/members";
import WrapperStyle from "../../utils/WrapperStyle";
import MemberList from "../components/members/MemberList";
import Errors from "../../utils/Errors";
import MemberTimerModal from "../components/members/MemberTimerModal";
import { drawerOpenState } from "../../atoms/viewPadding";
import NotificationBar from "../components/sidebar/NotificationBar";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: 20,
          overflowY: "auto",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const Members = () => {
  DynamicTitle("Üyeler - Admin");

  const drawerOpen = useRecoilValue(drawerOpenState);

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const setMemberList = useSetRecoilState(memberListState);
  const setMemberTotalPage = useSetRecoilState(memberTotalPageState);

  const [loading, setLoading] = useState(false);

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const getData = useCallback(async () => {
    setLoading(true);
    await axios
      .get("member", {
        params: {
          order_direction: "DESC",
          limit: 50,
          page: 1,
        },
      })
      .then((response) => {
        setMemberList(response.data.data);
        setMemberTotalPage(response.data.totalPages);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [setMemberList, setMemberTotalPage]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <ThemeProvider theme={theme}>
      <NotificationBar />
      <section className="members">
        <NavBar />
        <ToastContainer />
        <MemberTimerModal />
        <Box
          className="members-wrapper"
          style={WrapperStyle()}
          sx={{
            paddingLeft: drawerOpen ? 0 : "64px",
            transition: "all 150ms linear",
            paddingTop: "20px",
          }}
        >
          {loading ? (
            <Box
              sx={{
                height: "500px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img className="loading-logo" src={miniLogo} alt="" />
            </Box>
          ) : (
            <animated.div ref={ref} style={springs}>
              <Box className="members-container">
                <h6
                  style={{
                    color: "var(--priceText)",
                    marginTop: "var(--gap2x)",
                    marginBottom: "var(--gap4x)",
                  }}
                >
                  Üyeler
                </h6>
                <MemberList />
              </Box>
            </animated.div>
          )}
        </Box>
      </section>
    </ThemeProvider>
  );
};

export default Members;
