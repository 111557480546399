import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FormatReturnFieldWithCustomDecimals } from "../../../../../utils/FormatFieldReturn";

const PreLoginMemberProfit = ({
  subType,
  setSubType,
  value,
  setValue,
  status,
  setStatus,
  handleClose,
  loading,
}) => {
  return (
    <div
      style={{
        marginTop: "var(--gap2x)",
        display: "flex",
        flexDirection: "column",
        gap: "var(--gap2x)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "var(--gap2x)",
        }}
      >
        <FormControl size="small" fullWidth>
          <InputLabel id="select-label">Alt Tip</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            value={subType}
            label="Alt Tip"
            onChange={(e) => setSubType(e.target.value)}
          >
            <MenuItem value={"amt"}>Maliyet + Miktar</MenuItem>
            <MenuItem value={"pct"}>Maliyet + Yüzde (%)</MenuItem>
            <MenuItem value={"fix_profit_plus_amt"}>Fix Profit + Miktar</MenuItem>
            <MenuItem value={"fix_profit_plus_pct"}>Fix Profit + Yüzde (%)</MenuItem>
            <MenuItem value={"special_profit_plus_pct"}>Özel Fix Kar + Yüzde (%)</MenuItem>
            <MenuItem value={"special_profit_plus_amt"}>Özel Fix Kar + Miktar</MenuItem>
          </Select>
        </FormControl>
        <TextField
          sx={{ minWidth: 120 }}
          size="small"
          value={value}
          autoComplete="off"
          label="Değer"
          onChange={(e) => setValue(FormatReturnFieldWithCustomDecimals(e.target.value, 2))}
        />
        <FormControl size="small" fullWidth>
          <InputLabel id="select-label">Durum</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            value={status}
            label="Durum"
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value={0}>İnaktif</MenuItem>
            <MenuItem value={1}>Aktif</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "var(--gap)",
        }}
      >
        <Button type="button" onClick={handleClose} className="iyzi-button-gray">
          İptal
        </Button>
        {loading ? (
          <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
        ) : (
          <Button type="submit" className="iyzi-button-blue">
            Kaydet
          </Button>
        )}
      </div>
    </div>
  );
};

export default PreLoginMemberProfit;
