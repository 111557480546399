const IyziBalanceDescription = (e) => {
  switch (e) {
    case 0:
      return "---";
    case 1:
      return "Havale ile Bakiye Ekleme";
    case 2:
      return "Kredi Kartı ile Bakiye Ekleme";
    case 3:
      return "Mal Kayıp Tazmin Cüzdan Girişi";
    case 3.1:
      return "Mal Kayıp Navlun Cüzdan Girişi";
    case 4:
      return "Tazmin İade";
    case 5:
      return "Navlun Tazmin Cüzdan Girişi";
    case 6:
      return "Ödül";
    case 7:
      return "Loyality";
    case 8:
      return "Gönderi İptal Cüzdan Girişi";
    case 9:
      return "Cüzdandan Yük Ödemesi";
    case 10.1:
    case 10.2:
      return "Kredi Kartı ile Direkt Ödeme";
    case 11:
      return "Cüzdandan İade";
    case 12.01:
      return "Kısmi İade Kalan Cüzdan Giriş";
    case 12.02:
      return "Kısmi İade Kalan Cüzdan Çıkış";
    case 14.0:
      return "Navlun Kg Farkı İadesi";
    case 15.0:
      return "Cüzdandan Domestik Ödemesi";
    case 16.1:
    case 16.2:
      return "Kredi Kartı ile Domestik Ödeme";
    case 17.0:
      return "Yurtiçi Nakliye Bedeli Fatura";
    case 18.0:
      return "Cüzdan Return Ödemesi";
    case 19.0:
      return "Yurtdışı Nakliye Bedeli Fatura";
    case 20.1:
    case 20.2:
      return "Kredi Kartı ile Return Ödeme";
    case 21.0:
      return "Return Navlun Bedeli Fatura";
    case 22.0:
      return "Gider Pusulası";
    case 23.0:
      return "Tazmin İade Faturası";
    case 24.0:
      return "Navlun İade Faturası";
    default:
      return "";
  }
};

export default IyziBalanceDescription;
