import "../../../style/myWallet/MyWallet.css";
import { Box, createTheme, ThemeProvider, Button, FormControl, InputLabel, Select, MenuItem, CircularProgress, } from "@mui/material";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useRecoilValue } from "recoil";
import { useInView, animated } from "@react-spring/web";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import { drawerOpenState } from "../../../atoms/viewPadding";
import NavBar from "../sidebar/NavBar";
import { ToastContainer } from "react-toastify";
import WrapperStyle from "../../../utils/WrapperStyle";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import Errors from "../../../utils/Errors";
import NotificationBar from "../sidebar/NotificationBar";
import DownloadBase64Excel from "../../../utils/DownloadBase64Excel";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const SalesReport = () => {
  DynamicTitle("Gönderi İade Talepleri - Admin");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const drawerOpen = useRecoilValue(drawerOpenState);

  const [loading, setLoading] = useState(false);
  const [dateType, setDateType] = useState(false);

  const navUnder = {
    paddingLeft: drawerOpen ? "264px" : "64px",
    transition: "all 150ms linear",
    backgroundColor: "var(--lighterBg)",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  };

  const handleDateChange = (e) => {
    setDateType(e.target.value)
  }

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  // Sayfa Fonksiyonları
  const handleGetUspsReport = () => {
    setLoading(true);
    axios
      .post(`parcel/sales-report`, {
        shipping_date_filter_by: dateType
      })
      .then((response) => {
        toast.success(response.data.message);
        DownloadBase64Excel(
          response.data.base64,
          "Satış",
          "Raporu"
        );
        setLoading(false);
      })
      .catch((error) => {
        Errors(error);
        setLoading(false);
      });

  };

  const handleGetCronJob = () => {
    setLoading(true);
    axios
      .post(`log/integration-token-logs?export=1`)
      .then((response) => {
        toast.success(response.data.message);
        DownloadBase64Excel(
          response.data.base64,
          "Cron",
          "Job"
        );
        setLoading(false);
      })
      .catch((error) => {
        Errors(error);
        setLoading(false);
      });

  };

  return (
    <ThemeProvider theme={theme}>
      <NotificationBar />
      <section
        className="my-wallet"
        style={{
          paddingLeft: drawerOpen ? 0 : "64px",
          transition: "all 150ms linear",
        }}
      >
        <NavBar />
        <ToastContainer />
        <Box className="nav-under" style={navUnder}>
          <div>
            <ReceiptLongOutlinedIcon /> Satış Raporu
          </div>
        </Box>
        <Box className="my-wallet-wrapper" style={WrapperStyle()}>
          <Box>
            <FormControl required size="small" sx={{
              width: "250px"
            }}>
              <InputLabel id="select-label-announcement-type">Rapor Tarihi</InputLabel>
              <Select
                labelId="select-label-announcement-type"
                id="select"
                value={dateType}
                label="Rapor Tarihi"
                onChange={(e) => handleDateChange(e)}
              >
                <MenuItem value="">
                  <em>Temizle</em>
                </MenuItem>
                <MenuItem value={"yesterday"}>Dün</MenuItem>
                <MenuItem value={"last_week"}>Geçen Hafta</MenuItem>
                <MenuItem value={"last_month"}>Geçen Ay</MenuItem>
                <MenuItem value={"this_week"}>Bu Hafta</MenuItem>
                <MenuItem value={"this_month"}>Bu Ay</MenuItem>
                <MenuItem value={"this_year"}>Bu Yıl</MenuItem>
                <MenuItem value={"all_times"}>Tüm Zamanlar</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <animated.div ref={ref} style={springs}>
            {loading ? (
              <Box
                sx={{
                  width: "72px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress sx={{ width: "24px !important", height: "24px !important" }} />
              </Box>
            ) : (
              <Button
                sx={{
                  marginTop: "1rem",
                  width: "200px"
                }}
                variant="contained"
                onClick={handleGetUspsReport}
              >
                Satış Raporu
              </Button>
            )}
          </animated.div>
          <animated.div ref={ref} style={springs}>
            {loading ? (
              <Box
                sx={{
                  width: "72px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress sx={{ width: "24px !important", height: "24px !important" }} />
              </Box>
            ) : (
              <Button
                sx={{
                  marginTop: "1rem",
                  width: "200px"
                }}
                variant="contained"
                onClick={handleGetCronJob}
              >
                ENTEGRASYON CRON JOB
              </Button>
            )}
          </animated.div>
        </Box>
      </section>
    </ThemeProvider>
  );
};

export default SalesReport;
