import "../../style/login/Login.css";
import PreHeader from "../components/sign/PreHeader";
import Login from "../components/sign/Login";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useCookies } from "react-cookie";

const Home = () => {
  const navigate = useNavigate();

  const [token] = useCookies(["jwt"]);
  const jwt = token?.jwt;

  useEffect(() => {
    if (jwt !== undefined && jwt !== null && jwt.length !== 0) {
      navigate("/dashboard");
    }
  }, [jwt, navigate]);

  return (
    <>
      <section className="login">
        <div className="login-sign">
          <PreHeader />
          <Login />
        </div>
      </section>
    </>
  );
};

export default Home;
