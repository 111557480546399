import { Box, TextField, Typography } from "@mui/material";

const MemberInformationEditUsers = ({ member }) => {
  return (
    <Box
      sx={{
        boxShadow: "var(--shadow)",
        padding: "20px",
        backgroundColor: "var(--lighterBg)",
        borderRadius: "var(--inputBorderRadius)",
        display: "grid",
        gap: "var(--gap2x)",
      }}
    >
      <Typography color={"var(--priceText)"} variant="h5">
        Kişi Bilgileri
      </Typography>
      <Box sx={{ display: "flex", gap: "var(--gap2x)" }}>
        <TextField size="small" fullWidth label="Ad" />
        <TextField size="small" fullWidth label="Soyad" />
        <TextField size="small" fullWidth label="Doğum Tarihi" />
        <TextField size="small" fullWidth label="TC Kimlik Numarası" />
      </Box>
    </Box>
  );
};

export default MemberInformationEditUsers;
