import { useRecoilValue } from "recoil";
import { financeHavaleDataState } from "../../../../atoms/finance";
import { Table, TableBody, TableContainer } from "@mui/material";
import FinanceHavaleTableBody from "./FinanceHavaleTableBody";
import FinanceHavaleTableHead from "./FinanceHavaleTableHead";

const FinanceHavaleTable = () => {
  const financeHavaleData = useRecoilValue(financeHavaleDataState);

  return (
    <TableContainer
      sx={{
        backgroundColor: "var(--lighterBg)",
        boxShadow: "var(--shadow)",
        borderRadius: "var(--wrapperBorderRadius)",
      }}
    >
      <Table>
        <FinanceHavaleTableHead />
        <TableBody>
          {financeHavaleData?.map((item, key) => (
            <FinanceHavaleTableBody key={key} item={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FinanceHavaleTable;
