import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useState } from "react";
import Errors from "../../../../utils/Errors";
import { toast } from "react-toastify";
import axios from "../../../../api/axios";
import ExchangeRateSetting from "./systemSettingOptions/ExchangeRateSetting";
import MemberProfitSetting from "./systemSettingOptions/MemberProfitSetting";
import PreLoginMemberProfit from "./systemSettingOptions/PreLoginMemberProfitSetting";
import DefaultMemberCustomerGroupSetting from "./systemSettingOptions/DefaultMemberCustomerGroupSetting";
import PaymentTypeSetting from "./systemSettingOptions/PaymentTypeSetting";
import DefaultDomesticSupplierSetting from "./systemSettingOptions/DefaultDomesticSupplierSetting";
import FreeShippingUpperLimitSetting from "./systemSettingOptions/FreeShippingUpperLimitSetting";
import DefaultDomesticPriceSetting from "./systemSettingOptions/DefaultDomesticPriceSetting";
import PaymentOrderSetting from "./systemSettingOptions/PaymentOrderSetting";
import RefundOrderSetting from "./systemSettingOptions/RefundOrderSetting";

const AddSystemSetting = ({ open, setOpen, data, setData }) => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [subType, setSubType] = useState("");
  const [value, setValue] = useState("");
  const [status, setStatus] = useState("");

  const handleClose = () => {
    setOpen(false);
    setType("");
    setSubType("");
    setValue("");
    setStatus("");
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    setLoading(true);
    axios
      .post(`system-setting/add`, {
        type,
        sub_type: subType,
        value,
        status,
      })
      .then((response) => {
        setData([...data, response.data?.data]);
        toast.success(response.data?.message);
        handleClose();
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  const handleChangeType = (e) => {
    setType(e.target.value);
    setSubType("");
    setValue("");
    setStatus("");
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Sistem Ayarı Ekle</DialogTitle>
      <form onSubmit={handleSubmit} style={{ minWidth: "600px" }}>
        <DialogContent>
          <FormControl size="small" fullWidth>
            <InputLabel id="select-label">Tip</InputLabel>
            <Select
              labelId="select-label"
              id="select"
              value={type}
              label="Tip"
              onChange={handleChangeType}
            >
              <MenuItem value={"payment_type"}>Sistem Ödeme Kanalı</MenuItem>
              <MenuItem value={"exchange_rate"}>Sistem Kuru</MenuItem>
              <MenuItem value={"payment_order"}>Sistem Ödeme Sıralaması</MenuItem>
              <MenuItem value={"refund_order"}>Sistem İade Sıralaması</MenuItem>
              <MenuItem value={"default_domestic_supplier"}>
                Sistem Varsayılan Domestik Tedarikçi
              </MenuItem>
              <MenuItem value={"member_profit"}>Varsayılan Üyelik Karı</MenuItem>
              <MenuItem value={"pre_login_member_profit"}>
                Varsayılan Giriş Öncesi Üyelik Karı
              </MenuItem>
              <MenuItem value={"default_member_customer_group"}>
                Sistem Varsayılan Üyelik Müşteri Grubu
              </MenuItem>
              <MenuItem value={"free_shipping_upper_limit"}>
                Sistem Ücretsiz Domestik Kargo Limiti
              </MenuItem>
              <MenuItem value={"default_domestic_price"}>Sistem Varsayılan Domestik Fiyat</MenuItem>
            </Select>
          </FormControl>
          {type === "exchange_rate" && (
            <ExchangeRateSetting
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
          {type === "member_profit" && (
            <MemberProfitSetting
              subType={subType}
              setSubType={setSubType}
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
          {type === "pre_login_member_profit" && (
            <PreLoginMemberProfit
              subType={subType}
              setSubType={setSubType}
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
          {type === "default_member_customer_group" && (
            <DefaultMemberCustomerGroupSetting
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
          {type === "payment_type" && (
            <PaymentTypeSetting
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
          {type === "default_domestic_supplier" && (
            <DefaultDomesticSupplierSetting
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
          {type === "free_shipping_upper_limit" && (
            <FreeShippingUpperLimitSetting
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
          {type === "default_domestic_price" && (
            <DefaultDomesticPriceSetting
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
          {type === "payment_order" && (
            <PaymentOrderSetting
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
          {type === "refund_order" && (
            <RefundOrderSetting
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default AddSystemSetting;
