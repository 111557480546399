import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { supplierConsigmentDownloadState } from "../../../../atoms/myShipments";
import FormatDateHour from "../../../../utils/FormatDateHour";
import { useCallback } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import axios from "../../../../api/axios";

const SupplierConsignmentDownload = () => {
  const [supplierConsigmentDownload, setSupplierConsigmentDownload] =
    useRecoilState(supplierConsigmentDownloadState);

  const handleClose = useCallback(() => {
    setSupplierConsigmentDownload({ status: false, data: [] });
  }, [supplierConsigmentDownload]);

  const selectedAWB =
    supplierConsigmentDownload.data.length > 0
      ? supplierConsigmentDownload.data[0].tracking_awb
      : null;

  const getFile = (path) => {
    axios
      .get(`document/get-file/${path.replaceAll("/", "-")}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        let blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        let image = window.URL.createObjectURL(blob);

        window.open(image, "_blank");
      });
  };

  return (
    <Modal
      sx={{
        padding: 20,
        outline: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "scroll",
      }}
      open={supplierConsigmentDownload.status}
      onClose={handleClose}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--wrapperBorderRadius)",
            outline: 0,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "20px",
          }}
        >
          <HighlightOffIcon
            sx={{
              alignSelf: "flex-end",
              cursor: "pointer",
              ":hover": {
                color: "var(--error)",
                transition: "all 150ms linear",
              },
            }}
            onClick={handleClose}
          />
          {supplierConsigmentDownload.data.length > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tedarikçi</TableCell>
                    <TableCell>İzleme Numarası</TableCell>
                    <TableCell>Tip</TableCell>
                    <TableCell>Dosya</TableCell>
                    <TableCell>Oluşturulma Tarihi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {supplierConsigmentDownload?.data?.map((consigment, key) => (
                    <TableRow
                      key={key}
                      style={{
                        backgroundColor:
                          consigment?.tracking_awb === selectedAWB
                            ? "green"
                            : "grey",
                      }}
                    >
                      <TableCell>
                        <div>
                          <Typography>
                            {consigment?.get_supplier?.supplier_name}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>
                          <Typography>{consigment?.tracking_awb}</Typography>
                        </div>
                      </TableCell>
                      <TableCell>{consigment?.label_type}</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            border: "1px solid var(--inputBorderColor)",
                            borderRadius: "var(--wrapperBorderRadius)",
                            padding: "10px",
                            transition: "all 150ms linear",
                            cursor: "pointer",

                            ":hover": {
                              backgroundColor: "var(--pastelBlue)",
                              transition: "all 150ms linear",
                            },
                          }}
                          onClick={() => getFile(consigment?.file_path)}
                          key={key}
                        >
                          {consigment?.file_path}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {FormatDateHour(consigment?.created_at)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {supplierConsigmentDownload.data.length === 0 && (
            <Typography>Tedarikçi Konşimentosu bulunmaktadır.</Typography>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SupplierConsignmentDownload;
