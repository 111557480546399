import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  createTheme,
  InputAdornment,
  TextField,
  ThemeProvider,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useCallback, useEffect, useState } from "react";
import axios from "../../../../api/axios";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";
import {
  adminBakiyeExportState,
  financeBakiyeCurrentPageState,
  financeBakiyeDataState,
  financeBakiyeTotalPageState,
  searchAdminBakiyeDateState,
  searchAdminBakiyeEmailState,
  searchAdminBakiyeIdState,
  searchAdminBakiyeKonsimentoState,
  searchAdminBakiyeNameState,
  searchAdminBakiyeParamState,
} from "../../../../atoms/finance";
import { useLocation, useNavigate } from "react-router-dom";
import Errors from "../../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: "0",
        },
      },
    },
  },
});

const FinanceBakiyeSearch = () => {
  const navigate = useNavigate();

  // awb parametresi url'de bulunuyorsa silmek için.
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const awbParam = searchParams.get("memberId");

  const [, setLoading] = useState(false);
  const [expand, setExpand] = useState(false);

  const [, setAdminBakiyeExport] = useRecoilState(adminBakiyeExportState);
  const [searchAdminBakiyeParam, setSearchAdminBakiyeParam] = useRecoilState(
    searchAdminBakiyeParamState
  );
  const [searchAdminBakiyeId, setSearchAdminBakiyeId] = useRecoilState(
    searchAdminBakiyeIdState
  );
  const [searchAdminBakiyeName, setSearchAdminBakiyeName] = useRecoilState(
    searchAdminBakiyeNameState
  );
  const [searchAdminBakiyeEmail, setSearchAdminBakiyeEmail] = useRecoilState(
    searchAdminBakiyeEmailState
  );
  const [searchAdminBakiyeKonsimento, setSearchAdminBakiyeKonsimento] =
    useRecoilState(searchAdminBakiyeKonsimentoState);
  const [searchAdminBakiyeDate, setSearchAdminBakiyeDate] = useRecoilState(
    searchAdminBakiyeDateState
  );

  const [, setFinanceBakiyeTotalPage] = useRecoilState(
    financeBakiyeTotalPageState
  );
  const [, setFinanceBakiyeCurrentPage] = useRecoilState(
    financeBakiyeCurrentPageState
  );
  const [, setFinanceBakiyeData] = useRecoilState(financeBakiyeDataState);

  const handleSearch = useCallback(
    async (e) => {
      e.preventDefault();
      await axios
        .get(`wallet/list`, {
          params: {
            page: 1,
            limit: 100,
            order_direction: "DESC",
            search: searchAdminBakiyeParam,
          },
        })
        .then((resp) => {
          setFinanceBakiyeData(resp.data.data);
          setFinanceBakiyeCurrentPage(resp.data.currentPage);
          setFinanceBakiyeTotalPage(resp.data.totalPages);
          setAdminBakiyeExport("basic");
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    },
    [
      searchAdminBakiyeParam,
      setAdminBakiyeExport,
      setFinanceBakiyeData,
      setFinanceBakiyeCurrentPage,
      setFinanceBakiyeTotalPage,
    ]
  );

  const handleClear = useCallback(async () => {
    setSearchAdminBakiyeParam("");
    navigate("/finance");
    await axios
      .get(`wallet/list`, {
        params: {
          page: 1,
          limit: 100,
          order_direction: "DESC",
        },
      })
      .then((resp) => {
        setFinanceBakiyeData(resp.data.data);
        setFinanceBakiyeCurrentPage(resp.data.currentPage);
        setFinanceBakiyeTotalPage(resp.data.totalPages);
        setAdminBakiyeExport("detailed");
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [
    setAdminBakiyeExport,
    setSearchAdminBakiyeParam,
    setFinanceBakiyeData,
    setFinanceBakiyeCurrentPage,
    setFinanceBakiyeTotalPage,
    navigate,
  ]);

  const handleDetailedSearch = useCallback(
    async (e) => {
      if (e) e.preventDefault();
      await axios
        .get(`wallet/list`, {
          params: {
            page: 1,
            limit: 100,
            order_direction: "DESC",
            detailed: 1,
            member_id: searchAdminBakiyeId,
            member_name: searchAdminBakiyeName,
            member_email: searchAdminBakiyeEmail,
            parcel_ref_no: searchAdminBakiyeKonsimento,
            created_at: searchAdminBakiyeDate,
          },
        })
        .then((resp) => {
          setFinanceBakiyeData(resp.data.data);
          setFinanceBakiyeCurrentPage(resp.data.currentPage);
          setFinanceBakiyeTotalPage(resp.data.totalPages);
          setAdminBakiyeExport("detailed");
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    },
    [
      setFinanceBakiyeData,
      setAdminBakiyeExport,
      setFinanceBakiyeCurrentPage,
      setFinanceBakiyeTotalPage,
      navigate,
      searchAdminBakiyeId,
      searchAdminBakiyeName,
      searchAdminBakiyeEmail,
      searchAdminBakiyeKonsimento,
      searchAdminBakiyeDate,
    ]
  );

  const clearId = useCallback(() => {
    setSearchAdminBakiyeId("");
    handleClear();
  }, [handleClear, setSearchAdminBakiyeId]);

  const clearName = useCallback(() => {
    setSearchAdminBakiyeName("");
    handleClear();
  }, [setSearchAdminBakiyeName, handleClear]);

  const clearEmail = useCallback(() => {
    setSearchAdminBakiyeEmail("");
    handleClear();
  }, [setSearchAdminBakiyeEmail, handleClear]);

  const clearAwb = useCallback(() => {
    setSearchAdminBakiyeKonsimento("");
    handleClear();
  }, [setSearchAdminBakiyeKonsimento, handleClear]);

  const clearDate = useCallback(() => {
    setSearchAdminBakiyeDate("");
    handleClear();
  }, [setSearchAdminBakiyeDate, handleClear]);

  useEffect(() => {
    if (awbParam) {
      setExpand(true);
      handleDetailedSearch();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: "var(--lighterBg)",
          padding: "20px",
          borderRadius: "var(--inputBorderRadius)",
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          gap: "var(--gap)",
        }}
      >
        <form
          style={{ display: "flex", gap: "var(--gap)" }}
          onSubmit={handleSearch}
        >
          <TextField
            size="small"
            fullWidth
            label="Arama"
            placeholder="Üyer ID, Üye Adı, Mail Adresi "
            autoComplete="off"
            value={searchAdminBakiyeParam}
            onChange={(e) => setSearchAdminBakiyeParam(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                handleClear();
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchAdminBakiyeParam.length !== 0 && (
                    <ClearOutlinedIcon
                      sx={{ cursor: "pointer" }}
                      onClick={handleClear}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Button type="submit" className="iyzi-button">
            Ara
          </Button>
          <Button
            type="button"
            sx={{ minWidth: "150px" }}
            className="iyzi-button"
            onClick={() => setExpand(!expand)}
          >
            {expand ? "Detaylı Aramayı Kapat" : "Detaylı Aramayı Aç"}
          </Button>
        </form>
        <Accordion
          sx={{ boxShadow: "none" }}
          expanded={expand}
          onChange={() => setExpand(!expand)}
        >
          <AccordionSummary
            sx={{ minHeight: "0!important", height: "0!important", padding: 0 }}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          ></AccordionSummary>
          <AccordionDetails sx={{ padding: "20px" }}>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "var(--gap)",
              }}
              onSubmit={handleDetailedSearch}
            >
              <div style={{ display: "flex", gap: "var(--gap)" }}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  label="Üye ID"
                  value={searchAdminBakiyeId}
                  onChange={(e) => setSearchAdminBakiyeId(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      clearId();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchAdminBakiyeId.length !== 0 && (
                          <ClearOutlinedIcon
                            sx={{ cursor: "pointer" }}
                            onClick={clearId}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  label="Üye İsmi"
                  value={searchAdminBakiyeName}
                  onChange={(e) => setSearchAdminBakiyeName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      clearName();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchAdminBakiyeName.length !== 0 && (
                          <ClearOutlinedIcon
                            sx={{ cursor: "pointer" }}
                            onClick={clearName}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  value={searchAdminBakiyeEmail}
                  onChange={(e) => setSearchAdminBakiyeEmail(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      clearEmail();
                    }
                  }}
                  label="Üye Email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchAdminBakiyeEmail.length !== 0 && (
                          <ClearOutlinedIcon
                            sx={{ cursor: "pointer" }}
                            onClick={clearEmail}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  label="Konşimento Numarası"
                  value={searchAdminBakiyeKonsimento}
                  onChange={(e) =>
                    setSearchAdminBakiyeKonsimento(e.target.value)
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      clearAwb();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchAdminBakiyeKonsimento.length !== 0 && (
                          <ClearOutlinedIcon
                            sx={{ cursor: "pointer" }}
                            onClick={clearAwb}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  label="Tarih"
                  type="date"
                  value={searchAdminBakiyeDate}
                  onChange={(e) => setSearchAdminBakiyeDate(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      clearDate();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchAdminBakiyeDate.length !== 0 && (
                          <ClearOutlinedIcon
                            sx={{ cursor: "pointer" }}
                            onClick={clearDate}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "var(--gap)",
                }}
              >
                <Button type="submit" className="iyzi-button">
                  Detaylı Ara
                </Button>
              </div>
            </form>
          </AccordionDetails>
        </Accordion>
      </Box>
    </ThemeProvider>
  );
};

export default FinanceBakiyeSearch;
