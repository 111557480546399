import { TableCell, TableHead, TableRow } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import {
  financeBekleyenOdemelerCurrentPageState,
  financeBekleyenOdemelerDataState,
  financeBekleyenOdemelerTotalPageState,
} from "../../../../atoms/finance";
import axios from "../../../../api/axios";
import Errors from "../../../../utils/Errors";

const BekleyenOdemelerTableHead = () => {
  const [, setFinanceBekleyenOdemelerDataState] = useRecoilState(financeBekleyenOdemelerDataState);
  const [, setFinanceBekleyenOdemelerTotalPageState] = useRecoilState(
    financeBekleyenOdemelerTotalPageState
  );
  const [financeBekleyenOdemelerCurrentPage, setFinanceBekleyenOdemelerCurrentPageState] =
    useRecoilState(financeBekleyenOdemelerCurrentPageState);

  const [activeSort, setActiveSort] = useState([
    { column: "member_id", direction: null },
    { column: "created_at", direction: null },
    { column: "member_invoice_no", direction: null },
    { column: "supplier_compensation_amount", direction: null },
    { column: "status", direction: null },
  ]);

  const handlePageChange = useCallback(
    async (orderBy) => {
      const updatedSort = activeSort.map((sort) => {
        if (sort.column === orderBy) {
          return {
            ...sort,
            direction: sort.direction === "ASC" ? "DESC" : "ASC",
          };
        }
        return {
          ...sort,
          direction: null, // Diğer sütunların yönünü varsayılan duruma sıfırlayın
        };
      });

      setActiveSort(updatedSort);

      const currentDirection = updatedSort.find((sort) => sort.column === orderBy).direction;

      await axios
        .get(`wallet/parcel-price-information-details`, {
          params: {
            page: financeBekleyenOdemelerCurrentPage,
            limit: 100,
            order_by: orderBy,
            order_direction: currentDirection,
          },
        })
        .then((resp) => {
          setFinanceBekleyenOdemelerDataState(resp.data.data);
          setFinanceBekleyenOdemelerCurrentPageState(resp.data.currentPage);
          setFinanceBekleyenOdemelerTotalPageState(resp.data.totalPages);
        })
        .catch((error) => Errors(error));
    },
    [
      setFinanceBekleyenOdemelerDataState,
      setFinanceBekleyenOdemelerCurrentPageState,
      setFinanceBekleyenOdemelerTotalPageState,
      financeBekleyenOdemelerCurrentPage,
      activeSort,
    ]
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ fontWeight: 700 }}>Muhasebe Kodu</TableCell>
        <TableCell
          sx={{ fontWeight: 700, cursor: "pointer" }}
          onClick={() => handlePageChange("line_no")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Müşteri ID
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "member_id")?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Müşteri Adı</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Iyzi AWB</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Tedarikçi AWB</TableCell>
        <TableCell
          sx={{ fontWeight: 700, cursor: "pointer" }}
          onClick={() => handlePageChange("line_no")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Açıklama
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "member_id")?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          sx={{ fontWeight: 700, cursor: "pointer" }}
          onClick={() => handlePageChange("line_no")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Tarih
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "member_id")?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Gecikme</TableCell>
        <TableCell
          sx={{ fontWeight: 700, cursor: "pointer" }}
          onClick={() => handlePageChange("line_no")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Tutar
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "member_id")?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default BekleyenOdemelerTableHead;
