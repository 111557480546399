import "../../../style/courierRegistration/CourierDeliveredAdmin.css";
import {
  Box,
  Button,
  createTheme,
  TextField,
  ThemeProvider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  courierDeliveredAdminOpenState,
  courierListAdminState,
  courierRightItemsState,
  deliveredItemState,
} from "../../../atoms/courierRegistration";
import CourierDeliveredItemsAdmin from "./CourierDeliveredItemsAdmin";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const CourierDeliveredAdmin = () => {
  const [openD, setOpenD] = useState(false);
  const [fullname, setFullname] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);

  const deliveredItem = useRecoilValue(deliveredItemState);
  const [courierListAdmin, setCourierListAdmin] = useRecoilState(
    courierListAdminState
  );
  const [courierRightItems, setCourierRightItems] = useRecoilState(
    courierRightItemsState
  );

  const [, setCourierDeliveredAdminOpen] = useRecoilState(
    courierDeliveredAdminOpenState
  );

  const handleRequest = useCallback(async () => {
    setLoading(true);
    await axios
      .post(`booking/update/${deliveredItem.id}`, {
        status: 3,
        received_parcels: courierRightItems.join(),
        submitter: fullname,
      })
      .then((response) => {
        toast.success(response.data.message);
        setCourierListAdmin([
          response.data.data,
          ...courierListAdmin.filter((e) => e.id !== deliveredItem.id),
        ]);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => Errors(error))
      .finally(() => {
        setLoading(false);
        setCourierRightItems([]);
        setCourierDeliveredAdminOpen(false);
      });
  }, [
    deliveredItem,
    courierRightItems,
    fullname,
    setCourierDeliveredAdminOpen,
    setCourierRightItems,
    setCourierListAdmin,
    courierListAdmin,
  ]);

  const handleCloseDeliveredAdmin = useCallback(() => {
    setCourierRightItems([]);
    setCourierDeliveredAdminOpen(false);
  }, [setCourierRightItems, setCourierDeliveredAdminOpen]);

  const handleSendComplete = useCallback(
    (e) => {
      e.preventDefault();
      const fullNameValue = `${firstName} ${lastName}`;
      setFullname(fullNameValue);
      setOpenD(true);
    },
    [firstName, lastName, setOpenD]
  );

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={openD}
        onClose={(e) => setOpenD(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <img
          style={{ width: "24px", margin: " 10px auto 0" }}
          src="/images/logo/logo_white_mini.svg"
          alt="IyziShip Icon"
        />
        <DialogTitle id="alert-dialog-title">Teslim Al</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Kaydı tamamlamak için Onayla butonuna basınız.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ dispay: "flex", gap: "var(--gap)" }}>
          <Button
            sx={{
              textTransform: "none",
              fontSize: "13px",
              color: "var(--priceText)",
              borderRadius: "var(--buttonBorderRadius)",
              backgroundColor: "var(--lighterBg)",
              border: "1px solid var(--inputBorderColor)",
              ":hover": {
                backgroundColor: "var(--inputBorderColor)",
              },
            }}
            fullWidth
            onClick={() => setOpenD(false)}
          >
            İptal
          </Button>
          {loading ? (
            <Box
              sx={{
                maxWidth: "187px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                sx={{
                  width: "24px!important",
                  height: "24px!important",
                }}
              />
            </Box>
          ) : (
            <Button
              sx={{
                textTransform: "none",
                fontSize: "13px",
                color: "var(--lighterBg)",
                backgroundColor: "var(--info)",
                border: "1px solid var(--info)",
                borderRadius: "var(--buttonBorderRadius)",
                ":hover": {
                  backgroundColor: "var(--blueBrand)",
                  border: "1px solid var(--blueBrand)",
                },
              }}
              fullWidth
              onClick={handleRequest}
            >
              Onayla
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <form onSubmit={handleSendComplete} className="courier-delivered-admin">
        <CourierDeliveredItemsAdmin />
        <Box className="courier-delivered-input">
          <Typography className="delivered-input-headline">
            AWB girişini tamamladıktan sonra yükleri size{" "}
            <span className="headline-span">
              teslim edenin adını ve soyadını
            </span>{" "}
            yazarak işlemi tamamlayabilirsiniz
          </Typography>
          <TextField
            label="Ad"
            size="small"
            fullWidth
            autoComplete="off"
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            label="Soyad"
            size="small"
            fullWidth
            autoComplete="off"
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Box>
        <Box className="courier-delivered-button">
          <Button
            onClick={handleCloseDeliveredAdmin}
            type="button"
            className="abort"
          >
            İptal
          </Button>
          <Button
            sx={{
              opacity: courierRightItems.length === 0 ? 0.3 : 1,
            }}
            disabled={courierRightItems.length === Number(0) ? true : false}
            type="submit"
            className="send"
          >
            Tamamla
          </Button>
        </Box>
      </form>
    </ThemeProvider>
  );
};

export default CourierDeliveredAdmin;
