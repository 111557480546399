import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  supportCurrentPageState,
  supportListState,
  supportTotalPageState,
  supportOrderDirectionState,
  supportOrderByState,
  supportPageLimitState
} from "../../../atoms/support";
import { useCallback } from "react";
import SupportListTableHeadAdmin from "./supportTable/SupportListTableHeadAdmin";
import SupportListTableBodyAdmin from "./supportTable/SupportListTableBodyAdmin";
import axios from "../../../api/axios";
import Errors from "../../../utils/Errors";

const SupportListTableAdmin = () => {
  const supportOrderDirection = useRecoilValue(supportOrderDirectionState);
  const supportOrderBy = useRecoilValue(supportOrderByState);
  const supportPageLimit = useRecoilValue(supportPageLimitState);
  const [supportList, setSupportList] = useRecoilState(supportListState);
  const [supportTotalPage, setSupportTotalPage] = useRecoilState(
    supportTotalPageState
  );
  const [supportCurrentPage, setSupportCurrentPage] = useRecoilState(
    supportCurrentPageState
  );

  const handlePageChange = useCallback(
    async (e, pageValue) => {
      await axios
        .get(
          `ticket?order_direction=${supportOrderDirection}&limit=${supportPageLimit}&page=${pageValue}&order_by=updated_at`
        )
        .then((response) => {
          setSupportCurrentPage(pageValue);
          setSupportList(response.data.data);
          setSupportTotalPage(response.data.totalPages);
        })
        .catch((error) => {
          Errors(error);
        });
    },
    [setSupportList, setSupportTotalPage, setSupportCurrentPage]
  );

  return (
    <>
      <TableContainer
        sx={{
          backgroundColor: "var(--lighterBg)",
          borderRadius: "var(--inputBorderRadius)",
          boxShadow: "0px 1px 10px rgb(0 0 0 / 25%)",
        }}
      >
        <Table>
          <SupportListTableHeadAdmin />
          <TableBody>
            {supportList?.map((item, key) => (
              <SupportListTableBodyAdmin key={key} item={item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="support-list-pagination-wrapper">
        <Pagination
          showFirstButton
          showLastButton
          sx={{ display: "flex", justifyContent: "flex-end" }}
          size="small"
          count={supportTotalPage}
          page={supportCurrentPage}
          onChange={handlePageChange}
        />
      </Box>
    </>
  );
};

export default SupportListTableAdmin;
