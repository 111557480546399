const OpenBase64InNewTab = (base64, contentType) => {
  // Yeni bir pencere veya sekme aç
  const newWindow = window.open("", "_blank");

  // Yeni pencerenin içeriğini oluştur
  newWindow.document.write(`
    <iframe width="100%" height="100%" src="data:${contentType};base64, ${base64}"></iframe>
  `);
};

export default OpenBase64InNewTab;
