import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Errors from "../../../../utils/Errors";
import { useEffect, useState } from "react";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import ExchangeRateSetting from "./systemSettingOptions/ExchangeRateSetting";
import MemberProfitSetting from "./systemSettingOptions/MemberProfitSetting";
import PreLoginMemberProfit from "./systemSettingOptions/PreLoginMemberProfitSetting";
import DefaultMemberCustomerGroupSetting from "./systemSettingOptions/DefaultMemberCustomerGroupSetting";
import PaymentTypeSetting from "./systemSettingOptions/PaymentTypeSetting";
import DefaultDomesticSupplierSetting from "./systemSettingOptions/DefaultDomesticSupplierSetting";
import FreeShippingUpperLimitSetting from "./systemSettingOptions/FreeShippingUpperLimitSetting";
import DefaultDomesticPriceSetting from "./systemSettingOptions/DefaultDomesticPriceSetting";
import PaymentOrderSetting from "./systemSettingOptions/PaymentOrderSetting";
import RefundOrderSetting from "./systemSettingOptions/RefundOrderSetting";

const EditSystemSetting = ({ data, setData, open, setOpen, item }) => {
  const [type, setType] = useState(item?.type);
  const [subType, setSubType] = useState(item?.sub_type);
  const [value, setValue] = useState(item?.value);
  const [status, setStatus] = useState(item?.status);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setType("");
    setSubType("");
    setValue("");
    setStatus("");
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    setLoading(true);
    axios
      .post(`system-setting/update/${item?.id}`, {
        type,
        sub_type: subType,
        value,
        status,
      })
      .then((response) => {
        toast.success(response.data?.message);
        handleClose();
        const updatedItem = data.map((e) => {
          if (e.id === item.id) {
            return {
              ...e,
              type,
              type_name: response.data.data?.type_name,
              sub_type: subType,
              sub_type_name: response.data.data?.sub_type_name,
              value,
              value_name: response.data.data?.value_name,
              status,
              status_name: response.data.data?.status_name,
            };
          }
          return e;
        });

        setData(updatedItem);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (open) {
      setType(item?.type);
      setSubType(item?.sub_type);
      setValue(item?.value);
      setStatus(item?.status);
    }
  }, [open, item]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{item?.type_name} Düzenle</DialogTitle>
      <form onSubmit={handleSubmit} style={{ minWidth: 600 }}>
        <DialogContent>
          {item.type === "exchange_rate" && (
            <ExchangeRateSetting
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
          {type === "member_profit" && (
            <MemberProfitSetting
              subType={subType}
              setSubType={setSubType}
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}

          {type === "pre_login_member_profit" && (
            <PreLoginMemberProfit
              subType={subType}
              setSubType={setSubType}
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
          {type === "default_member_customer_group" && (
            <DefaultMemberCustomerGroupSetting
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
          {type === "payment_type" && (
            <PaymentTypeSetting
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
          {type === "default_domestic_supplier" && (
            <DefaultDomesticSupplierSetting
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
          {type === "free_shipping_upper_limit" && (
            <FreeShippingUpperLimitSetting
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
          {type === "default_domestic_price" && (
            <DefaultDomesticPriceSetting
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
          {type === "payment_order" && (
            <PaymentOrderSetting
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
          {type === "refund_order" && (
            <RefundOrderSetting
              value={value}
              setValue={setValue}
              status={status}
              setStatus={setStatus}
              handleClose={handleClose}
              loading={loading}
            />
          )}
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default EditSystemSetting;
