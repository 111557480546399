import {
  Modal,
  Table,
  TableBody,
  TableContainer,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { useCallback } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FaturaKonsimentoTableHead from "./FaturaKonsimentoTableHead";
import FaturaKonsimentoTableBody from "./FaturaKonsimentoTableBody";
import {
  faturaKonsimentoDataState,
  faturaKonsimentoModalState,
} from "../../../../atoms/finance";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          margin: "20px",
          overflow: "auto",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          display: "flex",
          alignItems: "center",
          gap: "10px",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "14px",
          opacity: 0.8,
        },
      },
    },
  },
});

const FaturaKonsimentoModal = () => {
  const [faturaKonsimentoData, setFaturaKonsimentoData] = useRecoilState(
    faturaKonsimentoDataState
  );
  const [faturaKonsimentoModal, setFaturaKonsimentoModal] = useRecoilState(
    faturaKonsimentoModalState
  );

  const handleClose = useCallback(() => {
    setFaturaKonsimentoModal(false);
    setFaturaKonsimentoData([]);
  }, [setFaturaKonsimentoModal, setFaturaKonsimentoData]);

  return (
    <ThemeProvider theme={theme}>
      <Modal open={faturaKonsimentoModal} onClose={handleClose}>
        <TableContainer
          sx={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            padding: "20px",
            outline: 0,
            display: "grid",
            gap: "var(--gap)",
            width: "100%",
            maxWidth: "1100px",
            margin: "auto",
            position: "relative",
          }}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              cursor: "pointer",
              color: "var(--priceText)",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleClose}
          />
          <Table>
            <FaturaKonsimentoTableHead />
            <TableBody>
              {faturaKonsimentoData?.map((item, key) => (
                <FaturaKonsimentoTableBody key={key} item={item} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Modal>
    </ThemeProvider>
  );
};

export default FaturaKonsimentoModal;
