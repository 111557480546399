import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  createTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useCallback } from "react";
import axios from "../../../../api/axios";
import {
  myShipmentListLoadingState,
  myShipmentsListLimitState,
  shipmentStatusState,
  shipmentListOrderDirectionState,
  timeState,
  senderCountryState,
  senderCountryCodeState,
  receiverCountryState,
  receiverCountryCodeState,
  selectedSupplierState,
  selectedPriceStatusState,
  selectedPriceGiveStatusState,
  selectedBeevisionStatusState,
  selectedExportStatusState,
  selectedDomesticStatusState,
  selectedCaseStatusState,
  awbState,
  domNoState,
  refIdState,
  senderState,
  receiverState,
  detailedStatusState,
  basicSearchErrorState,
  errorSetState,
  erasedShipmentState,
  timeStartState,
  timeEndState,
  selectedPacTypeState,
} from "../../../../atoms/myShipments";
import {
  PriceControlScreenDataState,
  PriceControlScreenTotalPageState,
} from "../../../../atoms/priceControl";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import countries from "../../../../json/countries.json";
import { searchAdminBakiyeIdState } from "../../../../atoms/finance";
import Errors from "../../../../utils/Errors";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          color: "currentcolor",
        },
        icon: {
          color: "currentcolor",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "currentcolor",
        },
      },
    },
  },
});

const PriceControlScreenDetailedSearch = () => {
  const navigate = useNavigate();

  const [time, setTime] = useRecoilState(timeState);
  const [timeStart, setTimeStart] = useRecoilState(timeStartState);
  const [timeEnd, setTimeEnd] = useRecoilState(timeEndState);

  const [senderCountry, setSenderCountry] = useRecoilState(senderCountryState);
  const [senderCountryCode, setSenderCountryCode] = useRecoilState(senderCountryCodeState);
  const [receiverCountry, setReceiverCountry] = useRecoilState(receiverCountryState);
  const [receiverCountryCode, setReceiverCountryCode] = useRecoilState(receiverCountryCodeState);

  const [selectedSupplier, setSelectedSupplier] = useRecoilState(selectedSupplierState);

  const [selectedBeevision, setSelectedBeevision] = useRecoilState(selectedBeevisionStatusState);
  const [pacType, setPacType] = useRecoilState(selectedPacTypeState);

  const [selectedPriceStatus, setSelectedPriceStatus] = useRecoilState(selectedPriceStatusState);
  const [selectedExportStatus, setSelectedExportStatus] = useRecoilState(selectedExportStatusState);
  const [selectedDomesticStatus, setSelectedDomesticStatus] = useRecoilState(
    selectedDomesticStatusState
  );
  const [selectedPriceGiveStatus, setSelectedPriceGiveStatus] = useRecoilState(
    selectedPriceGiveStatusState
  );
  const [selectedCaseStatus, setSelectedCaseStatus] = useRecoilState(selectedCaseStatusState);

  const setPriceControlScreenData = useSetRecoilState(PriceControlScreenDataState);
  const setPriceControlScreenTotalPage = useSetRecoilState(PriceControlScreenTotalPageState);

  const [awb, setAwb] = useRecoilState(awbState);
  const [domNo, setDomNo] = useRecoilState(domNoState);
  const [refId, setRefId] = useRecoilState(refIdState);
  const [sender, setSender] = useRecoilState(senderState);
  const [receiver, setReceiver] = useRecoilState(receiverState);
  const [erasedShipment, setErasedShipment] = useRecoilState(erasedShipmentState);

  const setMyShipmentListLoading = useSetRecoilState(myShipmentListLoadingState);

  const myShipmentsListLimit = useRecoilValue(myShipmentsListLimitState);
  const shipmentOrderDirectionPage = useRecoilValue(shipmentListOrderDirectionState);
  const [shipmentStatus, setShipmentStatus] = useRecoilState(shipmentStatusState);

  const setDetailedStatus = useSetRecoilState(detailedStatusState);
  const setBasicSearchError = useSetRecoilState(basicSearchErrorState);
  const setErrorSet = useSetRecoilState(errorSetState);

  //* Member ID
  const [searchAdminBakiyeId, setSearchAdminBakiyeId] = useRecoilState(searchAdminBakiyeIdState);

  const handleDetailedSearch = useCallback(
    async (e) => {
      if (e) e.preventDefault();

      setMyShipmentListLoading(true);
      setDetailedStatus(1);

      const params = {
        order_direction: shipmentOrderDirectionPage,
        limit: myShipmentsListLimit,
        page: 1,
        unlimited: 1,
        detailed: 1,
        ...(awb !== "" && { tracking_awb: awb }),
        ...(domNo !== "" && { domestic_awb: domNo }),
        ...(refId !== "" && { parcel_ref_no: refId }),
        ...(selectedSupplier !== "" && {
          supplier_id: Number(selectedSupplier),
        }),
        ...(shipmentStatus !== "" && { status: shipmentStatus }),
        ...(time !== "" && { arrived_to_office_date: time }),
        ...(timeStart !== "" && { arrived_from_date: timeStart }),
        ...(timeEnd !== "" && { arrived_to_date: timeEnd }),
        ...(sender !== "" && { sender_name: sender }),
        ...(receiver !== "" && { receiver_name: receiver }),
        ...(pacType !== "" && { packaging_type: pacType }),
        ...(senderCountryCode !== "" && { sender_country: senderCountryCode }),
        ...(receiverCountryCode !== "" && {
          receiver_country: receiverCountryCode,
        }),
        ...(selectedExportStatus !== "" && {
          parcel_type: selectedExportStatus,
        }),
        ...(selectedPriceStatus !== "" && { paid: selectedPriceStatus }),
        ...(selectedPriceGiveStatus !== "" && {
          price_approved: selectedPriceGiveStatus,
        }),
        ...(selectedBeevision !== "" && { beevision_read: selectedBeevision }),
        ...(selectedDomesticStatus !== "" && {
          is_domestic: selectedDomesticStatus,
        }),
        ...(selectedCaseStatus !== "" && { is_case: selectedCaseStatus }),
        ...(searchAdminBakiyeId !== "" && { member_id: searchAdminBakiyeId }),
        ...(erasedShipment !== "" && { deleted: erasedShipment }),
      };

      const filteredParam = Object.fromEntries(
        Object.entries(params).filter(([key, value]) => value !== "" && value !== null)
      );

      await axios
        .get(
          `parcel?&beevision_read=1,2&order_by=arrived_to_office_date&`,
          { params: filteredParam }
        )
        .then((response) => {
          setPriceControlScreenData(response.data.data);
          setPriceControlScreenTotalPage(response.data.totalPages);
          setErrorSet(true);
          setBasicSearchError(false);
        })
        .catch((error) => {
          Errors(error);
        })
        .finally(() => {
          setMyShipmentListLoading(false);
        });
    },
    [
      setPriceControlScreenData,
      setMyShipmentListLoading,
      setDetailedStatus,
      setPriceControlScreenTotalPage,
      setErrorSet,
      setBasicSearchError,
      erasedShipment,
      awb,
      domNo,
      time,
      shipmentOrderDirectionPage,
      myShipmentsListLimit,
      refId,
      selectedSupplier,
      shipmentStatus,
      sender,
      senderCountryCode,
      receiver,
      receiverCountryCode,
      selectedExportStatus,
      selectedDomesticStatus,
      selectedCaseStatus,
      selectedPriceStatus,
      selectedPriceGiveStatus,
      selectedBeevision,
      searchAdminBakiyeId,
      timeStart,
      timeEnd,
      pacType,
    ]
  );

  const handleSenderCountry = useCallback(
    (e) => {
      const value = e.target.value;
      setSenderCountry(value.slice(0, -3));
      setSenderCountryCode(value.substr(value.length - 2));
    },
    [setSenderCountry, setSenderCountryCode]
  );

  const handleReceiverCountry = useCallback(
    (e) => {
      const value = e.target.value;
      setReceiverCountry(value.slice(0, -3));
      setReceiverCountryCode(value.substr(value.length - 2));
    },
    [setReceiverCountry, setReceiverCountryCode]
  );

  const handleClear = useCallback(() => {
    const params = {
      order_direction: shipmentOrderDirectionPage,
      limit: myShipmentsListLimit,
      page: 1,
    };

    axios
      .get(
        `parcel?&detailed=1&limit=1000&page=${1}&beevision_read=1,2&order_by=arrived_to_office_date&order_direction=DESC&`,
        { params: params }
      )
      .then((response) => {
        setPriceControlScreenData(response.data.data);
        setPriceControlScreenTotalPage(response.data.totalPages);
        setErrorSet(true);
        setBasicSearchError(false);

        // Clear States
        setTime("");
        setTimeStart("");
        setTimeEnd("");
        setSenderCountry("");
        setSenderCountryCode("");
        setReceiverCountry("");
        setReceiverCountryCode("");
        setSelectedSupplier("");
        setSelectedBeevision("");
        setSelectedPriceStatus("");
        setSelectedPriceGiveStatus("");
        setSelectedExportStatus("");
        setSelectedDomesticStatus("");
        setAwb("");
        setDomNo("");
        setRefId("");
        setSender("");
        setReceiver("");
        setDetailedStatus(0);
        setSearchAdminBakiyeId("");
        setErasedShipment(0);
        setShipmentStatus("");
        setPacType("");
        // Clear Search Parameters
        if (window.location.href.includes("price-control-screen")) {
          navigate("/operation-progress/price-control-screen");
        } else {
          navigate("/my-shipment");
        }
      })
      .catch((error) => {
        Errors(error);
      })
      .finally(() => {
        setMyShipmentListLoading(false);
      });
  }, [
    navigate,
    setSearchAdminBakiyeId,
    setPriceControlScreenData,
    setMyShipmentListLoading,
    setSelectedPriceGiveStatus,
    setDetailedStatus,
    setPriceControlScreenTotalPage,
    setErrorSet,
    setBasicSearchError,
    setPacType,
    shipmentOrderDirectionPage,
    myShipmentsListLimit,
    setTimeEnd,
    setTimeStart,
    setTime,
    setSenderCountry,
    setSenderCountryCode,
    setReceiverCountry,
    setReceiverCountryCode,
    setSelectedSupplier,
    setSelectedBeevision,
    setSelectedPriceStatus,
    setSelectedExportStatus,
    setSelectedDomesticStatus,
    setAwb,
    setDomNo,
    setRefId,
    setSender,
    setReceiver,
    setShipmentStatus,
    setErasedShipment,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={handleDetailedSearch} className="myShipments-detailed-search-wrapper">
        <h6 className="myShipments-detailed-search-title">
          <TravelExploreOutlinedIcon />
          Detaylı Arama
        </h6>
        <Box className="myShipments-detailed-search">
          <TextField
            autoComplete="off"
            fullWidth
            size="small"
            label="Müşteri ID"
            value={searchAdminBakiyeId}
            onChange={(e) => setSearchAdminBakiyeId(e.target.value)}
          />
          <TextField
            autoComplete="off"
            fullWidth
            size="small"
            label="Müşteri Adı"
            value={sender}
            onChange={(e) => setSender(e.target.value)}
          />
          <TextField
            autoComplete="off"
            fullWidth
            size="small"
            label="Ref No"
            value={refId}
            onChange={(e) => setRefId(e.target.value)}
          />
          <TextField
            value={awb}
            onChange={(e) => setAwb(e.target.value)}
            autoComplete="off"
            fullWidth
            size="small"
            label="AWB No"
          />
          <TextField
            value={domNo}
            onChange={(e) => setDomNo(e.target.value)}
            autoComplete="off"
            fullWidth
            size="small"
            label="Dom No"
          />

          <FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: "14px" }} id="supplier-select-label">
              Tedarikçi
            </InputLabel>
            <Select
              labelId="supplier-select-label"
              id="supplier-select"
              label="Tedarikçi"
              value={selectedSupplier}
              onChange={(e) => setSelectedSupplier(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={"301"}>DHL</MenuItem>
              <MenuItem value={"338"}>DHL ECO</MenuItem>
              <MenuItem value={"302"}>DPD / GLS</MenuItem>
              <MenuItem value={"303"}>Fedex Envelope</MenuItem>
              <MenuItem value={"305"}>Fedex</MenuItem>
              <MenuItem value={"306"}>USPS</MenuItem>
              <MenuItem value={"300"}>TNT</MenuItem>
              <MenuItem value={"400"}>TNT Import</MenuItem>
              <MenuItem value={"342"}>UPS Saver</MenuItem>
              <MenuItem value={"307"}>ROYAL MAIL-ZARF</MenuItem>
              <MenuItem value={"308"}>ROYAL MAIL-KÜÇÜK BOY KOLİ</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: "14px" }} id="supplier-select-label">
              Beevision
            </InputLabel>
            <Select
              labelId="supplier-select-label"
              id="supplier-select"
              label="Beevision"
              value={selectedBeevision}
              onChange={(e) => setSelectedBeevision(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={"0"}>Geçmedi</MenuItem>
              <MenuItem value={"1"}>Beevision</MenuItem>
              <MenuItem value={"2"}>Beevision - Manuel</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: "14px" }} id="supplier-select-label">
              Paket Tipi
            </InputLabel>
            <Select
              labelId="supplier-select-label"
              id="supplier-select"
              label="Paket Tipi"
              value={pacType}
              onChange={(e) => setPacType(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={"S"}>Single</MenuItem>
              <MenuItem value={"B"}>Bundle</MenuItem>
            </Select>
          </FormControl>
          <TextField
            type={"date"}
            label="Gönderi Geçirilme Tarihi"
            InputLabelProps={{ shrink: true }}
            value={time}
            onChange={(e) => setTime(e.target.value)}
            size={"small"}
          />
          <TextField
            type={"date"}
            label="Tarih Aralığı Başlangıç"
            InputLabelProps={{ shrink: true }}
            value={timeStart}
            onChange={(e) => setTimeStart(e.target.value)}
            size={"small"}
          />
          <TextField
            type={"date"}
            label="Tarih Aralığı Bitiş"
            InputLabelProps={{ shrink: true }}
            value={timeEnd}
            onChange={(e) => setTimeEnd(e.target.value)}
            size={"small"}
          />
          <Autocomplete
            id="country-sender-typing-select"
            fullWidth
            size="small"
            options={countries}
            autoHighlight
            getOptionLabel={(option) => `${option.label} ${option.code}`}
            renderOption={(props, option) => (
              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                label="Çıkış Ülkesi"
                value={senderCountry}
                onBlur={(e) => handleSenderCountry(e)}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
          <Autocomplete
            id="country-receiver-typing-select"
            fullWidth
            size="small"
            options={countries}
            autoHighlight
            getOptionLabel={(option) => `${option.label} ${option.code}`}
            renderOption={(props, option) => (
              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Varış Ülkesi"
                value={receiverCountry}
                onBlur={(e) => handleReceiverCountry(e)}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
          <FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: "14px" }} id="price-status-select-label">
              Ödeme Durumu
            </InputLabel>
            <Select
              labelId="price-status-select-label"
              id="price-status-select"
              label="Ödeme Durumu"
              value={selectedPriceStatus}
              multiline
              onChange={(e) => setSelectedPriceStatus(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={0}>Ödeme Bekleyenler</MenuItem>
              <MenuItem value={1}>Ödenenler</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: "14px" }} id="price-status-select-label">
              Silinme Durumu
            </InputLabel>
            <Select
              labelId="price-status-select-label"
              id="price-status-select"
              label="Silinme Durumu"
              value={erasedShipment}
              multiline
              onChange={(e) => setErasedShipment(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={0}>Silinmemişler</MenuItem>
              <MenuItem value={1}>Silinenler</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: "14px" }} id="export-status-select-label">
              İhracat Tipi
            </InputLabel>
            <Select
              labelId="export-status-select-label"
              id="export-status-select"
              label="İhracat Tipi"
              value={selectedExportStatus}
              onChange={(e) => setSelectedExportStatus(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={1}>Normal</MenuItem>
              <MenuItem value={2}>Hediye</MenuItem>
              <MenuItem value={3}>Mikro İhracat</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: "14px" }} id="domestic-status-select-label">
              Domestik Durumu
            </InputLabel>
            <Select
              labelId="domestic-status-select-label"
              id="domestic-status-select"
              label="Domestik Durumu"
              value={selectedDomesticStatus}
              multiline
              onChange={(e) => setSelectedDomesticStatus(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={0}>Girilmeyenler</MenuItem>
              <MenuItem value={1}>Girilenler</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: "14px" }} id="case-status-select-label">
              Case Durumu
            </InputLabel>
            <Select
              labelId="case-status-select-label"
              id="case-status-select"
              label="Case Durumu"
              value={selectedCaseStatus}
              multiline
              onChange={(e) => setSelectedCaseStatus(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={0}>Case Olmayanlar</MenuItem>
              <MenuItem value={1}>Case Olanlar</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: "14px" }} id="domestic-status-select-label">
              Fiyat
            </InputLabel>
            <Select
              labelId="domestic-status-select-label"
              id="domestic-status-select"
              label="Fiyat"
              value={selectedPriceGiveStatus}
              multiline
              onChange={(e) => setSelectedPriceGiveStatus(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={0}>Verilmedi</MenuItem>
              <MenuItem value={1}>Verildi</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ gap: "var(--gap)" }} className="myShipments-detailed-search-done">
          <Button className="iyzi-button-blue" type="submit">
            <SearchOutlinedIcon />
            Arama Yap
          </Button>
          <Button type="button" className="iyzi-button-blue" onClick={handleClear}>
            Temizle
          </Button>
        </Box>
      </form>
    </ThemeProvider>
  );
};

export default PriceControlScreenDetailedSearch;
