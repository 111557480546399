import { Table, TableBody, TableContainer } from "@mui/material";
import MemberUsersTableHeader from "./MemberUsersTableHeader";
import MemberUsersTableBody from "./MemberUsersTableBody";

const MemberUsersTable = ({ member }) => {
  return (
    <TableContainer>
      <Table>
        <MemberUsersTableHeader />
        <TableBody className="member-table">
          {member?.get_member_users?.map((user, key) => (
            <MemberUsersTableBody key={key} user={user} member={member} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MemberUsersTable;
