import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  basicSearchState,
  myShipmentListLoadingState,
  myShipmentListState,
  myShipmentsListLimitState,
  shipmentListOrderDirectionState,
  totalPagesState,
} from "../atoms/myShipments";
import { useCallback } from "react";
import { Button } from "@mui/material";
import axios from "../api/axios";
import Errors from "./Errors";

const NavigateParcelAdmin = ({ parcel, status, fontSize }) => {
  const navigate = useNavigate();

  const returnStatusNumbers =
    2.22 || 2.23 || 2.24 || 2.25 || 2.26 || 2.27 || 2.28 || 2.29;

  //* Gönderilerim sayfasının global stateleri
  const [, setBasicSearch] = useRecoilState(basicSearchState);
  const [, setMyShipmentList] = useRecoilState(myShipmentListState);
  const [, setMyShipmentListLoading] = useRecoilState(
    myShipmentListLoadingState
  );
  const [, setTotalPages] = useRecoilState(totalPagesState);

  const shipmentOrderDirectionPage = useRecoilValue(
    shipmentListOrderDirectionState
  );
  const myShipmentsListLimit = useRecoilValue(myShipmentsListLimitState);

  //TODO İadeler sayfasının global stateleri gelecek

  const handleNavigateShipment = useCallback(async () => {
    if (status === returnStatusNumbers) {
      alert("Daha iadeler sayfası bitmedi");
    } else {
      setBasicSearch(parcel);
      await axios
        .get(
          `parcel?order_direction=${shipmentOrderDirectionPage}&limit=${myShipmentsListLimit}&page=${1}&search=${parcel}`
        )
        .then((response) => {
          setMyShipmentList(response.data.data);
          setTotalPages(response.data.totalPages);
          navigate("/my-shipment");
        })
        .catch((error) => Errors(error))
        .finally(() => {
          setMyShipmentListLoading(false);
        });
    }
  }, [
    parcel,
    status,
    shipmentOrderDirectionPage,
    myShipmentsListLimit,
    returnStatusNumbers,
    setBasicSearch,
    navigate,
    setMyShipmentList,
    setMyShipmentListLoading,
    setTotalPages,
  ]);

  return (
    <Button
      sx={{
        textTransform: "none",
        fontSize: fontSize ? fontSize : "inherit",
        padding: 0,
        ":hover": { backgroundColor: "transparent" },
      }}
      onClick={handleNavigateShipment}
    >
      {parcel}
    </Button>
  );
};

export default NavigateParcelAdmin;
