import {
  Box, Button, createTheme, InputAdornment, TextField, ThemeProvider, Accordion,
  AccordionDetails,
  AccordionSummary, InputLabel, Select, MenuItem, FormControl
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import React, {useCallback, useState} from "react";
import {useRecoilState} from "recoil";
import {
  financeFaturaInvoicingCurrentPageState,
  financeFaturaInvoicingDataState,
  financeFaturaInvoicingTotalPageState,
  invoicingModalState,
  adminBakiyeInvocingExportState,
  financeFaturaSearchAdminBakiyeDateState,
  financeFaturaSearchAdminBakiyeEndDateState,
  financeFaturaSearchAdminKonsimentoState,
  financeFaturaInvoicingTotalItemsState,
  financeFaturaInvoicingTotalPriceState,
  financeFaturaSearchAdminParcelTypeState,
} from "../../../../atoms/finance";
import axios from "../../../../api/axios";
import Errors from "../../../../utils/Errors";
import FormatDate from "../../../../utils/FormatDate";
import DownloadBase64Excel from "../../../../utils/DownloadBase64Excel";
import { toast } from "react-toastify";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const FaturaInvoicingSearh = () => {
  const [, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [expand, setExpand] = useState(false);
  const [exportSelectedDesktop, setExportSelectedDesktop] = useState([]);

  const [, setFinanceFaturaInvoicingData] = useRecoilState(financeFaturaInvoicingDataState);
  const [, setFinanceFaturaInvoicingTotalPage] = useRecoilState(financeFaturaInvoicingTotalPageState);
  const [financeFaturaInvoicingTotalItems, setFinanceFaturaInvoicingTotalItems] = useRecoilState(financeFaturaInvoicingTotalItemsState);
  const [financeFaturaInvoicingTotalPrice, setFinanceFaturaInvoicingTotalPrice] = useRecoilState(financeFaturaInvoicingTotalPriceState);
  const [, setFinanceFaturaInvoicingCurrentPage] = useRecoilState(financeFaturaInvoicingCurrentPageState);
  const [, setInvoicingModal] = useRecoilState(invoicingModalState);

  const [searchAdminBakiyeParam, setSearchAdminBakiyeParam] = useState(
    ""
  );
  const [searchAdminBakiyeId, setSearchAdminBakiyeId] = useState(
    ""
  );
  const [searchAdminBakiyeName, setSearchAdminBakiyeName] = useState(
    ""
  );
  const [searchAdminBakiyeEmail, setSearchAdminBakiyeEmail] = useState(
    ""
  );
  const [searchAdminBakiyeKonsimento, setSearchAdminBakiyeKonsimento] =
  useRecoilState(financeFaturaSearchAdminKonsimentoState);
  const [searchAdminBakiyeDate, setSearchAdminBakiyeDate] = useRecoilState(
    financeFaturaSearchAdminBakiyeDateState
  );
  const [searchAdminBakiyeEndDate, setSearchAdminBakiyeEndDate] = useRecoilState(
    financeFaturaSearchAdminBakiyeEndDateState
  );
  const [searchAdminParcelType, setSearchAdminParcelType] = useRecoilState(
      financeFaturaSearchAdminParcelTypeState
  );
  const [adminBakiyeInvocingExport, setAdminBakiyeInvocingExport] = useRecoilState(adminBakiyeInvocingExportState);

  const handleInvoicingModal = () => {
    setInvoicingModal(true);
  }

  const handleDetailedSearch = useCallback(
    async (e) => {
      if (e) e.preventDefault();
      await axios
        .get(`invoice/list/billings`, {
          params: {
            page: 1,
            limit: 100,
            order_direction: "DESC",
            detailed: 1,
            member_id: searchAdminBakiyeId,
            member_name: searchAdminBakiyeName,
            uyumsoft_code: searchAdminBakiyeEmail,
            parcel_ref_no: searchAdminBakiyeKonsimento,
            start_date: searchAdminBakiyeDate,
            end_date: searchAdminBakiyeEndDate,
            parcel_type: searchAdminParcelType,
          },
        })
        .then((resp) => {
          toast.success(resp.data.message);
          setAdminBakiyeInvocingExport("detailed");
          setFinanceFaturaInvoicingData(resp.data.data);
          setFinanceFaturaInvoicingTotalItems(resp.data.totalItems);
          setFinanceFaturaInvoicingTotalPrice(resp.data.totalPrice);
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    },
    [
      searchAdminBakiyeId,
      searchAdminBakiyeName,
      searchAdminBakiyeEmail,
      searchAdminBakiyeKonsimento,
      searchAdminBakiyeDate,
      searchAdminBakiyeEndDate,
      searchAdminParcelType,
      setAdminBakiyeInvocingExport,
      setFinanceFaturaInvoicingTotalItems,
      setFinanceFaturaInvoicingTotalPrice,
    ]
  );

  const handleClear = useCallback(() => {
    setSearchParam("");
    axios
      .get(`invoice/list/billings`, {
        params: {
          page: 1,
          limit: 500,
          order_direction: "DESC",
        },
      })
      .then((res) => {
        setFinanceFaturaInvoicingData(res.data.data);
        setFinanceFaturaInvoicingTotalPage(res.data.totalPage);
        setFinanceFaturaInvoicingTotalItems(res.data.totalItems);
        setFinanceFaturaInvoicingTotalPrice(res.data.totalPrice);
        setFinanceFaturaInvoicingCurrentPage(res.data.currentPage);
        setAdminBakiyeInvocingExport("detailed");
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [setFinanceFaturaInvoicingData, setFinanceFaturaInvoicingTotalPage, setFinanceFaturaInvoicingTotalItems,
    setFinanceFaturaInvoicingTotalPrice, setFinanceFaturaInvoicingCurrentPage, setAdminBakiyeInvocingExport]);

  const clearId = useCallback(() => {
    setSearchAdminBakiyeId("");
    handleClear();
  }, [handleClear, setSearchAdminBakiyeId]);

  const clearName = useCallback(() => {
    setSearchAdminBakiyeName("");
    handleClear();
  }, [setSearchAdminBakiyeName, handleClear]);

  const clearEmail = useCallback(() => {
    setSearchAdminBakiyeEmail("");
    handleClear();
  }, [setSearchAdminBakiyeEmail, handleClear]);

  const clearAwb = useCallback(() => {
    setSearchAdminBakiyeKonsimento("");
    handleClear();
  }, [setSearchAdminBakiyeKonsimento, handleClear]);

  const clearDate = useCallback(() => {
    setSearchAdminBakiyeDate("");
  }, [setSearchAdminBakiyeDate]);

  const clearEndDate = useCallback(() => {
    setSearchAdminBakiyeEndDate("");
  }, [setSearchAdminBakiyeEndDate]);

  const clearParcelType = useCallback(() => {
    setSearchAdminParcelType("");
  }, [setSearchAdminParcelType]);

  const handleSearch = useCallback(() => {
    axios
      .get(`invoice/list/billings`, {
        params: {
          page: 1,
          limit: 500,
          order_direction: "DESC",
          search: searchParam,
        },
      })
      .then((resp) => {
        setFinanceFaturaInvoicingData(resp.data.data);
        setFinanceFaturaInvoicingCurrentPage(resp.data.currentPage);
        setFinanceFaturaInvoicingTotalPage(resp.data.totalPages);
        setFinanceFaturaInvoicingTotalItems(resp.data.totalItems);
        setFinanceFaturaInvoicingTotalPrice(resp.data.totalPrice);
        setAdminBakiyeInvocingExport("basic");
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [setFinanceFaturaInvoicingData, searchParam, setFinanceFaturaInvoicingCurrentPage, setFinanceFaturaInvoicingTotalPage,
    setFinanceFaturaInvoicingTotalItems, setFinanceFaturaInvoicingTotalPrice, setAdminBakiyeInvocingExport]);

  const handleExport = (e) => {
    e.preventDefault();

    const detailedParams = {
      page: 1,
      limit: 100,
      order_direction: "DESC",
      detailed: 1,
      member_id: searchAdminBakiyeId,
      member_name: searchAdminBakiyeName,
      uyumsoft_code: searchAdminBakiyeEmail,
      start_date: FormatDate(searchAdminBakiyeDate),
      end_date: FormatDate(searchAdminBakiyeEndDate),
      parcel_type: searchAdminParcelType,
      export: 1,
    };

    const basicParams = {
      limit: 500,
      page: 1,
      order_direction: "DESC",
      export: 1,
      start_date: FormatDate(searchAdminBakiyeDate),
      end_date: FormatDate(searchAdminBakiyeEndDate),
      parcel_type: searchAdminParcelType,
      search: searchParam,
    };

    const params = {
      limit: 500,
      page: 1,
      order_direction: "DESC",
      export: 1,
      start_date: FormatDate(searchAdminBakiyeDate),
      end_date: FormatDate(searchAdminBakiyeEndDate),
      parcel_type: searchAdminParcelType,
    };

    axios
      .get(`invoice/list/billings`, {
        params:
          adminBakiyeInvocingExport === ""
            ? params
            : adminBakiyeInvocingExport === "basic"
              ? basicParams
              : detailedParams,
      })
      .then((res) => {
        DownloadBase64Excel(
          res.data.base64,
          "IyziBakiyem",
          "finance",
          FormatDate(searchAdminBakiyeDate),
          FormatDate(searchAdminBakiyeEndDate)
        );
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: "var(--lighterBg)",
          padding: "20px",
          borderRadius: "var(--inputBorderRadius)",
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          display: "flex",
          gap: "var(--gap)",
        }}
      >
        <TextField
          size="small"
          fullWidth
          label="Arama"
          placeholder="Uye Id, Üye Adı, Uyumsoft Kodu"
          autoComplete="off"
          value={searchParam}
          onChange={(e) => setSearchParam(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            } else if (e.key === "Escape") {
              handleClear();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button onClick={handleSearch} className="iyzi-button">
          Ara
        </Button>
        <Button
          type="button"
          sx={{ minWidth: "150px" }}
          className="iyzi-button"
          onClick={() => setExpand(!expand)}
        >
          {expand ? "Detaylı Aramayı Kapat" : "Detaylı Aramayı Aç"}
        </Button>
      </Box>
      <Accordion
        sx={{ boxShadow: "none" }}
        expanded={expand}
        onChange={() => setExpand(!expand)}
      >
        <AccordionSummary
          sx={{ minHeight: "0!important", height: "0!important", padding: 0 }}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        ></AccordionSummary>
        <AccordionDetails sx={{ padding: "20px" }}>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "var(--gap)",
            }}
            onSubmit={handleDetailedSearch}
          >
            <div style={{display: "flex", gap: "var(--gap)"}}>
              <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  label="Üye ID"
                  value={searchAdminBakiyeId}
                  onChange={(e) => setSearchAdminBakiyeId(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      clearId();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlinedIcon/>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                          {searchAdminBakiyeId.length !== 0 && (
                              <ClearOutlinedIcon
                                  sx={{cursor: "pointer"}}
                                  onClick={clearId}
                              />
                          )}
                        </InputAdornment>
                    ),
                  }}
              />
              <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  label="Üye İsmi"
                  value={searchAdminBakiyeName}
                  onChange={(e) => setSearchAdminBakiyeName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      clearName();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlinedIcon/>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                          {searchAdminBakiyeName.length !== 0 && (
                              <ClearOutlinedIcon
                                  sx={{cursor: "pointer"}}
                                  onClick={clearName}
                              />
                          )}
                        </InputAdornment>
                    ),
                  }}
              />
              <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  value={searchAdminBakiyeEmail}
                  onChange={(e) => setSearchAdminBakiyeEmail(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      clearEmail();
                    }
                  }}
                  label="Uyumsoft Kodu"
                  InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlinedIcon/>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                          {searchAdminBakiyeEmail.length !== 0 && (
                              <ClearOutlinedIcon
                                  sx={{cursor: "pointer"}}
                                  onClick={clearEmail}
                              />
                          )}
                        </InputAdornment>
                    ),
                  }}
              />
              <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  label="Konşimento Numarası"
                  value={searchAdminBakiyeKonsimento}
                  onChange={(e) =>
                      setSearchAdminBakiyeKonsimento(e.target.value)
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      clearAwb();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlinedIcon/>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                          {searchAdminBakiyeKonsimento.length !== 0 && (
                              <ClearOutlinedIcon
                                  sx={{cursor: "pointer"}}
                                  onClick={clearAwb}
                              />
                          )}
                        </InputAdornment>
                    ),
                  }}
              />
              </div>
              <div style={{display: "flex", gap: "var(--gap)"}}>
                <TextField
                    fullWidth
                    autoComplete="off"
                    size="small"
                    label="Başlangıç Tarihi"
                    type="date"
                    value={searchAdminBakiyeDate}
                    onChange={(e) => setSearchAdminBakiyeDate(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Escape") {
                        clearDate();
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                            <SearchOutlinedIcon/>
                          </InputAdornment>
                      ),
                      endAdornment: (
                          <InputAdornment position="end">
                            {searchAdminBakiyeDate.length !== 0 && (
                                <ClearOutlinedIcon
                                    sx={{cursor: "pointer"}}
                                    onClick={clearDate}
                                />
                            )}
                          </InputAdornment>
                      ),
                    }}
                />
                <TextField
                    fullWidth
                    autoComplete="off"
                    size="small"
                    label="Bitiş Tarihi"
                    type="date"
                    value={searchAdminBakiyeEndDate}
                    onChange={(e) => setSearchAdminBakiyeEndDate(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Escape") {
                        clearEndDate();
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                            <SearchOutlinedIcon/>
                          </InputAdornment>
                      ),
                      endAdornment: (
                          <InputAdornment position="end">
                            {searchAdminBakiyeEndDate.length !== 0 && (
                                <ClearOutlinedIcon
                                    sx={{cursor: "pointer"}}
                                    onClick={clearEndDate}
                                />
                            )}
                          </InputAdornment>
                      ),
                    }}
                />
                <FormControl fullWidth size="small">
                  <InputLabel sx={{fontSize: "14px"}} id="export-status-select-label">
                    İhracat Tipi
                  </InputLabel>
                  <Select
                      labelId="export-status-select-label"
                      id="export-status-select"
                      label="İhracat Tipi"
                      value={searchAdminParcelType}
                      onChange={(e) => setSearchAdminParcelType(e.target.value)}
                      sx={{fontSize: "14px", display: "flex", minHeight: "40px"}}
                  >
                    <MenuItem value="">
                      <em>Temizle</em>
                    </MenuItem>
                    <MenuItem value={1}>Normal</MenuItem>
                    <MenuItem value={2}>Hediye</MenuItem>
                    <MenuItem value={3}>Mikro İhracat</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "var(--gap)",
                  }}
              >
                <Button type="submit" className="iyzi-button">
                  Detaylı Ara
                </Button>
              </div>
          </form>
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "var(--gap)",
        }}
      >
        <Box sx={{
          display: "flex",
          gap: "20px"
        }}>
          <Box sx={{
            backgroundColor: "#F5AF02",
            padding: "10px",
            borderRadius: "26px",
            color: "#fff"
          }}>
            <p>Onay Bekleniyor: {financeFaturaInvoicingTotalItems} Adet / {Number(financeFaturaInvoicingTotalPrice).toFixed(2)}TL</p>
          </Box>
        </Box>
        <Box sx={{
          display: "flex",
          gap: "10px"
        }}>
          <Button sx={{
            backgroundColor: "var(--info)",
            color: "#fff",
            padding: "10px",
            fontSize: "11px",
            borderRadius: "5px",
            ":hover": {
              backgroundColor: "var(--info)",
              opacity: "0.7",
              transition: "all 0.5s"
            },
          }} onClick={handleExport}>
            Dışarı Aktar
          </Button>
          <Button sx={{
            backgroundColor: "var(--info)",
            color: "#fff",
            padding: "10px",
            fontSize: "11px",
            borderRadius: "5px",
            ":hover": {
              backgroundColor: "var(--info)",
              opacity: "0.7",
              transition: "all 0.5s"
            },
          }} onClick={handleInvoicingModal}>
            Toplu Fatura Kes
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default FaturaInvoicingSearh;
