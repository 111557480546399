import { useRecoilState } from "recoil";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled } from "@mui/material/styles";
import { drawerOpenState } from "../../../atoms/viewPadding";
import { Link } from "react-router-dom";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const NavDrawerHeader = () => {
  const [drawerOpen, setDrawerOpen] = useRecoilState(drawerOpenState);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  return (
    <DrawerHeader className="navbar-drawer-header">
      {drawerOpen ? (
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      ) : (
        <IconButton onClick={handleDrawerOpen}>
          <ChevronRightIcon />
        </IconButton>
      )}
      <Link to={"/dashboard"}>
        <img
          className={drawerOpen ? "navbar-logo" : "navbar-logo-hidden"}
          style={{
            marginLeft: "20px",
            height: "50px",
            opacity: drawerOpen ? 1 : 0,
          }}
          src="/images/home/logo.svg"
          alt="IyziShip Logo"
        />
      </Link>
    </DrawerHeader>
  );
};

export default NavDrawerHeader;
