import { Table, TableBody, TableContainer } from "@mui/material";
import BekleyenOdemelerTableHead from "./BekleyenOdemelerTableHead";
import { useRecoilValue } from "recoil";
import { financeBekleyenOdemelerDataState } from "../../../../atoms/finance";
import BekleyenOdemelerTableBody from "./BekleyenOdemelerTableBody";

const BekleyenOdemelerTable = () => {
  const financeBekleyenOdemelerData = useRecoilValue(
    financeBekleyenOdemelerDataState
  );
  return (
    <TableContainer
      sx={{
        backgroundColor: "var(--lighterBg)",
        borderRadius: "var(--wrapperBorderRadius)",
        boxShadow: "var(--shadow)",
      }}
    >
      <Table>
        <BekleyenOdemelerTableHead />
        <TableBody>
          {financeBekleyenOdemelerData?.map((item, key) => (
            <BekleyenOdemelerTableBody key={key} item={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BekleyenOdemelerTable;
