import { useRecoilState } from "recoil";
import { addExtraPriceOpenState, selectedShipmentForMyShipmentsState } from "../../../../atoms/myShipments";
import {
  Button, Modal, ThemeProvider, createTheme, FormControl, InputLabel, Select, MenuItem, TextField, Box
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Errors from "../../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px",
          overflow: "auto",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const ExtraPriceAdd = () => {
  const [extraPriceAdd, setextraPriceAdd] = useRecoilState(addExtraPriceOpenState);
  const [selectedShipmentForMyShipments, setSelectedShipmentForMyShipments] = useRecoilState(
    selectedShipmentForMyShipmentsState
  );

  console.log(selectedShipmentForMyShipments)

  const [extraPriceType, setExtraPriceType] = useState("");
  const [currencyPrice, setCurrencyPrice] = useState("");
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getAddExtraPriceHandler = () => {

    axios
      .post(`parcel/operation/add-additional-cost-parcel/${selectedShipmentForMyShipments.id}`, {
        type: extraPriceType,
        price: currencyPrice,
      })
      .then((response) => {
        toast.success(response.data.message);
        setextraPriceAdd(false);
        setExtraPriceType("");
        setCurrencyPrice("");
      })
      .catch((error) => {
        Errors(error);
      });

  };

  const handleInputChange = useCallback((e) => {
    const inputValue = e.target.value;
    const regex = /^-?\d*\.?\d*$/;

    if (!regex.test(inputValue) && inputValue !== "") {
      return;
    } else {
      // Diğer işlemleriniz buraya gelecek, örneğin state güncellemesi gibi
      setCurrencyPrice(inputValue);
    }
  }, [setCurrencyPrice]);

  const handleClose = () => {
    setData([]);
    setextraPriceAdd(false);
    setExtraPriceType("");
    setCurrencyPrice("");
  };

  // useEffect(() => {
  //   if (historyOfChangeModal.status) {
  //     getData();
  //   }
  // }, [historyOfChangeModal.status, getData]);

  return (
    <ThemeProvider theme={theme}>
      <Modal open={extraPriceAdd} onClose={handleClose}>
        <div
          style={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            padding: "20px",
            outline: 0,
            display: "grid",
            gap: "var(--gap)",
            width: "100%",
            maxWidth: "1100px",
            margin: "auto",
            position: "relative",
          }}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              cursor: "pointer",
              color: "var(--priceText)",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleClose}
          />
          <Box sx={
            {
              display: "flex",
              flexDirection: "column",
              gap: "20px"
            }
          }>
            <Box>
              <p>
                Ekstra Masraf Ekle
              </p>
            </Box>
            <FormControl fullWidth size="small">
              <InputLabel sx={{ fontSize: "14px" }} id="price-status-select-label">
                Ekstra Masraf Tipi
              </InputLabel>
              <Select
                labelId="price-status-select-label"
                id="price-status-select"
                label="Extra Ücret Tipi"
                value={extraPriceType}
                multiline
                onChange={(e) => setExtraPriceType(e.target.value)}
                sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
              >
                <MenuItem value="">
                  <em>Temizle</em>
                </MenuItem>
                <MenuItem value={"ADDRESS CHANGE FEE"}>Adres Değişikliği Ücreti</MenuItem>
                <MenuItem value={"REDISTRIBUTION FEE"}>Yeniden Dağıtım Ücreti</MenuItem>
                <MenuItem value={"PACKAGING FEE"}>Paketleme Ücreti</MenuItem>
                <MenuItem value={"DISCHARGE FEE"}>Tasfiye Ücreti</MenuItem>
                <MenuItem value={"DESTRUCTION FEE"}>İmha Ücreti</MenuItem>
                <MenuItem value={"DISCOUNT"}>İndirim</MenuItem>
                <MenuItem disabled={selectedShipmentForMyShipments?.get_real_contract === null} value={"INSURANCE"}>Sigorta</MenuItem>
                <MenuItem disabled={selectedShipmentForMyShipments?.get_real_contract === null} value={"LUCID"}>Lucid Ücreti</MenuItem>
                <MenuItem disabled={selectedShipmentForMyShipments?.get_real_contract === null} value={"DDP"}>DDP</MenuItem>
              </Select>
            </FormControl>
            {
              extraPriceType.length > 0 && (
                <Box>
                  <TextField
                    value={currencyPrice}
                    onChange={handleInputChange}
                    autoComplete="off"
                    fullWidth
                    size="small"
                    label={`Fiyat (${selectedShipmentForMyShipments?.currency})`}
                  />
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                </Box>
              )
            }
          </Box>
          <Box sx={{ display: " flex", justifyContent: "flex-end" }}>
            <Button
              type="button"
              onClick={() => {
                handleClose();
              }}
              className="iyzi-button"
            >
              Vazgeç
            </Button>
            <Button
              onClick={getAddExtraPriceHandler}
              className="iyzi-button"
              sx={{ ml: "10px" }}
            >
              Ekle
            </Button>
          </Box>

        </div>
      </Modal>
    </ThemeProvider>
  );
};

export default ExtraPriceAdd;
