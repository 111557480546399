import { Modal, ThemeProvider, createTheme } from "@mui/material";
import { useRecoilState } from "recoil";
import { useCallback } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  faturaFileDataState,
  faturaFileModalState,
} from "../../../../atoms/finance";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          margin: "20px",
          overflow: "auto",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          display: "flex",
          alignItems: "center",
          gap: "10px",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "14px",
          opacity: 0.8,
        },
      },
    },
  },
});

const FaturaFileModal = () => {
  const [faturaFileModal, setFaturaFileModal] =
    useRecoilState(faturaFileModalState);
  const [faturaFileData, setFaturaFileData] =
    useRecoilState(faturaFileDataState);

  const handleClose = useCallback(() => {
    setFaturaFileModal(false);
    setFaturaFileData("");
  }, [setFaturaFileModal, setFaturaFileData]);

  return (
    <ThemeProvider theme={theme}>
      <Modal open={faturaFileModal} onClose={handleClose}>
        <div
          style={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            padding: "20px",
            outline: 0,
            display: "grid",
            gap: "var(--gap)",
            width: "100%",
            maxWidth: "1100px",
            margin: "auto",
            position: "relative",
            height: "max-content",
          }}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              cursor: "pointer",
              color: "var(--priceText)",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleClose}
          />
          <div dangerouslySetInnerHTML={{ __html: faturaFileData }} />
        </div>
      </Modal>
    </ThemeProvider>
  );
};

export default FaturaFileModal;
