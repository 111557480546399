import { Button, TableCell, TableRow } from "@mui/material";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import {
  editAnnouncementState,
  notificationObjState,
} from "../../../../atoms/announcement";

const AnnouncementTableBody = ({ not }) => {
  const [, setEditAnnouncement] = useRecoilState(editAnnouncementState);
  const [, setNotificationObj] = useRecoilState(notificationObjState);

  const handleEditAnnouncement = useCallback(() => {
    setNotificationObj(not);
    setEditAnnouncement(true);
  }, [setEditAnnouncement, setNotificationObj, not]);

  return (
    <TableRow className="announcement-list-table-body">
      <TableCell>{not.type && not.type}</TableCell>
      <TableCell>
        {not.member_id && not.member_id !== "0" ? not.member_id : "---"}
      </TableCell>
      <TableCell>
        {not.get_user?.name} {not.get_user?.surname}
      </TableCell>
      <TableCell>{not.start_date === null ? "---" : not.start_date}</TableCell>
      <TableCell>{not.end_date === null ? "---" : not.end_date}</TableCell>
      <TableCell>
        <Button
          onClick={handleEditAnnouncement}
          className="announcement-list-table-body-edit"
        >
          Düzenle
        </Button>
      </TableCell>
      <TableCell>
        <Button className="announcement-list-table-body-delete">Sil</Button>
      </TableCell>
    </TableRow>
  );
};

export default AnnouncementTableBody;
