import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  createTheme,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  supportCurrentPageState,
  supportListState,
  supportTotalPageState,
} from "../../../atoms/support";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import axios from "../../../api/axios";
import Errors from "../../../utils/Errors";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          color: "currentcolor",
        },
        icon: {
          color: "currentcolor",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "currentcolor",
        },
      },
    },
  },
});

const SupportSearch = () => {
  const [, setSupportList] = useRecoilState(supportListState);
  const [, setSupportCurrentPage] = useRecoilState(supportCurrentPageState);
  const [, setSupportTotalPage] = useRecoilState(supportTotalPageState);

  const [searchParams, setSearchParams] = useState({
    id: "",
    parcel_ref_no: "",
    tracking_awb: "",
    subject: "",
    member_name: "",
    assigned_user_name: "",
    created_at: "",
    updated_at: "",
    closed_at: "",
    status: "",
    ticket_user_name: "",
    is_case: "",
  });

  const [isExpanded, setIsExpanded] = useState(false);

  const navigate = useNavigate();

  const handleDetailedSearch = async (e) => {
    if (e) e.preventDefault();

    const filteredParams = {
      ...searchParams,
      detailed: 1,
    };

    await axios
      .get("ticket", { params: filteredParams })
      .then((response) => {
        setSupportList(response.data.data);
        setSupportCurrentPage(response.data.currentPage);
        setSupportTotalPage(response.data.totalPages);
      })
      .catch((error) => {
        Errors(error);
      });
  };

  const handleClear = () => {
    setSearchParams({
      id: "",
      parcel_ref_no: "",
      tracking_awb: "",
      subject: "",
      member_name: "",
      assigned_user_name: "",
      created_at: "",
      updated_at: "",
      closed_at: "",
      status: "",
      ticket_user_name: "",
      is_case: "",
    });

    navigate("/support");
    handleDetailedSearch();
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box maxWidth={1600} mx="auto">
        <form
          onSubmit={handleDetailedSearch}
          className="myShipments-detailed-search-wrapper"
        >
          <h6
            style={{
              color: "var(--priceText)",
              marginTop: "var(--gap2x)",
            }}
            className="myShipments-detailed-search-title"
          >
            Detaylı Arama
            <Button onClick={toggleExpand} size="small" sx={{ p: 0, mx: 2 }}>
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
          </h6>
          {isExpanded && (
            <>
              <Box
                sx={{ paddingTop: "30px" }}
                className="myShipments-detailed-search"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      value={searchParams.id}
                      onChange={(e) =>
                        setSearchParams({ ...searchParams, id: e.target.value })
                      }
                      autoComplete="off"
                      fullWidth
                      size="small"
                      label="Vaka Numarası"
                      sx={{ borderRadius: "5px", backgroundColor: "white" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      value={searchParams.parcel_ref_no}
                      onChange={(e) =>
                        setSearchParams({
                          ...searchParams,
                          parcel_ref_no: e.target.value,
                        })
                      }
                      autoComplete="off"
                      fullWidth
                      size="small"
                      label="Referans No"
                      sx={{ borderRadius: "5px", backgroundColor: "white" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      value={searchParams.tracking_awb}
                      onChange={(e) =>
                        setSearchParams({
                          ...searchParams,
                          tracking_awb: e.target.value,
                        })
                      }
                      autoComplete="off"
                      fullWidth
                      size="small"
                      label="AWB"
                      sx={{ borderRadius: "5px", backgroundColor: "white" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Konu</InputLabel>
                      <Select
                        value={searchParams.subject}
                        onChange={(e) =>
                          setSearchParams({
                            ...searchParams,
                            subject: e.target.value,
                          })
                        }
                        label="Konu"
                        sx={{
                          borderRadius: "5px",
                          backgroundColor: "white",
                        }}
                      >
                        <MenuItem value="0">Kurye Kaydı</MenuItem>
                        <MenuItem value="1">Hasarlı Gönderi Bildirimi</MenuItem>
                        <MenuItem value="2">Adres Güncelleme</MenuItem>
                        <MenuItem value="3">Gümrük Bildirimi</MenuItem>
                        <MenuItem value="4">
                          İçerik Bilgisi Yetersiz/Hatalı
                        </MenuItem>
                        <MenuItem value="5">Gönderi Oluşturma</MenuItem>
                        <MenuItem value="6">Ödeme</MenuItem>
                        
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      value={searchParams.member_name}
                      onChange={(e) =>
                        setSearchParams({
                          ...searchParams,
                          member_name: e.target.value,
                        })
                      }
                      autoComplete="off"
                      fullWidth
                      size="small"
                      label="Kimden"
                      sx={{ borderRadius: "5px", backgroundColor: "white" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      value={searchParams.assigned_user_name}
                      onChange={(e) =>
                        setSearchParams({
                          ...searchParams,
                          assigned_user_name: e.target.value,
                        })
                      }
                      autoComplete="off"
                      fullWidth
                      size="small"
                      label="İlgilenen"
                      sx={{ borderRadius: "5px", backgroundColor: "white" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      value={searchParams.created_at}
                      onChange={(e) =>
                        setSearchParams({
                          ...searchParams,
                          created_at: e.target.value,
                        })
                      }
                      autoComplete="off"
                      fullWidth
                      size="small"
                      label="Tarih"
                      sx={{ borderRadius: "5px", backgroundColor: "white" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      value={searchParams.updated_at}
                      onChange={(e) =>
                        setSearchParams({
                          ...searchParams,
                          updated_at: e.target.value,
                        })
                      }
                      autoComplete="off"
                      fullWidth
                      size="small"
                      label="Son İşlem"
                      sx={{ borderRadius: "5px", backgroundColor: "white" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      value={searchParams.closed_at}
                      onChange={(e) =>
                        setSearchParams({
                          ...searchParams,
                          closed_at: e.target.value,
                        })
                      }
                      autoComplete="off"
                      fullWidth
                      size="small"
                      label="Çözüm Tarihi"
                      sx={{ borderRadius: "5px", backgroundColor: "white" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Durum</InputLabel>
                      <Select
                        value={searchParams.status}
                        onChange={(e) =>
                          setSearchParams({
                            ...searchParams,
                            status: e.target.value,
                          })
                        }
                        label="Durum"
                        sx={{
                          borderRadius: "5px",
                          backgroundColor: "white",
                        }}
                      >
                        <MenuItem value="0">Yeni Talep</MenuItem>
                        <MenuItem value="1">Üyeden Cevap Bekleniyor</MenuItem>
                        <MenuItem value="2">
                          iyziTeam'den Cevap Bekleniyor
                        </MenuItem>
                        <MenuItem value="3">Kapalı</MenuItem>
                        {/* Add the rest of the options */}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      value={searchParams.ticket_user_name}
                      onChange={(e) =>
                        setSearchParams({
                          ...searchParams,
                          ticket_user_name: e.target.value,
                        })
                      }
                      autoComplete="off"
                      fullWidth
                      size="small"
                      label="Talebe Eklenen Konuşmacı"
                      sx={{ borderRadius: "5px", backgroundColor: "white" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Case Durumu</InputLabel>
                      <Select
                        value={searchParams.is_case}
                        onChange={(e) =>
                          setSearchParams({
                            ...searchParams,
                            is_case: e.target.value,
                          })
                        }
                        label="Case Durumu"
                        sx={{
                          borderRadius: "5px",
                          backgroundColor: "white",
                        }}
                      >
                        <MenuItem value="1">Açık</MenuItem>
                        <MenuItem value="0">Kapalı</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{ display: "flex", gap: "5px", paddingTop: "20px" }}
                className="myShipments-detailed-search-done"
              >
                <Button className="iyzi-button-blue" type="submit">
                  <SearchOutlinedIcon />
                  Arama Yap
                </Button>
                <Button
                  type="button"
                  className="iyzi-button-blue"
                  onClick={handleClear}
                >
                  Temizle
                </Button>
              </Box>
            </>
          )}
        </form>
      </Box>
    </ThemeProvider>
  );
};

export default SupportSearch;
