import { useRecoilValue } from "recoil";
import { financeTazminIadeDataState } from "../../../../atoms/finance";
import {
  Table,
  TableBody,
  TableContainer,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import FinanceTazminIadeTableHead from "./FinanceTazminIadeTableHead";
import FinanceTazminIadeTableBody from "./FinanceTazminIadeTableBody";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 8,
        },
      },
    },
  },
});

const FinanceTazminIadeTable = () => {
  const financeTazminIadeData = useRecoilValue(financeTazminIadeDataState);

  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        sx={{
          backgroundColor: "var(--lighterBg)",
          boxShadow: "var(--shadow)",
          borderRadius: "var(--wrapperBorderRadius)",
        }}
      >
        <Table>
          <FinanceTazminIadeTableHead />
          <TableBody>
            {financeTazminIadeData?.map((item, key) => (
              <FinanceTazminIadeTableBody key={key} item={item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

export default FinanceTazminIadeTable;
