import {
  Modal,
  Typography,
  Box,
  TextField,
  Button,
  ThemeProvider,
  createTheme,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { useRecoilState } from "recoil";
import { useCallback } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
    invoicingSingleModalState,
    selectedRowState,
    financeFaturaSearchAdminBakiyeDateState,
    financeFaturaSearchAdminBakiyeEndDateState,
    financeFaturaSearchAdminKonsimentoState, financeFaturaSearchAdminParcelTypeState
} from "../../../../atoms/finance";
import { useState } from "react"
import Errors from "../../../../utils/Errors";
import axios from "../../../../api/axios";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          margin: "20px",
          overflow: "auto",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          display: "flex",
          alignItems: "center",
          gap: "10px",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "14px",
          opacity: 0.8,
        },
      },
    },
  },
});

const FaturaInvoicingSingleModal = () => {
  const [invoicingSingleModal, setInvoicingSingleModal] = useRecoilState(invoicingSingleModalState);
  const [selectedRow, setSelectedRow] = useRecoilState(selectedRowState);
  const [searchAdminBakiyeDate, setSearchAdminBakiyeDate] = useRecoilState(financeFaturaSearchAdminBakiyeDateState);
  const [searchAdminBakiyeEndDate, setSearchAdminBakiyeEndDate] = useRecoilState(financeFaturaSearchAdminBakiyeEndDateState);
  const [searchAdminParcelType, setSearchAdminParcelType] = useRecoilState(financeFaturaSearchAdminParcelTypeState);
  const [searchAdminBakiyeKonsimento, setSearchAdminBakiyeKonsimento] =
  useRecoilState(financeFaturaSearchAdminKonsimentoState);
  const [billType, setBillType] = useState("");

  const [startDate, setStartDate] = useState("");
  const [loading, setLoading] = useState(false);

  const memberIds = selectedRow?.member_id;
  const parcelIds = selectedRow?.parcel_ref_no;

  const handleClose = useCallback(() => {
    setInvoicingSingleModal(false);
  }, [setInvoicingSingleModal]);

  const sendInvoiceDate = () => {
    setLoading(true);

    // Seçilen tarih verisini ISO formatından alma
    const invoiceDate = new Date(startDate);

    // İstediğiniz tarih formatına dönüştürme
    const formattedDate = `${invoiceDate.getFullYear()}-${(invoiceDate.getMonth() + 1).toString().padStart(2, '0')}-${invoiceDate.getDate().toString().padStart(2, '0')} 00:00:00`;

    axios
      .post(`/create-invoice`, {
        member_ids: [memberIds],
        start_date: searchAdminBakiyeDate,
        end_date: searchAdminBakiyeEndDate,
        parcel_type: searchAdminParcelType,
        invoice_date: startDate,
        // parcel_ids: parcelIds,
        docTraCode: billType,
      })
      .then((response) => {
        // İşlemleriniz
      })
      .catch((error) => {
        // Hata yönetimi
        // Errors(error);
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };


  return (
    <ThemeProvider theme={theme}>
      <Modal open={invoicingSingleModal} onClose={handleClose}>
        <Box
          style={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            display: "flex",
            flexDirection: "column",
            padding: "50px",
            gap: "30px",
            width: "100%",
            maxWidth: "1100px",
            margin: "auto",
            height: "max-content",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              cursor: "pointer",
              color: "var(--priceText)",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleClose}
          />
          <Typography>
            Fatura Kesilecek Tarih
          </Typography>
          <TextField sx={{ minWidth: "200px" }}
            type={"date"}
            label="Fatura Tarihi"
            InputLabelProps={{ shrink: true }}
            size={"small"}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          {/* <TextField sx={{ minWidth: "200px" }}
            type={"date"}
            label="Fatura Başlangıç Tarihi"
            InputLabelProps={{ shrink: true }}
            size={"small"}
            value={searchAdminBakiyeDate}
            onChange={(e) => setSearchAdminBakiyeDate(e.target.value)}
          />
          <TextField sx={{ minWidth: "200px" }}
            type={"date"}
            label="Fatura Bitiş Tarihi"
            InputLabelProps={{ shrink: true }}
            size={"small"}
            value={searchAdminBakiyeEndDate}
            onChange={(e) => setSearchAdminBakiyeEndDate(e.target.value)}
          /> */}
          <TextField
            sx={{ minWidth: "180px" }}
            type={"text"}
            label="Konşimento Numarası"
            InputLabelProps={{ shrink: true }}
            size={"small"}
            disabled
            value={searchAdminBakiyeKonsimento}
          />
          <FormControl sx={{ minWidth: "200px" }} size="small">
            <InputLabel
              sx={{ fontSize: "14px" }}
              id="currency-select-label"
            >
              Fatura Hareket Kodu
            </InputLabel>
            <Select
              labelId="currency-select-label"
              id="currency-select"
              label="Fatura Hareket Kodu"
              value={billType}
              onChange={(e) => setBillType(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={"FTS-100"}>FTS-100</MenuItem>
              <MenuItem value={"FTS-101"}>FTS-101</MenuItem>
            </Select>
          </FormControl>
          {loading ? (
            <Box
              sx={{
                width: "72px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress sx={{ width: "24px !important", height: "24px !important" }} />
            </Box>
          ) : (
            <Button
              disabled={!billType}
              onClick={sendInvoiceDate}
              sx={{
                justifySelf: "flex-end",
                backgroundColor: "var(--info)",
                color: "var(--lighterBg)",
                textTransform: "none",
                transition: "all 150ms linear",
                borderRadius: "var(--buttonBorderRadius)",
                padding: "7px 16px",
                ":hover": {
                  backgroundColor: "var(--blueBrand)",
                  transition: "all 150ms linear",
                },
                ":disabled": {
                  backgroundColor: "var(--lighterBg)"
                },
              }}
            >
              Fatura Kes
            </Button>
          )}

        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default FaturaInvoicingSingleModal;
