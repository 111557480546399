import { atom } from "recoil";

export const financeBakiyeIadeTotalPageState = atom({
  key: "financeBakiyeIadeTotalPage",
  default: 0,
});

export const financeBakiyeIadeCurrentPageState = atom({
  key: "financeBakiyeIadeCurrentPage",
  default: 1,
});

export const financeBakiyeIadeDataState = atom({
  key: "financeBakiyeIadeData",
  default: [],
});

export const financeHavaleTotalPageState = atom({
  key: "financeHavaleTotalPage",
  default: 0,
});

export const financeHavaleCurrentPageState = atom({
  key: "financeHavaleCurrentPage",
  default: 1,
});

export const financeHavaleDataState = atom({
  key: "financeHavaleDataState",
  default: [],
});

export const FinanceGonderiIadeTotalPageState = atom({
  key: "FinanceGonderiIadeTotalPage",
  default: 0,
});

export const FinanceGonderiIadeCurrentPageState = atom({
  key: "FinanceGonderiIadeCurrentPage",
  default: 1,
});

export const FinanceGonderiIadeDataState = atom({
  key: "FinanceGonderiIadeDataState",
  default: [],
});

export const financeIbanTotalPageState = atom({
  key: "financeIbanTotalPage",
  default: 0,
});

export const financeIbanCurrentPageState = atom({
  key: "financeIbanCurrentPage",
  default: 1,
});

export const financeIbanDataState = atom({
  key: "financeIbanDataState",
  default: [],
});

export const financeBakiyeTotalPageState = atom({
  key: "financeBakiyeTotalPage",
  default: 0,
});

export const financeBakiyeCurrentPageState = atom({
  key: "financeBakiyeCurrentPage",
  default: 1,
});

export const financeBakiyeDataState = atom({
  key: "financeBakiyeData",
  default: [],
});

export const searchAdminBakiyeParamState = atom({
  key: "searchAdminBakiyeParam",
  default: "",
});

export const searchAdminBakiyeIdState = atom({
  key: "searchAdminBakiyeId",
  default: "",
});

export const searchAdminBakiyeNameState = atom({
  key: "searchAdminBakiyeName",
  default: "",
});

export const searchAdminBakiyeEmailState = atom({
  key: "searchAdminBakiyeEmail",
  default: "",
});

export const searchAdminBakiyeKonsimentoState = atom({
  key: "searchAdminBakiyeKonsimento",
  default: "",
});

export const searchAdminBakiyeDateState = atom({
  key: "searchAdminBakiyeDate",
  default: "",
});

export const adminBakiyeExportState = atom({
  key: "adminBakiyeExport",
  default: "",
});
export const adminBakiyeInvocingExportState = atom({
  key: "adminBakiyeInvocingExport",
  default: "",
});

export const dynamicRefundableAmountState = atom({
  key: "dynamicRefundableAmount",
  default: [],
});

export const financeBakiyeIadeFilesModalState = atom({
  key: "financeBakiyeIadeFilesModal",
  default: false,
});

export const selectedBakiyeIadeFilesState = atom({
  key: "selectedBakiyeIadeFiles",
  default: [],
});
export const financeHavaleFilesModalState = atom({
  key: "financeHavaleFilesModal",
  default: false,
});

export const selectedHavaleFilesState = atom({
  key: "selectedHavaleFiles",
  default: [],
});

export const financeTazminIadeTotalPageState = atom({
  key: "financeTazminIadeTotalPage",
  default: 0,
});

export const financeTazminIadeCurrentPageState = atom({
  key: "financeTazminIadeCurrentPage",
  default: 1,
});

export const financeTazminIadeDataState = atom({
  key: "financeTazminIadeData",
  default: [],
});

export const financeTazminIadeFilesModalState = atom({
  key: "financeTazminIadeFilesModal",
  default: false,
});

export const selectedTazminIadeFilesState = atom({
  key: "selectedTazminIadeFiles",
  default: {},
});

export const financeTazminIadeResponseModalState = atom({
  key: "financeTazminIadeResponseModal",
  default: false,
});

export const financeTazminIadeResponseItemState = atom({
  key: "financeTazminIadeResponseItem",
  default: {},
});

export const financeFaturaDataState = atom({
  key: "financeFaturaData",
  default: [],
});

export const financeFaturaTotalPageState = atom({
  key: "financeFaturaTotalPage",
  default: 0,
});

export const financeFaturaCurrentPageState = atom({
  key: "financeFaturaCurrentPage",
  default: 1,
});

export const financeFaturaInvoicingDataState = atom({
  key: "financeFaturaInvoicingData",
  default: [],
});
export const financeFaturaSearchAdminBakiyeDateState = atom({
  key: "financeFaturaSearchAdminBakiyeDate",
  default: "",
});
export const financeFaturaSearchAdminKonsimentoState = atom({
  key: "financeFaturaSearchAdminKonsimento",
  default: "",
});
export const financeFaturaSearchAdminBakiyeEndDateState = atom({
  key: "financeFaturaSearchAdminBakiyeEndDate",
  default: "",
});

export const financeFaturaSearchAdminParcelTypeState = atom({
  key: "financeFaturaSearchAdminParcelType",
  default: "",
});

export const financeFaturaInvoicingTotalPageState = atom({
  key: "financeFaturaInvoicingTotalPage",
  default: 0,
});

export const financeFaturaInvoicingTotalPriceState = atom({
  key: "financeFaturaInvoicingTotalPrice",
  default: 0,
});

export const financeFaturaInvoicingCurrentPageState = atom({
  key: "financeFaturaInvoicingCurrentPage",
  default: 1,
});

export const financeFaturaInvoicingTotalItemsState = atom({
  key: "financeFaturaInvoicingTotalItems",
  default: 0,
});

export const financeBekleyenOdemelerDataState = atom({
  key: "financeBekleyenOdemelerData",
  default: [],
});

export const financeBekleyenOdemelerTotalPageState = atom({
  key: "financeBekleyenOdemelerTotalPage",
  default: 0,
});

export const financeBekleyenOdemelerCurrentPageState = atom({
  key: "financeBekleyenOdemelerCurrentPage",
  default: 1,
});

export const faturaKonsimentoDataState = atom({
  key: "faturaKonsimentoData",
  default: [],
});

export const selectedRowsState = atom({
  key: "selectedRows",
  default: [],
});

export const selectedRowState = atom({
  key: "selectedRow",
  default: [],
});

export const faturaKonsimentoIdState = atom({
  key: "faturaKonsimentoId",
  default: "",
});

export const faturaKonsimentoModalState = atom({
  key: "faturaKonsimentoModal",
  default: false,
});

export const selectedAllRowsState = atom({
  key: "selectedAllRows",
  default: false,
});

export const faturaFileModalState = atom({
  key: "faturaFileModal",
  default: false,
});

export const invoicingModalState = atom({
  key: "invoicingModal",
  default: false,
});

export const invoicingSingleModalState = atom({
  key: "invoicingSingleModal",
  default: false,
});

export const faturaFileDataState = atom({
  key: "faturaFileData",
  default: "",
});
