import {
  Box,
  Button,
  createTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import axios from "../../../api/axios";
import { announcementAddOpenState, notificationListState } from "../../../atoms/announcement";
import FormatDate from "../../../utils/FormatDate";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "10px !important",
        },
      },
    },
  },
});

const AnnouncementAdd = () => {
  const [, setAnnouncementAddOpen] = useRecoilState(announcementAddOpenState);

  const [announcementType, setAnnouncementType] = useState("");
  const [memberId, setMemberId] = useState("");
  const [announcementText, setAnnouncementText] = useState("");
  const [startDate, setStartDate] = useState(FormatDate(new Date()));
  const [endDate, setEndDate] = useState("");

  const [notificationList, setNotificationList] = useRecoilState(notificationListState);

  const handleAnnouncementChange = useCallback((e) => {
    setAnnouncementType(e.target.value);
  }, []);

  const handleSubmitAnnouncement = useCallback(
    async (e) => {
      if (e) e.preventDefault();

      await axios
        .post("notification/add", {
          type: announcementType,
          member_id: announcementType === "generic" ? 0 : memberId,
          text: announcementText,
          start_date: startDate,
          end_date: endDate,
        })
        .then((response) => {
          toast.success(response.data.message);
          setNotificationList([response.data.data, ...notificationList]);
        })

        .catch((error) => {
          Errors(error);
        });
    },
    [
      announcementType,
      announcementText,
      startDate,
      endDate,
      setNotificationList,
      notificationList,
      memberId,
    ]
  );

  const handleCloseAnnouncementAdd = useCallback(() => {
    setAnnouncementType("");
    setAnnouncementText("");
    setStartDate("");
    setEndDate("");
    setAnnouncementAddOpen(false);
  }, [setAnnouncementAddOpen]);

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={handleSubmitAnnouncement} className="announcement-add-wrapper">
        <Box className="announcement-add-inputs">
          <FormControl required size="small" fullWidth>
            <InputLabel id="select-label-announcement-type">Duyuru Türü</InputLabel>
            <Select
              labelId="select-label-announcement-type"
              id="select"
              value={announcementType}
              label="Duyuru Türü"
              onChange={(e) => handleAnnouncementChange(e)}
            >
              <MenuItem value={"member"}>Üye</MenuItem>
              <MenuItem value={"generic"}>Genel</MenuItem>
            </Select>
          </FormControl>
          {announcementType === "member" && (
            <FormControl required size="small" fullWidth>
              <InputLabel id="select-label-member-id">Üye ID</InputLabel>
              <Select
                labelId="select-label-member-id"
                id="select-member-id"
                value={memberId}
                label="Üye ID"
                onChange={(e) => setMemberId(e.target.value)}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
              </Select>
            </FormControl>
          )}
          <TextField
            label="Başlangıç Tarihi"
            type={"date"}
            size="small"
            value={startDate}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setStartDate(e.target.value)}
            fullWidth
          />
          <TextField
            label="Bitiş Tarihi"
            type={"date"}
            size="small"
            required={startDate.length !== 0 ? true : false}
            value={endDate}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setEndDate(e.target.value)}
            fullWidth
          />
        </Box>
        <TextField
          label="Duyuru Giriniz"
          value={announcementText}
          onChange={(e) => setAnnouncementText(e.target.value)}
          required
          multiline
          rows={4}
          fullWidth
          size="small"
        />
        <Box className="announcement-add-buttons">
          <Button onClick={handleCloseAnnouncementAdd} type="button">
            Vazgeç
          </Button>
          <Button type="submit">Kaydet</Button>
        </Box>
      </form>
    </ThemeProvider>
  );
};

export default AnnouncementAdd;
