import { CircularProgress, TableCell, TableRow } from "@mui/material";
import MessageTime from "../../../../utils/MessageTime";
import { useCallback, useState } from "react";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useRecoilState } from "recoil";
import {
  faturaFileDataState,
  faturaFileModalState,
  faturaKonsimentoDataState,
  faturaKonsimentoIdState,
  faturaKonsimentoModalState,
} from "../../../../atoms/finance";
import axios from "../../../../api/axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Errors from "../../../../utils/Errors";

const FinanceFaturaTableBody = ({ item }) => {
  const [fileLoading, setFileLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setFaturaFileModal] = useRecoilState(faturaFileModalState);
  const [, setFaturaFileData] = useRecoilState(faturaFileDataState);
  const [, setFaturaKonsimentoData] = useRecoilState(faturaKonsimentoDataState);
  const [, setFaturaKonsimentoId] = useRecoilState(faturaKonsimentoIdState);
  const [, setFaturaKonsimentoModal] = useRecoilState(faturaKonsimentoModalState);

  const handleFile = () => {
    setFileLoading(true);
    axios
      .get(`invoice/${item.id}`)
      .then((resp) => {
        setFaturaFileData(resp.data);
        setFaturaFileModal(true);
      })
      .catch((error) => Errors(error))
      .finally(() => setFileLoading(false));
  };

  const seeBillDetails = useCallback(() => {
    setLoading(true);
    axios
      .get(`invoice/get-items/${item.id}`, {
        params: {
          page: 1,
          limit: 1000,
          order_direction: "ASC",
        },
      })
      .then((response) => {
        setFaturaKonsimentoData(response.data.data);
        setFaturaKonsimentoId(item.id);
        setFaturaKonsimentoModal(true);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [item, setFaturaKonsimentoData, setFaturaKonsimentoId, setFaturaKonsimentoModal]);
  console.log(fileLoading);
  return (
    <TableRow>
      <TableCell>{item?.get_member?.uyumsoft_code}</TableCell>
      <TableCell>{item?.member_id}</TableCell>
      <TableCell>{item?.get_member?.name}</TableCell>
      <TableCell>
        <div
          style={{
            fontSize: "inherit",
            display: "flex",
            gap: "var(--gap)",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: "inherit", width: 120 }}>{item.invoice_no}</div>
          {fileLoading && (
            <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
          )}
          {item.invoice_file === null || item.invoice_file === ""
            ? ""
            : !fileLoading && (
                <InsertDriveFileOutlinedIcon
                  sx={{ cursor: "pointer", color: "var(--info)" }}
                  onClick={handleFile}
                />
              )}
        </div>
      </TableCell>
      <TableCell>{MessageTime(item?.created_at)}</TableCell>
      <TableCell>{item?.description}</TableCell>
      <TableCell>
        <div
          style={{
            fontSize: "inherit",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          {item.get_invoice_items_count}{" "}
          {loading ? (
            <CircularProgress sx={{ width: "20px!important", height: "20px!important" }} />
          ) : (
            <VisibilityIcon
              onClick={seeBillDetails}
              sx={{
                width: "20px!important",
                height: "20px!important",
                cursor: "pointer",
                color: "var(--btnBgBlue)",
              }}
            />
          )}
        </div>
      </TableCell>
      <TableCell>{item?.invoice_amount}</TableCell>
    </TableRow>
  );
};

export default FinanceFaturaTableBody;
