import {
  Box,
  CircularProgress,
  createTheme,
  Divider,
  Modal,
  ThemeProvider,
} from "@mui/material";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import { useRecoilState } from "recoil";
import {
  selectedShipmentForMyShipmentsState,
  shipmentDetailOpenState,
} from "../../../../atoms/myShipments";
import { useCallback, useEffect, useState } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px 5px 20px",
          overflow: "auto",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const ShipmentDetail = () => {
  const [shipmentDetailOpen, setShipmentDetailOpen] = useRecoilState(
    shipmentDetailOpenState
  );
  const [selectedShipmentForMyShipments, setSelectedShipmentForMyShipments] =
    useRecoilState(selectedShipmentForMyShipmentsState);

  const [dataIsLoad, setDataIsLoad] = useState(false);

  const handleShipmentDetailClose = () => {
    setShipmentDetailOpen(false);
    setDataIsLoad(false);
    setSelectedShipmentForMyShipments([]);
  };

  const getSelectedData = useCallback(() => {
    setDataIsLoad(true);
  }, []);

  useEffect(() => {
    if (selectedShipmentForMyShipments.length !== 0) {
      getSelectedData();
    }
  }, [selectedShipmentForMyShipments, getSelectedData]);

  return (
    <ThemeProvider theme={theme}>
      <Modal open={shipmentDetailOpen} onClose={handleShipmentDetailClose}>
        <Box
          sx={{
            maxWidth: "1100px",
            width: "100%",
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            margin: "20px auto",
            outline: 0,
            padding: "20px",
            position: "relative",
          }}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              color: "var(--priceText)",
              cursor: "pointer",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleShipmentDetailClose}
          />
          <h6
            style={{
              color: "var(--priceText)",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginBottom: "20px",
            }}
          >
            <PlagiarismOutlinedIcon />
            Gönderi Detayı
          </h6>
          {dataIsLoad ? (
            <Box
              sx={{
                display: "flex",
                gap: "var(--gap)",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px",
                }}
              >
                <Box
                  sx={{
                    border: "2px solid var(--info)",
                    minHeight: "200px",
                    maxWidth: "392px",
                    width: "100%",
                    borderRadius: "var(--inputBorderRadius)",
                    padding: "20px",
                  }}
                >
                  <Box sx={{ color: "var(--shipmentLight)", fontSize: "14px" }}>
                    Gönderi Numarası
                  </Box>
                  <Box
                    sx={{
                      color: "var(--priceText)",
                      fontWeight: 700,
                      fontSize: "18px",
                      margin: "5px 0",
                    }}
                  >
                    {selectedShipmentForMyShipments.parcel_ref_no}
                  </Box>
                  <Divider
                    sx={{
                      height: "1px",
                      backgroundColor: "var(--textPrice)",
                      width: "100%",
                    }}
                  />
                  <div>
                    <div>
                      <div></div>
                      <div
                        style={{
                          backgroundColor: "#E8F9EE",
                          width: "38px",
                          height: "38px",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#0EBC93",
                            width: "14px",
                            height: "14px",
                            borderRadius: "50%",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div
                      style={{
                        background: "rgba(133, 185, 237, 0.2)",
                        width: "38px",
                        height: "38px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    ></div>
                    <div></div>
                  </div>
                </Box>
              </Box>
              <Divider
                sx={{
                  width: "1px",
                  backgroundColor: "var(--priceText)",
                  opacity: 0.1,
                }}
              />
              <Box sx={{ width: "100%", padding: "10px" }}>
                {selectedShipmentForMyShipments.id} - Harita
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default ShipmentDetail;
