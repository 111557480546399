import { Box, Button } from "@mui/material";
import { CircleFlag } from "react-circle-flags";
import { useRecoilValue } from "recoil";
import { ticketState } from "../../../atoms/support";
import countries from "../../../json/countries.json";
import FlightTakeoffOutlinedIcon from "@mui/icons-material/FlightTakeoffOutlined";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FlightLandOutlinedIcon from "@mui/icons-material/FlightLandOutlined";
import NavigateParcelAdmin from "../../../utils/NavigateParcelAdmin";
import CopyToClipboard from "react-copy-to-clipboard";

const SupportDetailStepperCountries = () => {
  const ticket = useRecoilValue(ticketState);

  return (
    <>
      <Box className="steps-where-to l-size">
        <Box className="to-integration">
          <div>Etsy</div>
          <div>2410218693</div>
        </Box>
        <Box className="to-plane">
          {countries.find((e) => e.code === ticket.get_parcel?.sender_country).label === "Turkey"
            ? "TR"
            : countries
              .find((e) => e.code === ticket.get_parcel?.sender_country)
              .code.toUpperCase()}
          <div className="plane-flag">
            <CircleFlag
              countryCode={countries
                .find((e) => e.code === ticket.get_parcel?.sender_country)
                .code.toLowerCase()}
              height="24"
            />
            <FlightTakeoffOutlinedIcon id="take-off" />
          </div>
        </Box>
        <Box className="to-tracking">
          <Box sx={{
            display: "flex",
            gap: "10px",
            width: "180px"
          }}>
            <NavigateParcelAdmin
              parcel={ticket?.get_parcel?.parcel_ref_no}
              status={ticket?.get_parcel?.status}
            />
            {ticket?.get_parcel?.parcel_ref_no !== null &&
              <CopyToClipboard text={ticket?.get_parcel?.parcel_ref_no}>
                <Button sx={{
                  minWidth: "5px",
                }} variant="contained" color="primary">
                  <ContentCopyIcon fontSize={"10px"} />
                </Button>
              </CopyToClipboard>
            }
          </Box>
        </Box>
        <Box className="to-plane">
          {countries.find((e) => e.code === ticket.get_parcel?.receiver_country).code.toUpperCase()}
          <div className="plane-flag">
            <FlightLandOutlinedIcon id="land" />
            <CircleFlag
              countryCode={countries
                .find((e) => e.code === ticket.get_parcel?.receiver_country)
                .code.toLowerCase()}
              height="24"
            />
          </div>
        </Box>
      </Box>
      <Box className="steps-where-to s-size">
        <Box className="size-wrapper">
          <Box className="to-integration">
            <div>Etsy</div>
            <div>2410218693</div>
          </Box>
          <Box className="to-tracking">
            <NavigateParcelAdmin
              parcel={ticket?.get_parcel?.parcel_ref_no}
              status={ticket?.get_parcel?.status}
            />
          </Box>
        </Box>
        <Box className="size-wrapper">
          <Box className="to-plane">
            {countries.find((e) => e.code === ticket.get_parcel?.sender_country).label === "Turkey"
              ? "TR"
              : countries
                .find((e) => e.code === ticket.get_parcel?.sender_country)
                .code.toUpperCase()}
            <div className="plane-flag">
              <CircleFlag
                countryCode={countries
                  .find((e) => e.code === ticket.get_parcel?.sender_country)
                  .code.toLowerCase()}
                height="24"
              />
              <FlightTakeoffOutlinedIcon id="take-off" />
            </div>
          </Box>
          <Box className="to-plane">
            {countries
              .find((e) => e.code === ticket.get_parcel?.receiver_country)
              .code.toUpperCase()}
            <div className="plane-flag">
              <FlightLandOutlinedIcon id="land" />
              <CircleFlag
                countryCode={countries
                  .find((e) => e.code === ticket.get_parcel?.receiver_country)
                  .code.toLowerCase()}
                height="24"
              />
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SupportDetailStepperCountries;
