import {
  Autocomplete,
  Box,
  Button,
  createTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import React, { useCallback, useEffect, useState } from "react";
import axios from "../../../api/axios";
import {
  myShipmentListLoadingState,
  myShipmentListState,
  myShipmentsListLimitState,
  shipmentStatusState,
  shipmentListOrderDirectionState,
  timeState,
  timeStartState,
  timeEndState,
  senderCountryState,
  senderCountryCodeState,
  receiverCountryState,
  receiverCountryCodeState,
  selectedSupplierState,
  selectedPriceStatusState,
  selectedExportStatusState,
  selectedDomesticStatusState,
  selectedCaseStatusState,
  awbState,
  refIdState,
  senderState,
  receiverState,
  detailedStatusState,
  totalPagesState,
  basicSearchErrorState,
  basicSearchArrayState,
  basicFieldArrayState,
  errorSetState,
  totalItemsState,
  erasedShipmentState,
  selectedTrackingStatusState, selectedSearchDateTypeState,
} from "../../../atoms/myShipments";
import { useRecoilState, useRecoilValue } from "recoil";
import countries from "../../../json/countries.json";
import { searchAdminBakiyeIdState } from "../../../atoms/finance";
import Errors from "../../../utils/Errors";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          color: "currentcolor",
        },
        icon: {
          color: "currentcolor",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "currentcolor",
        },
      },
    },
  },
});

const MyShipmentsDetailedSearch = () => {
  const navigate = useNavigate();

  const [time, setTime] = useRecoilState(timeState);
  const [timeStart, setTimeStart] = useRecoilState(timeStartState);
  const [timeEnd, setTimeEnd] = useRecoilState(timeEndState);
  const [senderCountry, setSenderCountry] = useRecoilState(senderCountryState);
  const [senderCountryCode, setSenderCountryCode] = useRecoilState(
    senderCountryCodeState
  );

  const [dateTypeLabel, setDateTypeLabel] = useState("Oluşturulma Tarihi");
  const [supplierList, setSupplierList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [receiverCountry, setReceiverCountry] =
    useRecoilState(receiverCountryState);
  const [receiverCountryCode, setReceiverCountryCode] = useRecoilState(
    receiverCountryCodeState
  );

  const [selectedSearchDateType, setSelectedSearchDateType] = useRecoilState(
    selectedSearchDateTypeState
  );

  const [selectedSupplier, setSelectedSupplier] = useRecoilState(
    selectedSupplierState
  );
  const [selectedPriceStatus, setSelectedPriceStatus] = useRecoilState(
    selectedPriceStatusState
  );

  const [selectedTrackingStatus, setSelectedTrackingStatus] = useRecoilState(
    selectedTrackingStatusState
  );

  const [selectedExportStatus, setSelectedExportStatus] = useRecoilState(
    selectedExportStatusState
  );
  const [selectedDomesticStatus, setSelectedDomesticStatus] = useRecoilState(
    selectedDomesticStatusState
  );
  const [selectedCaseStatus, setSelectedCaseStatus] = useRecoilState(
    selectedCaseStatusState
  );

  const [awb, setAwb] = useRecoilState(awbState);
  const [refId, setRefId] = useRecoilState(refIdState);
  const [sender, setSender] = useRecoilState(senderState);
  const [receiver, setReceiver] = useRecoilState(receiverState);
  const [basicSearchArray, setBasicSearchArray] = useRecoilState(
    basicSearchArrayState
  );
  const [basicFieldArray, setBasicFieldArray] =
    useRecoilState(basicFieldArrayState);

  const [erasedShipment, setErasedShipment] =
    useRecoilState(erasedShipmentState);

  const [, setMyShipmentListLoading] = useRecoilState(
    myShipmentListLoadingState
  );
  const [, setMyShipmentList] = useRecoilState(myShipmentListState);

  const myShipmentsListLimit = useRecoilValue(myShipmentsListLimitState);
  const shipmentOrderDirectionPage = useRecoilValue(
    shipmentListOrderDirectionState
  );
  const [shipmentStatus, setShipmentStatus] =
    useRecoilState(shipmentStatusState);
  const [, setDetailedStatus] = useRecoilState(detailedStatusState);
  const [, setTotalPages] = useRecoilState(totalPagesState);
  const [, setBasicSearchError] = useRecoilState(basicSearchErrorState);
  const [, setErrorSet] = useRecoilState(errorSetState);
  const [, setTotalItems] = useRecoilState(totalItemsState);

  //* Member ID
  const [searchAdminBakiyeId, setSearchAdminBakiyeId] = useRecoilState(
    searchAdminBakiyeIdState
  );

  const handleDetailedSearch = useCallback(
    async (e) => {
      if (e) e.preventDefault();

      setMyShipmentListLoading(true);
      setDetailedStatus(1);

      const params = {
        order_direction: shipmentOrderDirectionPage,
        limit: myShipmentsListLimit,
        page: 1,
        detailed: 1,
        ...(awb !== "" && { tracking_awb: awb }),
        ...(refId !== "" && { parcel_ref_no: refId }),
        ...(selectedSupplier?.length > 0 && {
          supplier_id: selectedSupplier, // Boş değilse array olarak gönderilir
        }),
        ...(shipmentStatus !== "" && { status: shipmentStatus }),
        ...(selectedSearchDateType !== "" && { date_search_type: selectedSearchDateType }),
        ...(time !== "" && { created_at: time }),
        ...(timeStart !== "" && { from_date: timeStart }),
        ...(timeEnd !== "" && { to_date: timeEnd }),
        ...(basicSearchArray !== "" && { where_in_field: basicSearchArray }),
        ...(basicFieldArray !== "" && { where_in_array: basicFieldArray }),
        ...(sender !== "" && { sender_name: sender }),
        ...(receiver !== "" && { receiver_name: receiver }),
        ...(senderCountryCode !== "" && { sender_country: senderCountryCode }),
        ...(selectedTrackingStatus !== "" && {
          has_tracking_awb: selectedTrackingStatus,
        }),
        ...(receiverCountryCode !== "" && {
          receiver_country: receiverCountryCode,
        }),
        ...(selectedExportStatus !== "" && {
          parcel_type: selectedExportStatus,
        }),
        ...(selectedPriceStatus !== "" && { paid: selectedPriceStatus }),
        ...(selectedDomesticStatus !== "" && {
          is_domestic: selectedDomesticStatus,
        }),
        ...(selectedCaseStatus !== "" && { is_case: selectedCaseStatus }),
        ...(searchAdminBakiyeId !== "" && { member_id: searchAdminBakiyeId }),
        ...(erasedShipment !== "" && { deleted: erasedShipment }),
      };

      const filteredParam = Object.fromEntries(
        Object.entries(params).filter(
          ([key, value]) => value !== "" && value !== null
        )
      );

      await axios
        .get(`parcel`, { params: filteredParam })
        .then((response) => {
          setMyShipmentList(response.data.data);
          setTotalPages(response.data.totalPages);
          setTotalItems(response.data.totalItems);
          setErrorSet(true);
          setBasicSearchError(false);
        })
        .catch((error) => {
          Errors(error);
        })
        .finally(() => {
          setMyShipmentListLoading(false);
        });
    },
    [
      setMyShipmentList,
      setMyShipmentListLoading,
      setDetailedStatus,
      setTotalPages,
      setErrorSet,
      setBasicSearchError,
      setTotalItems,
      erasedShipment,
      awb,
      time,
      timeStart,
      timeEnd,
      shipmentOrderDirectionPage,
      myShipmentsListLimit,
      refId,
      selectedSupplier,
      shipmentStatus,
      sender,
      senderCountryCode,
      receiver,
      receiverCountryCode,
      selectedExportStatus,
      selectedDomesticStatus,
      selectedCaseStatus,
      selectedPriceStatus,
      searchAdminBakiyeId,
      basicSearchArray,
      basicFieldArray,
      selectedTrackingStatus,
    ]
  );

  const handleSenderCountry = useCallback(
    (e) => {
      const value = e.target.value;
      setSenderCountry(value.slice(0, -3));
      setSenderCountryCode(value.substr(value.length - 2));
    },
    [setSenderCountry, setSenderCountryCode]
  );

  const getSupplierData = () => {
    axios
      .get(`supplier/list-simple`, { params: { unlimited: 1 } })
      .then((response) => setSupplierList(response.data.data))
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  const handleReceiverCountry = useCallback(
    (e) => {
      const value = e.target.value;
      setReceiverCountry(value.slice(0, -3));
      setReceiverCountryCode(value.substr(value.length - 2));
    },
    [setReceiverCountry, setReceiverCountryCode]
  );

  const handleClear = useCallback(() => {
    const params = {
      order_direction: shipmentOrderDirectionPage,
      limit: myShipmentsListLimit,
      page: 1,
    };

    axios
      .get(`parcel`, { params: params })
      .then((response) => {
        setMyShipmentList(response.data.data);
        setTotalPages(response.data.totalPages);
        setTotalItems(response.data.totalItems);
        setErrorSet(true);
        setBasicSearchError(false);

        // Clear States
        setTime("");
        setTimeStart("");
        setTimeEnd("");
        setSenderCountry("");
        setSenderCountryCode("");
        setReceiverCountry("");
        setReceiverCountryCode("");
        setSelectedSupplier("");
        setSelectedSearchDateType("");
        setSelectedPriceStatus("");
        setSelectedExportStatus("");
        setSelectedDomesticStatus("");
        setSelectedCaseStatus("");
        setAwb("");
        setRefId("");
        setSender("");
        setReceiver("");
        setDetailedStatus(0);
        setSearchAdminBakiyeId("");
        setErasedShipment(0);
        setShipmentStatus("");
        setSelectedTrackingStatus(null)
        setBasicFieldArray([]);

        // Clear Search Parameters
        if (window.location.href.includes("price-control-screen")) {
          navigate("/operation-progress/price-control-screen");
        } else {
          navigate("/my-shipment");
        }
      })
      .catch((error) => {
        Errors(error);
      })
      .finally(() => {
        setMyShipmentListLoading(false);
      });
  }, [
    navigate,
    setSearchAdminBakiyeId,
    setMyShipmentList,
    setMyShipmentListLoading,
    setDetailedStatus,
    setTotalPages,
    setTotalItems,
    setErrorSet,
    setBasicSearchError,
    shipmentOrderDirectionPage,
    myShipmentsListLimit,
    setTime,
    setSenderCountry,
    setSenderCountryCode,
    setReceiverCountry,
    setReceiverCountryCode,
    setSelectedSupplier,
    setSelectedPriceStatus,
    setSelectedExportStatus,
    setSelectedDomesticStatus,
    setSelectedSearchDateType,
    setAwb,
    setRefId,
    setSender,
    setReceiver,
    setShipmentStatus,
    setErasedShipment,
    setBasicFieldArray,
    setSelectedTrackingStatus
  ]);

  useEffect(() => {
    getSupplierData();
    setSelectedSearchDateType("");
  }, []);

  useEffect(() => {
    setTimeout(() => {
      handleDetailedSearch();
    }, 2000);
  }, [searchAdminBakiyeId]);

  return (
    <ThemeProvider theme={theme}>
      <form
        onSubmit={handleDetailedSearch}
        className="myShipments-detailed-search-wrapper"
      >
        <h6 className="myShipments-detailed-search-title">
          <TravelExploreOutlinedIcon />
          Detaylı Arama
        </h6>
        <Box className="myShipments-detailed-search">
          <TextField
            value={awb}
            onChange={(e) => setAwb(e.target.value)}
            autoComplete="off"
            fullWidth
            size="small"
            label="AWB"
          />
          <TextField
            autoComplete="off"
            fullWidth
            size="small"
            label="Referans ID"
            value={refId}
            onChange={(e) => setRefId(e.target.value)}
          />
          <FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: "14px" }} id="supplier-select-label">
              Tedarikçi
            </InputLabel>
            <Select
              labelId="supplier-select-label"
              id="supplier-select"
              label="Tedarikçi"
              multiple
              value={selectedSupplier || []} // Ensure this is always an array
              onChange={(e) => setSelectedSupplier(e.target.value)} // Update state with the array
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
              renderValue={(selected) =>
                (selected || [])
                  .map(id => {
                    const supplier = supplierList?.find(sup => sup.id === id);
                    return supplier ? supplier.supplier_name : id;
                  })
                  .join(', ')
              } // Display selected suppliers' names separated by commas
            >
              <MenuItem value="">
                <em>Hepsi</em>
              </MenuItem>
              {supplierList?.map((sup, key) => (
                <MenuItem key={key} value={sup.id}>
                  {sup.supplier_name} - {sup.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: "14px" }} id="date-type-select-label">
              Tarih Tipi
            </InputLabel>
            <Select
              labelId="date-type-select-label"
              id="date-type-select"
              label="Tarih Tipi"
              value={selectedSearchDateType}
              onChange={(e) => setSelectedSearchDateType(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={"_created"}>Oluşma Tarihine Göre</MenuItem>
              <MenuItem value={"delivered_to_supplier"}>Taşıyıcıya Teslim Tarihine Göre</MenuItem>
            </Select>
          </FormControl>


          <TextField
            type={"date"}
            /*/label="Oluşturulma Tarihi"/*/
            label={dateTypeLabel}
            InputLabelProps={{ shrink: true }}
            value={time}
            onChange={(e) => setTime(e.target.value)}
            size={"small"}
          />
          <TextField
            type={"date"}
            label="Tarih Aralığı Başlangıç"
            InputLabelProps={{ shrink: true }}
            value={timeStart}
            onChange={(e) => setTimeStart(e.target.value)}
            size={"small"}
          />
          <TextField
            type={"date"}
            label="Tarih Aralığı Bitiş"
            InputLabelProps={{ shrink: true }}
            value={timeEnd}
            onChange={(e) => setTimeEnd(e.target.value)}
            size={"small"}
          />
          <TextField
            autoComplete="off"
            fullWidth
            size="small"
            label="Gönderici Kişi"
            value={sender}
            onChange={(e) => setSender(e.target.value)}
          />
          <TextField
            autoComplete="off"
            fullWidth
            size="small"
            label="Alıcı Kişi"
            value={receiver}
            onChange={(e) => setReceiver(e.target.value)}
          />
          <Autocomplete
            id="country-sender-typing-select"
            fullWidth
            size="small"
            options={countries}
            autoHighlight
            getOptionLabel={(option) => `${option.label} ${option.code}`}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                label="Çıkış Ülkesi"
                value={senderCountry}
                onBlur={(e) => handleSenderCountry(e)}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
          <Autocomplete
            id="country-receiver-typing-select"
            fullWidth
            size="small"
            options={countries}
            autoHighlight
            getOptionLabel={(option) => `${option.label} ${option.code}`}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Varış Ülkesi"
                value={receiverCountry}
                onBlur={(e) => handleReceiverCountry(e)}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
          <FormControl fullWidth size="small">
            <InputLabel
              sx={{ fontSize: "14px" }}
              id="price-status-select-label"
            >
              Ödeme Durumu
            </InputLabel>
            <Select
              labelId="price-status-select-label"
              id="price-status-select"
              label="Ödeme Durumu"
              value={selectedPriceStatus}
              multiline
              onChange={(e) => setSelectedPriceStatus(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={0}>Ödeme Bekleyenler</MenuItem>
              <MenuItem value={1}>Ödenenler</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel
              sx={{ fontSize: "14px" }}
              id="price-status-select-label"
            >
              Takip Numarası Durumu
            </InputLabel>
            <Select
              labelId="price-status-select-label"
              id="price-status-select"
              label="Takip Numarası Durumu"
              value={selectedTrackingStatus}
              multiline
              onChange={(e) => setSelectedTrackingStatus(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={1}>Olanlar</MenuItem>
              <MenuItem value={0}>Olmayanlar</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel
              sx={{ fontSize: "14px" }}
              id="price-status-select-label"
            >
              Silinme Durumu
            </InputLabel>
            <Select
              labelId="price-status-select-label"
              id="price-status-select"
              label="Silinme Durumu"
              value={erasedShipment}
              multiline
              onChange={(e) => setErasedShipment(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={0}>Silinmemişler</MenuItem>
              <MenuItem value={1}>Silinenler</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel
              sx={{ fontSize: "14px" }}
              id="export-status-select-label"
            >
              İhracat Tipi
            </InputLabel>
            <Select
              labelId="export-status-select-label"
              id="export-status-select"
              label="İhracat Tipi"
              value={selectedExportStatus}
              onChange={(e) => setSelectedExportStatus(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={1}>Normal</MenuItem>
              <MenuItem value={2}>Hediye</MenuItem>
              <MenuItem value={3}>Mikro İhracat</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel
              sx={{ fontSize: "14px" }}
              id="domestic-status-select-label"
            >
              Domestik Durumu
            </InputLabel>
            <Select
              labelId="domestic-status-select-label"
              id="domestic-status-select"
              label="Domestik Durumu"
              value={selectedDomesticStatus}
              multiline
              onChange={(e) => setSelectedDomesticStatus(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={0}>Girilmeyenler</MenuItem>
              <MenuItem value={1}>Girilenler</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: "14px" }} id="case-status-select-label">
              Case Durumu
            </InputLabel>
            <Select
              labelId="case-status-select-label"
              id="case-status-select"
              label="Case Durumu"
              value={selectedCaseStatus}
              multiline
              onChange={(e) => setSelectedCaseStatus(e.target.value)}
              sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
            >
              <MenuItem value="">
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={0}>Case Olmayanlar</MenuItem>
              <MenuItem value={1}>Case Olanlar</MenuItem>
            </Select>
          </FormControl>
          <TextField
            autoComplete="off"
            fullWidth
            size="small"
            label="Member ID"
            value={searchAdminBakiyeId}
            onChange={(e) => setSearchAdminBakiyeId(e.target.value)}
          />
        </Box>
        <Box
          sx={{ gap: "var(--gap)" }}
          className="myShipments-detailed-search-done"
        >
          <Button className="iyzi-button-blue" type="submit">
            <SearchOutlinedIcon />
            Arama Yap
          </Button>
          <Button
            type="button"
            className="iyzi-button-blue"
            onClick={handleClear}
          >
            Temizle
          </Button>
        </Box>
      </form>
    </ThemeProvider >
  );
};

export default MyShipmentsDetailedSearch;
