import { TableCell, TableHead, TableRow } from "@mui/material";

const SupportListTableHeadAdmin = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Vaka Numarası</TableCell>
        <TableCell>Ref No</TableCell>
        <TableCell>Konu</TableCell>
        <TableCell>Durum</TableCell>
        <TableCell>Kimden</TableCell>
        <TableCell>Derece</TableCell>
        <TableCell>Not</TableCell>
        <TableCell>İlgilenen</TableCell>
        <TableCell>Açılış Tarihi</TableCell>
        <TableCell>Son İşlem</TableCell>
        <TableCell>Çözüm Tarihi</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default SupportListTableHeadAdmin;
