import { Table, TableBody, TableContainer } from "@mui/material";
import NewMemberTableHead from "./NewMemberTableHead";
import NewMemberTableBody from "./NewMemberTableBody";

const NewMemberTable = ({ data }) => {
  return (
    <TableContainer
      style={{
        backgroundColor: "var(--lighterBg)",
        padding: "20px",
        borderRadius: "var(--wrapperBorderRadius",
        boxShadow: "var(--shadow)",
      }}
    >
      <Table>
        <NewMemberTableHead />
        <TableBody>
          {data?.map((item) => (
            <NewMemberTableBody key={item?.id} item={item} data={data} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NewMemberTable;
