import React from "react";
import { Box } from "@mui/material";
import NavBar from "../components/sidebar/NavBar";
import { ToastContainer } from "react-toastify";
import Log from "../components/logs/Log";
import NotificationBar from "../components/sidebar/NotificationBar";

export default function Logs() {
  return (
    <>
      <NotificationBar />
      <section className="myShipments">
        <NavBar />
        <ToastContainer />
        <Box className="myShipments-wrapper">
          <Log />
        </Box>
      </section>
    </>
  );
}
