import { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { FormatReturnFieldWithDotsAndComma } from "../../../../utils/FormatFieldReturn";

const ActivePassiveMemberCountChart = ({ data }) => {
  const ref = useRef(null);

  useEffect(() => {
    const createChart = (ref, data) => {
      if (ref.current) {
        const chartData = {
          labels: data?.labels,
          datasets: data?.datasets,
        };

        const chartOptions = {
          aspectRatio: 4,
          scales: {
            x: {
              type: "category",
            },
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 1,
              },
            },
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
          },
          plugins: {
            legend: {
              display: true,
              position: "bottom",
            },
          },
        };

        if (ref.current.chart) {
          ref.current.chart.destroy();
        }

        const newChart = new Chart(ref.current, {
          data: chartData,
          options: chartOptions,
        });

        ref.current.chart = newChart;
      }
    };

    createChart(ref, data);
  }, [data]);

  return (
    <div
      style={{
        backgroundColor: "var(--lighterBg)",
        boxShadow: "var(--shadow)",
        borderRadius: "var(--wrapperBorderRadius)",
        padding: "20px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between", gap: "var(--gap2x)" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "var(--gap)" }}>
          <h6 style={{ color: "var(--priceText)" }}>Aktif vs Pasif Müşteri Sayısı</h6>
        </div>
        <div style={{ display: "flex", gap: "var(--gap2x)", alignItems: "center" }}>
          <span style={{ fontSize: "24px", color: "var(--shipmentGreen)" }}>
            {FormatReturnFieldWithDotsAndComma(data?.average_count_active)}
            <span style={{ fontSize: "14px", color: "var(--priceText)" }}>Aktif</span>
          </span>
          <span style={{ fontSize: "24px", color: "var(--shipmentRed)" }}>
            {FormatReturnFieldWithDotsAndComma(data?.average_count_passive)}
            <span style={{ fontSize: "14px", color: "var(--priceText)" }}>Pasif</span>
          </span>
        </div>
      </div>
      <canvas ref={ref} />
    </div>
  );
};

export default ActivePassiveMemberCountChart;
