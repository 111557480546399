import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const DeleteDialog = ({ open, setOpen, handleDelete }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setOpen(false);
    handleDelete();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Silme İsteği</DialogTitle>
      <DialogContent>
        <DialogContentText>
          İlgili satırı silmek üzeresiniz. Devam etmek istiyor musunuz?
        </DialogContentText>
        <DialogActions>
          <Button className="iyzi-button-gray" onClick={handleClose}>
            İptal
          </Button>
          <Button className="iyzi-button-red" onClick={handleSubmit}>
            Sil
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteDialog;
