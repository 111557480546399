import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import axios from "../../../../../api/axios";
import { validateEmail } from "../../../../../utils/emailValidation";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Errors from "../../../../../utils/Errors";
import { memberDocumentEditOpenState, memberListState } from "../../../../../atoms/members";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";

const MemberCreateUserModal = ({ createUserModal, setCreateUserModal, memberId }) => {
  const [, setMemberDocumentEditOpen] = useRecoilState(memberDocumentEditOpenState);
  const [, setMemberList] = useRecoilState(memberListState);

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [lang, setLang] = useState("tr");
  const [role, setRole] = useState(1);

  const [pwdError, setPwdError] = useState(false);
  const [pwdRepeatError, setPwdRepeatError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneReq, setPhoneReq] = useState(false);
  const [notEqualPwd, setNotEqualPwd] = useState(false);

  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    password: "",
    passwordRepeat: "",
    showPassword: false,
    showPasswordRepeat: false,
  });

  const handleClose = () => {
    setCreateUserModal(false);
    setPwdError(false);
    setPwdRepeatError(false);
    setPhoneError(false);
    setPhoneReq(false);
    setNotEqualPwd(false);
    setName("");
    setSurname("");
    setEmail("");
    setPhone("");
    setLang("tr");
    setRole(1);
    setValues({
      password: "",
      passwordRepeat: "",
      showPassword: false,
      showPasswordRepeat: false,
    });
  };

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;

    setEmail(newEmail);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowPasswordRepeat = () => {
    setValues({
      ...values,
      showPasswordRepeat: !values.showPasswordRepeat,
    });
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Tab" && !e.shiftKey) {
      e.preventDefault();
      document.getElementById("outlined-adornment-passwordRepeat").focus();
    }
  };

  const handleTextChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === "name") {
      setName(value);
    }
    if (name === "surname") {
      setSurname(value);
    }
    if (name === "phone") {
      setPhone(value);

      if (value?.length === 0 || value?.length === 10) {
        setPhoneError(false);
      } else {
        setPhoneError(true);
      }

      if (value?.length > 0) {
        setPhoneReq(true);
      } else {
        setPhoneReq(false);
      }
    }
  };

  const handleCreateUser = async (e) => {
    if (e) e.preventDefault();

    if (values.password === "" || values.password.length < 6) {
      setPwdError(true);
      return;
    }

    if (values.password === "" || values.passwordRepeat === "") {
      setPwdRepeatError(true);
      return;
    }

    if (values.password !== values.passwordRepeat) {
      setNotEqualPwd(true);
      return;
    }

    const param = {
      member_id: memberId,
      name,
      surname,
      email,
      password: values.password,
      password_confirmation: values.passwordRepeat,
      lang,
      role_id: role,
      ...(phone !== "" && { phone }),
    };

    if (
      values.password.length > 5 ||
      values.passwordRepeat !== "" ||
      values.password === values.passwordRepeat
    ) {
      setLoading(true);
      await axios
        .post(`user/add`, param)
        .then((response) => {
          toast.success(response.data.message);

          setMemberList((prevMemberList) =>
            prevMemberList.map((member) =>
              member.id === memberId
                ? {
                    ...member,
                    get_member_users: [...member.get_member_users, response.data.data],
                  }
                : member
            )
          );

          // Aynı işlemi memberEditUserModal state için de uygulayın.
          setMemberDocumentEditOpen((prevMemberEditUserModal) => ({
            ...prevMemberEditUserModal,
            data: {
              ...prevMemberEditUserModal.data,
              get_member_users: prevMemberEditUserModal.data.get_member_users
                ? [...prevMemberEditUserModal.data.get_member_users, response.data.data]
                : [response.data.data], // Eğer get_member_users undefined veya null ise yeni bir array oluşturur ve yeni user'ı ekler
            },
          }));
          handleClose();
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    }
  };

  return (
    <Modal open={createUserModal} onClose={handleClose}>
      <div
        style={{
          width: "100%",
          maxWidth: "600px",
          margin: "auto",
          borderRadius: "var(--wrapperBorderRadius)",
          padding: "20px",
          backgroundColor: "var(--lighterBg)",
        }}
      >
        <form
          onSubmit={handleCreateUser}
          style={{ display: "flex", flexDirection: "column", gap: "var(--gap)" }}
        >
          <h6 style={{ color: "var(--priceText)", marginBottom: "var(--gap2x)" }}>
            Kullanıcı Ekle
          </h6>
          <div style={{ display: "flex", flexDirection: "column", gap: "var(--gap2x)" }}>
            <div style={{ display: "flex", gap: "var(--gap2x)" }}>
              <TextField
                value={name}
                name="name"
                onChange={handleTextChange}
                autoComplete="off"
                fullWidth
                size="small"
                label="İsim"
                required
              />
              <TextField
                value={surname}
                name="surname"
                onChange={handleTextChange}
                autoComplete="off"
                fullWidth
                size="small"
                label="Soyisim"
                required
              />
            </div>
            <div style={{ display: "flex", gap: "var(--gap2x)" }}>
              <FormControl size="small" fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Şifre</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  autoComplete="off"
                  onChange={handleChange("password")}
                  onKeyDown={handleKeyDown}
                  required
                  fullWidth
                  error={pwdError}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {pwdError && (
                  <FormHelperText sx={{ color: "var(--error)", fontSize: "13px" }}>
                    Şifre 6 haneden az olamaz!
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl size="small" fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-passwordRepeat">Şifre Tekrarı</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-passwordRepeat"
                  type={values.showPasswordRepeat ? "text" : "password"}
                  value={values.passwordRepeat}
                  autoComplete="off"
                  onChange={handleChange("passwordRepeat")}
                  onKeyDown={handleKeyDown}
                  required
                  error={pwdRepeatError || notEqualPwd}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle passwordRepeat visibility"
                        onClick={handleClickShowPasswordRepeat}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Şifre Tekrarı"
                />
                {(pwdRepeatError || notEqualPwd) && (
                  <FormHelperText sx={{ color: "var(--error)", fontSize: "13px" }}>
                    Şifre Tekrarı, Şifre ile aynı olmak zorundadır!
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div style={{ display: "flex", gap: "var(--gap2x)" }}>
              <TextField
                fullWidth
                value={email}
                onChange={handleEmailChange}
                error={email?.length !== 0 && !validateEmail(email)}
                helperText={
                  email?.length !== 0 && !validateEmail(email) && "Geçerli bir email giriniz."
                }
                autoComplete="on"
                inputMode="email"
                type="email"
                id="outlined-register-email"
                size="small"
                label="Email Adresiniz"
                variant="outlined"
                required
              />
              <TextField
                value={phone}
                name="phone"
                onChange={handleTextChange}
                required={phoneReq}
                autoComplete="off"
                placeholder="5332221144"
                error={phoneError}
                fullWidth
                size="small"
                label="Telefon"
              />
            </div>
            <div style={{ display: "flex", gap: "var(--gap2x)" }}>
              <FormControl size="small" fullWidth>
                <InputLabel id="lang-select-label">Dil Seçeneği</InputLabel>
                <Select
                  labelId="lang-select-label"
                  id="lang-select"
                  value={lang}
                  label="Dil Seçeneği"
                  onChange={(e) => setLang(e.target.value)}
                >
                  <MenuItem value={"tr"}>Türkçe</MenuItem>
                  <MenuItem value={"en"}>İngilizce</MenuItem>
                </Select>
              </FormControl>
              <FormControl size="small" fullWidth>
                <InputLabel id="role-select-label">Rol</InputLabel>
                <Select
                  labelId="role-select-label"
                  id="role-select"
                  value={role}
                  label="Rol"
                  onChange={(e) => setRole(e.target.value)}
                >
                  <MenuItem value={1}>(MEM) Member</MenuItem>
                  <MenuItem value={2}>(DEV) Developers</MenuItem>
                  <MenuItem value={3}>(BOS) Super Admin</MenuItem>
                  <MenuItem value={4}>(ADM) Admin</MenuItem>
                  <MenuItem value={5}>(FIN) Finance</MenuItem>
                  <MenuItem value={6}>(SPO) Supervisor OPS</MenuItem>
                  <MenuItem value={7}>(OPS) OPS Stuff</MenuItem>
                  <MenuItem value={8}>(COU) Courier</MenuItem>
                  <MenuItem value={9}>(SPC) Supervisor CS</MenuItem>
                  <MenuItem value={10}>(CSE) CS Stuff</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", gap: "var(--gap)" }}>
            {loading ? (
              <div
                style={{
                  width: "64px",
                  height: "35px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
              </div>
            ) : (
              <Button type="submit" className="iyzi-button-blue">
                Kaydet
              </Button>
            )}
            <Button type="button" onClick={handleClose} className="iyzi-button-blue">
              Kapat
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default MemberCreateUserModal;
