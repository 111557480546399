import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  createTheme,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useCallback, useState } from "react";
import PriceControlScreenDetailedSearch from "./PriceControlScreenDetailedSearch";
import SearchIcon from "@mui/icons-material/Search";
import {
  awbState,
  domNoState,
  basicSearchErrorState,
  basicSearchState,
  detailedStatusState,
  errorSetState,
  myShipmentListLoadingState,
  myShipmentListState,
  myShipmentsListLimitState,
  receiverCountryCodeState,
  receiverState,
  refIdState,
  selectedDomesticStatusState,
  selectedCaseStatusState,
  selectedExportStatusState,
  selectedPriceStatusState,
  selectedPriceGiveStatusState,
  selectedBeevisionStatusState,
  selectedSupplierState,
  senderCountryCodeState,
  senderState,
  shipmentListOrderDirectionState,
  shipmentStatusState,
  timeState,
  timeStartState,
  timeEndState,
  totalPagesState,
  selectedPacTypeState,
} from "../../../../atoms/myShipments";
import {
  PriceControlScreenCurrentPageState,
  PriceControlScreenDataState,
  PriceControlScreenTotalPageState,
  PriceControlScreenIDState,
} from "../../../../atoms/priceControl";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "../../../../api/axios";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Errors from "../../../../utils/Errors";
import { searchAdminBakiyeIdState } from "../../../../atoms/finance";

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          boxShadow: "none",
        },
      },
    },
  },
});

const PriceControlScreenSearch = () => {
  const navigate = useNavigate();

  // awb parametresi url'de bulunuyorsa silmek için.
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const awbParam = searchParams.get("memberId");

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [priceControlScreenData, setPriceControlScreenData] = useRecoilState(
    PriceControlScreenDataState
  );

  const [priceControlCurrentPageState, setPriceControlCurrentPageState] =
    useRecoilState(PriceControlScreenCurrentPageState);

  const detailedStatus = useRecoilValue(detailedStatusState);

  const [PriceControlScreenTotalPage, setPriceControlScreenTotalPage] =
    useRecoilState(PriceControlScreenTotalPageState);

  const [shipmentStatus, setShipmentStatus] =
    useRecoilState(shipmentStatusState);
  const [basicSearch, setBasicSearch] = useRecoilState(basicSearchState);
  const [myShipmentList, setMyShipmentList] =
    useRecoilState(myShipmentListState);
  const [, setMyShipmentListLoading] = useRecoilState(
    myShipmentListLoadingState
  );
  const [, setDetailedStatus] = useRecoilState(detailedStatusState);
  const [, setTotalPages] = useRecoilState(totalPagesState);
  const [basicSearchError, setBasicSearchError] = useRecoilState(
    basicSearchErrorState
  );
  const [errorSet, setErrorSet] = useRecoilState(errorSetState);

  const myShipmentsListLimit = useRecoilValue(myShipmentsListLimitState);
  const shipmentOrderDirectionPage = useRecoilValue(
    shipmentListOrderDirectionState
  );
  const awb = useRecoilValue(awbState);
  const domNo = useRecoilValue(domNoState);
  const refId = useRecoilValue(refIdState);
  const sender = useRecoilValue(senderState);
  const receiver = useRecoilValue(receiverState);
  const time = useRecoilValue(timeState);
  const timeStart = useRecoilValue(timeStartState);
  const timeEnd = useRecoilValue(timeEndState);
  const senderCountryCode = useRecoilValue(senderCountryCodeState);
  const receiverCountryCode = useRecoilValue(receiverCountryCodeState);
  const selectedSupplier = useRecoilValue(selectedSupplierState);
  const selectedPriceStatus = useRecoilValue(selectedPriceStatusState);
  const selectedPriceGiveStatus = useRecoilValue(selectedPriceGiveStatusState);
  const selectedBeevision = useRecoilValue(selectedBeevisionStatusState);
  const selectedExportStatus = useRecoilValue(selectedExportStatusState);
  const selectedDomesticStatus = useRecoilValue(selectedDomesticStatusState);
  const selectedCaseStatus = useRecoilValue(selectedCaseStatusState);
  const pacType = useRecoilValue(selectedPacTypeState);

  //* Member ID
  const [searchAdminBakiyeId, setSearchAdminBakiyeId] = useRecoilState(
    searchAdminBakiyeIdState
  );

  const handleBasicSearch = useCallback(async () => {
    setDetailedStatus(0);

    await axios
      .get(
        detailedStatus === "1"
          ? `parcel?order_direction=${shipmentOrderDirectionPage}&limit=1000&page=${priceControlCurrentPageState}&tracking_awb=${awb}&domestic_awb=${domNo}&parcel_ref_no=${refId}&supplier_id=${
              selectedSupplier !== ""
                ? Number(selectedSupplier)
                : selectedSupplier
            }&status=${shipmentStatus}&arrived_to_office_date=${time}&arrived_from_date=${timeStart}&arrived_to_date=${timeEnd}&packaging_type=${pacType}&sender_name=${sender}&receiver_name=${receiver}&sender_country=${senderCountryCode}&receiver_country=${receiverCountryCode}&parcel_type=${selectedExportStatus}&paid=${selectedPriceStatus}&price_approved=${selectedPriceGiveStatus}&is_domestic=${selectedDomesticStatus}&is_case=${selectedCaseStatus}&detailed=1`
          : `parcel?&detailed=1&limit=1000&page=${1}&beevision_read=1,2&order_by=arrived_to_office_date&order_direction=DESC&parcel_ref_no=${basicSearch}& `
      )
      // .get(
      //   `parcel?&detailed=1&limit=1000&page=${1}&beevision_read=1,2&&order_by=arr&order_direction=DESC&parcel_ref_no=${basicSearch}`
      // )
      .then((response) => {
        setPriceControlScreenData(response.data.data);
        setPriceControlScreenTotalPage(response.data.totalPages);

        if (basicSearch === "") {
          setErrorSet(true);
          setBasicSearchError(false);
        }
      })
      .catch((error) => {
        Errors(error);
      })
      .finally(() => {});
  }, [
    setBasicSearchError,
    setErrorSet,
    setPriceControlScreenData,
    setPriceControlScreenTotalPage,
    shipmentStatus,
    myShipmentsListLimit,
    shipmentOrderDirectionPage,
    basicSearch,
    selectedSupplier,
    sender,
    senderCountryCode,
    receiver,
    receiverCountryCode,
    selectedExportStatus,
    selectedDomesticStatus,
    selectedCaseStatus,
    selectedPriceStatus,
    selectedBeevision,
    searchAdminBakiyeId,
    detailedStatus,
    shipmentStatus,
    time,
    timeStart,
    timeEnd,
    pacType,
    awb,
    domNo,
    refId,
    priceControlCurrentPageState,
    
  ]);

  const handleClearBasicSearch = () => {
    setBasicSearch("");
    setSearchAdminBakiyeId("");
    navigate("/operation-progress/price-control-screen");
  };

  const handleDetailedSearch = useCallback(
    async (shipmentStatusValue) => {
      setMyShipmentListLoading(true);
      setDetailedStatus(1);

      const params = {
        order_direction: shipmentOrderDirectionPage,
        limit: myShipmentsListLimit,
        tracking_awb: awb,
        domestic_awb: domNo,
        parcel_ref_no: refId,
        supplier_id:
          selectedSupplier !== "" ? Number(selectedSupplier) : selectedSupplier,
        status: shipmentStatusValue,
        arrived_to_office_date: time,
        sender_name: sender,
        receiver_name: receiver,
        sender_country: senderCountryCode,
        receiver_country: receiverCountryCode,
        parcel_type: selectedExportStatus,
        paid: selectedPriceStatus,
        beevision_read: selectedBeevision,
        is_domestic: selectedDomesticStatus,
        is_case: selectedCaseStatus,
        member_id: searchAdminBakiyeId,
        detailed: 1,
      };

      await axios
        .get(`parcel`, { params: params })
        .then((response) => {
          setPriceControlScreenData(response.data.data);
          setPriceControlScreenTotalPage(response.data.totalPages);
          setErrorSet(true);
          setBasicSearchError(false);
        })
        .catch((error) => {
          Errors(error);
        })
        .finally(() => {
          setMyShipmentListLoading(false);
        });
    },
    [
      setPriceControlScreenData,
      setMyShipmentListLoading,
      setDetailedStatus,
      setPriceControlScreenTotalPage,
      setErrorSet,
      setBasicSearchError,
      awb,
      domNo,
      time,
      shipmentOrderDirectionPage,
      myShipmentsListLimit,
      refId,
      selectedSupplier,
      sender,
      senderCountryCode,
      receiver,
      receiverCountryCode,
      selectedExportStatus,
      selectedDomesticStatus,
      selectedCaseStatus,
      selectedPriceStatus,
      selectedBeevision,
      searchAdminBakiyeId,
    ]
  );

  useEffect(() => {
    if (myShipmentList.length === 0 && !errorSet) {
      setBasicSearchError(true);
    }

    if (myShipmentList.length !== 0) {
      setErrorSet(false);
    }
  }, [myShipmentList, errorSet, setBasicSearchError, setErrorSet]);

  useEffect(() => {
    if (awbParam) {
      setExpanded(true);
      handleDetailedSearch();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box className="myShipments-search">
        <Card className="search-card">
          <CardContent className="search-card-content">
            <Box sx={{ display: "flex", gap: "var(--gap)", width: "100%" }}>
              <TextField
                className="search-card-content-search"
                label="Ref No"
                value={basicSearch}
                error={basicSearchError}
                autoComplete="off"
                helperText={
                  basicSearchError
                    ? "Arama kriterlerinizi değiştirip yeniden deneyiniz."
                    : ""
                }
                onChange={(e) => setBasicSearch(e.target.value)}
                onKeyDown={(e) =>
                  e.key === "Enter"
                    ? handleBasicSearch()
                    : e.key === "Escape"
                    ? handleClearBasicSearch()
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                size="small"
              />
            </Box>
            <Box sx={{ display: "flex", gap: "var(--gap)" }}>
              <Button
                className="iyzi-button-blue"
                style={{ minWidth: "150px" }}
                onClick={handleExpandClick}
              >
                {expanded ? "Detaylı Aramayı Kapat" : "Detaylı Aramayı Aç"}
              </Button>
            </Box>
          </CardContent>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <PriceControlScreenDetailedSearch />
            </CardContent>
          </Collapse>
        </Card>
      </Box>
    </ThemeProvider>
  );
};

export default PriceControlScreenSearch;
