import { Table, TableBody, TableContainer } from "@mui/material";
import React from "react";
import FinanceBakiyeTableBody from "./financeBakiye/FinanceBakiyeTableBody";
import FinanceBakiyeTableHead from "./financeBakiye/FinanceBakiyeTableHead";
import { useRecoilValue } from "recoil";
import { financeBakiyeDataState } from "../../../atoms/finance";

const FinanceBakiye = React.memo(() => {
  const financeBakiyeData = useRecoilValue(financeBakiyeDataState);

  return (
    <TableContainer
      sx={{
        backgroundColor: "var(--lighterBg)",
        borderRadius: "var(--inputBorderRadius)",
        boxShadow: "var(--shadow)",
      }}
    >
      <Table>
        <FinanceBakiyeTableHead />
        <TableBody>
          {financeBakiyeData?.map((item, key) => (
            <FinanceBakiyeTableBody key={key} item={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default FinanceBakiye;
