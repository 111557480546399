import { useRecoilValue } from "recoil";
import { financeBakiyeIadeDataState } from "../../../../atoms/finance";
import { Table, TableBody, TableContainer } from "@mui/material";
import FinanceBakiyeIadeTableBody from "./FinanceBakiyeIadeTableBody";
import FinanceBakiyeIadeTableHead from "./FinanceBakiyeIadeTableHead";

const FinanceBakiyeIadeTable = () => {
  const financeBakiyeIadeData = useRecoilValue(financeBakiyeIadeDataState);

  return (
    <TableContainer
      sx={{
        backgroundColor: "var(--lighterBg)",
        boxShadow: "var(--shadow)",
        borderRadius: "var(--wrapperBorderRadius)",
      }}
    >
      <Table>
        <FinanceBakiyeIadeTableHead />
        <TableBody>
          {financeBakiyeIadeData?.map((item, key) => (
            <FinanceBakiyeIadeTableBody key={key} item={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FinanceBakiyeIadeTable;
