import { Box, TextField, Typography } from "@mui/material";

const MemberInformationEditAddress = ({ member }) => {
  return (
    <Box
      sx={{
        boxShadow: "var(--shadow)",
        padding: "20px",
        backgroundColor: "var(--lighterBg)",
        borderRadius: "var(--inputBorderRadius)",
        display: "grid",
        gap: "var(--gap2x)",
      }}
    >
      <Typography color={"var(--priceText)"} variant="h5">
        Adres Bilgileri
      </Typography>
      <Box sx={{ display: "flex", gap: "var(--gap2x)" }}>
        <TextField size="small" fullWidth label="Adres Satırı" />
        <TextField size="small" fullWidth label="Adres Satırı 2" />
        <TextField size="small" fullWidth label="Posta Kodu" />
        <TextField size="small" fullWidth label="Semt/Eyalet" />
        <TextField size="small" fullWidth label="Şehir" />
        <TextField size="small" fullWidth label="İlçe" />
        <TextField size="small" fullWidth label="Ülke Alan Kodu" />
        <TextField size="small" fullWidth label="Telefon" />
      </Box>
    </Box>
  );
};

export default MemberInformationEditAddress;
