import React, { useEffect, useRef } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { useState } from "react";
import {
  PriceControlScreenDataState,
  PriceControlScreenIDState,
} from "../../../../atoms/priceControl";
import { Table, TableBody, TableContainer, Box, Switch } from "@mui/material";
import PriceControlScreenTableBody from "./PriceControlScreenTableBody";
import PriceControlScreenTableHead from "./PriceControlScreenTableHead";

const PriceControlScreenTable = () => {
  const PriceControlScreenData = useRecoilValue(PriceControlScreenDataState);
  const [priceControlScreenIDState, setPriceControlScreenIDState] =
    useRecoilState(PriceControlScreenIDState);
  const [selectedRows, setSelectedRows] = useState([]);
  const wrapper1Ref = useRef(null);
  const wrapper2Ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (wrapper1Ref.current && wrapper2Ref.current) {
        wrapper2Ref.current.scrollLeft = wrapper1Ref.current.scrollLeft;
      }
    };

    if (wrapper1Ref.current && wrapper2Ref.current) {
      wrapper1Ref.current.addEventListener("scroll", handleScroll);
      wrapper2Ref.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (wrapper1Ref.current && wrapper2Ref.current) {
        wrapper1Ref.current.removeEventListener("scroll", handleScroll);
        wrapper2Ref.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleSelectAllClick = () => {
    const allSelected = selectedRows.length === PriceControlScreenData.length;

    const newSelectedRows = allSelected
      ? []
      : PriceControlScreenData.map((item) => item.id);

    setPriceControlScreenIDState(newSelectedRows);
    setSelectedRows(newSelectedRows);
  };

  const handleCheckboxClick = (itemId) => {
    if (priceControlScreenIDState.includes(itemId)) {
      setPriceControlScreenIDState(
        priceControlScreenIDState.filter((id) => id !== itemId)
      );
    } else {
      setPriceControlScreenIDState([...priceControlScreenIDState, itemId]);
    }
  };

  const [group1Checked, setGroup1Checked] = useState(false);
  const [group2Checked, setGroup2Checked] = useState(false);
  const [group3Checked, setGroup3Checked] = useState(false);
  const [group4Checked, setGroup4Checked] = useState(false);
  const [group5Checked, setGroup5Checked] = useState(false);
  const [group6Checked, setGroup6Checked] = useState(false);

  const handleGroup1Change = () => {
    setGroup1Checked(!group1Checked);
  };

  const handleGroup2Change = () => {
    setGroup2Checked(!group2Checked);
  };

  const handleGroup3Change = () => {
    setGroup3Checked(!group3Checked);
  };

  const handleGroup4Change = () => {
    setGroup4Checked(!group4Checked);
  };

  const handleGroup5Change = () => {
    setGroup5Checked(!group5Checked);
  };

  const handleGroup6Change = () => {
    setGroup6Checked(!group6Checked);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          padding: "0px 20px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "50%",
            textAlign: "center",
          }}
        >
          <p>Seçilenler: {priceControlScreenIDState.length}</p>
        </Box>
        <Box
          sx={{
            width: "50%",
            textAlign: "center",
          }}
        >
          <p>Toplam Sayı: {PriceControlScreenData.length}</p>
        </Box>
        <Box
          sx={{
            width: "50%",
            textAlign: "center",
          }}
        >
          <p>Müşteri ID - Müşteri Adı</p>
          <Switch checked={group1Checked} onChange={handleGroup1Change} />
        </Box>
        <Box
          sx={{
            width: "50%",
            textAlign: "center",
          }}
        >
          <p>Ted İadeleri - Sorun</p>
          <Switch checked={group2Checked} onChange={handleGroup2Change} />
        </Box>
        <Box
          sx={{
            width: "50%",
            textAlign: "center",
          }}
        >
          <p>Ref No - Dom No</p>
          <Switch checked={group3Checked} onChange={handleGroup3Change} />
        </Box>
        <Box
          sx={{
            width: "50%",
            textAlign: "center",
          }}
        >
          <p>Müşteri Ölçüleri</p>
          <Switch checked={group5Checked} onChange={handleGroup5Change} />
        </Box>
        <Box
          sx={{
            width: "50%",
            textAlign: "center",
          }}
        >
          <p>iyziTeam Ölçüleri</p>
          <Switch checked={group6Checked} onChange={handleGroup6Change} />
        </Box>
        <Box
          sx={{
            width: "50%",
            textAlign: "center",
          }}
        >
          <p>Ölçü Beyanları - Tarih</p>
          <Switch checked={group4Checked} onChange={handleGroup4Change} />
        </Box>
      </Box>

      <Table
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <TableBody
          sx={{
            width: "100%",
            overflowX: "scroll",
            // height always show all devices
            height: "calc(100vh - 450px)",
            position: "relative",
          }}
        >
          <PriceControlScreenTableHead
            onSelectAllClick={handleSelectAllClick}
            allSelected={selectedRows}
            group1Checked={group1Checked}
            group2Checked={group2Checked}
            group3Checked={group3Checked}
            group4Checked={group4Checked}
            group5Checked={group5Checked}
            group6Checked={group6Checked}
            selectedRows={selectedRows}
            
          />
          {PriceControlScreenData?.map((item, key) => (
            <PriceControlScreenTableBody
              key={item.id}
              item={item}
              onCheckboxClick={handleCheckboxClick}
              selectedRows={priceControlScreenIDState}
              group1Checked={group1Checked}
              group2Checked={group2Checked}
              group3Checked={group3Checked}
              group4Checked={group4Checked}
              group5Checked={group5Checked}
              group6Checked={group6Checked}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default PriceControlScreenTable;
