import React, { useEffect, useCallback, useState } from "react";
import { Box, Button, Table, TableHead, TableBody, TableCell, TableRow, TableContainer, Modal } from "@mui/material";
import Checkbox from "@mui/material/Checkbox"; // Import Checkbox
import { useSetRecoilState } from "recoil";
import { notificationOpenState } from "../../../atoms/viewPadding";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import { useRecoilState, useRecoilValue } from "recoil";
import NotificationBar from "../../components/sidebar/NotificationBar";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import NavBar from "../../components/sidebar/NavBar";
import WrapperStyle from "../../../utils/WrapperStyle";
import { drawerOpenState } from "../../../atoms/viewPadding";
import { useCookies } from "react-cookie";
import { useDropzone } from "react-dropzone";
import uploadIcon from "../../../img/icons/upload_icon.svg";
import DowloadPdfView from "../../../components/dowloadPdfView/DowloadPdfView";
import Errors from "../../../utils/Errors";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import axios from "../../../api/axios";
import DownloadBase64Excel from "../../../utils/DownloadBase64Excel";

const AwbTransfer = () => {
  DynamicTitle("Takip Numarası Aktarma - Admin");
  const setNotificationOpen = useSetRecoilState(notificationOpenState);

  const drawerOpen = useRecoilValue(drawerOpenState);

  const navUnder = {
    paddingLeft: drawerOpen ? "264px" : "64px",
    transition: "all 150ms linear",
    backgroundColor: "var(--lighterBg)",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  };

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
  const [parsedExcel, setParsedExcel] = useState([]);
  const [parsedUspsExcel, setParsedUspsExcel] = useState([]);
  const [sendFiles, setSendFiles] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disabledUpdateBtn, setDisabledUpdateBtn] = useState(false);
  const [responseData, setResponseData] = useState([]);
  console.log(responseData)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUspsModalOpen, setIsUspsModalOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const handleDrop = useCallback((acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      id: Math.random().toString(),
      maxSize: 2048,
    }));
    setSendFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }, []);

  const handleSetState = useCallback((id) => {
    setSendFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
  });

  const parseExcelNormal = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post("document/read-excel", formData);
      setParsedExcel(response.data.data);
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response?.data?.message);
    }
  };

  const parseUspsExcel = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post("document/read-usps-excel", formData);
      setParsedUspsExcel(response.data.data);
      toast.success("USPS Excel başarıyla yüklendi.");
    } catch (error) {
      toast.error(error.response?.data?.message || "USPS Excel yüklenirken bir hata oluştu.");
    }
  };

  const parcelRefs = parsedExcel.map((item) => item[0]);
  const parcelRefsUsps = parsedUspsExcel.map((item) => item?.parcel_ref_no);
  const trackingAWBs = parsedExcel.map((item) => item[1]);
  const trackingAWBsUsps = parsedUspsExcel.map((item) => item?.new_parcel_tracking_awb);

  const trackingAWBsResponseFail = responseData.fail?.map((item) => item.parcel_ref_no);
  const trackingAWBsResponseSuccess = responseData.success?.map((item) => item.parcel_ref_no);

  const parcelsToUpdate = parcelRefs.map((parcel_ref_no, index) => ({
    parcel_ref_no,
    new_tracking_awb: trackingAWBs[index],
    update_status: "1",
    new_status: 3.01
  }));

  const parcelsToUpdateExcel = parcelRefsUsps.map((parcel_ref_no, index) => ({
    parcel_ref_no,
    new_tracking_awb: trackingAWBsUsps[index],
    update_status: "1",
    new_status: 3.01
  }));

  const handleUpdateAwb = async () => {
    try {
      const response = await axios.post("parcel/update-tracking-awb-multiple", {
        parcels: parsedExcel.length > 0 ? parcelsToUpdate : parcelsToUpdateExcel
      }, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      // USPS için ayrı bir state kullanabilirsiniz, ben burada aynı state'i kullanıyorum
      toast.success(response.data.message);
      setResponseData(response.data.data);
      setDisabledUpdateBtn(true);
    } catch (error) {
      toast.error(error.response?.data?.message);
      setDisabledUpdateBtn(false);
    }
  };

  useEffect(() => {
    setNotificationOpen(true);
  }, [setNotificationOpen]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleUspsModalOpen = () => {
    setIsUspsModalOpen(true);
  };

  const handleUspsModalClose = () => {
    setIsUspsModalOpen(false);
  };

  const handleResetTables = () => {
    setParsedExcel([]);
    setParsedUspsExcel([]);
    setSelectedRows([]);
    setDisabledUpdateBtn(false);
    setSelectAll(false);
    setResponseData([]);
  };

  const handleRowSelect = (event, row) => {
    if (event.target.checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, row]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((selectedRow) => selectedRow !== row)
      );
    }
  };

  // Function to handle select all
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(parsedExcel);
    }
    setSelectAll(!selectAll);
  };

  const handleSelectAllUsps = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(parsedUspsExcel);
    }
    setSelectAll(!selectAll);
  };

  return (
    <section className="dashboard">
      <NavBar />
      <NotificationBar />
      <ToastContainer />
      <Box className="nav-under" style={navUnder}>
        <div>
          <ReceiptLongOutlinedIcon /> Takip Numarası Aktarma
        </div>
      </Box>
      <Box style={WrapperStyle()} className="dashboard-wrapper">
        <Modal open={isUspsModalOpen} onClose={handleUspsModalClose}>
          <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", boxShadow: 24, p: 4, width: "50%", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: "10px" }}>
            <div className="dropzone-area" {...getRootProps()}>
              <input {...getInputProps()} />
              <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }} className="dropzone-area-text">
                <img src={uploadIcon} alt="Upload Icon" />
                <span className="dropzone-area-text-headline">
                  USPS Excel dosyasını sürükleyip bırakın veya{" "}
                </span>
                <div>
                  <span style={{ color: "var(--info)", cursor: "pointer" }}>Gözat</span>
                </div>
                <span className="dropzone-area-text-sub">
                  Desteklenen Format: XLSX (Excel)
                </span>
              </div>
            </div>

            <DowloadPdfView sendFiles={sendFiles} handleSetState={handleSetState} />
            {sendFiles.length > 0 && (
              <Button
                sx={{
                  marginTop: "1rem",
                }}
                variant="contained"
                onClick={() => {
                  parseUspsExcel(sendFiles[0].file);
                  handleUspsModalClose();
                }}
              >
                Gönder
              </Button>
            )}
          </Box>
        </Modal>
        <Modal open={isModalOpen} onClose={handleModalClose}>
          <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", boxShadow: 24, p: 4, width: "50%", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: "10px" }}>
            <div className="dropzone-area" {...getRootProps()}>
              <input {...getInputProps()} />
              <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }} className="dropzone-area-text">
                <img src={uploadIcon} alt="Upload Icon" />
                <span className="dropzone-area-text-headline">
                  Dosyayı sürükleyip bırakın veya{" "}
                </span>
                <div>
                  <span style={{ color: "var(--info)", cursor: "pointer" }}>Gözat</span>
                </div>
                <span className="dropzone-area-text-sub">
                  Desteklenen Format: XLSX (Excel)
                </span>
              </div>
            </div>

            <DowloadPdfView sendFiles={sendFiles} handleSetState={handleSetState} />
            {sendFiles.length > 0 && (
              <Button
                sx={{
                  marginTop: "1rem",
                }}
                variant="contained"
                onClick={() => {
                  parseExcelNormal(sendFiles[0].file);
                  handleModalClose();
                }}
              >
                Gönder
              </Button>
            )}
          </Box>
        </Modal>
        <Box sx={{
          marginBottom: "10px",
          display: "flex",
          gap: "10px"
        }}>
          <Button sx={{ ":disabled": { opacity: 0.3 } }} disabled={parsedUspsExcel.length > 0} className="iyzi-button" onClick={handleModalOpen}>Excel Oku</Button>
          <Button sx={{ ":disabled": { opacity: 0.3 } }} disabled={parsedExcel.length > 0} className="iyzi-button" onClick={handleUspsModalOpen}>USPS Excel Oku</Button>
          <Button className="iyzi-button" onClick={handleResetTables}>Excel Tabloları Sıfırla</Button>
        </Box>
        {parsedExcel?.length > 0 && (
          <Box>
            <Box sx={{
              marginTop: "20px",
              marginBottom: "20px",
              marginLeft: "10px"
            }}>
              Excel
            </Box>
            <TableContainer
              sx={{
                backgroundColor: "var(--lighterBg)",
                boxShadow: "var(--shadow)",
                borderRadius: "var(--wrapperBorderRadius)",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        onChange={handleSelectAll}
                        checked={selectAll}
                      /> Tümünü Seç
                    </TableCell> {/* Checkbox to select all */}
                    <TableCell>Ref No</TableCell>
                    <TableCell>Güncellenecek Awb Numarası</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parsedExcel?.map((item, key) => (
                    <TableRow key={key} item={item}>
                      <TableCell>
                        <Checkbox
                          onChange={(event) => handleRowSelect(event, item)}
                          checked={selectedRows.includes(item)}
                        />
                      </TableCell>
                      <TableCell>{item[0]}</TableCell>
                      <TableCell>{item[1]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )
        }

        {parsedUspsExcel?.length > 0 && (
          <Box>
            <Box sx={{
              marginTop: "20px",
              marginBottom: "20px",
              marginLeft: "10px"
            }}>
              USPS Excel
            </Box>
            <TableContainer
              sx={{
                backgroundColor: "var(--lighterBg)",
                boxShadow: "var(--shadow)",
                borderRadius: "var(--wrapperBorderRadius)",

                height: "750px"

              }}
            >
              <Table>
                <TableHead sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "white",
                }}>
                  <TableRow>
                    <TableCell sx={{

                      fontWeight: "bold"
                    }}>
                      <Checkbox
                        onChange={handleSelectAllUsps}
                        checked={selectAll}
                      /> Tümünü Seç
                    </TableCell> {/* Checkbox to select all */}
                    <TableCell sx={{

                      fontWeight: "bold"
                    }}>Bulunma Durumu</TableCell>
                    <TableCell sx={{

                      fontWeight: "bold"
                    }}>Ref No</TableCell>
                    <TableCell sx={{

                      fontWeight: "bold"
                    }}>Güncel AWB</TableCell>
                    <TableCell sx={{

                      fontWeight: "bold"
                    }}>Güncellenecek AWB</TableCell>
                    <TableCell sx={{

                      fontWeight: "bold"
                    }}>Güncel Durum</TableCell>
                    <TableCell sx={{

                      fontWeight: "bold"
                    }}>Güncel Durum Adı</TableCell>
                    <TableCell sx={{

                      fontWeight: "bold"
                    }}>Güncellenecek Durum</TableCell>
                    <TableCell sx={{

                      fontWeight: "bold"
                    }}>Güncellenecek Durum Adı</TableCell>
                    {/* Add other table headers */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parsedUspsExcel?.map((item, key) => (
                    <TableRow key={key} item={item}>
                      <TableCell>
                        <Checkbox
                          onChange={(event) => handleRowSelect(event, item)}
                          checked={selectedRows.includes(item)}
                        />
                      </TableCell>
                      <TableCell sx={{
                        textAlign: "center",

                      }}> <p style={{
                        color: item.parcel_found === true ? "var(--greenBrand)" : "var(--redBrand)",
                        backgroundColor: item.parcel_found === true ? "var(--pastelGreen)" : "var(--pastelRed)",
                        borderRadius: "10px",
                      }}>
                          {item.parcel_found === true ? "Bulundu" : "Bulunamadı"}
                        </p></TableCell>
                      <TableCell sx={{
                        backgroundColor: trackingAWBsResponseFail?.includes(item.parcel_ref_no) ? "red" : "",
                        fontSize: "14px",
                        fontWeight: "bold"
                      }} >{item.parcel_ref_no}</TableCell>
                      <TableCell >{item.tracking_awb}</TableCell>
                      <TableCell sx={{
                        backgroundColor: "#A3D1FF50"

                      }}>{item.new_parcel_tracking_awb}</TableCell>
                      <TableCell sx={{


                      }}>{item.parcel_status}</TableCell>
                      <TableCell sx={{


                      }}>{item.parcel_status_name}</TableCell>
                      <TableCell sx={{
                        backgroundColor: "#A3D1FF50"

                      }}>{item.new_parcel_status}</TableCell>
                      <TableCell sx={{
                        backgroundColor: "#A3D1FF50"

                      }}>{item.new_parcel_status_name}</TableCell>
                      {/* Add other table cells */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )
        }

        <Box sx={{
          marginTop: "10px"
        }}>
          {
            selectedRows.length > 0 && (
              <Button sx={{ ":disabled": { opacity: 0.3 } }} disabled={disabledUpdateBtn} className="iyzi-button-blue" onClick={handleUpdateAwb}>Seçilenleri Güncelle</Button>
            )
          }
        </Box>
      </Box>
    </section>
  );
};

export default AwbTransfer;
