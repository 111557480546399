import "../../../style/myWallet/MyWallet.css";
import { Button, createTheme, Pagination, ThemeProvider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useInView, animated } from "@react-spring/web";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import axios from "../../../api/axios";
import { drawerOpenState } from "../../../atoms/viewPadding";
import NavBar from "../sidebar/NavBar";
import { ToastContainer } from "react-toastify";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import WrapperStyle from "../../../utils/WrapperStyle";
import DataArrayIcon from "@mui/icons-material/DataArray";
import LogSearch from "./LogSearch";
import { logCurrentPageState, logDataState, logTotalPageState } from "../../../atoms/log";
import LogTable from "./LogTable";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const Log = () => {
  DynamicTitle("Log Bilgileri - Admin");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const drawerOpen = useRecoilValue(drawerOpenState);

  const [loading, setLoading] = useState(false);

  const navUnder = {
    paddingLeft: drawerOpen ? "264px" : 0,
    transition: "all 150ms linear",
    backgroundColor: "var(--lighterBg)",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  };

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const setlogData = useSetRecoilState(logDataState);
  const [logTotalPage, setlogTotalPage] = useRecoilState(logTotalPageState);
  const [logCurrentPage, setlogCurrentPage] = useRecoilState(logCurrentPageState);

  const getData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`log/check-price-logs`, {
        params: {
          limit: 500,
          page: 1,
          order_direction: "DESC",
        },
      })
      .then((res) => {
        setlogData(res.data.data);
        setlogTotalPage(res.data.totalPages);

        setlogCurrentPage(res.data.currentPage);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [setlogData, setlogTotalPage, setlogCurrentPage]);

  // const dataInterval = setInterval(getData, 30000);

  const handleBalancePageChange = useCallback(
    async (e, page) => {
      setLoading(true);
      await axios
        .get(`log/check-price-logs`, {
          params: {
            limit: 500,
            page,
            order_direction: "DESC",
          },
        })
        .then((res) => {
          setlogData(res.data.data);
          setlogTotalPage(res.data.totalPages);
          setlogCurrentPage(res.data.currentPage);
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    },
    [setlogData, setlogTotalPage, setlogCurrentPage]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <ThemeProvider theme={theme}>
      <section className="my-wallet" style={{ paddingLeft: "64px" }}>
        <NavBar />
        <ToastContainer />
        <div className="nav-under" style={navUnder}>
          <div>
            <DataArrayIcon /> Log
          </div>
        </div>
        <div className="my-wallet-wrapper" style={WrapperStyle()}>
          <animated.div ref={ref} style={springs}>
            <div className="my-wallet-container">
              <Button onClick={getData} className="iyzi-button-blue">
                Log Tablosunu Güncelle
              </Button>
              {loading ? (
                <div
                  style={{
                    height: "500px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img className="loading-logo" src={miniLogo} alt="" />
                </div>
              ) : (
                <>
                  <LogSearch />
                  {logTotalPage > 1 && (
                    <Pagination
                      showFirstButton
                      showLastButton
                      size="small"
                      count={logTotalPage}
                      page={logCurrentPage}
                      onChange={handleBalancePageChange}
                    />
                  )}
                  <LogTable />
                  {logTotalPage > 1 && (
                    <Pagination
                      showFirstButton
                      showLastButton
                      size="small"
                      count={logTotalPage}
                      page={logCurrentPage}
                      onChange={handleBalancePageChange}
                    />
                  )}
                </>
              )}
            </div>
          </animated.div>
        </div>
      </section>
    </ThemeProvider>
  );
};

export default Log;
