import { useRecoilState } from "recoil";
import { historyOfChangeModalState } from "../../../../atoms/myShipmentsAdmin";
import { CircularProgress, Modal, ThemeProvider, createTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import axios from "../../../../api/axios";
import AuditHistory from "../../../../utils/AuditHistory";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Errors from "../../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px",
          overflow: "auto",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const HistoryOfChange = () => {
  const [historyOfChangeModal, setHistoryOfChangeModal] = useRecoilState(historyOfChangeModalState);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getData = useCallback(() => {
    axios
      .get(`parcel/audit-history/${historyOfChangeModal?.data?.id}`)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [historyOfChangeModal]);

  const handleClose = useCallback(() => {
    setData([]);
    setHistoryOfChangeModal({ status: false, data: {} });
  }, [setHistoryOfChangeModal]);

  useEffect(() => {
    if (historyOfChangeModal.status) {
      getData();
    }
  }, [historyOfChangeModal.status, getData]);

  return (
    <ThemeProvider theme={theme}>
      <Modal open={historyOfChangeModal.status} onClose={handleClose}>
        <div
          style={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            padding: "20px",
            outline: 0,
            display: "grid",
            gap: "var(--gap)",
            width: "100%",
            maxWidth: "1100px",
            margin: "auto",
            position: "relative",
          }}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              cursor: "pointer",
              color: "var(--priceText)",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleClose}
          />
          {loading ? <CircularProgress /> : <AuditHistory data={data} />}
        </div>
      </Modal>
    </ThemeProvider>
  );
};

export default HistoryOfChange;
