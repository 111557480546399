import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  ffdBase64State,
  ffdCheckDomState,
  ffdCheckWhereState,
  ffdDataState,
} from "../../../../atoms/operation";
import OperationFreightFrontDeskResponse from "./OperationFreightFrontDeskResponse";
import { useNavigate } from "react-router-dom";
import Errors from "../../../../utils/Errors";

const OperationFreightFrontDeskDom = () => {
  const navigate = useNavigate();

  const [domAwb, setDomAwb] = useState("");
  const [domSupplier, setDomSupplier] = useState("");
  const [awb, setAwb] = useState("");
  const [awbError, setAwbError] = useState(false);
  const [awbErrorText, setAwbErrorText] = useState("");

  const [ffdData, setFfdData] = useRecoilState(ffdDataState);
  const [, setFfdBase64] = useRecoilState(ffdBase64State);

  const ffdCheckWhere = useRecoilValue(ffdCheckWhereState);
  const ffdCheckDom = useRecoilValue(ffdCheckDomState);

  const handleChangeDomAwb = (e) => {
    const value = e.target.value.toUpperCase();
    setDomAwb(value);
  };

  const handleChangeDomSupplier = (e) => {
    const value = e.target.value;
    setDomSupplier(value);
  };

  const handleAwb = (e) => {
    const { value } = e.target;
    const pattern = /^[a-zA-ZğüşıöçĞÜŞİÖÇ0-9]*$/; // Sadece harf ve rakam kabul eder

    if (pattern.test(value)) {
      setAwb(value.toUpperCase());
      setAwbError(false);
    }
  };

  const handleAwbBlur = (e) => {
    const { value } = e.target;
    const pattern = /^[a-zA-Z]{2}[0-9]{8}[a-zA-Z]{2}$/;

    if (!pattern.test(value) && awb.length !== 0) {
      setAwbError(true);
      setAwbErrorText("");
      setAwbErrorText("Girilen değer, belirlenen formata uymamaktadır!");
    }
  };

  const handleClear = () => {
    setFfdData({});
    setFfdBase64("");
  };

  const handleClearAwb = () => {
    setAwb("");
  };

  const handleClearDom = () => {
    setDomAwb("");
  };

  const handleSubmitDom = (e) => {
    e.preventDefault();

    const data =
      ffdCheckWhere === "dom"
        ? {
            parcel_ref_no: awb,
            from: "front_desk",
            delivery_type: "domestic",
            domestic_supplier: domSupplier,
            domestic_awb: domAwb,
          }
        : {
            parcel_ref_no: awb,
            from: "front_desk",
            delivery_type: "non_dm_frontdesk",
          };

    axios
      .post(`parcel/operation/set-received`, data)
      .then((response) => {
        setFfdData(response.data.data);
        setFfdBase64(response.data.base64);
      })
      .catch((error) => Errors(error));
  };

  return (
    <>
      <form onSubmit={handleSubmitDom} className="operation-feight-front-desk-dom">
        {ffdCheckDom && (
          <div className="operation-feight-front-desk-dom-input">
            <TextField
              fullWidth
              required
              label="Domestik AWB No"
              autoComplete="off"
              value={domAwb}
              onChange={handleChangeDomAwb}
              onKeyDown={(e) => e.key === "Escape" && handleClearDom()}
              size="small"
            />
            <FormControl size="small" required fullWidth>
              <InputLabel id="dom-awb-select-label">Domestik Tedarikçi</InputLabel>
              <Select
                labelId="dom-awb-select-label"
                id="dom-awb-select"
                value={domSupplier}
                label="Domestik Tedarikçi"
                onChange={handleChangeDomSupplier}
                size="small"
              >
                <MenuItem value="">
                  <em>Temizle</em>
                </MenuItem>
                <MenuItem value={"Aras"}>Aras Kargo</MenuItem>
                <MenuItem value={"Surat"}>Sürat Kargo</MenuItem>
                <MenuItem value={"Ups"}>UPS Kargo</MenuItem>
                <MenuItem value={"Diğer"}>Diğer</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
        <div className="operation-feight-front-desk-dom-input">
          <TextField
            required
            fullWidth
            size="small"
            label="Lütfen AWB Giriniz"
            autoComplete="off"
            value={awb}
            onChange={handleAwb}
            onBlur={handleAwbBlur}
            error={awbError}
            helperText={awbError && awbErrorText}
            inputProps={{ maxLength: 12 }}
            onKeyDown={(e) => e.key === "Escape" && handleClearAwb()}
          />
          <Button sx={{ maxHeight: "37px" }} type="submit" className="iyzi-button">
            Ekle
          </Button>
        </div>
      </form>
      <Button onClick={handleClear}>Aşağıyı Temizle (İSTEĞE BAĞLI)</Button>
      {Object.keys(ffdData).length !== 0 && <OperationFreightFrontDeskResponse />}
    </>
  );
};

export default OperationFreightFrontDeskDom;
