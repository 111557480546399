import { useState, useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import TextField from "@mui/material/TextField";
import { ffdPakCostState } from "../../../../atoms/operation";
function OperationPak({ items, i, disable }) {
  const [pakCost, setPakCost] = useRecoilState(ffdPakCostState);
  const [totalCw, setTotalCw] = useState();
  console.log("pakCost",pakCost)
  const roundWeight = (weight) => {
    if (weight <= 0.01) {
      return 0.01;
    } else if (weight < 10) {
      const integerPart = Math.floor(weight);
      const decimalPart = weight - integerPart;
      if (decimalPart > 0 && decimalPart <= 0.5) {
        return integerPart + 0.5;
      } else {
        return Math.ceil(weight * 2) / 2; // Bu, 0.5 adımlarla yukarı doğru yuvarlar
      }
    } else {
      return Math.ceil(weight);
    }
  };
  const handleInputChange = useCallback((e, i) => {
    const { name, value } = e.target;

    setPakCost((prevpaks) => {
      const updatedpaks = [...prevpaks];
      let fvalue = value.replace(/,/g, ".");

      if (name === "qty") {
        updatedpaks[i] = {
          ...updatedpaks[i],
          qty: fvalue.replace(/[^0-9]/g, ""),
        };
      } else {
        const value = fvalue.replace(/[^0-9.]|(?<=\..*)\.|^0{2,}(?=[\.])/g, "");
        const regex = /^\d+(\.\d{0,2})?$/;

        if (value.startsWith(".") || (!regex.test(value) && value !== "")) {
          return prevpaks;
        } else {
          if (name === "gross") {
            let newValue = value.replace(/^0{2,}/, "0");
            if (Number(fvalue) > 300) {
              newValue = "300";
            }
            updatedpaks[i] = {
              ...updatedpaks[i],
              gross: newValue,
            };
          } else {
            updatedpaks[i] = {
              ...updatedpaks[i],
              [name]: value,
            };
          }
        }
      }

      const calculatedCw = updatedpaks?.map((pak) =>
        ((Number(pak.width) *
          Number(pak.length) *
          Number(pak.height)) /
          5000) *
          Number(pak.qty) >
        Number(pak.gross) * Number(pak.qty)
          ? ((Number(pak.width) *
              Number(pak.length) *
              Number(pak.height)) /
              5000) *
            Number(pak.qty)
          : Number(pak.gross) * Number(pak.qty)
      );

      setTotalCw(
        roundWeight(calculatedCw?.reduce((acc, item) => acc + item, 0))
      );
      return updatedpaks;
    });
  }, [setPakCost]);

  useEffect(() => {
    if (i > 0) {
      // Request sonucunda gelen veri "responseData" olarak adlandırılmıştır.
      const transformedData = items?.map((item, index) => ({
        //width: item.width,
        width: "",
        // length: item.length,
        length: "",
        // height: item.height,
        height: "",
        // gross: item.gross,
        gross: "",
        // qty: item.qty,
        qty: "",
        id: 0, // Bu kısmı, unique bir id almak için item.id olarak da ayarlayabilirsiniz.
      }));
      // Dönüştürülmüş veriyi "paks" state'ine ayarlama
      setPakCost(transformedData);
      const calculatedCw = transformedData?.map((pak) =>
        ((Number(pak.width) *
          Number(pak.length) *
          Number(pak.height)) /
          5000) *
          Number(pak.qty) >
        Number(pak.gross) * Number(pak.qty)
          ? ((Number(pak.width) *
              Number(pak.length) *
              Number(pak.height)) /
              5000) *
            Number(pak.qty)
          : Number(pak.gross) * Number(pak.qty)
      );

      setTotalCw(calculatedCw?.reduce((acc, item) => acc + item, 0));
    }
  }, [i, items, setPakCost]);

  return (
    <div
    style={{ display: "flex", flexDirection: "column", gap: "var(--gap)" ,opacity: disable ? "0.5":"1" }}
  >
    {i>0 && pakCost.map((pak, index) => (
      <form
        onSubmit={(e) => e.preventDefault()} // Formun submitini engelliyoruz
        className="operation-cargo-dom-resp-weight-iyziteam-pak"
        key={index}
      >
        <div className="operation-cargo-dom-resp-weight-iyziteam-item">
          <div className="little-title">Genişlik</div>
          <TextField
            size="small"
            name="width"
            value={pak.width}
            disabled={disable}
            required
            onChange={(e) => handleInputChange(e, index)}
            sx={{
              backgroundColor: "#e9ecef",
              width: "50px",
              height: "20px",
              fontSize: "10px",
              fontWeight: 700,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0",
              "& fieldset": { border: "none" },
            }}
          />
        </div>
        <div className="operation-cargo-dom-resp-weight-iyziteam-item">
          <div className="little-title">Uzunluk</div>
          <TextField
            size="small"
            name="length"
            required
            value={pak.length}
            disabled={disable}
            onChange={(e) => handleInputChange(e, index)}
            sx={{
              backgroundColor: "#e9ecef",
              width: "50px",
              height: "20px",
              fontSize: "10px",
              fontWeight: 700,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0",
              "& fieldset": { border: "none" },
            }}
          />
        </div>
        <div className="operation-cargo-dom-resp-weight-iyziteam-item">
          <div className="little-title">Yükseklik</div>
          <TextField
            size="small"
            name="height"
            value={pak.height}
            disabled={disable}
            required
            onChange={(e) => handleInputChange(e, index)}
            sx={{
              backgroundColor: "#e9ecef",
              width: "50px",
              height: "20px",
              fontSize: "10px",
              fontWeight: 700,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0",
              "& fieldset": { border: "none" },
            }}
          />
        </div>
        <div className="operation-cargo-dom-resp-weight-iyziteam-item">
          <div className="little-title">Kap</div>
          <TextField
            size="small"
            value={pak.qty}
            required
            disabled={disable}
            onChange={(e) => handleInputChange(e, index)}
            name="qty"
            sx={{
              backgroundColor: "#e9ecef",
              width: "50px",
              height: "20px",
              fontSize: "10px",
              fontWeight: 700,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0",
              "& fieldset": { border: "none" },
            }}
          />
        </div>
        <div className="operation-cargo-dom-resp-weight-iyziteam-item">
          <div className="little-title">Kilogram</div>
          <TextField
            size="small"
            name="gross"
            disabled={disable}
            value={pak.gross}
            required
            onChange={(e) => handleInputChange(e, index)}
            sx={{
              backgroundColor: "#e9ecef",
              width: "50px",
              height: "20px",
              fontSize: "10px",
              fontWeight: 700,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0",
              "& fieldset": { border: "none" },
            }}
          />
        </div>

        <div className="operation-cargo-dom-resp-weight-iyziteam-item">
          <div className="little-title">
            <b style={{ fontSize: "inherit" }}>Brüt Ağırlık :</b>&nbsp;
          </div>
          <div
            style={{
              backgroundColor: "#e9ecef",
              width: "50px",
              height: "20px",
              fontSize: "10px",
              fontWeight: 700,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0",
            }}
          >
            {Number(pak.gross * pak.qty).toFixed(2)} KG
          </div>
        </div>
        <div className="operation-cargo-dom-resp-weight-iyziteam-item">
          <div className="little-title">Hacim / Desi</div>
          <div
            style={{
              backgroundColor: "#e9ecef",
              width: "50px",
              height: "20px",
              fontSize: "10px",
              fontWeight: 700,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0",
            }}
          >
            {Number(
              ((pak.width * pak.length * pak.height) / 5000) * pak.qty
            ).toFixed(2)}
            KG
          </div>
        </div>
      </form>
    ))}
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "#6a707e",
        gap: "5px",
      }}
      className="operation-cargo-dom-resp-wight-shipment-bottom-side"
    >
      <div style={{ fontSize: "7px" }} className="little-title">
        Toplam Ücret Ağırlığı
      </div>
      <div
        style={{
          backgroundColor: "#e9ecef",
          width: "124px",
          height: "20px",
          fontSize: "10px",
          fontWeight: 700,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="operation-cargo-dom-resp-wight-shipment-item-bottom"
      >
        {totalCw} kg
      </div>
    </div>
  </div>
  );
}

export default OperationPak;
