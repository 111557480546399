import "../../../style/operation/Operation.css";
import { Box, Checkbox, FormControlLabel, ThemeProvider, createTheme } from "@mui/material";
import { useCallback } from "react";
import { useCookies } from "react-cookie";
import { useInView, animated } from "@react-spring/web";
import axios from "../../../api/axios";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import NavBar from "../sidebar/NavBar";
import { ToastContainer } from "react-toastify";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import WrapperStyle from "../../../utils/WrapperStyle";
import OperationFreightFrontDeskDom from "./operationFreightFrontDesk/OperationFreightFrontDeskDom";
import { useRecoilState } from "recoil";
import {
  ffdCheckCourierState,
  ffdCheckDomState,
  ffdCheckHandState,
  ffdCheckWhereState,
  ffdCheckedMultipleDomState,
  ffdCheckedTagState,
} from "../../../atoms/operation";
import NotificationBar from "../sidebar/NotificationBar";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const OperationFreightFrontDesk = () => {
  DynamicTitle("Operasyon Ön Büro - Admin");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [checkedDom, setCheckedDom] = useRecoilState(ffdCheckDomState);
  const [checkedHand, setCheckedHand] = useRecoilState(ffdCheckHandState);
  const [checkedCourier, setCheckedCourier] = useRecoilState(ffdCheckCourierState);
  const [, setFfdCheckWhere] = useRecoilState(ffdCheckWhereState);
  const [ffdCheckedMultipleDom, setFfdCheckedMultipleDom] = useRecoilState(
    ffdCheckedMultipleDomState
  );
  const [ffdCheckedTag, setFfdCheckedTag] = useRecoilState(ffdCheckedTagState);

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const handleCheckedDom = useCallback(() => {
    setCheckedDom(true);
    setCheckedHand(false);
    setCheckedCourier(false);
    setFfdCheckWhere("dom");
  }, [setFfdCheckWhere, setCheckedDom, setCheckedHand, setCheckedCourier]);

  const handleCheckedHand = useCallback(() => {
    setCheckedDom(false);
    setCheckedHand(true);
    setCheckedCourier(false);
    setFfdCheckWhere("hand");
  }, [setFfdCheckWhere, setCheckedDom, setCheckedHand, setCheckedCourier]);

  const handleCheckedCourier = useCallback(() => {
    setCheckedDom(false);
    setCheckedHand(false);
    setCheckedCourier(true);
    setFfdCheckWhere("courier");
  }, [setFfdCheckWhere, setCheckedDom, setCheckedHand, setCheckedCourier]);

  return (
    <ThemeProvider theme={theme}>
      <NotificationBar />
      <section className="operation" style={{ paddingLeft: "64px" }}>
        <NavBar />
        <ToastContainer />
        <Box className="operation-wrapper" style={WrapperStyle()}>
          <animated.div ref={ref} style={springs}>
            <Box className="operation-container">
              <h6
                style={{
                  color: "var(--priceText)",
                  marginTop: "var(--gap2x)",
                  marginBottom: "var(--gap4x)",
                }}
              >
                Operasyon Ön Büro
              </h6>
              <div className="operation-freight-front-desk">
                <div className="operation-freight-front-desk-checkbox">
                  <div className="operation-freight-front-desk-checkbox-doms">
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedOutlinedIcon />}
                          checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                          checked={checkedDom}
                          onChange={handleCheckedDom}
                        />
                      }
                      label="Domestik Teslim"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedOutlinedIcon />}
                          checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                          checked={checkedHand}
                          onChange={handleCheckedHand}
                        />
                      }
                      label="Yük Elden Teslim"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedOutlinedIcon />}
                          checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                          checked={checkedCourier}
                          onChange={handleCheckedCourier}
                        />
                      }
                      label="Yük Kuryeden Teslim"
                    />
                  </div>
                  <div className="operation-freight-front-desk-checkbox-tics">
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedOutlinedIcon />}
                          checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                          checked={ffdCheckedMultipleDom}
                          onChange={() => setFfdCheckedMultipleDom(!ffdCheckedMultipleDom)}
                        />
                      }
                      label="Çoklu Domestik Gönderi"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedOutlinedIcon />}
                          checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                          checked={ffdCheckedTag}
                          onChange={() => setFfdCheckedTag(!ffdCheckedTag)}
                        />
                      }
                      label="Etiket Yazdır"
                    />
                  </div>
                </div>
                <OperationFreightFrontDeskDom />
              </div>
            </Box>
          </animated.div>
        </Box>
      </section>
    </ThemeProvider>
  );
};

export default OperationFreightFrontDesk;
