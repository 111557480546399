import { Box, Button, createTheme, InputAdornment, TextField, ThemeProvider } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useCallback, useState } from "react";
import { useSetRecoilState } from "recoil";
import { useCookies } from "react-cookie";
import { logCurrentPageState, logDataState, logTotalPageState } from "../../../atoms/log";
import axios from "../../../api/axios";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const LogSearch = () => {
  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  const [searchParam, setSearchParam] = useState("");

  const setlogTotalPage = useSetRecoilState(logTotalPageState);
  const setlogCurrentPage = useSetRecoilState(logCurrentPageState);
  const setlogData = useSetRecoilState(logDataState);

  const handleSearch = useCallback(() => {
    axios
      .get(`log/check-price-logs`, {
        params: {
          page: 1,
          limit: 500,
          order_direction: "DESC",
          search: searchParam,
        },
        headers: {
          Authorization: `Bearer ${jwt}`, // Replace 'Token' with your actual bearer token
        },
      })
      .then((resp) => {
        setlogData(resp.data.data);
        setlogCurrentPage(resp.data.currentPage);
        setlogTotalPage(resp.data.totalPages);
        console.log("log search", resp.data.totalPages);
      })
      .catch((error) => Errors(error));
  }, [searchParam, jwt, setlogData, setlogCurrentPage, setlogTotalPage]);

  const handleClear = useCallback(() => {
    setSearchParam("");
    axios
      .get(`log/check-price-logs`, {
        params: {
          page: 1,
          limit: 500,
          order_direction: "DESC",
        },
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((resp) => {
        setlogData(resp.data.data);
        setlogCurrentPage(resp.data.currentPage);
        setlogTotalPage(resp.data.totalPages);
      })
      .catch((error) => Errors(error));
  }, [jwt, setlogData, setlogCurrentPage, setlogTotalPage]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: "var(--lighterBg)",
          padding: "20px",
          borderRadius: "var(--inputBorderRadius)",
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          display: "flex",
          gap: "var(--gap)",
        }}
      >
        <TextField
          size="small"
          fullWidth
          label="Log Arama"
          placeholder=""
          autoComplete="off"
          value={searchParam}
          onChange={(e) => setSearchParam(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            } else if (e.key === "Escape") {
              handleClear();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button onClick={handleSearch} className="iyzi-button">
          Ara
        </Button>
      </Box>
    </ThemeProvider>
  );
};

export default LogSearch;
