import { Box, Button, createTheme, InputAdornment, TextField, ThemeProvider } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import {
  FinanceGonderiIadeCurrentPageState,
  FinanceGonderiIadeDataState,
  FinanceGonderiIadeTotalPageState,
} from "../../../../atoms/finance";
import { useCookies } from "react-cookie";
import axios from "../../../../api/axios";
import Errors from "../../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const FinanceGonderiIadeSearch = () => {
  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  const [, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");

  const [, setFinanceGonderiIadeTotalPage] = useRecoilState(FinanceGonderiIadeTotalPageState);
  const [, setFinanceGonderiIadeCurrentPage] = useRecoilState(FinanceGonderiIadeCurrentPageState);
  const [, setFinanceGonderiIadeData] = useRecoilState(FinanceGonderiIadeDataState);

  const handleSearch = useCallback(() => {
    axios
      .get(`parcel-return/demand/list`, {
        params: {
          page: 1,
          limit: 100,
          order_direction: "DESC",
          search: searchParam,
        },
        headers: {
          Authorization: `Bearer ${jwt}`, // Replace 'Token' with your actual bearer token
        },
      })
      .then((resp) => {
        setFinanceGonderiIadeData(resp.data.data);
        setFinanceGonderiIadeCurrentPage(resp.data.currentPage);
        setFinanceGonderiIadeTotalPage(resp.data.totalPages);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [
    searchParam,
    jwt,
    setFinanceGonderiIadeData,
    setFinanceGonderiIadeCurrentPage,
    setFinanceGonderiIadeTotalPage,
  ]);

  const handleClear = useCallback(() => {
    setSearchParam("");
    axios
      .get(`parcel-return/demand/list`, {
        params: {
          page: 1,
          limit: 100,
          order_direction: "DESC",
        },
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((resp) => {
        setFinanceGonderiIadeData(resp.data.data);
        setFinanceGonderiIadeCurrentPage(resp.data.currentPage);
        setFinanceGonderiIadeTotalPage(resp.data.totalPages);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [
    jwt,
    setFinanceGonderiIadeData,
    setFinanceGonderiIadeCurrentPage,
    setFinanceGonderiIadeTotalPage,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: "var(--lighterBg)",
          padding: "20px",
          borderRadius: "var(--inputBorderRadius)",
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          display: "flex",
          gap: "var(--gap)",
        }}
      >
        <TextField
          size="small"
          fullWidth
          label="Gönderi Arama"
          placeholder=""
          autoComplete="off"
          value={searchParam}
          onChange={(e) => setSearchParam(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            } else if (e.key === "Escape") {
              handleClear();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button onClick={handleSearch} className="iyzi-button">
          Ara
        </Button>
      </Box>
    </ThemeProvider>
  );
};

export default FinanceGonderiIadeSearch;
