import { CircularProgress, Pagination, createTheme } from "@mui/material";
import { useRecoilValue } from "recoil";
import { useCookies } from "react-cookie";
import { useInView, animated } from "@react-spring/web";
import { ThemeProvider } from "@emotion/react";
import { drawerOpenState } from "../../../atoms/viewPadding";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import axios from "../../../api/axios";
import NotificationBar from "../sidebar/NotificationBar";
import NavBar from "../sidebar/NavBar";
import WrapperStyle from "../../../utils/WrapperStyle";
import NewMemberTable from "./newMemberReports/NewMemberTable";
import { useEffect, useState } from "react";
import Errors from "../../../utils/Errors";
import NewMemberOptions from "./newMemberReports/NewMemberOptions";

const theme = createTheme({
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          overflow: "auto",
          maxHeight: 500,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const NewMemberReports = () => {
  DynamicTitle("Yeni Üye - Raporlar");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const drawerOpen = useRecoilValue(drawerOpenState);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currency, setCurrency] = useState(1);

  const navUnder = {
    paddingLeft: drawerOpen ? "264px" : 0,
    transition: "all 150ms linear",
    backgroundColor: "var(--lighterBg)",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  };

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const getData = () => {
    axios
      .get(`report/new-membership`)
      .then((response) => {
        setData(response.data?.data);
        setCurrentPage(response.data?.currentPage);
        setTotalPages(response.data?.totalPages);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  const handlePageChange = (e, page) => {
    setLoading(true);
    axios
      .get(`report/new-membership`, {
        params: {
          page,
        },
      })
      .then((response) => {
        setData(response.data?.data);
        setCurrentPage(page);
        setTotalPages(response.data?.totalPages);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <NotificationBar />
      <NavBar />
      <section className="my-wallet">
        <div className="nav-under" style={navUnder}>
          <div>Yeni Üye</div>
        </div>
        <div className="my-wallet-wrapper" style={WrapperStyle()}>
          <animated.div ref={ref} style={springs}>
            <div className="my-wallet-container">
              <NewMemberOptions
                setData={setData}
                setCurrentPage={setCurrentPage}
                setTotalPages={setTotalPages}
                setCurrency={setCurrency}
              />
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <Pagination
                    showFirstButton
                    showLastButton
                    size="small"
                    count={currentPage}
                    page={totalPages}
                    onChange={handlePageChange}
                  />
                  <NewMemberTable data={data} currency={currency} />
                  {data?.length > 10 && (
                    <Pagination
                      showFirstButton
                      showLastButton
                      size="small"
                      count={currentPage}
                      page={totalPages}
                      onChange={handlePageChange}
                    />
                  )}
                </>
              )}
            </div>
          </animated.div>
        </div>
      </section>
    </ThemeProvider>
  );
};

export default NewMemberReports;
