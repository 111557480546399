import { Dialog, DialogContent, Pagination } from "@mui/material";
import AuditHistory from "../../../../utils/AuditHistory";

const AuditHistoryDialog = ({ data, open, setOpen, totalPage, currentPage, handlePageChange }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Pagination
          showFirstButton
          showLastButton
          size="small"
          count={totalPage}
          page={currentPage}
          onChange={handlePageChange}
        />
        <AuditHistory data={data} />
        <Pagination
          showFirstButton
          showLastButton
          size="small"
          count={totalPage}
          page={currentPage}
          onChange={handlePageChange}
          sx={{ marginTop: "10px" }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AuditHistoryDialog;
