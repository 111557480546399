import {
  Box,
  Button,
  CircularProgress,
  createTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import axios from "../../../api/axios";
import {
  editAnnouncementState,
  notificationListState,
  notificationObjState,
} from "../../../atoms/announcement";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "10px !important",
        },
      },
    },
  },
});

const AnnouncementEdit = () => {
  const [, setEditAnnouncement] = useRecoilState(editAnnouncementState);
  const [notificationObj, setNotificationObj] = useRecoilState(notificationObjState);

  const [announcementType, setAnnouncementType] = useState(notificationObj?.type);
  const [memberId, setMemberId] = useState(notificationObj?.member_id);
  const [announcementText, setAnnouncementText] = useState(notificationObj?.text);
  const [startDate, setStartDate] = useState(notificationObj?.start_date);
  const [endDate, setEndDate] = useState(notificationObj?.end_date);
  const [loading, setLoading] = useState(false);

  const [notificationList, setNotificationList] = useRecoilState(notificationListState);

  const handleAnnouncementChange = useCallback((e) => {
    setAnnouncementType(e.target.value);
  }, []);

  const handleSubmitAnnouncement = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      await axios
        .post(`notification/update/${notificationObj.id}`, {
          type: announcementType,
          member_id: announcementType === "generic" ? 0 : memberId,
          text: announcementText,
          start_date: startDate,
          end_date: endDate,
        })
        .then((response) => {
          if (response.data.status === 200) {
            toast.success(response.data.message, { autoClose: 500 });
            const updatedNotificationList = notificationList.map((obj) => {
              if (obj.id === response.data.data.id) {
                // Eğer objenin id'si güncellenecek objenin id'sine eşitse, yeni objeyi yerleştir
                return response.data.data;
              } else {
                // Eğer id eşleşmezse, objeyi olduğu gibi bırak
                return obj;
              }
            });
            setNotificationList(updatedNotificationList);
            setNotificationObj({});
            setAnnouncementType("");
            setAnnouncementText("");
            setStartDate("");
            setEndDate("");
            setEditAnnouncement(false);
          }
        })
        .catch((error) => {
          Errors(error);
        })
        .finally(() => setLoading(false));
    },
    [
      announcementType,
      announcementText,
      startDate,
      endDate,
      setNotificationList,
      notificationList,
      notificationObj,
      setEditAnnouncement,
      setNotificationObj,
      memberId,
    ]
  );

  const handleCloseAnnouncementEdit = useCallback(() => {
    setNotificationObj({});
    setAnnouncementType("");
    setAnnouncementText("");
    setStartDate("");
    setEndDate("");
    setEditAnnouncement(false);
  }, [setEditAnnouncement, setNotificationObj]);

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={handleSubmitAnnouncement} className="announcement-add-wrapper">
        <Box className="announcement-add-inputs">
          <FormControl size="small" fullWidth>
            <InputLabel id="select-label-announcement-type">Duyuru Türü SSS</InputLabel>
            <Select
              labelId="select-label-announcement-type"
              id="select"
              value={announcementType}
              label="Duyuru Türü"
              onChange={(e) => handleAnnouncementChange(e)}
            >
              <MenuItem value={"member"}>Üye</MenuItem>
              <MenuItem value={"generic"}>Genel</MenuItem>
            </Select>
          </FormControl>
          {announcementType === "member" && (
            <FormControl required size="small" fullWidth>
              <InputLabel id="select-label-member-id">Üye ID</InputLabel>
              <Select
                labelId="select-label-member-id"
                id="select-member-id"
                value={memberId}
                label="Üye ID"
                onChange={(e) => setMemberId(e.target.value)}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
              </Select>
            </FormControl>
          )}
          <TextField
            label="Başlangıç Tarihi"
            type={"date"}
            size="small"
            value={startDate}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setStartDate(e.target.value)}
            fullWidth
          />
          <TextField
            label="Bitiş Tarihi"
            type={"date"}
            size="small"
            value={endDate}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setEndDate(e.target.value)}
            fullWidth
          />
        </Box>
        <TextField
          label="Duyuru Giriniz"
          value={announcementText}
          onChange={(e) => setAnnouncementText(e.target.value)}
          multiline
          rows={4}
          fullWidth
          size="small"
        />
        <Box className="announcement-add-buttons">
          <Button onClick={handleCloseAnnouncementEdit} type="button">
            Vazgeç
          </Button>
          {loading ? (
            <Box
              sx={{
                width: "72px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress sx={{ width: "24px !important", height: "24px !important" }} />
            </Box>
          ) : (
            <Button type="submit">Kaydet</Button>
          )}
        </Box>
      </form>
    </ThemeProvider>
  );
};

export default AnnouncementEdit;
