import { Box } from "@mui/material";
import SupportCaseQuestionType from "../../../../utils/SupportCaseQuestionType";
import MessageTime from "../../../../utils/MessageTime";
import { useSetRecoilState } from "recoil";
import {
  supportCaseMessagesState,
  supportCaseModalState,
} from "../../../../atoms/support";
const SupportDetailCaseListAdmin = ({ item }) => {
  const setSupportCaseModal = useSetRecoilState(supportCaseModalState);
  const setSupportCaseMessages = useSetRecoilState(supportCaseMessagesState);

  const handleCaseMessage = async () => {
    setSupportCaseModal(true);
    setSupportCaseMessages(item);
  };

  return (
    <Box
      onClick={handleCaseMessage}
      sx={{
        cursor: "pointer",
        padding: "5px",
        borderRadius: "var(--wrapperBorderRadius)",
        ":hover": { boxShadow: "var(--shadow)" },
      }}
    >
      <Box
        sx={{
          color: "var(--priceText)",
          fontSize: "11px",
          paddingBottom: "15px",
        }}
      >
        <Box>
          <p>
            {Number(item.question_type) === 1
              ? "Adres/İletişim Bilgisi Yetersiz BA"
              : Number(item.question_type) === 2
              ? "Alıcı Adresi Yok NH"
              : Number(item.question_type) === 3
              ? "Hasar DM"
              : Number(item.question_type) === 4
              ? "Kayıp LT"
              : Number(item.question_type) === 5
              ? "Adres Değişikliği Taleplerinde AC"
              : Number(item.question_type) === 6
              ? "Gümrük Gecikmesi CD"
              : Number(item.question_type) === 7
              ? "Evrak Eksiği PW"
              : "Alıcı Kurum ise Adreste Yok CA"}{" "}
            <br /> <br />
          </p>
          <p
            style={{
              fontSize: "13px",
            }}
          >
            {MessageTime(item.created_at)} - {item?.get_created_by[0]?.name}{" "}
            {item?.get_created_by[0]?.surname}
          </p>
        </Box>
        <br />
        <Box>
          <p
            style={{
              color: item.deleted === "1" ? "red" : "green",
              fontSize: "13px",
            }}
          >
            {item.deleted === "1" ? "KAPALI" : "AÇIK"}
          </p>
        </Box>
      </Box>
      {item.deleted === "1" && (
        <Box
          sx={{
            border: "1px solid var(--inputBorderColor)",
            borderRadius: "var(--inputBorderRadius)",
            color: "var(--priceText)",
            fontSize: "13px",
            padding: "15px",
            position: "relative",
          }}
        >
          <Box>
            <Box
              sx={
                Number(item.question_type) === 1
                  ? {
                      backgroundColor: "var(--pastelYellow)",
                      color: "var(--orangeBrand)",
                      padding: "3px 10px",
                      borderRadius: "var(--wrapperBorderRadius)",
                      position: "absolute",
                      top: "-15px",
                      right: "15px",
                      fontSize: "13px",
                    }
                  : Number(item.case_type) === 2
                  ? {
                      backgroundColor: "var(--pastelBlue)",
                      color: "var(--info)",
                      padding: "3px 10px",
                      borderRadius: "var(--wrapperBorderRadius)",
                      position: "absolute",
                      top: "-15px",
                      right: "15px",
                      fontSize: "13px",
                    }
                  : Number(item.case_type) === 3
                  ? {
                      backgroundColor: "var(--pastelGreen)",
                      color: "var(--shipmentGreen)",
                      padding: "3px 10px",
                      borderRadius: "var(--wrapperBorderRadius)",
                      position: "absolute",
                      top: "-15px",
                      right: "15px",
                      fontSize: "13px",
                    }
                  : {
                      backgroundColor: "var(--pastelRed)",
                      color: "var(--redBrand)",
                      padding: "3px 10px",
                      borderRadius: "var(--wrapperBorderRadius)",
                      position: "absolute",
                      top: "-15px",
                      right: "15px",
                      fontSize: "13px",
                    }
              }
            >
              {SupportCaseQuestionType(item.case_type)}
            </Box>
            {item.message}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SupportDetailCaseListAdmin;
