import { Dialog, DialogContent, DialogTitle, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useRecoilState } from "recoil";
import { adminFilesModalOpenState, selectedDocumentsState } from "../../../../atoms/myShipments";
import GetFileAdmin from "../../../../utils/GetFileAdmin";
import { useDropzone } from "react-dropzone";
import { useCallback, useState } from "react";
import uploadIcon from "../../../../img/icons/upload_icon.svg";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import DowloadPdfView from "../../../../components/dowloadPdfView/DowloadPdfView";

const FilesModal = () => {
  const [adminFilesModalOpen, setAdminFilesModalOpen] = useRecoilState(adminFilesModalOpenState);
  const [selectedDocuments, setSelectedDocuments] = useRecoilState(selectedDocumentsState);

  const handleClose = useCallback(() => {
    setAdminFilesModalOpen({ status: false, data: {}, id: null });
  }, [setAdminFilesModalOpen]);

  const [sendFiles, setSendFiles] = useState([]);

  const handleDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      id: Math.random().toString(),
    }));
    setSendFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setSelectedDocuments((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
  });

  const uploadSelected = () => {
    let formData = new FormData();
    Array.from(selectedDocuments).forEach((file, index) => {
      formData.append(`files[${index}]`, file.file);
    });
    formData.append("parent_id", adminFilesModalOpen.id);
    formData.append("parent_type", "Parcel");

    axios.post(`document/add`, formData).then(() => {
      toast.success("Dosyalar Başarıyla Yüklendi", { autoClose: 500 });
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    });
  };

  const handleSetState = (id) => {
    setSendFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    setSelectedDocuments((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  return (
    <Dialog open={adminFilesModalOpen.status} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Dosyalar</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box className="dropzone-wrapper">
          <Box className="dropzone">
            <div className="dropzone-area" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="dropzone-area-text">
                <img src={uploadIcon} alt="Upload Icon" />
                <span className="dropzone-area-text-headline">
                  Dosyayı sürükleyip bırakın veya{" "}
                  <span style={{ color: "var(--info)" }}>Gözat</span>
                </span>
                <span className="dropzone-area-text-sub">
                  Desteklenen Formatlar: DOC, DOCX, PDF, TXT, CSV, PNG, JPG, JPEG, XLSX
                </span>
              </div>
            </div>
          </Box>
          <DowloadPdfView sendFiles={sendFiles} handleSetState={handleSetState} />
          <div className="dropzone-uploader-button" onClick={uploadSelected}>
            Kaydet
          </div>
        </Box>
        {adminFilesModalOpen.data?.get_parcel_documents?.length !== 0 && (
          <Box
            sx={{
              padding: "10px",
              borderRadius: "var(--wrapperBorderRadius)",
              boxShadow: "var(--shadow)",
            }}
          >
            <Box
              sx={{
                color: "var(--priceText)",
                fontWeight: 700,
                fontSize: "14px",
                marginBottom: "20px",
                marginTop: "20px",
                paddingLeft: "8px",
              }}
            >
              Müşterinin Yüklediği Dosyalar - {adminFilesModalOpen.data?.length}
            </Box>
            {adminFilesModalOpen.data?.get_parcel_documents?.map((document, key) => (
              <>
                <span style={{ fontSize: "12px", color: "var(--priceText)" }}>
                  {document.get_created_by.name} {document.get_created_by.surname}
                </span>
                <Box
                  sx={{
                    border: "1px solid var(--phoneDigitBg)",
                    marginBottom: "10px",
                    padding: "10px",
                    borderRadius: "var(--wrapperBorderRadius)",
                    fontSize: "13px",
                    cursor: "pointer",
                    color: "var(--priceText)",
                    transition: "all 150ms linear",
                    ":hover": {
                      backgroundColor: "var(--pastelBlue)",
                      transition: "all 150ms linear",
                    },
                  }}
                  key={document.id}
                  onClick={() => GetFileAdmin(document)}
                >
                  <span
                    style={{
                      fontSize: "13px",
                      color: "var(--priceText)",
                      fontWeight: "700",
                    }}
                  >
                    {key + 1}
                  </span>{" "}
                  - {document.name} / {document.created_at.slice(0, 10).replaceAll("-", "/")} -{" "}
                  {document.created_at.slice(11, 19)}
                </Box>{" "}
              </>
            ))}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FilesModal;
