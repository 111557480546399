import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Modal,
  TextField,
  Typography,
  Grid,
  Tooltip,
  Avatar,
  Collapse,
  CardContent,
  Card,
  CardHeader,
  IconButton,
  Container,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { Checkbox, FormControlLabel } from "@mui/material";

import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import { CircleFlag } from "react-circle-flags";
import { useNavigate } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddBoxIcon from "@mui/icons-material/AddBox";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import miniLogo from "../../../../img/images/line-md_circle-to-confirm-circle-transition.svg";
import proformaLogo from "../../../../img/images/mdi_map-outline.svg";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import ClearIcon from "@mui/icons-material/Clear";
import FlightTakeoffOutlinedIcon from "@mui/icons-material/FlightTakeoffOutlined";
import FlightLandOutlinedIcon from "@mui/icons-material/FlightLandOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  ffdBase64State,
  ffdCheckCourierState,
  ffdCheckDomState,
  ffdCheckFrontDeskState,
  ffdCheckHandState,
  ffdDataState,
  ffdDomAwbState,
  ffdDomSupplierState,
  ffdManualDomWidth,
  ffdManualDomLength,
  ffdManualDomHeight,
  ffdManualDomGw,
  ffdManualDomSaveToDimension,
  ffdCheckManualDimensionState,
  ffdIyziTeamDimemsionState,
  ffdCheckMultipleRXState,
  ffdCheckSearchRXState,
  ffdCheckSingleRXState,
  editedProformaState,
  ffdPakCostState,
} from "../../../../atoms/operation";
import {
  statusChangeModalOpenState,
  courierStatusState,
} from "../../../../atoms/myShipments";
import { useCallback, useEffect, useState } from "react";
import FormatDate from "../../../../utils/FormatDate";
import PDFReader from "../PDFReader";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import countriesDetailed from "../../../../json/countriesDetailed.json";
import uploadIcon from "../../../../img/icons/upload_icon.svg";
import DowloadPdfView from "../../../../components/dowloadPdfView/DowloadPdfView";
import { useDropzone } from "react-dropzone";
import Currency from "../../../../utils/CurrencySymbol";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  overPayOpenState,
  overPayState,
  productCurrencyState,
  productValueState,
  supplierIdState,
} from "../../../../atoms/createShipment";
import DownloadBase64Pdf from "../../../../utils/DownloadBase64Pdf";
import FilesModal from "../../myShipments/modals/FilesModal";
import { update } from "lodash";
import { left } from "@popperjs/core";

import OperationDimension from "./OperationDimension";
import OperationDimensionMember from "./OperationDimensionMember";
import OperationProforma from "./OperationProforma";
import OperationPak from "./OperationPak";
import Errors from "../../../../utils/Errors";
import StatusChangeModal from "../../../components/myShipments/modals/StatusChangeModal";

const OperationCargoDomResp = () => {
  const navigate = useNavigate();
  const [ffdData, setFfdData] = useRecoilState(ffdDataState);
  const [ffdBase64, setFfdBase64] = useRecoilState(ffdBase64State);

  const [sendRequest, setSendRequest] = useState(false);
  const [checkPriceDialog, setCheckPriceDialog] = useState(false);
  const [checkPriceDialogData, setCheckPriceDialogData] = useState({
    old_selling_price: 0,
    new_selling_price: 0,
  });
  const [loading, setLoading] = useState(false);
  const [hideSecurityInspectionButton, setHideSecurityInspectionButton] =
    useState(false);
  const [securityInspectionDialog, setSecurityInspectionDialog] =
    useState(false);
  const [securityRemoveInspectionDialog, setSecurityRemoveInspectionDialog] =
    useState(false);
  const [showCommoduties, setShowCommoduties] = useState(false);
  const [noProblemDialog, setNoProblemDialog] = useState(false);
  const [selectedProblemStatus, setSelectedProblemStatus] = useState("");

  //paketleme hatası stateleri//
  const [packageModal, setPackageModal] = useState(false);
  const [packageDescription, setPackageDescription] = useState("");
  const [sendPackageFiles, setSendPackageFiles] = useState([]);
  //paketleme hatasi stateleri//

  const [unavailablePackageModal, setUnavailablePackageModal] = useState(false);
  const [unavailablePackageDescription, setUnavailablePackageDescription] =
    useState("");
  const [sendUnavailablePackageFiles, setSendUnavailablePackageFiles] =
    useState([]);

  const [damagedModal, setDamagedModal] = useState(false);
  const [missingDocumentModal, setMissingDocumentModal] = useState(false);
  const [missingProformaModal, setMissingProformaModal] = useState(false);
  const [missingAddressModal, setMissingAddressModal] = useState(false);
  const [sendFiles, setSendFiles] = useState([]);
  const [description, setDescription] = useState("");
  const [textError, setTextError] = useState(false);

  const [domEn, setDomEn] = useRecoilState(ffdManualDomWidth);
  const [domBoy, setDomBoy] = useRecoilState(ffdManualDomLength);
  const [domYuk, setDomYuk] = useRecoilState(ffdManualDomHeight);
  const [domGw, setDomGw] = useRecoilState(ffdManualDomGw);

  const [checkDimension, setCheckDimension] = useState(false);
  const [editedProforma] = useRecoilState(editedProformaState);
  const [proformaCurrency, setProformaCurrency] = useState(
    ffdData?.get_parcel_commoduties[0]?.currency
  );
  const [ioss, setIoss] = useState(ffdData?.ioss);

  const pakCost = useRecoilValue(ffdPakCostState);
  console.log("pakCost", pakCost);
  useEffect(() => {
    if (domYuk === "" || domBoy === "" || domEn === "" || domGw === "") {
      setCheckDimension(true);
    } else {
      setCheckDimension(false);
    }
  }, [setCheckDimension, domYuk, domBoy, domEn, domGw]);

  const handleDomEn = (e) => {
    const value = e.target.value;
    setDomEn(value);
  };
  const handleDomBoy = (e) => {
    const value = e.target.value;
    setDomBoy(value);
  };
  const handleDomYuk = (e) => {
    const value = e.target.value;
    setDomYuk(value);
  };
  const handleDomGw = (e) => {
    const value = e.target.value;
    setDomGw(value);
  };

  const handleDecimalChange = (e) => {
    let value = e.target.value.replace(/,/g, ".");
    const name = e.target.name;

    if (name === "qty") {
      value = value.replace(/[^0-9]|^0{1,}/g, "");
    } else {
      value = value.replace(/[^0-9.]|(?<=\..*)\.|^0{2,}(?=[.])/g, "");

      if (name === "gross") {
        value = value.replace(/^0{2,}/, "0");
      }

      const regex = /^\d+(\.\d{0,2})?$/;

      if (
        value.startsWith(".") ||
        (!regex.test(value) && value !== "") ||
        (name !== "gross" && value.startsWith("0.") && value.length === 1)
      ) {
        return;
      } else {
        if (name === "domestic_en") {
          setDomEn(value);
        } else if (name === "domestic_boy") {
          setDomBoy(value);
        } else if (name === "domestic_yuk") {
          setDomYuk(value);
        } else if (name === "domestic_gw") {
          setDomGw(value);
        }
      }
    }
  };

  console.log(domYuk);

  const handleClear = useCallback(() => {
    setFfdData({});
    setFfdBase64("");
  }, [setFfdData, setFfdBase64]);

  useEffect(() => {
    if (ffdData?.get_parcel_commoduties.length > 0) {
      setCommoduties(ffdData?.get_parcel_commoduties);
      console.log("ffData Commoduties loaded.");
    }

    if (ffdData?.get_parcel_domestic_dimensions.length > 0) {
      if (fffCheckSingleDom) {
        setDomBoy(ffdData?.get_parcel_domestic_dimensions[0].length);
        setDomEn(ffdData?.get_parcel_domestic_dimensions[0].width);
        setDomGw(ffdData?.get_parcel_domestic_dimensions[0].gw);
        setDomYuk(ffdData?.get_parcel_domestic_dimensions[0].height);
      }
    }
    if (
      ffdData?.operation_step_type === "domestik_tekli_securitysiz" &&
      ffdData?.operation_step === "step_0"
    ) {
      setHideSecurityInspectionButton(true);
    }
  }, [ffdData]);

  useEffect(() => {
    if (sendRequest) {
      console.log("Gönderim Durumu:" + sendRequest);
      console.log("Gönderim Durumu Tip:" + typeof sendRequest);
      handleCompleteMalKabul();
    }
    console.log("Fiyat Kontrol Durumu Güncelleniyor.");
  }, [sendRequest]);
  console.log(ffdData);
  console.log("ffddata length:" + Object.keys(ffdData).length);

  const handleSend = useCallback(
    (e) => {
      axios
        .post(`parcel/operation/mark-damaged`, {
          parcel_ref_no: ffdData?.parcel_ref_no,
          status: e === 1 ? 2.16 : 2.17,
        })
        .then((response) => {
          toast.success(response.data.message);
          handleClear();
        })
        .catch((error) => Errors(error));
    },
    [ffdData, handleClear]
  );

  const handleSecurityInspection = () => {
    /*/ axios
      .post(`parcel/operation/security-check`, {
        parcel_ref_no: ffdData?.parcel_ref_no,
      })
      .then((response) => {
        toast.success(response.data.message);
        setSecurityRemoveInspectionDialog(false);
        let cloned = JSON.parse(JSON.stringify(ffdData));
        cloned.security_inspection = false;
        setFfdData(cloned);
        setSecurityInspectionDialog(false);
        setSecurityInsPectionCheck(false);
      })
      .catch((error) => {
        toast.error(error.response.data.data);
        setSecurityInspectionDialog(false);
        setSecurityInsPectionCheck(false);
        setSecurityRemoveInspectionDialog(false);
      });/*/
    toast.success("Güvenlik Kontrolüne Geçici Alındı.");
    setSecurityRemoveInspectionDialog(false);
    let cloned = JSON.parse(JSON.stringify(ffdData));
    cloned.security_inspection = false;
    setFfdData(cloned);
    setSecurityInspectionDialog(false);
    setSecurityInsPectionCheck(false);
  };

  const handleSecurityRemoveInspection = () => {
    let cloned = JSON.parse(JSON.stringify(ffdData));
    cloned.security_inspection = true;
    cloned.status = "2.01";
    cloned.temp = 1;
    setFfdData(cloned);
    setSecurityRemoveInspectionDialog(false);
    return toast.success("Güvenlik Kontrolü Geçici olarak Kaldırıldı.");

    //GONDERI DURUMU GUNCELLEMEK İCİN  ORN AXIOS ISTEGI //
    /*/ axios.post('parcel/operation/update-status',{status:"2.01",id:ffdData?.id}).then((response) => {

    })
    .catch((error) => {
        toast.error(
            error.response.data.message
                ? error.response.data.message
                : error.response.data.data
        );
        if (error.response.status === 401) {
            localStorage.removeItem("user");
            document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            navigate("/login");
        }
    });/*/
  };
  const [filesModalOpen, setFilesModalOpen] = useState(false);
  const handleCloseFilesModal = () => {
    setFilesModalOpen(false);
  };

  const printAPIDocument = () => {
    if (ffdData?.base64APILabel && ffdData?.base64APILabel !== null) {
      DownloadBase64Pdf(ffdData?.base64APILabel, ffdData?.base64APILabelAwb);
      return toast.success("API PDF İndirildi.");
    }
    return toast.error("API PDF Bulunamadı.");
  };
  const printAPIProformaDocument = () => {
    if (
      ffdData?.base64APICommercialInvoice &&
      ffdData?.base64APICommercialInvoice !== null
    ) {
      DownloadBase64Pdf(
        ffdData?.base64APICommercialInvoice,
        ffdData?.base64APILabelAwb + "_CommercialInvoice"
      );
      return toast.success("API Proforma PDF İndirildi.");
    }
    return toast.error("API  Proforma PDF Bulunamadı.");
  };

  const createNewLabelNumber = () => {
    setLoading(true);
    axios
      .get(`parcel/create-label-api/${ffdData.id}`)
      .then((response) => {
        console.log(response);
        if (response.data.data.error_count > 0) {
          toast.error(
            response.data.data.errors.map((f) => f.Message).join(",")
          );
        } else {
          let cloned = { ...ffdData };
          cloned.tracking_awb = response.data.data.success_items.masterTrack
            ? response.data.data.success_items.masterTrack.TrackingNumber
            : "";
          setFfdData(cloned);
          return toast.success(response.data.message);
        }
      })
      .catch((error) => Errors(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const printDocument = () => {
    const byteCharacters = atob(ffdBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement("iframe");

    // The component should be mounted in the DOM tree before calling print
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.onload = function () {
      setTimeout(() => {
        // Give the browser some time to render the PDF
        iframe.contentWindow.print();
        URL.revokeObjectURL(blobUrl); // Free up memory
      }, 0);
    };
    iframe.src = blobUrl;
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: " 80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: " 20%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  //ADRES HATASI TYPES AND METHODS //
  const missingAddressTypes = [
    { value: "PO BOX", label: "PO BOX" },
    { value: "ASKERİ ÜS", label: "ASKERİ ÜS" },
    { value: "POSTA KODU", label: "POSTA KODU" },
    { value: "AÇIK ADRES", label: "AÇIK ADRES" },
    { value: "DİĞER", label: "DİĞER", extraContent: true },
  ];

  const [selectedMissingAddressOptions, setSelectedMissingAddressOptions] =
    useState([]);

  const handleMultiSelectChange0 = (event, selected) => {
    if (selected.filter((f) => f.extraContent).length === 0) {
      setOtherTags0([]);
    }
    setSelectedMissingAddressOptions(selected);
    console.log("Secilen Adres Tipleri", selectedMissingAddressOptions);
  };

  const [inputOtherValue0, setInputOtherValue0] = useState("");
  const [otherTags0, setOtherTags0] = useState([]);

  const handleInputOtherChange0 = (event) => {
    setInputOtherValue0(event.target.value);
  };

  const handleInputOtherKeyPress0 = (event) => {
    if (event.key === "Enter" && inputOtherValue0.trim() !== "") {
      setOtherTags0([...otherTags0, inputOtherValue0]);
      setInputOtherValue0("");
    }
  };

  const handleTagDelete0 = (tagToDelete) => {
    setOtherTags0(otherTags0.filter((tag) => tag !== tagToDelete));
  };

  const handleRegisterMissingAddress = () => {
    let cloneMissingSelectedOptions = JSON.parse(
      JSON.stringify(selectedMissingAddressOptions)
    );
    let clonedOtherTags = JSON.parse(JSON.stringify(otherTags0));
    cloneMissingSelectedOptions = cloneMissingSelectedOptions
      .filter((f) => !f.extraContent)
      .map((r) => r.label);

    if (clonedOtherTags.length > 0) {
      for (let i = 0; i < clonedOtherTags.length; i++) {
        cloneMissingSelectedOptions.push(clonedOtherTags[i]);
      }
    }
    console.log(otherTags0);
    console.log(cloneMissingSelectedOptions);
    setMissingAddressModal(false);
    setSelectedMissingAddressOptions(cloneMissingSelectedOptions);
    return console.log("Eksik Adres Eklendi.");
  };
  //ADRES HATASI TYPES AND METHODS //

  //PROFORMA HATASI TYPES AND METHODS //
  const missingProformaTypes = [
    { value: "IOSS", label: "IOSS" },
    { value: "GTIP", label: "GTIP" },
    {
      value: "DETAYLI İNGİLİZCE MAL TANIMI",
      label: "DETAYLI İNGİLİZCE MAL TANIMI",
    },
    { value: "DİĞER", label: "DİĞER", extraContent: true },
  ];

  const [selectedMissingProformaOptions, setSelectedMissingProformaOptions] =
    useState([]);

  const handleMultiSelectChange2 = (event, selected) => {
    if (selected.filter((f) => f.extraContent).length === 0) {
      setOtherTags2([]);
    }
    setSelectedMissingProformaOptions(selected);
    console.log("Secilen Proforma Tipleri", selectedMissingProformaOptions);
  };

  const [inputOtherValue2, setInputOtherValue2] = useState("");
  const [otherTags2, setOtherTags2] = useState([]);

  const handleInputOtherChange2 = (event) => {
    setInputOtherValue2(event.target.value);
  };

  const handleInputOtherKeyPress2 = (event) => {
    if (event.key === "Enter" && inputOtherValue2.trim() !== "") {
      setOtherTags2([...otherTags2, inputOtherValue2]);
      setInputOtherValue2("");
    }
  };

  const handleTagDelete2 = (tagToDelete) => {
    setOtherTags2(otherTags2.filter((tag) => tag !== tagToDelete));
  };

  const handleRegisterMissingProforma = () => {
    let cloneMissingSelectedOptions = JSON.parse(
      JSON.stringify(selectedMissingProformaOptions)
    );
    let clonedOtherTags = JSON.parse(JSON.stringify(otherTags2));
    cloneMissingSelectedOptions = cloneMissingSelectedOptions
      .filter((f) => !f.extraContent)
      .map((r) => r.label);

    if (clonedOtherTags.length > 0) {
      for (let i = 0; i < clonedOtherTags.length; i++) {
        cloneMissingSelectedOptions.push(clonedOtherTags[i]);
      }
    }
    console.log(otherTags2);
    console.log(cloneMissingSelectedOptions);
    setMissingProformaModal(false);
    setSelectedMissingProformaOptions(cloneMissingSelectedOptions);
    return console.log("Proforma Eklendi.");
  };
  //PROFORMA HATASI TYPES AND METHODS //

  //EKSİK EVRAK TYPES AND METHODS//
  const missingDocumentTypes = [
    { value: "E-ARŞİV", label: "E-ARŞİV" },
    { value: "TSCA", label: "TSCA" },
    { value: "FDA", label: "FDA" },
    { value: "MSDS", label: "MSDS" },
    { value: "ATR", label: "ATR" },
    { value: "DİĞER", label: "DİĞER", extraContent: true },
  ];

  const [selectedMissingDocumentOptions, setSelectedMissingDocumentOptions] =
    useState([]);

  const handleMultiSelectChange = (event, selected) => {
    if (selected.filter((f) => f.extraContent).length === 0) {
      setOtherTags([]);
    }
    setSelectedMissingDocumentOptions(selected);
    console.log("Secilen Eksik Evrak Tipleri", selectedMissingDocumentOptions);
  };

  const [inputOtherValue, setInputOtherValue] = useState("");
  const [otherTags, setOtherTags] = useState([]);

  const handleInputOtherChange = (event) => {
    setInputOtherValue(event.target.value);
  };

  const handleInputOtherKeyPress = (event) => {
    if (event.key === "Enter" && inputOtherValue.trim() !== "") {
      setOtherTags([...otherTags, inputOtherValue]);
      setInputOtherValue("");
    }
  };

  const handleTagDelete = (tagToDelete) => {
    setOtherTags(otherTags.filter((tag) => tag !== tagToDelete));
  };
  //EKSİK EVRAK TYPES AND METHODS//

  const handleSetStatePackage = (id) => {
    setSendPackageFiles((prevFiles2) =>
      prevFiles2.filter((file) => file.id !== id)
    );
  };

  const handleSetStateUnavailablePackage = (id) => {
    setSendUnavailablePackageFiles((prevFiles2) =>
      prevFiles2.filter((file) => file.id !== id)
    );
  };

  const handleSetState = (id) => {
    setSendFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  const handleDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      id: Math.random().toString(),
      maxSize: 2048,
    }));
    if (cStatus === "2.06") {
      setSendPackageFiles((prevFiles) => [...prevFiles, ...newFiles]);
    } else if (cStatus === "2.07") {
      setSendUnavailablePackageFiles((prevFiles) => [
        ...prevFiles,
        ...newFiles,
      ]);
    } else if (cStatus === "2.08") {
      setSendFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
  });
  const handleTextBlur = useCallback(
    (e) => {
      if (e.target.value.length < 2) {
        setTextError(true);
      } else if (e.target.value.length > 2) {
        setTextError(false);
      }
    },
    [setTextError]
  );

  const handleRegisterPackage = () => {
    setPackageModal(false);
    return console.log("Paketleme Hatası Eklendi.");
  };
  const handleRegisterUnavaiblePackage = () => {
    setUnavailablePackageModal(false);
    return console.log("Uygunsuz Paketleme Hatası Eklendi.");
  };

  const handleRegisterDamage = () => {
    setDamagedModal(false);
    return console.log("Hasar Eklendi.");
  };

  //tekli gonderi coklu gonderi ve gonderi arama//
  const fffCheckMultipleDom = useRecoilValue(ffdCheckMultipleRXState);
  const fffCheckSearchDom = useRecoilValue(ffdCheckSearchRXState);
  const fffCheckSingleDom = useRecoilValue(ffdCheckSingleRXState);
  //tekli gonderi coklu gonderi ve gonderi arama//

  const ffdCheckDom = useRecoilValue(ffdCheckDomState); // domestik teslim
  const ffdCheckFrontDesk = useRecoilValue(ffdCheckFrontDeskState); // ön büro elden teslim
  const ffdCheckHand = useRecoilValue(ffdCheckHandState); // depo elden teslim
  const ffdCheckCourier = useRecoilValue(ffdCheckCourierState); // kurye teslim

  const domSupplier = useRecoilValue(ffdDomSupplierState);
  const domAwb = useRecoilValue(ffdDomAwbState);
  // const domEn = useRecoilValue(ffdManualDomWidth);
  //const domUzunluk = useRecoilValue(ffdManualDomLength);
  //const domYukseklik = useRecoilValue(ffdManualDomHeight);
  //const domGw = useRecoilValue(ffdManualDomGw);
  const domSaveToDimension = useRecoilValue(ffdManualDomSaveToDimension);
  const domManualDimensionCheck = useRecoilValue(ffdCheckManualDimensionState);

  const iyziTeamDimensions = useRecoilValue(ffdIyziTeamDimemsionState);
  // console.log("iyziTeamDimensions", iyziTeamDimensions);

  const prepareRequestData = () => {
    let data = {};

    if (ffdCheckDom) {
      data = {
        delivery_type: "domestic",
        domestic_awb: domAwb,
        domestic_supplier: domSupplier,
        from: "ware_house",
        parcel_ref_no: ffdData?.parcel_ref_no,
      };
    } else if (ffdCheckFrontDesk) {
      data = {
        delivery_type: "non_dm_warehouse",
        from: "front_desk",
        parcel_ref_no: ffdData?.parcel_ref_no,
      };
    } else if (ffdCheckHand) {
      data = {
        delivery_type: "non_dm_warehouse",
        from: "ware_house",
        parcel_ref_no: ffdData?.parcel_ref_no,
      };
    } else {
      data = {
        delivery_type: "non_dm_warehouse_courier",
        from: "ware_house",
        parcel_ref_no: ffdData?.parcel_ref_no,
      };
    }

    data.supplier_id = ffdData?.get_parcel_supplier.id;

    if (fffCheckSingleDom) {
      data.parcel_send_radio = "Single";
    } else {
      data.parcel_send_radio = "Multiple";
    }

    //OPERASYON  TİPİ VE AŞAMASI//
    data["operation_step_type"] = ffdData?.operation_step_type;
    data["operation_step"] = ffdData?.operation_step;
    //OPERASYON TİPİ VE AŞAMASI//

    //ÖLÇÜLER VE PROFORMA DATALARI//
    data["iyzi_team_dimensions"] = iyziTeamDimensions;
    data["commoduties"] = commoduties;
    //ÖLÇÜLER VE PROFORMA DATALARI//

    data["security_inspection"] = ffdData?.security_inspection;

    //PAKETLEME HATASI DATALARI//
    data["package_error"] = { packageDescription, sendPackageFiles };
    //PAKETLEME HATASI DATALARI//

    //UYGUNSUZ PAKETLEME  HATASI DATALARI//
    data["unavailable_package_error"] = {
      unavailablePackageDescription,
      sendUnavailablePackageFiles,
    };
    //UYGUNSUZ PAKETLEME  HATASI DATALARI//

    //HASARLI GÖNDERİ - EKSİK EVRAK - EKSİK ADRES VE PROFORMA HATALARI DATALARI//
    data["damaged_parcel_error"] = { description, sendFiles };
    data["missing_document_error"] = selectedMissingDocumentOptions;
    data["missing_address_error"] = selectedMissingAddressOptions;
    data["proforma_error"] = selectedMissingProformaOptions;
    //HASARLI GÖNDERİ - EKSİK EVRAK - EKSİK ADRES VE PROFORMA HATALARI DATALARI//

    if (selectedProblemStatus === "") {
      console.log(ffdData?.security_inspection);
      console.log(!ffdData?.security_inspection);
      if (!ffdData?.security_inspection === false) {
        data["parcel_status"] = "2.01";
      } else {
        data["parcel_status"] = "2.03";
      }
    } else {
      data["parcel_status"] = selectedProblemStatus;
    }

    data["tracking_awb"] = ffdData?.tracking_awb;

    //MANUEL DOMESTİK ÖLÇÜLER //
    data["is_manual_domestic"] = domManualDimensionCheck;
    data["width"] = domEn;
    data["length"] = domBoy;
    data["height"] = domYuk;
    data["gross"] = domGw;
    //MANUEL DOMESTİK ÖLÇÜLER //

    //VALİDASYON KONTROLÜ//
    // const validation_response = presendValidations(data);
    const validation_response = makeValidations(data);
    //VALİDASYON KONTROLÜ//

    return prepareFormData(data, validation_response);
  };

  const prepareFormData = (data, validationResponse) => {
    if (!validationResponse.status) {
      const formData = new FormData();
      if (data.commoduties.filter((f) => f.id !== 0).length > 0) {
        data.commoduties
          .filter((f) => f.id !== 0)
          .forEach((com, index) => {
            formData.append(`commoduties[${index}][id]`, com.id);
            formData.append(`commoduties[${index}][title]`, com.title);
            formData.append(`commoduties[${index}][sku]`, com.sku);
            formData.append(`commoduties[${index}][hs_code]`, com.hs_code);
            formData.append(`commoduties[${index}][country]`, com.country);
            formData.append(`commoduties[${index}][qty]`, com.qty);
            formData.append(`commoduties[${index}][price]`, com.price);
            formData.append(
              `commoduties[${index}][total_price]`,
              com.total_price
            );
            formData.append(`commoduties[${index}][currency]`, com.currency);
          });
      }

      if (data.iyzi_team_dimensions.length > 0) {
        data.iyzi_team_dimensions.forEach((dim, index) => {
          formData.append(`iyzi_team_dimensions[${index}][id]`, dim.id);
          formData.append(`iyzi_team_dimensions[${index}][width]`, dim.width);
          formData.append(`iyzi_team_dimensions[${index}][length]`, dim.length);
          formData.append(`iyzi_team_dimensions[${index}][height]`, dim.height);
          formData.append(`iyzi_team_dimensions[${index}][gross]`, dim.gross);
          formData.append(`iyzi_team_dimensions[${index}][qty]`, dim.qty);
        });
      }

      if (data.package_error.sendPackageFiles.length > 0) {
        Array.from(data.package_error.sendPackageFiles).forEach(
          (file, index) => {
            formData.append(`packageFiles[${index}]`, file.file);
          }
        );
        formData.append(
          `package_description`,
          data.package_error.packageDescription
        );
      }

      if (
        data.unavailable_package_error.sendUnavailablePackageFiles.length > 0
      ) {
        Array.from(
          data.unavailable_package_error.sendUnavailablePackageFiles
        ).forEach((file, index) => {
          formData.append(`unavailablePackFiles[${index}]`, file.file);
        });
        formData.append(
          `unavailable_package_description`,
          data.unavailable_package_error.unavailablePackageDescription
        );
      }

      if (data.damaged_parcel_error.sendFiles.length > 0) {
        Array.from(data.damaged_parcel_error.sendFiles).forEach(
          (file, index) => {
            formData.append(`files[${index}]`, file.file);
          }
        );
        formData.append(`description`, data.damaged_parcel_error.description);
      }

      formData.append("missing_docs", data.missing_document_error);
      formData.append("receiver_address_errors", data.missing_address_error);
      formData.append("commoduty_errors", data.proforma_error);
      formData.append("status", data.parcel_status);
      formData.append("arrived_to_office_type", data.delivery_type);
      formData.append("from", data.from);
      formData.append("parcel_ref_no", data.parcel_ref_no);
      formData.append(
        "domestic_awb",
        data.domestic_awb ? data.domestic_awb : ""
      );
      formData.append(
        "domestic_supplier",
        data.domestic_supplier ? data.domestic_supplier : ""
      );

      formData.append("is_manual_domestic", data.is_manual_domestic);
      formData.append("width", data.width);
      formData.append("length", data.length);
      formData.append("height", data.height);
      formData.append("gross", data.gross);

      formData.append("parcel_send_radio", data.parcel_send_radio); //TEKLİ COKLU GONDERİ RADİO SECİMİ//

      formData.append("security_inspection", ffdData?.security_inspection);
      formData.append("operation_step_type", data.operation_step_type);
      formData.append("operation_step", data.operation_step);

      //FİYAT KONTROLÜ YAP //
      if (sendRequest === false) {
        formData.append("check_diff", "1");
      } else {
        formData.delete("check_diff");
      }
      //FİYAT KONTROLÜ YAP //

      return { status: 1, formData };
    }
    return { status: 0, message: validationResponse.message };
  };

  const makeValidations = (data) => {
    let toastStatus = {
      message: "",
      status: false,
    };
    if (ffdData?.operation_step_type === "domestik_tekli_security") {
      if (ffdData?.operation_step === "step_0") {
        if (
          data.width === "" ||
          data.length === "" ||
          data.height === "" ||
          data.gross === ""
        ) {
          toastStatus.message = "Domestik Ölçüler Boş Bırakılamaz!";
          toastStatus.status = true;
        }
      }
    } else if (ffdData?.operation_step_type === "domestik_tekli_securitysiz") {
      if (
        ffdData?.operation_step === "step_0" &&
        !ffdData?.security_inspection
      ) {
        console.log("Sadece Security Durumları Set Edilebilir.");
      } else if (
        ffdData?.operation_step === "step_0" &&
        ffdData?.security_inspection
      ) {
        //IYZI TEAM OLCU KONTROL//
        let index = [];
        let check_dimensions = true;

        if (data.iyzi_team_dimensions.length > 0) {
          for (let dim = 0; dim < data.iyzi_team_dimensions.length; dim++) {
            let item = data.iyzi_team_dimensions[dim];
            if (
              item.width === "" ||
              item.length === "" ||
              item.height === "" ||
              item.gross === "" ||
              item.qty === ""
            ) {
              check_dimensions = false;
              index.push(dim + 1);
            }
          }
          if (!check_dimensions) {
            toastStatus.message = `Iyzi Team Ölçülerinde  ${index.join(
              ","
            )} .satırlarda  Ölçü Boş Bırakılamaz`;
            toastStatus.status = true;
          }
        }

        console.log("SECİLEN SORUN DURUMU : " + selectedProblemStatus);
        //IYZI TEAM OLCU KONTROL//

        if (
          data.width === "" ||
          data.length === "" ||
          data.height === "" ||
          data.gross === ""
        ) {
          toastStatus.message = "Domestik Ölçüler Boş Bırakılamaz!";
          toastStatus.status = true;
        } else if (!check_dimensions) {
          if (
            selectedProblemStatus === "2.05" ||
            selectedProblemStatus === "2.06" ||
            selectedProblemStatus === "2.07" ||
            selectedProblemStatus === "2.08" ||
            selectedProblemStatus === "2.15"
          ) {
            toastStatus = {
              message: "",
              status: false,
            };
          } else {
            console.log("Iyzi Team Ölçüleri Kontrol Edildi ve Eksik Var.");
          }
        } else if (selectedProblemStatus === "") {
          toastStatus.message = "Sorunlu - Sorunsuz Seçimi Yapmalısınız.";
          toastStatus.status = true;
        } else if (
          selectedProblemStatus !== "" &&
          (selectedProblemStatus == "2.04" ||
            selectedProblemStatus === "2.12" ||
            selectedProblemStatus === "2.13")
        ) {
          toastStatus = {
            message: "",
            status: false,
          };
        } else if (
          (data.supplier_id !== 307 ||
            data.supplier_id !== 308 ||
            data.supplier_id !== 311) &&
          (data.tracking_awb === "" ||
            data.tracking_awb === null ||
            data.tracking_awb === "null")
        ) {
          toastStatus.message = "İzleme Takip No Alınmalıdır.";
          toastStatus.status = true;
        }
      }
    }
    return toastStatus;
  };

  const presendValidations = (data) => {
    let toastStatus = {
      status: false,
      message: "",
    };
    if (
      data.parcel_status === "2.08" &&
      data.damaged_parcel_error.sendFiles.length === 0
    ) {
      toastStatus.message =
        "Gönderi Hasarlı Dosya Seçimi ve Açıklamayı Boş Bırakamazsınız.";
      toastStatus.status = true;
      return toastStatus;
    } else if (
      data.parcel_status === "2.11" &&
      data.missing_document_error.length === 0
    ) {
      toastStatus.message = "Eksik Evrak Seçimini Boş Bırakamazsınız.";
      toastStatus.status = true;
      return toastStatus;
    } else if (
      data.parcel_status === "2.12" &&
      data.missing_address_error.length === 0
    ) {
      toastStatus.message = "Adres Hatası Seçimini Boş Bırakamazsınız.";
      toastStatus.status = true;
      return toastStatus;
    } else if (
      data.parcel_status === "2.13" &&
      data.proforma_error.length === 0
    ) {
      toastStatus.message = "Proforma Hatası Seçimini Boş Bırakamazsınız.";
      toastStatus.status = true;
      return toastStatus;
    }

    if (
      data.width === "" ||
      data.length === "" ||
      data.height === "" ||
      data.gross === ""
    ) {
      toastStatus.message = "Ölçüler Boş Bırakılamaz!";
      toastStatus.status = true;
      return toastStatus;
    }

    let check_dimensions = true;
    let check_dimensions2 = true;

    if (data.iyzi_team_dimensions.length > 0) {
      for (let dim = 0; dim < data.iyzi_team_dimensions.length; dim++) {
        let item = data.iyzi_team_dimensions[dim];
        if (
          item.width === "" ||
          item.length === "" ||
          item.height === "" ||
          item.gross === "" ||
          item.qty === ""
        ) {
          check_dimensions2 = false;
          break;
        }
      }
    }

    let index = [];

    if (ffdData?.security_inspection) {
      console.log("iyziTeamDimensions61:", data.iyzi_team_dimensions);
      /// GUVENLİGİ EGER GECERSE İYZİ OLCULERİNİ DİKKATE AL. //

      console.log(ffdData?.temp);
      if (ffdData?.temp === undefined) {
        if (data.iyzi_team_dimensions.length > 0) {
          for (let dim = 0; dim < data.iyzi_team_dimensions.length; dim++) {
            let item = data.iyzi_team_dimensions[dim];
            if (
              item.width === "" ||
              item.length === "" ||
              item.height === "" ||
              item.gross === "" ||
              item.qty === ""
            ) {
              check_dimensions = false;
              index.push(dim + 1);
              break;
            }
          }
        }
        if (!check_dimensions) {
          toastStatus.message = `Iyzi Team Ölçülerinde  ${index.join(
            ","
          )} .satırlarda  Ölçü Boş Bırakılamaz`;
          toastStatus.status = true;
          return toastStatus;
        }
      }
    }

    if (
      data.supplier_id !== 307 ||
      data.supplier_id !== 308 ||
      data.supplier_id !== 311
    ) {
      console.log("Statu:" + ffdData?.status);
      console.log("si:" + ffdData?.security_inspection);
      console.log("chk dim:" + check_dimensions);
      console.log("izleme no:" + data.tracking_awb);
      if (
        ffdData?.status === "2.01" &&
        ffdData?.security_inspection &&
        check_dimensions &&
        ffdData.temp === undefined &&
        (data.tracking_awb === "" ||
          data.tracking_awb === null ||
          data.tracking_awb === "null")
      ) {
        toastStatus.message = "İzleme Takip No Alınmalıdır.";
        toastStatus.status = true;
        return toastStatus;
      }
    }

    console.log(
      "ssorunsuz cond:",
      (data.parcel_status === "2.01" || data.parcel_status === "2.03") &&
        check_dimensions2 &&
        (data.tracking_awb === "" ||
          data.tracking_awb === null ||
          data.tracking_awb === "null")
    );
    if (
      (data.parcel_status === "2.01" || data.parcel_status === "2.03") &&
      check_dimensions2 &&
      (data.tracking_awb !== "" ||
        data.tracking_awb !== null ||
        data.tracking_awb !== "null")
    ) {
      toastStatus.message = "Sorunlu - Sorunsuz Seçimi Yapmalısınız.";
      toastStatus.status = true;
      return toastStatus;
    }

    return toastStatus;
  };

  const setDisableSectionStatus = (section) => {
    if (ffdData?.operation_step_type === "domestik_tekli_security") {
      if (ffdData?.operation_step === "step_0") {
        switch (section) {
          case "domestic_input_section":
            return false;
          case "print_section":
            return false;
          case "iyzi_team_section":
            return true;
          case "security_inspection_button_section":
            return true;
          case "new_tracking_awb_section":
            return true;
          case "handle_complete_button_section":
            return false;
        }
      } else if (ffdData?.operation_step === "step_1") {
        switch (section) {
          case "domestic_input_section":
            return true;
          case "print_section":
            return false;
          case "iyzi_team_section":
            return true;
          case "security_inspection_button_section":
            return false;
          case "new_tracking_awb_section":
            return true;
          case "handle_complete_button_section":
            if (ffdData?.security_inspection) {
              return false;
            }
            return true;
        }
      }
    } else if (ffdData?.operation_step_type === "domestik_tekli_securitysiz") {
      if (ffdData?.operation_step === "step_0") {
        switch (section) {
          case "domestic_input_section":
            return false;
          case "print_section":
            return false;
          case "iyzi_team_section":
            return false;
          case "security_inspection_button_section":
            return true;
          case "new_tracking_awb_section":
            return false;
          case "handle_complete_button_section":
            return false;
        }
      }
    }
    return true;
  };

  const setDisableButtonStatus = () => {
    let check_dimensions = true;
    if (iyziTeamDimensions.length > 0) {
      console.log("Olcu Kontrolü");
      for (let dim = 0; dim < iyziTeamDimensions.length; dim++) {
        let item = iyziTeamDimensions[dim];
        if (
          item.width === "" ||
          item.length === "" ||
          item.height === "" ||
          item.gross === "" ||
          item.qty === ""
        ) {
          check_dimensions = false;
          break;
        }
      }
    }
    //TODO::Sena iyziteam olculeri dolumu bosmu kontrolü icin bu yukardaki kodu kullanabilirsin. 18.09.2023 11:02//

    //(ffdData?.get_parcel_supplier.id ==307 || ffdData?.get_parcel_supplier.id ==308 || ffdData?.get_parcel_supplier.id === 311)//

    if (ffdCheckDom) {
      //DOMESTİK TESLİM İSE KAYDET BUTON KOSULLARI //
      if (fffCheckSingleDom) {
        //TEKLİ DOMESTİK TESLİM KOSULLARI//
        if (
          ffdData?.status === "2.01" &&
          !ffdData?.security_inspection &&
          !checkDimension
        ) {
          //STEP 1
          return false;
        } else if (ffdData?.status === "2.03" && ffdData?.security_inspection) {
          //STEP 2
          return false; //ROYAL MAIL 307-308 USPS-311//
        } else if (ffdData?.status === "2.01" && ffdData?.security_inspection) {
          //STEP 3
          return false;
        } else if (
          ffdData?.status === "2.01" &&
          ffdData?.security_inspection &&
          check_dimensions
        ) {
          //STEP 4
          return false;
        } else if (
          ffdData?.status === "2.01" &&
          ffdData?.security_inspection &&
          check_dimensions &&
          (selectedProblemStatus !== "2.01" || selectedProblemStatus !== "2.03")
        ) {
          //STEP 5
          return false;
        }
        return true;
      }
    }

    console.log("Güvenlik Kontrol Durumu :" + ffdData?.security_inspection);
    console.log("Gönderi Durumu :" + ffdData?.status);
    console.log("Ölçü Kontrol :" + checkDimension);
  };

  const handleCompleteMalKabul = () => {
    const reqStatus = prepareRequestData();

    if (reqStatus.status === 1) {
      axios
        .post(`parcel/operation/set-received`, reqStatus.formData)
        .then((response) => {
          if (response.status === 200) {
            // console.log("responsenowwww",response);
            if (response.data.old_selling_price !== undefined) {
              setCheckPriceDialogData(response.data);
              setCheckPriceDialog(true);
            } else {
              setCheckPriceDialogData({
                old_selling_price: 0,
                new_selling_price: 0,
              });
              setCheckPriceDialog(false);
              setSendRequest(false);
              toast.success("Kayıt Güncellendi.");
              /*/ setTimeout(() => {
                window.location.reload();
              }, 2500);/*/
            }
          }
        })
        .catch((error) => Errors(error));
    } else {
      return toast.error(reqStatus.message);
    }

    // commoduties    -> commoduties
    //gonderi hasarli -> sendFiles,description
    //eksik evrak  -> clonedMissingSelectedOptions
    //adres hatasi  -> cloned
    //proforma hatasi  -> cloned
  };

  const handleRegisterMissingDocument = () => {
    let cloneMissingSelectedOptions = JSON.parse(
      JSON.stringify(selectedMissingDocumentOptions)
    );
    let clonedOtherTags = JSON.parse(JSON.stringify(otherTags));
    cloneMissingSelectedOptions = cloneMissingSelectedOptions
      .filter((f) => !f.extraContent)
      .map((r) => r.label);

    if (clonedOtherTags.length > 0) {
      for (let i = 0; i < clonedOtherTags.length; i++) {
        cloneMissingSelectedOptions.push(clonedOtherTags[i]);
      }
    }
    console.log(otherTags);
    console.log(cloneMissingSelectedOptions);
    setMissingDocumentModal(false);
    setSelectedMissingDocumentOptions(cloneMissingSelectedOptions);
    return console.log("Eksik Evrak Eklendi.");
  };
  const productCurrency = useRecoilValue(productCurrencyState);
  const productValue = useRecoilValue(productValueState);
  const [, setOverPayOpen] = useRecoilState(overPayOpenState);
  const [, setOverPay] = useRecoilState(overPayState);
  const supplierId = useRecoilValue(supplierIdState);
  const [hsCodeError, setHsCodeError] = useState(false);
  const [qtyError, setQtyError] = useState(false);
  const [productDescriptionError, setProductDescriptionError] = useState(false);
  const [currency, setCurrency] = useState(productCurrency);
  const [commoduties, setCommoduties] = useState([
    {
      id: 0,
      title: "",
      sku: "",
      hs_code: "",
      country: "",
      qty: "",
      price: "",
      currency: currency,
      total_price: "",
    },
  ]);

  const productDescriptionBlur = (e) => {
    const value = e.target.value;
    if (value.length < 9) {
      setProductDescriptionError(true);
    } else setProductDescriptionError(false);
  };

  const handleHsCodeBlur = useCallback(
    (e, i) => {
      const values = [...commoduties];
      const inputLength = e.target.value.length;

      if (e.target.value.length < 6) {
        setHsCodeError(true);
      } else {
        setHsCodeError(false);
      }

      if (inputLength >= 6 && inputLength <= 12) {
        values[i][e.target.name] =
          e.target.value + "0".repeat(12 - e.target.value.length);
        setCommoduties(values);
      }
    },
    [commoduties, setCommoduties]
  );

  const handleQtyBlur = useCallback(
    (e, i) => {
      if (e.target.value < 1) {
        setQtyError(true);
      } else {
        setQtyError(false);
      }
    },
    [commoduties, setCommoduties]
  );

  const handleDeleteCommoduties = useCallback(
    (key) => {
      if (commoduties.length > 1) {
        setCommoduties(
          commoduties.filter((commodutie) => commodutie.id !== key)
        );
      }
    },
    [commoduties]
  );

  const handleInputBlur = useCallback(
    (e, i) => {
      const values = [...commoduties];

      // qty ve price değerlerinin her ikisi de dolu mu kontrol edelim
      const isQtyAndPriceFilled = values.every(
        (value) => value.qty !== "" && value.qty !== "0" && value.price !== ""
      );

      // Eğer her ikisi de dolu değilse işlem yapma
      if (!isQtyAndPriceFilled) {
        return;
      }

      // Toplam değeri kontrol et
      const totalValue = values.reduce((acc, curr) => {
        return acc + Number(curr.price) * Number(curr.qty);
      }, 0);

      if (
        totalValue > Number(productValue) &&
        supplierId !== 302 &&
        supplierId !== 337 &&
        supplierId !== 338 &&
        supplierId !== 339
      ) {
        setOverPayOpen(true);
        setOverPay(true); // overpay durumunda setOverPay'i de true yap
      } else {
        setOverPay(false);
      }
      setCommoduties(values);
    },
    [commoduties, productValue, setOverPay, setOverPayOpen, supplierId]
  );
  const [dimensions, setDimensions] = useState([
    { width: "", long: "", height: "", gross: "", qty: "", id: 0 },
  ]);

  const [title, setTitle] = useState();
  const [country, setCountry] = useState();

  const handleInputChange2 = useCallback(
    (e, i) => {
      const values = [...commoduties];

      if (e.target.name === "qty") {
        console.log(e.target.value);
        if (!/^\d*$/.test(e.target.value)) {
          return;
        }
        const updatedValue = { ...values[i] };
        updatedValue[e.target.name] = Number(e.target.value);
        values[i] = updatedValue;
      } else if (e.target.name === "price") {
        let priceValue = e.target.value.replace(/,/g, ".");
        // Virgül veya nokta sonrası en fazla iki hane olabilir
        const regex = /^\d*(\.|,)?\d{0,2}$/;
        // Noktadan veya virgülden önce sadece tek bir 0 olabilir
        const invalidZeroFormat = /^0{2,}\.\d*$/;
        // Giriş geçerli değilse veya geçersiz 0 formatına sahipse dönüş yap
        if (!regex.test(priceValue) || invalidZeroFormat.test(priceValue))
          return;
        const updatedValue = { ...values[i] };
        updatedValue[e.target.name] = priceValue;
        values[i] = updatedValue;
      } else if (e.target.name === "country") {
        const code = countriesDetailed.find(
          (country) =>
            `${country.country_name_tr}, ${country.country_name_en}` ===
            e.target.value
        ).iso_code;
        setCountry(e.target.value);
        values[i][e.target.name] = code;
      } else if (e.target.name === "title") {
        values[i][e.target.name] = e.target.value
          .toUpperCase()
          .replace(/[^A-Z\s-]/g, "")
          .replace(/\s{2,}/g, " ");

        if (e.target.value.length > 8) {
          setProductDescriptionError(false);
        }
      } else if (e.target.name === "hs_code") {
        if (!/^\d*$/.test(e.target.value) || /^0$/.test(e.target.value)) {
          return;
        }
        if (e.target.value.length >= 6) {
          setHsCodeError(false);
        }
        values[i][e.target.name] = e.target.value;
      } else {
        values[i][e.target.name] = e.target.value;
      }

      values[i].total_price = values[i].qty * values[i].price;
      setCommoduties(values);
    },
    [commoduties]
  );
  const handleAddInput = useCallback(() => {
    if (
      commoduties[commoduties.length - 1].title === "" ||
      //commoduties[commoduties.length - 1].sku === "" ||
      commoduties[commoduties.length - 1].hs_code === "" ||
      commoduties[commoduties.length - 1].country === "" ||
      commoduties[commoduties.length - 1].price === "" ||
      commoduties[commoduties.length - 1].qty === ""
    ) {
      return false;
    } else {
      setCommoduties([
        ...commoduties,
        {
          id: commoduties.length,
          title: "",
          sku: "",
          hs_code: "",
          country: "",
          qty: "",
          price: "",
          currency: currency,
          total_price: "",
        },
      ]);
    }
  }, [commoduties, setCommoduties, currency]);
  const handleInputChange = useCallback(
    (e, i) => {
      const values = [...dimensions];
      let fvalue = e.target.value.replace(/,/g, ".");
      if (e.target.name === "qty") {
        values[i] = {
          ...values[i],
          qty: fvalue.replace(/[^0-9]/g, ""),
        };
      } else {
        const value = fvalue.replace(/[^0-9.]|(?<=\..*)\.|^0{2,}(?=[\.])/g, "");
        const regex = /^\d+(\.\d{0,2})?$/;

        if (value.startsWith(".") || (!regex.test(value) && value !== "")) {
          return;
        } else {
          if (e.target.name === "gross") {
            let newValue = value.replace(/^0{2,}/, "0");
            if (Number(fvalue) > 300) {
              newValue = "300";
            }
            values[i] = {
              ...values[i],
              gross: newValue,
            };
          } else {
            values[i] = {
              ...values[i],
              [e.target.name]: value,
            };
          }
        }
      }
      setDimensions(values);
    },
    [dimensions, setDimensions]
  );
  console.log(ffdData);

  const [warring, setWarring] = useState(false);
  const [recieverWarring, setRecieverWarring] = useState(false);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  //_________________________Gönderici Adresi____________________________________
  const [checkAddresesUpdate, setCheckAddresesUpdate] = useState(false);
  const openUpdateModal = () => {
    setCheckAddresesUpdate(true);
  };
  const handleCloseWarring = useCallback(() => {
    setContactName("");
    setCompanyName("");
    setAddress1("");
    setAddress2("");
    setAddress3("");
    setPostalCode("");
    setState("");
    setTown("");
    setCity("");
    setPhone("");
    setEmail("");
    setStateCode("");
    setCheckAddresesUpdate(false);
    setWarring(false);
  }, [setCheckAddresesUpdate]);

  const showWarring = (e) => {
    e.preventDefault();
    setWarring(true);
  };
  const showRecieverWarring = (e) => {
    e.preventDefault();
    setRecieverWarring(true);
  };
  const [contactName, setContactName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [state, setState] = useState("");
  const [town, setTown] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [zipCodeError, setZipCodeError] = useState(false);
  const handleSetValues = useCallback(() => {
    setContactName(ffdData?.get_parcel_sender_address?.contact_name);
    setCompanyName(ffdData?.get_parcel_sender_address?.company_name);
    setAddress1(ffdData?.get_parcel_sender_address?.address1);
    setAddress2(ffdData?.get_parcel_sender_address?.address2);
    setAddress3(ffdData?.get_parcel_sender_address?.address3);
    setPostalCode(ffdData?.get_parcel_sender_address?.postal_code);
    setState(ffdData?.get_parcel_sender_address?.state);
    setTown(ffdData?.get_parcel_sender_address?.town);
    setCity(ffdData?.get_parcel_sender_address?.city);
    setPhone(ffdData?.get_parcel_sender_address?.phone);
    setEmail(ffdData?.get_parcel_sender_address?.email);
    setStateCode(ffdData?.get_parcel_sender_address?.state_code);
  }, [ffdData]);
  useEffect(() => {
    if (checkAddresesUpdate) {
      handleSetValues();
    }
  }, [handleSetValues, checkAddresesUpdate]);
  const addressRegisterHandler = async () => {
    setWarring(false);
    setCheckAddresesUpdate(false);
    console.log("contactName", contactName);
    const postData = {
      contact_name: contactName,
      company_name: companyName,
      address1,
      address2,
      address3,
      email,
      phone,
      town,
      city,
      state,
      country,
      postal_code: postalCode,
      specify: 1,
    };

    await axios
      .post(
        "member-address/update/" + ffdData?.get_parcel_sender_address.id,
        postData
      )
      .then(async (res) => {
        console.log("response:", res);
        if (res.data.status === 200) {
          setContactName(res.data.data.contact_name);
          setCompanyName(res.data.data.company_name);
          setAddress1(res.data.data.address1);
          setAddress2(res.data.data.address2);
          setAddress3(res.data.data.address3);
          setPostalCode(res.data.data.postal_code);
          setState(res.data.data.state);
          setTown(res.data.data.town);
          setCity(res.data.data.city);
          setPhone(res.data.data.phone);
          setEmail(res.data.data.email);
          setStateCode(res.data.data.state_code);
          setCheckAddresesUpdate(false);
          setWarring(false);
          const respUpdated = await axios.post(
            "parcel/update-parcel-addresses",
            {
              to: "sender",
              parcel_id: ffdData?.id,
              id: res.data.data.id,
            }
          );
          let cloned = JSON.parse(JSON.stringify(respUpdated.data.data[0]));
          if (res.data.changed) {
            // ADRESTE DEGİSİKLİK VARSA İZLEME NUMARASI AL MESAJINI GOSTER
            const r = await axios.post(
              "parcel/clear-tracking-awb/" + ffdData?.id
            );
            if (r.data.status === 200) {
              cloned.tracking_awb = "";
            }
          }
          setFfdData(cloned);
          return toast.success(res.data.message);
        }
      })
      .catch((error) => Errors(error));
  };
  const handleClose = useCallback(() => {
    setContactName("");
    setCompanyName("");
    setAddress1("");
    setAddress2("");
    setAddress3("");
    setPostalCode("");
    setState("");
    setTown("");
    setCity("");
    setPhone("");
    setEmail("");
    setStateCode("");
    setCheckAddresesUpdate(false);
  }, [setCheckAddresesUpdate]);
  const [zipLoading, setZipLoading] = useState(false);
  const [zipIsDone, setZipIsDone] = useState(null);
  const zipCodeHandler = useCallback(async () => {
    if (postalCode.length === 0) {
      setZipIsDone(null);
    } else {
      setZipLoading(true);
      setZipIsDone(null);
      await axios
        .post("get-geo-details", {
          postalcode: postalCode,
          country: ffdData?.get_parcel_sender_address?.country,
        })
        .then((res) => {
          setZipLoading(false);
          if (res.data.data.postalcodes.length === 0) {
            setZipIsDone(false);
            setZipCodeError(false);
            setStateCode("");
          } else if (res.data.data.postalcodes.length > 1) {
            setPostalCode("");
            setStateCode("");
            setZipCodeError(true);
            setZipIsDone(false);
          } else {
            setZipIsDone(true);
            setState(res.data.data.postalcodes[0].placeName);
            setTown(
              res.data.data.postalcodes[0].adminName2
                ? res.data.data.postalcodes[0].adminName2
                : res.data.data.postalcodes[0].placeName
            );
            setCity(res.data.data.postalcodes[0].adminName1);
            setStateCode(res.data.data.postalcodes[0].adminCode1);
            setZipCodeError(false);
          }
        })
        .catch((error) => {
          Errors(error);
          setZipLoading(false);
          setZipIsDone(false);
        });
    }
  }, [postalCode, ffdData]);
  //__________Alıcı Adresi________
  const [checkRecieverAddresesUpdate, setCheckRecieverAddresesUpdate] =
    useState(false);
  const openRecieverUpdateModal = () => {
    setCheckRecieverAddresesUpdate(true);
  };
  const [recevierContactName, setRecevierContactName] = useState("");
  const [recevierCompanyName, setRecevierCompanyName] = useState("");
  const [recevierAddress1, setRecevierAddress1] = useState("");
  const [recevierAddress2, setRecevierAddress2] = useState("");
  const [recevierAddress3, setRecevierAddress3] = useState("");
  const [recevierPostalCode, setRecevierPostalCode] = useState("");
  const [recevierState, setRecevierState] = useState("");
  const [recevierTown, setRecevierTown] = useState("");
  const [recevierCity, setRecevierCity] = useState("");
  const [recevierPhone, setRecevierPhone] = useState("");
  const [recevierEmail, setRecevierEmail] = useState("");
  const [recevierStateCode, setRecevierStateCode] = useState("");
  const handleRecevierClose = useCallback(() => {
    setRecevierContactName("");
    setRecevierCompanyName("");
    setRecevierAddress1("");
    setRecevierAddress2("");
    setRecevierAddress3("");
    setRecevierPostalCode("");
    setRecevierState("");
    setRecevierTown("");
    setRecevierCity("");
    setRecevierPhone("");
    setRecevierEmail("");
    setRecevierStateCode("");
    setCheckRecieverAddresesUpdate(false);
  }, [setCheckRecieverAddresesUpdate]);
  const handleRecevierCloseWarring = useCallback(() => {
    setRecevierContactName("");
    setRecevierCompanyName("");
    setRecevierAddress1("");
    setRecevierAddress2("");
    setRecevierAddress3("");
    setRecevierPostalCode("");
    setRecevierState("");
    setRecevierTown("");
    setRecevierCity("");
    setRecevierPhone("");
    setRecevierEmail("");
    setRecevierStateCode("");
    setCheckRecieverAddresesUpdate(false);
    setRecieverWarring(false);
  }, [setCheckRecieverAddresesUpdate]);
  const addressRecieverUpdate = async (e) => {
    setRecieverWarring(false);
    setCheckRecieverAddresesUpdate(false);
    e.preventDefault();
    console.log("recevierContactName", recevierContactName);
    const postData = {
      contact_name: recevierContactName,
      company_name: recevierCompanyName,
      address1: recevierAddress1,
      address2: recevierAddress2,
      address3: recevierAddress3,
      email: recevierEmail,
      phone: recevierPhone,
      town: recevierTown,
      city: recevierCity,
      state: recevierState,
      postal_code: recevierPostalCode,
      specify: 1,
    };
    console.log("postDatapostDatapostData", postData);
    await axios
      .post(
        "member-address/update/" +
          ffdData?.get_parcel_receiver_address.id,
        postData
      )
      .then(async (res) => {
        console.log("response:", res);
        if (res.data.status === 200) {
          setRecevierContactName(res.data.data.contact_name);
          setRecevierCompanyName(res.data.data.company_name);
          setRecevierAddress1(res.data.data.address1);
          setRecevierAddress2(res.data.data.address2);
          setRecevierAddress3(res.data.data.address3);
          setRecevierPostalCode(res.data.data.postal_code);
          setRecevierState(res.data.data.state);
          setRecevierTown(res.data.data.town);
          setRecevierCity(res.data.data.city);
          setRecevierPhone(res.data.data.phone);
          setRecevierEmail(res.data.data.email);
          setRecevierStateCode(res.data.data.state_code);
          setCheckRecieverAddresesUpdate(false);
          setRecieverWarring(false);
          const respUpdated = await axios.post(
            "parcel/update-parcel-addresses",
            {
              to: "receiver",
              parcel_id: ffdData?.id,
              id: res.data.data.id,
            }
          );
          let cloned = JSON.parse(JSON.stringify(respUpdated.data.data[0]));
          if (res.data.changed) {
            // ADRESTE DEGİSİKLİK VARSA İZLEME NUMARASI AL MESAJINI GOSTER
            const r = await axios.post(
              "parcel/clear-tracking-awb/" + ffdData?.id
            );
            if (r.data.status === 200) {
              cloned.tracking_awb = "";
            }
          }
          setFfdData(cloned);
          return toast.success(res.data.message);
        }
      })
      .catch((error) => Errors(error));
  };
  const handleRecieverSetValues = useCallback(() => {
    setRecevierContactName(ffdData?.get_parcel_receiver_address?.contact_name);
    setRecevierCompanyName(ffdData?.get_parcel_receiver_address?.company_name);
    setRecevierAddress1(ffdData?.get_parcel_receiver_address?.address1);
    setRecevierAddress2(ffdData?.get_parcel_receiver_address?.address2);
    setRecevierAddress3(ffdData?.get_parcel_receiver_address?.address3);
    setRecevierPostalCode(ffdData?.get_parcel_receiver_address?.postal_code);
    setRecevierState(ffdData?.get_parcel_receiver_address?.state);
    setRecevierTown(ffdData?.get_parcel_receiver_address?.town);
    setRecevierCity(ffdData?.get_parcel_receiver_address?.city);
    setRecevierPhone(ffdData?.get_parcel_receiver_address?.phone);
    setRecevierEmail(ffdData?.get_parcel_receiver_address?.email);
    setRecevierStateCode(ffdData?.get_parcel_receiver_address?.state_code);
  }, [ffdData]);
  useEffect(() => {
    if (checkRecieverAddresesUpdate) {
      handleRecieverSetValues();
    }
  }, [handleRecieverSetValues, checkRecieverAddresesUpdate]);
  //____________PAK MALiYETİ__________
  const [pakCheck, setPakCheck] = useState(false);
  const handlCheckPak = () => {
    setPakCheck(!pakCheck);
  };
  const [securityInspectionCheck, setSecurityInsPectionCheck] = useState(false);
  const [successCheck, setSuccessCheck] = useState(false);

  const [cStatus, setClickedStatus] = useState("");

  const [courierStatus, setcourierStatusState] =
    useRecoilState(courierStatusState);

  const [lastSelectedStatus, setLastSelectedStatus] = useState(null);

  const [statusChangeModalOpen, setStatusChangeModalOpenState] = useRecoilState(
    statusChangeModalOpenState
  );

  const handleStatusModalOpen = () => {
    setStatusChangeModalOpenState(true);
  };

  const handleStatusModalClose = () => {
    setStatusChangeModalOpenState(false);
  };

  // const handleCourierModalOpen = () => {
  //   setcourierStatusState(true);
  // };

  // const handleCourierModalClose = () => {
  //   setcourierStatusState(false);
  // };

  useEffect(() => {
    if (
      ffdData.status === "0.05" ||
      ffdData.status === "0.06" ||
      ffdData.status === "2.21" ||
      ffdData.status === "3.02" ||
      ffdData.status === "4.08"
    ) {
      setStatusChangeModalOpenState(true);
    } else {
      setStatusChangeModalOpenState(false);
    }
  }, [ffdData.status]);

  // useEffect(() => {
  //   if (
  //       ffdData.status === "1.00"
  //   ) {
  //     handleCourierModalOpen();
  //   } else {
  //     handleCourierModalClose();
  //   }
  // }, [ffdData.status]);

  const statusInfoTitle =
    ffdData.status === "2.21" ||
    ffdData.status === "3.02" ||
    ffdData.status === "4.08"
      ? "Girmiş olduğunuz gönderi iptal aşamasında olan bir gönderidir."
      : "Girmiş olduğunuz gönderi silinmiş ya da iptal durumunda olan bir gönderidir.";
  const statusInfoContent =
    "Gönderi statüsünü değiştirmek için aşağıdan statü seçebilirsiniz.";
  const shipmentID = ffdData?.id;

  return (
    <div className="operation-cargo-dom-resp-wrapper">
      <div className="operation-cargo-dom-resp-tickets-gruop">
        <div className="operation-cargo-dom-resp-tickets">
          <Alert
            severity="info"
            className="text-center"
            style={{
              width: "100%",
              backgroundColor: " #E5F6FD",
              color: " #014361",
            }}
          >
            Gönderinin Son Durumu :{" "}
            {ffdData?.status_text === ""
              ? ffdData.status
              : ffdData?.status_text}
          </Alert>
        </div>
        <div className="operation-cargo-dom-resp-tickets">
          <div
            severity="info"
            className="text-center"
            style={{
              width: "100%",

              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              className="iyzi-button-blue"
              onClick={handleStatusModalOpen}
              disabled={
                ffdData.status !== "0.05" ||
                ffdData.status !== "0.06" ||
                ffdData.status !== "2.21" ||
                ffdData.status !== "3.02" ||
                ffdData.status !== "4.08"
              }
              sx={{
                textTransform: "capitalize",
                padding: "10px",
                "&:disabled": {
                  opacity: 0.3,
                },
              }}
            >
              Gönderi Statüsünü Değiştir
            </Button>
          </div>
        </div>

        <div className="operation-cargo-dom-resp-tickets">
          <Alert
            severity="info"
            className="text-center"
            style={{
              width: "100%",
              backgroundColor: " #E5F6FD",
              color: " #014361",
            }}
          >
            Müşteri Bugüne Kadar Toplam : {ffdData?.total_parcel_count} Adet
            Gönderi Yapmıştır.
          </Alert>
        </div>
      </div>
      <Dialog open={statusChangeModalOpen} onClose={handleStatusModalClose}>
        <DialogContent>
          <StatusChangeModal
            title={statusInfoTitle}
            content={statusInfoContent}
            id={shipmentID}
          />
        </DialogContent>
      </Dialog>
      {/* <Dialog open={courierStatus} onClose={handleCourierModalClose}>
        <DialogContent>
          Girilen gönderi kuryeden teslim alınan bir gönderidir. Yük kuryeden teslim seçeneği ile aratmanız gerekmektedir.
        </DialogContent>
      </Dialog> */}
      <div className="operation-cargo-dom-resp-general-info">
        <div className="operation-cargo-dom-resp-info">
          <div className="operation-cargo-dom-resp-info-top">
            <div className="operation-cargo-dom-resp-info-sender">
              <div style={{ display: " flex", flexDirection: " column" }}>
                <Tooltip title={ffdData?.sender_name}>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "215px",
                    }}
                  >
                    <b>Gönderici:</b>
                    {ffdData?.sender_name}
                  </Typography>
                </Tooltip>
                <Tooltip title={ffdData?.get_parcel_sender_address.address1}>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "215px",
                    }}
                  >
                    <b>Adresi:</b>
                    {ffdData?.get_parcel_sender_address.address1}
                  </Typography>
                </Tooltip>

                <Tooltip title={ffdData?.get_parcel_sender_address.phone}>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "215px",
                    }}
                  >
                    <b>Telefon Numarası:</b>
                    {ffdData?.get_parcel_sender_address.phone}
                  </Typography>
                </Tooltip>
                <Tooltip title={ffdData?.get_parcel_sender_address.email}>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "215px",
                    }}
                  >
                   <b>E-mail:</b>
                    {ffdData?.get_parcel_sender_address.email}
                  </Typography>
                </Tooltip>
              </div>
              <div style={{ justifyContent: " end", display: "flex" }}>
                <Button
                  className="iyzi-button-blue button-fix"
                  onClick={openUpdateModal}
                  disabled={!ffdData.security_inspection}
                >
                  Düzenle
                </Button>
              </div>
            </div>
            <div className="operation-cargo-dom-resp-info-reciever">
              <div style={{ display: " flex", flexDirection: " column" }}>
                <Tooltip title={ffdData?.receiver_name}>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "215px",
                    }}
                  >
                    <b>Alıcı:</b> 
                    {ffdData?.receiver_name}
                  </Typography>
                </Tooltip>
                <Tooltip title={ffdData?.get_parcel_receiver_address.address1}>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "215px",
                    }}
                  >
                    <b>Adresi:</b> 
                    {ffdData?.get_parcel_receiver_address.address1}
                  </Typography>
                </Tooltip>
                <Tooltip title={ffdData?.get_parcel_receiver_address.phone}>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "215px",
                    }}
                  >
                    <b>Telefon Numarası:</b> 
                    {ffdData?.get_parcel_receiver_address.phone}
                  </Typography>
                </Tooltip>
                <Tooltip title={ffdData?.get_parcel_receiver_address.email}>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "215px",
                    }}
                  >
                   <b>E-mail:</b> 
                    {ffdData?.get_parcel_receiver_address.email}
                  </Typography>
                </Tooltip>
              </div>
              <div style={{ justifyContent: " end", display: "flex" }}>
                <Button
                  className="iyzi-button-blue button-fix"
                  onClick={openRecieverUpdateModal}
                  disabled={!ffdData.security_inspection}
                >
                  Düzenle
                </Button>
              </div>
            </div>
            <div className="operation-cargo-dom-resp-info-carrying">
              <Typography
                sx={{
                  backgroundColor:
                    ffdData.ddp === "1"
                      ? "var(--shipmentGreen) !important"
                      : " var(--shipmentRed) !important",
                  color: "var(--pastelGreen) !important",
                  borderRadius: " var(--wrapperBorderRadius) !important",
                  border: "1px solid var(--pastelGreen) !important",
                  boxShadow: "var(--shadow)",
                }}
              >
                YDG
              </Typography>
              <Typography
                sx={{
                  backgroundColor:
                    ffdData.insurance === "1"
                      ? "var(--shipmentGreen) !important"
                      : " var(--shipmentRed) !important",
                  color: "var(--pastelGreen) !important",
                  borderRadius: " var(--wrapperBorderRadius) !important",
                  border: "1px solid var(--pastelGreen) !important",
                  boxShadow: "var(--shadow)",
                }}
              >
                SİGORTA
              </Typography>
              <Typography
                sx={{
                  backgroundColor:
                    ffdData.parcel_type === "3"
                      ? "var(--shipmentGreen) !important"
                      : " var(--shipmentRed) !important",
                  color: "var(--pastelGreen) !important",
                  borderRadius: " var(--wrapperBorderRadius) !important",
                  border: "1px solid var(--pastelGreen) !important",
                  boxShadow: "var(--shadow)",
                }}
              >
                ETGB
              </Typography>
            </div>
          </div>
          <div className="operation-cargo-dom-resp-info-bottom">
            <div>
              <Box className="steps-where-to">
                <Box className="to-plane">
                  {countriesDetailed.find(
                    (e) => e.iso_code === ffdData.sender_country
                  ).country_name_tr === "Turkey"
                    ? "TÜRKİYE"
                    : countriesDetailed
                        .find((e) => e.iso_code === ffdData.sender_country)
                        ?.country_name_tr?.toUpperCase()}
                  <div className="plane-flag">
                    <Tooltip
                      title={countriesDetailed
                        .find((e) => e.iso_code === ffdData.sender_country)
                        ?.country_name_tr?.toUpperCase()}
                    >
                      <span>
                        <CircleFlag
                          countryCode={countriesDetailed
                            .find((e) => e.iso_code === ffdData.sender_country)
                            ?.iso_code?.toLowerCase()}
                          height="24"
                        />
                      </span>
                    </Tooltip>
                    <FlightTakeoffOutlinedIcon id="take-off" />
                  </div>
                </Box>

                <div className="list-item-supplier">
                  <Tooltip title={ffdData.get_parcel_supplier?.supplier_name}>
                    <Avatar
                      className="list-item-supplier-img"
                      src={`/images/${ffdData.get_parcel_supplier?.supplier_logo}`}
                    />
                  </Tooltip>
                </div>
                <Box className="to-plane">
                  {countriesDetailed
                    .find((e) => e.iso_code === ffdData.receiver_country)
                    ?.country_name_tr?.toUpperCase()}
                  <div className="plane-flag">
                    <FlightLandOutlinedIcon id="land" />
                    <Tooltip
                      title={countriesDetailed
                        .find((e) => e.iso_code === ffdData.receiver_country)
                        ?.country_name_tr?.toUpperCase()}
                    >
                      <span>
                        <CircleFlag
                          countryCode={countriesDetailed
                            .find(
                              (e) => e.iso_code === ffdData.receiver_country
                            )
                            ?.iso_code?.toLowerCase()}
                          height="24"
                        />
                      </span>
                    </Tooltip>
                  </div>
                </Box>
              </Box>
            </div>
            <div>
              Gönderi No
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: 700,
                  textAlign: "center",
                  width: "180px",
                  height: "20px",
                  color: " #6A707E",
                  backgroundColor: " #F3F6F9",
                  marginTop: "10px",
                }}
              >
                {ffdData.tracking_awb}
              </div>
            </div>
            <div>
              <Box className="to-integration">
                {ffdData.integration_parcel_id !== "0" && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                      <img className="logo" src={miniLogo} alt="" />
                      Etsy
                    </div>
                    <div>2410218693</div>
                  </div>
                )}
              </Box>
            </div>
          </div>
        </div>
        <div className="operation-cargo-dom-resp-pdf">
          <div className="resp-card-wrapper">
            <div
              style={{
                display: " flex",
                justifyContent: " end",
                marginBottom: "10px",
              }}
            >
              <Button
                disabled={setDisableSectionStatus("print_section")}
                onClick={printDocument}
                className="iyzi-button-blue"
                style={{ marginLeft: "3px" }}
              >
                Yazdır
              </Button>
            </div>
            <div style={{ display: " flex", justifyContent: "center" }}>
              <PDFReader base64String={ffdBase64} />
            </div>
            {/*/ <div className="resp-card-container">
                <div className="resp-card-number">201178</div>
                <div className="resp-card-page">1/1</div>
                <div className="resp-card-barcode">
                  <img src="/images/karekod.png" alt="Karekod" />
                  <Typography>TR81539995GB</Typography>
                  <Typography>274737572834</Typography>
                </div>
                <Divider className="resp-card-divider" />
                <div className="resp-card-info">
                  <div className="resp-card-saler">
                    <Typography>SİNEM ŞAHİN</Typography>
                  </div>
                  <div className="resp-card-customer">
                    <Typography>Chris D Ross</Typography>
                    <Typography>FEDEX - X</Typography>
                  </div>
                </div>
              </div> /*/}
          </div>
        </div>
      </div>
      <div className="operation-cargo-dom-resp-general-note-admin">
        <Card
          sx={{
            maxWidth: "1100px",
            width: "100%",
            backgroundColor: " var(--lighterBg)",
            padding: "0",
            borderRadius: "var(--wrapperBorderRadius)",
            boxShadow: "var(--shadow)",
            minHeight: "29px",
          }}
        >
          <CardHeader
            onClick={() => setOpen(!open)}
            title={
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                  letterSpacing: " 0px",
                  marginLeft: "20px",
                }}
              >
                Admin Notu
              </span>
            }
            action={
              <IconButton
                onClick={() => setOpen(!open)}
                aria-label="expand"
                size="small"
                sx={{ padding: "0", margin: "8px" }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            }
            sx={{ minHeight: "10px", padding: "0", cursor: "pointer" }}
          ></CardHeader>
          <div
            style={{
              backgroundColor: "rgba(211,211,211,0.4)",
            }}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <CardContent>
                <Container
                  sx={{
                    lineHeight: 2,
                  }}
                >
                  {ffdData.get_parcel_notes.length !== 0 &&
                    ffdData.get_parcel_notes
                      .filter((e) => e.type === "2")
                      .map((e) => (
                        <Box key={e.id} className="steps-note">
                          <span>NOT :</span> {e.description}
                        </Box>
                      ))}
                  {/* Proforma hataları :
                  {ffdData.commoduty_errors &&
                  ffdData.commoduty_errors !== null &&
                  ffdData.commoduty_errors !== ""
                    ? ffdData.commoduty_errors.split(",").map((e) => (
                        <Box key={e} className="steps-note">
                          {e}
                        </Box>
                      ))
                    : null} */}
                </Container>
              </CardContent>
            </Collapse>
          </div>
        </Card>
      </div>
      <div className="operation-cargo-dom-resp-general-note-shipment">
        <Card
          sx={{
            maxWidth: "1100px",
            width: "100%",
            backgroundColor: " var(--lighterBg)",
            padding: "0",
            borderRadius: "var(--wrapperBorderRadius)",
            boxShadow: "var(--shadow)",
            minHeight: "29px",
          }}
        >
          <CardHeader
            onClick={() => setOpen1(!open1)}
            title={
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                  letterSpacing: " 0px",
                  marginLeft: "20px",
                }}
              >
                Müşteri Notu
              </span>
            }
            action={
              <IconButton
                aria-label="expand"
                size="small"
                sx={{ padding: "0", margin: "8px" }}
              >
                {open1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            }
            sx={{ minHeight: "10px", padding: "0", cursor: "pointer" }}
          ></CardHeader>
          <div
            style={{
              backgroundColor: "rgba(211,211,211,0.4)",
            }}
          >
            <Collapse in={open1} timeout="auto" unmountOnExit>
              <CardContent>
                <Container
                  sx={{
                    lineHeight: 2,
                  }}
                >
                  {ffdData.get_parcel_notes.length !== 0 &&
                    ffdData.get_parcel_notes
                      .filter((e) => e.type === "1")
                      .map((e) => (
                        <Box key={e.id} className="steps-note">
                          <span>NOT: </span> {e.description}
                        </Box>
                      ))}
                </Container>
              </CardContent>
            </Collapse>
          </div>
        </Card>
      </div>
      <div className="operation-cargo-dom-resp-general-missing-document">
        <Card
          sx={{
            maxWidth: "1100px",
            width: "100%",
            backgroundColor: " var(--lighterBg)",
            padding: "0",
            borderRadius: "var(--wrapperBorderRadius)",
            boxShadow: "var(--shadow)",
          }}
        >
          <CardHeader
            onClick={() => setOpen2(!open2)}
            title={
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                  letterSpacing: " 0px",
                  marginLeft: "20px",
                }}
              >
                Eksik Evrak Hataları
              </span>
            }
            action={
              <IconButton
                aria-label="expand"
                size="small"
                sx={{ padding: "0", margin: "8px" }}
              >
                {open2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            }
            sx={{ minHeight: "10px", padding: "0", cursor: "pointer" }}
          ></CardHeader>
          <div
            style={{
              backgroundColor: "rgba(211,211,211,0.4)",
            }}
          >
            <Collapse in={open2} timeout="auto" unmountOnExit>
              <CardContent>
                <Container
                  sx={{
                    lineHeight: 2,
                  }}
                >
                  {ffdData.missing_docs &&
                  ffdData.missing_docs !== null &&
                  ffdData.missing_docs !== ""
                    ? ffdData.missing_docs.split(",").map((e) => (
                        <Box key={e} className="steps-note">
                          {e}
                        </Box>
                      ))
                    : null}
                </Container>
              </CardContent>
            </Collapse>
          </div>
        </Card>
      </div>
      <div className="operation-cargo-dom-resp-general-reciver-address-error">
        <Card
          sx={{
            maxWidth: "1100px",
            width: "100%",
            backgroundColor: " var(--lighterBg)",
            padding: "0",
            borderRadius: "var(--wrapperBorderRadius)",
            boxShadow: "var(--shadow)",
          }}
        >
          <CardHeader
            onClick={() => setOpen3(!open3)}
            title={
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                  letterSpacing: " 0px",
                  marginLeft: "20px",
                }}
              >
                Alıcı Adres Hataları
              </span>
            }
            action={
              <IconButton
                aria-label="expand"
                size="small"
                sx={{ padding: "0", margin: "8px" }}
              >
                {open3 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            }
            sx={{ minHeight: "10px", padding: "0", cursor: "pointer" }}
          ></CardHeader>
          <div
            style={{
              backgroundColor: "rgba(211,211,211,0.4)",
            }}
          >
            <Collapse in={open3} timeout="auto" unmountOnExit>
              <CardContent>
                <Container
                  sx={{
                    lineHeight: 2,
                  }}
                >
                  {ffdData.receiver_address_errors &&
                  ffdData.receiver_address_errors !== null &&
                  ffdData.receiver_address_errors !== ""
                    ? ffdData.receiver_address_errors.split(",").map((e) => (
                        <Box key={e} className="steps-note">
                          {e}
                        </Box>
                      ))
                    : null}
                </Container>
              </CardContent>
            </Collapse>
          </div>
        </Card>
      </div>
      <div className="operation-cargo-dom-resp-domestic-dimension">
        <div className="title">Domestic Ölçü Ekle</div>
        <div className="operation-manuel-cargo-item">
          <TextField
            disabled={setDisableSectionStatus("domestic_input_section")}
            value={domEn}
            onChange={handleDecimalChange}
            name="domestic_en"
            label="Domestik En"
            size="small"
            required
            fullWidth
          />
          <TextField
            disabled={setDisableSectionStatus("domestic_input_section")}
            value={domBoy}
            onChange={handleDecimalChange}
            name="domestic_boy"
            label="Domestik Boy"
            size="small"
            required
            fullWidth
          />
          <TextField
            disabled={setDisableSectionStatus("domestic_input_section")}
            value={domYuk}
            onChange={handleDecimalChange}
            name="domestic_yuk"
            label="Domestik Yükseklik"
            size="small"
            required
            fullWidth
          />
          <TextField
            disabled={setDisableSectionStatus("domestic_input_section")}
            value={domGw}
            onChange={handleDecimalChange}
            name="domestic_gw"
            label="Domestik GW"
            size="small"
            required
            fullWidth
          />
        </div>
      </div>
      <div className="operation-cargo-dom-resp-weight">
        <div className="operation-cargo-dom-resp-wight-shipment">
          <div className="title">Üye Tarafından Beyan Edilen Kilo</div>
          {ffdData?.get_parcel_dimensions
            .filter((item) => item.status === "1") // status değeri "1" olanları al
            .map((item, index) => (
              <OperationDimensionMember key={index} item={item} />
            ))}
          <div className="operation-cargo-dom-resp-wight-shipment-group-bottom">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "#6a707e",
                gap: "5px",
              }}
              className="operation-cargo-dom-resp-wight-shipment-bottom-side"
            >
              <div style={{ fontSize: "7px" }} className="little-title">
                Toplam Ücret Ağırlığı
              </div>
              <div
                style={{
                  backgroundColor: "#e9ecef",
                  width: "124px",
                  height: "20px",
                  fontSize: "10px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="operation-cargo-dom-resp-wight-shipment-item-bottom"
              >
                {
                  /*ffdData?.get_parcel_dimensions
                  .filter((item) => item.status === "1") // status değeri "1"
                  .reduce(
                    (acc, item) => Number(acc) + Number(item.total_cw),
                    0
                  )*/
                  Number(ffdData.cw) + " "
                }
                kg
              </div>
            </div>
          </div>
        </div>
        <div className="operation-cargo-dom-resp-weight-iyziteam">
          <div className="title">İyziTeam Tarafından Beyan Edilen Kilo</div>
          <OperationDimension
            disable={setDisableSectionStatus("iyzi_team_section")}
            items={ffdData?.get_parcel_dimensions}
            i={ffdData?.get_parcel_dimensions?.length}
          />
        </div>
      </div>
      <div className="operation-cargo-dom-resp-pak">
        <FormControlLabel
          control={
            <Checkbox
              icon={<RadioButtonUncheckedOutlinedIcon />}
              checkedIcon={<RadioButtonCheckedOutlinedIcon />}
              checked={pakCheck}
              onChange={handlCheckPak}
            />
          }
          label={
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "700",
                letterSpacing: "0px",
                color: "#8c8c8c",
              }}
            >
              Pak Maliyeti
            </Typography>
          }
        />
        {pakCheck && (
          <OperationPak
            disable={!ffdData?.security_inspection}
            items={ffdData?.get_parcel_dimensions}
            i={ffdData?.get_parcel_dimensions?.length}
          />
        )}
      </div>
      <div className="operation-cargo-dom-resp-proforma">
        <Card
          sx={{
            maxWidth: "1100px",
            width: "100%",
            padding: "5px",
            backgroundColor: " var(--lighterBg)",
            borderRadius: "var(--wrapperBorderRadius)",
            boxShadow: "var(--shadow)",
            minHeight: "40px",
            opacity: !ffdData.security_inspection ? "0.5" : "1",
          }}
        >
          <CardHeader
            onClick={() => setOpen4(!open4)}
            title={
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  letterSpacing: " 0px",
                  marginLeft: "20px",
                  display: "flex",
                  color: "#8C8C8C",
                  alignItems: "center",
                }}
              >
                <img
                  className="logo"
                  src={proformaLogo}
                  alt=""
                  style={{ marginRight: "20px" }}
                />{" "}
                Proforma
              </span>
            }
            action={
              <IconButton
                aria-label="expand"
                size="small"
                sx={{ padding: "0", margin: "8px" }}
              >
                {open4 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            }
            sx={{ minHeight: "10px", padding: "0", cursor: "pointer" }}
          ></CardHeader>
          <div>
            <Collapse in={open4} timeout="auto" unmountOnExit>
              <CardContent>
                <Container
                  sx={{
                    lineHeight: 2,
                  }}
                >
                  <div className="operation-cargo-dom-resp-proforma-up">
                    <TextField
                      value={ioss}
                      autoComplete="off"
                      onChange={(e) => setIoss(e.target.value)}
                      size="small"
                      label="IOSS Numarası*"
                      sx={{ width: "300px" }}
                      disabled={
                        !ffdData.security_inspection
                          ? !ffdData.security_inspection
                          : !editedProforma
                      }
                    />

                    <FormControl sx={{ minWidth: "120px" }} size="small">
                      <InputLabel
                        sx={{ fontSize: "14px" }}
                        id="currency-select-label"
                      >
                        Döviz Cinsi
                      </InputLabel>
                      <Select
                        labelId="currency-select-label"
                        id="currency-select"
                        label="Döviz Cinsi"
                        value={proformaCurrency}
                        disabled={
                          !ffdData.security_inspection
                            ? !ffdData.security_inspection
                            : !editedProforma
                        }
                        onChange={(e) => setProformaCurrency(e.target.value)}
                        sx={{
                          fontSize: "14px",
                          display: "flex",
                          minHeight: "40px",
                          width: "300px",
                        }}
                      >
                        <MenuItem value="">
                          <em>Temizle</em>
                        </MenuItem>
                        <MenuItem value={"EUR"}>€ (Euro)</MenuItem>
                        <MenuItem value={"USD"}>$ (Amerikan Doları)</MenuItem>
                        <MenuItem value={"GBP"}>
                          £ (İngiltere Sterlini)
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      value={
                        ffdData.parcel_type === 1
                          ? "Normal Gönderi"
                          : ffdData.parcel_type === 2
                          ? "Hediye"
                          : "Mikro (ETGB)"
                      }
                      autoComplete="off"
                      size="small"
                      label="Gönderi Açıklaması*"
                      sx={{ width: "300px" }}
                      disabled
                    />
                  </div>
                  <div className="operation-cargo-dom-resp-container">
                    <div className="title">Proforma Detayı</div>

                    <OperationProforma
                      disable={!ffdData?.security_inspection}
                      ffdData={ffdData}
                      setCommoduties={setCommoduties}
                      commoduties={commoduties}
                      proformaCurrency={proformaCurrency}
                    />
                  </div>
                </Container>
              </CardContent>
            </Collapse>
          </div>
        </Card>
      </div>

      <div>
        <div className="operation-cargo-dom-resp-container">
          <div>
            {/*  {ffdData?.get_parcel_documents.length > 0 ? (
                <>
                  <Button
                    onClick={() => {
                      setFilesModalOpen(true);
                    }}
                    className="iyzi-button"
                  >
                    Dosyalar
                  </Button>
                  <FilesModal
                    open={filesModalOpen}
                    onClose={handleCloseFilesModal}
                    parcelFiles={ffdData.get_parcel_documents}
                  />
                </>
              ) : /*/
            /*/
              null} */}
          </div>

          <div className="resp-card">
            <div className="resp-card-buttons">
              {/*/ffdData?.get_parcel_commoduties.length > 0 ? (

              ) : null/*/}

              {/*  {ffdData?.doc_type === "N" ? (
                <Button
                  onClick={() => {
                    if (ffdData?.get_parcel_commoduties.length > 0) {
                      setCommoduties(ffdData?.get_parcel_commoduties);
                      setShowCommoduties(true);
                    } else {
                      return toast.error("Proforma Detayları Yok!");
                    }
                  }}
                  className="iyzi-button"
                >
                  Proforma Detayı
                </Button>
              ) : null} */}

              {/*  {ffdData?.supplier_id === "305" &&
              ffdData?.base64APICommercialInvoice !== null ? (
                <Button
                  onClick={printAPIProformaDocument}
                  className="iyzi-button"
                  style={{ marginLeft: "3px" }}
                >
                  Api Sonrası Proforma
                </Button>
              ) : null} */}

              {/*      {ffdData?.security_inspection ? (
                <Button
                  onClick={createNewLabelNumber}
                  className="iyzi-button"
                  style={{ marginLeft: "3px" }}
                >
                  Yeni İzleme Numarası Al
                </Button>
              ) : null} */}

              {/*  {ffdData?.security_inspection ? (
                <>
                  <Button
                    style={{ marginLeft: "3px" }}
                    variant="contained"
                    onClick={() => setSecurityInspectionDialog(true)}
                    color="success"
                  >
                    Güvenlik Kontrolü Yap
                  </Button>
                  <Dialog
                    open={securityInspectionDialog}
                    onClose={() => setSecurityInspectionDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Güvenlik Kontrolüne Al"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText
                        sx={{ fontWeight: 500, fontSize: "16px" }}
                        id="alert-dialog-description"
                      >
                        Gönderiyi güvenlik kontrolüne almak istediğinizden emin
                        misiniz?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        className="iyzi-button"
                        onClick={() => setSecurityInspectionDialog(false)}
                      >
                        Hayır
                      </Button>
                      <Button
                        className="iyzi-button"
                        onClick={handleSecurityInspection}
                      >
                        Evet
                      </Button>
                    </DialogActions>
                  </Dialog>{" "}
                </>
              ) : null} */}

              {/*   {ffdData?.status === "2.03" || !ffdData?.security_inspection ? (
                <>
                  <Button
                    style={{ marginLeft: "3px" }}
                    variant="contained"
                    onClick={() => setSecurityRemoveInspectionDialog(true)}
                    color="info"
                  >
                    Güvenlik Kontrolünden Çıkar
                  </Button>
                  <Dialog
                    open={securityRemoveInspectionDialog}
                    onClose={() => setSecurityRemoveInspectionDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Güvenlik Kontrolünden Çıkar"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText
                        sx={{ fontWeight: 500, fontSize: "16px" }}
                        id="alert-dialog-description"
                      >
                        Gönderiyi güvenlik kontrolünden çıkarmak istediğinizden
                        emin misiniz?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        className="iyzi-button"
                        onClick={() => setSecurityRemoveInspectionDialog(false)}
                      >
                        Hayır
                      </Button>
                      <Button
                        className="iyzi-button"
                        onClick={handleSecurityRemoveInspection}
                      >
                        Evet
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              ) : null} */}
            </div>
          </div>
        </div>

        {showCommoduties ? (
          <div className="operation-cargo-dom-resp-container">
            {commoduties.map((com, key) => (
              <Grid key={"id_" + key} container spacing={0.5}>
                <Grid item xs={1} md={1}>
                  <TextField
                    required={com.title.length < 9}
                    value={com.title}
                    autoComplete="off"
                    onChange={(e) => handleInputChange2(e, key)}
                    onBlur={productDescriptionBlur}
                    error={productDescriptionError}
                    helperText={
                      productDescriptionError
                        ? "Ürün açıklaması 9 haneden az olamaz"
                        : ""
                    }
                    name="title"
                    size="small"
                    label="Ürün Açıklaması"
                    inputProps={{
                      pattern: "^[a-zA-Z -/]+$",
                      minLength: 9,
                    }}
                  />
                </Grid>
                <Grid item xs={1} md={1}>
                  <Autocomplete
                    id="country-typing-select"
                    fullWidth
                    size="small"
                    sx={{ maxWidth: 380 }}
                    autoComplete="off"
                    name="country"
                    value={
                      countriesDetailed.find(
                        (c) => c.iso_code === com.country
                      ) !== undefined
                        ? countriesDetailed.find(
                            (c) => c.iso_code === com.country
                          )
                        : null
                    }
                    options={countriesDetailed}
                    autoHighlight
                    getOptionLabel={(option) =>
                      `${option.country_name_tr}, ${option.country_name_en}`
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.iso_code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.iso_code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.country_name_tr}, {option.country_name_en}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Menşei"
                        required
                        autoComplete="off"
                        name="country"
                        onBlur={(e) => handleInputChange2(e, key)}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1} md={1}>
                  <TextField
                    required
                    value={com.hs_code}
                    onChange={(e) => handleInputChange2(e, key)}
                    name="hs_code"
                    size="small"
                    label="HS Kodu"
                    autoComplete="off"
                    inputProps={{
                      minLength: 6,
                      maxLength: 12,
                    }}
                    onBlur={(e) => handleHsCodeBlur(e, key)}
                    error={hsCodeError}
                    helperText={
                      hsCodeError && "Hs Kodu 6 haneden küçük girilemez."
                    }
                  />
                </Grid>
                <Grid item xs={1} md={1}>
                  <TextField
                    value={com.sku}
                    onChange={(e) => handleInputChange2(e, key)}
                    name="sku"
                    size="small"
                    label="SKU"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={1} md={1}>
                  <TextField
                    required
                    value={com.qty}
                    onChange={(e) => handleInputChange2(e, key)}
                    name="qty"
                    size="small"
                    label="Miktar"
                    autoComplete="off"
                    // type="number" // Sadece sayı girişi kabul edilecektir
                    inputProps={{
                      min: 1, // En küçük değer sıfır olmalıdır
                    }}
                    onBlur={(e) => handleQtyBlur(e, key)} // Miktar alanından çıkıldığında bu fonksiyon çalışacak
                    error={qtyError}
                    helperText={qtyError && "Miktar 0'dan küçük olamaz."}
                  />
                </Grid>
                <Grid item xs={1} md={1}>
                  <TextField
                    value={com.price}
                    onChange={(e) => handleInputChange2(e, key)}
                    onBlur={(e) => handleInputBlur(e, key)}
                    //onBlur={handleBlurProductValue}
                    name="price"
                    required
                    size="small"
                    label="Birim Fiyatı"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={1} md={1}>
                  <TextField
                    value={Number(com.price) * Number(com.qty)}
                    name="price"
                    disabled
                    required
                    size="small"
                    label="Toplam Fiyat"
                    autoComplete="off"
                    helperText={
                      <div
                        style={{
                          fontSize: "inherit",
                          color: "var(--error)",
                        }}
                      >
                        Gönderi oluştur ilk kısımda belirlediğiniz mal bedeli "
                        {productValue}
                        {Currency(productCurrency)}".
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={1} md={1}>
                  <DeleteIcon
                    className={key === 0 ? "display displayHide" : "display"}
                    onClick={() => handleDeleteCommoduties(key)}
                  />
                </Grid>
              </Grid>
            ))}
          </div>
        ) : null}
      </div>

      <div className="operation-cargo-dom-resp-tickets">
        <div
          style={{ opacity: ffdData?.security_inspection ? 1 : 0.5 }}
          className="resp-ticket okay"
        >
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              !ffdData?.security_inspection
                ? toast.error("Önce Security Inspection Tamamlamalısınız")
                : setNoProblemDialog(true);
            }}
          >
            SORUNSUZ
          </Typography>
          {ffdData?.security_inspection ? (
            <FormControlLabel
              control={
                <Checkbox
                  icon={
                    <RadioButtonUncheckedOutlinedIcon sx={{ color: "#fff" }} />
                  }
                  checkedIcon={
                    <RadioButtonCheckedOutlinedIcon sx={{ color: "#fff" }} />
                  }
                  checked={successCheck}
                  onChange={() => {
                    if (!ffdData?.security_inspection) {
                      toast.error("Önce Security Inspection Tamamlamalısınız");
                    } else {
                      if (!successCheck) {
                        setNoProblemDialog(true);
                        setSuccessCheck(true);
                        return console.log("No Problem Dialog Acıldı");
                      }
                      setSuccessCheck(false);
                    }
                  }}
                />
              }
              label={
                <div
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                  }}
                >
                  Sorunsuz
                </div>
              }
            />
          ) : null}

          {!ffdData?.security_inspection ? (
            <label style={{ color: "#fff", fontWeight: "bold" }}></label>
          ) : (
            <Dialog
              open={noProblemDialog}
              onClose={() => setNoProblemDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Gönderi Ölçü Kontrolü İşaretlemesi "}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{ fontWeight: 500, fontSize: "16px" }}
                  id="alert-dialog-description"
                >
                  Gönderiyi ölçü kontrolü durumuna işaretlemek istediğinizden
                  emin misiniz?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  className="iyzi-button"
                  onClick={() => {
                    setNoProblemDialog(false);
                    setSuccessCheck(false);
                  }}
                >
                  Hayır
                </Button>
                <Button
                  className="iyzi-button"
                  onClick={() => {
                    setSelectedProblemStatus("2.16");
                    toast.success(
                      "Gönderi Ölçü Kontrolü Durumuna İşaretlendi."
                    );
                    setSelectedMissingDocumentOptions([]);
                    setSelectedMissingAddressOptions([]);
                    setSelectedMissingProformaOptions([]);
                    setInputOtherValue0("");
                    setInputOtherValue("");
                    setInputOtherValue2("");
                    setOtherTags0([]);
                    setOtherTags([]);
                    setOtherTags2([]);
                    setNoProblemDialog(false);
                  }}
                >
                  Evet
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
        <div
          style={{ opacity: ffdData?.security_inspection ? 1 : 0.5 }}
          className="resp-ticket denied"
        >
          <Typography>SORUNLU</Typography>
          {ffdData?.security_inspection ? (
            <Box className="new-address-modal-wrapper" sx={{ width: "300px" }}>
              <Autocomplete
                id="country-typing-select"
                fullWidth
                size="small"
                disabled={!ffdData?.security_inspection}
                options={ffdData?.statusCodes}
                value={
                  ffdData?.statusCodes.find(
                    (option) => option.status === selectedProblemStatus
                  ) || null
                }
                ListboxProps={{
                  onClick: (e) => {
                    const clickedStatus = e.target.getAttribute("data-status");

                    setClickedStatus(clickedStatus);
                    console.log("clickedStatus:" + clickedStatus);
                    console.log("lastSelectedStatus:" + lastSelectedStatus);
                    // Eğer tıklanan seçenek son seçilmiş seçenekle aynıysa
                    //&& clickedStatus === lastSelectedStatus//
                    if (clickedStatus) {
                      if (clickedStatus === "2.06") {
                        setPackageModal(true);
                      } else if (clickedStatus === "2.07") {
                        setUnavailablePackageModal(true);
                      } else if (clickedStatus === "2.08") {
                        setDamagedModal(true);
                      } else if (clickedStatus === "2.11") {
                        setMissingDocumentModal(true);
                      } else if (clickedStatus === "2.12") {
                        setMissingAddressModal(true);
                      } else if (clickedStatus === "2.13") {
                        setMissingProformaModal(true);
                      }
                    }
                  },
                }}
                onChange={(e, val) => {
                  if (val) {
                    if (val.extra !== undefined) {
                      if (val.status === "2.08") {
                        setDamagedModal(true); // EGER GONDERİ HASARLI SECERSE
                      } else if (val.status === "2.11") {
                        setMissingDocumentModal(true); // EGER EKSİK EVRAK SECERSE
                      } else if (val.status === "2.12") {
                        setMissingAddressModal(true); // EGER ADRES SECERSE
                      } else if (val.status === "2.13") {
                        setMissingProformaModal(true); // EGER PROFORMA SECERSE
                      }
                    }
                    setSelectedProblemStatus(val.status);
                    setLastSelectedStatus(val.status); // Son seçilen değeri güncelle
                  } else {
                    setSelectedProblemStatus("");
                    setDamagedModal(false);
                    setPackageModal(false);
                    setUnavailablePackageModal(false);
                    setMissingDocumentModal(false);
                    setMissingAddressModal(false);
                    setMissingProformaModal(false);
                    setLastSelectedStatus(null); // Son seçilen değeri sıfırla
                    setClickedStatus("");
                  }
                }}
                sx={{
                  "& fieldset": {
                    borderColor: "white !important",
                  },
                  "& svg": {
                    color: "white",
                  },
                  "& .MuiAutocomplete-inputFocused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "white",
                    },
                }}
                autoHighlight
                openOnFocus={false}
                getOptionLabel={(option) => `${option.status} - ${option.text}`}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                    data-status={option.status}
                  >
                    {option.status} - {option.text}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="İptal Nedeni"
                    sx={{
                      "& label": {
                        color: "white !important",
                      },
                      "& .MuiAutocomplete-inputRoot": {
                        height: "50px",
                      },
                      "& .MuiInputBase-input": {
                        color: "white !important",
                      },
                    }}
                    required
                    name="unique-country-field"
                    value={selectedProblemStatus}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Box>
          ) : null}

          {/*/<Button onClick={() => handleSend(1)} sx={{textTransform:'none', color:'var(--lighterBg)'}}>Hasar Bildir ve Onay Bekle</Button>
          <Button  onClick={() => handleSend(2)} sx={{textTransform:'none', color:'var(--lighterBg)'}}>Hasar Bildir ve İptal Et</Button>
          <Button sx={{textTransform:'none', color:'var(--lighterBg)'}}>Uygunsuz İçerik Bildir ve Onay Bekle</Button>
          <Button sx={{textTransform:'none', color:'var(--lighterBg)'}}>Uygunsuz İçerik Bildir ve İptal Et</Button>
          <Button sx={{textTransform:'none', color:'var(--lighterBg)'}}>Uygunsuz İçerik Bildir ve Onay Bekle</Button>/*/}

          {/*/ EGER PAKETLEME HATASI  SECİLİRSE ACILACAK OLAN MODAL /*/}
          <Modal
            open={packageModal}
            onClose={() => setPackageModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus
          >
            <Box sx={style}>
              <DialogTitle id="damaged-modal-modal-title">
                Paketleme Hatası Dosya ve Açıklama Penceresi
              </DialogTitle>
              <TextField
                required
                fullWidth
                inputProps={{
                  minLength: 2,
                }}
                onBlur={(e) => handleTextBlur(e)}
                error={textError}
                helperText={textError && "Açıklama max 2 karakter olmalı"}
                value={packageDescription}
                onChange={(e) => setPackageDescription(e.target.value)}
                autoComplete="off"
                type="text"
                label="Açıklama"
                size="small"
                sx={{ marginBottom: "10px" }}
              />
              <Box className="dropzone-wrapper">
                <Box className="dropzone">
                  <div className="dropzone-area" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="dropzone-area-text">
                      <img src={uploadIcon} alt="Upload Icon" />
                      <span className="dropzone-area-text-headline">
                        Dosyayı sürükleyip bırakın veya{" "}
                        <span style={{ color: "var(--info)" }}>Gözat</span>
                      </span>
                      <span className="dropzone-area-text-sub">
                        Desteklenen Formatlar:
                        DOC,DOCX,XLSX,PDF,TXT,CSV,PNG,JPG,JPEG
                      </span>
                    </div>
                  </div>
                </Box>
                <DowloadPdfView
                  sendFiles={sendPackageFiles}
                  handleSetState={handleSetStatePackage}
                />
              </Box>
              <Box sx={{ display: " flex", justifyContent: "flex-end" }}>
                <Button
                  type="button"
                  onClick={() => {
                    setPackageModal(false);
                    setSendPackageFiles([]);
                  }}
                  className="iyzi-button"
                >
                  Vazgeç
                </Button>
                <Button
                  onClick={handleRegisterPackage}
                  className="iyzi-button"
                  sx={{ ml: "10px" }}
                >
                  Ekle
                </Button>
              </Box>
            </Box>
          </Modal>
          {/*/ EGER PAKETLEME HATASI  SECİLİRSE ACILACAK OLAN MODAL /*/}

          {/*/ EGER UYGUNSUZ PAKETLEME  SECİLİRSE ACILACAK OLAN MODAL /*/}
          <Modal
            open={unavailablePackageModal}
            onClose={() => setUnavailablePackageModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus
          >
            <Box sx={style}>
              <DialogTitle id="damaged-modal-modal-title">
                Uygunsuz Paketleme Dosya ve Açıklama Penceresi
              </DialogTitle>
              <TextField
                required
                fullWidth
                inputProps={{
                  minLength: 2,
                }}
                onBlur={(e) => handleTextBlur(e)}
                error={textError}
                helperText={textError && "Açıklama max 2 karakter olmalı"}
                value={unavailablePackageDescription}
                onChange={(e) =>
                  setUnavailablePackageDescription(e.target.value)
                }
                autoComplete="off"
                type="text"
                label="Açıklama"
                size="small"
                sx={{ marginBottom: "10px" }}
              />
              <Box className="dropzone-wrapper">
                <Box className="dropzone">
                  <div className="dropzone-area" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="dropzone-area-text">
                      <img src={uploadIcon} alt="Upload Icon" />
                      <span className="dropzone-area-text-headline">
                        Dosyayı sürükleyip bırakın veya{" "}
                        <span style={{ color: "var(--info)" }}>Gözat</span>
                      </span>
                      <span className="dropzone-area-text-sub">
                        Desteklenen Formatlar:
                        DOC,DOCX,XLSX,PDF,TXT,CSV,PNG,JPG,JPEG
                      </span>
                    </div>
                  </div>
                </Box>
                <DowloadPdfView
                  sendFiles={sendUnavailablePackageFiles}
                  handleSetState={handleSetStateUnavailablePackage}
                />
              </Box>
              <Box sx={{ display: " flex", justifyContent: "flex-end" }}>
                <Button
                  type="button"
                  onClick={() => {
                    setUnavailablePackageModal(false);
                    setSendUnavailablePackageFiles([]);
                  }}
                  className="iyzi-button"
                >
                  Vazgeç
                </Button>
                <Button
                  onClick={handleRegisterUnavaiblePackage}
                  className="iyzi-button"
                  sx={{ ml: "10px" }}
                >
                  Ekle
                </Button>
              </Box>
            </Box>
          </Modal>
          {/*/ EGER UYGUNSUZ PAKETLEME  SECİLİRSE ACILACAK OLAN MODAL /*/}

          {/*/ EGER GÖNDERİ HASARLI SECİLİRSE ACILACAK OLAN MODAL /*/}
          <Modal
            open={damagedModal}
            onClose={() => setDamagedModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus
          >
            <Box sx={style}>
              <DialogTitle id="damaged-modal-modal-title">
                Gönderi Hasarlı Dosya ve Açıklama Penceresi
              </DialogTitle>
              <TextField
                required
                fullWidth
                inputProps={{
                  minLength: 2,
                }}
                onBlur={(e) => handleTextBlur(e)}
                error={textError}
                helperText={textError && "Açıklama max 2 karakter olmalı"}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                autoComplete="off"
                type="text"
                label="Açıklama"
                size="small"
                sx={{ marginBottom: "10px" }}
              />
              <Box className="dropzone-wrapper">
                <Box className="dropzone">
                  <div className="dropzone-area" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="dropzone-area-text">
                      <img src={uploadIcon} alt="Upload Icon" />
                      <span className="dropzone-area-text-headline">
                        Dosyayı sürükleyip bırakın veya{" "}
                        <span style={{ color: "var(--info)" }}>Gözat</span>
                      </span>
                      <span className="dropzone-area-text-sub">
                        Desteklenen Formatlar:
                        DOC,DOCX,XLSX,PDF,TXT,CSV,PNG,JPG,JPEG
                      </span>
                    </div>
                  </div>
                </Box>
                <DowloadPdfView
                  sendFiles={sendFiles}
                  handleSetState={handleSetState}
                />
              </Box>
              <Box sx={{ display: " flex", justifyContent: "flex-end" }}>
                <Button
                  type="button"
                  onClick={() => {
                    setDamagedModal(false);
                    setSendFiles([]);
                  }}
                  className="iyzi-button"
                >
                  Vazgeç
                </Button>
                <Button
                  onClick={handleRegisterDamage}
                  className="iyzi-button"
                  sx={{ ml: "10px" }}
                >
                  Ekle
                </Button>
              </Box>
            </Box>
          </Modal>
          {/*/ EGER GÖNDERİ HASARLI SECİLİRSE ACILACAK OLAN MODAL /*/}

          {/*/ EGER EKSİK EVRAK SECİLİRSE ACILACAK OLAN MODAL /*/}
          <Modal
            open={missingDocumentModal}
            onClose={() => setMissingDocumentModal(false)}
            aria-labelledby="missing-modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus
          >
            <Box sx={style2}>
              <DialogTitle id="missing-modal-modal-title">
                Eksik Evrak Seçimleri
              </DialogTitle>
              <Autocomplete
                multiple
                id="multi-select-autocomplete"
                options={missingDocumentTypes}
                getOptionLabel={(option) => option.label}
                value={selectedMissingDocumentOptions}
                onChange={handleMultiSelectChange}
                renderInput={(params) => (
                  <TextField {...params} label="Çoklu Seçim" />
                )}
              />
              {selectedMissingDocumentOptions.filter((f) => f.extraContent)
                .length > 0 ? (
                <FormControl
                  style={{ marginTop: "10px" }}
                  fullWidth
                  size="small"
                >
                  <TextField
                    label="Diğer Seçenek Yazınız"
                    value={inputOtherValue}
                    onChange={handleInputOtherChange}
                    onKeyPress={handleInputOtherKeyPress}
                  />
                  <div>
                    <Box style={{ marginTop: "12px" }}>
                      Eklenecek Diğer Seçenekler
                    </Box>
                    {otherTags.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        onDelete={() => handleTagDelete(tag)}
                        style={{ margin: "4px" }}
                      />
                    ))}
                  </div>
                </FormControl>
              ) : null}
              <Box
                sx={{
                  display: " flex",
                  justifyContent: "flex-end",
                  marginTop: "5px",
                }}
              >
                <Button
                  type="button"
                  onClick={() => {
                    setMissingDocumentModal(false);
                    setSelectedMissingDocumentOptions([]);
                    setOtherTags([]);
                    setInputOtherValue("");
                  }}
                  className="iyzi-button"
                >
                  Vazgeç
                </Button>
                <Button
                  onClick={handleRegisterMissingDocument}
                  className="iyzi-button"
                  sx={{ ml: "10px" }}
                >
                  Ekle
                </Button>
              </Box>
            </Box>
          </Modal>
          {/*/ EGER EKSİK EVRAK SECİLİRSE ACILACAK OLAN MODAL /*/}

          {/*/ EĞER ADRES HATASI SEÇİLİRSE ACILACAK OLAN MODAL /*/}
          <Modal
            open={missingAddressModal}
            onClose={() => setMissingAddressModal(false)}
            aria-labelledby="missing-modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus
          >
            <Box sx={style2}>
              <DialogTitle id="missing-modal-modal-title">
                Adres Hatası Seçimleri
              </DialogTitle>
              <Autocomplete
                multiple
                id="multi-select-autocomplete"
                options={missingAddressTypes}
                getOptionLabel={(option) => option.label}
                value={selectedMissingAddressOptions}
                onChange={handleMultiSelectChange0}
                renderInput={(params) => (
                  <TextField {...params} label="Çoklu Seçim" />
                )}
              />
              {selectedMissingAddressOptions.filter((f) => f.extraContent)
                .length > 0 ? (
                <FormControl
                  style={{ marginTop: "10px" }}
                  fullWidth
                  size="small"
                >
                  <TextField
                    label="Diğer Seçenek Yazınız"
                    value={inputOtherValue0}
                    onChange={handleInputOtherChange0}
                    onKeyPress={handleInputOtherKeyPress0}
                  />
                  <div>
                    <Box style={{ marginTop: "12px" }}>
                      Eklenecek Diğer Seçenekler
                    </Box>
                    {otherTags0.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        onDelete={() => handleTagDelete0(tag)}
                        style={{ margin: "4px" }}
                      />
                    ))}
                  </div>
                </FormControl>
              ) : null}
              <Box
                sx={{
                  display: " flex",
                  justifyContent: "flex-end",
                  marginTop: "5px",
                }}
              >
                <Button
                  type="button"
                  onClick={() => {
                    setMissingAddressModal(false);
                    setOtherTags0([]);
                    setInputOtherValue0("");
                    setSelectedMissingAddressOptions([]);
                  }}
                  className="iyzi-button"
                >
                  Vazgeç
                </Button>
                <Button
                  onClick={handleRegisterMissingAddress}
                  className="iyzi-button"
                  sx={{ ml: "10px" }}
                >
                  Ekle
                </Button>
              </Box>
            </Box>
          </Modal>
          {/*/ EĞER ADRES HATASI SEÇİLİRSE ACILACAK OLAN MODAL /*/}

          {/*/ EGER PROFORMA SECİLİRSE ACILACAK OLAN MODAL /*/}
          <Modal
        open={missingProformaModal}
        onClose={() => setMissingProformaModal(false)}
        aria-labelledby="missing-modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus
      >
        <Box sx={style2}>
          <DialogTitle id="missing-modal-modal-title">
            Proforma Hatası Seçimleri
          </DialogTitle>
          <Autocomplete
            multiple
            id="multi-select-autocomplete"
            options={missingProformaTypes}
            getOptionLabel={(option) => option.label}
            value={selectedMissingProformaOptions}
            onChange={handleMultiSelectChange2}
            renderInput={(params) => (
              <TextField {...params} label="Çoklu Seçim" />
            )}
          />
          {selectedMissingProformaOptions.filter((f) => f.extraContent).length >
          0 ? (
            <FormControl style={{ marginTop: "10px" }} fullWidth size="small">
              <TextField
                label="Diğer Seçenek Yazınız"
                value={inputOtherValue2}
                onChange={handleInputOtherChange2}
                onKeyPress={handleInputOtherKeyPress2}
              />
              <div>
                <Box style={{ marginTop: "12px" }}>
                  Eklenecek Diğer Seçenekler
                </Box>
                {otherTags2.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onDelete={() => handleTagDelete2(tag)}
                    style={{ margin: "4px" }}
                  />
                ))}
              </div>
            </FormControl>
          ) : null}
          <Box
            sx={{
              display: " flex",
              justifyContent: "flex-end",
              marginTop: "5px",
            }}
          >
            <Button
              type="button"
              onClick={() => {
                setMissingProformaModal(false);
                setSelectedMissingProformaOptions([]);
                setOtherTags2([]);
                setInputOtherValue2("");
              }}
              className="iyzi-button"
            >
              Vazgeç
            </Button>
            <Button
              onClick={handleRegisterMissingProforma}
              className="iyzi-button"
              sx={{ ml: "10px" }}
            >
              Ekle
            </Button>
          </Box>
        </Box>
      </Modal>
          {/*/ EGER PROFORMA SECİLİRSE ACILACAK OLAN MODAL /*/}
        </div>

        <div>
          <div
            className={
              ffdData?.security_inspection
                ? "resp-ticket okay"
                : "resp-ticket problem"
            }
          >
            <Typography>SECURITY INSPECTION</Typography>
            {ffdData?.security_inspection ? (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={
                        <RadioButtonUncheckedOutlinedIcon
                          sx={{ color: "#fff" }}
                        />
                      }
                      checkedIcon={
                        <RadioButtonCheckedOutlinedIcon
                          sx={{ color: "#fff" }}
                        />
                      }
                      checked={securityInspectionCheck}
                      onChange={() => setSecurityInspectionDialog(true)}
                    />
                  }
                  label={
                    <div
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                      }}
                    >
                      Güvenlik Kontrolü Yap
                    </div>
                  }
                />
                <Dialog
                  open={securityInspectionDialog}
                  onClose={() => setSecurityInspectionDialog(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Güvenlik Kontrolüne Al"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ fontWeight: 500, fontSize: "16px" }}
                      id="alert-dialog-description"
                    >
                      Gönderiyi güvenlik kontrolüne almak istediğinizden emin
                      misiniz?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      className="iyzi-button"
                      onClick={() => setSecurityInspectionDialog(false)}
                    >
                      Hayır
                    </Button>
                    <Button
                      className="iyzi-button"
                      onClick={handleSecurityInspection}
                    >
                      Evet
                    </Button>
                  </DialogActions>
                </Dialog>{" "}
              </>
            ) : null}
            {/* {ffdData?.security_inspection ? (
              <CheckCircleOutlinedIcon
                sx={{
                  width: "113px",
                  height: " 113px",
                  color: "var(--lighterBg)",
                  cursor: "pointer",
                }}
              />
            ) : (
              <ClearIcon
                sx={{
                  width: "113px",
                  height: " 113px",
                  color: "var(--lighterBg)",
                  cursor: "pointer",
                }}
              />
            )} */}
          </div>
        </div>
      </div>

      <div
        className="row"
        style={{ maxWidth: " 1100px", marginBottom: "20px" }}
      >
        <Button
          className="iyzi-button-blue"
          sx={{ marginLeft: "3px", width: "200px", height: "46px" }}
        >
          Eski AWB Göster
        </Button>
        {/*         <Button
          onClick={printAPIDocument}
          className="iyzi-button-blue"
          sx={{ marginLeft: "3px", width: "123px", height: "46px" }}
        >
          Api Sonrası Pdf
        </Button> */}

        {loading ? (
          <span
            style={{
              width: "108px",
              height: "37px",
              margin: "0 25px",
            }}
          >
            <CircularProgress
              sx={{ width: "21px!important", height: "21px!important" }}
            />
          </span>
        ) : ffdData?.security_inspection ? (
          <Button
            disabled={setDisableSectionStatus("new_tracking_awb_section")}
            onClick={createNewLabelNumber}
            className="iyzi-button-blue"
            sx={{ marginLeft: "3px", width: "123px", height: "46px" }}
          >
            Yeni İzleme Numarası Al
          </Button>
        ) : null}

        {!hideSecurityInspectionButton ? (
          <>
            <Button
              style={{ marginLeft: "3px", height: "46px" }}
              variant="contained"
              disabled={setDisableSectionStatus(
                "security_inspection_button_section"
              )}
              onClick={() => setSecurityRemoveInspectionDialog(true)}
              className="iyzi-button-blue"
            >
              Güvenlik Kontrolünden Çıkar
            </Button>
            <Dialog
              open={securityRemoveInspectionDialog}
              onClose={() => setSecurityRemoveInspectionDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Güvenlik Kontrolünden Çıkar"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{ fontWeight: 500, fontSize: "16px" }}
                  id="alert-dialog-description"
                >
                  Gönderiyi güvenlik kontrolünden çıkarmak istediğinizden emin
                  misiniz?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  className="iyzi-button"
                  onClick={() => setSecurityRemoveInspectionDialog(false)}
                >
                  Hayır
                </Button>
                <Button
                  className="iyzi-button"
                  onClick={handleSecurityRemoveInspection}
                >
                  Evet
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : null}

        {/*/ yorum satırı yaptım burayi yeni ekledim 22.09.2023 08:45 /*/}
        {/*/ffdData?.status === "2.03" || !ffdData?.security_inspection ? (
          <>
            <Button
              style={{ marginLeft: "3px", height: "46px" }}
              variant="contained"
              disabled={ffdData?.status !== "2.03"}
              onClick={() => setSecurityRemoveInspectionDialog(true)}
              className="iyzi-button-blue"
            >
              Güvenlik Kontrolünden Çıkar
            </Button>
            <Dialog
              open={securityRemoveInspectionDialog}
              onClose={() => setSecurityRemoveInspectionDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Güvenlik Kontrolünden Çıkar"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{ fontWeight: 500, fontSize: "16px" }}
                  id="alert-dialog-description"
                >
                  Gönderiyi güvenlik kontrolünden çıkarmak istediğinizden emin
                  misiniz?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  className="iyzi-button"
                  onClick={() => setSecurityRemoveInspectionDialog(false)}
                >
                  Hayır
                </Button>
                <Button
                  className="iyzi-button"
                  onClick={handleSecurityRemoveInspection}
                >
                  Evet
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : null/*/}

        {" Security Inspection: " + ffdData?.security_inspection}
        {" Gonderi Durum: " + ffdData?.status}
        {" Ölçü Durum: " + checkDimension}
        <Button
          className="iyzi-button-blue"
          sx={{ float: "right", width: "158px", height: "46px" }}
          onClick={handleCompleteMalKabul}
          disabled={setDisableSectionStatus("handle_complete_button_section")}
        >
          Kaydet
        </Button>

        <Dialog
          open={checkPriceDialog}
          onClose={() => {
            setCheckPriceDialog(false);
            setCheckPriceDialogData({
              old_selling_price: 0,
              new_selling_price: 0,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Fiyat Farkı Durumu Oluştu"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontWeight: 500, fontSize: "16px" }}
              id="alert-dialog-description"
            >
              <Typography>
                <strong>Eski Fiyat : </strong>
                {checkPriceDialogData?.old_selling_price.toFixed(2)}
              </Typography>
              <Typography>
                <strong>Yeni Fiyat : </strong>
                {checkPriceDialogData?.new_selling_price.toFixed(2)}
              </Typography>
              Devam Etmek İstiyor Musunuz?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="iyzi-button"
              onClick={() => {
                setCheckPriceDialog(false);
                setSendRequest(false);
                setCheckPriceDialogData({
                  old_selling_price: 0,
                  new_selling_price: 0,
                });
              }}
            >
              Hayır
            </Button>
            <Button
              className="iyzi-button"
              onClick={() => {
                setSendRequest(true);
                // handleCompleteMalKabul();
              }}
            >
              Evet
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <Modal open={checkAddresesUpdate} onClose={handleClose}>
        <form
          style={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            padding: "20px",
            outline: 0,
            display: "grid",
            gap: "var(--gap)",
            width: "100%",
            maxWidth: "1100px",
            margin: "auto",
            position: "relative",
          }}
          onSubmit={showWarring}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              color: "var(--priceText)",
              cursor: "pointer",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleClose}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "var(--gap2x)",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                gap: "var(--gap2x)",
              }}
            >
              <TextField
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                size="small"
                label="Kontak"
                autoFocus
                onFocus={(e) => {
                  let descValue = e.target.value;
                  e.target.value = "";
                  e.target.value = descValue;
                }}
              />
              <TextField
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                size="small"
                label="Firma Adı"
              />
            </div>
            <TextField
              fullWidth
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              size="small"
              label="Adres Satırı"
            />
            <TextField
              fullWidth
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              size="small"
              label="Adres Satırı 2"
            />
            <TextField
              fullWidth
              value={address3}
              onChange={(e) => setAddress3(e.target.value)}
              size="small"
              label="Adres Satırı 3"
            />
            <div
              style={{
                display: "grid",
                gap: "var(--gap2x)",
                gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
              }}
            >
              <TextField
                required
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                placeholder="06010"
                onBlur={zipCodeHandler}
                fullWidth
                size="small"
                autoComplete="off"
                label="Posta Kodu"
                error={zipCodeError}
                helperText={
                  <div
                    style={{
                      fontSize: "inherit",
                      color: "var(--info)",
                      backgroundColor: "var(--pastelBlue)",
                      display: "flex",
                      gap: "5px",
                      padding: "5px",
                      borderRadius: "var(--wrapperBorderRadius)",
                      alignItems: "center",
                    }}
                  >
                    <InfoOutlinedIcon />
                    <div style={{ fontSize: "inherit", color: "var(--info)" }}>
                      Eğer posta kodunun yanında{" "}
                      <CloseOutlinedIcon
                        style={{ color: "red", fontSize: "11px" }}
                      />{" "}
                      görseli varsa bu seçeneklerde bulunmadığı anlamına gelir.
                      Semt, İlçe ve Şehir kısmını doldurarak devam etmenizde bir
                      sakınca yoktur.
                    </div>
                  </div>
                }
                InputProps={
                  zipIsDone === true
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <DoneOutlinedIcon style={{ color: "green" }} />
                          </InputAdornment>
                        ),
                      }
                    : zipIsDone === false
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CloseOutlinedIcon style={{ color: "red" }} />
                          </InputAdornment>
                        ),
                      }
                    : zipIsDone === null && zipLoading === true
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress
                              style={{ width: "20px", height: "20px" }}
                            />
                          </InputAdornment>
                        ),
                      }
                    : {}
                }
              />
              <FormControl size="small" fullWidth>
                <PhoneInput
                  country={ffdData?.get_parcel_sender_address?.country.toLowerCase()}
                  value={phone}
                  onChange={(e) => setPhone(e)}
                  autoComplete="off"
                  inputProps={{
                    name: "phone",
                    autoFocus: true,
                    id: "ind-phone-input",
                    style: {
                      height: "100%",
                      width: "100%",
                      minHeight: "40px",
                      maxHeight: "40px",
                    },
                  }}
                />
              </FormControl>
            </div>
            <TextField
              value={city}
              onChange={(e) => setCity(e.target.value)}
              size="small"
              label="Province / Eyalet"
            />
            <TextField
              value={town}
              onChange={(e) => setTown(e.target.value)}
              size="small"
              label="Kent"
            />
            <TextField
              value={state}
              onChange={(e) => setState(e.target.value)}
              size="small"
              label="İlçe"
            />
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="small"
              type={"email"}
              label="E-Posta"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "var(--gap)",
            }}
          >
            <Button type="submit" className="iyzi-button-blue">
              Kaydet
            </Button>

            <Button
              type="button"
              className="iyzi-button-blue"
              onClick={handleClose}
            >
              İptal
            </Button>
          </div>
        </form>
      </Modal>

      <Modal open={checkRecieverAddresesUpdate} onClose={handleRecevierClose}>
        <form
          style={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            padding: "20px",
            outline: 0,
            display: "grid",
            gap: "var(--gap)",
            width: "100%",
            maxWidth: "1100px",
            margin: "auto",
            position: "relative",
          }}
          onSubmit={showRecieverWarring}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              color: "var(--priceText)",
              cursor: "pointer",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleRecevierClose}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "var(--gap2x)",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                gap: "var(--gap2x)",
              }}
            >
              <TextField
                value={recevierContactName}
                onChange={(e) => setRecevierContactName(e.target.value)}
                size="small"
                label="Kontak"
                autoFocus
                onFocus={(e) => {
                  let descValue = e.target.value;
                  e.target.value = "";
                  e.target.value = descValue;
                }}
              />
              <TextField
                value={recevierCompanyName}
                onChange={(e) => setRecevierCompanyName(e.target.value)}
                size="small"
                label="Firma Adı"
              />
            </div>
            <TextField
              fullWidth
              value={recevierAddress1}
              onChange={(e) => setRecevierAddress1(e.target.value)}
              size="small"
              label="Adres Satırı"
            />
            <TextField
              fullWidth
              value={recevierAddress2}
              onChange={(e) => setRecevierAddress2(e.target.value)}
              size="small"
              label="Adres Satırı 2"
            />
            <TextField
              fullWidth
              value={recevierAddress3}
              onChange={(e) => setRecevierAddress3(e.target.value)}
              size="small"
              label="Adres Satırı 3"
            />
            <div
              style={{
                display: "grid",
                gap: "var(--gap2x)",
                gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
              }}
            >
              <TextField
                required
                value={recevierPostalCode}
                onChange={(e) => setRecevierPostalCode(e.target.value)}
                placeholder="06010"
                onBlur={zipCodeHandler}
                fullWidth
                size="small"
                autoComplete="off"
                label="Posta Kodu"
                error={zipCodeError}
                helperText={
                  <div
                    style={{
                      fontSize: "inherit",
                      color: "var(--info)",
                      backgroundColor: "var(--pastelBlue)",
                      display: "flex",
                      gap: "5px",
                      padding: "5px",
                      borderRadius: "var(--wrapperBorderRadius)",
                      alignItems: "center",
                    }}
                  >
                    <InfoOutlinedIcon />
                    <div style={{ fontSize: "inherit", color: "var(--info)" }}>
                      Eğer posta kodunun yanında{" "}
                      <CloseOutlinedIcon
                        style={{ color: "red", fontSize: "11px" }}
                      />{" "}
                      görseli varsa bu seçeneklerde bulunmadığı anlamına gelir.
                      Semt, İlçe ve Şehir kısmını doldurarak devam etmenizde bir
                      sakınca yoktur.
                    </div>
                  </div>
                }
                InputProps={
                  zipIsDone === true
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <DoneOutlinedIcon style={{ color: "green" }} />
                          </InputAdornment>
                        ),
                      }
                    : zipIsDone === false
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CloseOutlinedIcon style={{ color: "red" }} />
                          </InputAdornment>
                        ),
                      }
                    : zipIsDone === null && zipLoading === true
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress
                              style={{ width: "20px", height: "20px" }}
                            />
                          </InputAdornment>
                        ),
                      }
                    : {}
                }
              />
              <FormControl size="small" fullWidth>
                <PhoneInput
                  country={ffdData?.get_parcel_reciever_address?.country.toLowerCase()}
                  value={recevierPhone}
                  onChange={(e) => setRecevierPhone(e)}
                  autoComplete="off"
                  inputProps={{
                    name: "phone",
                    autoFocus: true,
                    id: "ind-phone-input",
                    style: {
                      height: "100%",
                      width: "100%",
                      minHeight: "40px",
                      maxHeight: "40px",
                    },
                  }}
                />
              </FormControl>
            </div>
            <TextField
              value={recevierCity}
              onChange={(e) => setRecevierCity(e.target.value)}
              size="small"
              label="Province / Eyalet"
            />
            <TextField
              value={recevierTown}
              onChange={(e) => recevierTown(e.target.value)}
              size="small"
              label="Kent"
            />
            <TextField
              value={recevierState}
              onChange={(e) => setRecevierState(e.target.value)}
              size="small"
              label="İlçe"
            />
            <TextField
              value={recevierEmail}
              onChange={(e) => setRecevierEmail(e.target.value)}
              size="small"
              type={"email"}
              label="E-Posta"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "var(--gap)",
            }}
          >
            <Button type="submit" className="iyzi-button-blue">
              Kaydet
            </Button>

            <Button
              type="button"
              className="iyzi-button-blue"
              onClick={handleRecevierClose}
            >
              İptal
            </Button>
          </div>
        </form>
      </Modal>

      <Dialog open={warring} onClose={handleCloseWarring}>
        <DialogTitle sx={{ color: "red" }}>{"Uyarı"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <strong>
              Adreste değişiklik yaptınız bu degişiklik eğer İzleme Takip No
              almadıysanız Tekrar alamnız gerektirir.
            </strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={addressRegisterHandler} color="primary">
            Okudum,Anladım.
          </Button>
          <Button onClick={handleCloseWarring} color="primary">
            Vazgeç
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={recieverWarring} onClose={handleRecevierCloseWarring}>
        <DialogTitle sx={{ color: "red" }}>{"Uyarı"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <strong>
              Adreste değişiklik yaptınız bu degişiklik eğer İzleme Takip No
              almadıysanız Tekrar alamnız gerektirir.
            </strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={addressRecieverUpdate} color="primary">
            Okudum,Anladım.
          </Button>
          <Button onClick={handleRecevierCloseWarring} color="primary">
            Vazgeç
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OperationCargoDomResp;
