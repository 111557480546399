import { Box, Button, ListItem, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  courierRightItemsState,
  deliveredItemState,
} from "../../../atoms/courierRegistration";
import DowloadPdfView from "../../../components/dowloadPdfView/DowloadPdfView";
import uploadIcon from "../../../img/icons/upload_icon.svg";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import BrokenImageRoundedIcon from "@mui/icons-material/BrokenImageRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  TextField,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import Errors from "../../../utils/Errors";

const CourierDeliveredItemsAdmin = () => {
  const deliveredItem = useRecoilValue(deliveredItemState);

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  const [parcelInfo, setParcelInfo] = useState([]);

  const [highlightedItems, setHighlightedItems] = useState([]);

  const [courierRightItems, setCourierRightItems] = useRecoilState(
    courierRightItemsState
  );

  const [leftItems, setLeftItems] = useState([]);

  const [sendFiles, setSendFiles] = useState([]);
  const [description, setDescription] = useState("");
  const [damageDialog, setDamageDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [damageModal, setDamageModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [damagedCheck, setDamagedCheck] = useState(false);
  const [parcelItem, setParcelItem] = useState("");
  const [textError, setTextError] = useState("");
  const [parcelId, setParcelId] = useState("");

  const handleLeftToRight = (item) => {
    setLeftItems((prevItems) => prevItems.filter((i) => i !== item));
    setCourierRightItems((prevItems) => [...prevItems, item]);
  };

  const handleRightToLeft = (item) => {
    setCourierRightItems((prevItems) => prevItems.filter((i) => i !== item));
    setLeftItems((prevItems) => [...prevItems, item]);
  };

  const handleAllRight = () => {
    if (leftItems.length !== 0) {
      setCourierRightItems([...leftItems, ...courierRightItems]);
      setLeftItems([]);
    }
  };

  const handleAllLeft = () => {
    if (courierRightItems.length !== 0) {
      setLeftItems([...leftItems, ...courierRightItems]);
      setCourierRightItems([]);
    }
  };

  const [getParcelId, setGetParcelId] = useState([]);

  const isDamageParcel = (item) => {
    const parcel = getParcelId.find((parcel) => parcel.parcel_ref_no === item);
    if (parcel) {
      const documentTypes = parcel.get_parcel_documents.map((doc) => doc.type);
      const isDamaged = documentTypes.includes("booking_damaged_parcel");
      return isDamaged;
    }
    return false;
  };

  const isCancelParcel = (item) => {
    const parcel = getParcelId.find((parcel) => parcel.parcel_ref_no === item);
    if (parcel) {
      const documentTypes = parcel.get_parcel_documents.map((doc) => doc.type);
      return documentTypes.includes("booking_cancel_parcel");
    }
    return false;
  };

  const handleRemoveItem = (itemToRemove) => {
    const updatedItems = leftItems.filter((item) => item !== itemToRemove);
    setLeftItems(updatedItems);
  };

  const filterParcelData = async () => {
    try {
      const response = await axios.get("parcel", {
        params: {
          member_id: deliveredItem?.member_id,
          detailed: 1,
          select: JSON.stringify({ parcel_ref_no: 1 }),
          where_in_field: "status",
          where_in_array: [0, 0.01, 0.02, 0.03, 0.04],
          booking_id: deliveredItem?.id,
        },
      });

      // Filtreleme işlemi burada yapılıyor
      const filteredParcelRefs = response.data.data
        //.filter((e) => !isCancelParcel(e.parcel_ref_no))
        .map((e) => e.parcel_ref_no);

      setLeftItems(filteredParcelRefs);
    } catch (error) {
      Errors(error);
    }
  };

  useEffect(() => {
    axios
      .get("parcel", {
        params: {
          member_id: deliveredItem?.member_id,
          detailed: 1,
          select: JSON.stringify({ id: 1, parcel_ref_no: 1 }),
          where_in_field: "status",
          where_in_array: [0, 0.01, 0.02, 0.03, 0.04],
          booking_id: deliveredItem?.id,
        },
      })
      .then((response) => {
        setGetParcelId(response.data.data);
      })
      .catch((error) => Errors(error));
  }, [deliveredItem?.member_id, cancelModal, damageModal]);

  useEffect(() => {
    axios
      .get(`booking`, {
        Authorization: `Bearer ${jwt}`,
      })
      .then((response) => {
        setParcelInfo(response.data.data);
      })
      .catch((error) => Errors(error));
  }, [jwt, deliveredItem?.member_id]);

  const [parcelRefNos, setParcelRefNos] = useState([]);

  const filteredParcelInfo = useCallback(
    (id) => {
      const MatchedData = parcelInfo?.filter((order) => order.id === id);
      const parcelRef = MatchedData[0]?.will_be_delivered_parcels?.map(
        (item) => item?.parcel_ref_no
      );
      setParcelRefNos(parcelRef);
    },
    [parcelInfo]
  );

  useEffect(() => {
    filteredParcelInfo(deliveredItem.id);
  }, [jwt, deliveredItem.id, filteredParcelInfo]);

  useEffect(() => {
    const commonItems = leftItems?.filter((item) =>
      parcelRefNos?.includes(item)
    );
    setHighlightedItems(commonItems);
  }, [leftItems, parcelRefNos]);

  const handleDamageDialogClose = () => {
    setDamageDialog(false);
  };
  const handleCancelDialogClose = () => {
    setCancelDialog(false);
  };
  const handleDamageModalOpen = () => {
    setDamageDialog(false);
    setDamageModal(true);
  };
  const handleCancelModalOpen = () => {
    setCancelDialog(false);
    setCancelModal(true);
  };
  const handleDamageDialogOpen = useCallback(
    (item) => {
      setDamageDialog(true);
      setParcelItem(item);
    },
    [setParcelItem]
  );
  const handleCancelDialogOpen = useCallback(
    (item) => {
      setCancelDialog(true);
      setParcelItem(item);
    },
    [setParcelItem]
  );

  const handleDamageModalClose = useCallback(() => {
    setDamageModal(false);
    setDescription("");
    setSendFiles([]);
  }, [setDamageModal, setDescription, setSendFiles]);

  const handleCancelModalClose = useCallback(() => {
    setCancelModal(false);
    setDescription("");
    setSendFiles([]);
  }, [setCancelModal, setDescription, setSendFiles]);

  useEffect(() => {
    const find = getParcelId?.filter(
      (item) => item.parcel_ref_no === parcelItem
    );
    setParcelId(find[0]?.id);
  }, [getParcelId, parcelItem, handleDamageModalClose, setParcelId, parcelId]);

  const handleDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      id: Math.random().toString(),
      maxSize: 2048,
    }));
    setSendFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
  });
  const handleSetState = (id) => {
    setSendFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: " 80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleRegisterDamage = useCallback(() => {
    let formData = new FormData();
    formData.append("description", description);
    Array.from(sendFiles).forEach((file, index) => {
      formData.append(`files[${index}]`, file.file);
    });
    axios
      .post(
        `parcel/mark-booking-parcel-damaged-with-reason/${parcelId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then(() => {
        toast.success("Dosyalar Başarıyla Yüklendi", { autoClose: 300 });
        setDamageModal(false);
        setSendFiles([]);
        setDescription("");
      })
      .catch((error) => Errors(error));
  }, [description, sendFiles, parcelId, jwt]);

  const handleRegisterCancel = useCallback(() => {
    let formData = new FormData();
    formData.append("description", description);
    Array.from(sendFiles).forEach((file, index) => {
      formData.append(`files[${index}]`, file.file);
    });
    axios
      .post(`parcel/cancel-booking-parcel/${parcelId}`, formData, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        toast.success("Dosyalar Başarıyla Yüklendi", { autoClose: 300 });
        handleRemoveItem(response?.data?.data?.parcel_ref_no);
        setCancelModal(false);
        setDamagedCheck(true);
        setSendFiles([]);
        setDescription("");
      })
      .catch((error) => Errors(error));
  }, [description, sendFiles, parcelId, jwt]);

  const handleTextBlur = useCallback(
    (e) => {
      if (e.target.value.length < 2) {
        setTextError(true);
      } else if (e.target.value.length > 2) {
        setTextError(false);
      }
    },
    [setTextError]
  );

  useEffect(() => {
    filterParcelData();
  }, []);

  useEffect(() => {
    handleRemoveItem();
  }, [deliveredItem?.member_id, deliveredItem?.id, damagedCheck]);

  return (
    <Box className="courier-delivered-drag-drop">
      <Dialog
        open={damageDialog}
        onClose={handleDamageDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Hasarlı Gönderi Kaydı"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ fontWeight: 500, fontSize: "16px" }}
            id="alert-dialog-description"
          >
            Hasarlı gönderi kaydı yapmak istiyor musunuz ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="iyzi-button" onClick={handleDamageDialogClose}>
            Hayır
          </Button>
          <Button className="iyzi-button" onClick={handleDamageModalOpen}>
            Evet
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={cancelDialog}
        onClose={handleCancelDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Kurye Kaydı Çıkartma"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ fontWeight: 600, fontSize: "15px" }}
            id="alert-dialog-description"
          >
            Gönderiyi kurye kaydından çıkartıldığında gönderinin kurye kaydı iptal edilecektir.
          </DialogContentText>
          <DialogContentText
            sx={{ fontWeight: 500, fontSize: "14px" }}
            id="alert-dialog-description"
          >
            Gönderiyi kurye kaydından çıkartmak istiyor musunuz?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="iyzi-button" onClick={handleCancelDialogClose}>
            Hayır
          </Button>
          <Button className="iyzi-button" onClick={handleCancelModalOpen}>
            Evet
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={cancelModal}
        onClose={handleCancelModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus
      >
        <Box sx={style}>
          <TextField
            required
            fullWidth
            inputProps={{
              minLength: 2,
            }}
            onBlur={(e) => handleTextBlur(e)}
            error={textError}
            helperText={textError && "Açıklama max 2 karakter olmalı"}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            autoComplete="off"
            type="text"
            label="Açıklama"
            size="small"
            sx={{ marginBottom: "10px" }}
          />
          <Box className="dropzone-wrapper">
            <Box className="dropzone">
              <div className="dropzone-area" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dropzone-area-text">
                  <img src={uploadIcon} alt="Upload Icon" />
                  <span className="dropzone-area-text-headline">
                    Dosyayı sürükleyip bırakın veya{" "}
                    <span style={{ color: "var(--info)" }}>Gözat</span>
                  </span>
                  <span className="dropzone-area-text-sub">
                    Desteklenen Formatlar:
                    DOC,DOCX,XLSX,PDF,TXT,CSV,PNG,JPG,JPEG
                  </span>
                </div>
              </div>
            </Box>
            <DowloadPdfView
              sendFiles={sendFiles}
              handleSetState={handleSetState}
            />
          </Box>
          <Box sx={{ display: " flex", justifyContent: "flex-end" }}>
            <Button
              type="button"
              onClick={handleCancelModalClose}
              className="iyzi-button"
            >
              Vazgeç
            </Button>
            <Button
              onClick={handleRegisterCancel}
              className="iyzi-button"
              sx={{ ml: "10px" }}
            >
              Kaydet
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={damageModal}
        onClose={handleDamageModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus
      >
        <Box sx={style}>
          <TextField
            required
            fullWidth
            inputProps={{
              minLength: 2,
            }}
            onBlur={(e) => handleTextBlur(e)}
            error={textError}
            helperText={textError && "Açıklama max 2 karakter olmalı"}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            autoComplete="off"
            type="text"
            label="Açıklama"
            size="small"
            sx={{ marginBottom: "10px" }}
          />
          <Box className="dropzone-wrapper">
            <Box className="dropzone">
              <div className="dropzone-area" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dropzone-area-text">
                  <img src={uploadIcon} alt="Upload Icon" />
                  <span className="dropzone-area-text-headline">
                    Dosyayı sürükleyip bırakın veya{" "}
                    <span style={{ color: "var(--info)" }}>Gözat</span>
                  </span>
                  <span className="dropzone-area-text-sub">
                    Desteklenen Formatlar:
                    DOC,DOCX,XLSX,PDF,TXT,CSV,PNG,JPG,JPEG
                  </span>
                </div>
              </div>
            </Box>
            <DowloadPdfView
              sendFiles={sendFiles}
              handleSetState={handleSetState}
            />
          </Box>
          <Box sx={{ display: " flex", justifyContent: "flex-end" }}>
            <Button
              type="button"
              onClick={handleDamageModalClose}
              className="iyzi-button"
            >
              Vazgeç
            </Button>
            <Button
              onClick={handleRegisterDamage}
              className="iyzi-button"
              sx={{ ml: "10px" }}
            >
              Kaydet
            </Button>
          </Box>
        </Box>
      </Modal>
      <Box className="courier-items-wrapper">
        <h6 className="courier-items-headline">Kullanıcıya Ait Aktif Yükler</h6>
        <Box className="courier-items-text">
          <span className="courier-items-text-code">
            {deliveredItem.booking_code}
          </span>{" "}
          teslimat koduyla oluşan kurye kaydı sahibinin tüm yüklerinin listesi
          burada yer almaktadır.
        </Box>
        <Box className="courier-items">
          {leftItems.map((item, key) => (
            <Box
              key={key}
              style={{ display: "flex" }}
              className="courier-item-container"
              sx={{
                borderTopRightRadius: highlightedItems.includes(item)
                  ? "0 !important"
                  : "",
                borderBottomRightRadius: highlightedItems.includes(item)
                  ? "0 !important"
                  : "",
              }}
            >
              <ListItem
                onClick={() => handleLeftToRight(item)}
                className="courier-item-container"
                sx={{
                  backgroundColor: highlightedItems.includes(item)
                    ? "var(--pastelBlue) !important"
                    : isCancelParcel(item)
                    ? "var(--notPurple)"
                    : "",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box className="courier-item">
                  AWB <span className="courier-item-awb">{item}</span>
                </Box>
              </ListItem>
              <Tooltip title="Hasarlı Gönderi Kaydı">
                <Button
                  onClick={() => handleDamageDialogOpen(item)}
                  sx={{
                    "&:hover": {
                      backgroundColor: highlightedItems.includes(item)
                        ? "rgba(25, 120, 210, 0.4)  !important"
                        : "transparent !important",
                    },
                    backgroundColor: highlightedItems.includes(item)
                      ? "var(--pastelBlue) !important"
                      : isCancelParcel(item)
                      ? "var(--notPurple)"
                      : "",
                    borderTopLeftRadius: highlightedItems.includes(item)
                      ? "0 !important"
                      : "",
                    borderBottomLeftRadius: highlightedItems.includes(item)
                      ? "0 !important"
                      : "",
                  }}
                >
                  {isDamageParcel(item) ? ( // Check if it's a damaged parcel
                    <BrokenImageRoundedIcon sx={{ color: "red" }} />
                  ) : (
                    <BrokenImageRoundedIcon />
                  )}
                </Button>
              </Tooltip>
              <Tooltip title="Gönderi Çıkar">
                <Button
                  onClick={() => handleCancelDialogOpen(item)}
                  sx={{
                    "&:hover": {
                      backgroundColor: highlightedItems.includes(item)
                        ? "rgba(25, 120, 210, 0.4)  !important"
                        : "transparent !important",
                    },
                    backgroundColor: highlightedItems.includes(item)
                      ? "var(--pastelBlue) !important"
                      : isCancelParcel(item)
                      ? "var(--notPurple)"
                      : "",
                    borderTopLeftRadius: highlightedItems.includes(item)
                      ? "0 !important"
                      : "",
                    borderBottomRLeftRadius: highlightedItems.includes(item)
                      ? "0 !important"
                      : "",
                  }}
                >
                  <CancelIcon />
                </Button>
              </Tooltip>
            </Box>
          ))}
        </Box>
      </Box>

      <Box className="courier-items-changer">
        <Box
          className={
            leftItems.length === 0
              ? "items-changer none"
              : "items-changer has-items"
          }
          onClick={handleAllRight}
        >
          <KeyboardDoubleArrowLeftIcon
            sx={
              leftItems.length === 0
                ? {
                    transform: "rotate(180deg)",
                    color: "var(--inputBorderColor)",
                  }
                : {
                    transform: "rotate(180deg)",
                    color: "var(--info)",
                  }
            }
          />
        </Box>
        <Box
          className={
            courierRightItems.length === 0
              ? "items-changer none"
              : "items-changer has-items"
          }
          onClick={handleAllLeft}
        >
          <KeyboardDoubleArrowLeftIcon
            sx={
              courierRightItems.length === 0
                ? {
                    color: "var(--inputBorderColor)",
                  }
                : {
                    color: "var(--info)",
                  }
            }
          />
        </Box>
      </Box>
      <Box className="courier-items-wrapper">
        <h6 className="courier-items-headline">Teslim Alınan Yükler</h6>
        <Box className="courier-items-text">
          <span className="courier-items-text-code">
            {deliveredItem.booking_code}
          </span>{" "}
          teslimat koduyla teslim alınacak tüm konşimentoların listesi burada
          yer almaktadır.
        </Box>
        <Box className="courier-items">
          {courierRightItems?.map((item, key) => (
            <ListItem
              key={key}
              onClick={() => handleRightToLeft(item)}
              className="courier-item-container"
              sx={{
                backgroundColor: highlightedItems.includes(item)
                  ? "var(--pastelBlue) !important"
                  : isCancelParcel(item)
                  ? "var(--notPurple)"
                  : "",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box className="courier-item">
                AWB <span className="courier-item-awb">{item}</span>
              </Box>
            </ListItem>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CourierDeliveredItemsAdmin;
