import "../../../../style/dropzone/Dropzone.css"
import { Modal, createTheme, ThemeProvider, Box } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  selectedDocumentsState,
  selectedShipmentForMyShipmentsState,
  uploadShipmentDocumentOpenState,
} from "../../../../atoms/myShipments";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import uploadIcon from "../../../../img/icons/upload_icon.svg";
import axios from "../../../../api/axios";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DowloadPdfView from "../../../../components/dowloadPdfView/DowloadPdfView";
import { useState } from "react";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px",
          overflow: "auto",
        },
      },
    },
  },
});

const MyShipmentUpload = () => {
  const [uploadShipmentDocumentOpen, setUploadShipmentDocumentOpen] = useRecoilState(
    uploadShipmentDocumentOpenState
  );
  const [selectedDocuments, setSelectedDocuments] = useRecoilState(selectedDocumentsState);
  const [selectedShipmentForMyShipments, setSelectedShipmentForMyShipments] = useRecoilState(
    selectedShipmentForMyShipmentsState
  );

  const [sendFiles, setSendFiles] = useState([]);

  const handleDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      id: Math.random().toString(),
    }));
    setSendFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setSelectedDocuments((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleSetState = (id) => {
    setSendFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    setSelectedDocuments((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
  });

  const uploadSelected = () => {
    let formData = new FormData();
    Array.from(selectedDocuments).forEach((file, index) => {
      formData.append(`files[${index}]`, file.file);
    });
    formData.append("parent_id", selectedShipmentForMyShipments.id);
    formData.append("parent_type", "Parcel");

    axios.post(`document/add`, formData).then(() => {
      toast.success("Dosyalar Başarıyla Yüklendi", { autoClose: 500 });
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    });
  };

  const getFile = (document) => {
    const pathName = document.path.replaceAll("/", "-");

    axios.get(`files/${pathName}`, { responseType: "arraybuffer" }).then((response) => {
      let blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      let image = window.URL.createObjectURL(blob);

      window.open(image, "_blank");
    });
  };

  const handleClose = () => {
    setUploadShipmentDocumentOpen(false);
    setSelectedShipmentForMyShipments([]);
  };
  return (
    <ThemeProvider theme={theme}>
      <Modal open={uploadShipmentDocumentOpen} onClose={handleClose}>
        <Box
          sx={{
            backgroundColor: "var(--lighterBg)",
            padding: "20px",
            borderRadius: "var(--inputBorderRadius)",
            outline: 0,
            width: "100%",
            maxWidth: "1100px",
            margin: "auto",
            position: "relative",
          }}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              color: "var(--priceText)",
              cursor: "pointer",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleClose}
          />
          <Box className="dropzone-wrapper">
            <Box className="dropzone">
              <div className="dropzone-area" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dropzone-area-text">
                  <img src={uploadIcon} alt="Upload Icon" />
                  <span className="dropzone-area-text-headline">
                    Dosyayı sürükleyip bırakın veya{" "}
                    <span style={{ color: "var(--info)" }}>Gözat</span>
                  </span>
                  <span className="dropzone-area-text-sub">
                    Desteklenen Formatlar: DOC, DOCX, PDF, TXT, CSV, PNG, JPG, JPEG, XLSX
                  </span>
                </div>
              </div>
            </Box>
            <DowloadPdfView sendFiles={sendFiles} handleSetState={handleSetState} />
            <div className="dropzone-uploader-button" onClick={uploadSelected}>
              Kaydet
            </div>
          </Box>
          <Box>
            {selectedShipmentForMyShipments.length !== 0 && (
              <Box
                sx={{
                  color: "var(--priceText)",
                  fontWeight: 700,
                  fontSize: "14px",
                  marginBottom: "20px",
                  marginTop: "20px",
                  paddingLeft: "8px",
                }}
              >
                Yüklenen Dosyalar - {selectedShipmentForMyShipments.get_parcel_documents.length}
              </Box>
            )}
            {selectedShipmentForMyShipments.length !== 0 &&
              selectedShipmentForMyShipments.get_parcel_documents.map((document, key) => (
                <Box
                  sx={{
                    border: "1px solid var(--phoneDigitBg)",
                    marginBottom: "10px",
                    padding: "10px",
                    borderRadius: "var(--wrapperBorderRadius)",
                    fontSize: "13px",
                    cursor: "pointer",
                    color: "var(--priceText)",
                    transition: "all 150ms linear",
                    ":hover": {
                      backgroundColor: "var(--pastelBlue)",
                      transition: "all 150ms linear",
                    },
                  }}
                  key={document.id}
                  onClick={() => getFile(document)}
                >
                  <span
                    style={{
                      fontSize: "13px",
                      color: "var(--priceText)",
                      fontWeight: "700",
                    }}
                  >
                    {key + 1}
                  </span>{" "}
                  - {document.name} / {document.created_at.slice(0, 10).replaceAll("-", "/")} -{" "}
                  {document.created_at.slice(11, 19)}
                </Box>
              ))}
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default MyShipmentUpload;
