import { CircularProgress, TableCell, TableRow, Checkbox, Button } from "@mui/material";
import MessageTime from "../../../../utils/MessageTime";
import { useCallback, useState } from "react";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  faturaFileDataState,
  faturaFileModalState,
  faturaKonsimentoDataState,
  faturaKonsimentoIdState,
  faturaKonsimentoModalState,
  selectedRowsState,
  selectedAllRowsState,
  invoicingSingleModalState,
  selectedRowState,
  financeFaturaInvoicingDataState,
} from "../../../../atoms/finance";
import axios from "../../../../api/axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Errors from "../../../../utils/Errors";

const FinanceFaturaTableBody = ({ item }) => {
  const [fileLoading, setFileLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useRecoilState(selectedRowsState);
  const [selectedRow, setSelectedRow] = useRecoilState(selectedRowState);
  const [invoicingSingleModal, setInvoicingSingleModal] = useRecoilState(invoicingSingleModalState);
  const selectAll = useRecoilValue(selectedAllRowsState);
  const [, setFaturaFileModal] = useRecoilState(faturaFileModalState);
  const [, setFaturaFileData] = useRecoilState(faturaFileDataState);
  const [, setFaturaKonsimentoData] = useRecoilState(faturaKonsimentoDataState);
  const [, setFaturaKonsimentoId] = useRecoilState(faturaKonsimentoIdState);
  const [, setFaturaKonsimentoModal] = useRecoilState(faturaKonsimentoModalState);


  const handleFile = () => {
    setFileLoading(true);
    axios
      .get(`invoice/${item.id}`)
      .then((resp) => {
        setFaturaFileData(resp.data);
        setFaturaFileModal(true);
      })
      .catch((error) => Errors(error))
      .finally(() => setFileLoading(false));
  };

  const sendInvoiceSingle = () => {
    setSelectedRow(item);
    setInvoicingSingleModal(true);
  };

  const seeBillDetails = () => {
    setFaturaKonsimentoData(item.details);
    setFaturaKonsimentoId(item.id);
    setFaturaKonsimentoModal(true);
  };

  const handleRowSelect = (event, row) => {
    if (event.target.checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, row]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((selectedRow) => selectedRow !== row)
      );
    }
  };

  return (
    <TableRow>
      <TableCell>
        <Checkbox
          onChange={(event) => handleRowSelect(event, item)}
          checked={selectedRows.includes(item)}
        />
      </TableCell>
      <TableCell>{item?.uyumsoft_code}</TableCell>
      <TableCell>{item?.member_id}</TableCell>
      <TableCell>{item?.name}</TableCell>
      <TableCell>
        <div
          style={{
            fontSize: "inherit",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          {item?.invoice_count}{" "}
          {loading ? (
            <CircularProgress sx={{ width: "20px!important", height: "20px!important" }} />
          ) : (
            <VisibilityIcon
              onClick={seeBillDetails}
              sx={{
                width: "20px!important",
                height: "20px!important",
                cursor: "pointer",
                color: "var(--btnBgBlue)",
              }}
            />
          )}
        </div>
      </TableCell>
      <TableCell>{Number(item?.invoice_total_price).toFixed(2)}</TableCell>
      <TableCell>
        <Button
          onClick={sendInvoiceSingle}
          sx={{
            justifySelf: "flex-end",
            backgroundColor: "var(--info)",
            color: "var(--lighterBg)",
            textTransform: "none",
            transition: "all 150ms linear",
            borderRadius: "var(--buttonBorderRadius)",
            padding: "5px",
            width: "100px",
            ":hover": {
              backgroundColor: "var(--blueBrand)",
              transition: "all 150ms linear",
            },
          }}
        >
          Fatura Kes
        </Button></TableCell>
    </TableRow>
  );
};

export default FinanceFaturaTableBody;
