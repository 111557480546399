import {
  CircularProgress,
  createTheme,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  selectedShipmentForMyShipmentsState,
  showFinancialDetailsOpenState,
} from "../../../../atoms/myShipments";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import { useCallback, useState } from "react";
import axios from "../../../../api/axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Currency from "../../../../utils/CurrencySymbol";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Errors from "../../../../utils/Errors";
import LocalPrice from "../../../../utils/LocalPrice";
import FormatDateDots from "../../../../utils/FormatDateDots";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px 5px 20px",
          overflow: "auto",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "10px 3px",
          fontSize: "12px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const ShowFinancialDetails = () => {
  const [showFinancialDetailsOpen, setShowFinancialDetailsOpen] = useRecoilState(
    showFinancialDetailsOpenState
  );

  const [selectedShipmentForMyShipments, setSelectedShipmentForMyShipments] = useRecoilState(
    selectedShipmentForMyShipmentsState
  );

  const createData = (
    date,
    hour,
    type,
    price,
    exchangeRate,
    fullName,
    paid,
    paidName,
    paymentDate
  ) => {
    return { date, hour, type, price, exchangeRate, fullName, paid, paidName, paymentDate };
  };

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const getFinancialData = useCallback(() => {
    setLoading(true);
    if (selectedShipmentForMyShipments.length !== 0) {
      axios
        .get(`parcel/finance-information/${selectedShipmentForMyShipments.id}`)
        .then((response) => {
          if (response.data.data === null) {
            toast.error("Finansal Değerler Tablonuz Bulunmamaktadır.");
            setShowFinancialDetailsOpen(false);
          } else {
            setRows(
              response.data.data
                .map((item) =>
                  item.get_details?.map((detail) =>
                    createData(
                      `${detail.created_at.slice(0, 10)}`,
                      `${detail.created_at.slice(11, 19)}`,
                      `${detail.type_name}`,
                      `${LocalPrice(detail.selling_price)} ${Currency(
                        selectedShipmentForMyShipments.currency
                      )}`,
                      `${detail.exchange_rate}`,
                      `${detail?.get_created_by?.name} ${detail?.get_created_by?.surname}`,
                      `${detail.paid}`,
                      `${detail.paid_name}`,
                      `${detail.payment_date}`
                    )
                  )
                )
                .flat()
            );
            setLoading(false);
          }
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    }
  }, [selectedShipmentForMyShipments, setShowFinancialDetailsOpen]);

  const handleClose = () => {
    setShowFinancialDetailsOpen(false);
    setSelectedShipmentForMyShipments([]);
  };

  useEffect(() => {
    if (showFinancialDetailsOpen) {
      getFinancialData();
    }
  }, [getFinancialData, showFinancialDetailsOpen]);

  return (
    <ThemeProvider theme={theme}>
      <Modal open={showFinancialDetailsOpen} onClose={handleClose}>
        {loading ? (
          <div
            style={{
              backgroundColor: "var(--lighterBg)",
              borderRadius: "var(--inputBorderRadius)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
              outline: 0,
              width: "100%",
              maxWidth: "1100px",
              margin: "auto",
              position: "relative",
            }}
          >
            <CancelOutlinedIcon
              sx={{
                position: "absolute",
                right: 1,
                top: 1,
                color: "var(--priceText)",
                cursor: "pointer",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
            <CircularProgress />
          </div>
        ) : (
          <TableContainer
            sx={{
              outline: 0,
              maxWidth: 1100,
              margin: "0 auto",
              position: "relative",
            }}
            component={Paper}
          >
            <CancelOutlinedIcon
              sx={{
                position: "absolute",
                right: 1,
                top: 1,
                color: "var(--priceText)",
                cursor: "pointer",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: 700,
                gap: "var(--gap)",
                padding: "20px",
              }}
            >
              <TrendingUpOutlinedIcon /> Finansal Değerler
            </div>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700, paddingLeft: "20px" }}>Tarih</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Açıklama</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Fiyat</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Oluşturan</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Ödeme Tarihi</TableCell>
                  <TableCell sx={{ fontWeight: 700, paddingRight: "20px" }}>Durum</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, key) => (
                  <TableRow
                    key={key}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      ":nth-of-type(even)": {
                        backgroundColor: "var(--pastelBlue)",
                      },
                    }}
                  >
                    <TableCell component="th" scope="row" sx={{ paddingLeft: "20px" }}>
                      {FormatDateDots(row.date)}
                      <br />
                      {row.hour}
                    </TableCell>
                    <TableCell align="left">{row.type}</TableCell>
                    <TableCell align="left">
                      <div style={{ fontSize: "12px" }}>{row.price}</div>
                      <div style={{ fontSize: "12px" }}>(Kur:&nbsp;{row.exchangeRate})</div>
                    </TableCell>
                    <TableCell align="left">
                      <div
                        style={
                          row.creater === "Sistem"
                            ? {
                                backgroundColor: "var(--info)",
                                color: "var(--lighterBg)",
                                padding: "2px 7px",
                                width: "fit-content",
                                borderRadius: "var(--buttonBorderRadius",
                                fontSize: "12px",
                              }
                            : {
                                backgroundColor: "var(--shipmentYellow)",
                                color: "var(--lighterBg)",
                                padding: "2px 7px",
                                width: "fit-content",
                                borderRadius: "var(--buttonBorderRadius",
                                fontSize: "12px",
                              }
                        }
                      >
                        {row.fullName}
                      </div>
                    </TableCell>
                    <TableCell>{FormatDateDots(row?.paymentDate)}</TableCell>
                    <TableCell align="left" sx={{ paddingRight: "20px" }}>
                      <div
                        style={{
                          backgroundColor:
                            Number(row.paid) === 0 ? "var(--error)" : "var(--greenBrand)",
                          width: "fit-content",
                          borderRadius: "var(--buttonBorderRadius)",
                          color: "var(--lighterBg)",
                          padding: "2px 7px",
                          fontSize: "12px",
                        }}
                      >
                        {row.paidName}
                      </div>
                      <div style={{ fontSize: "12px" }}>(Kur:&nbsp;{row.exchangeRate})</div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Modal>
    </ThemeProvider>
  );
};

export default ShowFinancialDetails;
