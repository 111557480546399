import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
} from "@mui/material";
import MemberDocumentEditProfitMargin from "./MemberDocumentEditProfitMargin";
import AuditHistory from "../../../../utils/AuditHistory";
import axios from "../../../../api/axios";
import { useState } from "react";
import Errors from "../../../../utils/Errors";
import { toast } from "react-toastify";
import getSuperAdminRoleCheck from "../../../../utils/getSuperAdminRoleCheck";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: 20,
          overflowY: "auto",
        },
      },
    },
  },
});

const MemberDocumentEditHeader = ({ member }) => {
  const isSuperAdminRole = getSuperAdminRoleCheck();

  const [loading, setLoading] = useState(false);

  const [historyData, setHistoryData] = useState([]);
  const [historyOpen, setHistoryOpen] = useState(false);

  const getHistory = async () => {
    if (isSuperAdminRole) {
      setHistoryOpen(true);
      setLoading(true);
      await axios
        .get(`member/audit-history/${member?.id}`)
        .then((response) => {
          toast.success(response.data.message);
          setHistoryData(response.data.data);
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    }
  };

  const handleClose = () => {
    setHistoryData([]);
    setHistoryOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "var(--gap2x)",
          backgroundColor: "var(--background)",
          padding: "10px 20px",
          borderRadius: "var(--inputBorderRadius)",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              display: "grid",
              gap: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "var(--gap)",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Typography variant="h5">
                {member?.member_type === "Individual" ? "Bireysel Üyelik" : "Kurumsal Üyelik"} - #
                {member?.id}
              </Typography>
              {loading && (
                <div
                  style={{
                    width: "37px",
                    height: "37px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
                </div>
              )}
              {!loading && (
                <Tooltip title="Değişiklik Tarihçesini Göster">
                  <IconButton
                    disabled={!isSuperAdminRole}
                    sx={{ ":disabled": { opacity: 0.3 }, color: "var(--info)" }}
                    onClick={getHistory}
                  >
                    <HistoryOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <Typography fontSize={13}>
              <span style={{ fontWeight: 700, fontSize: "inherit", color: "var(--info)" }}>
                İsim Soyisim:{" "}
              </span>
              {member?.name}
            </Typography>
            <Typography fontSize={13}>
              <span style={{ fontWeight: 700, fontSize: "inherit", color: "var(--info)" }}>
                Adres:{" "}
              </span>
              {member?.get_member_invoice_address?.address1 &&
                member?.get_member_invoice_address?.address1}{" "}
              {member?.get_member_invoice_address?.address2 &&
                member?.get_member_invoice_address?.address2}{" "}
              {member?.get_member_invoice_address?.address3 &&
                member?.get_member_invoice_address?.address3}{" "}
              {member?.get_member_invoice_address?.state &&
                `${member?.get_member_invoice_address?.state}`}
              {member?.get_member_invoice_address?.town &&
                `, ${member?.get_member_invoice_address?.town}`}
              {member?.get_member_invoice_address?.city &&
                `, ${member?.get_member_invoice_address?.city}`}
              {member?.get_member_invoice_address?.country &&
                `, ${member?.get_member_invoice_address?.country}`}
              {member?.tax_office && `, ${member?.tax_office}`}
            </Typography>
            {member?.company_type && (
              <Typography fontSize={13}>
                <span style={{ fontWeight: 700, fontSize: "inherit", color: "var(--info)" }}>
                  Firma Tipi:{" "}
                </span>
                {member?.company_type === "Individual" ? "Şahıs Firması" : "Tüzel Firma"}
              </Typography>
            )}
            <Typography fontSize={13}>
              <span style={{ fontWeight: 700, fontSize: "inherit", color: "var(--info)" }}>
                Muhasebe Kodu:
              </span>
              &nbsp;
              {member?.uyumsoft_advance_code ? member?.uyumsoft_advance_code : "---"}
            </Typography>
            <Typography fontSize={13}>
              <span style={{ fontWeight: 700, fontSize: "inherit", color: "var(--info)" }}>
                Cari Kodu:
              </span>
              &nbsp;
              {member?.uyumsoft_code ? member?.uyumsoft_code : "---"}
            </Typography>
          </Box>
        </Box>
        <Modal open={historyOpen} onClose={handleClose}>
          <div
            style={{
              maxWidth: "600px",
              width: "100%",
              backgroundColor: "var(--lighterBg)",
              borderRadius: "var(--wrapperBorderRadius)",
              margin: "auto",
            }}
          >
            {historyData.length > 0 && <AuditHistory data={historyData} />}
          </div>
        </Modal>
        <MemberDocumentEditProfitMargin member={member} />
      </Box>
    </ThemeProvider>
  );
};

export default MemberDocumentEditHeader;
