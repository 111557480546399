import { TableCell, TableHead, TableRow, Checkbox } from "@mui/material";
import axios from "../../../../api/axios";
import { useRecoilState } from "recoil";
import {
  financeFaturaInvoicingCurrentPageState,
  financeFaturaInvoicingDataState,
  financeFaturaInvoicingTotalPageState,
  selectedRowsState,
  selectedAllRowsState, financeFaturaInvoicingTotalItemsState, financeFaturaInvoicingTotalPriceState,
} from "../../../../atoms/finance";
import { useCallback, useState } from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Errors from "../../../../utils/Errors";

const FinanceFaturaInvoicingTableHead = () => {
  const [financeFaturaInvoicingData, setFinanceFaturaInvoicingData] = useRecoilState(financeFaturaInvoicingDataState);
  const [, setFinanceFaturaInvoicingTotalPage] = useRecoilState(financeFaturaInvoicingTotalPageState);
  const [, setFinanceFaturaInvoicingTotalItems] = useRecoilState(financeFaturaInvoicingTotalItemsState);
  const [, setFinanceFaturaInvoicingTotalPrice] = useRecoilState(financeFaturaInvoicingTotalPriceState);
  const [financeFaturaInvoicingCurrentPage, setFinanceFaturaInvoicingCurrentPage] = useRecoilState(
    financeFaturaInvoicingCurrentPageState
  );
  const [parsedUspsExcel, setParsedUspsExcel] = useState([]);
  const [selectAll, setSelectAll] = useRecoilState(selectedAllRowsState);
  const [selectedRows, setSelectedRows] = useRecoilState(selectedRowsState);

  const allRows = Object.values(financeFaturaInvoicingData).map(item => item);

  const [activeSort, setActiveSort] = useState([
    { column: "created_at", direction: null },
    { column: "member_invoice_no", direction: null },
    { column: "member_payable_amount", direction: null },
    { column: "invoice_note", direction: null },
    { column: "status", direction: null },
  ]);

  const handlePageChange = useCallback(
    async (orderBy) => {
      const updatedSort = activeSort.map((sort) => {
        if (sort.column === orderBy) {
          return {
            ...sort,
            direction: sort.direction === "ASC" ? "DESC" : "ASC",
          };
        }
        return {
          ...sort,
          direction: null, // Diğer sütunların yönünü varsayılan duruma sıfırlayın
        };
      });

      setActiveSort(updatedSort);

      const currentDirection = updatedSort.find((sort) => sort.column === orderBy).direction;

      await axios
        .get(`refund-compensation`, {
          params: {
            page: financeFaturaInvoicingCurrentPage,
            limit: 100,
            order_by: orderBy,
            order_direction: currentDirection,
          },
        })
        .then((resp) => {
          setFinanceFaturaInvoicingData(resp.data.data);
          setFinanceFaturaInvoicingCurrentPage(resp.data.currentPage);
          setFinanceFaturaInvoicingTotalPage(resp.data.totalPages);
          setFinanceFaturaInvoicingTotalItems(resp.data.totalItems);
          setFinanceFaturaInvoicingTotalPrice(resp.data.totalPrice);
        })
        .catch((error) => Errors(error));
    },
    [
      setFinanceFaturaInvoicingData,
      setFinanceFaturaInvoicingCurrentPage,
      setFinanceFaturaInvoicingTotalPage,
      setFinanceFaturaInvoicingTotalItems,
      setFinanceFaturaInvoicingTotalPrice,
      financeFaturaInvoicingCurrentPage,
      activeSort,
    ]
  );

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(allRows);
    }
    setSelectAll(!selectAll);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{
          fontWeight: "bold"
        }}>
          <Checkbox
            onChange={handleSelectAll}
            checked={selectAll}
          />
        </TableCell> {/* Checkbox to select all */}
        <TableCell sx={{ fontWeight: 700 }}>Muhasebe Kodu</TableCell>
        <TableCell
          onClick={() => handlePageChange("member_payable_amount")}
          sx={{ fontWeight: 700, cursor: "pointer" }}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Müşteri ID{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "member_payable_amount")?.direction ===
                    "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Müşteri Adı</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Fatura Gönderi Adedi</TableCell>
        <TableCell
          onClick={() => handlePageChange("member_payable_amount")}
          sx={{ fontWeight: 700, cursor: "pointer" }}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Fatura Tutarı{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "member_payable_amount")?.direction ===
                    "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          sx={{ fontWeight: 700 }}
        >
          Fatura Kesme
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default FinanceFaturaInvoicingTableHead;
