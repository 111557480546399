import { IconButton, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

const ExchangeRateHead = ({ handleAdd }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="center" style={{ padding: "8px" }}>
          <Tooltip title="Kur Ekle">
            <IconButton onClick={handleAdd}>
              <AddCircleOutlineOutlinedIcon sx={{ color: "var(--btnBgBlue)" }} />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell style={{ padding: "8px", fontWeight: 700 }}>Kur Tipi</TableCell>
        <TableCell style={{ padding: "8px", fontWeight: 700 }}>Kur Tarihi</TableCell>
        <TableCell style={{ padding: "8px", fontWeight: 700 }}>Para Birimi</TableCell>
        <TableCell style={{ padding: "8px", fontWeight: 700 }}>Alış Kuru</TableCell>
        <TableCell style={{ padding: "8px", fontWeight: 700 }}>Satış Kuru</TableCell>
        <TableCell style={{ padding: "8px", fontWeight: 700 }}>Oluşturulma Tarihi</TableCell>
        <TableCell style={{ padding: "8px", fontWeight: 700 }}>Değişiklik Tarihçesi</TableCell>
        <TableCell style={{ padding: "8px" }} />
      </TableRow>
    </TableHead>
  );
};

export default ExchangeRateHead;
