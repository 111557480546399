import "../../../../style/myShipments/ShipmentDimensionDetail.css";
import {
  Modal, IconButton, Accordion,
  AccordionDetails,
  Avatar,
  AccordionSummary, Typography, Box, createTheme, ThemeProvider
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useRecoilState } from "recoil";
import { adminShipmentContractDetailsState } from "../../../../atoms/myShipmentsAdmin";
import FormatDateHour from "../../../../utils/FormatDateHour";
import { useCallback } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Currency from "../../../../utils/CurrencySymbol";

const modalTheme = createTheme({
  components: {
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "10px",
          margin: "0 !important",
          ":before": {
            height: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: "48px!important",
        },
        content: {
          margin: "0 !important",
        },
      },
    },
  },
});

const ShipmentContractDetails = () => {
  const [shipmentContractDetails, setShipmentContractDetails] = useRecoilState(
    adminShipmentContractDetailsState
  );

  const handleClose = useCallback(() => {
    setShipmentContractDetails({ status: false, data: {} });
  }, [setShipmentContractDetails]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Modal
        className="shipment-dimension-detail-modal"
        open={shipmentContractDetails.status}
        onClose={handleClose}
      >
        <div style={{ maxHeight: "90vh", overflowY: "auto" }} className="shipment-dimension-detail-wrapper">
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              width: " 100%",
              height: "25px",
            }}
          >
            <IconButton
              onClick={handleClose}
              aria-label="kapat"
              sx={{
                "&:hover": {
                  backgroundColor: "transparent;",
                },
              }}
            >
              <HighlightOffOutlinedIcon sx={{ fontSize: "24px" }} />
            </IconButton>
          </div>
          <div className="shipment-dimension-detail">
            <div className="dimension-detail-per-side-wrapper">
              <div className="per-side-headline customer">Get Contract</div>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px"
              }}>

                <div className="dimension-detail-per-side">
                  <div className="detail-per-side">
                    <div className="per-side-subline">
                      <p>
                        Created At: {FormatDateHour(shipmentContractDetails.data?.get_contract?.created_at)}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        User Id: {shipmentContractDetails.data?.get_contract?.user_id}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        From Type: {Number(shipmentContractDetails.data?.get_contract?.from_type) === 1 ? "Kullanıcı" : Number(shipmentContractDetails.data?.get_contract?.from_type) === 2 ? "Admin" : "-"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="dimension-detail-per-side">
                  <div className="detail-per-side">
                    <div className="per-side-subline">
                      <p>
                        Id: {shipmentContractDetails.data?.get_contract?.id}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Contract Code: {shipmentContractDetails.data?.get_contract?.contract_code
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div className="dimension-detail-per-side">
                  <div className="detail-per-side">
                    <div className="per-side-subline">
                      <p>
                        Document Type: {shipmentContractDetails.data?.get_contract?.document_type}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="dimension-detail-per-side">
                  <div className="detail-per-side">
                    <div className="per-side-subline">
                      <p>
                        Country Code From: {shipmentContractDetails.data?.get_contract?.country_code_from}

                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Country Code To: {shipmentContractDetails.data?.get_contract?.country_code_to}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="dimension-detail-per-side">
                  <div className="detail-per-side">
                    <div className="per-side-subline">
                      <p>
                        Product Value: {shipmentContractDetails.data?.get_contract?.product_value}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Product Value Currency: {Currency(shipmentContractDetails.data?.get_contract?.product_value_currency)}
                      </p>
                    </div>
                  </div>
                </div>
                <Accordion
                  sx={{ backgroundColor: "var(--background)", borderRadius: "var(--inputBorderRadius)" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h5">Dimensions</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "var(--gap2x)",
                        backgroundColor: "var(--background)",
                        padding: "10px 20px",
                        borderRadius: "var(--inputBorderRadius)",
                      }}
                    >
                      {/* <p>
                        DDP: {shipmentContractDetails.data?.get_contract?.ddp?.ddp}
                      </p>
                      <p>
                        Ex Custom Price: {shipmentContractDetails.data?.get_contract?.ddp?.ex_custom_price}
                      </p> */}
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <div className="dimension-detail-per-side">
                  <div className="detail-per-side">
                    <div className="per-side-subline">
                      <p>
                        Total GW: {shipmentContractDetails.data?.get_contract?.total_gw}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Total VW: {shipmentContractDetails.data?.get_contract?.total_vw}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Total CW: {shipmentContractDetails.data?.get_contract?.total_cw}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Total DS: {shipmentContractDetails.data?.get_contract?.total_ds}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Total QTY: {shipmentContractDetails.data?.get_contract?.total_qty}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="dimension-detail-per-side">
                  <div className="detail-per-side">
                    <div className="per-side-subline">
                      <p>
                        Price id: {shipmentContractDetails.data?.get_contract?.price_id}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Master List: {shipmentContractDetails.data?.get_contract?.master_list}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Area: {shipmentContractDetails.data?.get_contract?.area}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="dimension-detail-per-side">
                  <div style={{
                    height: "350px"
                  }} className="detail-per-side">
                    <div className="per-side-subline">
                      <Avatar
                        className="step-shipment-logo-css"
                        src={`/images/${shipmentContractDetails.data?.get_contract?.supplier_logo}`}
                      />
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Supplier id: {shipmentContractDetails.data?.get_contract?.supplier_id}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Supplier Name: {shipmentContractDetails.data?.get_contract?.supplier_name}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Supplier Note EN:  {shipmentContractDetails.data?.get_contract?.supplier_note_en}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Supplier Note TR:  {shipmentContractDetails.data?.get_contract?.supplier_note_tr}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        IOSS: {shipmentContractDetails.data?.get_contract?.ioss}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Transit: {shipmentContractDetails.data?.get_contract?.transit}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Service Type: {shipmentContractDetails.data?.get_contract?.service_type}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Currency:  {Currency(shipmentContractDetails.data?.get_contract?.currency)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="dimension-detail-per-side">
                  <div className="detail-per-side">
                    <div className="per-side-subline">
                      <p>
                        Area Price: {shipmentContractDetails.data?.get_contract?.area_price}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="dimension-detail-per-side">
                  <div className="detail-per-side">
                    <div className="per-side-subline">
                      <p>
                        Cost Price: {shipmentContractDetails.data?.get_contract?.cost_price}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="dimension-detail-per-side">
                  <div className="detail-per-side">
                    <div className="per-side-subline">
                      <p>
                        Navlun Cost Price: {shipmentContractDetails.data?.get_contract?.navlun_cost_price}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="dimension-detail-per-side">
                  <div className="detail-per-side">
                    <div className="per-side-subline">
                      <p>
                        Navlun Selling Price: {shipmentContractDetails.data?.get_contract?.navlun_selling_price}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="dimension-detail-per-side">
                  <div className="detail-per-side">
                    <div className="per-side-subline">
                      <p>
                        Selling Price: {shipmentContractDetails.data?.get_contract?.selling_price}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="dimension-detail-per-side">
                  <div className="detail-per-side">
                    <div className="per-side-subline">
                      <p>
                        Exchange Rate: {shipmentContractDetails.data?.get_contract?.exchange_rate}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="dimension-detail-per-side">
                  <div className="detail-per-side">
                    <div className="per-side-subline">
                      <p>
                        Domestic Supplier Name: {shipmentContractDetails.data?.get_contract?.domestic_supplier_name}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Domestic Price: {shipmentContractDetails.data?.get_contract?.domestic_price}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Domestic Price Try: {shipmentContractDetails.data?.get_contract?.domestic_price_try}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Domestic Cost Price: {shipmentContractDetails.data?.get_contract?.domestic_cost_price}
                      </p>
                    </div>
                    <div className="per-side-subline">
                      <p>
                        Domestic Cost Price Try: {shipmentContractDetails.data?.get_contract?.domestic_cost_price_try}
                      </p>
                    </div>
                  </div>
                </div>

                <Accordion
                      sx={{ backgroundColor: "var(--background)", borderRadius: "var(--inputBorderRadius)" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5">Price Details</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "var(--gap2x)",
                            backgroundColor: "var(--background)",
                            padding: "10px 20px",
                            borderRadius: "var(--inputBorderRadius)",
                          }}
                        >
                          {/* <p>
                            Compansation_Fee-OOA_A: {shipmentContractDetails.data?.get_contract?.price_details?.Compansation_Fee-OOA_A}
                          </p> */}
                          <p>
                            Covid: {shipmentContractDetails.data?.get_contract?.price_details?.Covid}
                          </p>
                          <p>
                            EPH: {shipmentContractDetails.data?.get_contract?.price_details?.EPH}
                          </p>
                          <p>
                            FSC: {shipmentContractDetails.data?.get_contract?.price_details?.FSC}
                          </p>
                          <p>
                            Base Price: {shipmentContractDetails.data?.get_contract?.price_details?.base_price}
                          </p>
                          <p>
                            Beher Price: {shipmentContractDetails.data?.get_contract?.price_details?.beher_price}
                          </p>
                          <p>
                            Fix Profit Price: {shipmentContractDetails.data?.get_contract?.price_details?.fix_profit_price}
                          </p>
                          <p>
                            Member Profit Price: {shipmentContractDetails.data?.get_contract?.price_details?.member_profit_price}
                          </p>
                          <p>
                            Navlun Cost Price: {shipmentContractDetails.data?.get_contract?.price_details?.navlun_cost_price}
                          </p>
                          <p>
                            Navlun Selling Price: {shipmentContractDetails.data?.get_contract?.price_details?.navlun_selling_price}
                          </p>
                          <p>
                            Selling Price: {shipmentContractDetails.data?.get_contract?.price_details?.selling_price}
                          </p>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ backgroundColor: "var(--background)", borderRadius: "var(--inputBorderRadius)" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5">DDP</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "var(--gap2x)",
                            backgroundColor: "var(--background)",
                            padding: "10px 20px",
                            borderRadius: "var(--inputBorderRadius)",
                          }}
                        >
                          <p>
                            Currency: {shipmentContractDetails.data?.get_contract?.ddp?.currency}
                          </p>
                          <p>
                            DDP: {shipmentContractDetails.data?.get_contract?.ddp?.ddp}
                          </p>
                          <p>
                            Ex Custom Price: {shipmentContractDetails.data?.get_contract?.ddp?.ex_custom_price}
                          </p>

                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ backgroundColor: "var(--background)", borderRadius: "var(--inputBorderRadius)" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5">Insurance</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "var(--gap2x)",
                            backgroundColor: "var(--background)",
                            padding: "10px 20px",
                            borderRadius: "var(--inputBorderRadius)",
                          }}
                        >
                          <p>
                            Insurance: {shipmentContractDetails.data?.get_contract?.insurance?.
                              insurance_available
                            }
                          </p>
                          <p>
                            Insurance Price: {shipmentContractDetails.data?.get_contract?.insurance?.
                              insurance_price
                            }
                          </p>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ backgroundColor: "var(--background)", borderRadius: "var(--inputBorderRadius)" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5">Lucid</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "var(--gap2x)",
                            backgroundColor: "var(--background)",
                            padding: "10px 20px",
                            borderRadius: "var(--inputBorderRadius)",
                          }}
                        >
                          <p>
                            Currency: {shipmentContractDetails.data?.get_contract?.lucid_price_detail?.
                              currency
                            }
                          </p>
                          <p>
                            Lucid: {shipmentContractDetails.data?.get_contract?.lucid_price_detail?.
                              lucid
                            }
                          </p>
                          <p>
                            Lucid Price: {shipmentContractDetails.data?.get_contract?.lucid_price_detail?.
                              lucid_price
                            }
                          </p>
                          <p>

                          </p>

                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ backgroundColor: "var(--background)", borderRadius: "var(--inputBorderRadius)" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5">Extra Charge Information</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "var(--gap2x)",
                            backgroundColor: "var(--background)",
                            padding: "10px 20px",
                            borderRadius: "var(--inputBorderRadius)",
                          }}
                        >
                          {/* <p>
                        Lucid: {shipmentContractDetails.data?.get_contract?.lucid_price_detail?.
                          lucid
                        }
                      </p>
                      <p>
                        Lucid Price: {shipmentContractDetails.data?.get_contract?.lucid_price_detail?.
                          lucid_price
                        }
                      </p>
                      <p>

                      </p> */}

                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ backgroundColor: "var(--background)", borderRadius: "var(--inputBorderRadius)" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5">Return Price Detail</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "var(--gap2x)",
                            backgroundColor: "var(--background)",
                            padding: "10px 20px",
                            borderRadius: "var(--inputBorderRadius)",
                          }}
                        >
                          <p>
                            Return Price: {shipmentContractDetails.data?.get_contract?.return_price_detail?.return_price}
                          </p>
                          <p>
                            Return Price Txt: {shipmentContractDetails.data?.get_contract?.return_price_detail?.return_price_txt}
                          </p>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
              </Box>
            </div>

            <div className="dimension-detail-per-side-wrapper">
              <div className="per-side-headline iyzi">Get Real Contract</div>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px"
              }}>
                {
                  shipmentContractDetails.data?.get_real_contract !== null &&
                  <>
                    <div className="dimension-detail-per-side">
                      <div className="detail-per-side">
                        <div className="per-side-subline">
                          <p>
                            Created At: {FormatDateHour(shipmentContractDetails.data?.get_real_contract?.created_at)}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            User Id: {shipmentContractDetails.data?.get_real_contract?.user_id}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            From Type: {Number(shipmentContractDetails.data?.get_real_contract?.from_type) === 1 ? "Kullanıcı" : Number(shipmentContractDetails.data?.get_real_contract?.from_type) === 2 ? "Admin" : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="dimension-detail-per-side">
                      <div className="detail-per-side">
                        <div className="per-side-subline">
                          <p>
                            Id: {shipmentContractDetails.data?.get_real_contract?.id}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Contract Code: {shipmentContractDetails.data?.get_real_contract?.contract_code
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="dimension-detail-per-side">
                      <div className="detail-per-side">
                        <div className="per-side-subline">
                          <p>
                            Document Type: {shipmentContractDetails.data?.get_real_contract?.document_type}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="dimension-detail-per-side">
                      <div className="detail-per-side">
                        <div className="per-side-subline">
                          <p>
                            Country Code From: {shipmentContractDetails.data?.get_real_contract?.country_code_from}

                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Country Code To: {shipmentContractDetails.data?.get_real_contract?.country_code_to}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="dimension-detail-per-side">
                      <div className="detail-per-side">
                        <div className="per-side-subline">
                          <p>
                            Product Value: {shipmentContractDetails.data?.get_real_contract?.product_value}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Product Value Currency: {Currency(shipmentContractDetails.data?.get_real_contract?.product_value_currency)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <Accordion
                      sx={{ backgroundColor: "var(--background)", borderRadius: "var(--inputBorderRadius)" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5">Dimensions</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "var(--gap2x)",
                            backgroundColor: "var(--background)",
                            padding: "10px 20px",
                            borderRadius: "var(--inputBorderRadius)",
                          }}
                        >
                          {/* <p>
                        DDP: {shipmentContractDetails.data?.get_real_contract?.ddp?.ddp}
                      </p>
                      <p>
                        Ex Custom Price: {shipmentContractDetails.data?.get_real_contract?.ddp?.ex_custom_price}
                      </p> */}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <div className="dimension-detail-per-side">
                      <div className="detail-per-side">
                        <div className="per-side-subline">
                          <p>
                            Total GW: {shipmentContractDetails.data?.get_real_contract?.total_gw}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Total VW: {shipmentContractDetails.data?.get_real_contract?.total_vw}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Total CW: {shipmentContractDetails.data?.get_real_contract?.total_cw}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Total DS: {shipmentContractDetails.data?.get_real_contract?.total_ds}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Total QTY: {shipmentContractDetails.data?.get_real_contract?.total_qty}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="dimension-detail-per-side">
                      <div className="detail-per-side">
                        <div className="per-side-subline">
                          <p>
                            Price id: {shipmentContractDetails.data?.get_real_contract?.price_id}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Master List: {shipmentContractDetails.data?.get_real_contract?.master_list}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Area: {shipmentContractDetails.data?.get_real_contract?.area}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="dimension-detail-per-side">
                      <div style={{
                        height: "350px"
                      }} className="detail-per-side">
                        <div className="per-side-subline">
                          <Avatar
                            className="step-shipment-logo-css"
                            src={`/images/${shipmentContractDetails.data?.get_real_contract?.supplier_logo}`}
                          />
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Supplier id: {shipmentContractDetails.data?.get_real_contract?.supplier_id}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Supplier Name: {shipmentContractDetails.data?.get_real_contract?.supplier_name}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Supplier Note EN:  {shipmentContractDetails.data?.get_real_contract?.supplier_note_en}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Supplier Note TR:  {shipmentContractDetails.data?.get_real_contract?.supplier_note_tr}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            IOSS: {shipmentContractDetails.data?.get_real_contract?.ioss}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Transit: {shipmentContractDetails.data?.get_real_contract?.transit}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Service Type: {shipmentContractDetails.data?.get_real_contract?.service_type}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Currency:  {Currency(shipmentContractDetails.data?.get_real_contract?.currency)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="dimension-detail-per-side">
                      <div className="detail-per-side">
                        <div className="per-side-subline">
                          <p>
                            Area Price: {shipmentContractDetails.data?.get_real_contract?.area_price}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="dimension-detail-per-side">
                      <div className="detail-per-side">
                        <div className="per-side-subline">
                          <p>
                            Cost Price: {shipmentContractDetails.data?.get_real_contract?.cost_price}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="dimension-detail-per-side">
                      <div className="detail-per-side">
                        <div className="per-side-subline">
                          <p>
                            Navlun Cost Price: {shipmentContractDetails.data?.get_real_contract?.navlun_cost_price}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="dimension-detail-per-side">
                      <div className="detail-per-side">
                        <div className="per-side-subline">
                          <p>
                            Navlun Selling Price: {shipmentContractDetails.data?.get_real_contract?.navlun_selling_price}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="dimension-detail-per-side">
                      <div className="detail-per-side">
                        <div className="per-side-subline">
                          <p>
                            Selling Price: {shipmentContractDetails.data?.get_real_contract?.selling_price}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="dimension-detail-per-side">
                      <div className="detail-per-side">
                        <div className="per-side-subline">
                          <p>
                            Exchange Rate: {shipmentContractDetails.data?.get_real_contract?.exchange_rate}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="dimension-detail-per-side">
                      <div className="detail-per-side">
                        <div className="per-side-subline">
                          <p>
                            Domestic Supplier Name: {shipmentContractDetails.data?.get_real_contract?.domestic_supplier_name}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Domestic Price: {shipmentContractDetails.data?.get_real_contract?.domestic_price}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Domestic Price Try: {shipmentContractDetails.data?.get_real_contract?.domestic_price_try}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Domestic Cost Price: {shipmentContractDetails.data?.get_real_contract?.domestic_cost_price}
                          </p>
                        </div>
                        <div className="per-side-subline">
                          <p>
                            Domestic Cost Price Try: {shipmentContractDetails.data?.get_real_contract?.domestic_cost_price_try}
                          </p>
                        </div>
                      </div>
                    </div>

                    <Accordion
                      sx={{ backgroundColor: "var(--background)", borderRadius: "var(--inputBorderRadius)" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5">Price Details</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "var(--gap2x)",
                            backgroundColor: "var(--background)",
                            padding: "10px 20px",
                            borderRadius: "var(--inputBorderRadius)",
                          }}
                        >
                          {/* <p>
                            Compansation_Fee-OOA_A: {shipmentContractDetails.data?.get_real_contract?.price_details?.Compansation_Fee-OOA_A}
                          </p> */}
                          <p>
                            Covid: {shipmentContractDetails.data?.get_real_contract?.price_details?.Covid}
                          </p>
                          <p>
                            EPH: {shipmentContractDetails.data?.get_real_contract?.price_details?.EPH}
                          </p>
                          <p>
                            FSC: {shipmentContractDetails.data?.get_real_contract?.price_details?.FSC}
                          </p>
                          <p>
                            Base Price: {shipmentContractDetails.data?.get_real_contract?.price_details?.base_price}
                          </p>
                          <p>
                            Beher Price: {shipmentContractDetails.data?.get_real_contract?.price_details?.beher_price}
                          </p>
                          <p>
                            Fix Profit Price: {shipmentContractDetails.data?.get_real_contract?.price_details?.fix_profit_price}
                          </p>
                          <p>
                            Member Profit Price: {shipmentContractDetails.data?.get_real_contract?.price_details?.member_profit_price}
                          </p>
                          <p>
                            Navlun Cost Price: {shipmentContractDetails.data?.get_real_contract?.price_details?.navlun_cost_price}
                          </p>
                          <p>
                            Navlun Selling Price: {shipmentContractDetails.data?.get_real_contract?.price_details?.navlun_selling_price}
                          </p>
                          <p>
                            Selling Price: {shipmentContractDetails.data?.get_real_contract?.price_details?.selling_price}
                          </p>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ backgroundColor: "var(--background)", borderRadius: "var(--inputBorderRadius)" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5">DDP</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "var(--gap2x)",
                            backgroundColor: "var(--background)",
                            padding: "10px 20px",
                            borderRadius: "var(--inputBorderRadius)",
                          }}
                        >
                          <p>
                            Currency: {shipmentContractDetails.data?.get_real_contract?.ddp?.currency}
                          </p>
                          <p>
                            DDP: {shipmentContractDetails.data?.get_real_contract?.ddp?.ddp}
                          </p>
                          <p>
                            Ex Custom Price: {shipmentContractDetails.data?.get_real_contract?.ddp?.ex_custom_price}
                          </p>

                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ backgroundColor: "var(--background)", borderRadius: "var(--inputBorderRadius)" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5">Insurance</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "var(--gap2x)",
                            backgroundColor: "var(--background)",
                            padding: "10px 20px",
                            borderRadius: "var(--inputBorderRadius)",
                          }}
                        >
                          <p>
                            Insurance: {shipmentContractDetails.data?.get_real_contract?.insurance?.
                              insurance_available
                            }
                          </p>
                          <p>
                            Insurance Price: {shipmentContractDetails.data?.get_real_contract?.insurance?.
                              insurance_price
                            }
                          </p>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ backgroundColor: "var(--background)", borderRadius: "var(--inputBorderRadius)" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5">Lucid</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "var(--gap2x)",
                            backgroundColor: "var(--background)",
                            padding: "10px 20px",
                            borderRadius: "var(--inputBorderRadius)",
                          }}
                        >
                          <p>
                            Currency: {shipmentContractDetails.data?.get_real_contract?.lucid_price_detail?.
                              currency
                            }
                          </p>
                          <p>
                            Lucid: {shipmentContractDetails.data?.get_real_contract?.lucid_price_detail?.
                              lucid
                            }
                          </p>
                          <p>
                            Lucid Price: {shipmentContractDetails.data?.get_real_contract?.lucid_price_detail?.
                              lucid_price
                            }
                          </p>
                          <p>

                          </p>

                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ backgroundColor: "var(--background)", borderRadius: "var(--inputBorderRadius)" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5">Extra Charge Information</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "var(--gap2x)",
                            backgroundColor: "var(--background)",
                            padding: "10px 20px",
                            borderRadius: "var(--inputBorderRadius)",
                          }}
                        >
                          {/* <p>
                        Lucid: {shipmentContractDetails.data?.get_real_contract?.lucid_price_detail?.
                          lucid
                        }
                      </p>
                      <p>
                        Lucid Price: {shipmentContractDetails.data?.get_real_contract?.lucid_price_detail?.
                          lucid_price
                        }
                      </p>
                      <p>

                      </p> */}

                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ backgroundColor: "var(--background)", borderRadius: "var(--inputBorderRadius)" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5">Return Price Detail</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "var(--gap2x)",
                            backgroundColor: "var(--background)",
                            padding: "10px 20px",
                            borderRadius: "var(--inputBorderRadius)",
                          }}
                        >
                          <p>
                            Return Price: {shipmentContractDetails.data?.get_real_contract?.return_price_detail?.return_price}
                          </p>
                          <p>
                            Return Price Txt: {shipmentContractDetails.data?.get_real_contract?.return_price_detail?.return_price_txt}
                          </p>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </>
                }
                {
                  shipmentContractDetails.data?.get_real_contract === null &&
                  "iyziTeam kontratı oluşturulmamıştır."
                }


              </Box>
            </div>
          </div>
        </div>
      </Modal>
    </ThemeProvider>
  );
};

export default ShipmentContractDetails;
