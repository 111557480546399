import { Button, CircularProgress, Pagination } from "@mui/material";
import { useCallback, useState } from "react";
import axios from "../../../../api/axios";
import Errors from "../../../../utils/Errors";
import { memberDocumentEditOpenState, memberListState } from "../../../../atoms/members";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";

const MemberDocumentEditAddresses = ({
  invoiceAddresses,
  bookingAddresses,
  senderAddresses,
  receiverAddresses,
  member,
  senderCurrentPage,
  senderTotalPages,
  receiverCurrentPage,
  receiverTotalPages,
  setSenderCurrentPage,
  setSenderTotalPages,
  setReceiverCurrentPage,
  setReceiverTotalPages,
  setSenderAddresses,
  setReceiverAddresses,
  addressesLimit,
}) => {
  const [memberList, setMemberList] = useRecoilState(memberListState);
  const [memberDocumentEditOpen, setMemberDocumentEditOpen] = useRecoilState(
    memberDocumentEditOpenState
  );

  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [bookingLoading, setBookingLoading] = useState(false);
  const [senderLoading, setSenderLoading] = useState(false);
  const [receiverLoading, setReceiverLoading] = useState(false);

  const handleApproveAddress = useCallback(
    async (id, addressType) => {
      if (addressType === 1) {
        setInvoiceLoading(true);
      } else if (addressType === 2) {
        setBookingLoading(true);
      }

      await axios
        .post(`member-address/approve-member-address/${id}`)
        .then((response) => {
          toast.success(response.data.message);

          const updatedArray = memberList?.map((item) => {
            if (item.id === member?.id) {
              // gelen veriyi bu öğeye ata
              if (addressType === 1) {
                return {
                  ...item,
                  get_member_invoice_addresses: response.data.data,
                  get_member_invoice_address: response.data.data.find(
                    (address) => Number(address.is_active) === 1 && Number(address.is_default) === 1
                  ),
                };
              } else if (addressType === 2) {
                return {
                  ...item,
                  get_member_booking_addresses: response.data.data,
                };
              }
            }
            // diğer öğeleri olduğu gibi döndür
            return item;
          });

          // güncellenmiş diziyi state'e kaydet
          setMemberList(updatedArray);

          let updatedObject;

          if (addressType === 1) {
            updatedObject = {
              ...memberDocumentEditOpen.data, // mevcut objenin tüm özelliklerini al
              get_member_invoice_addresses: response.data.data, // get_member_invoice_addresses özelliğini yeni array ile güncelle
              get_member_invoice_address: response.data.data.find(
                (address) => Number(address.is_active) === 1 && Number(address.is_default) === 1
              ),
            };
          } else if (addressType === 2) {
            updatedObject = {
              ...memberDocumentEditOpen.data, // mevcut objenin tüm özelliklerini al
              get_member_booking_addresses: response.data.data, // get_member_invoice_addresses özelliğini yeni array ile güncelle
            };
          }

          setMemberDocumentEditOpen({ status: true, data: updatedObject });
        })
        .catch((error) => Errors(error))
        .finally(() => {
          if (addressType === 1) {
            setInvoiceLoading(false);
          } else if (addressType === 2) {
            setBookingLoading(false);
          }
        });
    },
    [member, memberList, setMemberList, memberDocumentEditOpen, setMemberDocumentEditOpen]
  );

  const handleSenderPageChange = (e, pageValue) => {
    setSenderLoading(true);

    axios
      .get(`member-address`, {
        params: { member_id: member.id, address_type: 4, limit: addressesLimit, page: pageValue },
      })
      .then((response) => {
        setSenderAddresses(response.data.data);
        setSenderCurrentPage(pageValue);
        setSenderTotalPages(response.data.totalPages);
      })
      .catch((error) => Errors(error))
      .finally(() => setSenderLoading(false));
  };

  const handleReceiverPageChange = (e, pageValue) => {
    setReceiverLoading(true);

    axios
      .get(`member-address`, {
        params: { member_id: member.id, address_type: 5, limit: addressesLimit, page: pageValue },
      })
      .then((response) => {
        setReceiverAddresses(response.data.data);
        setReceiverCurrentPage(pageValue);
        setReceiverTotalPages(response.data.totalPages);
      })
      .catch((error) => Errors(error))
      .finally(() => setReceiverLoading(false));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "var(--gap2x)" }}>
      <div
        style={{
          boxShadow: "var(--shadow)",
          padding: "10px",
          borderRadius: "var(--wrapperBorderRadius)",
          display: "flex",
          flexDirection: "column",
          gap: "var(--gap)",
        }}
      >
        <h6 style={{ color: "var(--priceText)", fontSize: "16px" }}>Fatura Adresi</h6>
        {invoiceAddresses?.length === 0 && "Kayıtlı fatura adresi bulunmamaktadır."}
        {invoiceAddresses?.length !== 0 && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              gap: "var(--gap2x)",
            }}
          >
            {invoiceAddresses?.map((address, key) => (
              <div
                style={{
                  color: "var(--priceText)",
                  fontSize: "13px",
                  padding: "10px",
                  borderRadius: "var(--wrapperBorderRadius)",
                  border:
                    Number(address.is_default) === 1 && Number(address.is_active) === 1
                      ? "2px solid var(--info)"
                      : "1px solid var(--inputBorderColor)",
                }}
                key={key}
              >
                <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                  <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                    Başlık:{" "}
                  </span>
                  {address?.address_description ? address?.address_description : "---"}
                </div>
                <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                  <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                    Kontak:{" "}
                  </span>
                  {address?.contact_name ? address?.contact_name : "---"}
                </div>
                <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                  <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                    Email:{" "}
                  </span>
                  {address?.email ? address?.email : "---"}
                </div>
                <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                  <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                    Telefon:{" "}
                  </span>
                  {address?.phone ? address?.phone : "---"}
                </div>
                <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                  <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                    Adres Satırı:{" "}
                  </span>
                  {address?.address1 && address?.address1} {address?.address2 && address?.address2}{" "}
                  {address?.address3 && address?.address3} {address?.state && `${address?.state}`}
                </div>
                <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                  <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                    Adres Bölgesi:{" "}
                  </span>
                  {address?.town && `, ${address?.town}`}
                  {address?.city && `, ${address?.city}`}
                  {address?.country && `, ${address?.country}`}
                  {address?.postal_code && `, ${address?.postal_code}`}
                  {address?.state_code && `, ${address?.state_code}`}
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                  {Number(address.is_default) === 0 &&
                    Number(address.is_active) === 0 &&
                    (invoiceLoading ? (
                      <div
                        style={{
                          width: "64px",
                          height: "36px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress
                          sx={{ width: "21px!important", height: "21px!important" }}
                        />
                      </div>
                    ) : (
                      <Button
                        onClick={() => handleApproveAddress(address.id, 1)}
                        className="iyzi-button-blue"
                      >
                        Onayla
                      </Button>
                    ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div
        style={{
          boxShadow: "var(--shadow)",
          padding: "10px",
          borderRadius: "var(--wrapperBorderRadius)",
          display: "flex",
          flexDirection: "column",
          gap: "var(--gap)",
        }}
      >
        <h6 style={{ color: "var(--priceText)", fontSize: "16px" }}>Toplama Adresi</h6>
        {bookingAddresses?.length === 0 && "Kayıtlı toplama adresi bulunmamaktadır."}
        {bookingAddresses?.length !== 0 && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              gap: "var(--gap2x)",
            }}
          >
            {bookingAddresses?.map((address, key) => (
              <div
                style={{
                  color: "var(--priceText)",
                  fontSize: "13px",
                  padding: "10px",
                  borderRadius: "var(--wrapperBorderRadius)",
                  border:
                    Number(address.is_default) === 1 && Number(address.is_active) === 1
                      ? "2px solid var(--info)"
                      : "1px solid var(--inputBorderColor)",
                }}
                key={key}
              >
                <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                  <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                    Başlık:{" "}
                  </span>
                  {address?.address_description ? address?.address_description : "---"}
                </div>
                <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                  <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                    Kontak:{" "}
                  </span>
                  {address?.contact_name ? address?.contact_name : "---"}
                </div>
                <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                  <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                    Email:{" "}
                  </span>
                  {address?.email ? address?.email : "---"}
                </div>
                <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                  <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                    Telefon:{" "}
                  </span>
                  {address?.phone ? address?.phone : "---"}
                </div>
                <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                  <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                    Adres Satırı:{" "}
                  </span>
                  {address?.address1 && address?.address1} {address?.address2 && address?.address2}{" "}
                  {address?.address3 && address?.address3} {address?.state && `${address?.state}`}
                </div>
                <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                  <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                    Adres Bölgesi:{" "}
                  </span>
                  {address?.town && `, ${address?.town}`}
                  {address?.city && `, ${address?.city}`}
                  {address?.country && `, ${address?.country}`}
                  {address?.postal_code && `, ${address?.postal_code}`}
                  {address?.state_code && `, ${address?.state_code}`}
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                  {Number(address.is_default) === 0 &&
                    Number(address.is_active) === 0 &&
                    (bookingLoading ? (
                      <div
                        style={{
                          width: "64px",
                          height: "36px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress
                          sx={{ width: "21px!important", height: "21px!important" }}
                        />
                      </div>
                    ) : (
                      <Button
                        onClick={() => handleApproveAddress(address.id, 2)}
                        className="iyzi-button-blue"
                      >
                        Onayla
                      </Button>
                    ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div
        style={{
          boxShadow: "var(--shadow)",
          padding: "10px",
          borderRadius: "var(--wrapperBorderRadius)",
          display: "flex",
          flexDirection: "column",
          gap: "var(--gap)",
        }}
      >
        <h6 style={{ color: "var(--priceText)", fontSize: "16px" }}>Gönderici Adresi</h6>
        {senderAddresses?.length === 0 && "Kayıtlı gönderici adresi bulunmamaktadır."}
        {senderAddresses?.length !== 0 &&
          (senderLoading ? (
            <div
              style={{
                width: "100%",
                minHeight: "120px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
            </div>
          ) : (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                gap: "var(--gap2x)",
              }}
            >
              {senderAddresses?.map((address, key) => (
                <div
                  style={{
                    color: "var(--priceText)",
                    fontSize: "13px",
                    padding: "10px",
                    borderRadius: "var(--wrapperBorderRadius)",
                    border: "1px solid var(--inputBorderColor)",
                  }}
                  key={key}
                >
                  <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                    <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                      Başlık:{" "}
                    </span>
                    {address?.address_description ? address?.address_description : "---"}
                  </div>
                  <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                    <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                      Kontak:{" "}
                    </span>
                    {address?.contact_name ? address?.contact_name : "---"}
                  </div>
                  <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                    <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                      Email:{" "}
                    </span>
                    {address?.email ? address?.email : "---"}
                  </div>
                  <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                    <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                      Telefon:{" "}
                    </span>
                    {address?.phone ? address?.phone : "---"}
                  </div>
                  <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                    <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                      Adres Satırı:{" "}
                    </span>
                    {address?.address1 && address?.address1}{" "}
                    {address?.address2 && address?.address2}{" "}
                    {address?.address3 && address?.address3} {address?.state && `${address?.state}`}
                  </div>
                  <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                    <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                      Adres Bölgesi:{" "}
                    </span>
                    {address?.town && `, ${address?.town}`}
                    {address?.city && `, ${address?.city}`}
                    {address?.country && `, ${address?.country}`}
                    {address?.postal_code && `, ${address?.postal_code}`}
                    {address?.state_code && `, ${address?.state_code}`}
                  </div>
                </div>
              ))}
            </div>
          ))}
        <Pagination
          showFirstButton
          showLastButton
          size="small"
          count={senderTotalPages}
          page={senderCurrentPage}
          onChange={handleSenderPageChange}
        />
      </div>
      <div
        style={{
          boxShadow: "var(--shadow)",
          padding: "10px",
          borderRadius: "var(--wrapperBorderRadius)",
          display: "flex",
          flexDirection: "column",
          gap: "var(--gap)",
        }}
      >
        <h6 style={{ color: "var(--priceText)", fontSize: "16px" }}>Alıcı Adresi</h6>
        {receiverAddresses?.length === 0 && "Kayıtlı alıcı adresi bulunmamaktadır."}
        {receiverAddresses?.length !== 0 &&
          (receiverLoading ? (
            <div
              style={{
                width: "100%",
                minHeight: "120px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
            </div>
          ) : (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                gap: "var(--gap2x)",
              }}
            >
              {receiverAddresses?.map((address, key) => (
                <div
                  style={{
                    color: "var(--priceText)",
                    fontSize: "13px",
                    padding: "10px",
                    borderRadius: "var(--wrapperBorderRadius)",
                    border: "1px solid var(--inputBorderColor)",
                  }}
                  key={key}
                >
                  <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                    <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                      Başlık:{" "}
                    </span>
                    {address?.address_description ? address?.address_description : "---"}
                  </div>
                  <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                    <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                      Kontak:{" "}
                    </span>
                    {address?.contact_name ? address?.contact_name : "---"}
                  </div>
                  <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                    <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                      Email:{" "}
                    </span>
                    {address?.email ? address?.email : "---"}
                  </div>
                  <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                    <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                      Telefon:{" "}
                    </span>
                    {address?.phone ? address?.phone : "---"}
                  </div>
                  <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                    <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                      Adres Satırı:{" "}
                    </span>
                    {address?.address1 && address?.address1}{" "}
                    {address?.address2 && address?.address2}{" "}
                    {address?.address3 && address?.address3} {address?.state && `${address?.state}`}
                  </div>
                  <div style={{ fontSize: "inherit", fontWeight: "inherit" }}>
                    <span style={{ color: "var(--info)", fontWeight: "600", fontSize: "inherit" }}>
                      Adres Bölgesi:{" "}
                    </span>
                    {address?.town && `, ${address?.town}`}
                    {address?.city && `, ${address?.city}`}
                    {address?.country && `, ${address?.country}`}
                    {address?.postal_code && `, ${address?.postal_code}`}
                    {address?.state_code && `, ${address?.state_code}`}
                  </div>
                </div>
              ))}
            </div>
          ))}
        <Pagination
          showFirstButton
          showLastButton
          size="small"
          count={receiverTotalPages}
          page={receiverCurrentPage}
          onChange={handleReceiverPageChange}
        />
      </div>
    </div>
  );
};

export default MemberDocumentEditAddresses;
