import { TableCell, TableHead, TableRow } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  financeBakiyeIadeCurrentPageState,
  financeBakiyeIadeDataState,
  financeBakiyeIadeTotalPageState,
} from "../../../../atoms/finance";
import { useCallback, useState } from "react";
import axios from "../../../../api/axios";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Errors from "../../../../utils/Errors";

const FinanceBakiyeIadeTableHead = () => {
  const [, setFinanceBakiyeIadeData] = useRecoilState(financeBakiyeIadeDataState);
  const [, setFinanceBakiyeIadeTotalPage] = useRecoilState(financeBakiyeIadeTotalPageState);
  const [financeBakiyeIadeCurrentPage, setFinanceBakiyeIadeCurrentPage] = useRecoilState(
    financeBakiyeIadeCurrentPageState
  );

  const [activeSort, setActiveSort] = useState([
    { column: "member_id", direction: null },
    { column: "created_at", direction: null },
    { column: "status", direction: null },
  ]);

  const handlePageChange = useCallback(
    async (orderBy) => {
      const updatedSort = activeSort.map((sort) => {
        if (sort.column === orderBy) {
          return {
            ...sort,
            direction: sort.direction === "ASC" ? "DESC" : "ASC",
          };
        }
        return {
          ...sort,
          direction: null, // Diğer sütunların yönünü varsayılan duruma sıfırlayın
        };
      });

      setActiveSort(updatedSort);

      const currentDirection = updatedSort.find((sort) => sort.column === orderBy).direction;

      await axios
        .get(`refund`, {
          params: {
            page: financeBakiyeIadeCurrentPage,
            limit: 500,
            order_by: orderBy,
            order_direction: currentDirection,
          },
        })
        .then((resp) => {
          setFinanceBakiyeIadeData(resp.data.data);
          setFinanceBakiyeIadeCurrentPage(resp.data.currentPage);
          setFinanceBakiyeIadeTotalPage(resp.data.totalPages);
        })
        .catch((error) => Errors(error));
    },
    [
      setFinanceBakiyeIadeData,
      setFinanceBakiyeIadeCurrentPage,
      setFinanceBakiyeIadeTotalPage,
      financeBakiyeIadeCurrentPage,
      activeSort,
    ]
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{ fontWeight: 700, cursor: "pointer" }}
          onClick={() => handlePageChange("member_id")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Müşteri ID{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "member_id")?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Müşteri Adı</TableCell>
        <TableCell
          sx={{ fontWeight: 700, cursor: "pointer" }}
          onClick={() => handlePageChange("created_at")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Talep Tarihi{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "created_at")?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          sx={{ fontWeight: 700, cursor: "pointer" }}
          onClick={() => handlePageChange("invoice_amount")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Tutar{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "invoice_amount")?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell sx={{ fontWeight: 700 }}>İade Edilebilir Tutar</TableCell>

        <TableCell sx={{ fontWeight: 700 }}>İade Açıklaması</TableCell>

        <TableCell
          sx={{ fontWeight: 700, cursor: "pointer" }}
          onClick={() => handlePageChange("status")}
          align="center"
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Durum{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "status")?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default FinanceBakiyeIadeTableHead;
