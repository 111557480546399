import {
  Box,
  Button,
  createTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  ThemeProvider,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  CircularProgress
} from "@mui/material";
import { useCallback, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import "../../../style/courierRegistration/CourierAbortAdmin.css";
import {
  courierAbortAdminOpenState,
  courierAbortAdminIDState,
} from "../../../atoms/courierRegistration";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          width: "100%",
          padding: "20px",
          outline: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const CourierAbortAdmin = () => {
  const [courierAbortAdminOpen, setCourierAbortAdminOpen] = useRecoilState(
    courierAbortAdminOpenState
  );

  const [courierAbortAdminID] = useRecoilState(courierAbortAdminIDState);

  const [reason, setReason] = useState("");

  const [abortDialog, setAbortDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseCourierAbort = useCallback(() => {
    setCourierAbortAdminOpen(false);
  }, [setCourierAbortAdminOpen]);

  const handleSendAbort = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);

      axios
        .post(`booking/update/${courierAbortAdminID}`, {
          status: 5,
          cancel_reason: reason,
        })
        .then(() => {
          toast.success("Kayıt İptal Edildi");
          window.location.reload();
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    },
    [reason, courierAbortAdminID]
  );

  const handleOpenCourierAbortDialog = () => {
    setAbortDialog(true);
  };

  const handleCloseCourierAbortDialog = () => {
    setAbortDialog(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal open={courierAbortAdminOpen} onClose={handleCloseCourierAbort}>
        <form onSubmit={handleSendAbort} className="courier-abort-admin">
          <h6>Kurye Kayıt İptal Nedeni</h6>
          <FormControl required fullWidth>
            <InputLabel id="select-abort">İptal Nedeni</InputLabel>
            <Select
              labelId="select-abort"
              id="select-abort"
              value={reason}
              label="İptal Nedeni"
              onChange={(e) => setReason(e.target.value)}
            >
              <MenuItem disabled selected>
                Seçiniz
              </MenuItem>
              <MenuItem value={1}>Adreste Yok</MenuItem>
              <MenuItem value={2}>Yanlış Adres</MenuItem>
              <MenuItem value={3}>Gönderi Hazır Değil</MenuItem>
              <MenuItem value={4}>Gönderi Taşımaya Uygun Değil</MenuItem>
            </Select>
          </FormControl>
          <Box className="courier-abort-admin-buttons">
            <Button
              type="button"
              onClick={handleCloseCourierAbort}
              className="abort"
            >
              Vazgeç
            </Button>
            <Button
              type="button"
              onClick={handleOpenCourierAbortDialog}
              className="send"
            >
              Kaydı İptal Et
            </Button>
          </Box>
        </form>
      </Modal>
      <Dialog open={abortDialog} onClose={handleCloseCourierAbortDialog}>
        <DialogTitle id="alert-dialog-title">Kurye Kaydın İptal</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Kurye kaydını iptal etmek istiyor musunuz?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCourierAbortDialog}>Vazgeç</Button>
          {loading ? (
            <Box
              sx={{
                width: "72px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress sx={{ width: "24px !important", height: "24px !important" }} />
            </Box>
          ) : (
            <Button onClick={handleSendAbort}>Onayla</Button>
          )}
       
          
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default CourierAbortAdmin;
