import { useRecoilValue } from "recoil";
import { financeIbanDataState } from "../../../../atoms/finance";
import { Table, TableBody, TableContainer } from "@mui/material";
import FinanceIbanTableBody from "./FinanceIbanTableBody";
import FinanceIbanTableHead from "./FinanceIbanTableHead";

const FinanceIbanTable = () => {
  const financeIbanData = useRecoilValue(financeIbanDataState);

  return (
    <TableContainer
      sx={{
        backgroundColor: "var(--lighterBg)",
        boxShadow: "var(--shadow)",
        borderRadius: "var(--wrapperBorderRadius)",
      }}
    >
      <Table>
        <FinanceIbanTableHead />
        <TableBody>
          {financeIbanData?.map((item, key) => (
            <FinanceIbanTableBody key={key} item={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FinanceIbanTable;
