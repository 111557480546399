import {
  Box,
  Button,
  CircularProgress,
  createTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { useRecoilState } from "recoil";
import {
  proformaUpdateOpenState,
  selectedShipmentForMyShipmentsState,
} from "../../../../atoms/myShipments";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useCallback, useEffect, useState } from "react";
import axios from "../../../../api/axios";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import countries from "../../../../json/countries.json";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../../../style/myShipments/ProformaUpdate.css";
import Currency from "../../../../utils/CurrencySymbol";
import GetSum from "../../../../utils/GetSum";
import { toast } from "react-toastify";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Errors from "../../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px",
          overflow: "auto",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const MyShipmentProformaUpdate = () => {
  const [proformaUpdateOpen, setProformaUpdateOpen] = useRecoilState(
    proformaUpdateOpenState
  );

  const [selectedShipmentForMyShipments, setSelectedShipmentForMyShipments] =
    useRecoilState(selectedShipmentForMyShipmentsState);

  console.log(selectedShipmentForMyShipments);

  const [proformaCurrency, setProformaCurrency] = useState("");
  const [proformaIoss, setProformaIoss] = useState(selectedShipmentForMyShipments.ioss);
  const [updatedProformaDialog, setUpdatedProformaDialog] = useState(false);
  const [commoduties, setCommoduties] = useState([
    {
      id: selectedShipmentForMyShipments.id,
      dev_id: 0,
      title: "",
      sku: "",
      hs_code: "",
      country: "",
      qty: "",
      price: "",
      currency: proformaCurrency,
      total_price: "",
      type: "CREATE",
    },
  ]);
  console.log(commoduties[0].type);
  const [userCommoduites, setUserCommoduites] = useState([]);
  const [sumPiece, setSumPiece] = useState("");
  const [sumPrice, setSumPrice] = useState("");
  const [registerLoading, setRegisterLoading] = useState(false);

  const handleAddInput = useCallback(() => {
    if (
      commoduties[commoduties.length - 1].title === "" ||
      commoduties[commoduties.length - 1].sku === "" ||
      commoduties[commoduties.length - 1].hs_code === "" ||
      commoduties[commoduties.length - 1].country === "" ||
      commoduties[commoduties.length - 1].price === "" ||
      commoduties[commoduties.length - 1].qty === ""
    ) {
      return false;
    } else {
      setCommoduties([
        ...commoduties,
        {
          id: 0,
          dev_id: commoduties.length,
          title: "",
          sku: "",
          hs_code: "",
          country: "",
          qty: "",
          price: "",
          currency: proformaCurrency,
          total_price: "",
          type: "CREATE",
        },
      ]);
    }
  }, [commoduties, setCommoduties, proformaCurrency]);

  const isCreatedAlready =
    selectedShipmentForMyShipments?.get_parcel_commoduties?.map(
      (item) => item.status === "2"
    );
  const originalCommoduties =
    selectedShipmentForMyShipments?.get_parcel_commoduties?.filter(
      (e) => e.status === "2"
    );

  const handleInputChange = useCallback(
    (e, i) => {
      const values = [...commoduties];
      if (e.target.name === "qty" && e.target.name === "price") {
        values[i][e.target.name] = Number(e.target.value);
      } else if (e.target.name === "country") {
        values[i][e.target.name] = e.target.value.slice(-2);
      } else {
        values[i][e.target.name] = e.target.value;
      }

      values[i].total_price = values[i].qty * values[i].price;

      setSumPiece(values.map((value) => Number(value.qty)).reduce(GetSum));
      setSumPrice(
        values.map((value) => Number(value.total_price)).reduce(GetSum)
      );

      if (isCreatedAlready && originalCommoduties?.length === values?.length) {
        // Orijinal ve güncel değerleri karşılaştır
        if (
          Object.keys(values[i]).some(
            (key) =>
              key !== "type" && values[i][key] !== originalCommoduties[i][key]
          )
        ) {
          values[i].type = "UPDATE";
        } else {
          values[i].type = "";
        }
      }

      setCommoduties(values);
    },
    [commoduties, setCommoduties, isCreatedAlready, originalCommoduties]
  );

  const handleIossChange = (e) => {
    setProformaIoss(e.target.value)
  }


  const handleIossUpdateDialogOpen = (e) => {
    setUpdatedProformaDialog(true);
  }

  const handleIossUpdateDialogClose = (e) => {
    setUpdatedProformaDialog(false);
  }


  const handleUpdateIoss = (e) => {
    axios
      .post(`parcel/operation/update-ioss-parcel/${selectedShipmentForMyShipments.id}`, {
        parcel_id: selectedShipmentForMyShipments.id,
        ioss: proformaIoss,
      })
      .then((response) => {
        toast.success("Başarılı");
        setProformaIoss(response.data.ioss);
        handleIossUpdateDialogClose();
      })
      .catch((error) => Errors(error))
      .finally(() => handleIossUpdateDialogClose);
  }

  console.log(proformaIoss)

  const handleDeleteCommoduties = useCallback(
    (i) => {
      const values = [...commoduties];

      if (values[i].type === "DELETE") {
        values[i].type = "UPDATE";
        setCommoduties(values);
      } else if (values[i].type === "UPDATE") {
        values[i].type = "DELETE";
        setCommoduties(values);
      } else {
        setCommoduties(commoduties?.filter((com) => com.dev_id !== i));
      }
    },
    [commoduties]
  );

  const createData = (
    userStatus,
    descriptionTitle,
    descriptionCountryCode,
    descriptionCountryHsCode,
    piece,
    pieceGrossType,
    pricePerPiece,
    totalPrice
  ) => {
    return {
      userStatus,
      descriptionTitle,
      descriptionCountryCode,
      descriptionCountryHsCode,
      piece,
      pieceGrossType,
      pricePerPiece,
      totalPrice,
    };
  };

  const handleProformaUpdateSubmit = () => {
    setRegisterLoading(true);
    axios
      .post(`parcel/update-proforma/${selectedShipmentForMyShipments.id}`, {
        commoduties,
      })
      .then(() => {
        toast.success("Başarılı");
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      })
      .catch((error) => Errors(error))
      .finally(() => setRegisterLoading(false));
  };

  const handleProformaUpdateClose = () => {
    setUserCommoduites([]);
    setProformaCurrency("");
    setSumPiece("");
    setSumPrice("");
    setProformaIoss("");
    setSelectedShipmentForMyShipments([]);
    setCommoduties([
      {
        id: 0,
        dev_id: 0,
        title: "",
        sku: "",
        hs_code: "",
        country: "",
        qty: "",
        price: "",
        currency: "",
        total_price: "",
        type: "",
      },
    ]);
    setProformaUpdateOpen(false);
  };

  const handleClearProforma = () => {
    setSumPiece("");
    setSumPrice("");
    setCommoduties([
      {
        id: 0,
        dev_id: 0,
        title: "",
        sku: "",
        hs_code: "",
        country: "",
        qty: "",
        price: "",
        currency: "",
        total_price: "",
        type: "",
      },
    ]);
  };

  const handleCloneProforma = () => {
    if (isCreatedAlready) {
      const filteredProforma =
        selectedShipmentForMyShipments?.get_parcel_commoduties?.map((com) =>
          com.status === "2"
            ? {
              id: com.id,
              dev_id: 0,
              title: com.title,
              sku: com.sku,
              hs_code: com.hs_code,
              country: com.country,
              qty: com.qty,
              price: com.price,
              currency: com.currency,
              total_price: com.total_price,
              type: "DELETE",
            }
            : {
              id: com.id,
              dev_id: 0,
              title: com.title,
              sku: com.sku,
              hs_code: com.hs_code,
              country: com.country,
              qty: com.qty,
              price: com.price,
              currency: com.currency,
              total_price: com.total_price,
              type: "CREATE",
            }
        );

      setCommoduties(filteredProforma);
    } else {
      const filteredProforma =
        selectedShipmentForMyShipments?.get_parcel_commoduties
          .filter((com) => com.status === "1")
          .map((com) => ({
            id: com.id,
            dev_id: 0,
            title: com.title,
            sku: com.sku,
            hs_code: com.hs_code,
            country: com.country,
            qty: com.qty,
            price: com.price,
            currency: com.currency,
            total_price: com.total_price,
            type: "CREATE",
          }));

      setCommoduties(filteredProforma);
    }

    setSumPiece(
      selectedShipmentForMyShipments?.get_parcel_commoduties
        .filter((com) => com.status === "1")
        .map((value) => Number(value.qty))
        .reduce(GetSum)
    );

    setSumPrice(
      selectedShipmentForMyShipments?.get_parcel_commoduties
        .filter((com) => com.status === "1")
        .map((value) => Number(value.total_price))
        .reduce(GetSum)
    );
  };

  const getAdminCommoduties = useCallback(() => {
    if (
      selectedShipmentForMyShipments?.get_parcel_commoduties?.filter(
        (com) => com.status === "2"
      ).length > 0
    ) {
      const filteredProforma =
        selectedShipmentForMyShipments?.get_parcel_commoduties
          .filter((com) => com.status === "2")
          .map((com) => ({
            id: com.id,
            dev_id: 0,
            title: com.title,
            sku: com.sku,
            hs_code: com.hs_code,
            country: com.country,
            qty: com.qty,
            price: com.price,
            currency: com.currency,
            total_price: com.total_price,
            type: "CREATED",
          }));
      setSumPiece(
        selectedShipmentForMyShipments?.get_parcel_commoduties
          .filter((com) => com.status === "2")
          .map((value) => Number(value.qty))
          .reduce(GetSum)
      );
      setSumPrice(
        selectedShipmentForMyShipments?.get_parcel_commoduties
          .filter((com) => com.status === "2")
          .map((value) => Number(value.total_price))
          .reduce(GetSum)
      );
      setCommoduties(filteredProforma);
    }
  }, [selectedShipmentForMyShipments?.get_parcel_commoduties]);

  useEffect(() => {
    if (selectedShipmentForMyShipments.length !== 0) {
      setUserCommoduites(
        selectedShipmentForMyShipments?.get_parcel_commoduties.map((com) =>
          createData(
            `${com.status}`,
            `${com.title}`,
            `Menşei: ${com.country}`,
            `Menşei: ${com.hs_code}`,
            `${com.qty} Adet`,
            `KG`,
            `${com.price} ${Currency(com.currency)}`,
            `${com.total_price} ${Currency(com.currency)}`
          )
        )
      );
      setProformaCurrency(selectedShipmentForMyShipments.currency);
    }
  }, [selectedShipmentForMyShipments, proformaUpdateOpen]);

  useEffect(() => {
    if (proformaCurrency !== "") {
      commoduties.map((com) => (com.currency = proformaCurrency));
    }
  }, [proformaCurrency, commoduties]);

  useEffect(() => {
    setCommoduties([
      {
        ...commoduties[0],
        type: "CREATE",
      },
    ]);
  }, [proformaUpdateOpen]);

  useEffect(() => {
    getAdminCommoduties();
  }, [getAdminCommoduties]);

  useEffect(() => {
    setProformaIoss(selectedShipmentForMyShipments.ioss)
  }, [selectedShipmentForMyShipments]);

  return (
    <ThemeProvider theme={theme}>
      <Modal open={proformaUpdateOpen} onClose={handleProformaUpdateClose}>
        <Box
          sx={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            padding: "20px",
            outline: 0,
            display: "grid",
            gap: "var(--gap)",
            width: "100%",
            maxWidth: "1100px",
            margin: "auto",
            position: "relative",
          }}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              color: "var(--priceText)",
              cursor: "pointer",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleProformaUpdateClose}
          />
          <Box
            sx={{
              display: "grid",
              gap: "var(--gap3x)",
              color: "var(--priceText)",
            }}
          >
            <h6>Proforma Güncelle</h6>
            <Box
              sx={{ display: "flex", gap: "var(--gap2x)", flexWrap: "wrap" }}
            >
              <div>
                <h6>Admin Proforma Bilgileri</h6>
                <div>Proforma Fatura Detayları</div>
              </div>
              <FormControl sx={{ minWidth: "120px" }} size="small">
                <InputLabel
                  sx={{ fontSize: "14px" }}
                  id="currency-select-label"
                >
                  Döviz Cinsi
                </InputLabel>
                <Select
                  labelId="currency-select-label"
                  id="currency-select"
                  label="Döviz Cinsi"
                  value={proformaCurrency}
                  onChange={(e) => setProformaCurrency(e.target.value)}
                  sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
                >
                  <MenuItem value="">
                    <em>Temizle</em>
                  </MenuItem>
                  <MenuItem value={"EUR"}>€ (Euro)</MenuItem>
                  <MenuItem value={"USD"}>$ (Amerikan Doları)</MenuItem>
                  <MenuItem value={"GBP"}>£ (İngiltere Sterlini)</MenuItem>
                </Select>
              </FormControl>
              <Button
                onClick={handleCloneProforma}
                sx={{
                  textTransform: "none",
                  backgroundColor: "var(--info)",
                  color: "var(--lighterBg)",
                  transition: "all 150ms linear",
                  ":hover": {
                    backgroundColor: "var(--blueBrand)",
                  },
                }}
              >
                <LibraryAddOutlinedIcon sx={{ marginRight: "5px" }} />
                Müşteri Proforma Klonla
              </Button>
              <Box
                sx={{ display: "flex", gap: "var(--gap2x)", flexWrap: "wrap" }}
              >
                <TextField
                  required
                  value={proformaIoss}
                  onChange={(e) => handleIossChange(e)}
                  name="title"
                  size="small"
                  label="IOSS / VAT Numarası"
                />
                <Button
                  onClick={handleIossUpdateDialogOpen}
                  sx={{
                    textTransform: "none",
                    backgroundColor: "var(--info)",
                    color: "var(--lighterBg)",
                    transition: "all 150ms linear",
                    ":hover": {
                      backgroundColor: "var(--blueBrand)",
                    },
                  }}
                >
                  {selectedShipmentForMyShipments.ioss > 0 ? "IOSS Güncelle" : "IOSS Ekle"}
                </Button>
              </Box>
            </Box>

            <Dialog open={updatedProformaDialog} onClose={handleIossUpdateDialogClose}>
              <DialogTitle sx={{ color: "red" }}>{"Uyarı"}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <strong>
                    GÖNDERİYE AİT TAKİP NUMARASI ALINMIŞTIR DEVAM EDERSENİZ TAKİP NUMARSI SİLİNECEKTİR VE TEKRAR TAKİP NUMARASI ALMANIZ GEREKMEKTEDİR.
                  </strong>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleUpdateIoss} color="primary">
                  Okudum,Anladım.
                </Button>
                <Button onClick={handleIossUpdateDialogClose} color="primary">
                  Vazgeç
                </Button>
              </DialogActions>
            </Dialog>

            <Box className="proforma-details">
              <div className="details-columns">
                {commoduties.map((com, key) => (
                  <div key={key}>
                    <div className="details-column">
                      <div
                        className="column"
                        style={{
                          backgroundColor:
                            com?.type === "DELETE"
                              ? "var(--pastelRed"
                              : "inherit",
                        }}
                      >
                        <TextField
                          required
                          value={com.title}
                          onChange={(e) => handleInputChange(e, key)}
                          name="title"
                          size="small"
                          label="Ürün Açıklaması"
                          inputProps={{ pattern: "[a-z]" }}
                        />
                        {/* <Autocomplete
                          id={`country-typing-select-${com.dev_id}`}
                          fullWidth
                          size="small"
                          sx={{ maxWidth: 380 }}
                          options={countries}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              {option.label}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Menşei"
                              required
                              name="country"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        /> */}
                        <FormControl sx={{ minWidth: "120px" }} size="small">
                          <InputLabel
                            sx={{ fontSize: "14px" }}
                            id="country-select-label"
                          >
                            Menşei
                          </InputLabel>
                          <Select
                            labelId="country-select-label"
                            id="country-select"
                            label="Menşei"
                            name="country"
                            value={com.country}
                            onChange={(e) => handleInputChange(e, key)}
                            sx={{
                              fontSize: "14px",
                              display: "flex",
                              minHeight: "40px",
                            }}
                          >
                            <MenuItem value="">
                              <em>Temizle</em>
                            </MenuItem>
                            {countries.map((country, key) => (
                              <MenuItem key={key} value={country.code}>
                                <img
                                  loading="lazy"
                                  style={{ marginRight: "5px", width: "20px" }}
                                  src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                                  alt={`${country.label} Milli Bayrağı Görseli`}
                                />
                                {country.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField
                          required
                          value={com.hs_code}
                          onChange={(e) => handleInputChange(e, key)}
                          name="hs_code"
                          size="small"
                          type={"number"}
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                            {
                              display: "none",
                            },
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                          }}
                          label="HS Kodu"
                          InputProps={{ inputProps: { min: 0 } }}
                        />
                        <TextField
                          required
                          value={com.sku}
                          onChange={(e) => handleInputChange(e, key)}
                          name="sku"
                          size="small"
                          type={"number"}
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                            {
                              display: "none",
                            },
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                          }}
                          label="SKU"
                          InputProps={{ inputProps: { min: 0 } }}
                        />
                        <TextField
                          value={Number(com.qty)}
                          onChange={(e) => handleInputChange(e, key)}
                          name="qty"
                          required
                          type={"number"}
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                            {
                              display: "none",
                            },
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                          }}
                          size="small"
                          label="Adet"
                          InputProps={{ inputProps: { min: 0 } }}
                        />
                        <TextField
                          value={Number(com.price)}
                          onChange={(e) => handleInputChange(e, key)}
                          name="price"
                          required
                          type={"number"}
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                            {
                              display: "none",
                            },
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                          }}
                          size="small"
                          label="Birim Fiyatı"
                          InputProps={{ inputProps: { min: 0 } }}
                        />
                        <TextField
                          value={Number(com.total_price).toFixed(2)}
                          disabled
                          name="total_price"
                          required
                          size="small"
                          label="Toplam"
                        />
                      </div>
                      <DeleteIcon
                        className="display"
                        onClick={() => handleDeleteCommoduties(key)}
                      />
                    </div>
                  </div>
                ))}
                <div
                  style={{
                    marginTop: "var(--gap2x)",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-end",
                    gap: "var(--gap)",
                  }}
                >
                  {/* <TextField
                    sx={{ maxWidth: "143px" }}
                    size="small"
                    label="Toplam Adet"
                    name="total_sum_piece"
                    value={sumPiece}
                    disabled
                    multiline
                  /> */}
                  <div
                    style={{
                      border: "1px solid var(--priceText)",
                      padding: "10px",
                      borderRadius: "10px",
                      paddingRight: "30px",
                    }}
                  >
                    Toplam Adet:
                    <span style={{ paddingLeft: "20px" }}>{sumPiece}</span>
                  </div>
                  {/* <TextField
                    sx={{ maxWidth: "143px" }}
                    size="small"
                    label="Topam Fiyat"
                    name="total_sum_price"
                    value={sumPrice}
                    disabled
                    multiline
                  /> */}
                  <div
                    style={{
                      border: "1px solid var(--priceText)",
                      padding: "10px",
                      borderRadius: "10px",
                      paddingRight: "30px",
                    }}
                  >
                    Toplam Fiyat:
                    <span style={{ paddingLeft: "10px" }}>
                      {Number(sumPrice).toFixed(2)}{" "}
                      {sumPrice === "" || sumPrice === 0 || sumPrice === "0"
                        ? ""
                        : Currency(proformaCurrency)}
                    </span>
                  </div>
                </div>
              </div>
              <AddCircleIcon
                onClick={handleAddInput}
                className="proforma-add"
              />
              {registerLoading ? (
                <CircularProgress
                  sx={{ width: "fit-content", marginLeft: "auto" }}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexWrap: "wrap",
                    width: "100%",
                    gap: "var(--gap)",
                  }}
                >
                  <Button
                    onClick={handleClearProforma}
                    sx={{
                      textTransform: "none",
                      backgroundColor: "var(--info)",
                      color: "var(--lighterBg)",
                      transition: "all 150ms linear",
                      ":hover": {
                        backgroundColor: "var(--blueBrand)",
                      },
                    }}
                  >
                    <HighlightOffIcon
                      sx={{ marginRight: "5px", color: "var(--notRed)" }}
                    />
                    Temizle
                  </Button>
                  <Button
                    onClick={handleProformaUpdateSubmit}
                    sx={{
                      textTransform: "none",
                      backgroundColor: "var(--info)",
                      color: "var(--lighterBg)",
                      transition: "all 150ms linear",
                      ":hover": {
                        backgroundColor: "var(--blueBrand)",
                      },
                    }}
                  >
                    <CheckCircleOutlineIcon
                      sx={{ marginRight: "5px", color: "var(--shipmentGreen)" }}
                    />
                    Kaydet
                  </Button>
                </Box>
              )}
            </Box>
            <h6>Müşteri Proforma Bilgileri</h6>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 700 }}>Açıklama</TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>Adet (KG)</TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>Birim Fiyatı</TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>Toplam Fiyat</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userCommoduites
                    .filter((userCom) => userCom.userStatus === "1")
                    .map((userCom, key) => (
                      <TableRow key={key}>
                        <TableCell
                          sx={{ fontSize: "13px", color: "var(--priceText)" }}
                        >
                          {userCom.descriptionTitle}
                          <br />
                          {userCom.descriptionCountryCode}
                          <br />
                          {userCom.descriptionCountryHsCode}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "grid",
                              justifyContent: "flex-start",
                              fontSize: "13px",
                              color: "var(--priceText)",
                            }}
                          >
                            {userCom.piece}
                            <br />
                            <span
                              style={{
                                display: "flex",
                                justifySelf: "flex-end",
                                fontSize: "11px",
                                color: "var(--priceText)",
                              }}
                            >
                              {userCom.pieceGrossType}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "13px", color: "var(--priceText)" }}
                        >
                          {userCom.pricePerPiece}
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "13px", color: "var(--priceText)" }}
                        >
                          {userCom.totalPrice}
                        </TableCell>
                      </TableRow>
                    ))}
                  {userCommoduites.length !== 0 && (
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: 700, fontSize: "12px" }}
                        align="right"
                      >
                        GENEL TOPLAM
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "13px",
                          color: "var(--error)",
                          fontWeight: 700,
                        }}
                      >
                        {selectedShipmentForMyShipments?.get_parcel_commoduties
                          ?.filter((com) => com.status === "1")
                          .map((com) => Number(com.qty))
                          .reduce(GetSum, 0)}{" "}
                        ADET
                      </TableCell>
                      <TableCell sx={{ fontSize: "13px", fontWeight: 700 }}>
                        {selectedShipmentForMyShipments?.get_parcel_commoduties
                          ?.filter((com) => com.status === "1")
                          .map((com) => Number(com.price))
                          .reduce(GetSum, 0)}{" "}
                        {Currency(selectedShipmentForMyShipments.currency)}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default MyShipmentProformaUpdate;
