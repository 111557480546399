import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import cg from "../../../../json/memberCustomerGroups.json";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import { FormatReturnFieldWithCustomDecimals } from "../../../../utils/FormatFieldReturn";
import axios from "../../../../api/axios";
import Errors from "../../../../utils/Errors";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import Currency from "../../../../utils/CurrencySymbol";
import CountriesTurkishSorted from "../../../../utils/CountriesTurkishSorted";
import AbbreviateName from "../../../../utils/AbbreviateName";

const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "7px !important",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "20px !important",
        },
      },
    },
  },
});

const NewMemberOptions = ({ setCurrency, setData, setCurrentPage, setTotalPages }) => {
  const [loading, setLoading] = useState(true);
  const [memberLoading, setMemberLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [betweenDates, setBetweenDates] = useState(false);
  const [reportEra, setReportEra] = useState("");
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [invoiceCurrency, setInvoiceCurrency] = useState("TRY");
  const [memberList, setMemberList] = useState([]);
  const [members, setMembers] = useState([]);
  const [membership, setMembership] = useState("");
  const [customerGroup, setCustomerGroup] = useState([]);
  const [profitType, setProfitType] = useState("");
  const [profitAmount, setProfitAmount] = useState("");
  const [shipmentType, setShipmentType] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [parcelStatus, setParcelStatus] = useState("");
  const [courierType, setCourierType] = useState("");

  const [firstDate, setFirstDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);

  const [senderCountries, setSenderCountries] = useState([]);
  const [invoiceAddressCountries, setInvoiceAddressCountries] = useState([]);

  const shipmentTypes = [
    { value: "X", label: "Express" },
    { value: "E", label: "Economy" },
    { value: "P", label: "Postal" },
  ];

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleChange = (e) => {
    const { value, name } = e.target;

    const setters = {
      between_dates: () => setBetweenDates(!betweenDates),
      report_era: () => setReportEra(value),
      invoice_status: () => setInvoiceStatus(value),
      invoice_currency: () => setInvoiceCurrency(value),
      membership: () => setMembership(value),
      pricing_type: () => setProfitType(value),
      pricing_amount: () => setProfitAmount(FormatReturnFieldWithCustomDecimals(value, 2)),
      parcel_status: () => setParcelStatus(value),
      courier_type: () => setCourierType(value),
    };

    if (setters[name]) {
      setters[name]();
    }
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    axios
      .get(`report/new-membership`, {
        params: {
          between_dates: betweenDates ? 1 : 0,
          ...(reportEra && { report_period: reportEra }),
          ...(betweenDates && { between_date_start: firstDate, between_date_end: lastDate }),
          ...(members.length !== 0 && { member_ids: members }),
          ...(shipmentType.length !== 0 && { shipment_type_ids: shipmentType }),
          ...(customerGroup.length !== 0 && { customer_group_ids: customerGroup }),
          ...(supplier.length !== 0 && { supplier_ids: supplier }),
          ...(invoiceCurrency !== "TRY" && { currency: invoiceCurrency }),
          ...(invoiceStatus?.toString()?.length !== 0 && { invoice_status: invoiceStatus }),
          ...(membership && { member_type: membership }),
          ...(senderCountries.length !== 0 && { sender_countries: senderCountries }),
          ...(invoiceAddressCountries.length !== 0 && {
            invoice_countries: invoiceAddressCountries,
          }),
          ...(profitType && { profit_type: profitType }),
          ...(profitType && { profit_amount: profitAmount }),
          ...(courierType && { courier_type: courierType }),
          ...(parcelStatus?.toString()?.length !== 0 && { parcel_status: parcelStatus }),
        },
      })
      .then((response) => {
        setData(response.data?.data);
        setCurrentPage(response.data?.currentPage);
        setTotalPages(response.data?.totalPages);
        
        invoiceCurrency === "USD" ? setCurrency(true) : setCurrency(false);
      })
      .catch((error) => Errors(error))
      .finally(() => setSearchLoading(false));
  };

  const getSupplierData = () => {
    axios
      .get(`supplier/list-simple`, { params: { unlimited: 1 } })
      .then((response) => setSupplierList(response.data.data))
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  const getMemberData = () => {
    axios
      .get(`member/list-simple`, { params: { unlimited: 1 } })
      .then((response) =>
        setMemberList(
          response.data.data.map((e) => ({
            value: e.id,
            label: e.name,
          }))
        )
      )
      .catch((error) => Errors(error))
      .finally(() => setMemberLoading(false));
  };

  useEffect(() => {
    getSupplierData();
    getMemberData();
  }, []);

  // TODO: Çıkış ülkesi ve faturalandırma ülkesi yapılacak.

  return (
    <ThemeProvider theme={theme}>
      <Card>
        <CardContent>
          <Button
            onClick={handleExpand}
            fullWidth
            className="iyzi-button-blue"
            sx={{ ":disabled": { opacity: 0.3 } }}
          >
            {expanded ? "Rapor Ayarlarını Kapat" : "Rapor Ayarlarını Aç"}
          </Button>
        </CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <h6
              style={{
                display: "flex",
                alignItems: "center",
                color: "var(--priceText)",
                marginBottom: "var(--gap2x)",
                gap: "5px",
              }}
            >
              <ViewInArIcon /> Rapor Ayarları
            </h6>
            <form
              onSubmit={handleSubmit}
              style={{ display: "flex", flexDirection: "column", gap: "var(--gap2x)" }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "var(--gap2x)" }}>
                <FormControlLabel
                  control={
                    <Checkbox checked={betweenDates} name="between_dates" onChange={handleChange} />
                  }
                  sx={{
                    boxShadow: "var(--shadow)",
                    paddingRight: "10px",
                    borderRadius: "var(--wrapperBorderRadius)",
                    margin: "0px",
                    ":hover": {
                      boxShadow: "var(--blueShadow)",
                      transition: "all 150ms linear",
                    },
                  }}
                  label="Tarih Aralığı"
                />
              </div>
              {betweenDates && (
                <div style={{ display: "flex", alignItems: "center", gap: "var(--gap2x)" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl size="small" component="fieldset" fullWidth>
                      <DatePicker
                        label="Başlangıç Tarihi"
                        value={firstDate}
                        onChange={(newValue) => {
                          if (!newValue || newValue.toString() === "Invalid Date") {
                            setFirstDate("");
                          } else {
                            setFirstDate(newValue.format("YYYY-MM-DD"));
                          }
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} required autoComplete="off" />
                        )}
                        mask="__-__-____"
                        inputFormat="DD-MM-YYYY"
                      />
                    </FormControl>
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl size="small" component="fieldset" fullWidth>
                      <DatePicker
                        label="Bitiş Tarihi"
                        value={lastDate}
                        onChange={(newValue) => {
                          if (!newValue || newValue.toString() === "Invalid Date") {
                            setLastDate("");
                          } else {
                            setLastDate(newValue.format("YYYY-MM-DD"));
                          }
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} required autoComplete="off" />
                        )}
                        mask="__-__-____"
                        inputFormat="DD-MM-YYYY"
                      />
                    </FormControl>
                  </LocalizationProvider>
                </div>
              )}
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                  gap: "var(--gap2x)",
                }}
              >
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Rapor Dönemi</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={reportEra}
                    label="Rapor Dönemi"
                    name="report_era"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>
                      <em>Tüm Zamanlar</em>
                    </MenuItem>
                    <MenuItem value={"daily"}>Günlük</MenuItem>
                    <MenuItem value={"weekly"}>Haftalık</MenuItem>
                    <MenuItem value={"monthly"}>Aylık</MenuItem>
                    <MenuItem value={"quarterly"}>3 Aylık</MenuItem>
                    <MenuItem value={"last_year"}>Geçen Yıl</MenuItem>
                    <MenuItem value={"this_year"}>Bu Yıl</MenuItem>
                  </Select>
                </FormControl>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Faturalandırma Durumu</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={invoiceStatus}
                    name="invoice_status"
                    label="Faturalandırma Durumu"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>
                      <em>Hepsi</em>
                    </MenuItem>
                    <MenuItem value={0}>Faturalandırılmamış</MenuItem>
                    <MenuItem value={1}>Faturalandırılmış</MenuItem>
                  </Select>
                </FormControl>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Faturalandırma Kuru</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={invoiceCurrency}
                    name="invoice_currency"
                    label="Faturalandırma Kuru"
                    onChange={handleChange}
                  >
                    <MenuItem value={"TRY"}>({Currency("TRY")}) Türk Lirası</MenuItem>
                    <MenuItem value={"USD"}>({Currency("USD")}) Amerikan Doları</MenuItem>
                  </Select>
                </FormControl>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Üyelik</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={membership}
                    name="membership"
                    label="Üyelik"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>
                      <em>Hepsi</em>
                    </MenuItem>
                    <MenuItem value={"Individual"}>Bireysel</MenuItem>
                    <MenuItem value={"Company"}>Kurumsal</MenuItem>
                  </Select>
                </FormControl>
                {memberLoading ? (
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
                  </div>
                ) : (
                  <Autocomplete
                    id="customer-group-typing-select"
                    fullWidth
                    size="small"
                    options={memberList}
                    value={memberList.filter((option) => members.includes(option.value)) || []}
                    onChange={(e, newValue) => {
                      setMembers(
                        newValue.map((item) =>
                          typeof value === "string" ? item.value.split(",") : item.value
                        )
                      );
                    }}
                    autoHighlight
                    autoComplete="off"
                    openOnFocus={false}
                    getOptionLabel={(option) => `${option.value} - ${AbbreviateName(option.label)}`}
                    multiple
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.value} - {AbbreviateName(option.label)}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Üyeler"
                        autoComplete="off"
                        name="unique-customer-group-field"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off",
                        }}
                      />
                    )}
                  />
                )}
                <Autocomplete
                  id="country-typing-select"
                  fullWidth
                  size="small"
                  options={CountriesTurkishSorted()}
                  value={
                    CountriesTurkishSorted().filter((option) =>
                      senderCountries.includes(option.iso_code)
                    ) || []
                  }
                  onChange={(event, newValue) => {
                    setSenderCountries(
                      newValue.map((item) =>
                        typeof value === "string" ? item.iso_code.split(",") : item.iso_code
                      )
                    );
                  }}
                  autoHighlight
                  openOnFocus={false}
                  autoComplete="off"
                  getOptionLabel={(option) =>
                    `${option.country_name_tr}, ${option.country_name_en}`
                  }
                  multiple
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.iso_code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.iso_code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.country_name_tr}, {option.country_name_en}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Çıkış Ülkesi Seçiniz"
                      name="unique-country-field"
                      autoComplete="off"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off",
                      }}
                    />
                  )}
                />
                <Autocomplete
                  id="country-typing-select"
                  fullWidth
                  size="small"
                  options={CountriesTurkishSorted()}
                  value={
                    CountriesTurkishSorted().filter((option) =>
                      invoiceAddressCountries.includes(option.iso_code)
                    ) || []
                  }
                  onChange={(event, newValue) => {
                    setInvoiceAddressCountries(
                      newValue.map((item) =>
                        typeof value === "string" ? item.iso_code.split(",") : item.iso_code
                      )
                    );
                  }}
                  autoHighlight
                  openOnFocus={false}
                  autoComplete="off"
                  getOptionLabel={(option) =>
                    `${option.country_name_tr}, ${option.country_name_en}`
                  }
                  multiple
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.iso_code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.iso_code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.country_name_tr}, {option.country_name_en}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Fatura Adres Ülkesi"
                      name="unique-country-field"
                      autoComplete="off"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off",
                      }}
                    />
                  )}
                />
                <Autocomplete
                  id="customer-group-typing-select"
                  fullWidth
                  size="small"
                  options={cg}
                  value={cg.filter((option) => customerGroup.includes(option.value)) || []}
                  onChange={(e, newValue) => {
                    setCustomerGroup(
                      newValue.map((item) =>
                        typeof value === "string" ? item.value.split(",") : item.value
                      )
                    );
                  }}
                  autoHighlight
                  autoComplete="off"
                  openOnFocus={false}
                  getOptionLabel={(option) => `${option.label}`}
                  multiple
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Müşteri Grubu"
                      autoComplete="off"
                      name="unique-customer-group-field"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off",
                      }}
                    />
                  )}
                />
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Fiyatlama Tipi</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={profitType}
                    label="Fiyatlama Tipi"
                    name="pricing_type"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>
                      <em>Hepsi</em>
                    </MenuItem>
                    <MenuItem value={"amt"}>Maliyet + Miktar</MenuItem>
                    <MenuItem value={"pct"}>Maliyet + Yüzde (%)</MenuItem>
                    <MenuItem value={"fix_profit_plus_amt"}>Fix Profit + Miktar</MenuItem>
                    <MenuItem value={"fix_profit_plus_pct"}>Fix Profit + Yüzde (%)</MenuItem>
                    <MenuItem value={"special_profit_plus_pct"}>Özel Fix Kar + Yüzde (%)</MenuItem>
                    <MenuItem value={"special_profit_plus_amt"}>Özel Fix Kar + Miktar</MenuItem>
                  </Select>
                </FormControl>
                {profitType && (
                  <TextField
                    required
                    value={profitAmount}
                    autoComplete="off"
                    size="small"
                    fullWidth
                    label="Fiyatlama Miktarı"
                    name="pricing_amount"
                    onChange={handleChange}
                  />
                )}
                <Autocomplete
                  id="shipment-type-typing-select"
                  fullWidth
                  size="small"
                  options={shipmentTypes}
                  value={
                    shipmentTypes.filter((option) => shipmentType.includes(option.value)) || []
                  }
                  onChange={(e, newValue) => {
                    setShipmentType(
                      newValue.map((item) =>
                        typeof value === "string" ? item.value.split(",") : item.value
                      )
                    );
                  }}
                  autoHighlight
                  autoComplete="off"
                  openOnFocus={false}
                  getOptionLabel={(option) => `${option.label}`}
                  multiple
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Ürün"
                      autoComplete="off"
                      name="unique-shipment-type-field"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off",
                      }}
                    />
                  )}
                />
                {loading ? (
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
                  </div>
                ) : (
                  <Autocomplete
                    id="supplier-typing-select"
                    fullWidth
                    size="small"
                    options={supplierList}
                    value={supplierList.filter((option) => supplier.includes(option.id)) || []}
                    onChange={(e, newValue) => {
                      setSupplier(
                        newValue.map((item) =>
                          typeof value === "string" ? item.id.split(",") : item.id
                        )
                      );
                    }}
                    autoHighlight
                    autoComplete="off"
                    openOnFocus={false}
                    getOptionLabel={(option) => `${option.id} - ${option.supplier_name}`}
                    multiple
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.id} - {option.supplier_name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tedarikçi"
                        autoComplete="off"
                        name="unique-supplier-field"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off",
                        }}
                      />
                    )}
                  />
                )}
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Gönderi Durumu</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={parcelStatus}
                    label="Gönderi Durumu"
                    name="parcel_status"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>
                      <em>Hepsi</em>
                    </MenuItem>
                    <MenuItem value={0}>Oluşturuldu</MenuItem>
                    <MenuItem value={2}>iyziMerkez’de İşlemde</MenuItem>
                    <MenuItem value={3}>iyziMerkez’den Çıkış Yapanlar</MenuItem>
                  </Select>
                </FormControl>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Kurye Tipi</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={courierType}
                    name="courier_type"
                    label="Kurye Tipi"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>
                      <em>Hepsi</em>
                    </MenuItem>
                    <MenuItem value={"iyziCourier"}>iyziKurye</MenuItem>
                    <MenuItem value={"Getir"}>Kendin Getir</MenuItem>
                    <MenuItem value={"Other"}>Kendin Gönder </MenuItem>
                    <MenuItem value={"Aras"}>Aras</MenuItem>
                    <MenuItem value={"Mng"}>Mng</MenuItem>
                    <MenuItem value={"Surat"}>Sürat</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {searchLoading ? (
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
                  </div>
                ) : (
                  <Button type="submit" className="iyzi-button-blue">
                    <TravelExploreIcon /> Göster
                  </Button>
                )}
              </div>
            </form>
          </CardContent>
        </Collapse>
      </Card>
    </ThemeProvider>
  );
};

export default NewMemberOptions;
