import "../../../style/myWallet/MyWallet.css";
import { Box, createTheme, Pagination, ThemeProvider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useRecoilState, useRecoilValue } from "recoil";
import { useInView, animated } from "@react-spring/web";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import axios from "../../../api/axios";
import { drawerOpenState } from "../../../atoms/viewPadding";
import NavBar from "../../components/sidebar/NavBar";
import { ToastContainer } from "react-toastify";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import WrapperStyle from "../../../utils/WrapperStyle";
import FinanceNav from "../../components/finance/FinanceNav";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import {
  financeTazminIadeCurrentPageState,
  financeTazminIadeDataState,
  financeTazminIadeTotalPageState,
} from "../../../atoms/finance";
import FinanceTazminIadeSearch from "./financeTazminIade/FinanceTazminIadeSearch";
import FinanceTazminIadeTable from "./financeTazminIade/FinanceTazminIadeTable";
import FinanceTazminIadeModal from "./financeTazminIade/FinanceTazminIadeModal";
import FinanceTazminIadeResponseModal from "./financeTazminIade/FinanceTazminIadeResponseModal";
import Errors from "../../../utils/Errors";
import NotificationBar from "../sidebar/NotificationBar";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const FinanceTazminIade = () => {
  DynamicTitle("Tazmin İşlemleri - Admin");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const drawerOpen = useRecoilValue(drawerOpenState);

  const [loading, setLoading] = useState(false);

  const navUnder = {
    paddingLeft: drawerOpen ? "264px" : 0,
    transition: "all 150ms linear",
    backgroundColor: "var(--lighterBg)",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  };

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  // Sayfa Fonksiyonları
  const [, setFinanceTazminIadeData] = useRecoilState(financeTazminIadeDataState);
  const [financeTazminIadeTotalPage, setFinanceTazminIadeTotalPage] = useRecoilState(
    financeTazminIadeTotalPageState
  );
  const [financeTazminIadeCurrentPage, setFinanceTazminIadeCurrentPage] = useRecoilState(
    financeTazminIadeCurrentPageState
  );

  const getData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`refund-compensation`, {
        params: {
          limit: 100,
          page: 1,
          order_direction: "DESC",
        },
      })
      .then((res) => {
        setFinanceTazminIadeData(res.data.data);
        setFinanceTazminIadeTotalPage(res.data.totalPage);
        setFinanceTazminIadeCurrentPage(res.data.currentPage);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [setFinanceTazminIadeData, setFinanceTazminIadeTotalPage, setFinanceTazminIadeCurrentPage]);

  const handleBalancePageChange = useCallback(
    async (e, page) => {
      setLoading(true);
      await axios
        .get(`refund-compensation`, {
          params: {
            limit: 100,
            page: page,
            order_direction: "DESC",
          },
        })
        .then((res) => {
          setFinanceTazminIadeData(res.data.data);
          setFinanceTazminIadeTotalPage(res.data.totalPage);
          setFinanceTazminIadeCurrentPage(res.data.currentPage);
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    },
    [setFinanceTazminIadeData, setFinanceTazminIadeTotalPage, setFinanceTazminIadeCurrentPage]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <ThemeProvider theme={theme}>
      <NotificationBar />
      <section
        className="my-wallet"
        style={{ paddingLeft: drawerOpen ? 0 : "64px", transition: "all 150ms linear" }}
      >
        <NavBar />
        <ToastContainer />
        <FinanceTazminIadeModal />
        <FinanceTazminIadeResponseModal />
        <Box className="nav-under" style={navUnder}>
          <div>
            <ReceiptLongOutlinedIcon /> Bakiye İade Talepleri
          </div>
        </Box>

        <Box className="my-wallet-wrapper" style={WrapperStyle()}>
          <FinanceNav />
          <animated.div ref={ref} style={springs}>
            <Box className="my-wallet-container">
              {loading ? (
                <Box
                  sx={{
                    height: "500px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img className="loading-logo" src={miniLogo} alt="" />
                </Box>
              ) : (
                <>
                  <FinanceTazminIadeSearch />
                  <FinanceTazminIadeTable />
                  {financeTazminIadeTotalPage > 1 && (
                    <Pagination
                      showFirstButton
                      showLastButton
                      size="small"
                      count={financeTazminIadeTotalPage}
                      page={financeTazminIadeCurrentPage}
                      onChange={handleBalancePageChange}
                    />
                  )}
                </>
              )}
            </Box>
          </animated.div>
        </Box>
      </section>
    </ThemeProvider>
  );
};

export default FinanceTazminIade;
