import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import RemoveFileExtension from "../../../../utils/RemoveFileExtension";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import Errors from "../../../../utils/Errors";
import { useRecoilState } from "recoil";
import { memberChangeActivatedState } from "../../../../atoms/members";

const MemberDocumentEditApproval = ({
  member,
  document,
  documents,
  setDocuments,
  documentType,
}) => {
  const [documentLoading, setDocumentLoading] = useState(false);
  const [documentStatus, setDocumentStatus] = useState(1);
  const [documentId, setDocumentId] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [denyReason, setDenyReason] = useState("");
  const [type, setType] = useState("");

  const [loading, setLoading] = useState(false);

  const [rejectOpen, setRejectOpen] = useState(false);
  const [approveOpen, setApproveOpen] = useState(false);

  const [, setMemberChangeActivated] = useRecoilState(memberChangeActivatedState);
  console.log("documents", documents);
  const isThere = (value) => documents?.some((e) => e.type === value);
  const handleToSeeDocument = (id) => {
    setDocumentLoading(true);
    axios
      .get(`document/${id}`, { responseType: "arraybuffer" })
      .then((response) => {
        let blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        let image = window.URL.createObjectURL(blob);
        window.open(image, "_blank");
      })
      .catch((error) => Errors(error))
      .finally(() => setDocumentLoading(false));
  };

  const handleDeleteOpen = (id) => {
    setDeleteOpen(true);
    setDocumentId(id);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setDocumentId(null);
  };

  const handleApproveClose = () => {
    setApproveOpen(false);
    setDocumentStatus(1);
    setDocumentId(null);
    setDenyReason("");
    setType("");
  };

  const handleRejectClose = () => {
    setRejectOpen(false);
  };

  const handleDeleteDocument = () => {
    setDeleteLoading(true);
    axios
      .post(`document/delete/${documentId}`)
      .then(() => {
        setDocuments(documents?.filter((document) => documentId !== document.id));
        setMemberChangeActivated(true);
      })
      .catch((error) => Errors(error))
      .finally(() => setDeleteLoading(false));
    handleDeleteClose();
  };

  const handleApprovalDocument = useCallback(() => {
    setLoading(true);
    if (documentStatus === 0) {
      setRejectOpen(false);
      axios
        .post(`document/update/${document.id}`, {
          parent_id: member.id,
          parent_type: "Member",
          status: documentStatus,
          deny_reason: denyReason,
        })
        .then((response) => {
          const filteredDocuments = documents.filter((e) => e.id !== document.id);
          setDocuments([...filteredDocuments, response.data.data]);
          // Show success toast
          toast.success(response.data.message);

          // Action Catcher
          setMemberChangeActivated(true);
        })
        .catch((error) => Errors(error))
        .finally(() => {
          setLoading(false);
          setDocumentStatus(1);
          setDocumentId(null);
          setDenyReason("");
          setType("");
        });
    } else if (documentStatus === 2) {
      setApproveOpen(false);
      axios
        .post(`document/update/${document.id}`, {
          type,
          parent_id: member.id,
          parent_type: "Member",
          status: documentStatus,
        })
        .then((response) => {
          const filteredDocuments = documents.filter((e) => e.id !== document.id);
          setDocuments([...filteredDocuments, response.data.data]);

          // Show success toast
          toast.success(response.data.message);

          // Action Catcher
          setMemberChangeActivated(true);
        })
        .catch((error) => Errors(error))
        .finally(() => {
          setLoading(false);
          setDocumentStatus(1);
          setDocumentId(null);
          setDenyReason("");
          setType("");
        });
    }
  }, [
    denyReason,
    document,
    documentStatus,
    documents,
    member,
    setDocuments,
    type,
    setMemberChangeActivated,
  ]);

  const handleDocumentType = useCallback((e) => {
    setType(e.target.value);
  }, []);

  const handleOpenForm = (e) => {
    if (e) e.preventDefault();
    if (documentStatus === 0) {
      setRejectOpen(true);
    } else if (documentStatus === 2) {
      setApproveOpen(true);
    }
  };

  const result =
    documentType === "tax_plate"
      ? "Vergi Levhası"
      : documentType === "operating_certificate"
      ? "İşletme Belgesi"
      : documentType === "identity"
      ? "Kimlik"
      : documentType === "trade_registry_gazette"
      ? "Ticaret Sicili"
      : documentType === "signature_circulars"
      ? "İmza Beyannameleri"
      : "Belirtilmeyen bir doküman türü";

  return (
    <Box
      sx={{
        display: "flex",
        gap: "var(--gap)",
        alignItems: "center",
      }}
    >
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Evrak Silme İsteği"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Seçtiğin evrağı silmek üzereyim, emin misin?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="iyzi-button" onClick={handleDeleteClose}>
            Hayır
          </Button>
          <Button className="iyzi-button" onClick={handleDeleteDocument} autoFocus>
            Evet
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={rejectOpen}
        onClose={handleRejectClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Evrak Ret"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Seçtiğin evrağı reddetmek üzereyim, emin misin?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="iyzi-button" onClick={handleRejectClose}>
            Hayır
          </Button>
          <Button className="iyzi-button" onClick={handleApprovalDocument} autoFocus>
            Evet
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={approveOpen}
        onClose={handleApproveClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Evrak Onay"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Seçtiğin evrağı onaylıyorum, emin misin?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="iyzi-button" onClick={handleApproveClose}>
            Hayır
          </Button>
          <Button className="iyzi-button" onClick={handleApprovalDocument} autoFocus>
            Evet
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          border: "1px solid var(--inputBorderColor)",
          maxWidth: "400px",
          width: "100%",
          borderRadius: "var(--buttonBorderRadius)",
          padding: "5px 10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "var(--lighterBg)",
          borderBottomColor:
            Number(document.status) === 0
              ? "var(--shipmentRed)"
              : Number(document.status) === 1
              ? "var(--shipmentYellow)"
              : Number(document.status) === 2
              ? "var(--shipmentGreen)"
              : "black",
          borderBottomWidth: "3px",
        }}
      >
        <Typography fontSize={13}>{RemoveFileExtension(document.name)}</Typography>

        <Box sx={{ display: "flex", gap: "var(--gap)" }}>
          {documentLoading ? (
            <CircularProgress
              sx={{
                width: "21px!important",
                height: "21px!important",
                margin: "0 auto",
              }}
            />
          ) : (
            <VisibilityIcon
              onClick={() => handleToSeeDocument(document.id)}
              sx={{ color: "var(--info)", cursor: "pointer" }}
            />
          )}
          {deleteLoading ? (
            <CircularProgress
              sx={{
                width: "21px!important",
                height: "21px!important",
                margin: "0 auto",
              }}
            />
          ) : (
            <DeleteIcon
              onClick={() => document.status !== "2" && handleDeleteOpen(document.id)}
              sx={{
                color: "var(--shipmentRed)",
                cursor: document.status === "2" ? "auto" : "pointer",
                opacity: document.status === "2" ? 0.3 : 1,
              }}
            />
          )}
        </Box>
      </Box>
      {Number(document.status) === 0 ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "var(--lighterBg)",
              backgroundColor: "var(--shipmentRed)",
              borderRadius: "var(--wrapperBorderRadius)",
              padding: "6px 8px",
              gap: "5px",
            }}
          >
            <CancelOutlinedIcon sx={{ color: "var(--lighterBg)" }} /> Onaylanmadı
          </Box>
          <Box>{result}</Box>
        </>
      ) : Number(document.status) === 2 ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "var(--lighterBg)",
              backgroundColor: "var(--shipmentGreen)",
              borderRadius: "var(--wrapperBorderRadius)",
              padding: "6px 8px",
              gap: "5px",
            }}
          >
            <CheckCircleOutlineOutlinedIcon sx={{ color: "var(--lighterBg)" }} /> Onaylandı
          </Box>
          <Box>{result}</Box>
        </>
      ) : (
        <form
          style={{
            display: "flex",
            alignItems: "center",
            gap: "var(--gap)",
          }}
          onSubmit={handleOpenForm}
        >
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel id="document-status-select-label">Onay Durumu</InputLabel>
            <Select
              labelId="document-status-select-label"
              id="document-status-select"
              value={documentStatus}
              label="Onay Durumu"
              onChange={(e) => setDocumentStatus(e.target.value)}
            >
              <MenuItem value={1}>
                <em>Beklemede</em>
              </MenuItem>
              <MenuItem value={0}>Onaylanmadı</MenuItem>
              <MenuItem value={2}>Onaylandı</MenuItem>
            </Select>
          </FormControl>
          {documentStatus === 0 && (
            <TextField
              size="small"
              autoComplete="off"
              required
              label="Onaylanmama Sebebi"
              sx={{ minWidth: 200 }}
              value={denyReason}
              onChange={(e) => setDenyReason(e.target.value)}
            />
          )}
          {documentStatus === 2 && (
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel id="document-type-select-label">Evrak Tipi</InputLabel>
              <Select
                labelId="document-type-select-label"
                id="document-type-select"
                value={type}
                label="Evrak Tipi"
                onChange={(e) => handleDocumentType(e)}
              >
                <MenuItem value={""}>
                  <em>Hiçbiri</em>
                </MenuItem>
                <MenuItem disabled={isThere("tax_plate")} value={"tax_plate"}>Vergi Levhası</MenuItem>
                <MenuItem disabled={isThere("signature_circulars")} value={"signature_circulars"}>İmza Sirküleri</MenuItem>
                <MenuItem disabled={isThere("trade_registry_gazette")} value={"trade_registry_gazette"}>Sicil Gazetesi</MenuItem>
                <MenuItem disabled={isThere("identity")} value={"identity"}>Kimlik Numarası</MenuItem>
                <MenuItem disabled={isThere("operating_certificate")} value={"operating_certificate"}>Faaliyet Belgesi</MenuItem>
              </Select>
            </FormControl>
          )}
          {((type !== "" && documentStatus === 2) || documentStatus === 0) &&
            (loading ? (
              <div
                style={{
                  width: "92px",
                  height: "36.5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress sx={{ width: "24px!important", height: "24px!important" }} />
              </div>
            ) : (
              <Button
                type="submit"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "var(--lighterBg)",
                  backgroundColor:
                    documentStatus === 0
                      ? "var(--notRed)"
                      : documentStatus === 2
                      ? "var(--shipmentGreen)"
                      : "var(--shipmentBlue)",
                  borderRadius: "var(--wrapperBorderRadius)",
                  padding: "6px 8px",
                  gap: "5px",
                  transition: "all 150ms linear",
                  textTransform: "none",
                  ":hover": {
                    transition: "all 150ms linear",
                    backgroundColor:
                      documentStatus === 0
                        ? "var(--shipmentRed)"
                        : documentStatus === 2
                        ? "var(--success)"
                        : "var(--info)",
                  },
                }}
              >
                {documentStatus === 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      color: "var(--lighterBg)",
                    }}
                  >
                    <CancelOutlinedIcon sx={{ color: "var(--lighterBg)" }} /> Reddet
                  </Box>
                )}
                {documentStatus === 2 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      color: "var(--lighterBg)",
                    }}
                  >
                    <CheckCircleOutlineOutlinedIcon sx={{ color: "var(--lighterBg)" }} /> Onayla
                  </Box>
                )}
              </Button>
            ))}
        </form>
      )}
      {/* {document.type && "-"} {document.type && document.type} */}
    </Box>
  );
};

export default MemberDocumentEditApproval;
