const AbbreviateName = (name, maxLength = 12) => {
  if (name.length <= maxLength) {
    return name;
  }

  const parts = name.split(" ");
  if (parts.length === 1) {
    return `${parts[0][0]}. ${parts[0].slice(1)}`;
  } else {
    return parts.reduce((acc, part, index) => {
      if (index === 0) {
        return `${part[0]}.`;
      } else if (index === parts.length - 1) {
        return `${acc} ${part}`;
      } else {
        return `${acc} ${part[0]}.`;
      }
    }, "");
  }
};

export default AbbreviateName;
