import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { pastAddressModalState } from "../../../../atoms/myShipmentsAdmin";
import FormatDateHour from "../../../../utils/FormatDateHour";
import { useCallback } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const PastAddress = () => {
  const [pastAddressModal, setPastAddressModal] = useRecoilState(
    pastAddressModalState
  );

  const handleClose = useCallback(() => {
    setPastAddressModal({ status: false, data: [] });
  }, [setPastAddressModal]);

  return (
    <Modal
      sx={{
        padding: 20,
        outline: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "scroll",
      }}
      open={pastAddressModal.status}
      onClose={handleClose}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--wrapperBorderRadius)",
            outline: 0,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "20px",
          }}
        >
          <HighlightOffIcon
            sx={{
              alignSelf: "flex-end",
              cursor: "pointer",
              ":hover": {
                color: "var(--error)",
                transition: "all 150ms linear",
              },
            }}
            onClick={handleClose}
          />
          {pastAddressModal.data.length > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Önceki Adres</TableCell>
                    <TableCell>Şimdiki Adresi</TableCell>
                    <TableCell>Güncelleme Tarihi</TableCell>
                    <TableCell>Alan Adı</TableCell>
                    <TableCell>Oluşturan Kişi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pastAddressModal?.data?.map((address, key) => (
                    <TableRow key={key}>
                      <TableCell>
                        <div>
                          <Typography>
                            <strong>İletişim Adı:</strong>{" "}
                            {address?.get_before_address?.contact_name}
                          </Typography>
                          <Typography>
                            <strong>Adres Tanımı:</strong>{" "}
                            {address?.get_before_address?.address_description}
                          </Typography>
                          <Typography>
                            <strong>Tam Adres:</strong>{" "}
                            {`${address?.get_before_address?.address1}, ${
                              address?.get_before_address?.address2 &&
                              address?.get_before_address?.address2
                            }, ${address?.get_before_address?.town}, ${
                              address?.get_before_address?.state
                            }, ${address?.get_before_address?.country}, ${
                              address?.get_before_address?.postal_code
                            }`}
                          </Typography>
                          <Typography>
                            <strong>E-posta:</strong>{" "}
                            {address?.get_before_address?.email}
                          </Typography>
                          <Typography>
                            <strong>Telefon:</strong>{" "}
                            {address?.get_before_address?.phone}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>
                          <Typography>
                            <strong>İletişim Adı:</strong>{" "}
                            {address?.get_after_address?.contact_name}
                          </Typography>
                          <Typography>
                            <strong>Adres Tanımı:</strong>{" "}
                            {address?.get_after_address?.address_description}
                          </Typography>
                          <Typography>
                            <strong>Tam Adres:</strong>{" "}
                            {`${address?.get_after_address?.address1}, ${
                              address?.get_after_address?.address2 &&
                              address?.get_after_address?.address2
                            }, ${address?.get_after_address?.town}, ${
                              address?.get_after_address?.state
                            }, ${address?.get_after_address?.country}, ${
                              address?.get_after_address?.postal_code
                            }`}
                          </Typography>
                          <Typography>
                            <strong>E-posta:</strong>{" "}
                            {address?.get_after_address?.email}
                          </Typography>
                          <Typography>
                            <strong>Telefon:</strong>{" "}
                            {address?.get_after_address?.phone}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>
                        {FormatDateHour(address?.created_at)}
                      </TableCell>
                      <TableCell>{address?.field_name}</TableCell>
                      <TableCell>
                        {address?.get_created_by?.name}{" "}
                        {address?.get_created_by?.surname}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {pastAddressModal.data.length === 0 && (
            <Typography>Geçmiş adresiniz bulunmamaktadır.</Typography>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PastAddress;
