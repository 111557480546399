import { CircularProgress, createTheme } from "@mui/material";
import { useRecoilValue } from "recoil";
import { useCookies } from "react-cookie";
import { useInView, animated } from "@react-spring/web";
import { ThemeProvider } from "@emotion/react";
import { drawerOpenState } from "../../../atoms/viewPadding";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import axios from "../../../api/axios";
import NotificationBar from "../sidebar/NotificationBar";
import NavBar from "../sidebar/NavBar";
import WrapperStyle from "../../../utils/WrapperStyle";
import { useEffect, useState } from "react";
import Errors from "../../../utils/Errors";
import ReturnParcelCountChart from "./returnReports/ReturnParcelCountChart";
import ReturnParcelCostChart from "./returnReports/ReturnParcelCostChart";
import ReturnOptions from "./returnReports/ReturnOptions";

const theme = createTheme({
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          overflow: "auto",
          maxHeight: 500,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const ReturnReports = () => {
  DynamicTitle("İadeler - Raporlar");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const drawerOpen = useRecoilValue(drawerOpenState);

  const [loading, setLoading] = useState(true);
  const [returnParcelCount, setReturnParcelCount] = useState({});
  const [returnParcelCost, setReturnParcelCost] = useState({});
  const [currency, setCurrency] = useState(false);
  const [compareDesign, setCompareDesign] = useState(false);

  const navUnder = {
    paddingLeft: drawerOpen ? "264px" : 0,
    transition: "all 150ms linear",
    backgroundColor: "var(--lighterBg)",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  };

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const getData = () => {
    axios
      .get(`report/parcel-return`)
      .then((response) => {
        setReturnParcelCount(response.data?.data_return_parcel_count);
        setReturnParcelCost(response.data?.data_return_parcel_cost);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <NotificationBar />
      <NavBar />
      <section className="my-wallet" style={{ minHeight: "calc(100vh - 64px)" }}>
        <div className="nav-under" style={navUnder}>
          <div>İadeler</div>
        </div>
        <div className="my-wallet-wrapper" style={WrapperStyle()}>
          <animated.div ref={ref} style={springs}>
            <div className="my-wallet-container">
              <ReturnOptions
                setReturnParcelCount={setReturnParcelCount}
                setReturnParcelCost={setReturnParcelCost}
                setCurrency={setCurrency}
                setCompareDesign={setCompareDesign}
              />
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "column", gap: "var(--gap)" }}>
                  <ReturnParcelCountChart data={returnParcelCount} compareDesign={compareDesign} />
                  <ReturnParcelCostChart data={returnParcelCost} currency={currency} compareDesign={compareDesign} />
                </div>
              )}
            </div>
          </animated.div>
        </div>
      </section>
    </ThemeProvider>
  );
};

export default ReturnReports;
