import { Typography } from "@mui/material";
import LighterWrapperBox from "../../../../style/styleJs/LighterWrapperBox";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import OperationMeasureControlResultTable from "./OperationMeasureControlResultTable";
import OperationMeasureControlResultCheck from "./OperationMeasureControlResultCheck";

const OperationMeasureControlResult = () => {
  return (
    <div
      style={LighterWrapperBox()}
      className="operation-measure-control-result"
    >
      <Typography
        align="center"
        fontSize={24}
        fontWeight={700}
        color={"var(--shipmentBlue)"}
      >
        AWB: TR81539995GB CW FARK BİLDİRİMİ/DÜZENLEMESİ
      </Typography>
      <div
        style={{
          display: "flex",
          gap: "var(--gap3x)",
          justifyContent: "center",
        }}
      >
        <Typography fontSize={12} color={"var(--priceText)"}>
          HAWB: 273522795914
        </Typography>
        <Typography fontSize={12} color={"var(--priceText)"}>
          Taşıyıcı: FEDEX
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "var(--gap3x)",
        }}
      >
        <div
          style={{
            border: "1px solid transparent",
            padding: "5px",
            color: "var(--lighterBg)",
            backgroundColor: "var(--shipmentRed)",
            width: "fit-content",
            borderRadius: "var(--buttonBorderRadius)",
          }}
        >
          TR
        </div>
        <ArrowRightAltOutlinedIcon />
        <div
          style={{
            border: "1px solid transparent",
            padding: "5px",
            color: "var(--lighterBg)",
            backgroundColor: "var(--shipmentGreen)",
            width: "fit-content",
            borderRadius: "var(--buttonBorderRadius)",
          }}
        >
          GB
        </div>
      </div>
      <OperationMeasureControlResultTable />
      <OperationMeasureControlResultCheck />
      <div></div>
    </div>
  );
};

export default OperationMeasureControlResult;
