export const FormatReturnInteger = (value) => {
  // Rakamlardan başka bir şey içerip içermediğini kontrol edin
  const isNumeric = /^[0-9]+$/.test(value);

  // Sadece sayısal değerler içeriyorsa, inputu döndür
  if (isNumeric) {
    return value;
  }

  // Sayısal olmayan bir değer içeriyorsa, boş string veya istediğiniz bir değeri döndürün
  return "";
};

export const FormatReturnFieldWithCustomDecimals = (val, decimal) => {
  let value = val.replace(/,/g, ".");

  // Sayısal olmayan karakterleri ve fazladan başlangıç sıfırlarını kaldır
  value = value.replace(/[^0-9.]|(?<=\..*)\.|^0+(?=[.])/g, "");

  // Ondalık noktadan sonra en fazla iki basamağa izin ver
  const parts = value.split(".");
  if (parts.length > 1) {
    parts[1] = parts[1].substring(0, decimal); // Ondalık kısmı iki basamağa kısıtla
    value = parts.join(".");
  }

  return value;
};

export const FormatReturnFieldWithTwoDecimalsWithMaxNumber = (val, maxNumber) => {
  let value = val.replace(/,/g, ".");

  // Sayısal olmayan karakterleri ve fazladan başlangıç sıfırlarını kaldır
  value = value.replace(/[^0-9.]|(?<=\..*)\.|^0+(?=[.])/g, "");

  // Ondalık noktadan sonra en fazla iki basamağa izin ver
  const parts = value.split(".");
  if (parts.length > 1) {
    parts[1] = parts[1]?.substring(0, 2); // Ondalık kısmı iki basamağa kısıtla
    value = parts.join(".");
  }

  if (Number(value) > maxNumber) {
    return maxNumber;
  } else return value;
};

export const FormatReturnFieldWithDotsAndComma = (number) => {
  // Sayının işaretini sakla ve mutlak değerini al
  let sign = number < 0 ? "-" : "";
  let absoluteNumber = Math.abs(number);

  let integerPart = Math.floor(absoluteNumber)?.toString(); // Tam kısmı al ve string'e çevir
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Binlik ayırıcı ekle

  let hasDecimal = number?.toString()?.includes("."); // Ondalık kısım kontrolü

  if (isNaN(number)) {
    return 0;
  } else {
    if (hasDecimal) {
      // Ondalık kısmı yuvarla ve 2 basamak kullan
      let roundedDecimalPart = absoluteNumber?.toFixed(2)?.split(".")[1];

      return `${sign}${integerPart},${roundedDecimalPart}`;
    } else {
      // Ondalık kısım yoksa, sadece tam kısmı döndür
      return `${sign}${integerPart}`;
    }
  }
};
