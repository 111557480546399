import { useCallback, useState } from "react";
import {
  overPayOpenState,
  overPayState,
  productCurrencyState,
  productValueState,
  supplierIdState,
} from "../../../../atoms/createShipment";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  editedProformaState
} from "../../../../atoms/operation"
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import countriesDetailed from "../../../../json/countriesDetailed.json";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Currency from "../../../../utils/CurrencySymbol";
import { useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const OperationProforma = ({ ffdData, setCommoduties, commoduties,disable,proformaCurrency }) => {
  const productValue = useRecoilValue(productValueState);
  const [, setOverPayOpen] = useRecoilState(overPayOpenState);
  const [, setOverPay] = useRecoilState(overPayState);
  const [, setEditedProforma] = useRecoilState(editedProformaState);
  const supplierId = useRecoilValue(supplierIdState);
  const [hsCodeError, setHsCodeError] = useState(false);
  const [qtyError, setQtyError] = useState(false);
  const [productDescriptionError, setProductDescriptionError] = useState(false);
  const productCurrency = useRecoilValue(productCurrencyState);
  const [currency, setCurrency] = useState(proformaCurrency);
  const [proformaEditclick, setProformaEditclick] = useState(false);
  const [changeButton,setChangeButton]=useState(false)
  const [proformaShow, setProformaShow] = useState(true);
  const handleUpdateProforma = () => {
    setChangeButton(true)
    setProformaEditclick(true);
    setEditedProforma(true)
  };
  useEffect(() => {
    const initialData = ffdData?.get_parcel_commoduties || [];
   
    setCommoduties(
      initialData.map((item) => ({
        id: item.id,
        title: item.title,
        sku: item.sku,
        hs_code: item.hs_code,
        country: item.country,
        qty: item.qty,
        price: item.price,
        currency: proformaCurrency,
        total_price: item.total_price,
      }))
    );
  }, [ffdData?.get_parcel_commoduties, setCommoduties,proformaCurrency]);

  const productDescriptionBlur = (e) => {
    const value = e.target.value;
    if (value.length < 9) {
      setProductDescriptionError(true);
    } else setProductDescriptionError(false);
  };
  console.log(commoduties)
  const handleInputChange2 = useCallback(
    (e, i) => {
      const values = [...commoduties];

      if (e.target.name === "qty") {
        if (!/^\d*$/.test(e.target.value)) {
          return;
        }
        const updatedValue = { ...values[i] };
        updatedValue[e.target.name] = Number(e.target.value);
        values[i] = updatedValue;
      } else if (e.target.name === "price") {
        let priceValue = e.target.value.replace(/,/g, ".");
        // Virgül veya nokta sonrası en fazla iki hane olabilir
        const regex = /^\d*(\.|,)?\d{0,2}$/;
        // Noktadan veya virgülden önce sadece tek bir 0 olabilir
        const invalidZeroFormat = /^0{2,}\.\d*$/;
        // Giriş geçerli değilse veya geçersiz 0 formatına sahipse dönüş yap
        if (!regex.test(priceValue) || invalidZeroFormat.test(priceValue))
          return;
        const updatedValue = { ...values[i] };
        updatedValue[e.target.name] = priceValue;
        values[i] = updatedValue;
      } else if (e.target.name === "country") {
        const code = countriesDetailed.find(
          (country) =>
            `${country.country_name_tr}, ${country.country_name_en}` ===
            e.target.value
        ).iso_code;

        values[i][e.target.name] = code;
      } else if (e.target.name === "title") {
        console.log("ttile", e.target.value);
        values[i][e.target.name] = e.target.value
          .toUpperCase()
          .replace(/[^A-Z\s-]/g, "")
          .replace(/\s{2,}/g, " ");

        if (e.target.value.length > 8) {
          setProductDescriptionError(false);
        }
      } else if (e.target.name === "hs_code") {
        if (!/^\d*$/.test(e.target.value) || /^0$/.test(e.target.value)) {
          return;
        }
        if (e.target.value.length >= 6) {
          setHsCodeError(false);
        }
        values[i][e.target.name] = e.target.value;
      } else {
        values[i][e.target.name] = e.target.value;
      }

      values[i].total_price = values[i].qty * values[i].price;
      setCommoduties(values);
    },
    [commoduties]
  );
  const handleHsCodeBlur = useCallback(
    (e, i) => {
      const values = [...commoduties];
      const inputLength = e.target.value.length;

      if (e.target.value.length < 6) {
        setHsCodeError(true);
      } else {
        setHsCodeError(false);
      }

      if (inputLength >= 6 && inputLength <= 12) {
        values[i][e.target.name] =
          e.target.value + "0".repeat(12 - e.target.value.length);
        setCommoduties(values);
      }
    },
    [commoduties, setCommoduties]
  );
  const handleDeleteCommoduties = useCallback(
    (key) => {
      if (commoduties.length > 1) {
        setCommoduties(
          commoduties.filter((commodutie) => commodutie.id !== key)
        );
      }
    },
    [commoduties,setCommoduties]
  );
  const handleQtyBlur = useCallback(
    (e, i) => {
      if (e.target.value < 1) {
        setQtyError(true);
      } else {
        setQtyError(false);
      }
    },
    [commoduties, setCommoduties]
  );
  const handleInputBlur = useCallback(
    (e, i) => {
      const values = [...commoduties];

      // qty ve price değerlerinin her ikisi de dolu mu kontrol edelim
      const isQtyAndPriceFilled = values.every(
        (value) => value.qty !== "" && value.qty !== "0" && value.price !== ""
      );

      // Eğer her ikisi de dolu değilse işlem yapma
      if (!isQtyAndPriceFilled) {
        return;
      }

      // Toplam değeri kontrol et
      const totalValue = values.reduce((acc, curr) => {
        return acc + Number(curr.price) * Number(curr.qty);
      }, 0);

      if (
        totalValue > Number(productValue) &&
        supplierId !== 302 &&
        supplierId !== 337 &&
        supplierId !== 338 &&
        supplierId !== 339
      ) {
        setOverPayOpen(true);
        setOverPay(true); // overpay durumunda setOverPay'i de true yap
      } else {
        setOverPay(false);
      }
      setCommoduties(values);
    },
    [commoduties, productValue, setOverPay, setOverPayOpen, supplierId]
  );
  const handleAddInput = useCallback(() => {
    if (
      commoduties[commoduties.length - 1].title === "" ||
      //commoduties[commoduties.length - 1].sku === "" ||
      commoduties[commoduties.length - 1].hs_code === "" ||
      commoduties[commoduties.length - 1].country === "" ||
      commoduties[commoduties.length - 1].price === "" ||
      commoduties[commoduties.length - 1].qty === ""
    ) {
      return false;
    } else {
      setCommoduties([
        ...commoduties,
        {
          id: commoduties.length,
          title: "",
          sku: "",
          hs_code: "",
          country: "",
          qty: "",
          price: "",
          currency: proformaCurrency,
          total_price: "",
        },
      ]);
    }
  }, [commoduties, setCommoduties, proformaCurrency]);

const handleActive= ()=>{
  setProformaEditclick(false)
  setChangeButton(false)
  setEditedProforma(false)
}

  return (
    <>
      <div>
      {commoduties.map((com, key) => (
        <div
          key={key}
          style={{
            display: " flex",
            flexDirection: " column",
            gap: "20px",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{marginRight:"140px"}}>
              {" "}
              <TextField
                required={com.title.length < 9}
                defaultValue={com.title}
                autoComplete="off"
                disabled={disable ? disable :!proformaEditclick}
                onChange={(e) => handleInputChange2(e, key)}
                onBlur={productDescriptionBlur}
                error={productDescriptionError}
                helperText={
                  productDescriptionError
                    ? "Ürün açıklaması 9 haneden az olamaz"
                    : ""
                }
                name="title"
                size="small"
                label="Ürün Açıklaması"
                inputProps={{
                  pattern: "^[a-zA-Z -/]+$",
                  minLength: 9,
                }}
              />{" "}
            </div>

            <div style={{ display: "flex", gap: "10px" }}>
              <Autocomplete
                id="country-typing-select"
                size="small"
                sx={{ maxWidth: "100px" }}
                autoComplete="off"
                name="country"
                defaultValue={
                  countriesDetailed.find((c) => c.iso_code === com.country) !==
                  undefined
                    ? countriesDetailed.find((c) => c.iso_code === com.country)
                    : null
                }
                options={countriesDetailed}
                autoHighlight
                getOptionLabel={(option) =>
                  `${option.country_name_tr}, ${option.country_name_en}`
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.iso_code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.iso_code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.country_name_tr}, {option.country_name_en}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Menşei"
                    required
                    autoComplete="off"
                    name="country"
                    disabled={disable ? disable :!proformaEditclick}
                    onBlur={(e) => handleInputChange2(e, key)}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off", // disable autocomplete and autofill
                    }}
                    sx={{ margin: "0" }}
                  />
                )}
              />

              <TextField
                required
                defaultValue={com.hs_code}
                onChange={(e) => handleInputChange2(e, key)}
                name="hs_code"
                size="small"
                label="HS Kodu"
                disabled={disable ? disable :!proformaEditclick}
                autoComplete="off"
                inputProps={{
                  minLength: 6,
                  maxLength: 12,
                }}
                onBlur={(e) => handleHsCodeBlur(e, key)}
                error={hsCodeError}
                helperText={hsCodeError && "Hs Kodu 6 haneden küçük girilemez."}
                sx={{ width: "100px" }}
              />

              <TextField
                defaultValue={com.sku}
                onChange={(e) => handleInputChange2(e, key)}
                name="sku"
                size="small"
                label="SKU"
                disabled={disable ? disable :!proformaEditclick}
                autoComplete="off"
                sx={{ width: "100px" }}
              />

              <TextField
                required
                defaultValue={com.qty}
                onChange={(e) => handleInputChange2(e, key)}
                name="qty"
                size="small"
                disabled={disable ? disable :!proformaEditclick}
                label="Miktar"
                autoComplete="off"
                // type="number" // Sadece sayı girişi kabul edilecektir
                inputProps={{
                  min: 1, // En küçük değer sıfır olmalıdır
                }}
                onBlur={(e) => handleQtyBlur(e, key)} // Miktar alanından çıkıldığında bu fonksiyon çalışacak
                error={qtyError}
                helperText={qtyError && "Miktar 0'dan küçük olamaz."}
                sx={{ width: "100px" }}
              />
              <TextField
                defaultValue={com.price}
                onChange={(e) => handleInputChange2(e, key)}
                onBlur={(e) => handleInputBlur(e, key)}
                disabled={disable ? disable :!proformaEditclick}
                //onBlur={handleBlurProductValue}
                name="price"
                required
                size="small"
                label="Birim Fiyatı"
                autoComplete="off"
                sx={{ width: "142px" }}
              />
              <IconButton>
                <DeleteIcon
                  style={{display: key === 0 ? "none" :""}}
                  onClick={() => handleDeleteCommoduties(key)}
                  disabled={commoduties.length === 1}
                />
              </IconButton>
            </div>
          </div>

          <div
            style={{
              justifyContent: " end",
              display: "flex",
              gap: "10px",
              marginRight: key===0 ? "25px":"47px"
            }}
          >
            <TextField
              disabled
              required
              size="small"
              label="Toplam"
              autoComplete="off"
              sx={{ maxWidth: "100px" }}
            />
            <TextField
              value={Number(com.price) * Number(com.qty)}
              name="price"
              disabled
              required
              sx={{ maxWidth: "142px" }}
              size="small"
              label="Toplam Fiyat"
              autoComplete="off"
              helperText={
                <div
                  style={{
                    fontSize: "inherit",
                    color: "var(--error)",
                  }}
                >
                  Gönderi oluştur ilk kısımda belirlediğiniz mal bedeli "
                  {productValue}
                  {Currency(productCurrency)}".
                </div>
              }
            />
          </div>
        </div>
      ))}
      <div style={{justifyContent: "end",display: ffdData?.get_parcel_commoduties.length > 0 ? "flex" :"none"}}>
        <IconButton     disabled={disable ? disable : !proformaEditclick}>
          <AddBoxIcon
            color="primary"
            fontSize="large"
            sx={{ padding: "0", marginTop: "0" }}
            onClick={handleAddInput}
          />
        </IconButton>
      </div>

    </div>
    <div style={{display: ffdData?.get_parcel_commoduties.length > 0 ? "flex" :"none",justifyContent: "end"}}> 
      <div style={{justifyContent: "end",display: proformaEditclick ? "none" : "flex" }}>
        <Button
          className="iyzi-button-blue"
          sx={{
            width: "110px",
          }}
          onClick={handleUpdateProforma}
        >
          Düzenle
        </Button>
      </div>
      <div style={{justifyContent: "end",display: changeButton ? "flex":"none",gap:"10px"}}>
        <Button
          className="iyzi-button-blue"
          sx={{
            width: "110px",
          }}
        >
          Kaydet
        </Button>
        <Button
          className="iyzi-button-blue"
          sx={{
            width: "110px",
          }}
          onClick={handleActive}
        >
          Vazgeç
        </Button>
      </div>
      </div>
    </>
  );
};

export default OperationProforma;
