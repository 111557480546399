import React from "react";
import {
  Box,
  Button,
  createTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useCallback } from "react";
import {
  memberTypeState,
  memberNameState,
  memberCompanyTypeState,
  memberFromDateState,
  memberToDateState,
  memberIdentityNoState,
  memberTaxNoState,
  memberCityState,
  memberTownState,
  memberPhoneState,
  memberListLoadingState,
  memberDetailedStatusState,
  membersStatusState,
  memberListState,
  memberBasicSearchErrorState,
  memberIDState,
  memberTotalPageState,
  memberListCurrentPageState,
  memberWithinTheDayState,
  memberOutsideTheDayState,
} from "../../../atoms/members";
import { useRecoilState, useSetRecoilState } from "recoil";
import "../../../style/members/Members.css";
import axios from "../../../api/axios";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          color: "currentcolor",
        },
        icon: {
          color: "currentcolor",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "currentcolor",
        },
      },
    },
  },
});

function MemberDetailedSearch() {
  const setMemberListLoading = useSetRecoilState(memberListLoadingState);
  const setMemberDetailedStatus = useSetRecoilState(memberDetailedStatusState);
  const setMemberList = useSetRecoilState(memberListState);
  const setBasicSearchError = useSetRecoilState(memberBasicSearchErrorState);
  const setMemberListCurrentPage = useSetRecoilState(memberListCurrentPageState);
  const setMemberTotalPage = useSetRecoilState(memberTotalPageState);

  const [name, setName] = useRecoilState(memberNameState);
  const [memberType, setMemberType] = useRecoilState(memberTypeState);
  const [companyType, setCompanyType] = useRecoilState(memberCompanyTypeState);
  const [fromDate, setFromDate] = useRecoilState(memberFromDateState);
  const [toDate, setToDate] = useRecoilState(memberToDateState);
  const [identityNo, setIdentityNo] = useRecoilState(memberIdentityNoState);
  const [taxNo, setTaxNo] = useRecoilState(memberTaxNoState);
  const [city, setCity] = useRecoilState(memberCityState);
  const [membersStatus, setMembersStatus] = useRecoilState(membersStatusState);
  const [town, setTown] = useRecoilState(memberTownState);
  const [phone, setPhone] = useRecoilState(memberPhoneState);
  const [withinTheDay, setWithinTheDay] = useRecoilState(memberWithinTheDayState);
  const [outsideTheDay, setOutsideTheDay] = useRecoilState(memberOutsideTheDayState);
  const [memberID, setMemberID] = useRecoilState(memberIDState);

  const handleEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleDetailedSearch();
    }
  };

  const handleDetailedSearch = useCallback(async () => {
    setMemberListLoading(true);
    setMemberDetailedStatus(0);

    const params = {
      detailed: 1,
      name,
      member_type: memberType,
      company_type: companyType,
      status: membersStatus,
      from_date: fromDate.length !== 0 && toDate.length !== 0 ? fromDate : "",
      to_date: fromDate.length !== 0 && toDate.length !== 0 ? toDate : "",
      identity_no: identityNo,
      tax_no: taxNo,
      city,
      town,
      phone,
      within_the_day: withinTheDay,
      outside_the_day: outsideTheDay,
      id: memberID,
    };

    await axios
      .get(`member`, { params: params })
      .then((response) => {
        setMemberList(response.data.data);
        setBasicSearchError(false);
        setMemberTotalPage(response.data.totalPages);
        setMemberListCurrentPage(response.data.currentPage);
      })
      .catch((error) => Errors(error))
      .finally(() => setMemberListLoading(false));
  }, [
    setMemberList,
    setMemberListLoading,
    setMemberDetailedStatus,
    setBasicSearchError,
    setMemberListCurrentPage,
    setMemberTotalPage,
    name,
    identityNo,
    taxNo,
    city,
    memberType,
    companyType,
    membersStatus,
    town,
    phone,
    withinTheDay,
    outsideTheDay,
    fromDate,
    toDate,
    memberID,
  ]);



  return (
    <ThemeProvider theme={theme}>
      <Box className="member-detailed-search-wrapper">
        <h6 className="member-detailed-search-title">
          <TravelExploreOutlinedIcon />
          Detaylı Arama
        </h6>
        <Box
          className="member-detailed-search"
          style={{ display: "flex", flexDirection: "column", gap: "5px" }}
        >
          <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              onKeyDown={handleEnterKeyPress}
              autoComplete="off"
              fullWidth
              size="small"
              label="İsim"
            />
            <FormControl fullWidth size="small">
              <InputLabel sx={{ fontSize: "14px" }} id="price-status-select-label">
                Üye Tipi
              </InputLabel>
              <Select
                labelId="price-status-select-label"
                id="price-status-select"
                label="status"
                value={memberType}
                multiline
                onChange={(e) => setMemberType(e.target.value)}
                sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
              >
                <MenuItem value="">
                  <em>Temizle</em>
                </MenuItem>
                <MenuItem value={"Individual"}>Bireysel Üyelik</MenuItem>
                <MenuItem value={"Company"}>Kurumsal Üyelik</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel sx={{ fontSize: "14px" }} id="price-status-select-label">
                Şirket Tipi
              </InputLabel>
              <Select
                labelId="price-status-select-label"
                id="price-status-select"
                label="status"
                value={companyType}
                multiline
                onChange={(e) => setCompanyType(e.target.value)}
                sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
              >
                <MenuItem value="">
                  <em>Temizle</em>
                </MenuItem>
                <MenuItem value={"Individual"}>Şahıs Firması</MenuItem>
                <MenuItem value={"Company"}>Tüzel Firma</MenuItem>
              </Select>
            </FormControl>{" "}
          </div>
          <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
            <TextField
              autoComplete="off"
              fullWidth
              size="small"
              label="Kimlik No"
              value={identityNo}
              onChange={(e) => setIdentityNo(e.target.value)}
              onKeyDown={handleEnterKeyPress}
            />
            <TextField
              fullWidth
              type={"date"}
              InputLabelProps={{ shrink: true }}
              label="Başlangıç Tarihi"
              value={fromDate}
              required
              onChange={(e) => setFromDate(e.target.value)}
              onKeyDown={handleEnterKeyPress}
              size={"small"}
            />
            <TextField
              fullWidth
              type={"date"}
              label="Bitiş Tarihi"
              InputLabelProps={{ shrink: true }}
              required
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              onKeyDown={handleEnterKeyPress}
              size={"small"}
            />
          </div>
          <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
            <FormControl fullWidth size="small">
              <InputLabel sx={{ fontSize: "14px" }} id="price-status-select-label">
                Status
              </InputLabel>
              <Select
                labelId="price-status-select-label"
                id="price-status-select"
                label="status"
                value={membersStatus}
                multiline
                onChange={(e) => setMembersStatus(e.target.value)}
                sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
              >
                <MenuItem value="">
                  <em>Temizle</em>
                </MenuItem>
                <MenuItem value={0}>İnaktif</MenuItem>
                <MenuItem value={1}>Onay Bekleniyor</MenuItem>
                <MenuItem value={2}>Onaylandı</MenuItem>
              </Select>
            </FormControl>
            <TextField
              autoComplete="off"
              fullWidth
              size="small"
              label="vergi No"
              value={taxNo}
              onChange={(e) => setTaxNo(e.target.value)}
              onKeyDown={handleEnterKeyPress}
            />
            <TextField
              autoComplete="off"
              fullWidth
              size="small"
              label="İl"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              onKeyDown={handleEnterKeyPress}
            />
          </div>
          <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
            <TextField
              autoComplete="off"
              fullWidth
              size="small"
              label="ilçe"
              value={town}
              onChange={(e) => setTown(e.target.value)}
              onKeyDown={handleEnterKeyPress}
            />
            <TextField
              autoComplete="off"
              fullWidth
              size="small"
              label="Telefon Numarası"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              onKeyDown={handleEnterKeyPress}
            />
            <TextField
              autoComplete="off"
              fullWidth
              size="small"
              label="Üye ID"
              value={memberID}
              onChange={(e) => setMemberID(e.target.value)}
              onKeyDown={handleEnterKeyPress}
            />
          </div>
          <div style={{display: "flex", gap: "10px", marginTop: "10px"}}>
            <TextField
                autoComplete="off"
                fullWidth
                size="small"
                label="Son Gönderi Gün İçinde Oluşmuş"
                value={withinTheDay}
                onChange={(e) => setWithinTheDay(e.target.value)}
                onKeyDown={handleEnterKeyPress}
            />
            <TextField
                autoComplete="off"
                fullWidth
                size="small"
                label="Son Gönderi Gün Dışında Oluşmuş"
                value={outsideTheDay}
                onChange={(e) => setOutsideTheDay(e.target.value)}
                onKeyDown={handleEnterKeyPress}
            />
            <TextField  style={{ visibility: 'hidden' }} fullWidth size="small"/>
          </div>
        </Box>
        <Box
          className="member-detailed-search-done"
          style={{
            display: " flex",
            justifyContent: " flex-end",
            marginTop: " 10px",
          }}
        >
          <Button className="iyzi-button" onClick={handleDetailedSearch}>
            <SearchOutlinedIcon />
            Arama Yap
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default MemberDetailedSearch;
