import { Box, Button, Pagination } from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import AddAlertOutlinedIcon from "@mui/icons-material/AddAlertOutlined";
import AnnouncementTable from "./AnnouncementTable";
import { useCallback } from "react";
import {
  announcementAddOpenState,
  announcementListCountState,
  announcementListCurrentPageState,
  editAnnouncementState,
  notificationListState,
} from "../../../atoms/announcement";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "../../../api/axios";
import AnnouncementAdd from "./AnnouncementAdd";
import AnnouncementEdit from "./AnnouncementEdit";
import Errors from "../../../utils/Errors";

const AnnouncementList = () => {
  const [, setNotificationList] = useRecoilState(notificationListState);
  const [announcementListCount, setAnnouncementListCount] = useRecoilState(
    announcementListCountState
  );
  const [announcementListCurrentPage, setAnnouncementListCurrentPage] = useRecoilState(
    announcementListCurrentPageState
  );
  const editAnnouncement = useRecoilValue(editAnnouncementState);
  const [announcementAddOpen, setAnnouncementAddOpen] = useRecoilState(announcementAddOpenState);

  const handlePageChange = useCallback(
    async (e, pageValue) => {
      await axios
        .get(`notification?limit=25&page=${pageValue}`)
        .then((response) => {
          setNotificationList(response.data.data);
          setAnnouncementListCurrentPage(response.data.currentPage);
          setAnnouncementListCount(response.data.totalPages);
        })
        .catch((error) => Errors(error));
    },
    [setNotificationList, setAnnouncementListCurrentPage, setAnnouncementListCount]
  );

  const handleAnnouncementAdd = useCallback(() => {
    setAnnouncementAddOpen(true);
  }, [setAnnouncementAddOpen]);

  return (
    <>
      <h6>
        <NotificationsNoneOutlinedIcon />
        Duyurular
      </h6>
      {editAnnouncement ? (
        <AnnouncementEdit />
      ) : announcementAddOpen ? (
        <AnnouncementAdd />
      ) : (
        <>
          <Button onClick={handleAnnouncementAdd} className="announcement-add">
            <AddAlertOutlinedIcon />
            Duyuru Ekle
          </Button>
          <Box className="announcement-list">
            <AnnouncementTable />
          </Box>
          <Pagination
            sx={{ marginLeft: "auto", marginBottom: "var(--gap2x)" }}
            showFirstButton
            showLastButton
            size="small"
            count={Number(announcementListCount)}
            page={Number(announcementListCurrentPage)}
            onChange={handlePageChange}
          />
        </>
      )}
    </>
  );
};

export default AnnouncementList;
