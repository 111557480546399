import {
  Box,
  Button,
  CircularProgress,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ThemeProvider,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import ScaleOutlinedIcon from "@mui/icons-material/ScaleOutlined";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import EditLocationAltOutlinedIcon from "@mui/icons-material/EditLocationAltOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import SecurityUpdateGoodOutlinedIcon from "@mui/icons-material/SecurityUpdateGoodOutlined";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {
  addNoteOpenState,
  connectedPaymentsOpenState,
  editReceiverAddressOpenState,
  editSenderAddressOpenState,
  selectedShipmentForMyShipmentsState,
  proformaUpdateOpenState,
  showFinancialDetailsOpenState,
  addExtraPriceOpenState,
  myShipmentListState,
  adminFilesModalOpenState,
  supplierChangeOpenState,
  statusChangeModalOpenState,
  updateHAWBModalOpenState,
  supplierConsigmentDownloadState,
  adminParcelMemberIdState,
  weightCheckOpenState,
  editDomesticInfoState,
  editDomesticPackageInfoState
} from "../../../atoms/myShipments";
import { useRecoilState } from "recoil";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import DownloadBase64Pdf from "../../../utils/DownloadBase64Pdf";
import { useCallback, useState } from "react";
import {
  caseTransactionsState,
  createAPIState,
  pastAddressModalState,
  adminShipmentDimensionDetailState,
  historyOfChangeModalState,
  domesticInfoModalState,
  adminShipmentContractDetailsState
} from "../../../atoms/myShipmentsAdmin";
import { useCookies } from "react-cookie";
import PreviewIcon from "@mui/icons-material/Preview";
import { useNavigate } from "react-router-dom";
import Errors from "../../../utils/Errors";
import getSuperAdminRoleCheck from "../../../utils/getSuperAdminRoleCheck";
import OpenBase64InNewTab from "../../../utils/OpenBase64InNewTab";

const theme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.1);",
        },
      },
    },
  },
});

const ListItemLinks = ({ shipment }) => {
  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  const navigate = useNavigate();
  const superAdminRoleCheck = getSuperAdminRoleCheck();

  const [, setAdminParcelMemberId] = useRecoilState(adminParcelMemberIdState);
  const [, setSelectedShipmentForMyShipments] = useRecoilState(selectedShipmentForMyShipmentsState);
  const [, setEditReceiverAddressOpen] = useRecoilState(editReceiverAddressOpenState);
  const [, setEditSenderAddressOpen] = useRecoilState(editSenderAddressOpenState);
  const [, setEditDomesticInfo] = useRecoilState(editDomesticInfoState);
  const [, setEditDomesticPackageInfo] = useRecoilState(editDomesticPackageInfoState);
  const [, setAddNoteOpen] = useRecoilState(addNoteOpenState);
  const [, setShowFinancialDetailsOpen] = useRecoilState(showFinancialDetailsOpenState);
  const [, setAddExtraPriceOpen] = useRecoilState(addExtraPriceOpenState);
  const [, setConnectedPaymentsOpen] = useRecoilState(connectedPaymentsOpenState);
  // const [, setHandleOpenDimensionsModal] = useRecoilState(
  //   adminDimensionsModalOpenState
  // );
  const [, setAdminFilesModalOpen] = useRecoilState(adminFilesModalOpenState);
  const [, setSupplierChangeOpen] = useRecoilState(supplierChangeOpenState);
  const [, setStatusChangeModalOpenState] = useRecoilState(statusChangeModalOpenState);
  const [, setUpdateHAWBModalOpenState] = useRecoilState(updateHAWBModalOpenState);

  const [, setProformaUpdateOpen] = useRecoilState(proformaUpdateOpenState);
  const [, setPastAddressModal] = useRecoilState(pastAddressModalState);
  const [, setSupplierConsignmentDownloadState] = useRecoilState(supplierConsigmentDownloadState);
  const [, setShipmentDimensionDetail] = useRecoilState(adminShipmentDimensionDetailState);
  const [, setShipmentContractDetails] = useRecoilState(adminShipmentContractDetailsState);
  const [, setWeightCheckOpen] = useRecoilState(weightCheckOpenState);

  const [, setCaseTransactions] = useRecoilState(caseTransactionsState);
  const [, setCreateAPI] = useRecoilState(createAPIState);
  const [myShipmentList, setMyShipmentList] = useRecoilState(myShipmentListState);

  const [, setHistoryOfChangeModal] = useRecoilState(historyOfChangeModalState);
  const [, setDomesticInfoModal] = useRecoilState(domesticInfoModalState);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogStatus, setDialogStatus] = useState("");
  const [consignmentLoading, setConsignmentLoading] = useState(false);
  const [pastAddressLoading, setPastAddressLoading] = useState(false);
  const [caseTransactionsLoading, setCaseTransactionsLoading] = useState(false);
  const [createAPILoading, setCreateAPILoading] = useState(false);

  // const [cancelLoading, setCancelLoading] = useState(false);
  // const [adress, setAddress] = useState([]);
  const [destroyDialog, setDestroyDialog] = useState(false);
  const [activeLoading, setActiveLoading] = useState(false);

  const handleDestroyDialogOpen = () => {
    setDestroyDialog(true);
  };

  const handleDestroyDialogClose = () => {
    setDestroyDialog(false);
  };

  const handleFilesModalOpenState = () => {
    setAdminFilesModalOpen({
      status: true,
      // data: shipment?.get_ticket?.get_ticket_documents,
      data: shipment,
      // id: shipment?.get_ticket?.id,
      id: shipment?.id,
    });
  };

  const handleShipmentDimensionDetailState = () => {
    setShipmentDimensionDetail({
      status: true,
      data: shipment,
    });
  };

  const handleShipmentContractDetailsState = () => {
    setShipmentContractDetails({
      status: true,
      data: shipment,
    });
  };

  const handleSupplierConsignmentDownload = () => {
    setSupplierConsignmentDownloadState({
      status: true,
      data: shipment.get_parcel_labels,
    });
  };

  const handleUpdateHAWBModalOpenState = () => {
    setSelectedShipmentForMyShipments(shipment);
    setUpdateHAWBModalOpenState(true);
  };

  const handleConsignmentDownload = (shipment) => {
    setConsignmentLoading(true);
    axios
      .get(`parcel/get-consignment/${shipment.id}`)
      .then((response) => {
        OpenBase64InNewTab(response.data.base64, "application/pdf");
        toast.success("Konşimento İndirme Başarılı.", { autoClose: 500 });
      })
      .catch((error) => {
        Errors(error);
      })
      .finally(() => setConsignmentLoading(false));
  };

  // const handleDimensionsOpenState = () => {
  //   setSelectedShipmentForMyShipments(shipment);
  //   setHandleOpenDimensionsModal(true);
  // };

  const handleSupplierChangeOpenState = () => {
    setSelectedShipmentForMyShipments(shipment);
    setSupplierChangeOpen(true);
  };

  const handleOpenWeightCheckModal = () => {
    setSelectedShipmentForMyShipments(shipment);
    setWeightCheckOpen(true);
  };

  const handleStatusChangeOpenState = () => {
    setSelectedShipmentForMyShipments(shipment);
    setStatusChangeModalOpenState(true);
  };

  const handleEditReceiverAddressModal = () => {
    setSelectedShipmentForMyShipments(shipment);
    setEditReceiverAddressOpen(true);
  };

  const handleEditSenderAddressModal = () => {
    setSelectedShipmentForMyShipments(shipment);
    setEditSenderAddressOpen(true);
  };

  const handleDomesticChanges = () => {
    setSelectedShipmentForMyShipments(shipment);
    setEditDomesticInfo(true);
  };

  const handleDomesticPackageChanges = () => {
    setSelectedShipmentForMyShipments(shipment);
    setEditDomesticPackageInfo(true);
  };

  const handleAddNoteModal = () => {
    setSelectedShipmentForMyShipments(shipment);
    setAddNoteOpen(true);
  };
  const handleFinancialDetailsOpen = () => {
    setSelectedShipmentForMyShipments(shipment);
    setShowFinancialDetailsOpen(true);
  };

  const handleConnectedPaymentsModal = () => {
    setSelectedShipmentForMyShipments(shipment);
    setConnectedPaymentsOpen(true);
  };

  const handleProformaUpdate = () => {
    setSelectedShipmentForMyShipments(shipment);
    if (shipment?.tracking_awb === null) {
      setProformaUpdateOpen(true);
    } else {
      setProformaUpdateOpen(true);
    }

    if (Number(shipment.status) < 3) {
    }
  };

  const handleShowHistoryOfChange = useCallback(() => {
    setHistoryOfChangeModal({ status: true, data: shipment });
  }, [setHistoryOfChangeModal, shipment]);

  const handleAddExtraPrice = () => {
    setSelectedShipmentForMyShipments(shipment);
    setAddExtraPriceOpen(true);
  };
  // const getAddress = useCallback(async () => {
  //   await axios
  //     .get(
  //       `member-address?address_type=4&member_id=${shipment.member_id}&order_direction=DESC`
  //     )
  //     .then((response) => {
  //       setAddress(response.data.data);
  //     })
  //     .catch((error) => {
  //     });
  // }, []);

  const handlePastAddress = async () => {
    setPastAddressLoading(true);
    await axios
      .get(`parcel/past-addresses/${shipment?.id}`)
      .then((response) => {
        if (response.data.data.length === 0) {
          toast.info("Geçmiş adresiniz bulunmamaktadır.");
        } else {
          setPastAddressModal({ status: true, data: response.data.data });
        }
      })
      .catch((error) => {
        Errors(error);
      })
      .finally(() => {
        setPastAddressLoading(false);
      });
  };

  const handleCaseTransactions = useCallback(async () => {
    setCaseTransactionsLoading(true);
    await axios
      .get(`parcel/show-status-history/${shipment.id}`)
      .then((response) => {
        if (response?.data?.data?.length === 0) {
          toast.info("Durum işleminiz bulunmamaktadır");
        } else {
          setCaseTransactions({ status: true, data: response.data.data });
        }
      })
      .catch((error) => {
        Errors(error);
      })
      .finally(() => {
        setCaseTransactionsLoading(false);
      });
  }, [shipment, setCaseTransactions]);

  const handleCreateAPI = useCallback(async () => {
    setCreateAPILoading(true);
    await axios
      .get(`parcel/create-label-api/${shipment.id}`)
      .then((response) => {
        if (response?.data?.data?.length === 0) {
          toast.info("API Oluşturulamadı");
        } else {
          toast.success(response.data.message);
          setCreateAPI({ status: true, data: response.data.data });
        }
      })
      .catch((error) => {
        Errors(error);
      })
      .finally(() => {
        setCreateAPILoading(false);
      });
  }, [shipment, setCreateAPI]);

  // const handleDelete = (shipment) => {
  //   if (Number(shipment.status) < 2) {
  //     axios
  //       .post(`parcel/delete/${shipment.id}`)
  //       .then((response) => {
  //         if (response.data.status === 200) {
  //           toast.success(response.data.message, { autoClose: 500 });
  //           setTimeout(() => {
  //             window.location.reload(true);
  //           }, 1000);
  //         }
  //         if (response.data.status === 400) {
  //           toast.error(response.data.message, { autoClose: 500 });
  //         }
  //       })
  //       .catch((error) => {
  //         if (error.response.status === 401) {
  //           localStorage.removeItem("user");
  //           document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  //           navigate("/login");
  //         }
  //       });
  //   }
  // };

  const handleNothing = () => { };

  const handleOpenDialog = useCallback((e) => {
    if (e === "Tracking") {
      setOpenDialog(true);
      setDialogStatus(e);
    }
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
    setDialogStatus("");
  }, []);

  const handleSetDialog = useCallback(() => {
    if (dialogStatus === "Tracking") {
      handleCreateAPI();
    }
    handleCloseDialog();
  }, [dialogStatus, handleCreateAPI, handleCloseDialog]);

  const handleCancelShipment = (e) => {
    // setCancelLoading(true);
    axios
      .post(`parcel/cancel-parcel/${shipment.id}`)
      .then((response) => {
        if (response.data.status === 200) {
          toast.success(response.data.message, { autoClose: 2000 });
        } else {
          toast.error(response.data.message, { autoClose: 2000 });
        }
      })
      .catch((error) => {
        Errors(error);
      })
      .finally(() => {
        // setCancelLoading(false);
      });
  };

  const handleDestroy = () => {
    axios
      .post(`parcel/delete/${shipment.id}`, null, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.success(response.data.message, { autoClose: 2000 });
          setMyShipmentList(myShipmentList.filter((item) => item.id !== shipment.id));
        } else if (response.data.status === 400) {
          toast.error(response.data.message, { autoClose: 2000 });
        }
      })
      .catch((error) => {
        Errors(error);
      })
      .finally(() => {
        setDestroyDialog(false);
      });
  };

  const handleNavigateToSupportCenter = () => {
    setAdminParcelMemberId(shipment?.member_id);
    navigate(`/support?awb=${shipment?.parcel_ref_no}`);
  };

  const handleActiveShipment = useCallback(async () => {
    setActiveLoading(true);
    await axios
      .post(`parcel/reactivate-parcel/${shipment?.id}`)
      .then((response) => {
        setMyShipmentList((prevShipmentList) =>
          prevShipmentList.map((prevShipment) =>
            prevShipment.id === shipment?.id ? response.data.data : prevShipment
          )
        );
      })
      .catch((error) => Errors(error))
      .finally(() => setActiveLoading(false));
  }, [shipment, setMyShipmentList]);

  const handleDomesticAwbView = () => {
    if (shipment?.domestic_awb) {
      setDomesticInfoModal({ status: true, data: shipment });
    } else toast.info("Domestik bilgi eklenmedi.");
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center" }} id="alert-dialog-title">
          <img height={"50px"} src="/images/home/logo.svg" alt="IyziShip Logo" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            İzleme numarası almak istiyor musunuz?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="iyzi-button" onClick={handleCloseDialog}>
            Hayır
          </Button>
          <Button className="iyzi-button" onClick={handleSetDialog}>
            Evet
          </Button>
        </DialogActions>
      </Dialog>

      {Number(shipment?.deleted) === 1 && (
        <div className="list-links">
          {activeLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
            </div>
          ) : (
            <div style={{
              backgroundColor: "var(--btnBgBlue)",
              color: "white",
              padding: "10px 20px",
              borderRadius: "5px"
            }} onClick={handleActiveShipment}>Aktif Et</div>
          )}
        </div>
      )}
      {Number(shipment?.deleted) === 0 && (
        <>
          <Box className="list-links">
            <Button
              sx={{
                textTransform: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: 0,
                fontSize: "12px",
                color: "var(--priceText)",
                ":hover": {
                  backgroundColor: "transparent",
                  color: "var(--info)!important",
                  transition: "all 150ms linear",
                },
              }}
              onClick={handleEditReceiverAddressModal}
            >
              <EditOutlinedIcon sx={{ height: "13px" }} /> Alıcı Adresi Düzenle
            </Button>

            <div onClick={handleSupplierConsignmentDownload}>
              <SimCardDownloadOutlinedIcon sx={{ height: "13px" }} /> Tedarikçi Konşimentosu İndir
            </div>

            <Button
              sx={{
                textTransform: "none",
                display: "flex",
                justifyContent: "flex-start",
                padding: 0,
                textAlign: "left",
                fontSize: "12px",
                color: "var(--priceText)",
                ":hover": {
                  backgroundColor: "transparent",
                  color: "var(--info)!important",
                  transition: "all 150ms linear",
                },
              }}
              onClick={handleEditSenderAddressModal}
            >
              <EditOutlinedIcon sx={{ height: "13px" }} /> Gönderici Adresi Düzenle
            </Button>
            {consignmentLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "15px",
                }}
              >
                <CircularProgress sx={{ height: "15px !important", width: "15px !important" }} />
              </div>
            ) : (
              <div onClick={() => handleConsignmentDownload(shipment)}>
                <SimCardDownloadOutlinedIcon sx={{ height: "13px" }} /> Konşimento Görüntüle
              </div>
            )}
            <Button
              sx={{
                textTransform: "none",
                display: "flex",
                justifyContent: "flex-start",
                padding: 0,
                textAlign: "left",
                fontSize: "12px",
                color: "var(--priceText)",
                ":hover": {
                  backgroundColor: "transparent",
                  color: "var(--info)!important",
                  transition: "all 150ms linear",
                },
              }}
              onClick={handleDestroyDialogOpen}
            >
              <DeleteOutlineOutlinedIcon sx={{ height: "13px" }} /> Sil
            </Button>
            <Dialog
              open={destroyDialog}
              onClose={handleDestroyDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Gönderi Silme İsteği"}</DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{ fontWeight: 500, fontSize: "16px" }}
                  id="alert-dialog-description"
                >
                  Gönderiyi silmek etmek istediğinizden emin misiniz?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button className="iyzi-button" onClick={handleDestroyDialogClose}>
                  Hayır
                </Button>
                <Button className="iyzi-button" onClick={handleDestroy}>
                  Evet
                </Button>
              </DialogActions>
            </Dialog>
            <div onClick={handleFinancialDetailsOpen}>
              <TrendingUpOutlinedIcon sx={{ height: "13px" }} /> Finansal Değerler
            </div>
            <div onClick={handleAddNoteModal}>
              <NoteAddOutlinedIcon sx={{ height: "13px" }} />{" "}
              {(shipment.get_parcel_notes.length !== 0 &&
                shipment.get_parcel_notes.filter((e) => e.type === "1")) === false
                ? "Not Ekle"
                : "Notu Düzenle"}
            </div>
            <div onClick={handleConnectedPaymentsModal}>
              <PaymentsOutlinedIcon sx={{ height: "13px" }} /> Bağlı Ödemeler
            </div>
            <div onClick={handleShipmentDimensionDetailState}>
              <SquareFootOutlinedIcon sx={{ height: "13px" }} /> Ölçüler
            </div>
            <Button
              className={!superAdminRoleCheck ? "passive-link" : ""}
              disabled={!superAdminRoleCheck}
              sx={{
                textTransform: "none",
                display: "flex",
                justifyContent: "flex-start",
                padding: 0,
                textAlign: "left",
                fontSize: "12px",
                color: "var(--priceText)",
                ":hover": {
                  backgroundColor: "transparent",
                  color: "var(--info)!important",
                  transition: "all 150ms linear",
                },
                ":disabled": {
                  opacity: 0.3,
                },
              }}
              onClick={handleShipmentContractDetailsState}
            >
              <SquareFootOutlinedIcon sx={{ height: "13px" }} />  Kontrat Detayları
            </Button>
            <div >

            </div>
            <div onClick={handleDomesticAwbView}>
              <SquareFootOutlinedIcon sx={{ height: "13px" }} /> Domestik Awb Görüntüle
            </div>
            <div onClick={handleDomesticChanges}>
              <SquareFootOutlinedIcon sx={{ height: "13px" }} /> Domestik Bilgi Ekleme
            </div>
            <div onClick={handleDomesticPackageChanges}>
              <SquareFootOutlinedIcon sx={{ height: "13px" }} /> Paket Tipi Değişiklği
            </div>
            <div onClick={handleFilesModalOpenState}>
              <FolderOpenOutlinedIcon sx={{ height: "13px" }} /> Dosyalar
            </div>
            <div onClick={handleNavigateToSupportCenter}>
              <SupervisedUserCircleOutlinedIcon sx={{ height: "13px" }} /> Destek Talebi Oluştur
            </div>
            <div onClick={handleStatusChangeOpenState}>
              <ChangeCircleIcon sx={{ height: "13px" }} /> Statü Değiştir
            </div>

            <Button
              className={Number(shipment.status) > 1 ? "passive-link" : ""}
              disabled={Number(shipment.status) > 1}
              sx={{
                textTransform: "none",
                display: "flex",
                justifyContent: "flex-start",
                padding: 0,
                textAlign: "left",
                fontSize: "12px",
                color: "var(--priceText)",
                ":hover": {
                  backgroundColor: "transparent",
                  color: "var(--info)!important",
                  transition: "all 150ms linear",
                },
                ":disabled": {
                  opacity: 0.3,
                },
              }}
              onClick={handleCancelShipment}
            >
              <HighlightOffOutlinedIcon sx={{ height: "13px" }} /> İptal Et
            </Button>
          </Box>
          <Divider
            sx={{
              opacity: 0.1,
              width: "100%",
              height: "1px",
              backgroundColor: "var(--priceText)",
            }}
          />
          <div className="list-links">
            <div onClick={handleOpenWeightCheckModal}>
              <ScaleOutlinedIcon sx={{ height: "13px" }} /> Kilo Kontrolü
            </div>
            {createAPILoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "15px",
                }}
              >
                <CircularProgress sx={{ height: "15px !important", width: "15px !important" }} />
              </div>
            ) : (
              <div onClick={() => handleOpenDialog("Tracking")}>
                <SendOutlinedIcon sx={{ height: "13px" }} /> İzleme Numarası Al
              </div>
            )}
            {pastAddressLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "15px",
                }}
              >
                <CircularProgress sx={{ height: "15px !important", width: "15px !important" }} />
              </div>
            ) : (
              <div onClick={handlePastAddress}>
                <ImportContactsOutlinedIcon sx={{ height: "13px" }} /> Geçmiş Adresleri Gör
              </div>
            )}
            <div onClick={handleSupplierChangeOpenState}>
              <EditLocationAltOutlinedIcon sx={{ height: "13px" }} /> Tedarikçi Değiştir
            </div>
            {caseTransactionsLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "15px",
                }}
              >
                <CircularProgress sx={{ height: "15px !important", width: "15px !important" }} />
              </div>
            ) : (
              <div onClick={handleCaseTransactions}>
                <BuildOutlinedIcon sx={{ height: "13px" }} /> Durum İşlemlerini Göster
              </div>
            )}
            <div onClick={handleUpdateHAWBModalOpenState}>
              <SecurityUpdateGoodOutlinedIcon sx={{ height: "13px" }} /> İzleme Numarası Manuel
              Güncelle
            </div>
            {shipment?.doc_type !== "D" && (
              <div onClick={handleProformaUpdate}>
                <ReceiptLongOutlinedIcon sx={{ height: "13px" }} /> Proforma Güncelle
              </div>
            )}
            <div onClick={handleShowHistoryOfChange}>
              <PreviewIcon sx={{ height: "13px" }} /> Değişiklik Tarihçesi Göster
            </div>
            <div onClick={handleAddExtraPrice}>
              <AttachMoneyIcon sx={{ height: "13px" }} /> Ekstra Masraf Ekle
            </div>
          </div>
        </>
      )}
    </ThemeProvider>
  );
};

export default ListItemLinks;
