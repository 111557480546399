import { atom } from "recoil";

export const notificationListState = atom({
  key: "notificationList",
  default: [],
});

export const announcementListCountState = atom({
  key: "announcementListCount",
  default: 1,
});

export const announcementListCurrentPageState = atom({
  key: "announcementListCurrentPage",
  default: 1,
});

export const editAnnouncementState = atom({
  key: "editAnnouncement",
  default: false,
});

export const announcementAddOpenState = atom({
  key: "announcementAddOpen",
  default: false,
});

export const notificationObjState = atom({
  key: "notificationObj",
  default: {},
});
