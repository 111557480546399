const LocalPrice = (e) => {
  if (isNaN(e)) {
    return 0;
  } else
    return Number(e).toLocaleString("tr-TR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
};

export default LocalPrice;
