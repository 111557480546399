import "../../../../style/globals/Globals.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import MessageTime from "../../../../utils/MessageTime";
import { useCallback, useState } from "react";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import {
  dynamicRefundableAmountState,
  financeBakiyeIadeCurrentPageState,
  financeBakiyeIadeDataState,
  financeBakiyeIadeFilesModalState,
  financeBakiyeIadeTotalPageState,
  selectedBakiyeIadeFilesState,
} from "../../../../atoms/finance";
import LocalPrice from "../../../../utils/LocalPrice";
import { useRecoilState, useRecoilValue } from "recoil";
import BalanceRefundWarring from "../../../../utils/BalanceRefundWarring";
import Errors from "../../../../utils/Errors";

const FinanceBakiyeIadeTableBody = ({ item }) => {
  const [financeBakiyeIadeData, setFinanceBakiyeIadeData] = useRecoilState(
    financeBakiyeIadeDataState
  );
  const [, setFinanceBakiyeIadeFilesModal] = useRecoilState(financeBakiyeIadeFilesModalState);
  const [, setSelectedBakiyeIadeFiles] = useRecoilState(selectedBakiyeIadeFilesState);
  const [, setFinanceBakiyeIadeTotalPage] = useRecoilState(financeBakiyeIadeTotalPageState);
  const [, setFinanceBakiyeIadeCurrentPage] = useRecoilState(financeBakiyeIadeCurrentPageState);

  const dynamicRefundableAmount = useRecoilValue(dynamicRefundableAmountState);
  const refundableBalanceData = dynamicRefundableAmount.find(
    (e) => e.member_id === item.member_id
  )?.data;

  const refundableBalance =
    refundableBalanceData?.refundable_balance !== undefined
      ? parseFloat(refundableBalanceData.refundable_balance)
      : null;

  const [dialog, setDialog] = useState(false);
  const [textTitle, setTextTitle] = useState("");
  const [textContent, setTextContent] = useState("");
  const [textStatus, setTextStatus] = useState(null);
  const [status, setStatus] = useState(item.status);
  const [rejectReason, setRejectReason] = useState("");
  const [approvalAmount, setApprovalAmount] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");

  const closeModal = () => {
    setShowModal(false);
  };

  const handleApprove = (e) => {
    e.preventDefault();
    setDialog(false);

    axios
      .post(`refund/approve-decline/${item.id}`, {
        action_type: "accept",
      })
      .then((response) => {
        if (response.data.status !== 400) {
          toast.success(response.data.message);
        }

        if (response.data.status === 400) {
          setShowModal(true);
          setMessage(response.data.message);
        }

        // Dizinin her öğesini kontrol edin
        const updatedData = financeBakiyeIadeData?.map((e) => {
          // Eğer öğe, değiştirmek istediğiniz öğeye eşitse, yanıt verisi ile değiştirin
          if (e === item && response.data.status !== 400) {
            return response.data.data;
          } else {
            // Eğer değilse, öğeyi olduğu gibi bırakın
            return e;
          }
        });
        // Diziyi güncelleyin
        setFinanceBakiyeIadeData(updatedData);
        setFinanceBakiyeIadeTotalPage(response.data.totalPage);
        setFinanceBakiyeIadeCurrentPage(response.data.currentPage);
        //window.location.reload();
      })
      .catch((error) => Errors(error));
  };

  const handleReject = (e) => {
    e.preventDefault();
    setDialog(false);
    setFinanceBakiyeIadeData(financeBakiyeIadeData.filter((e) => e.id !== item.id));

    const requestBody = {
      action_type: "decline",
      response_description: rejectReason,
    };

    axios
      .post(`refund/approve-decline/${item.id}`, requestBody)
      .then((response) => {
        // Dizinin her öğesini kontrol edin
        const updatedData = financeBakiyeIadeData?.map((e) => {
          // Eğer öğe, değiştirmek istediğiniz öğeye eşitse, yanıt verisi ile değiştirin
          if (e === item) {
            return response.data.data;
          } else {
            // Eğer değilse, öğeyi olduğu gibi bırakın
            return e;
          }
        });

        // Diziyi güncelleyin
        setFinanceBakiyeIadeData(updatedData);

        setFinanceBakiyeIadeTotalPage(response.data.totalPage);
        setFinanceBakiyeIadeCurrentPage(response.data.currentPage);
        toast.success(response.data.message);
        updateStatus(1);
      })
      .catch((error) => {
        setFinanceBakiyeIadeData([item, ...financeBakiyeIadeData]);
        Errors(error);
      });
  };

  const handleDialogOpen = (e) => {
    setTextStatus(e);

    if (e === 1) {
      setTextTitle("Onaylama İsteği");
      setTextContent("İade talebini onaylamak istediğinize emin misiniz?");
      setDialog(true);
    } else {
      setTextTitle("Reddetme İsteği");
      setTextContent("İade talebini reddetmek istediğinize emin misiniz?");
      setDialog(true);
    }
  };

  const handleDialogClose = () => {
    setDialog(false);
    setApprovalAmount("");
  };

  const handleChangeRejectReason = (e) => {
    setRejectReason(e.target.value);
  };

  const handleApprovalAmount = (e) => {
    const value = e.target.value.replace(",", ".");

    if (value === "" || /^\d*([.]\d{0,2})?$/.test(value)) {
      setApprovalAmount(value);
    }
  };

  const handleBlurApprovalAmount = (e) => {
    const value = e.target.value.replace(",", ".");

    if (Number(value) > Number(item.request_refund_amount)) {
      setApprovalAmount(Number(item.request_refund_amount).toFixed(2));
    } else setApprovalAmount(value);
  };

  const handleFileModalOpen = useCallback(() => {
    setFinanceBakiyeIadeFilesModal(true);
    // setSelectedBakiyeIadeFiles(item?.get_files);
  }, [setFinanceBakiyeIadeFilesModal]);

  const updateStatus = (newStatus) => {
    setStatus(newStatus);
  };
  const balanceCheck = parseFloat(item.request_refund_amount) > parseFloat(refundableBalance);

  return (
    <>
      <Dialog
        open={dialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "var(--wrapperBorderRadius)" }}
      >
        <DialogTitle id="alert-dialog-title">{textTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{textContent}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "8px 20px 20px" }}>
          <form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "var(--gap)",
            }}
            onSubmit={textStatus === 1 ? handleApprove : handleReject}
          >
            {textStatus === 2 && (
              <TextField
                label="Reddetme Nedeni"
                value={rejectReason}
                onChange={handleChangeRejectReason}
                fullWidth
                required
                size="small"
                autoComplete="off"
              />
            )}
            <div
              style={{
                display: "flex",
                gap: "var(--gap2x)",
                justifyContent: "flex-end",
              }}
            >
              <Button type="button" className="iyzi-button" onClick={handleDialogClose}>
                Hayır
              </Button>
              <Button className="iyzi-button" type="submit">
                Evet
              </Button>
            </div>
          </form>
        </DialogActions>
      </Dialog>
      <BalanceRefundWarring isOpen={showModal} onClose={closeModal} message={message} />
      <TableRow
        sx={{
          ":hover": {
            backgroundColor: "var(--pastelBlue)",
            transition: "all 150ms linear",
          },
        }}
      >
        <TableCell>{item.member_id}</TableCell>
        <TableCell>{item?.get_member?.name}</TableCell>
        <TableCell>{MessageTime(item.created_at)}</TableCell>
        <TableCell>{LocalPrice(item.request_refund_amount)} ₺</TableCell>
        <TableCell>{LocalPrice(refundableBalance)} ₺</TableCell>
        <TableCell>{item.request_description}</TableCell>
        <TableCell>
          <div className={`status status${item.status}`}>
            {Number(item.status) === 0
              ? "Beklemede"
              : Number(item.status) === 1
              ? "Onaylandı"
              : Number(item.status) === 2
              ? "Reddedildi"
              : "İptal Edildi"}
          </div>
        </TableCell>
        <TableCell>
          {Number(item.status) === 0 && (
            <div
              style={{
                display: Number(item.status) === 0 ? "flex" : "none",
                gap: "var(--gap)",
              }}
            >
              <Button
                onClick={() => handleDialogOpen(1)}
                className="iyzi-button-green "
                disabled={balanceCheck}
              >
                Onayla
              </Button>
              <Button onClick={() => handleDialogOpen(2)} className="iyzi-button-red">
                Reddet
              </Button>
            </div>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default FinanceBakiyeIadeTableBody;
