import {
  TableCell,
  TableRow,
  Button,
  CircularProgress,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  DialogContentText,
  IconButton,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useCallback, useState } from "react";
import axios from "../../../../../api/axios";
import Errors from "../../../../../utils/Errors";
import GetRole from "../../../../../utils/GetRole";
import MemberEditUserModal from "./MemberEditUserModal";
import { useRecoilState } from "recoil";
import {
  memberDocumentEditOpenState,
  memberListState,
  memberUserIDState,
} from "../../../../../atoms/members";
import { toast } from "react-toastify";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import MemberHistoryUserModal from "./MemberHistoryUserModal";

const MemberUsersTableBody = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const role = GetRole();
  const isDisabled = role === "BOS" || role === "DEV" ? false : true;

  const [historyData, setHistoryData] = useState([]);

  const [, setMemberList] = useRecoilState(memberListState);
  const [, setMemberDocumentEditOpen] = useRecoilState(memberDocumentEditOpenState);
  const [, setMemberUserID] = useRecoilState(memberUserIDState);

  const [memberEditUserModal, setMemberEditUserModal] = useState(false);

  const handleDeleteUser = useCallback(async () => {
    setLoading(true);
    axios
      .post(`user/delete/${user?.id}`)
      .then((response) => {
        toast.success(response.data.message);
        setMemberList((prevMemberList) =>
          prevMemberList.map((member) =>
            // member.id !== user?.id kontrolü yapılır çünkü
            // user?.id, silinmek istenen kullanıcının ID'sidir ve
            // bu kullanıcının bulunduğu member objesini bulmamız gerekiyor.
            member.get_member_users.some((e) => e.id === user?.id)
              ? {
                  ...member,
                  // user?.id'li kullanıcıyı get_member_users listesinden filtreleyip çıkarıyoruz.
                  get_member_users: member.get_member_users.filter((e) => e.id !== user?.id),
                }
              : member
          )
        );
        setMemberDocumentEditOpen((prevData) => ({
          ...prevData,
          data: {
            ...prevData.data,
            get_member_users: prevData.data?.get_member_users.filter(
              (memberUser) => memberUser.id !== user?.id
            ),
          },
        }));
      })
      .catch((error) => Errors(error))
      .finally(() => {
        setLoading(false);
        setOpen(false);
      });
  }, [user, setMemberList, setMemberDocumentEditOpen]);

  const handleEditUser = useCallback(() => {
    setMemberEditUserModal(true);
    setMemberUserID(user?.id);
  }, [user, setMemberUserID]);

  const handleHistoryUser = useCallback(async () => {
    setHistoryLoading(true);
    await axios
      .get(`user/audit-history/${user?.id}`)
      .then((response) => setHistoryData(response.data.data))
      .catch((error) => Errors(error))
      .finally(() => setHistoryLoading(false));
  }, [user]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Kullanıcı Silme İsteği"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bu kullanıcıyı silmek istediğinize emin misiniz?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <div
              style={{
                width: "64px",
                height: "33px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
            </div>
          ) : (
            <Button
              disabled={isDisabled}
              sx={{ ":disabled": { opacity: 0.3 } }}
              className="iyzi-button-blue"
              onClick={handleDeleteUser}
              autoFocus
            >
              Evet
            </Button>
          )}
          <Button className="iyzi-button-blue" onClick={handleClose}>
            İptal
          </Button>
        </DialogActions>
      </Dialog>
      <MemberEditUserModal
        memberEditUserModal={memberEditUserModal}
        setMemberEditUserModal={setMemberEditUserModal}
        user={user}
      />
      <MemberHistoryUserModal data={historyData} setData={setHistoryData} />
      <TableRow>
        <TableCell>
          <IconButton onClick={handleEditUser} sx={{ color: "var(--info)" }}>
            <EditOutlinedIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          {user?.name} {user?.surname}
        </TableCell>
        <TableCell>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              fontSize: "inherit",
              color: "var(--inherit)",
              fontWeight: "inherit",
            }}
          >
            <span
              style={{
                fontSize: "inherit",
                fontWeight: "inherit",
                color: "var(--inherit)",
                display: "flex",
                alignItems: "center",
              }}
            >
              {user?.email_verified_at && (
                <CheckCircleOutlineOutlinedIcon sx={{ color: "var(--shipmentGreen)" }} />
              )}
            </span>
            <span
              style={{
                fontSize: "inherit",
                fontWeight: "inherit",
                color: "var(--inherit)",
                display: "flex",
                alignItems: "center",
              }}
            >
              {user?.email}
            </span>
          </div>
        </TableCell>
        <TableCell>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              fontSize: "inherit",
              color: "var(--inherit)",
              fontWeight: "inherit",
            }}
          >
            <span
              style={{
                fontSize: "inherit",
                fontWeight: "inherit",
                color: "var(--inherit)",
                display: "flex",
                alignItems: "center",
              }}
            >
              {Number(user?.phone_verified) === 1 && (
                <CheckCircleOutlineOutlinedIcon sx={{ color: "var(--shipmentGreen)" }} />
              )}
            </span>
            <span
              style={{
                fontSize: "inherit",
                fontWeight: "inherit",
                color: "var(--inherit)",
                display: "flex",
                alignItems: "center",
              }}
            >
              {user?.phone}
            </span>
          </div>
        </TableCell>
        <TableCell>{user?.lang}</TableCell>
        <TableCell>{user?.status}</TableCell>
        <TableCell>
          ({user?.user_role?.name}) {user?.user_role?.display_name}
          {!user?.user_role && user?.role_id}
        </TableCell>
        <TableCell>
          {historyLoading ? (
            <div
              style={{
                width: "37px",
                height: "37px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress sx={{ width: "21px !important", height: "21px !important" }} />
            </div>
          ) : (
            <IconButton onClick={handleHistoryUser} sx={{ color: "var(--info)" }}>
              <HistoryOutlinedIcon />
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          <Button
            disabled={isDisabled}
            sx={{
              minWidth: "0",
              borderRadius: "var(inputBorderRadius)",
              backgroundColor: "var(--notRed)",
              transition: "all 150ms linear",
              ":hover": {
                backgroundColor: "var(--shipmentRed)",
                transition: "all 150ms linear",
              },
              ":disabled": {
                opacity: 0.3,
              },
            }}
            onClick={() => setOpen(true)}
          >
            <CancelOutlinedIcon
              sx={{
                color: "var(--lighterBg)",
              }}
            />
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

export default MemberUsersTableBody;
