import React from "react";
import { TableRow, TableCell } from "@mui/material";
import FormatDateHour from "../../../utils/FormatDateHour";

const LogTableBody = ({ item }) => {
  return (
    <TableRow
      sx={{
        ":hover": {
          backgroundColor: "var(--pastelBlue)",
          transition: "all 150ms linear",
        },
      }}
    >
      <TableCell>{item.member_id}</TableCell>
      <TableCell>
        {item?.get_member?.name} {item.get_member?.surname}
      </TableCell>
      <TableCell>{FormatDateHour(item.get_member?.get_member_latest_parcel?.created_at)}</TableCell>
      <TableCell>{FormatDateHour(item.created_at)}</TableCell>
      <TableCell>{item.ip}</TableCell>
      <TableCell>{item.currency}</TableCell>
      <TableCell>{item.status_name}</TableCell>
    </TableRow>
  );
};

export default LogTableBody;
