import Avatar from "@mui/material/Avatar";
import { useRecoilValue } from "recoil";
import { drawerOpenState } from "../../../atoms/viewPadding";
import { nameState, surnameState, emailState } from "../../../atoms/membership";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

const ProfileDetails = () => {
  const drawerOpen = useRecoilValue(drawerOpenState);
  const name = useRecoilValue(nameState);
  const surname = useRecoilValue(surnameState);
  const email = useRecoilValue(emailState);

  const profileHeadlineHeader = drawerOpen
    ? {
        visibility: "visible",
        whiteSpace: "nowrap",
        transition: "all 150ms linear",
        opacity: "1",
      }
    : {
        visibility: "hidden",
        opacity: "0",
        whiteSpace: "nowrap",
        transition: "all 150ms linear",
        width: "0",
      };

  const profileHeadlineText = drawerOpen
    ? {
        opacity: "1",
        visibility: "visible",
        whiteSpace: "nowrap",
        transition: "all 150ms linear",
      }
    : {
        opacity: "0",
        visibility: "hidden",
        whiteSpace: "nowrap",
        transition: "all 150ms linear",
        width: "0",
      };

  return (
    <Link
      to={"/members"}
      className="profile-details-accordion"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "var(--background)",
        }}
        className="profile-details-accordion-summary"
      >
        <Tooltip
          title={drawerOpen ? "" : "Profil Bilgilerim"}
          placement="right"
        >
          <Avatar
            sx={
              drawerOpen
                ? {
                    bgcolor: "var(--blueBrand)",
                    width: "32px",
                    height: "32px",
                    marginLeft: "20px",
                    transition: "all 150ms linear",
                    marginRight: "18px",
                  }
                : {
                    bgcolor: "var(--blueBrand)",
                    width: "32px",
                    height: "32px",
                    marginLeft: "16px",
                    transition: "all 150ms linear",
                  }
            }
            aria-label="recipe"
          >
            <span style={{ fontSize: "14px" }}>
              {name?.charAt(0).toUpperCase()}
              {surname?.charAt(0).toUpperCase()}
            </span>
          </Avatar>
        </Tooltip>
        <div
          style={{ justifyContent: "inherit" }}
          className="profile-headline-container"
        >
          <div className="profile-headline-wrapper">
            <div
              className="profile-headline-header"
              style={profileHeadlineHeader}
            >
              {name?.length + surname?.length > 20
                ? `${name.charAt(0).toUpperCase()}.`
                : name.charAt(0) + name.slice(1)}{" "}
              {surname.charAt(0) + surname.slice(1)}
            </div>
            <div className="profile-headline-text" style={profileHeadlineText}>
              {email?.length > 24 ? `${email.slice(0, 24)}...` : email}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProfileDetails;
