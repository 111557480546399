import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const AuditHistory = ({ data }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableCell>Önceki Değer</TableCell>
          <TableCell>Yeni Değer</TableCell>
          <TableCell>Oluşturma Tarihi</TableCell>
          <TableCell>Alan Adı</TableCell>
          <TableCell>Oluşturan Kişi</TableCell>
        </TableHead>
        <TableBody>
          {data?.map((e, key) => (
            <TableRow key={key}>
              <TableCell sx={{maxWidth:'200px', whiteSpace:'pre-wrap'}}>{e?.before_value}</TableCell>
              <TableCell sx={{maxWidth:'200px', whiteSpace:'pre-wrap'}}>{e?.after_value}</TableCell>
              <TableCell sx={{maxWidth:'200px', whiteSpace:'pre-wrap'}}>{e?.created_at}</TableCell>
              <TableCell sx={{maxWidth:'200px', whiteSpace:'pre-wrap'}}>{e?.field_name}</TableCell>
              <TableCell sx={{maxWidth:'200px', whiteSpace:'pre-wrap'}}>
                {e?.get_created_by?.name} {e?.get_created_by?.surname}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AuditHistory;
