import { TableCell, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import CurrencySymbol from "../../../../utils/CurrencySymbol";

const FaturaKonsimentoTableBody = ({ item }) => {
  return (
    <TableRow
      sx={{
        transition: "all 150ms linear",
        ":hover": {
          transition: "all 150ms linear",
          backgroundColor: "var(--pastelBlue)",
        },
      }}
    >
      <TableCell>
        {item?.parcel_return_no === "0" ? (
            "---"
        ) : (
            <Link
                style={{ fontSize: "inherit", color: "var(--inherit)" }}
                to={`/my-shipment?awb=${item?.parcel_return_no}`}
            >
              {item?.parcel_return_no}
            </Link>
        )}
      </TableCell>
      <TableCell>
        {
          <Link
            style={{ fontSize: "inherit", color: "var(--inherit)" }}
            to={`/my-shipment?awb=${item?.parcel_ref_no}`}
          >
            {item?.parcel_ref_no}
          </Link>
        }
      </TableCell>
      <TableCell>{item?.receiver_name}</TableCell>
      <TableCell>{item?.receiver_country}</TableCell>
      <TableCell>{Number(item?.total_paid_price).toFixed(2)} {CurrencySymbol(item?.currency)}</TableCell>
      <TableCell>{Number(item?.total_paid_price_try).toFixed(2)} {CurrencySymbol('TRY')}</TableCell>
    </TableRow>
  );
};

export default FaturaKonsimentoTableBody;
