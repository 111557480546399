import { TableCell, TableRow } from "@mui/material";
import LocalPrice from "../../../../utils/LocalPrice";
import Currency from "../../../../utils/CurrencySymbol";
import { FormatReturnFieldWithDotsAndComma } from "../../../../utils/FormatFieldReturn";

const NewMemberTableBody = ({ item }) => {
  const month = parseInt(item?.created_at?.split(".")[1], 10);
  console.log(month);
  return (
    <TableRow>
      <TableCell sx={{ padding: "5px" }}>{item?.name}</TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.id}</TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.member_type}</TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.created_at}</TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.company_group}</TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.profit_type}</TableCell>
      <TableCell sx={{ padding: "5px" }}>
        {FormatReturnFieldWithDotsAndComma(item?.profit_amount)}
      </TableCell>
      <TableCell sx={{ padding: "5px" }}>{item?.first_parcel_date}</TableCell>
      <TableCell
        sx={{ padding: "5px", ...(month === 1 && { backgroundColor: "var(--shipmentGreen)" }) }}
      >
        {LocalPrice(item?.January)} {Currency("TRY")}
      </TableCell>
      <TableCell
        sx={{ padding: "5px", ...(month === 2 && { backgroundColor: "var(--shipmentGreen)" }) }}
      >
        {LocalPrice(item?.February)} {Currency("TRY")}
      </TableCell>
      <TableCell
        sx={{ padding: "5px", ...(month === 3 && { backgroundColor: "var(--shipmentGreen)" }) }}
      >
        {LocalPrice(item?.March)} {Currency("TRY")}
      </TableCell>
      <TableCell
        sx={{ padding: "5px", ...(month === 4 && { backgroundColor: "var(--shipmentGreen)" }) }}
      >
        {LocalPrice(item?.April)} {Currency("TRY")}
      </TableCell>
      <TableCell
        sx={{ padding: "5px", ...(month === 5 && { backgroundColor: "var(--shipmentGreen)" }) }}
      >
        {LocalPrice(item?.May)} {Currency("TRY")}
      </TableCell>
      <TableCell
        sx={{ padding: "5px", ...(month === 6 && { backgroundColor: "var(--shipmentGreen)" }) }}
      >
        {LocalPrice(item?.June)} {Currency("TRY")}
      </TableCell>
      <TableCell
        sx={{ padding: "5px", ...(month === 7 && { backgroundColor: "var(--shipmentGreen)" }) }}
      >
        {LocalPrice(item?.July)} {Currency("TRY")}
      </TableCell>
      <TableCell
        sx={{ padding: "5px", ...(month === 8 && { backgroundColor: "var(--shipmentGreen)" }) }}
      >
        {LocalPrice(item?.August)} {Currency("TRY")}
      </TableCell>
      <TableCell
        sx={{ padding: "5px", ...(month === 9 && { backgroundColor: "var(--shipmentGreen)" }) }}
      >
        {LocalPrice(item?.September)} {Currency("TRY")}
      </TableCell>
      <TableCell
        sx={{ padding: "5px", ...(month === 10 && { backgroundColor: "var(--shipmentGreen)" }) }}
      >
        {LocalPrice(item?.October)} {Currency("TRY")}
      </TableCell>
      <TableCell
        sx={{ padding: "5px", ...(month === 11 && { backgroundColor: "var(--shipmentGreen)" }) }}
      >
        {LocalPrice(item?.November)} {Currency("TRY")}
      </TableCell>
      <TableCell
        sx={{ padding: "5px", ...(month === 12 && { backgroundColor: "var(--shipmentGreen)" }) }}
      >
        {LocalPrice(item?.December)} {Currency("TRY")}
      </TableCell>
    </TableRow>
  );
};

export default NewMemberTableBody;
