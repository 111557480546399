import { Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { drawerOpenState } from "../../../atoms/viewPadding";
import miniLogo from "../../../img/logos/logo_white_mini.svg";

const NavMiniLogo = () => {
  const drawerOpen = useRecoilValue(drawerOpenState);

  return (
    drawerOpen ?? (
      <Box className="navbar-logo-wrapper">
        <img
          className={drawerOpen ? "navbar-mini-logo-hidden" : "navbar-mini-logo"}
          src={miniLogo}
          alt="IyziShip Logo"
        />
      </Box>
    )
  );
};

export default NavMiniLogo;
