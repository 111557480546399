import { TableCell, TableRow } from "@mui/material";
import MessageTime from "../../../../utils/MessageTime";
import CurrencySymbol from "../../../../utils/CurrencySymbol";
import { Link } from "react-router-dom";

const BekleyenOdemelerTableBody = ({ item }) => {
  const oneDay = 24 * 60 * 60 * 1000; // bir günün milisaniye cinsinden değeri
  const firstDate = new Date(item.created_at); // karşılaştırılacak ilk tarih
  const secondDate = new Date(); // bugünün tarihi
  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

  return (
    <TableRow>
      <TableCell>
        {
          item?.get_parcel_price_information?.get_parcel?.get_parcel_member
            ?.uyumsoft_code
        }
      </TableCell>
      <TableCell>
        {item?.get_parcel_price_information?.get_parcel?.member_id}
      </TableCell>
      <TableCell>
        {
          item?.get_parcel_price_information?.get_parcel?.get_parcel_member
            ?.name
        }
      </TableCell>
      <TableCell>
        {item?.get_parcel_price_information?.get_parcel?.parcel_ref_no}
      </TableCell>
      <TableCell>
        {item?.get_parcel_price_information?.get_parcel?.tracking_awb}
      </TableCell>
      <TableCell>{item?.type}</TableCell>
      <TableCell>{MessageTime(item?.created_at)}</TableCell>
      <TableCell sx={{ color: diffDays === 0 ? "inherit" : "var(--error)" }}>
        {diffDays}
      </TableCell>
      <TableCell>
        {item?.selling_price}{" "}
        {CurrencySymbol(
          item?.get_parcel_price_information?.get_parcel?.currency
        )}
      </TableCell>
    </TableRow>
  );
};

export default BekleyenOdemelerTableBody;
