import React, { useState } from "react";
import { TableRow, TableCell, Button } from "@mui/material";
import { useCookies } from "react-cookie";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import Errors from "../../../../utils/Errors";
import { useEffect } from "react";
import FormatDateHour from "../../../../utils/FormatDateHour";
import { useRecoilState } from "recoil";
import {
  PriceDiffListState
} from "../../../../atoms/priceControl";

const paddedCellStyle = {
  fontSize: "12px",
  padding: "2px",
  width: "fit-content",
};

const PriceControlScreenTableBody = ({
  item,
  onCheckboxClick,
  selectedRows,
  group1Checked,
  group2Checked,
  group3Checked,
  group4Checked,
  group5Checked,
  group6Checked,
}) => {
  const [token] = useCookies(["jwt"]);
  const jwt = token?.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [inputsDisabled, setInputsDisabled] = useState(true);
  const [editMode, setEditMode] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceOld, setTotalPriceOld] = useState(0);
  const [priceDiffList, setPriceDiffList] = useRecoilState(PriceDiffListState);

  const initializeDimensions = (item) => {
    return {
      [item.id]: {
        id: item.get_parcel_dimensions.find((e) => e.status === "2")?.id || "",
        qty:
          item.get_parcel_dimensions.find((e) => e.status === "2")?.qty || "",
        width:
          item.get_parcel_dimensions.find((e) => e.status === "2")?.width || "",
        length:
          item.get_parcel_dimensions.find((e) => e.status === "2")?.length ||
          "",
        height:
          item.get_parcel_dimensions.find((e) => e.status === "2")?.height ||
          "",
        gross:
          item.get_parcel_dimensions.find((e) => e.status === "2")?.gross || "",
      },
    };
  };

  const [dimensions, setDimensions] = useState(() =>
    initializeDimensions(item)
  );

  const handleInputChange = (field, value) => {
    setDimensions((prevDimensions) => ({
      ...prevDimensions,
      [item.id]: {
        ...prevDimensions[item.id],
        [field]: value,
      },
    }));
    setInputsDisabled(false);
  };

  const handleSendClick = () => {
    if (editMode) {
      const apiUrl = "parcel/operation/update-beevision-dimension";

      const payload = {
        dimensions: [
          {
            id: dimensions[item.id].id,
            qty: dimensions[item.id].qty,
            width: dimensions[item.id].width,
            length: dimensions[item.id].length,
            height: dimensions[item.id].height,
            gross: dimensions[item.id].gross,
          },
        ],
      };

      axios
        .post(apiUrl, payload)
        .then((response) => {
          toast.success(response.data.message);
          setDimensions({
            [item.id]: {
              id: "",
              qty: "",
              width: "",
              length: "",
              height: "",
              gross: "",
            },
          });
          setEditMode(false);
          setTimeout(function () {
            window.location.reload();
          }, 1500);
        })
        .catch((error) => {
          Errors(error);
          toast.error(error.message);
          setDimensions({
            [item.id]: {
              id: "",
              qty: "",
              width: "",
              length: "",
              height: "",
              gross: "",
            },
          });
          setEditMode(false);
        });
    } else {
      setEditMode(true);
    }
  };

  const handleCloseEdit = () => {
    setEditMode(false);
  };

  const thePrice = Number(item.get_real_contract?.selling_price || 0);
  const insurance = item.get_real_contract?.insurance || "{}";
  const ddp = item.get_real_contract?.ddp || "{}";
  const extraInformation =
    item.get_real_contract?.extra_information || "{}";
  const lucidPrice =
    item.get_real_contract?.lucid_price_detail || "{}";

  useEffect(() => {
    let calculatedTotalPrice = thePrice;

    if (item.get_real_contract?.domestic_price > 0) {
      calculatedTotalPrice += Number(item.get_real_contract?.domestic_price);
    }
    if (item.insurance === "1") {
      calculatedTotalPrice += Number(insurance.insurance_price || 0);
    }
    if (lucidPrice.lucid === "1") {
      calculatedTotalPrice += Number(lucidPrice.lucid_price || 0);
    }
    if (item.ddp === "1") {
      calculatedTotalPrice += Number(ddp.ex_custom_price || 0);
    }

    setTotalPrice(Number(calculatedTotalPrice.toFixed(2)));
  }, [thePrice, item, insurance, ddp, extraInformation]);

  // OLD PRICE

  const thePriceOld = Number(item.get_contract?.selling_price || 0);
  const insuranceOld = item.get_contract?.insurance || "{}";
  const ddpOld = item.get_contract?.ddp || "{}";
  const extraInformationOld =
    item.get_contract?.extra_information || "{}";
  const lucidPriceOld =
    item.get_contract?.lucid_price_detail || "{}";

  useEffect(() => {
    let calculatedTotalPriceOld = thePriceOld;

    if (item.get_contract?.domestic_price > 0) {
      calculatedTotalPriceOld += Number(item.get_contract?.domestic_price);
    }
    if (item.insurance === "1") {
      calculatedTotalPriceOld += Number(insuranceOld.insurance_price || 0);
    }
    if (lucidPriceOld.lucid === "1") {
      calculatedTotalPriceOld += Number(lucidPriceOld.lucid_price || 0);
    }
    if (item.ddp === "1") {
      calculatedTotalPriceOld += Number(ddpOld.ex_custom_price || 0);
    }
    setTotalPriceOld(Number(calculatedTotalPriceOld.toFixed(2)));
  }, [thePriceOld, item, insuranceOld, ddpOld, extraInformationOld]);

  let domesticTl;

  if (
    item.get_contract?.exchange_rate !== undefined &&
    item.get_contract?.domestic_price !== undefined
  ) {
    domesticTl = Number(
      (
        item.get_contract?.domestic_price * item.get_contract?.exchange_rate
      ).toFixed(2)
    );
  }

  const calculatedValue =
    item.get_real_contract?.domestic_price *
    item.get_real_contract?.exchange_rate;
  const roundedValue = isNaN(parseFloat(calculatedValue))
    ? 0
    : parseFloat(calculatedValue).toFixed(2);

  const priceDiff = totalPrice - totalPriceOld;

  useEffect(() => {
    setPriceDiffList(priceDiff);
    setDimensions(initializeDimensions(item));
  }, [item]);

  return (
    <TableRow
      sx={{
        ":hover": {
          backgroundColor: "var(--pastelBlue)",
          transition: "all 150ms linear",
        },
      }}
    >
      <TableCell sx={paddedCellStyle}>
        <input
          onChange={() => onCheckboxClick(item.id)}
          checked={selectedRows.includes(item.id)}
          type="checkbox"
        />
      </TableCell>
      <TableCell
        sx={{ display: group1Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {FormatDateHour(item.created_at)}
      </TableCell>
      <TableCell
        sx={{ display: group1Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {item.get_parcel_member.id}
      </TableCell>
      <TableCell
        sx={{ display: group1Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {item.sender_name}
      </TableCell>
      <TableCell
        sx={{ display: group2Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {item.supplier_id}
      </TableCell>
      <TableCell
        sx={{ display: group2Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {item.get_parcel_supplier.supplier_name}
      </TableCell>
      <TableCell
        sx={{ display: group2Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {item.status}
      </TableCell>
      <TableCell
        sx={{ display: group2Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {item.status_name}
      </TableCell>
      <TableCell
        sx={{ display: group3Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {item.parcel_ref_no}
      </TableCell>
      <TableCell
        sx={{ display: group3Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {item.tracking_awb}
      </TableCell>
      <TableCell
        sx={{ display: group3Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {item.domestic_awb}
      </TableCell>

      {/* Müşteri beyanı */}
      {item.get_parcel_dimensions
        .filter((e) => e.status === "1")
        .slice(0, 1)
        .map((filteredItem, index) => (
          <React.Fragment key={index}>
            <TableCell
              sx={{ display: group4Checked ? "none" : "" }}
              style={paddedCellStyle}
            >
              {filteredItem.qty}
            </TableCell>
            <TableCell
              sx={{ display: group5Checked ? "none" : "" }}
              style={paddedCellStyle}
            >
              {filteredItem.width}
            </TableCell>
            <TableCell
              sx={{ display: group5Checked ? "none" : "" }}
              style={paddedCellStyle}
            >
              {filteredItem.length}
            </TableCell>
            <TableCell
              sx={{ display: group5Checked ? "none" : "" }}
              style={paddedCellStyle}
            >
              {filteredItem.height}
            </TableCell>
            <TableCell
              sx={{ display: group5Checked ? "none" : "" }}
              style={paddedCellStyle}
            >
              {filteredItem.gw}
            </TableCell>
            <TableCell
              sx={{ display: group5Checked ? "none" : "" }}
              style={paddedCellStyle}
            >
              {filteredItem.vw}
            </TableCell>
            <TableCell
              sx={{ display: group5Checked ? "none" : "" }}
              style={paddedCellStyle}
            >
              {filteredItem.cw}
            </TableCell>
          </React.Fragment>
        ))}

      {item.get_parcel_dimensions
        .filter((e) => e.status === "1")
        .slice(0, 1)
        .map((filteredItem, index) => (
          <React.Fragment key={index}>
            <TableCell
              sx={{ display: group4Checked ? "none" : "" }}
              style={paddedCellStyle}
            >
              {filteredItem.total_ds}
            </TableCell>
          </React.Fragment>
        ))}
      <TableCell
        sx={{ display: group4Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {isNaN(parseFloat(totalPriceOld)) ? "0" : totalPriceOld} {item.currency}
      </TableCell>
      <TableCell
        sx={{ display: group4Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {domesticTl} TL ({item.get_contract?.domestic_price} {""}{" "}
        {item.currency})
      </TableCell>
      <TableCell
        sx={{ display: group4Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {item.get_parcel_packaging_history?.after_value}
      </TableCell>

      {editMode && (
        <React.Fragment>
          {["qty", "width", "length", "height", "gross"].map((dimension) => (
            <TableCell
              key={dimension}
              sx={{ display: group4Checked ? "none" : "" }}
              style={paddedCellStyle}
            >
              <input
                style={{
                  fontSize: "13px",
                  width: "50px",
                }}
                type="text"
                value={dimensions[item.id]?.[dimension]}
                onChange={(e) => handleInputChange(dimension, e.target.value)}
                disabled={!editMode}
                label={dimension}
              />
            </TableCell>
          ))}
        </React.Fragment>
      )}
      {!editMode && (
        <React.Fragment>
          {["qty", "width", "length", "height", "gross"].map((dimension) => (
            <TableCell
              key={dimension}
              sx={{ display: group6Checked ? "none" : "" }}
              style={paddedCellStyle}
            >
              <input
                style={{
                  fontSize: "13px",
                  width: "50px",
                }}
                type="text"
                value={
                  item.get_parcel_dimensions?.find((e) => e.status === "2")?.[
                  dimension
                  ]
                }
                onChange={(e) => handleInputChange(dimension, e.target.value)}
                disabled={!editMode}
                label={dimension}
              />
            </TableCell>
          ))}
        </React.Fragment>
      )}

      <TableCell
        sx={{ display: group6Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {item.get_parcel_dimensions?.find((e) => e.status === "2")?.vw}
      </TableCell>
      <TableCell
        sx={{ display: group6Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {item.get_parcel_dimensions?.find((e) => e.status === "2")?.cw}
      </TableCell>
      <TableCell
        sx={{ display: group6Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        <Button
          sx={{
            textTransform: "none",
            fontSize: "13px",
            color: "var(--lighterBg)",
            backgroundColor: editMode ? "var(--success)" : "var(--info)",
            marginBottom: "1px",
            borderRadius: "var(--buttonBorderRadius)",
            ":hover": {
              backgroundColor: editMode ? "var(--success)" : "var(--info)",
              opacity: editMode ? "90%" : "100%",
            },
          }}
          fullWidth
          onClick={handleSendClick}
        >
          {editMode ? "Gönder" : "Düzenle"}
        </Button>
        {editMode && (
          <Button
            sx={{
              textTransform: "none",
              fontSize: "13px",
              color: "var(--lighterBg)",
              backgroundColor: "var(--redBrand)",
              borderRadius: "var(--buttonBorderRadius)",
              ":hover": {
                backgroundColor: "var(--redBrand)",
                opacity: editMode ? "90%" : "100%",
              },
            }}
            fullWidth
            onClick={handleCloseEdit}
          >
            İptal
          </Button>
        )}
      </TableCell>

      {item.get_parcel_dimensions
        .filter((e) => e.status === "2")
        .slice(0, 1)
        .map((filteredItem, index) => (
          <React.Fragment key={index}>
            <TableCell
              sx={{ display: group4Checked ? "none" : "" }}
              style={paddedCellStyle}
            >
              {filteredItem.total_ds}
            </TableCell>
          </React.Fragment>
        ))}
      <TableCell
        sx={{ display: group4Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {totalPrice} {item.get_real_contract?.product_value_currency}
      </TableCell>
      <TableCell
        sx={{ display: group4Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {roundedValue} TL ({item.get_real_contract?.domestic_price} {""}{" "}
        {item.currency})
      </TableCell>
      <TableCell
        sx={{
          display: group4Checked ? "none" : "",
          color:
            item.get_parcel_packaging_history?.after_value ===
              item.packaging_type
              ? "green"
              : "red",
        }}
        style={paddedCellStyle}
      >
        {item.packaging_type}
      </TableCell>
      <TableCell
        sx={{
          display: group4Checked ? "none" : "",
          color: priceDiff < item.selling_price.includes("-") ? "red" : "green",
        }}
        style={paddedCellStyle}
      >
        {isNaN(parseFloat(priceDiff)) ? "0" : parseFloat(priceDiff).toFixed(2)}{" "}
        {item.get_real_contract?.product_value_currency}
      </TableCell>
      <TableCell
        sx={{ display: group4Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {item.price_approved === "1" ? "Verildi" : "Verilmedi"}
      </TableCell>
      <TableCell
        sx={{ display: group4Checked ? "none" : "" }}
        style={paddedCellStyle}
      >
        {FormatDateHour(item.arrived_to_office_date)}
      </TableCell>
    </TableRow>
  );
};

export default PriceControlScreenTableBody;
