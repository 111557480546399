import "../../../style/operation/Operation.css";
import {
  Box,
  ThemeProvider,
  createTheme,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Grid,
  Modal,
  Typography,
  List,
  ListItem,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import axios from "../../../api/axios";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import NavBar from "../sidebar/NavBar";
import { ToastContainer, toast } from "react-toastify";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import WrapperStyle from "../../../utils/WrapperStyle";
import WarehouseOutlinedIcon from "../../../img/images/la_warehouse.svg";
import { useRecoilState } from "recoil";
import { drawerOpenState } from "../../../atoms/viewPadding";
import NotificationBar from "../sidebar/NotificationBar";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const DomesticInfoChangesScreen = () => {
  DynamicTitle("Depo Yük Girişi - Admin");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [shipments, setShipments] = useState([createEmptyShipment()]);
  const [activeInput, setActiveInput] = useState(null);
  const manuelScanRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useRecoilState(drawerOpenState);
  const [manuelScan, setManuelScan] = useState("");
  const [checkSettings, setCheckSettings] = useState(false);
  const [duplicates, setDuplicates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (manuelScanRef.current) {
      manuelScanRef.current.focus();
    }
    setDrawerOpen(false);
  }, [setDrawerOpen]);

  function createEmptyShipment() {
    return {
      domestic_awb: "",
      domestic_supplier: "",
      length: "",
      width: "",
      height: "",
      gw: "",
      id: "",
    };
  }

  const getShipment = async () => {
    try {
      const response = await axios.post(`parcel/operation/custom-domestic-search`, {
        domestic_awb: manuelScan,
      });
      toast.success(response.data.message);
      const shipment = response.data.data;
      setShipments((prevShipments) => [{ ...shipment }, ...prevShipments.slice(1)]);
    } catch (error) {
      toast.error("Domestik Awb Bulunamadı");
    } finally {
      setLoading(false);
    }
  };

  const handleManuelScanChange = (event) => {
    setManuelScan(event.target.value);
  };

  const handleSearchClick = () => {
    getShipment();
  };

  const handleUpdateDomesticInfo = () => {
    const validShipments = shipments.filter((shipment) => {
      return (
        shipment.domestic_supplier &&
        shipment.domestic_awb &&
        shipment.length &&
        shipment.width &&
        shipment.height &&
        shipment.gw
      );
    });

    const requestBody = validShipments.map((shipment) => ({
      domestic_supplier: shipment.domestic_supplier,
      domestic_awb: shipment.domestic_awb,
      length: shipment.length,
      width: shipment.width,
      height: shipment.height,
      gross: shipment.gw,
      id: shipment.id,
    }));

    axios
      .post(`parcel/operation/add-custom-domestic-parcel`, { items: requestBody })
      .then((response) => {
        if (response.data.duplicates >= 1) {
          setDuplicates(response.data.duplicates); // Tekrarlayan AWB'leri kaydet
          setIsModalOpen(true); // Modal'ı aç
        } else {
          toast.success(response.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      })
      .catch((error) => {
        toast.error("Girilen bilgileri kontrol edin");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleShipmentChange = (index, field, value) => {
    const updatedShipments = [...shipments];

    // Sadece belirli alanlarda kontrol yap
    const monitoredFields = ["domestic_awb", "domestic_supplier", "length", "width", "height", "gw"];
    if (monitoredFields.includes(field) && value.includes(",000026Save")) {
      // "KAYDET" kelimesini kaldır
      const cleanedValue = value.replace(",000026Save", "").trim(); // "KAYDET" silinir, kalan değer korunur
      updatedShipments[index][field] = cleanedValue;

      setShipments(updatedShipments); // Güncellenmiş shipment'ları kaydet
      handleUpdateDomesticInfo(); // Kaydet fonksiyonunu çağır
      return;
    }

    if (monitoredFields.includes(field) && value.includes(",000026New")) {
      // "YENİ" kelimesini kaldır
      const cleanedValue = value.replace(",000026New", "").trim(); // "YENİ" silinir, kalan değer korunur
      updatedShipments[index][field] = cleanedValue;

      setShipments(updatedShipments); // Güncellenmiş shipment'ları YENİ
      window.location.reload(); // Sayfa yenilemesi çalışır
      return;
    }

    if (monitoredFields.includes(field) && value.includes(",000026Lıne")) {
      // "YENİ" kelimesini kaldır
      const cleanedValue = value.replace(",000026Lıne", "").trim(); // "YENİ" silinir, kalan değer korunur
      updatedShipments[index][field] = cleanedValue;

      setShipments(updatedShipments); // Güncellenmiş shipment'ları YENİ
      handleAddShipment(); // Sayfa yenilemesi çalışır
      return;
    }

    // Değeri güncelle
    updatedShipments[index][field] = value;

    // AWB kontrolü ve taşıyıcı ataması
    if (field === "domestic_awb") {
      const awbLength = value.length;
      if (awbLength === 34) {
        updatedShipments[index].domestic_supplier = "Aras";
      } else if (awbLength === 19) {
        updatedShipments[index].domestic_supplier = "Mng";
      } else if (awbLength === 11) {
        updatedShipments[index].domestic_supplier = "Sürat";
      } else {
        updatedShipments[index].domestic_supplier = "Diğer";
      }
    }

    // Güncellenmiş shipment'ları state'e aktar
    setShipments(updatedShipments);
  };

  const handleAddShipment = () => {
    setShipments((prevShipments) => [...prevShipments, createEmptyShipment()]);
    setTimeout(() => {
      if (manuelScanRef.current) {
        manuelScanRef.current.focus();
      }
    }, 100);
  };

  const handleRemoveShipment = (index) => {
    setShipments((prevShipments) =>
      prevShipments.filter((_, i) => i !== index)
    );
  };

  const Numpad = ({ onKeyPress }) => {
    const keys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "0", "C"];

    return (
      <Grid container spacing={1} sx={{ maxWidth: "350px", margin: "0 auto" }}>
        {keys.map((key) => (
          <Grid item xs={4} key={key}>
            <Button fullWidth variant="contained" onClick={() => onKeyPress(key)}>
              {key}
            </Button>
          </Grid>
        ))}
      </Grid>
    );
  };

  const handleNumpadKeyPress = (key) => {
    if (!activeInput) {
      console.error("No active input is selected.");
      return;
    }

    const fieldName = activeInput.getAttribute("name");
    const inputIndex = parseInt(activeInput.getAttribute("data-index"));

    if (isNaN(inputIndex) || !fieldName) {
      console.error("Invalid fieldName or inputIndex:", { fieldName, inputIndex });
      return;
    }

    // Eğer "C" tuşuna basıldıysa alanı temizle
    if (key === "C") {
      handleShipmentChange(inputIndex, fieldName, "");
      return;
    }

    const currentValue = activeInput.value || "";
    handleShipmentChange(inputIndex, fieldName, currentValue + key);
  };

  const handleRemoveShipmentRequest = async (id) => {
    try {
      const response = await axios.post(`parcel/operation/delete-custom-domestic-parcel/${id}`);
      toast.success(response.data.message);

      // API çağrısı başarılı olduğunda, shipment listesini güncelle
      setShipments((prevShipments) =>
        prevShipments.filter((shipment) => shipment.id !== id)
      );
    } catch (error) {
      Errors(error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (shipments.length === 0) {
      setShipments([createEmptyShipment()]); // En az bir boş shipment ekle
    }
  }, [shipments]);

  useEffect(() => {
    if (manuelScan.length === 34 || manuelScan.length === 19 || manuelScan.length === 11) {
      getShipment();
    }
  }, [manuelScan]);


  return (
    <ThemeProvider theme={theme}>
      <NotificationBar />
      <section
        className="operation"
        style={{
          transition: "all 150ms linear",
        }}
      >
        <NavBar />
        <ToastContainer />
        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: "10px",
              p: 4,
            }}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              Tekrarlayan AWB Numaraları
            </Typography>
            <Typography id="modal-description" sx={{ mt: 2 }}>
              Aşağıdaki AWB numaraları zaten mevcut:
            </Typography>
            <List>
              {duplicates.map((awb, index) => (
                <ListItem key={index}>
                  <Typography>{awb}</Typography>
                </ListItem>
              ))}
            </List>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseModal}
              sx={{ mt: 2 }}
              fullWidth
            >
              Kapat
            </Button>
          </Box>
        </Modal>
        {loading ? (
          <Box
            sx={{
              height: "500px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="loading-logo" src={miniLogo} alt="" />
          </Box>
        ) : (
          <div style={{ display: "flex", gap: "20px", flexDirection: "column", paddingBottom: "30px" }}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  width: "60%",
                }}
                className="operation-wrapper"
                style={WrapperStyle()}
              >
                <Box
                  sx={{
                    display: "flex",
                    position: "fixed",
                    gap: "300px",
                    width: "58%",
                    zIndex: "1000",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    marginTop: "10px",
                    boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.1)",
                    padding: "10px",
                  }}

                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      style={{ width: "150px", margin: " 10px auto 0" }}
                      src="/images/new-line.jpeg"
                      alt="IyziShip Icon"
                    />
                    <span>YENİ SÜTUN</span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      style={{ width: "150px", margin: " 10px auto 0" }}
                      src="/images/save.jpeg"
                      alt="IyziShip Icon"
                    />
                    <span>KAYDET</span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      style={{ width: "150px", margin: " 10px auto 0" }}
                      src="/images/new.jpeg"
                      alt="IyziShip Icon"
                    />
                    <span>YENİ</span>
                  </Box>
                </Box>
                <Box sx={{
                  marginTop: "26vh"
                }} className="operation-container">
                  <div
                    className="operation-freight-front-desk"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "100%",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <TextField
                        value={manuelScan}
                        size="small"
                        sx={{ width: "100%" }}
                        onChange={handleManuelScanChange}
                        placeholder="Domestik Awb Arama"
                      />
                    </Box>
                    <Button
                      className="iyzi-button-blue"
                      onClick={handleSearchClick}
                      style={{ marginLeft: "10px", height: "40px" }}
                    >
                      Ara
                    </Button>
                  </div>

                  {shipments.map((shipment, index) => (
                    <Box
                      key={index}
                      className="operation-freight-front-desk"
                      sx={{
                        display: "flex !important",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "20px",
                        marginTop: "20px",
                        backgroundColor: duplicates?.includes(shipment.domestic_awb) ? "#ffd3d3 !important" : "",
                      }}
                    >
                      <TextField
                        label="Domestik AWB"
                        name="domestic_awb"
                        data-index={index}
                        value={shipment.domestic_awb}
                        onFocus={(e) => setActiveInput(e.target)}
                        onChange={(e) =>
                          handleShipmentChange(index, "domestic_awb", e.target.value)
                        }
                        size="small"
                        inputRef={index === shipments.length - 1 ? manuelScanRef : null}
                      />
                      <TextField
                        label="Domestik Taşıyıcı"
                        name="domestic_supplier"
                        data-index={index}
                        value={shipment.domestic_supplier}
                        onFocus={(e) => setActiveInput(e.target)}
                        onChange={(e) =>
                          handleShipmentChange(index, "domestic_supplier", e.target.value)
                        }
                        size="small"
                      />
                      <TextField
                        label="Uzunluk"
                        name="length"
                        data-index={index}
                        value={shipment.length}
                        onFocus={(e) => setActiveInput(e.target)}
                        onChange={(e) =>
                          handleShipmentChange(index, "length", e.target.value)
                        }
                        size="small"
                        sx={{
                          width: "100px",
                        }}
                      />
                      <TextField
                        label="Genişlik"
                        name="width"
                        data-index={index}
                        value={shipment.width}
                        onFocus={(e) => setActiveInput(e.target)}
                        onChange={(e) =>
                          handleShipmentChange(index, "width", e.target.value)
                        }
                        size="small"
                        sx={{
                          width: "100px",
                        }}
                      />
                      <TextField
                        label="Yükseklik"
                        name="height"
                        data-index={index}
                        value={shipment.height}
                        onFocus={(e) => setActiveInput(e.target)}
                        onChange={(e) =>
                          handleShipmentChange(index, "height", e.target.value)
                        }
                        size="small"
                        sx={{
                          width: "100px",
                        }}
                      />
                      <TextField
                        label="Gross"
                        name="gross"
                        data-index={index}
                        value={shipment.gw}
                        onFocus={(e) => setActiveInput(e.target)}
                        onChange={(e) =>
                          handleShipmentChange(index, "gw", e.target.value)
                        }
                        size="small"
                        sx={{
                          width: "100px",
                        }}
                      />
                      <Button
                        className="iyzi-button-red"
                        sx={{ ":disabled": { opacity: "0.4" } }}
                        onClick={shipment.id ? () => handleRemoveShipmentRequest(shipment.id) : () => handleRemoveShipment(index)}
                      >
                        Sil
                      </Button>
                    </Box>
                  ))}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "20px",
                      width: "100%",
                    }}
                  >
                    {shipments.length === 0 && (
                      <Button
                        sx={{
                          width: "50%",
                        }}
                        className="iyzi-button-blue"
                        onClick={handleAddShipment}
                      >
                        Yeni
                      </Button>
                    )}

                    <Button
                      sx={{
                        width: "50%",
                      }}
                      className="iyzi-button-blue"
                      onClick={handleUpdateDomesticInfo}
                    >
                      Kaydet
                    </Button>
                  </Box>
                </Box>
              </Box>
              {/* <Box
                sx={{
                  display: "flex",
                  width: "40%",
                }}
              >
                <Box>
                  <Numpad onKeyPress={handleNumpadKeyPress} />
                </Box>
              </Box> */}
            </Box>
          </div>
        )}
        <Dialog open={checkSettings} onClose={() => setCheckSettings(false)}>
          <DialogContent>
            <DialogTitle>
              <DialogContentText id="alert-dialog-description">
                Barkod okunuyor lütfen bekleyiniz...
              </DialogContentText>
            </DialogTitle>
          </DialogContent>
        </Dialog>
      </section>
    </ThemeProvider>
  );
};

export default DomesticInfoChangesScreen;
