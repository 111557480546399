import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useCookies } from "react-cookie";
import axios from "../../../api/axios";
import Loading, { loadingState } from "../../../atoms/loading";
import { DynamicTitle } from "../../../utils/DynamicTitle";
import { roleState } from "../../../atoms/role";
import { emailState, nameState, surnameState } from "../../../atoms/membership";
import Errors from "../../../utils/Errors";
import { Button } from "@mui/material";

const Login = () => {
  DynamicTitle("Giriş Yap - Admin");
  const navigate = useNavigate();

  const email = useRef("");
  const [loading, setLoading] = useRecoilState(loadingState);
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const [, setCookies] = useCookies(["jwt"]);

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const setRole = useSetRecoilState(roleState);
  const setName = useSetRecoilState(nameState);
  const setSurname = useSetRecoilState(surnameState);
  const setEmail = useSetRecoilState(emailState);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .post(`login`, {
        email: email.current.value,
        password: values.password,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          setCookies("jwt", response.data.access_token, { path: "/" });
          const user = response.data.user;
          const tokenType = response.data.access_type;
          const expireIn = response.data.expires_in;
          const expireTime = response.data.expires_time;
          setRole(response.data.user.user_role.name);
          setName(response.data.user.name);
          setSurname(response.data.user.surname);
          setEmail(response.data.user.email);

          localStorage.setItem("user", JSON.stringify({ user, tokenType, expireIn, expireTime }));

          if (response.data.user.user_role.name === "MEM") {
            localStorage.removeItem("user");
            document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          } else if (
            response.data.user.user_role.name === "DEV" ||
            response.data.user.user_role.name === "BOS" ||
            response.data.user.user_role.name === "ADM" ||
            response.data.user.user_role.name === "FIN" ||
            response.data.user.user_role.name === "SPO" ||
            response.data.user.user_role.name === "OPS" ||
            response.data.user.user_role.name === "COU" ||
            response.data.user.user_role.name === "SPC" ||
            response.data.user.user_role.name === "CSE"
          ) {
            toast.success("Giriş Başarılı");
            setTimeout(() => {
              navigate("/dashboard");
            }, [0]);
          }
        } else if (response.data.status !== 200) {
          localStorage.removeItem("user");
          document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          toast.error(response.data.message);
        }
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  return (
    <div className="sign-mail-wrapper">
      <ToastContainer />
      <form onSubmit={handleLogin} className="sign-mail">
        <TextField
          fullWidth
          id="outlined-basic"
          label="E-Posta"
          type="email"
          variant="outlined"
          inputRef={email}
          required
        />
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Şifre</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            autoComplete="off"
            onChange={handleChange("password")}
            onKeyDown={(e) => (e.key === "Enter" ? handleLogin : "")}
            required
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        {loading ? (
          <Loading />
        ) : (
          <Button
            type="submit"
            className="iyzi-button-blue"
            sx={{ padding: "7px 20px", width: "100%", margin: "0 auto" }}
          >
            Giriş Yap
          </Button>
        )}
      </form>
    </div>
  );
};

export default Login;
